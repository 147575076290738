import { l, ObjectTypeIds, PERMISSIONS } from 'constants/common';

export const ObjectTypeIcons = {
    [ObjectTypeIds.Segment]: 'bc-icon-segment',
    [ObjectTypeIds.Template]: 'bc-icon-template',
    [ObjectTypeIds.Campaign]: 'bc-icon-campaign',
    [ObjectTypeIds.RealtimePromotion]: 'bc-icon-customer-journey',
    [ObjectTypeIds.Report]: 'bc-icon-reports',
};

export const getObjectTypes = (t) => [
    {
        name: t(l.Segment),
        id: ObjectTypeIds.Segment,
        value: String(ObjectTypeIds.Segment),
        icon: ObjectTypeIcons.Segment,
        checked: false,
        permissions: [],
    },
    {
        name: t(l.Template),
        id: ObjectTypeIds.Template,
        value: String(ObjectTypeIds.Template),
        icon: ObjectTypeIcons.Template,
        checked: false,
        permissions: [],
    },
    {
        name: t(l.Campaign),
        id: ObjectTypeIds.Campaign,
        value: String(ObjectTypeIds.Campaign),
        icon: ObjectTypeIcons.Campaign,
        checked: false,
        permissions: [],
    },
    {
        name: t(l.RealtimePromotion),
        id: ObjectTypeIds.RealtimePromotion,
        value: String(ObjectTypeIds.RealtimePromotion),
        icon: ObjectTypeIcons.RealtimePromotion,
        checked: false,
        permissions: [PERMISSIONS.ViewWorkflow],
    },
    {
        name: t(l.Report),
        id: ObjectTypeIds.Report,
        value: String(ObjectTypeIds.Report),
        icon: ObjectTypeIcons.Report,
        checked: false,
        permissions: [],
    },
];
