import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
//Import UI Components
import { BusyLoader, Button, Modal } from '@geneui/components';
//Import Components
import CampaignInfoModalBody from './CampaignInfoModalBody';
import TemplateInfoModalBody from '../TemplateModal/TemplateInfoModalBody';
import CampaignBonusInfoModalBody from './CampaignBonusInfoModalBody';
import { CustomTabs, SegmentModal } from 'components';
// Import Constants
import { ModalsActions, l } from 'constants/common';
// Import Services
import { CampaignHttpService, TemplateHttpService, BonusHttpService, UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import Config
import { CampaignInfoModalCustomTabsConfig } from './config';
import { tabsKey } from '../Campaign/config';

const CampaignInfoModal = ({ handleClose, isVisible, campaignId, handleInfoModalActionClick, templateProviders }) => {
    const { t } = useTranslation();
    const { doGetRequest, doPostRequest } = useRequest();

    const { getTemplate, getCampaign, getBonusById, getScheduleRunDatesRequest } = useMemo(
        () => ({
            getCampaign: CampaignHttpService.getCampaignById(),
            getTemplate: TemplateHttpService.getTemplate(),
            getBonusById: BonusHttpService.getBonusById(),
            getScheduleRunDatesRequest: UtilsHttpService.getScheduleRunDates(),
        }),
        [],
    );

    const [campaignInfo, setCampaignInfo] = useState(null);
    const [templateInfo, setTemplateInfo] = useState(null);
    const [bonusInfo, setBonusInfo] = useState(null);
    const [tabActiveKey, setTabActiveKey] = useState('Campaign');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
    const [isLoadingBonus, setIsLoadingBonus] = useState(false);
    const [recurrenceDataLocal, setRecurrenceDataLocal] = useState({});

    const isRequestedTabsData = useRef({ [tabsKey.Template]: false, [tabsKey.Bonus]: false });

    const getTemplateInfo = () => {
        if (!isRequestedTabsData.current[tabsKey.Template]) {
            isRequestedTabsData.current[tabsKey.Template] = true;
            setIsLoadingTemplate(true);
            doGetRequest(getTemplate.request, {
                queryString: { templateId: campaignInfo.TemplateId },
                successCallback: (Data) => {
                    setTemplateInfo(Data);
                },
            }).then(() => {
                setIsLoadingTemplate(false);
            });
        }
    };

    const getBonusInfo = () => {
        if (!isRequestedTabsData.current[tabsKey.Bonus]) {
            isRequestedTabsData.current[tabsKey.Bonus] = true;
            setIsLoadingBonus(true);
            doGetRequest(getBonusById.request, {
                queryString: { bonusId: campaignInfo.Bonus.BonusId },
                successCallback: (Data) => {
                    setBonusInfo(Data);
                },
            }).then(() => {
                setIsLoadingBonus(false);
            });
        }
    };

    const getTemplateData = (templateId) => {
        return new Promise((resolve, reject) => {
            doGetRequest(getTemplate.request, {
                queryString: { templateId: templateId },
                successCallback: (Data) => {
                    resolve(Data);
                },
                errorCallback: (error) => {
                    reject(error);
                },
            });
        });
    };

    const getContent = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case tabsKey.Segment:
                return (
                    <SegmentModal
                        segmentId={campaignInfo.SegmentId}
                        fromCampaignModal
                        handleInfoModalActionClick={handleInfoModalActionClick}
                    />
                );
            case tabsKey.Template:
                return (
                    <TemplateInfoModalBody
                        getTemplateInfo={getTemplateData}
                        isLoading={isLoadingTemplate}
                        childItemsInfo={templateInfo?.ChildItemsInfo}
                        templateId={templateInfo?.TemplateId}
                        templateInfo={templateInfo}
                        withActionButtons={true}
                        handleInfoModalActionClick={handleInfoModalActionClick}
                        templateProviders={templateProviders}
                    />
                );
            case tabsKey.Bonus:
                return (
                    <CampaignBonusInfoModalBody
                        campaignBonus={campaignInfo?.Bonus}
                        bonusInfo={bonusInfo}
                        isLoading={isLoadingBonus}
                    />
                );
            case tabsKey.Campaign:
                return (
                    <CampaignInfoModalBody
                        recurrenceData={recurrenceDataLocal}
                        campaignInfo={campaignInfo}
                        handleInfoModalActionClick={handleInfoModalActionClick}
                    />
                );
        }
    };

    const tabChangeHandler = (tabKey) => {
        return () => {
            if (tabKey === tabsKey.Template && isNil(templateInfo)) {
                getTemplateInfo();
            } else if (tabKey === tabsKey.Bonus && isNil(bonusInfo)) {
                getBonusInfo();
            }
            setTabActiveKey(tabKey);
        };
    };

    const tabsGenerator = () => {
        return CampaignInfoModalCustomTabsConfig.filter(({ tabKeyForFilter, tabKey }) => {
            if (tabKey === tabsKey.Template) {
                return campaignInfo[tabKeyForFilter];
            } else {
                return campaignInfo[tabKeyForFilter] || campaignInfo.Bonus?.BonusId;
            }
        }).map(({ tabKey, buttonContent }) => {
            return {
                tabKey,
                tabTitle: buttonContent(tabChangeHandler, t(l[tabKey])),
                tabContent: getContent(tabKey),
            };
        });
    };

    const init = () => {
        setIsLoading(true);
        doGetRequest(getCampaign.request, {
            queryString: { campaignId },
            successCallback: (Data) => {
                let campaignInfoData = Data;

                if (!isNil(Data?.Schedule)) {
                    if (!isNil(Data.Schedule?.CronSchedule) && Data.Schedule?.RecurrenceCount) {
                        campaignInfoData.Schedule.ScheduleType = l.Repeat;
                        doPostRequest(getScheduleRunDatesRequest.request, {
                            requestBody: {
                                cronExpression: Data.Schedule.CronSchedule,
                                startDate: Data.Schedule.StartDate,
                                endDate: Data.Schedule.EndDate,
                                timeZone: Data.Schedule.Timezone,
                            },
                            successCallback: (Data) => {
                                setRecurrenceDataLocal(Data);
                            },
                        });
                    } else {
                        campaignInfoData.Schedule.ScheduleType = l.OneTime;
                    }
                }
                setCampaignInfo(campaignInfoData);

                setIsLoading(false);
            },
        });
    };

    const cleanUp = () => {
        return () => {
            setCampaignInfo(null);
            setIsLoading(true);
            getCampaign.cancel('CampaignInfoModal:getCampaign');
            getTemplate.cancel('CampaignInfoModal:getTemplate');
            getBonusById.cancel('CampaignInfoModal:getBonusById');
        };
    };

    useEffect(init, []);

    useEffect(cleanUp, []);

    return (
        <>
            <Modal
                closeOnClickOutside={true}
                onCancel={() => handleClose(ModalsActions.CLOSE)}
                background="dark-background"
                position="center"
                visible={isVisible}
                closable={false}
                size="content-size"
                className="segment-list-modal crm-template-info-modal campaign-info-modal"
                title={
                    <div className="segment-list-modal-head">
                        <span className="segment-list-modal-h-text">{t(l.CampaignInfo)}</span>
                    </div>
                }
                footer={
                    <Button appearance="minimal" onClick={() => handleClose(ModalsActions.CLOSE)}>
                        {t(l.Close)}
                    </Button>
                }
            >
                <div className={'crm-campaign-info-popup'}>
                    <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
                        {!isNil(campaignInfo) && (
                            <CustomTabs
                                type="box"
                                activeTabKey={tabActiveKey}
                                tabsConfig={tabsGenerator()}
                                tabChangeHandler={tabChangeHandler}
                            />
                        )}
                    </BusyLoader>
                </div>
            </Modal>
        </>
    );
};

CampaignInfoModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    campaignId: PropTypes.string.isRequired,
    handleInfoModalActionClick: PropTypes.func,
    templateProviders: PropTypes.object,
};

export default CampaignInfoModal;
