import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// Import Components
import DynamicVariables from './DynamicVariables';
import SMSContent from './SMSContent';
// Import Constants
import { TemplateInputTypes } from 'constants/common';
// Import Hooks
import { useInputInsertion } from 'hooks';
// Import Services
import { TemplateType } from 'services/template';

const SMSTemplateContent = ({
    templateData,
    handleChange,
    templateLangKey,
    dynamicVariables,
    maxSMSPartsCount,
    shortenerAndTrackerSettings,
}) => {
    const { insertionHandler, updateInsertionHandler, insertionOptions, setInsertionOptions } = useInputInsertion();

    const updateDynamicVariableOptionsSMS = useCallback(() => {
        setInsertionOptions({
            channel: TemplateType.SMS,
            inputFilterKey: '',
            hintText: '',
            focusedInputKey: TemplateInputTypes.Body,
        });
    }, [setInsertionOptions]);

    return (
        <>
            <div>
                <DynamicVariables
                    dynamicVariables={dynamicVariables}
                    clickHandler={insertionHandler}
                    insertionOptions={insertionOptions}
                />
            </div>
            <div className="crm-sms-main-content">
                <SMSContent
                    bodyPropertyName={TemplateInputTypes.Body}
                    templateData={templateData}
                    templateLangKey={templateLangKey}
                    maxSMSPartsCount={maxSMSPartsCount}
                    handleChange={handleChange}
                    shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                    updateDynamicVariableOptions={updateDynamicVariableOptionsSMS}
                    updateInsertionHandler={updateInsertionHandler}
                    shouldFocusOnMount={true}
                />
            </div>
        </>
    );
};

SMSTemplateContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
    maxSMSPartsCount: PropTypes.number,
    shortenerAndTrackerSettings: PropTypes.array,
};

SMSTemplateContent.defaultProps = {
    shortenerAndTrackerSettings: [],
};

export default SMSTemplateContent;
