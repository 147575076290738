import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import UI components
import { Radio, ExtendedInput } from '@geneui/components';
import { LabelTag } from 'components';
// Import constants
import { LabelsColors } from './configs';
// Import constants
import { l } from 'constants/common';

const EditCreateContent = ({ setName, setDescription, setColor, setDisableSave, data }) => {
    const { t } = useTranslation();

    const { Name, Description, Color } = data;
    const { colors } = LabelsColors;

    const [labelName, setLabelName] = useState(Name);
    const [selectedColor, setSelectedColor] = useState(Color);

    const selectColorHandler = (e) => {
        const current = e.target.value;
        setSelectedColor(current);
        setColor(current);
        setDisableSave(false);
    };

    const nameChangeHandler = (e) => {
        const current = e.target.value;
        setLabelName(current);
        setName(current);
        setDisableSave(false);
    };

    const descriptionChangeHandler = (e) => {
        const current = e.target.value;
        setDescription(current);
        setDisableSave(false);
    };

    const getRadioBtnItemBgColor = (color) => {
        return color.includes('rgba') ? color.slice(0, color.length - 2) + '0.2)' : color + '34';
    };

    return (
        <>
            <ExtendedInput
                labelAppearance="swap"
                placeholder={t(l.Name)}
                onChange={nameChangeHandler}
                defaultValue={Name}
            />
            <ExtendedInput
                labelAppearance="swap"
                placeholder={t(l.Description)}
                onChange={descriptionChangeHandler}
                defaultValue={Description}
            />
            <span className="radio-but-txt">{t(l.Colors)}</span>
            <div className="label-body">
                {colors.map(({ value }, index) => {
                    const bgColor = getRadioBtnItemBgColor(value);

                    return (
                        <div key={index} style={{ '--label-tag-color': value, '--label-tag-bg-color': bgColor }}>
                            <div className="crm-manage-label-radio-btn-item">
                                <Radio value={value} checked={value === selectedColor} onChange={selectColorHandler} />
                            </div>
                        </div>
                    );
                })}
            </div>
            <span className="radio-but-txt">{t(l.Preview)}</span>
            <div className="preview-block">
                <LabelTag color={selectedColor} labelName={labelName || t(l.Name)} />
            </div>
        </>
    );
};

EditCreateContent.propTypes = {
    data: PropTypes.object.isRequired,
    setName: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    setColor: PropTypes.func.isRequired,
    setDisableSave: PropTypes.func.isRequired,
};

export default memo(EditCreateContent);
