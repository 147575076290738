import { CustomerJourneyActionTypes as Types } from './types';

const incrementBlockCount = (blockType) => ({
    type: Types.INCREMENT_BLOCK_COUNT,
    payload: blockType,
});

const decrementBlockCount = (blocksCounts) => ({
    type: Types.DECREMENT_BLOCK_COUNT,
    payload: blocksCounts,
});

const resetBlocksCount = () => ({
    type: Types.RESET_BLOCKS_COUNT,
});

const setBlocksCounts = (payload) => ({
    type: Types.SET_BLOCKS_COUNTS,
    payload,
});

const setMaxBlocksCounts = (payload) => ({
    type: Types.SET_MAX_BLOCKS_COUNTS,
    payload,
});

export default {
    incrementBlockCount,
    decrementBlockCount,
    resetBlocksCount,
    setBlocksCounts,
    setMaxBlocksCounts,
};
