import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
// Import UI Components
import { Button } from '@geneui/components';
// Import Components
import { FilterFields } from 'components';
// Import Constants
import { l, SupportedComparisonFilterFieldsTypes } from 'constants/common';
// Import Hooks
import { useFilterFieldsState } from 'hooks';
// Import Services
import { FilterExistingValueChecker, FiltersFieldsWithComparisionMap } from 'components/common/FilterFields/service';
// Import SCSS
import 'assets/scss/clientList.scss';

const SegmentationFilters = ({ filtersList, onApply, onReset }) => {
    const {
        filters,
        appliedFilters,
        changeFilters,
        applyFilters: applyChangedFilters,
        resetFilters,
        isResetDisabled,
    } = useFilterFieldsState(filtersList);

    const { t } = useTranslation();

    const applyFilters = (data = filters, withOnApply) => {
        const resultData = FiltersFieldsWithComparisionMap(data);
        withOnApply && onApply(resultData);
    };

    const handleChange = (e, changeInputValue, customChange, index, filterInputData) => {
        const resultData = changeInputValue(e, customChange, cloneDeep(filters), index, filterInputData);
        changeFilters(resultData);
    };

    const handleComparisonChange = (comparison, index) => {
        const data = cloneDeep(filters);
        data[index].comparision = comparison.Comparision;
        data[index].type = SupportedComparisonFilterFieldsTypes[comparison.Type];
        changeFilters(data);
    };

    const handleReset = () => {
        resetFilters();
        onReset();
    };

    const handleApply = () => {
        if (FilterExistingValueChecker(filters)) {
            applyChangedFilters();
            applyFilters(filters, true);
        }
    };

    const isApplyDisabled = useMemo(() => {
        const currentFilters = filters.reduce((acc, val) => {
            acc[val.ColumnId] = val;
            return acc;
        }, {});

        return !appliedFilters.reduce((acc, val) => {
            const currentFilter = currentFilters[val.ColumnId];
            if (isEqual(val.value, currentFilter.value)) {
                if (!isEmpty(val.value) && !isEqual(val.comparision, currentFilter.comparision)) {
                    return true;
                }
                return acc;
            }

            return true;
        }, false);
    }, [filters, appliedFilters]);

    return (
        <div>
            <div className="crm-horizontal-filters-container">
                <FilterFields
                    filterInputsData={filters}
                    handleChange={handleChange}
                    withComparisons={true}
                    handleComparisonChange={handleComparisonChange}
                />
            </div>
            <div className="crm-filters-action-buttons">
                <Button appearance="minimal" onClick={handleReset} disabled={isResetDisabled}>
                    {t(l.Reset)}
                </Button>
                <Button color="confirm" onClick={handleApply} disabled={isApplyDisabled}>
                    {t(l.Apply)}
                </Button>
            </div>
        </div>
    );
};

SegmentationFilters.propTypes = {
    filtersList: PropTypes.array,
    onApply: PropTypes.func,
    onReset: PropTypes.func,
};

export default memo(SegmentationFilters);
