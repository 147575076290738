import { CustomerJourneyListActionTypes as Types } from './types';

const setCustomerJourneyListTableFilters = (filters) => ({
    type: Types.SET_CUSTOMER_JOURNEY_LIST_TABLE_FILTERS,
    payload: filters,
});

const setCustomerJourneyListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_CUSTOMER_JOURNEY_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setCustomerJourneyListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_CUSTOMER_JOURNEY_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setCustomerJourneyListTableSorting = (sorting) => ({
    type: Types.SET_CUSTOMER_JOURNEY_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetCustomerJourneyListState = () => ({
    type: Types.RESET_CUSTOMER_JOURNEY_LIST_STATE,
});

export default {
    setCustomerJourneyListTableFilters,
    setCustomerJourneyListTablePagingPageNumber,
    setCustomerJourneyListTablePagingPageSize,
    setCustomerJourneyListTableSorting,
    resetCustomerJourneyListState,
};
