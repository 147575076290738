import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
//  Import UI Components
import { Dropdown, ExtendedInput, BusyLoader } from '@geneui/components';
//  Import Services
import { UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest, useValidator } from 'hooks';
// Import Constants
import { l, DynamicInputTypes, FieldTypes } from 'constants/common';

const CustomFields = ({
    customFieldsValues,
    setCustomFieldsValues,
    onCustomFieldOptionsLoading,
    customFields,
    isLoaded,
    addValidationProperties,
    setDropDownSettings,
    dropDownSettings,
}) => {
    const { t } = useTranslation();

    const { doPostRequest } = useRequest();
    const validator = useValidator();
    const pageValidation = useSelector((state) => state.pageValidation);

    const [isLoading, setIsLoading] = useState(true);

    const { getCustomFieldOptionsRequest } = useMemo(
        () => ({
            getCustomFieldOptionsRequest: UtilsHttpService.getCustomFieldOptions(),
        }),
        [],
    );

    const inputChangeHandler = (value, customFieldId, IsRequired, Type) => {
        const tmpValue = DynamicInputTypes.INPUT === Type ? value.trimStart() : value;
        let obj = { CustomFieldId: customFieldId, FieldValue: tmpValue, IsRequired, Type };
        let list = [...customFieldsValues];
        let index = list.findIndex((r) => r.CustomFieldId === customFieldId);
        if (index !== -1) {
            list[index] = obj;
        } else {
            list.push(obj);
        }
        setCustomFieldsValues(list);
        validator({ [FieldTypes.CustomField + customFieldId]: tmpValue });
    };

    const handleInputBlur = (data) => {
        return (e) => {
            inputChangeHandler(e.target.value.trimEnd(), data.CustomFieldId, data.IsRequired, data.Type);
        };
    };

    const renderDynamicInputElement = (data, index) => {
        const customField = customFieldsValues.find((el) => el.CustomFieldId === data.CustomFieldId);
        const pValidation = pageValidation[FieldTypes.CustomField + data?.CustomFieldId];
        return (
            <div key={index} className="owner-row">
                <ExtendedInput
                    appearance="outline"
                    inputSize="big"
                    labelAppearance="swap"
                    placeholder={t(data.Name)}
                    isValid={!pValidation?.errorText}
                    errorText={t(l[pValidation?.errorText], {
                        fieldName: data.Name,
                        count: pValidation?.paramValue,
                    })}
                    onChange={(e) => inputChangeHandler(e.target.value, data.CustomFieldId, data.IsRequired, data.Type)}
                    onBlur={handleInputBlur(data)}
                    value={isNil(customField) ? '' : customField.FieldValue}
                />
            </div>
        );
    };

    const renderDynamicDropDownElement = (data, index) => {
        const customField = customFieldsValues.find((el) => el.CustomFieldId === data.CustomFieldId);
        const pValidation = pageValidation[FieldTypes.CustomField + data?.CustomFieldId];

        return (
            <div key={index} className="owner-row">
                <Dropdown
                    isMultiSelect={false}
                    disabled={false}
                    hasSearch={true}
                    inputSize="default"
                    placeholder={t(data.Name)}
                    appearance="outline"
                    data={dropDownSettings[index]}
                    searchPlaceholderText={t(l.Search)}
                    labelAppearance="swap"
                    noDataText={t(l.NoDataFound)}
                    onChange={(e) => inputChangeHandler(e?.value, data.CustomFieldId, data.IsRequired, data.Type)}
                    value={+customField?.FieldValue}
                    clearable={!data.IsRequired}
                    isValid={!pValidation?.errorText}
                    errorText={t(l[pValidation?.errorText], {
                        fieldName: data.Name,
                        count: pValidation?.paramValue,
                    })}
                />
            </div>
        );
    };

    const renderDynamicInputs = () => {
        return customFields.map((data, index) => {
            switch (data.Type) {
                case DynamicInputTypes.INPUT:
                    return renderDynamicInputElement(data, index);
                case DynamicInputTypes.DROP_DOWN:
                    return !isNil(dropDownSettings) && renderDynamicDropDownElement(data, index);
                default:
                    // for not supported types
                    return <span key={index}></span>;
            }
        });
    };

    const getInputSettings = (data) => {
        return data.map((element) => {
            if (element.Type === DynamicInputTypes.DROP_DOWN) {
                const { CustomFieldId } = element;
                return doPostRequest(getCustomFieldOptionsRequest.request, {
                    requestBody: [CustomFieldId],
                });
            }
            return null;
        });
    };

    const mapDropDownSettings = (responses) => {
        // for promise all need has error but case dows not cover
        let hasError = false;
        let dropDownData = responses.map((response) => {
            // for cases when promise is null
            if (isNil(response)) return response;

            const { HasError, Data } = response;
            if (!HasError) {
                return Data.map((row) => ({ label: row.OptionValue, value: row.CustomFieldOptionId }));
            }
            // case when one of one promise has error
            hasError = true;
            return null;
        });
        return { Data: dropDownData, HasError: hasError };
    };

    useEffect(() => {
        onCustomFieldOptionsLoading(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(() => {
        if (!isNil(customFields) && isLoaded) {
            Promise.all(getInputSettings(customFields)).then((responses) => {
                const { Data, HasError } = mapDropDownSettings(responses);

                setDropDownSettings(Data);
                setIsLoading(HasError);

                customFields.forEach(({ IsRequired, Type, CustomFieldId }, i) => {
                    let tmpValue = customFieldsValues[i]?.FieldValue;

                    if (IsRequired) {
                        addValidationProperties({
                            customField: true,
                            property: { key: FieldTypes.CustomField + CustomFieldId, value: tmpValue, type: Type },
                        });
                    }

                    if (tmpValue) {
                        inputChangeHandler(tmpValue, CustomFieldId, IsRequired, Type);
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFields, isLoaded]);

    return (
        <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
            {!isNil(customFields) && renderDynamicInputs()}
        </BusyLoader>
    );
};
CustomFields.propTypes = {
    customFieldsValues: PropTypes.array,
    customFields: PropTypes.array,
    setCustomFieldsValues: PropTypes.func,
    isLoaded: PropTypes.bool,
    addValidationProperties: PropTypes.func,
    setDropDownSettings: PropTypes.func,
    onCustomFieldOptionsLoading: PropTypes.func,
    dropDownSettings: PropTypes.array,
};

CustomFields.defaultProps = {
    onCustomFieldOptionsLoading: noop,
};

export default memo(CustomFields);
