/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Import UI components
import { Icon, Tooltip } from '@geneui/components';
import { LabelTag } from 'components';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';

const ConfigurationWidget = ({ title, description, icon, clickHandler, classNames, small, labelTag, infoIcon }) => {
    const [classNamesBase, setClassNamesBase] = useState({ ...classNames });
    const { t } = useTranslation();

    useEffect(() => {
        setClassNamesBase(() => {
            const result = { ...classNames };
            let additionalClassName = {
                wrapper: 'config-widgets-wrapper-small',
                btnTitle: 'config-widgets-btn-heading-small',
            };
            if (small) {
                result.wrapper = `${classNamesBase.wrapper} ${additionalClassName.wrapper}`;
                result.btnTitle = `${classNamesBase.btnTitle} ${additionalClassName.btnTitle}`;
            }
            return result;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classNames, small]);

    //TODO remove onclick from div

    return (
        <div className={classNamesBase.wrapper}>
            <div className={classNamesBase.btn} onClick={clickHandler}>
                <div className={classNamesBase.icon}>
                    <Icon type={icon} />
                </div>
                <div className={classNamesBase.btnContent}>
                    <div className={classNamesBase.btnHeading}>
                        <span className={classNamesBase.btnTitle}>{title}</span>
                        {!isNil(infoIcon) && (
                            <Tooltip text={t(infoIcon)}>
                                <Icon type="bc-icon-info" />
                            </Tooltip>
                        )}
                    </div>
                    <span className={classNamesBase.btnTxt}>{description}</span>
                </div>
                {!isNil(labelTag) && <LabelTag color={labelTag?.color} labelName={labelTag?.name} />}
            </div>
        </div>
    );
};

ConfigurationWidget.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
    classNames: PropTypes.object,
    small: PropTypes.bool,
    labelTag: PropTypes.object,
    infoIcon: PropTypes.string,
};

ConfigurationWidget.defaultProps = {
    classNames: {
        wrapper: 'config-widgets-wrapper',
        btn: 'config-widgets-btn',
        icon: 'config-widgets-btn-icon',
        btnContent: 'config-widgets-btn-content',
        btnHeading: 'config-widgets-btn-heading',
        btnTitle: 'config-widgets-btn-title',
        btnTxt: 'config-widgets-btn-txt',
    },
    small: false,
    description: '',
};

export default memo(ConfigurationWidget);
