import { CompositePageReportListActionTypes as Types } from './types';

const setReportListTableFilters = (filters) => ({
    type: Types.SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_FILTERS,
    payload: filters,
});

const setReportListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setReportListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setReportListTableSorting = (sorting) => ({
    type: Types.SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetReportListState = () => ({
    type: Types.RESET_COMPOSITE_PAGE_REPORT_LIST_STATE,
});

export default {
    setReportListTableFilters,
    setReportListTablePagingPageNumber,
    setReportListTablePagingPageSize,
    setReportListTableSorting,
    resetReportListState,
};
