import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, some } from 'lodash';
// Import UI Components
import { Toaster } from '@geneui/components';
// Import Hooks
import { useLocalization } from 'hooks';
// Import Constants
import { allRoutePaths } from 'routes/config';
import { DefaultLangId, l } from 'constants/common';
import { BrowserStorageKeys } from 'constants/browserStorage';
// Import SCSS
import 'assets/scss/authPages.scss';

const selectAuth = (state) => state.auth;

const AuthLayout = ({ children, rightPartWrapperClassName }) => {
    const { t } = useTranslation();
    const { isLoggedIn } = useSelector(selectAuth);
    const [setLocalization] = useLocalization();

    useEffect(() => {
        localStorage.removeItem(BrowserStorageKeys.isWaitForSSOResponse);
        setLocalization(DefaultLangId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const directUrl = localStorage.getItem(BrowserStorageKeys.directUrl) || '';

    if (isLoggedIn) {
        if (!isEmpty(directUrl) && some(allRoutePaths, (path) => !isNil(directUrl.match(path.regex)))) {
            return <Redirect to={directUrl} />;
        } else {
            return <Redirect to="/crm/homepage" />;
        }
    }

    return (
        <div className="crm-auth-layout-container crm-sign-in-page">
            <div className="crm-auth-left-block">
                <div className="crm-auth-left-wrapper">
                    <h1 className="crm-auth-left-title">{t(l.Welcome)}</h1>
                    <p className="crm-auth-left-subtitle">{t(l.EasyWayToManageYourMarketingEffort)}</p>
                    <p className="crm-auth-left-description">{t(l.CRMDescription)}</p>
                </div>
            </div>

            <div className={classnames('crm-auth-right-block', rightPartWrapperClassName)}>{children}</div>

            <Toaster />
        </div>
    );
};

AuthLayout.propTypes = {
    rightPartWrapperClassName: PropTypes.string,
};

export default AuthLayout;
