import UIHashBaseContainer from './UIHashBaseContainer';

export default class UIHashComparisonContainer extends UIHashBaseContainer {
    constructor(comparison, _Value, _additionalData) {
        super();
        this.comparison = comparison || null;
        this._Value = _Value || null;
        this._additionalData = _additionalData || null;
    }

    get additionalData() {
        return this._additionalData;
    }

    setAdditionalData(value) {
        this._additionalData = value;
    }

    get comparisonId() {
        if (this.comparison) {
            return this.comparison.comparison;
        }
        return null;
    }

    setValue(value) {
        this._Value = value;
    }

    get viewType() {
        return this.comparison.viewType;
    }

    get Value() {
        return this._Value;
    }

    get elementType() {
        return this.comparison ? this.comparison.elementType : null;
    }

    get queryFilter() {
        return {
            Column: { Id: this.comparison.columnId },
            Comparision: this.comparison.comparison,
            Value: this._Value,
        };
    }
}
