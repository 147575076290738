import React from 'react';
import { isNil } from 'lodash';
// Import Components
import SegmentWidget from './SegmentWidget';
import TemplateWidget from './TemplateWidget';
import BonusWidget from './BonusWidget';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { DateTimeFormat, PERMISSIONS } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

const tabsKey = {
    Campaign: 'Campaign',
    Segment: 'Segment',
    Template: 'Template',
    Bonus: 'Bonus',
};

const tabsToSwitchKey = {
    [tabsKey.Template]: tabsKey.Bonus,
    [tabsKey.Bonus]: tabsKey.Template,
};

const CampaignTabsConfig = [
    {
        tabKey: tabsKey.Segment,
        buttonContent(selectedRowData, handleClick) {
            return <SegmentWidget data={selectedRowData} handleClick={handleClick} />;
        },
    },
    {
        tabKey: tabsKey.Template,
        buttonContent(selectedRowData, handleClick, handleSwitcherChange, switcherChecked) {
            return (
                <TemplateWidget
                    data={selectedRowData}
                    isWithSwitcher={true}
                    handleClick={handleClick}
                    handleSwitcherChange={handleSwitcherChange}
                    switcherChecked={switcherChecked}
                />
            );
        },
    },
    {
        tabKey: tabsKey.Bonus,
        buttonContent(selectedRowData, handleClick, handleSwitcherChange, switcherChecked) {
            return (
                <BonusWidget
                    data={selectedRowData}
                    isWithSwitcher={true}
                    handleClick={handleClick}
                    handleSwitcherChange={handleSwitcherChange}
                    switcherChecked={switcherChecked}
                />
            );
        },
        permissions: PERMISSIONS.ViewAwards,
    },
];

const dateTimeFormat = (date) => {
    return isNil(date) ? '' : customMomentWithoutTimezoneConversion(date).format(DateTimeFormat);
};

export { tabsKey, tabsToSwitchKey, CampaignTabsConfig, dateTimeFormat };
