import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import GeneUIProvider from '@geneui/components/GeneUIProvider';
import i18n from './i18n';

import './googleAnalytics';

import store from './store';

import './index.scss';

import { App } from 'components';

import Router from './BrowserRouterWrapper';

const dotenv = require('dotenv');
const dotenvExpand = require('dotenv-expand');

const myEnv = dotenv.config();
dotenvExpand(myEnv);
render(
    <Provider store={store}>
        <Router>
            <I18nextProvider i18n={i18n}>
                <GeneUIProvider>
                    <App />
                </GeneUIProvider>
            </I18nextProvider>
        </Router>
    </Provider>,
    document.getElementById('root'),
);
