import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Dropdown, Icon, Tag } from '@geneui/components';
// Import Components
import { RandomComponentWithHeadline } from 'components';
//Import Constants
import { l, ColorVars } from 'constants/common';

const RecipientSelectors = ({
    selectedEmailRecipients,
    selectedFtpRecipients,
    setSelectedEmailRecipients,
    setSelectedFtpRecipients,
    ftpRecipientsList,
    emailRecipientsList,
}) => {
    const { t } = useTranslation();

    const pageValidation = useSelector((state) => state.pageValidation);

    const [selectedEmailRecipientsValues, setSelectedEmailRecipientsValues] = useState([]);
    const [selectedFtpRecipientsValues, setSelectedFtpRecipientsValues] = useState([]);

    const mapToValues = (list) => {
        return list.map(({ value }) => value);
    };

    const deleteChosenRecipients = (e) => {
        const filteredSelectedValues = selectedEmailRecipients.filter((val) => +val.value !== +e);
        setSelectedEmailRecipients(filteredSelectedValues);
    };

    const deleteChosenFtp = (e) => {
        const filteredSelectedValues = selectedFtpRecipients.filter((val) => +val.value !== +e);
        setSelectedFtpRecipients(filteredSelectedValues);
    };

    useEffect(() => {
        setSelectedEmailRecipientsValues(mapToValues(selectedEmailRecipients));
    }, [selectedEmailRecipients]);

    useEffect(() => {
        setSelectedFtpRecipientsValues(mapToValues(selectedFtpRecipients));
    }, [selectedFtpRecipients]);

    return (
        <div className="providers-wrap">
            <div className="providers-wrap-inner">
                <div className="title-wrapper">
                    <p className="title-wrapper-text">{t(l.EmailRecipients)}</p>
                    <span className="title-wrapper-line" />
                </div>
                <div className="providers-wrap-row">
                    <Dropdown
                        data={emailRecipientsList}
                        value={selectedEmailRecipientsValues}
                        isMultiSelect={true}
                        onChange={(values) => setSelectedEmailRecipients(values)}
                        isValid={pageValidation?.EmailRecipients?.isValid}
                        errorText={pageValidation?.EmailRecipients?.errorText}
                    />
                    <RandomComponentWithHeadline className={'crm-form-single-input-equal'}>
                        <div className={'crm-multi-select-country-section-body'}>
                            <div className="crm-multi-select-country-inner">
                                <p>{t(l.SelectedItems)}</p>
                                {selectedEmailRecipients &&
                                    selectedEmailRecipients.map(({ label, value, isUnavailable }) => (
                                        <Tag
                                            name={label}
                                            key={value}
                                            appearance="minimal"
                                            cornerRadius="smooth-radius"
                                            className={'crm-multi-select-country-section__tag'}
                                            color={isUnavailable ? ColorVars.danger : ''}
                                            icons={
                                                <Icon
                                                    type={'bc-icon-clear'}
                                                    onClick={() => deleteChosenRecipients(value)}
                                                />
                                            }
                                        />
                                    ))}
                            </div>
                        </div>
                    </RandomComponentWithHeadline>
                </div>
            </div>
            <div className="providers-wrap-inner">
                <div className="title-wrapper">
                    <p className="title-wrapper-text">{t(l.FTPRecipients)}</p>
                    <span className="title-wrapper-line" />
                </div>
                <div className="providers-wrap-row">
                    <Dropdown
                        data={ftpRecipientsList}
                        value={selectedFtpRecipientsValues}
                        isMultiSelect={true}
                        onChange={(values) => setSelectedFtpRecipients(values)}
                    />
                    <RandomComponentWithHeadline className={'crm-form-single-input-equal'}>
                        <div className={'crm-multi-select-country-section-body'}>
                            <div className="crm-multi-select-country-inner">
                                <p>{t(l.SelectedItems)}</p>
                                {selectedFtpRecipients &&
                                    selectedFtpRecipients.map(({ label, value }) => (
                                        <Tag
                                            name={label}
                                            key={value}
                                            appearance="minimal"
                                            cornerRadius="smooth-radius"
                                            className={'crm-multi-select-country-section__tag'}
                                            icons={
                                                <Icon type={'bc-icon-clear'} onClick={() => deleteChosenFtp(value)} />
                                            }
                                        />
                                    ))}
                            </div>
                        </div>
                    </RandomComponentWithHeadline>
                </div>
            </div>
        </div>
    );
};

RecipientSelectors.propTypes = {
    emailRecipientsList: PropTypes.array,
    ftpRecipientsList: PropTypes.array,
    selectedEmailRecipients: PropTypes.array,
    selectedFtpRecipients: PropTypes.array,
    setSelectedEmailRecipients: PropTypes.func,
    setSelectedFtpRecipients: PropTypes.func,
};

RecipientSelectors.defaultProps = { selectedEmailRecipients: [], selectedFtpRecipients: [] };

export default memo(RecipientSelectors);
