import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop, random } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { SkeletonLoader, Tag, Icon, Tooltip } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const SelectedItemsContainer = ({ className, data, titleLabel, removable, onRemove, isLoading, withTooltip }) => {
    const { t } = useTranslation();

    const randomArray = useMemo(() => {
        return [...Array(random(5, 10))].map(() => random(100, 200));
    }, []);

    return (
        <div className={className}>
            <div className="crm-multi-select-country-section-body">
                <div className="crm-multi-select-country-inner">
                    <p>{t(titleLabel)}</p>
                    {isLoading ? (
                        <div className="crm-multi-select-country-inner skeleton-content">
                            {randomArray.map((item, index) => {
                                return <SkeletonLoader key={index} isBusy={true} width={item} />;
                            })}
                        </div>
                    ) : (
                        <>
                            {data.map((item, index) => {
                                if (withTooltip) {
                                    return (
                                        <Tooltip
                                            key={index}
                                            className="selected-items-container-item"
                                            text={isNil(item.tooltip) ? item.label : item.tooltip}
                                        >
                                            <Tag
                                                key={index}
                                                name={item.label}
                                                appearance="minimal"
                                                cornerRadius="smooth-radius"
                                                className={'crm-multi-select-country-section__tag'}
                                                icons={
                                                    removable && (
                                                        <Icon type={'bc-icon-clear'} onClick={() => onRemove(item)} />
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    );
                                }
                                return (
                                    <Tag
                                        key={index}
                                        name={item.label}
                                        appearance="minimal"
                                        cornerRadius="smooth-radius"
                                        className={'crm-multi-select-country-section__tag'}
                                        icons={
                                            removable && <Icon type={'bc-icon-clear'} onClick={() => onRemove(item)} />
                                        }
                                    />
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

SelectedItemsContainer.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
    titleLabel: PropTypes.string,
    removable: PropTypes.bool,
    isLoading: PropTypes.bool,
    withTooltip: PropTypes.bool,
    onRemove: PropTypes.func,
};
SelectedItemsContainer.defaultProps = {
    className: '',
    data: [],
    titleLabel: l.SelectedItems,
    removable: false,
    isLoading: false,
    withTooltip: false,
    onRemove: noop,
};

export default memo(SelectedItemsContainer);
