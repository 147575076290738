import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Import Components
import { KeyValueItem } from 'components';

const KeyValueItems = ({ items, vertical }) => {
    return (
        <div className="crm-keyvalue-items-wrapper">
            {items.map(({ isLoading, label, value, classNames, ItemWrapper = Fragment, ...rest }, index) => {
                return (
                    <ItemWrapper key={index}>
                        <KeyValueItem
                            isLoading={isLoading}
                            label={label}
                            value={value}
                            classNames={classNames}
                            vertical={vertical}
                            {...rest}
                        />
                    </ItemWrapper>
                );
            })}
        </div>
    );
};

KeyValueItems.defaultProps = {
    vertical: false,
};

KeyValueItems.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            ...KeyValueItem.propTypes,
            ItemWrapper: PropTypes.elementType,
        }),
    ),
    vertical: PropTypes.bool,
};

export default KeyValueItems;
