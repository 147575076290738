/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
// Import Hooks
import { useRequest, useData } from 'hooks';
// Import Services
import { BonusHttpService } from 'services/http';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
import { BonusModal } from 'components';

const CJPartnerBonusIdInfoBlock = ({ data, customAttributes: _customAttributes, additionalDataUpdate }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const dataValue = useData(data);

    const [isLoading, setIsLoading] = useState(false);
    const [bonusData, setBonusData] = useState(null);
    const [isVisibleBonusInfo, setIsVisibleBonusInfo] = useState(false);

    const { getBonusById } = useMemo(
        () => ({
            getBonusById: BonusHttpService.getBonusById(),
        }),
        [],
    );

    useEffect(() => {
        setIsLoading(true);
        doGetRequest(getBonusById.request, {
            queryString: { bonusId: dataValue.value },
            successCallback: (data) => {
                setIsLoading(false);
                setBonusData(data);
                additionalDataUpdate(data);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataValue]);

    const openBonusModal = () => {
        setIsVisibleBonusInfo(true);
    };

    return (
        <>
            <CJInfoBlockLabelValueWrapper
                label={t(l.Name)}
                value={bonusData?.Name}
                isLoading={isLoading}
                onClick={openBonusModal}
                isClickable={true}
            />

            {isVisibleBonusInfo && (
                <BonusModal
                    id={bonusData?.Id}
                    wallet={bonusData?.BalanceType}
                    title={bonusData?.Name}
                    description={bonusData?.Description}
                    startDate={bonusData?.StartDate}
                    endDate={bonusData?.EndDate}
                    type={bonusData.Type}
                    bonusDetails={bonusData.BonusDetails}
                    handleClose={() => setIsVisibleBonusInfo(false)}
                    isVisible={isVisibleBonusInfo}
                    state={bonusData?.State}
                />
            )}
        </>
    );
};

CJPartnerBonusIdInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
    additionalDataUpdate: PropTypes.func,
};

CJPartnerBonusIdInfoBlock.defaultProps = {
    additionalDataUpdate: noop,
};

export default memo(CJPartnerBonusIdInfoBlock);
