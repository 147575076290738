import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
// Import Hooks
import { useGACustomEvent } from 'hooks';
// Import UI Components
import { Copy, Icon, Tooltip } from '@geneui/components';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';

const { GAGenericCategory } = GACategoryEvents;

const PartnerRow = ({ data }) => {
    const { t } = useTranslation();
    const { logCustomEvent } = useGACustomEvent();
    const copyableContentRef = useRef({});
    const statusTooltipText = data.IsCrmActivated ? l.PartnerActiveTooltipText : l.PartnerNotActiveTooltipText;

    const onCopy = () => {
        logCustomEvent(
            GAGenericCategory.name,
            GAGenericCategory.events.copyPartnerId,
            'daily communication metrics',
            copyableContentRef.current?.innerText,
        );
    };

    return (
        <>
            <Tooltip key={data.label} title={isString(data.label) ? data.label : ''}>
                <span className={`crm-option-column-${data.value} ellipsis-text`}>{data.label}</span>
            </Tooltip>

            <div className="partner-row-elements-container">
                {copyableContentRef.current && (
                    <Copy
                        key={data.displayId}
                        size="small"
                        copiedTooltipText={t(l.IdCopied)}
                        contentRef={copyableContentRef}
                        showOnHover={true}
                        onClick={onCopy}
                    />
                )}
                <p
                    ref={(node) => {
                        if (node) {
                            copyableContentRef.current = node;
                        }
                    }}
                >
                    {data.displayId}
                </p>
                <Tooltip text={t(statusTooltipText)}>
                    <Icon
                        type="bc-icon-status-circle"
                        className={`${data.IsCrmActivated ? 'partner-active' : 'partner-not-active'}`}
                    />
                </Tooltip>
            </div>
        </>
    );
};

PartnerRow.propTypes = {
    data: PropTypes.object,
    id: PropTypes.number,
};

export default memo(PartnerRow);
