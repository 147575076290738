export const comparisonIconsTypes = {
    0: 'bc-icon-equal',
    1: 'bc-icon-not-equal',
    2: 'bc-icon-contains',
    8: 'bc-icon-contains',
    3: 'bc-icon-not-contains',
    4: 'bc-icon-above',
    5: 'bc-icon-greater-or-equal',
    6: 'bc-icon-below',
    7: 'bc-icon-less-or-equal',
    9: 'bc-icon-within',
    10: 'bc-icon-starts-with',
    11: 'bc-icon-ends-with',
    12: 'bc-icon-not-contains',
    13: 'bc-icon-not-within',
    14: 'bc-icon-not-starts-with',
    15: 'bc-icon-not-ends-with',
};

export const ClientsCountChartConfig = ({ Count, Total }) => [
    {
        y: Count,
        color: '#ef5d8f',
        name: 'Selected',
    },
    {
        x: Total,
        color: '#47cfe0',
        name: 'Total',
    },
];
