import { QueryConfigurationsTypes as Types } from 'actions/types';

export default (state = {}, { payload, type }) => {
    switch (type) {
        case Types.SET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS:
            return Object.assign({ ...payload }, state, {});
        case Types.RESET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS:
            return {};
        default:
            return state;
    }
};
