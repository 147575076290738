import React, { memo, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { first, keys, noop, sortBy } from 'lodash';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    CJCommunicationAvailabilityConditionTimePickerValidationSchema,
    CJCommunicationAvailabilityConditionOptionValidationSchema,
} from 'validators/schemas.validation';
import { yupSchemaValidate } from 'validators/service.validator';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Constants
import {
    l,
    TimeZonePack,
    CommunicationAvailabilityScheduleTypeLabels,
    CommunicationAvailabilityScheduleTypes,
} from 'constants/common';
import { CJCommunicationAvailabilityConditionConfig } from 'components/CustomerJourney/config';
// Import UI Components
import { Checkbox, Tooltip, TimePicker, Dropdown, Label, Icon } from '@geneui/components';

const CJCommunicationAvailabilityCondition = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const { ActivePartner } = useSelector((state) => state.header.settings);

    const scheduleTypeData = useMemo(() => {
        const tmpData =
            option?.Required === true
                ? CommunicationAvailabilityScheduleTypes.filter(
                      (item) => item.value !== CommunicationAvailabilityScheduleTypeLabels.Always,
                  )
                : CommunicationAvailabilityScheduleTypes;

        return tmpData.map((item) => ({ ...item, label: t(item.label) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    const timeZonePackData = useMemo(() => {
        return sortBy(
            keys(TimeZonePack).map((key) => ({ label: TimeZonePack[key], value: +key })),
            ['value'],
        );
    }, []);

    const formik = useFormik({
        initialValues: {
            timeZone: defaultValue?.timeZone ?? ActivePartner.TimeZone,
            scheduleType: defaultValue?.scheduleType ?? first(scheduleTypeData).value,
            schedule:
                defaultValue?.schedule ??
                CJCommunicationAvailabilityConditionConfig[defaultValue?.scheduleType ?? first(scheduleTypeData).value],
        },
        onSubmit: noop,
        validationSchema: CJCommunicationAvailabilityConditionOptionValidationSchema(option),
    });

    const { values, setFieldValue, submitForm } = formik;
    const { timeZone, scheduleType, schedule } = values;

    const scheduleRef = useRef(schedule);

    useEffect(() => {
        submitForm();
        setSchedule(scheduleRef.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getUpdate({ timeZone: timeZone, scheduleType: scheduleType, schedule: schedule });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeZone, scheduleType, schedule]);

    const setSchedule = (value) => {
        scheduleRef.current = value.map((item) => {
            let result = item;
            if (item.isEnable) {
                result = { ...item };
                let errorText = null;
                let isValid = yupSchemaValidate(
                    CJCommunicationAvailabilityConditionTimePickerValidationSchema(
                        'StartTime',
                        result.value.endTime?.value,
                    ),
                    { value: result.value.startTime?.value },
                    (error) => {
                        errorText = getCustomerJourneyFormFieldTranslatableErrorText(t, error?.message);
                    },
                );
                result.value.startTime.isValid = isValid;
                result.value.startTime.errorText = errorText;

                errorText = null;
                isValid = yupSchemaValidate(
                    CJCommunicationAvailabilityConditionTimePickerValidationSchema(
                        'EndTime',
                        result.value.startTime?.value,
                    ),
                    { value: result.value.endTime?.value },
                    (error) => {
                        errorText = getCustomerJourneyFormFieldTranslatableErrorText(t, error?.message);
                    },
                );

                result.value.endTime.isValid = isValid;
                result.value.endTime.errorText = errorText;
            }
            return result;
        });

        setFieldValue('schedule', scheduleRef.current);
    };

    const timeZoneChangeHandler = (selectedTimeZone) => {
        setFieldValue('timeZone', selectedTimeZone?.value);
    };

    const scheduleTypeChangeHandler = (selectedScheduleType) => {
        setFieldValue('scheduleType', selectedScheduleType?.value);
        setSchedule(CJCommunicationAvailabilityConditionConfig[selectedScheduleType?.value]);
    };

    const checkboxChangeHandler = (index) => {
        return () => {
            const enabledItems = [];
            const tmpSchedule = scheduleRef.current.map((item, itemIndex) => {
                const tmpItem = { ...item, isCheckboxEnable: true };
                if (index === itemIndex) {
                    tmpItem.isEnable = !tmpItem.isEnable;
                }
                if (tmpItem.isEnable) {
                    enabledItems.push(itemIndex);
                }
                return tmpItem;
            });

            if (enabledItems.length === 1) {
                tmpSchedule[first(enabledItems)].isCheckboxEnable = false;
            }

            setSchedule(tmpSchedule);
        };
    };

    const timeChangeHandler = (index, key) => {
        return (e) => {
            const tmpSchedule = [...scheduleRef.current];

            tmpSchedule[index] = {
                ...tmpSchedule[index],
                value: {
                    ...tmpSchedule[index].value,
                    [key]: { value: e?.target?.value },
                },
            };
            setSchedule(tmpSchedule);
        };
    };

    return (
        <div className="crm-communication-availability">
            <div className="crm-communication-availability-dropdown-container">
                <Dropdown
                    flexibility="full-width"
                    hasSearch={true}
                    label={t(l.CommunicationAvailabilityScheduleType)}
                    placeholder={t(l.CommunicationAvailabilityScheduleType)}
                    labelAppearance="swap"
                    onChange={scheduleTypeChangeHandler}
                    value={scheduleType}
                    appearance="outline"
                    data={scheduleTypeData}
                    noDataText={t(l.NoDataFound)}
                />

                {scheduleType !== CommunicationAvailabilityScheduleTypeLabels.Always && (
                    <Dropdown
                        flexibility="full-width"
                        hasSearch={true}
                        label={t(l.TimeZone)}
                        placeholder={t(l.TimeZone)}
                        labelAppearance="swap"
                        onChange={timeZoneChangeHandler}
                        value={timeZone}
                        appearance="outline"
                        data={timeZonePackData}
                        noDataText={t(l.NoDataFound)}
                    />
                )}
            </div>
            <div className="crm-communication-availability-schedule-container">
                {schedule.map((item, index) => {
                    return (
                        <div key={`${item.label}_${index}`} className="crm-communication-availability-schedule-item">
                            <div className="crm-communication-availability-schedule-item-label-container">
                                <Label>{t(item.label)}</Label>
                                {item.withCheckbox && (
                                    <Checkbox
                                        checked={item.isEnable}
                                        onChange={checkboxChangeHandler(index)}
                                        disabled={!item.isCheckboxEnable}
                                    />
                                )}
                            </div>
                            <div className="crm-communication-availability-schedule-item-time-container">
                                <Tooltip text={t(l.CommunicationAvailabilityStartTime)} position="bottom">
                                    <TimePicker
                                        isValid={item.value.startTime.isValid}
                                        errorText={item.value.startTime.errorText}
                                        appearance="outline"
                                        showSeconds={false}
                                        minuteFormat="mm"
                                        hourFormat="HH"
                                        onChange={timeChangeHandler(index, 'startTime')}
                                        disabled={!item.isEnable}
                                        value={item.value.startTime?.value}
                                    />
                                </Tooltip>
                                <Icon type="bc-icon-minus" />
                                <Tooltip text={t(l.CommunicationAvailabilityEndTime)} position="bottom">
                                    <TimePicker
                                        isValid={item.value.endTime.isValid}
                                        errorText={item.value.endTime.errorText}
                                        appearance="outline"
                                        showSeconds={false}
                                        minuteFormat="mm"
                                        hourFormat="HH"
                                        onChange={timeChangeHandler(index, 'endTime')}
                                        disabled={!item.isEnable}
                                        value={item.value.endTime?.value}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

CJCommunicationAvailabilityCondition.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJCommunicationAvailabilityCondition.defaultProps = {};

export default memo(CJCommunicationAvailabilityCondition);
