/* eslint-disable react/jsx-no-bind */
import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import components
import { ResetSave } from 'components';

const PageHeadlineActionBar = ({
    hasAdditionalSettings,
    handleSave,
    handleReset,
    disableSave,
    disableReset,
    setSaveButtonLabel,
    buttonWithDropDownConfig,
}) => {
    const modeRef = useRef({});

    const setMode = (mode) => {
        modeRef.current = mode;
        setSaveButtonLabel(mode);
    };

    function saveButtonClickHandler() {
        handleSave(modeRef.current);
    }

    function resetButtonClickHandler() {
        handleReset();
    }

    return (
        <div className="crm-page-headline_button-box">
            <ResetSave
                hasAdditionalSettings={hasAdditionalSettings}
                resetButtonClickHandler={resetButtonClickHandler}
                disableReset={disableReset}
                saveButtonClickHandler={saveButtonClickHandler}
                buttonWithDropDownConfig={buttonWithDropDownConfig}
                setMode={setMode}
                disableSave={disableSave}
            />
        </div>
    );
};

PageHeadlineActionBar.propTypes = {
    disableSave: PropTypes.bool,
    disableReset: PropTypes.bool,
    handleSave: PropTypes.func,
    handleReset: PropTypes.func,
    hasAdditionalSettings: PropTypes.bool,
    buttonWithDropDownConfig: PropTypes.object,
    setSaveButtonLabel: PropTypes.func.isRequired,
};

PageHeadlineActionBar.defaultProps = {
    disableSave: false, // TODO: change default state disable save
    disableReset: false,
    hasAdditionalSettings: false,
    buttonWithDropDownConfig: {},
    handleSave: noop,
    handleReset: noop,
};

export default memo(PageHeadlineActionBar);
