import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Components
import { TimeFormatWithoutSeconds, l } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMoment } = Helpers;

const CJDTTimeInfoBlock = ({ data }) => {
    const { t } = useTranslation();
    const { hours, minutes } = data?.value?.params;
    const date = customMoment().set('hour', hours).set('minute', minutes);

    return <CJInfoBlockLabelValueWrapper label={t(l.Time)} value={date.format(TimeFormatWithoutSeconds)} />;
};

CJDTTimeInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJDTTimeInfoBlock);
