import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CJTagValidationSchema } from 'validators/schemas.validation';
// Import UI Components
import { SkeletonLoader } from '@geneui/components';
// Import Components
import { InputWithDropDown } from 'components';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Hooks
import { useMenuItem } from 'hooks';

const CJTag = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const { CustomAttributes } = option;
    const { MenuItemId } = CustomAttributes;
    const { convertedData, isLoading } = useMenuItem(MenuItemId);
    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
        },
        onSubmit: noop,
        validationSchema: CJTagValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm } = formik;
    const { value } = values;

    const onChangeHandler = (value) => {
        setFieldValue('value', value);
        getUpdate({ value });
    };

    useEffect(() => {
        submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="crm-cj-money-cmp">
            <SkeletonLoader isBusy={isLoading}>
                {!isLoading && (
                    <InputWithDropDown
                        placeholder={t(CustomAttributes?.DisplayName)}
                        label={t(CustomAttributes?.DisplayName)}
                        isValid={!errors.value}
                        errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
                        defaultValue={value}
                        changeHandler={onChangeHandler}
                        data={convertedData}
                        labelAppearance="swap"
                    />
                )}
            </SkeletonLoader>
        </div>
    );
};

CJTag.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJTag.defaultProps = {
    defaultValue: { value: '' },
};

export default memo(CJTag);
