/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import Components
import { PageHeadline } from 'components';
import CommonTemplate from './CommonTemplate';
// Import Hooks
import { useRequest, useToaster, useQueryParams, useGoToRoute } from 'hooks';
// Import Services
import { createTemplateResultData, TemplateType, getTemplateValidationTranslatableErrorText } from 'services/template';
// Import Actions
import { TemplateActions, PageValidationAction } from 'actions';
// Import Constants
import { AlertTypes, l } from 'constants/common';
import { RoutesList } from 'routes';
// Import SCSS
import 'assets/scss/commonTemplateWrapper.scss';

const { clearTemplateList } = TemplateActions;
const { success } = AlertTypes;

const { clearPageValidation } = PageValidationAction;

const CommonTemplateWrapper = ({
    editOrCreateRequest,
    resetValidationScenario,
    titleKey,
    nameAndDescriptionDefaultValue,
    isLoading,
    templateType,
    addValidationProperties,
    removeValidationProperties,
    maxSMSPartsCount,
    shortenerAndTrackerSettings,
    getAssets,
    addAsset,
}) => {
    const { t } = useTranslation();
    const { goToRoute } = useGoToRoute();

    const { showToaster } = useToaster();
    const { doPostRequest } = useRequest();
    const dispatch = useDispatch();
    const templateData = useSelector((state) => state.template);
    const [tabActiveKey, setTabActiveKey] = useState('0');

    const queryParams = useQueryParams('notification');
    const decodedQueryParams = queryParams.decode();
    const eventId = decodedQueryParams?.eventId;
    const selectedNotificationChannel = decodedQueryParams?.selectedChannel;

    useEffect(cleanUp, []);

    function saveHandler(name, description) {
        return doPostRequest(editOrCreateRequest, {
            requestBody: createTemplateResultData(name, description, templateData, templateType, eventId),
            successCallback: () => showToaster(success, t(l.TemplateWasSuccessfullySaved)),
        }).then((data) => {
            if (!isNil(eventId)) {
                return {
                    ...(data ?? {}),
                    customRoute: () => {
                        goToRoute(
                            RoutesList.NOTIFICATION,
                            { eventId: eventId },
                            queryParams.encode({ selectedChannel: selectedNotificationChannel }),
                        );
                    },
                };
            }
            return data;
        });
    }
    function cleanUp() {
        return () => {
            resetHandler();
            dispatch(clearPageValidation());
        };
    }

    function resetHandler() {
        dispatch(clearTemplateList({ templateType }));
        resetValidationScenario();
        setTabActiveKey(0);
    }
    return (
        <div className="crm-create-template-page-wrapper-container sms-template-wrap">
            <PageHeadline
                titleKey={titleKey}
                handleSave={saveHandler}
                handleReset={resetHandler}
                defaultName={nameAndDescriptionDefaultValue?.Name}
                defaultDescription={nameAndDescriptionDefaultValue?.Description}
                getCustomValidationTypeErrorText={getTemplateValidationTranslatableErrorText(t)}
                isLoading={isLoading}
                withWarningsAlertContent={true}
            />
            <CommonTemplate
                addValidationProperties={addValidationProperties}
                removeValidationProperties={removeValidationProperties}
                maxSMSPartsCount={maxSMSPartsCount}
                isLoading={isLoading}
                tabActiveKey={tabActiveKey}
                setTabActiveKey={setTabActiveKey}
                templateType={templateType}
                getAssets={getAssets}
                addAsset={addAsset}
                eventId={eventId}
                shortenerAndTrackerSettings={shortenerAndTrackerSettings}
            />
        </div>
    );
};

CommonTemplateWrapper.propTypes = {
    titleKey: PropTypes.string.isRequired,
    editOrCreateRequest: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    templateType: PropTypes.string,
    nameAndDescriptionDefaultValue: PropTypes.object,
    addValidationProperties: PropTypes.func,
    removeValidationProperties: PropTypes.func,
    resetValidationScenario: PropTypes.func,
    maxSMSPartsCount: PropTypes.number,
    getAssets: PropTypes.func,
    addAsset: PropTypes.func,
    shortenerAndTrackerSettings: PropTypes.array,
};

CommonTemplateWrapper.defaultProps = {
    isLoading: false,
    templateType: TemplateType.SMS,
    addValidationProperties: noop,
    removeValidationProperties: noop,
    resetValidationScenario: noop,
    getAssets: noop,
    addAsset: noop,
    shortenerAndTrackerSettings: [],
};

export default CommonTemplateWrapper;
