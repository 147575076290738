import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import Hooks
import { useGACustomEvent, useRequest } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
import { Helpers } from 'services';
import { getStartDate, getEndDate } from 'services/dashboard';
// Import UI Components
import { DatePickerInput, BusyLoader, BarChart, Empty, ModuleTitle } from '@geneui/components';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';

let getDashboardClientActivityObj;
const { customMomentWithoutTimezoneConversion } = Helpers;
const { GADateFilterCategory } = GACategoryEvents;

const DailyActiveCustomersByProductChart = ({ parentDate }) => {
    const { doGetRequest } = useRequest();
    const { t } = useTranslation();
    const { logCustomEvent } = useGACustomEvent();
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardClientActivityData, setDashboardClientActivity] = useState(null);
    const [date, setDate] = useState(parentDate);

    const { getDashboardClientActivityRequest } = useRef({
        getDashboardClientActivityRequest: DashboardHttpService.getDashboardClientActivity,
    }).current;

    const getDashboardClientActivity = (date) => {
        setIsLoading(true);
        if (!isNil(getDashboardClientActivityObj)) {
            getDashboardClientActivityObj.cancel(
                'DailyActiveCustomersByProductChart:getDashboardClientActivityRequest',
            );
        }

        getDashboardClientActivityObj = getDashboardClientActivityRequest();

        doGetRequest(getDashboardClientActivityObj.request, {
            queryString: { startDate: date[0], endDate: date[1] },
            successCallback: ({ StackBarChartData, SportEventsSeries }) => {
                let categories = [];
                let CasinoClientCounts = [];
                let ClientCounts = [];
                let SportClientCounts = [];
                StackBarChartData.forEach((element) => {
                    CasinoClientCounts.push(element['CasinoClientCount']);
                    ClientCounts.push(element['ClientCount']);
                    SportClientCounts.push(element['SportClientCount']);
                    categories.push(customMomentWithoutTimezoneConversion(element['Date']).format('MMM DD'));
                });
                setDashboardClientActivity({
                    series: [
                        {
                            name: SportEventsSeries.find((r) => r.field === 'CasinoClientCount').name,
                            data: CasinoClientCounts,
                        },
                        {
                            name: SportEventsSeries.find((r) => r.field === 'ClientCount').name,
                            data: ClientCounts,
                        },
                        {
                            name: SportEventsSeries.find((r) => r.field === 'SportClientCount').name,
                            data: SportClientCounts,
                        },
                    ],
                    categories: categories,
                });
            },
        }).then(() => setIsLoading(false));
    };

    const cleanUp = () => {
        return () => {
            getDashboardClientActivityObj.cancel('WidgetChooser:getDashboardClientActivityRequest');
        };
    };

    const getDate = (date) => [getStartDate(date[0]), getEndDate(date[1])];

    useEffect(cleanUp, []);

    const handleDatePickerChange = (e) => {
        const date = getDate(e);

        logCustomEvent(
            GADateFilterCategory.name,
            GADateFilterCategory.events.activeCustomersByProductCategories,
            'active customers by product categories metrics',
            `${date[0]} - ${date[1]}`,
        );
        setDate(date);
    };

    useEffect(() => {
        getDashboardClientActivity(date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date[0], date[1]]);

    useEffect(() => {
        setDate(getDate(parentDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentDate]);

    return (
        <>
            <div className="charts-wrap-inner-head">
                <ModuleTitle title={t(l.DailyActiveCustomersByProductCategories)} />

                <div className="campaign-metrics-wrap-c-h-d">
                    <DatePickerInput.WithRange
                        onChange={handleDatePickerChange}
                        label={t(l.TimePeriod)}
                        labelAppearance="swap"
                        value={date}
                    />
                </div>
            </div>
            {isLoading ? (
                <BusyLoader spinnerSize="big" isBusy={true} />
            ) : isEmpty(dashboardClientActivityData?.categories) ? (
                <Empty title={t(l.NoDataToDisplay)} />
            ) : (
                <div className="charts-wrap-inner-content">
                    <BarChart
                        series={dashboardClientActivityData?.series ?? []}
                        categories={dashboardClientActivityData?.categories ?? []}
                        plotOptions={{
                            column: {
                                stacking: 'normal',
                            },
                        }}
                    />
                </div>
            )}
        </>
    );
};

DailyActiveCustomersByProductChart.propTypes = {
    parentDate: PropTypes.array.isRequired,
};

export default DailyActiveCustomersByProductChart;
