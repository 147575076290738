import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import classNames from 'classnames';
// Import UI components
import { Button } from '@geneui/components';
// Import components
import Row from './Row';

const ParentRow = ({
    isChecked,
    childrenList,
    id,
    name,
    onRowClick,
    onClick,
    searchInput,
    hasInfoTooltip,
    withSelect,
}) => {
    const [isChildrenVisible, setIsChildrenVisible] = useState(false);

    const toggleRowChildrenClickHandler = () => {
        setIsChildrenVisible(!isChildrenVisible);
    };

    useEffect(() => {
        setIsChildrenVisible(!isNil(searchInput) && !isEmpty(searchInput));
    }, [searchInput]);

    return (
        <>
            <Button
                appearance="minimal"
                className={classNames('tween-block-drpd-arrow', { activated: isChildrenVisible })}
                icon="bc-icon-arrow-right"
                onClick={toggleRowChildrenClickHandler}
            />
            <Row
                onClick={onClick}
                name={name}
                isChecked={isChecked}
                key={`${id}_parent`}
                isDraggable={false}
                hasInfoTooltip={false}
                withSelect={withSelect}
            />
            <div className="d-a-d-child-wrapper">
                {isChildrenVisible &&
                    childrenList.map((item, index) => {
                        if (!item.isVisible) {
                            return <></>;
                        }
                        return (
                            <Row
                                key={`${item.id}_${index}`}
                                {...item}
                                onClick={onRowClick(index)}
                                isDraggable={false}
                                hasInfoTooltip={hasInfoTooltip}
                                withSelect={withSelect}
                            />
                        );
                    })}
            </div>
        </>
    );
};

ParentRow.propTypes = {
    isChecked: PropTypes.bool,
    childrenList: PropTypes.array,
    name: PropTypes.string,
    onRowClick: PropTypes.func,
    onClick: PropTypes.func,
    id: PropTypes.string,
    searchInput: PropTypes.string,
    hasInfoTooltip: PropTypes.bool.isRequired,
    withSelect: PropTypes.bool,
};
ParentRow.defaultProps = {
    withSelect: true,
};

export default ParentRow;
