import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { noop, has, isString, cloneDeep } from 'lodash';
// Import UI Components
import { DatePickerInput, Icon, Popover } from '@geneui/components';
// Import Services
import { FilterHolderComponentsTypes } from './service';
// Import Hooks
import { useHasPermission } from 'hooks';
// Import Constants
import { DateTimeFormat, DateFormat, DateToISOStringFormat } from 'constants/common';
// Import Configs
import { comparisonIconsTypes } from '../../DynamicSegment/config';

const FilterFields = ({ filterInputsData, handleBlur, handleChange, withComparisons, handleComparisonChange }) => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const [openedComparisonPopoverIndex, setOpenedComparisonPopoverIndex] = useState();

    const inputChangeHandler = (changeInputValue, customChange, index, filterInputData) => {
        return function (e) {
            handleChange(e, changeInputValue, customChange, index, filterInputData);
        };
    };

    const getEndDate = (dateValue) => {
        return moment.utc(dateValue).startOf('day').format(DateToISOStringFormat);
    };

    // eslint-disable-next-line react/prop-types
    const getFilter = (filterInputData, i) => {
        const { type, displayName, customChange, valueFieldKey, props: inputProps, ...rest } = filterInputData;
        const { Component, changeInputValue, props } = FilterHolderComponentsTypes[type];
        const dynamicProps = {};
        if (type === FilterHolderComponentsTypes.rangeDate.keyName && isString(rest.value[1])) {
            const tmpValue = cloneDeep(rest.value);
            tmpValue[1] = getEndDate(tmpValue[1]);
            dynamicProps.value = tmpValue;
        }

        return type === FilterHolderComponentsTypes.rangeDate.keyName ? (
            <DatePickerInput.WithRange
                key={valueFieldKey}
                placeholder={t(displayName)}
                label={t(displayName)}
                onChange={inputChangeHandler(changeInputValue, customChange, i, filterInputData)}
                onBlur={handleBlur}
                format={rest.withTime ? DateTimeFormat : DateFormat}
                {...rest}
                {...dynamicProps}
                {...inputProps}
            />
        ) : (
            <Component
                key={valueFieldKey}
                placeholder={t(displayName)}
                onChange={inputChangeHandler(changeInputValue, customChange, i)}
                onBlur={handleBlur}
                {...rest}
                {...props}
                {...inputProps}
            />
        );
    };

    const popoverToggleHandler = (index) => {
        setOpenedComparisonPopoverIndex((prev) => {
            if (prev === index) {
                return null;
            } else {
                return index;
            }
        });
    };

    const handleComparisonOptionSelection = (comparison, index) => {
        setOpenedComparisonPopoverIndex(null);
        handleComparisonChange(comparison, index);
    };

    return (
        <>
            {filterInputsData.map((filterInputData, i) => {
                if (has(filterInputData, 'permission')) {
                    if (hasPermission(filterInputData.permission)) {
                        return getFilter(filterInputData, i);
                    } else {
                        return <> </>;
                    }
                } else {
                    const ComparisonIcon = comparisonIconsTypes[filterInputData.comparision];
                    return (
                        <>
                            {!withComparisons ? (
                                getFilter(filterInputData, i)
                            ) : (
                                <div className="crm-filter-field-comparison-popover-container">
                                    <Popover
                                        toggleHandler={() => popoverToggleHandler(i)}
                                        isOpen={i === openedComparisonPopoverIndex}
                                        extendTargetWidth={false}
                                        minHeight={277}
                                        align="center"
                                        disableReposition={false}
                                        scrollbarNeeded={false}
                                        screenType="desktop"
                                        Content={filterInputData.ComparisionUIs.map((comparision, index) => {
                                            return (
                                                <li
                                                    key={`${comparision.Comparision}_${comparision.DisplayName}_${index}`}
                                                    className={`crm-flex crm-align-items-center crm-dynamic-segment-list-item`}
                                                    onClick={() => handleComparisonOptionSelection(comparision, i)}
                                                    role="presentation"
                                                >
                                                    <div className="popover-content-pair">
                                                        <Icon
                                                            className="crm-dynamic-segment-list-item-icon"
                                                            key={comparision.Comparision}
                                                            type={comparisonIconsTypes[comparision.Comparision]}
                                                        />
                                                        <h6>{comparision.DisplayName}</h6>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    >
                                        <Icon
                                            className="crm-dynamic-segment-list-item-icon client-filter-icon"
                                            type={ComparisonIcon}
                                        />
                                    </Popover>
                                    {getFilter(filterInputData, i)}
                                </div>
                            )}
                        </>
                    );
                }
            })}
        </>
    );
};

const isRequiredDataField = ({ type, data }) => {
    return (
        (type === FilterHolderComponentsTypes.select.keyName && data) ||
        type !== FilterHolderComponentsTypes.select.keyName
    );
};

FilterFields.propTypes = {
    filterInputsData: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            valueFieldKey: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            value: PropTypes.any,
            data: isRequiredDataField,
        }),
    ).isRequired,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    withComparisons: PropTypes.bool,
    handleComparisonChange: PropTypes.func,
};

FilterFields.defaultProps = {
    handleBlur: noop,
    handleChange: noop,
    withComparisons: false,
};

export default FilterFields;
