import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Components
import Navigation from './Navigation';
import Create from './Create';
import Partners from './Partners';
import FTNRate from './FTNRate';
import MaintenanceInfo from './MaintenanceInfo';
import UserTime from './UserTime';
// import Products from './Products'; TODO usage is commented tmp
import UserProfile from './UserProfile';
// Import UI Components
import { Button, Popover, Image, SkeletonLoader, Divider } from '@geneui/components';
// Import Actions
import { AuthAction, HeaderAction, SignOutAction, UserDataAction } from 'actions';
// Import Services
import { AuthHttpService } from 'services/http';
import { publish } from 'services/event';
// Import Images
import logoImgPath from 'assets/images/logo.svg';
// Import Hooks
import {
    useLocalization,
    useGoToRoute,
    useOnClickOutside,
    useHasPermission,
    useMaintenanceSettings,
    useGACustomEvent,
} from 'hooks';
import { GlobalSearch } from 'components';
// Import Constants
import { NavigationList } from './constants';
import {
    l,
    ViewThemeTypes,
    ViewModeTypes,
    GlobalEvents,
    PERMISSIONS,
    GACategoryEvents,
    AuthProvidersTypes,
} from 'constants/common';
import { BrowserStorageKeys } from 'constants/browserStorage';
// Import SCSS
import 'assets/scss/header.scss';

const { GACreateCategory } = GACategoryEvents;

const Header = ({ setIsLoading, isLoading }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { goToRoute } = useGoToRoute();
    const [setLocalization] = useLocalization();
    const { hasPermission } = useHasPermission();
    const { startDate, endDate, hasUpcomingMaintenance, isUnderMaintenance } = useMaintenanceSettings();

    const { doLogout } = AuthAction;
    const {
        getSettingsFetch,
        getSettingsSucceeded,
        getSettingsFailed,
        setNavigation,
        setSettingsFetch,
        setSettingsFailed,
        setApplicationModes,
        setLangId,
    } = HeaderAction;
    const { signOutFetch, signOutSucceeded, signOutFailed } = SignOutAction;
    const { clearUserData, setLanguage } = UserDataAction;

    const { settings } = useSelector((state) => state.header);
    const { Settings } = settings;
    const { FirstName, UserSession } = useSelector((state) => state.userData);
    const { displayNameKey: activeNavigationName, route: path } = useSelector((state) => state.header.navigation);
    const { logCustomEvent } = useGACustomEvent();

    const createRef = useRef(null);
    const productsRef = useRef(null);
    const navigationRef = useRef(null);
    const userProfileRef = useRef(null);

    const [navigationVisibilityStatus, setNavigationVisibilityStatus] = useState(false);
    const [createVisibilityStatus, setCreateVisibilityStatus] = useState(false);
    const [partnersVisibilityStatus, setPartnersVisibilityStatus] = useState(false);
    const [productsVisibilityStatus, setProductsVisibilityStatus] = useState(false);
    const [userProfileVisibilityStatus, setUserProfileVisibilityStatus] = useState(false);
    const [isVisibleUserProfileSettingsModal, setIsVisibleUserProfileSettingsModal] = useState(false);
    const [isVisiblePredefinedInfoModal, setIsVisiblePredefinedInfoModal] = useState(false);

    let navigationList = cloneDeep(NavigationList);

    const signOutHandler = () => {
        dispatch(signOutFetch());

        AuthHttpService.logout()
            .then((response) => {
                if (!response.HasError) {
                    if (UserSession?.ProviderId === AuthProvidersTypes.Password) {
                        localStorage.setItem(BrowserStorageKeys.isCRMSignIn, true);
                    } else {
                        localStorage.removeItem(BrowserStorageKeys.isCRMSignIn);
                    }

                    dispatch(signOutSucceeded());
                    dispatch(doLogout());
                    dispatch(clearUserData());
                } else {
                    dispatch(signOutFailed(response.AlertMessage));
                }
            })
            .catch((error) => {
                dispatch(signOutFailed(error));
            });
    };

    const changeLanguageHandler = (langId) => {
        AuthHttpService.saveLanguageSetting(langId)
            .then((response) => {
                const { HasError, AlertMessage } = response;
                if (!HasError) {
                    setLocalization(langId);
                    dispatch(setLanguage(langId));
                    dispatch(setLangId(langId));
                } else {
                    console.log(AlertMessage);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const saveSettings = (settings, isOnlyModeChanged) => {
        dispatch(setSettingsFetch());
        if (!isOnlyModeChanged) setIsLoading(true);

        return AuthHttpService.updateSettings(settings)
            .then((response) => {
                const { HasError, AlertMessage } = response;

                if (!HasError) {
                    if (isOnlyModeChanged) {
                        const { ViewMode, Appearance } = settings;
                        dispatch(setApplicationModes({ ViewMode, Appearance }));
                    } else {
                        goToRoute({ path });
                    }
                    setUserProfileVisibilityStatus(false);
                } else {
                    dispatch(setSettingsFailed(AlertMessage));
                }
                publish(GlobalEvents.UserSettingsChange, settings);
            })
            .catch((error) => {
                dispatch(setSettingsFailed(error));
                if (!isOnlyModeChanged) setIsLoading(false);
            });
    };

    useOnClickOutside(
        createRef,
        useCallback(() => {
            if (createVisibilityStatus) {
                setCreateVisibilityStatus(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [createVisibilityStatus]),
        isVisiblePredefinedInfoModal,
    );

    useOnClickOutside(
        productsRef,
        useCallback(() => {
            if (productsVisibilityStatus) {
                setProductsVisibilityStatus(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [productsVisibilityStatus]),
    );

    useOnClickOutside(
        userProfileRef,
        useCallback(() => {
            if (userProfileVisibilityStatus) {
                setUserProfileVisibilityStatus(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [userProfileVisibilityStatus]),
        isVisibleUserProfileSettingsModal,
    );

    useOnClickOutside(
        navigationRef,
        useCallback(() => {
            if (navigationVisibilityStatus) {
                setNavigationVisibilityStatus(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [navigationVisibilityStatus]),
    );
    const getPartnersSettings = () => {
        return AuthHttpService.getPartners().then(({ Data, HasError, Message }) => {
            if (HasError) {
                throw new Error(Message);
            }

            return Data;
        });
    };

    const getCurrenciesSettings = () => {
        return AuthHttpService.getCurrencies().then(({ Data, HasError, Message }) => {
            if (HasError) {
                throw new Error(Message);
            }

            return Data;
        });
    };

    const init = () => {
        dispatch(getSettingsFetch());

        Promise.all([getPartnersSettings(), getCurrenciesSettings()])
            .then(([partners, currencies]) => {
                dispatch(getSettingsSucceeded({ partners, currencies }));
            })
            .catch((error) => dispatch(getSettingsFailed(error)));
    };

    useEffect(init, []);

    const handleCreate = () => {
        logCustomEvent(GACreateCategory.name, GACreateCategory.events.navigationCreateButton, 'Open create popup', 1);
        setCreateVisibilityStatus(!createVisibilityStatus);
    };

    return (
        <div className="crm-header">
            <Helmet>
                <body
                    className={`${ViewModeTypes[Settings.ViewMode]} ${
                        ViewThemeTypes[Settings.Appearance] || 'view-theme-light'
                    }`}
                ></body>
            </Helmet>
            <div className="crm-header-column">
                <Image src={logoImgPath} className="logo" />

                <Divider type="vertical" />

                <div className="navigation-wrap" ref={navigationRef}>
                    <Popover
                        isOpen={navigationVisibilityStatus}
                        extendTargetWidth={false}
                        className="navigation-popover-content"
                        maxHeight={352}
                        height={352}
                        scrollbarNeeded={false}
                    >
                        <div className={'navigation-button-wrap'}>
                            <Button
                                onClick={() => setNavigationVisibilityStatus(!navigationVisibilityStatus)}
                                flexibility="content-size"
                                className="h-btn-dash"
                                icon="bc-icon-arrow-down"
                            >
                                {t(activeNavigationName)}
                            </Button>
                        </div>
                        {navigationVisibilityStatus ? (
                            <Navigation
                                setNavigationVisibilityStatus={setNavigationVisibilityStatus}
                                setNavigation={setNavigation}
                                navigationList={navigationList}
                            />
                        ) : (
                            <></>
                        )}
                    </Popover>
                </div>

                <Divider type="vertical" />

                <GlobalSearch />
            </div>
            <div className="crm-header-column">
                {(hasUpcomingMaintenance || (isUnderMaintenance && hasPermission([PERMISSIONS.CRMAdmin]))) && (
                    <>
                        <MaintenanceInfo
                            startDate={startDate}
                            endDate={endDate}
                            isUnderMaintenance={isUnderMaintenance}
                        />
                        <Divider type="vertical" />
                    </>
                )}

                <FTNRate />

                <Divider type="vertical" />

                <Popover
                    className="create-menu-popover"
                    isOpen={createVisibilityStatus}
                    extendTargetWidth={false}
                    minHeight={277}
                    align="center"
                    scrollbarNeeded={false}
                >
                    <div className="create-wrap" ref={createRef}>
                        <Button
                            className="create-btn"
                            icon={createVisibilityStatus ? 'bc-icon-clear' : 'bc-icon-plus-crm'}
                            appearance="outline"
                            active={createVisibilityStatus}
                            onClick={() => handleCreate()}
                        >
                            {t(l.Create)}
                        </Button>
                        {createVisibilityStatus ? (
                            <Create
                                predefinedInfoModalVisibleState={isVisiblePredefinedInfoModal}
                                setPredefinedInfoModalVisibleState={setIsVisiblePredefinedInfoModal}
                                setCreateVisibilityStatus={setCreateVisibilityStatus}
                                setNavigation={setNavigation}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Popover>

                <Divider type="vertical" />

                <Partners
                    setIsLoading={setIsLoading}
                    setPartnersVisibilityStatus={setPartnersVisibilityStatus}
                    isVisible={partnersVisibilityStatus}
                    toggleHandler={() => setPartnersVisibilityStatus(!partnersVisibilityStatus)}
                />

                <Divider type="vertical" />

                <SkeletonLoader isBusy={isLoading} height={'20px'} width={'55px'} circle>
                    <div className="time-holder">{!isLoading && <UserTime />}</div>
                </SkeletonLoader>

                <Divider type="vertical" />

                {/* <div className="products-wrap" ref={productsRef}> TODO: need products URLs and icons
                        <Popover
                            isOpen={productsVisibilityStatus}
                            extendTargetWidth={false}
                            minHeight={277}
                            align="center"
                            scrollbarNeeded={false}
                            behave="toggle"
                        >
                            <Tooltip style={{ marginTop: '11px' }} text="Products" position="bottom">
                                <Button
                                    onClick={() => setProductsVisibilityStatus(!productsVisibilityStatus)}
                                    className="transparent-btn"
                                    icon="bc-icon-apps"
                                />
                            </Tooltip>
                            {productsVisibilityStatus ? <Products /> : <></>}
                        </Popover>
                    </div> */}

                {/* <Tooltip style={{ marginTop: '11px' }} text={t(l.Help)} position="bottom"> TODO waiting for help page
                        <Button className="transparent-btn" icon="bc-icon-help" />
                    </Tooltip> */}

                <div className="profile-wrap" ref={userProfileRef}>
                    <Popover
                        isOpen={userProfileVisibilityStatus}
                        extendTargetWidth={false}
                        minHeight={277}
                        align="end"
                        scrollbarNeeded={false}
                    >
                        <Button
                            onClick={() => setUserProfileVisibilityStatus(!userProfileVisibilityStatus)}
                            className="user-btn"
                            icon="bc-icon-user"
                        >
                            {FirstName}
                        </Button>
                        {userProfileVisibilityStatus ? (
                            <UserProfile
                                settingsModalVisibleState={isVisibleUserProfileSettingsModal}
                                setSettingsModalVisibleState={setIsVisibleUserProfileSettingsModal}
                                signOutHandler={signOutHandler}
                                saveSettings={saveSettings}
                                changeLanguageHandler={changeLanguageHandler}
                            />
                        ) : (
                            <></>
                        )}
                    </Popover>
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    setIsLoading: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default memo(Header);
