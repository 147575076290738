import React, { memo, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Button } from '@geneui/components';
// Import Components
import { DataTable } from 'components';
import { AdminProviderActionsButtons, AdminProviderActionsModals } from 'components/AdminProviderList';
// Import Hooks
import { useListBaseRequestBody, useToaster, useRequest } from 'hooks';
// Import Services
import { AdministrationHttpService } from 'services/http';
// Import Configs
import { DataTableColumnsConfigs } from './config';
// Import Constants
import { l } from 'constants/common';

// eslint-disable-next-line react/display-name
const AdminProviderList = forwardRef(({ tableKey, listAction, listSelectorKey }, ref) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { showToaster } = useToaster();
    const { doPostRequest } = useRequest();
    const { tableData } = useSelector((state) => state[listSelectorKey]);

    const { filters, paging, sorting } = tableData;

    const [
        baseRequestBody,
        // eslint-disable-next-line no-unused-vars
        _newFilterField,
        setBaseRequestBodyFilters,
        setPagingPageNumber,
        setPagingPageSize,
        setSorting,
    ] = useListBaseRequestBody(filters, paging, sorting);

    const {
        resetAdminProviderState,
        setAdminProviderTablePageNumber,
        setAdminProviderTablePageSize,
        setAdminProviderTableSorting,
    } = listAction;

    const [isProviderListLoading, setIsProviderListLoading] = useState(false);
    const [providerListFilters, setProviderListFilters] = useState(baseRequestBody);

    const [providerListData, setProviderListData] = useState([]);
    const [providerListDataCount, setProviderListDataCount] = useState(0);

    const [isEditModalOpenedState, setIsEditModalOpenedState] = useState(false);
    const [isCreateModalOpenedState, setIsCreateModalOpenedState] = useState(false);
    const [isCloneModalOpenedState, setIsCloneModalOpenedState] = useState(false);
    const [openedModalData, setOpenedModalData] = useState({});

    const { columns } = DataTableColumnsConfigs(t);
    const isInit = useRef(false);
    const dataTableRef = useRef();

    const createProviderAction = (providerListData) => {
        setOpenedModalData(providerListData);
        setIsCreateModalOpenedState(true);
    };

    const getHeaderActions = () => {
        return (
            <Button icon="bc-icon-profit" onClick={() => createProviderAction(providerListData)}>
                {t(l.CreateProvider)}
            </Button>
        );
    };

    const { getEmailProviderListRequest } = useMemo(
        () => ({
            getEmailProviderListRequest: AdministrationHttpService.getEmailProviderList(),
        }),
        [],
    );

    const getProviderList = useCallback(
        (filter = providerListFilters) => {
            setIsProviderListLoading(true);
            doPostRequest(getEmailProviderListRequest.request, {
                requestBody: filter,
                successCallback: ({ Data, Count }) => {
                    const rows = getTableRows(Data || []);
                    setProviderListData(rows);
                    setProviderListDataCount(Count);
                },
            }).then(() => setIsProviderListLoading(false));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [providerListFilters],
    );

    const editProviderAction = (provider) => {
        setOpenedModalData(provider);
        setIsEditModalOpenedState(true);
    };

    const cloneProviderAction = (provider) => {
        setOpenedModalData(provider);
        setIsCloneModalOpenedState(true);
    };

    const modalsStateSetDict = {
        createProvider: {
            key: 'createProvider',
            fn: setIsCreateModalOpenedState,
        },
        editProvider: {
            key: 'editProvider',
            fn: setIsEditModalOpenedState,
        },
        cloneProvider: {
            key: 'cloneProvider',
            fn: setIsCloneModalOpenedState,
        },
    };

    const getRowActionBar = (row) => {
        const { data } = row;

        return (
            <>
                <AdminProviderActionsButtons
                    data={data}
                    editProviderAction={editProviderAction}
                    cloneProviderAction={cloneProviderAction}
                />
            </>
        );
    };

    // Data table part
    const setPagingPageNumberHandler = (pageNumber) => {
        setPagingPageNumber(pageNumber);
        dispatch(setAdminProviderTablePageNumber(pageNumber));
        setProviderListFilters(baseRequestBody);
    };

    const setPagingPageSizeHandler = (pageSize) => {
        setPagingPageSize(pageSize);
        dispatch(setAdminProviderTablePageSize(pageSize));
        setProviderListFilters(baseRequestBody);
    };

    const setSortingHandler = (sortingDirection, columnName) => {
        setSorting(sortingDirection, columnName);
        dispatch(setAdminProviderTableSorting(baseRequestBody.Sorting));
        setProviderListFilters(baseRequestBody);
    };

    const getTableColumns = () => {
        return columns;
    };

    const getTableRows = (providerList) => {
        return providerList.map(
            ({
                EmailProviderId,
                Name,
                Description,
                Host,
                Port,
                Type,
                UserName,
                FromAddress,
                PartnerEmail,
                MaxDegreeOfParallelism,
                DelayTimout,
                Partners,
                IsDefault,
                EnableSsl,
                NotInjectUnsubscribeLink,
            }) => {
                return {
                    hasHover: true,
                    dragDisable: true,
                    nestedData: [],
                    data: {
                        rowKey: EmailProviderId,
                        isRowOpend: false,
                        EmailProviderId,
                        Name,
                        Description,
                        Host,
                        Port,
                        Type,
                        UserName,
                        FromAddress,
                        PartnerEmail,
                        MaxDegreeOfParallelism,
                        DelayTimout,
                        Partners,
                        IsDefault,
                        EnableSsl,
                        NotInjectUnsubscribeLink,
                    },
                };
            },
        );
    };

    const getTableRefreshFn = () => {
        getProviderList(providerListFilters);
    };

    const onCloseModalHandler = ({ modalStateKey, alertType, alertMessage, isDoRefresh = false }) => {
        modalsStateSetDict[modalStateKey].fn(false);

        if (isDoRefresh) {
            showToaster(alertType, alertMessage);
            getTableRefreshFn();
        }
    };

    useImperativeHandle(ref, () => ({
        resetSelectedRows() {
            dataTableRef.current.resetSelectedRows();
        },
        reset() {
            dataTableRef.current.reset();
        },
    }));

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getEmailProviderListRequest.cancel('EmailProviderListPage:getEmailProviderListRequest');
            // Reset store state
            resetAdminProviderState && dispatch(resetAdminProviderState());
        };
    };

    useEffect(cleanUp, []);

    useEffect(() => {
        if (isInit.current) {
            getProviderList(providerListFilters);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [providerListFilters]);

    useEffect(() => {
        setBaseRequestBodyFilters(filters);
        setProviderListFilters(baseRequestBody);

        isInit.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <>
            <DataTable
                classNames="with-filter-tags-and-page-headline-height"
                name={l.EmailProviders}
                rowKey="rowKey"
                columnKey="dataKey"
                tableKey={tableKey}
                isColumnsSortable={true}
                data={providerListData}
                dataCount={providerListDataCount}
                rowActionBar={getRowActionBar}
                columns={getTableColumns()}
                isLoading={isProviderListLoading}
                isShowRefreshButton={true}
                isShowColumnChooser={true}
                setPagingPageSize={setPagingPageSizeHandler}
                setPagingPageNumber={setPagingPageNumberHandler}
                sorting={{
                    name: providerListFilters.Sorting?.Name,
                    direction: providerListFilters.Sorting?.Direction,
                }}
                setSorting={setSortingHandler}
                onRefreshClick={getTableRefreshFn}
                headerActions={getHeaderActions()}
                isHasArchiveActions={false}
                isHasUnarchiveActions={false}
                recevedCurrentPageNumber={providerListFilters.Pageing.PageNumber}
                withQueryParams={true}
                ref={dataTableRef}
            />
            <AdminProviderActionsModals
                isCreateModalOpenedState={isCreateModalOpenedState}
                isEditModalOpenedState={isEditModalOpenedState}
                isCloneModalOpenedState={isCloneModalOpenedState}
                onCloseModalHandler={onCloseModalHandler}
                modalsStateSetDict={modalsStateSetDict}
                openedModalData={openedModalData}
            />
        </>
    );
});

AdminProviderList.propTypes = {
    listSelectorKey: PropTypes.string.isRequired,
    listAction: PropTypes.shape({
        setAdminProviderTableFilters: PropTypes.func.isRequired,
        setAdminProviderTablePageNumber: PropTypes.func.isRequired,
        setAdminProviderTablePageSize: PropTypes.func.isRequired,
        setAdminProviderTableSorting: PropTypes.func.isRequired,
        resetAdminProviderState: PropTypes.func.isRequired,
    }).isRequired,
    tableKey: PropTypes.string,
};

AdminProviderList.defaultProps = {
    tableKey: 'adminProvidersDataTable',
};

export default memo(AdminProviderList);
