import { CurrencyRatesActionTypes as Types } from 'actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case Types.SET_CURRENCY_RATES:
            state = action.payload;
            return Object.assign({}, state);
        default:
            return state;
    }
};
