/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { noop, isEmpty, keys, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
// Import components
import { ValidatableDropdown, ButtonWithLoader } from 'components';
// Import constants
import { l } from 'constants/common';

const GeneralDetailsColumns = ({
    destinationColumns,
    isFileApplying,
    sourceColumns,
    columnChangehandler,
    handleGeneralDetailsApply,
}) => {
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: destinationColumns.reduce((acc, item) => {
            acc[item.DestinationColumn] = '';
            return acc;
        }, {}),
        onSubmit: noop,
        validationSchema: Yup.object(
            destinationColumns.reduce((acc, item) => {
                if (item.DestinationColumn === 'Email') {
                    acc[item.DestinationColumn] = Yup.string()
                        .required(t(l.CannotBeEmpty, { fieldName: item.DestinationColumn }))
                        .test(item.DestinationColumn, t(l.CantChooseTheSameColumnForOneProperty), function (value) {
                            return (
                                isNil(value) ||
                                !keys(this.parent).find(
                                    (key) => item.DestinationColumn !== key && this.parent[key] === value,
                                )
                            );
                        });
                    return acc;
                }
                acc[item.DestinationColumn] = Yup.string().test(
                    item.DestinationColumn,
                    t(l.CantChooseTheSameColumnForOneProperty),
                    function (value) {
                        return (
                            isNil(value) ||
                            !keys(this.parent).find(
                                (key) => item.DestinationColumn !== key && this.parent[key] === value,
                            )
                        );
                    },
                );

                return acc;
            }, {}),
        ),
    });

    const { values, errors, touched, setFieldValue, validateForm, submitForm, setFieldTouched } = formik;

    function changeHandler(index, filedName) {
        return (e) => {
            if (isNil(e)) {
                e = { value: '' };
            }
            const { value } = e;
            columnChangehandler(value, index);
            setFieldValue(filedName, value, true).then(() => setFieldTouched(filedName, true, true));
        };
    }

    function handleApply() {
        submitForm().then(() =>
            validateForm(values).then((formErrors) => {
                if (isEmpty(formErrors)) {
                    handleGeneralDetailsApply();
                }
            }),
        );
    }

    return (
        <div className="non-customer-inputs-group">
            {destinationColumns.map((column, i) => {
                return (
                    <div className="non-customer-input" key={i}>
                        <ValidatableDropdown
                            autocomplete="off"
                            onClear={changeHandler(i, column.DestinationColumn)}
                            clearable={true}
                            isMultiSelect={false}
                            inputSize="default"
                            placeholder={column.DestinationColumn}
                            labelAppearance="swap"
                            value={column.SourceColumn}
                            onChange={changeHandler(i, column.DestinationColumn)}
                            data={sourceColumns}
                            isValid={!(touched[column.DestinationColumn] && errors[column.DestinationColumn])}
                            errorText={touched[column.DestinationColumn] && errors[column.DestinationColumn]}
                        />
                    </div>
                );
            })}
            <ButtonWithLoader isLoading={isFileApplying} onClick={handleApply}>
                {t(l.Apply)}
            </ButtonWithLoader>
        </div>
    );
};

GeneralDetailsColumns.propTypes = {
    destinationColumns: PropTypes.arrayOf(PropTypes.object),
    sourceColumns: PropTypes.arrayOf(PropTypes.string),
    columnChangehandler: PropTypes.func,
    handleGeneralDetailsApply: PropTypes.func,
    isFileApplying: PropTypes.bool,
};

GeneralDetailsColumns.defaultProps = {
    isFileApplying: false,
};

export default GeneralDetailsColumns;
