import { ClientListActionTypes as Types } from './types';

const setClientListTableFilters = (filters) => ({
    type: Types.SET_CLIENT_LIST_TABLE_FILTERS,
    payload: filters,
});

const setClientListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_CLIENT_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setClientListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_CLIENT_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setClientListTableSorting = (sorting) => ({
    type: Types.SET_CLIENT_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetClientListState = () => ({
    type: Types.RESET_CLIENT_LIST_STATE,
});

export default {
    setClientListTableFilters,
    setClientListTablePagingPageNumber,
    setClientListTablePagingPageSize,
    setClientListTableSorting,
    resetClientListState,
};
