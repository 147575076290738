import { lazy } from 'react';

export default {
    0: { Component: lazy(() => import('./SingleSimpleInputForm')), config: {} },
    1: { Component: lazy(() => import('./SingleSelectForm')), config: {} },
    3: { Component: lazy(() => import('./StaticAndDynamicDateSimpleTypeForm')), config: { withTimeConsider: false } },
    4: { Component: lazy(() => import('./StaticAndDynamicDateFromToTypeForm')), config: { withTimeConsider: false } },
    5: { Component: lazy(() => import('./NumberSimpleInputWithHeadlineForm')), config: {} },
    6: { Component: lazy(() => import('./NumberFromToInputWithHeadlineForm')), config: {} },
    7: { Component: lazy(() => import('./SimpleConditionalForm')), config: {} },
    8: { Component: lazy(() => import('./LossAmountSportsBookForm')), config: {} },
    9: { Component: lazy(() => import('./AverageWithdrawalForm')), config: {} },
    12: { Component: lazy(() => import('./MonthDayPickerForm')), config: {} },
    13: { Component: lazy(() => import('./MonthDayPickerFromToForm')), config: {} },
    14: { Component: lazy(() => import('./MultiSelectForm')), config: {} },
    15: { Component: lazy(() => import('./CascadeForm')), config: {} },
    16: { Component: lazy(() => import('./CascadeForm')), config: {} },
    18: { Component: lazy(() => import('./StaticAndDynamicDateSimpleTypeForm')), config: { withTimeConsider: true } },
    19: { Component: lazy(() => import('./StaticAndDynamicDateFromToTypeForm')), config: { withTimeConsider: true } },
    21: { Component: lazy(() => import('./CascadeForm')), config: {} },
    24: { Component: lazy(() => import('./CascadeForm')), config: {} },
};
