import { isEmpty } from 'lodash';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { DateToISOStringFormat, MonthDayYearFormat, ScheduleModalViews } from 'constants/common';
import moment from 'moment';
const { notRepeatView, hourlyView } = ScheduleModalViews;
const { cronStringEncoder } = Helpers;

const resultScheduleDataCreator = (startTime, startDate, endDate, selectedDateSelector, datesDict, timeZone) => {
    const tmpStartTime = startTime.split(':');
    let cronSchedule = null;
    let startDateValue = moment(startDate, MonthDayYearFormat)
        .set({ hour: tmpStartTime[0], minute: tmpStartTime[1] })
        .format(DateToISOStringFormat);
    let endDateValue = moment(startDate, MonthDayYearFormat)
        .set({ hour: 23, minute: 59 })
        .format(DateToISOStringFormat);

    if (`${selectedDateSelector}` !== `${notRepeatView}`) {
        let cronString = cronStringEncoder(selectedDateSelector, datesDict[selectedDateSelector], tmpStartTime[0]);
        if (!isEmpty(cronString)) {
            let timeString = `0 ${tmpStartTime[1]}${selectedDateSelector === hourlyView ? '' : ` ${tmpStartTime[0]}`} `;
            cronSchedule = timeString + cronString;
        }
        endDateValue = moment(endDate, MonthDayYearFormat).set({ hour: 23, minute: 59 }).format(DateToISOStringFormat);

        if (selectedDateSelector !== hourlyView) {
            startDateValue = moment(startDate, MonthDayYearFormat)
                .set({ hour: 0, minute: 0 })
                .format(DateToISOStringFormat);
        }
    } else {
        endDateValue = startDateValue;
    }

    return {
        StartDate: startDateValue,
        EndDate: endDateValue,
        CronSchedule: cronSchedule,
        TimeZone: timeZone,
    };
};

const isSchedulerUnitPairsInvalid = (value) => {
    const isHourInvalid = !+value?.hour && (value?.hours || []).length === 0;
    const isDayInvalid = !+value?.day && (value?.days || []).length === 0;
    const isMonthInvalid = !+value?.month && (value?.months || []).length === 0;
    const isYearInvalid = !+value?.year && (value?.years || []).length === 0;

    switch (value?.viewType) {
        case ScheduleModalViews.hourlyView:
            return isHourInvalid;
        case ScheduleModalViews.dailyView:
            return isDayInvalid;
        case ScheduleModalViews.weeklyView:
            return value?.weeks.length === 0;
        case ScheduleModalViews.monthlyView:
            return isDayInvalid || isMonthInvalid;
        case ScheduleModalViews.annuallyView:
            return isDayInvalid || isMonthInvalid || isYearInvalid;
        default:
            return false;
    }
};

export { resultScheduleDataCreator, isSchedulerUnitPairsInvalid };
