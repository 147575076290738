import { TemplateListActionTypes as Types } from './types';

const setTemplateListTableFilters = (filters) => ({
    type: Types.SET_TEMPLATE_LIST_TABLE_FILTERS,
    payload: filters,
});

const setTemplateListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setTemplateListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setTemplateListTableSorting = (sorting) => ({
    type: Types.SET_TEMPLATE_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetTemplateListState = () => ({
    type: Types.RESET_TEMPLATE_LIST_STATE,
});
export default {
    setTemplateListTableFilters,
    setTemplateListTablePagingPageNumber,
    setTemplateListTablePagingPageSize,
    setTemplateListTableSorting,
    resetTemplateListState,
};
