import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import UI Components
import { Icon, Tooltip } from '@geneui/components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import {
    CJFormulaInArgumentType,
    CJReferenceInArgumentType,
    excludeRightValueContentVisibilitiesOperators,
} from 'constants/common';

const { getFlagsValues } = Helpers;

const useFilterTag = (optionType, customAttributes, rightValue, operator) => {
    const [data, setData] = useState(null);
    const { config, restore } = useMenuItem(customAttributes?.MenuItemId, false);

    useEffect(() => {
        if (
            !isNil(config) &&
            !excludeRightValueContentVisibilitiesOperators.includes(operator) &&
            !isNil(rightValue) &&
            rightValue?.$type !== CJFormulaInArgumentType &&
            rightValue?.$type !== CJReferenceInArgumentType
        ) {
            switch (optionType) {
                case 'WfInteger':
                    !isNil(customAttributes?.MenuItemId) &&
                        restore([rightValue.toString()]).then((Data) => {
                            setData(Data.find((item) => +item.Id === +rightValue).Name);
                        });
                    break;
                case 'WfInteger[]': {
                    if (!isNil(customAttributes?.MenuItemId)) {
                        const tmpRightValue = rightValue.map((item) => item.toString());
                        restore(tmpRightValue).then((Data) =>
                            setData(
                                Data.filter((item) => tmpRightValue.includes(item.Id))
                                    .map((item) => item.Name)
                                    .join(),
                            ),
                        );
                    }
                    break;
                }
                case 'WfFlags': {
                    if (!isNil(customAttributes?.MenuItemId)) {
                        const tmpRightValue = getFlagsValues(rightValue).map((item) => item.toString());
                        restore(tmpRightValue).then((Data) =>
                            setData(
                                Data.filter((item) => tmpRightValue.includes(item.Id))
                                    .map((item) => item.Name)
                                    .join(),
                            ),
                        );
                    }
                    break;
                }
                default:
                    setData(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    return data;
};

const CJFilterTag = ({
    label,
    stringBuilder,
    customAttributes,
    optionType,
    index,
    operator,
    rightValue,
    elements,
    removeTag,
    isLoading,
    isViewMode,
}) => {
    const { t } = useTranslation();

    const data = useFilterTag(optionType, customAttributes, rightValue, operator);

    const info = excludeRightValueContentVisibilitiesOperators.includes(operator)
        ? label
        : `${label} ${stringBuilder(rightValue, data, elements, t)}`;

    return (
        <Tooltip text={info} position="bottom">
            <div className="crm-cj-filter-tag">
                <p>{info}</p>
                {!isViewMode && <Icon type={'bc-icon-cancel'} onClick={() => removeTag(index)} disabled={isLoading} />}
            </div>
        </Tooltip>
    );
};

CJFilterTag.propTypes = {
    label: PropTypes.string.isRequired,
    customAttributes: PropTypes.object.isRequired,
    stringBuilder: PropTypes.func,
    optionType: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    elements: PropTypes.array.isRequired,
    operator: PropTypes.string.isRequired,
    rightValue: PropTypes.any,
    removeTag: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isViewMode: PropTypes.bool,
};

CJFilterTag.propTypes = {
    stringBuilder: () => '',
    isViewMode: false,
};

export default memo(CJFilterTag);
