import { CompositePageSegmentListActionTypes as Types } from './types';

const setSegmentListTableFilters = (filters) => ({
    type: Types.SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_FILTERS,
    payload: filters,
});

const setSegmentListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setSegmentListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setSegmentListTableSorting = (sorting) => ({
    type: Types.SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetSegmentListState = () => ({
    type: Types.RESET_COMPOSITE_PAGE_SEGMENT_LIST_STATE,
});

export default {
    setSegmentListTableFilters,
    setSegmentListTablePagingPageNumber,
    setSegmentListTablePagingPageSize,
    setSegmentListTableSorting,
    resetSegmentListState,
};
