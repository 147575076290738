// Import images
import ukFlagImg from 'assets/images/flags/uk.svg';
import viFlagImg from 'assets/images/flags/vi.svg';
import uzFlagImg from 'assets/images/flags/uz.svg';
import usFlagImg from 'assets/images/flags/us.svg';
import trFlagImg from 'assets/images/flags/tr.svg';
import thFlagImg from 'assets/images/flags/th.svg';
import svFlagImg from 'assets/images/flags/sv.svg';
import swFlagImg from 'assets/images/flags/sw.svg';
import esFlagImg from 'assets/images/flags/es.svg';
import slFlagImg from 'assets/images/flags/sl.svg';
import skFlagImg from 'assets/images/flags/sk.svg';
import srFlagImg from 'assets/images/flags/sr.svg';
import ruFlagImg from 'assets/images/flags/ru.svg';
import rtFlagImg from 'assets/images/flags/rt.svg';
import roFlagImg from 'assets/images/flags/ro.svg';
import ptFlagImg from 'assets/images/flags/pt.svg';
import plFlagImg from 'assets/images/flags/pl.svg';
import apFlagImg from 'assets/images/flags/ap.svg';
import faFlagImg from 'assets/images/flags/fa.svg';
import noFlagImg from 'assets/images/flags/no.svg';
import msFlagImg from 'assets/images/flags/ms.svg';
import mgFlagImg from 'assets/images/flags/mg.svg';
import mkFlagImg from 'assets/images/flags/mk.svg';
import ltFlagImg from 'assets/images/flags/lt.svg';
import lvFlagImg from 'assets/images/flags/lv.svg';
import kuFlagImg from 'assets/images/flags/ku.svg';
import koFlagImg from 'assets/images/flags/ko.svg';
import kkFlagImg from 'assets/images/flags/kk.svg';
import jaFlagImg from 'assets/images/flags/ja.svg';
import itFlagImg from 'assets/images/flags/it.svg';
import heFlagImg from 'assets/images/flags/he.svg';
import elFlagImg from 'assets/images/flags/el.svg';
import deFlagImg from 'assets/images/flags/de.svg';
import kaFlagImg from 'assets/images/flags/ka.svg';
import frFlagImg from 'assets/images/flags/fr.svg';
import fiFlagImg from 'assets/images/flags/fi.svg';
import etFlagImg from 'assets/images/flags/et.svg';
import enFlagImg from 'assets/images/flags/en.svg';
import nlFlagImg from 'assets/images/flags/nl.svg';
import daFlagImg from 'assets/images/flags/da.svg';
import csFlagImg from 'assets/images/flags/cs.svg';
import hrFlagImg from 'assets/images/flags/hr.svg';
import zsFlagImg from 'assets/images/flags/zs.svg';
import zhFlagImg from 'assets/images/flags/zh.svg';
import cnFlagImg from 'assets/images/flags/cn.svg';
import bgFlagImg from 'assets/images/flags/bg.svg';
import pbFlagImg from 'assets/images/flags/pb.svg';
import bnFlagImg from 'assets/images/flags/bn.svg';
import idFlagImg from 'assets/images/flags/id.svg';
import azFlagImg from 'assets/images/flags/az.svg';
import hyFlagImg from 'assets/images/flags/hy.svg';
import arFlagImg from 'assets/images/flags/ar.svg';
import sqFlagImg from 'assets/images/flags/sq.svg';
import aeFlagImg from 'assets/images/flags/ae.svg';
import afFlagImg from 'assets/images/flags/af.svg';
import amFlagImg from 'assets/images/flags/am.svg';
import beFlagImg from 'assets/images/flags/be.svg';
import brFlagImg from 'assets/images/flags/br.svg';
import bsFlagImg from 'assets/images/flags/bs.svg';
import caFlagImg from 'assets/images/flags/ca.svg';
import chFlagImg from 'assets/images/flags/ch.svg';
import cyFlagImg from 'assets/images/flags/cy.svg';
import eeFlagImg from 'assets/images/flags/ee.svg';
import ffFlagImg from 'assets/images/flags/ff.svg';
import foFlagImg from 'assets/images/flags/fo.svg';
import huFlagImg from 'assets/images/flags/hu.svg';
import mhFlagImg from 'assets/images/flags/mh.svg';
import mnFlagImg from 'assets/images/flags/mn.svg';
import mtFlagImg from 'assets/images/flags/mt.svg';
import nbFlagImg from 'assets/images/flags/nb.svg';
import rmFlagImg from 'assets/images/flags/rm.svg';
import seFlagImg from 'assets/images/flags/se.svg';
import spFlagImg from 'assets/images/flags/sp.svg';
import tiFlagImg from 'assets/images/flags/ti.svg';
import tkFlagImg from 'assets/images/flags/tk.svg';
import zuFlagImg from 'assets/images/flags/zu.svg';
import hiFlagImg from 'assets/images/flags/hi.svg';

import image from 'assets/images/blockEditorIcons/image.svg';

import notActiveImg from 'assets/images/crm-not-active-image.svg';
import underMaintenanceImg from 'assets/images/crm-under-maintenance-image.svg';
import preparingImg from 'assets/images/crm-preparing-image.svg';

import activeUsersWidgetImg from 'assets/images/widgetIcons/active_users.svg';
import retentionRateWidgetImg from 'assets/images/widgetIcons/retention_rate.svg';
import conversionRateWidgetImg from 'assets/images/widgetIcons/conversion_rate.svg';
import moneyRetentionRateWidgetImg from 'assets/images/widgetIcons/money_retention_rate.svg';
import dauMauWidgetImg from 'assets/images/widgetIcons/dau-mau.svg';
import dauWauWidgetImg from 'assets/images/widgetIcons/dau-wau.svg';
import arpuWidgetImg from 'assets/images/widgetIcons/arpu.svg';
import reactivationRateWidgetImg from 'assets/images/widgetIcons/reactivation_rate.svg';
import clvWidgetImg from 'assets/images/widgetIcons/clv.svg';

const LanguagesFlagsPaths = {
    ae: aeFlagImg,
    af: afFlagImg,
    am: amFlagImg,
    ap: apFlagImg,
    ar: arFlagImg,
    az: azFlagImg,
    be: beFlagImg,
    bg: bgFlagImg,
    bn: bnFlagImg,
    br: brFlagImg,
    bs: bsFlagImg,
    ca: caFlagImg,
    ch: chFlagImg,
    cn: cnFlagImg,
    cs: csFlagImg,
    cy: cyFlagImg,
    da: daFlagImg,
    de: deFlagImg,
    ee: eeFlagImg,
    el: elFlagImg,
    en: enFlagImg,
    es: esFlagImg,
    et: etFlagImg,
    fa: faFlagImg,
    ff: ffFlagImg,
    fi: fiFlagImg,
    fo: foFlagImg,
    fr: frFlagImg,
    he: heFlagImg,
    hi: hiFlagImg,
    hr: hrFlagImg,
    hu: huFlagImg,
    hy: hyFlagImg,
    id: idFlagImg,
    it: itFlagImg,
    ja: jaFlagImg,
    ka: kaFlagImg,
    kk: kkFlagImg,
    ko: koFlagImg,
    ku: kuFlagImg,
    lt: ltFlagImg,
    lv: lvFlagImg,
    mg: mgFlagImg,
    mh: mhFlagImg,
    mk: mkFlagImg,
    mn: mnFlagImg,
    ms: msFlagImg,
    mt: mtFlagImg,
    nb: nbFlagImg,
    nl: nlFlagImg,
    no: noFlagImg,
    pb: pbFlagImg,
    pl: plFlagImg,
    pt: ptFlagImg,
    rm: rmFlagImg,
    ro: roFlagImg,
    rt: rtFlagImg,
    ru: ruFlagImg,
    se: seFlagImg,
    sk: skFlagImg,
    sl: slFlagImg,
    sp: spFlagImg,
    sq: sqFlagImg,
    sr: srFlagImg,
    sv: svFlagImg,
    sw: swFlagImg,
    th: thFlagImg,
    ti: tiFlagImg,
    tk: tkFlagImg,
    tr: trFlagImg,
    uk: ukFlagImg,
    us: usFlagImg,
    uz: uzFlagImg,
    vi: viFlagImg,
    zh: zhFlagImg,
    zs: zsFlagImg,
    zu: zuFlagImg,
};

const BlockEditorIcons = {
    imageBlockPath: image,
};

const DashboardWidgetsIcons = {
    activeUsersWidgetImg,
    retentionRateWidgetImg,
    conversionRateWidgetImg,
    moneyRetentionRateWidgetImg,
    dauMauWidgetImg,
    dauWauWidgetImg,
    arpuWidgetImg,
    reactivationRateWidgetImg,
    clvWidgetImg,
};

const NotActiveImage = {
    path: notActiveImg,
};

const UnderMaintenanceImage = {
    path: underMaintenanceImg,
};

const PreparingImag = {
    path: preparingImg,
};

export {
    LanguagesFlagsPaths,
    BlockEditorIcons,
    DashboardWidgetsIcons,
    NotActiveImage,
    PreparingImag,
    UnderMaintenanceImage,
};
