import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
// Import Components
import FilterSchema from './FilterSchema';

function FilterSchemaList({
    handleChangeActiveList,
    readOnly,
    setReadOnly,
    resetActiveList,
    editingFilter,
    setEditingFilter,
    filtersData,
    setClientsCount,
    queryConfigurations,
    withoutActions,
}) {
    const filterGroups = useMemo(() => {
        const filters = groupBy(filtersData.filters, (item) => item[0].comparison.schemaId);

        return Object.entries(filters).map(([_key, item]) => ({
            filters: item,
            id: filtersData.id,
            isMain: filtersData.isMain,
            name: filtersData.name,
            seqId: filtersData.seqId,
            groupName: item[0][0].comparison.schemaName,
        }));
    }, [filtersData]);

    return filterGroups.map((item) => (
        <FilterSchema
            key={item.groupName}
            groupName={item.groupName}
            filtersData={item}
            queryConfigurations={queryConfigurations}
            handleChangeActiveList={handleChangeActiveList}
            readOnly={readOnly}
            withoutActions={withoutActions}
            setReadOnly={setReadOnly}
            resetActiveList={resetActiveList}
            editingFilter={editingFilter}
            setEditingFilter={setEditingFilter}
            setClientsCount={setClientsCount}
        />
    ));
}

FilterSchemaList.propTypes = {
    handleChangeActiveList: PropTypes.func,
    readOnly: PropTypes.bool,
    withoutActions: PropTypes.bool,
    setReadOnly: PropTypes.func,
    resetActiveList: PropTypes.func,
    editingFilter: PropTypes.string,
    setEditingFilter: PropTypes.func,
    filtersData: PropTypes.object.isRequired,
    queryConfigurations: PropTypes.object.isRequired,
    setClientsCount: PropTypes.func.isRequired,
};

FilterSchemaList.defaultProps = {
    readOnly: false,
    withoutActions: false,
};

export default FilterSchemaList;
