import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useFormik } from 'formik';
import { CJVisualizationStepTemplateValidationSchema } from 'validators/schemas.validation';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Hooks
import { useTranslation } from 'react-i18next';
// Import Constants
import { l } from 'constants/common';

const CustomerJourneyVisualizationStepTemplate = ({ value, onChange }) => {
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: { name: value?.name },
        onSubmit: noop,
        validationSchema: CJVisualizationStepTemplateValidationSchema(),
    });
    const { setFieldValue, errors } = formik;

    const [name, setName] = useState(value?.name);
    const [description, setDescription] = useState(value?.description);

    const nameChangeHandler = (e) => {
        setName(e.target.value);
    };
    const descriptionChangeHandler = (e) => {
        setDescription(e.target.value);
    };

    useEffect(() => {
        if (value?.name !== name) {
            setName(value?.name);
        }
        if (value?.description !== description) {
            setDescription(value?.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        setFieldValue('name', name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useEffect(() => {
        onChange({ name, description });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, description]);

    return (
        <div className="c-input-group-wrapper">
            <ExtendedInput
                appearance="outline"
                inputSize="default"
                labelAppearance="swap"
                placeholder={t(l.Name)}
                onChange={nameChangeHandler}
                value={isNil(name) ? '' : name}
                isValid={!errors?.name}
                errorText={t(errors?.name?.label, {
                    fieldName: t(errors?.name?.fieldName),
                    count: t(errors?.name?.count),
                })}
            />
            <ExtendedInput
                appearance="outline"
                inputSize="default"
                labelAppearance="swap"
                placeholder={t(l.Description)}
                onChange={descriptionChangeHandler}
                value={isNil(description) ? '' : description}
            />
        </div>
    );
};

CustomerJourneyVisualizationStepTemplate.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

CustomerJourneyVisualizationStepTemplate.defaultProps = {
    value: null,
    onChange: noop,
};

export default memo(CustomerJourneyVisualizationStepTemplate);
