import { UserDataActionTypes as Types } from './types';

const setUserData = (data) => ({
    type: Types.SET_USER_DATA,
    payload: data,
});

const clearUserData = () => ({
    type: Types.CLEAR_USER_DATA,
    payload: null,
});

const setLanguage = (langId) => ({
    type: Types.SET_LANGUAGE,
    payload: langId,
});

export default {
    setUserData,
    clearUserData,
    setLanguage,
};
