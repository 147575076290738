import { useCallback } from 'react';
import { Helpers } from 'services';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { partnerIdQueryNamespace } from 'constants/common';
import { isNil } from 'lodash';

const { queryParamsDecoder, queryParamsEncoder } = Helpers;

const useQueryParams = (queryNamespace) => {
    const { pathname } = useLocation();

    const { ActivePartner } = useSelector((state) => state.userData);
    const PartnerId = ActivePartner.PartnerId;

    const history = useHistory();

    const encode = useCallback(
        (data) => {
            if (!isNil(queryNamespace)) {
                const queryParams = queryParamsDecoder(window.location.search);
                queryParams[queryNamespace] = data;
                queryParams[partnerIdQueryNamespace] = PartnerId;
                return queryParamsEncoder(queryParams);
            }

            return queryParamsEncoder(data);
        },
        [PartnerId, queryNamespace],
    );

    const decode = useCallback(() => {
        const decodedQueryParams = queryParamsDecoder(window.location.search);
        if (!isNil(queryNamespace) && decodedQueryParams[partnerIdQueryNamespace] === PartnerId) {
            return decodedQueryParams[queryNamespace];
        } else if (isNil(queryNamespace)) {
            return decodedQueryParams;
        }
        return {};
    }, [PartnerId, queryNamespace]);

    const historyReplace = useCallback(
        (data) => {
            history.replace({
                pathname,
                search: encode(data),
            });
        },
        [encode, history, pathname],
    );

    return { encode, decode, historyReplace };
};

export default useQueryParams;
