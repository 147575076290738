import { AuthActionTypes as Types } from 'actions/types';

export default (
    state = {
        isLoggedIn: null,
        accessToken: null,
    },
    action,
) => {
    switch (action.type) {
        case Types.DO_LOGIN:
            state.accessToken = action.payload;
            state.isLoggedIn = true;
            return Object.assign({}, state, {});
        case Types.DO_LOGOUT:
            state.accessToken = null;
            state.isLoggedIn = false;
            return Object.assign({}, state, {});
        case Types.UPDATE_TOKEN:
            state.accessToken = action.payload;
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
