import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, values } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { TemplateHttpService, CustomerJourneyHttpService } from 'services/http';
// Import Components
import { CJInfoBlockLabelValueWrapper, CJReferenceInArgumentInfoBlock } from 'components/CustomerJourney/infoBlocks';
import { TemplateInfoModal } from 'components';
// Import Constants
import { l } from 'constants/common';

const CJTemplateConfigInfoBlock = ({ data, elements, option, blockId }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();

    const [isLoading, setIsLoading] = useState(false);
    const [templateData, setTemplateData] = useState(null);
    const [templatePlaceholdersData, setTemplatePlaceholdersData] = useState(() => []);
    const [isVisibleTemplateInfo, setIsVisibleTemplateInfo] = useState(false);

    const { getTemplate, getTemplateModels } = useMemo(
        () => ({
            getTemplate: TemplateHttpService.getTemplate(),
            getTemplateModels: CustomerJourneyHttpService.getTemplateModels(),
        }),
        [],
    );

    const openTemplateModal = useCallback(() => {
        setIsVisibleTemplateInfo(true);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        doGetRequest(getTemplate.request, {
            queryString: { templateId: data.templateId },
            successCallback: (data) => {
                setIsLoading(false);
                setTemplateData(data);
            },
        });
        if (!isEmpty(data?.dynamicVariables)) {
            doGetRequest(getTemplateModels.request, {
                queryString: { templateId: data.templateId },
                successCallback: (responseData) => {
                    const fieldNameMap = responseData.reduce((acc, val) => {
                        acc[val.FieldName] = val;
                        return acc;
                    }, {});

                    setTemplatePlaceholdersData(
                        values(data.dynamicVariables).map(({ fieldName, value }) => {
                            const isTranslatable = !isNil(fieldNameMap[fieldName]?.WfType);
                            return {
                                value: value,
                                fieldName: isTranslatable ? fieldNameMap[fieldName].DisplayNameKey : fieldName,
                                isTranslatable: isTranslatable,
                            };
                        }),
                    );
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.templateId]);

    return (
        <>
            <CJInfoBlockLabelValueWrapper
                label={t(l.Name)}
                value={templateData?.Name}
                isLoading={isLoading}
                onClick={openTemplateModal}
                isClickable={true}
            />
            {isVisibleTemplateInfo && (
                <TemplateInfoModal
                    templateId={templateData?.TemplateId}
                    childItemsInfo={templateData?.ChildItemsInfo}
                    handleClose={() => setIsVisibleTemplateInfo(false)}
                    isVisible={isVisibleTemplateInfo}
                />
            )}
            {templatePlaceholdersData.map(({ fieldName, value, isTranslatable }, index) => {
                return (
                    <div key={`${data.templateId}_${index}`} className="cj-info-block-dynamic-variable-container">
                        <div className="cj-dynamic-variable-field-name">
                            {isTranslatable ? t(fieldName) : fieldName}
                        </div>
                        <CJReferenceInArgumentInfoBlock
                            data={value}
                            elements={elements}
                            option={option}
                            blockId={blockId}
                        />
                    </div>
                );
            })}
        </>
    );
};

CJTemplateConfigInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
};

export default memo(CJTemplateConfigInfoBlock);
