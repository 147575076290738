import { MenuItemsActionTypes as Types } from 'actions/types';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case Types.SET_MENU_ITEM: {
            return { ...state, ...payload };
        }
        case Types.CLEAR_MENU_ITEMS:
            return {};
        default:
            return state;
    }
};
