import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Constants
import { l, CJUITypes } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper, CJMenuItemDropDownInfoBlock } from 'components/CustomerJourney/infoBlocks';

const CJIntegerInfoBlock = ({ data, customAttributes, option }) => {
    const { t } = useTranslation();

    return (customAttributes?.UIType === CJUITypes.DropDown || customAttributes?.UIType === CJUITypes.AutoComplete) &&
        !isNil(customAttributes?.MenuItemId) ? (
        <CJMenuItemDropDownInfoBlock data={data} customAttributes={customAttributes} option={option} />
    ) : (
        <CJInfoBlockLabelValueWrapper label={t(l.OptionValue)} value={data?.value} />
    );
};

CJIntegerInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(CJIntegerInfoBlock);
