import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useFormik } from 'formik';
// Import UI Components
import { TimePicker } from '@geneui/components';
// Import Constants
import { timeInitialValueCreator } from './config';

const TimeForm = ({ defaultValue, getUpdate, option }) => {
    const formik = useFormik({
        initialValues: timeInitialValueCreator(defaultValue, option?.Required),
        onSubmit: noop,
    });
    const { values, setFieldValue } = formik;
    const { time } = values;

    const timeChangeHandler = ({ target }) => {
        const tmpDate = target.value.split(':');
        setFieldValue('time', target.value);
        getUpdate({ hours: tmpDate[0], minutes: tmpDate[1] });
    };

    useEffect(() => {
        const tmpTime = time.split(':');

        getUpdate({
            hours: tmpTime[0],
            minutes: tmpTime[1],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TimePicker
            appearance="outline"
            showSeconds={false}
            minuteFormat={'mm'}
            hourFormat={'HH'}
            onChange={timeChangeHandler}
            value={time}
        />
    );
};

TimeForm.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(TimeForm);
