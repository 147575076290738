import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { first } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import UI Components
import { SkeletonLoader } from '@geneui/components';
// Import Components
import GeneralInfo from './GeneralInfo';
import BonusInfo from './BonusInfo';
// Import Constants
import { BonusTypesLabels, l } from 'constants/common';

const BonusInfoModalBody = ({
    id,
    title,
    description,
    type,
    startDate,
    endDate,
    bonusDetails,
    state,
    wallet,
    internalMessageTitle,
}) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();

    const [isLoadingMarketplaces, setIsLoadingMarketplaces] = useState(true);
    const [marketplace, setMarketplace] = useState(true);

    const { getNftMarketplacesRequest } = useMemo(
        () => ({
            getNftMarketplacesRequest: UtilsHttpService.getNftMarketplaces(),
        }),
        [],
    );

    const nftBonusBonusDetails = useMemo(() => first(bonusDetails), [bonusDetails]);

    useEffect(() => {
        if (type === BonusTypesLabels.NFTBonus) {
            doGetRequest(getNftMarketplacesRequest.request, {
                successCallback: (data) => {
                    const marketplaceValue = first(bonusDetails).Marketplace;
                    setMarketplace((data ?? []).find((item) => item.Value === marketplaceValue)?.DisplayName);
                },
            }).then(() => setIsLoadingMarketplaces(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GeneralInfo
                id={id}
                title={title}
                description={description}
                type={type}
                startDate={startDate}
                endDate={endDate}
                state={state}
                walletType={wallet}
                internalMessageTitle={internalMessageTitle}
            />
            {type !== BonusTypesLabels.NFTBonus ? (
                <div className="segment-modal-bottom">
                    {/*For title text can add active-state class*/}
                    <div className="segment-modal-b-content c-s-b-content">
                        <BonusInfo bonusDetails={bonusDetails} />
                    </div>
                </div>
            ) : (
                <div className="segment-modal-head">
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.Marketplace)}</span>
                        <SkeletonLoader isBusy={isLoadingMarketplaces} className="ellipsis-text kv-value kv-value-css">
                            {!isLoadingMarketplaces && (
                                <span className="ellipsis-text kv-value kv-value-css">{marketplace}</span>
                            )}
                        </SkeletonLoader>
                    </div>

                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.From)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{nftBonusBonusDetails?.From}</span>
                    </div>

                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.To)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{nftBonusBonusDetails?.To}</span>
                    </div>
                </div>
            )}
        </>
    );
};

BonusInfoModalBody.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    bonusDetails: PropTypes.array.isRequired,
    state: PropTypes.number.isRequired,
    wallet: PropTypes.string,
    internalMessageTitle: PropTypes.string,
};

BonusInfoModalBody.defaultProps = {
    wallet: '',
};

export default BonusInfoModalBody;
