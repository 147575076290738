import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

const useData = (data) => {
    const [value, setValue] = useState(data);

    useEffect(() => {
        setValue((prev) => {
            if (!isEqual(data, prev)) {
                return data;
            }
            return prev;
        });
    }, [data]);

    return value;
};

export default useData;
