import { SignOutActionTypes as Types } from './types';

const signOutFetch = (status) => ({
    type: Types.SIGN_OUT_FETCH,
    payload: status,
});

const signOutSucceeded = (status) => ({
    type: Types.SIGN_OUT_SUCCEEDED,
    payload: status,
});

const signOutFailed = (error) => ({
    type: Types.SIGN_OUT_FAILED,
    payload: error,
});

export default {
    signOutFetch,
    signOutSucceeded,
    signOutFailed,
};
