import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Icon, Tooltip } from '@geneui/components';

const CJDescriptionInfoWrapper = ({ children, description }) => {
    const { t } = useTranslation();

    const descriptionHTML = t(description, { defaultValue: '' });

    if (!descriptionHTML) {
        return children;
    }

    const descriptionNode = ReactHTMLParser(descriptionHTML);

    return (
        <div className="cms-cj-description-info-wrapper">
            {children}

            <Tooltip className="cms-cj-description-info-wrapper__tooltip" text={descriptionNode}>
                <Icon type="bc-icon-info" />
            </Tooltip>
        </div>
    );
};

CJDescriptionInfoWrapper.propTypes = {
    description: PropTypes.string,
};

CJDescriptionInfoWrapper.defaultProps = {
    description: '',
};

export default memo(CJDescriptionInfoWrapper);
