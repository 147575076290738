import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Components
import { AccessControl } from 'components';
import CampaignEmailActivity from './CampaignEmailActivity';
import CampaignCountOfData from './CampaignCountOfData';
import CampaignDataChart from './CampaignDataChart';
// Import Hooks
import { useRequest, useHasPermission } from 'hooks';
// Import Services
import { CampaignHttpService } from 'services/http';
// Import Constants
import { l, PERMISSIONS } from 'constants/common';

const CampaignReportVisualization = ({ campaignId, startDate, endDate, isLoadingSchedule, isActive }) => {
    const { t } = useTranslation();

    const { doPostRequest } = useRequest();
    const { hasPermission } = useHasPermission();

    const [isLoadingCampaignEmailActivity, setIsLoadingCampaignEmailActivity] = useState(true);
    const [campaignEmailActivityData, setCampaignEmailActivityData] = useState({});

    const [isLoadingCampaignBonusChartsData, setIsLoadingCampaignBonusChartsData] = useState(true);
    const [campaignBonusChartsData, setCampaignBonusChartsData] = useState({});

    const [isLoadingCampaignEmailChartsData, setIsLoadingCampaignEmailChartsData] = useState(true);
    const [campaignEmailChartsData, setCampaignEmailChartsData] = useState({});

    const [isRequestBodyChanged, setIsRequestBodyChanged] = useState(false);

    const { getCampaignEmailActivityRequest, getCampaignBonusChartsDataRequest, getCampaignEmailChartsDataRequest } =
        useMemo(
            () => ({
                getCampaignEmailActivityRequest: CampaignHttpService.getCampaignEmailActivity(),
                getCampaignBonusChartsDataRequest: CampaignHttpService.getCampaignBonusChartsData(),
                getCampaignEmailChartsDataRequest: CampaignHttpService.getCampaignEmailChartsData(),
            }),
            [],
        );

    const getEmailActivity = useCallback(() => {
        setIsLoadingCampaignEmailActivity(true);
        doPostRequest(getCampaignEmailActivityRequest.request, {
            requestBody: { CampaignId: campaignId, StartDate: startDate, EndDate: endDate },
            successCallback: (data) => {
                setCampaignEmailActivityData(data);
            },
        }).then(() => {
            setIsLoadingCampaignEmailActivity(false);
        });
    }, [campaignId, startDate, endDate, doPostRequest, getCampaignEmailActivityRequest]);

    const getBonusCharts = useCallback(() => {
        setIsLoadingCampaignBonusChartsData(true);
        doPostRequest(getCampaignBonusChartsDataRequest.request, {
            requestBody: { CampaignId: campaignId, StartDate: startDate, EndDate: endDate },
            successCallback: (data) => {
                setCampaignBonusChartsData(data);
            },
        }).then(() => {
            setIsLoadingCampaignBonusChartsData(false);
        });
    }, [campaignId, startDate, endDate, doPostRequest, getCampaignBonusChartsDataRequest]);

    const getEmailCharts = useCallback(() => {
        setIsLoadingCampaignEmailChartsData(true);
        doPostRequest(getCampaignEmailChartsDataRequest.request, {
            requestBody: { CampaignId: campaignId, StartDate: startDate, EndDate: endDate },
            successCallback: (data) => {
                setCampaignEmailChartsData(data);
            },
        }).then(() => {
            setIsLoadingCampaignEmailChartsData(false);
        });
    }, [campaignId, startDate, endDate, doPostRequest, getCampaignEmailChartsDataRequest]);

    useEffect(() => {
        if (!isLoadingSchedule && isActive && isRequestBodyChanged) {
            getEmailActivity();
            hasPermission(PERMISSIONS.ViewAwards) && getBonusCharts();
            getEmailCharts();

            setIsRequestBodyChanged(false);
        }
    }, [
        campaignId,
        startDate,
        endDate,
        isLoadingSchedule,
        isRequestBodyChanged,
        isActive,
        getEmailActivity,
        hasPermission,
        getBonusCharts,
        getEmailCharts,
    ]);

    useEffect(() => {
        setIsRequestBodyChanged(true);
    }, [campaignId, startDate, endDate]);

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getCampaignEmailActivityRequest.cancel('CampaignReportVisualization:getCampaignEmailActivityRequest');
            getCampaignBonusChartsDataRequest.cancel('CampaignReportVisualization:getCampaignBonusChartsDataRequest');
            getCampaignEmailChartsDataRequest.cancel('CampaignReportVisualization:getCampaignEmailChartsDataRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <div className="c-report-visual-wrapper">
            <div className="c-report-visual-wrapper-inner">
                <AccessControl permissions={PERMISSIONS.ViewAwards}>
                    <div className="c-report-visual-wrapper-inner-item">
                        <CampaignCountOfData
                            isLoading={isLoadingCampaignBonusChartsData}
                            data={
                                isNil(campaignBonusChartsData?.chartsCount) ? [] : campaignBonusChartsData?.chartsCount
                            }
                            title={t(l.CountOfSentBonus)}
                        />
                    </div>
                    <div className="c-report-visual-wrapper-inner-item">
                        <CampaignDataChart
                            isLoading={isLoadingCampaignBonusChartsData}
                            data={isNil(campaignBonusChartsData?.pieChart) ? {} : campaignBonusChartsData?.pieChart}
                            title={t(l.CountOfSentBonus)}
                        />
                    </div>
                </AccessControl>
                <div className="c-report-visual-wrapper-inner-item">
                    <CampaignCountOfData
                        isLoading={isLoadingCampaignEmailChartsData}
                        data={isNil(campaignEmailChartsData?.chartsCount) ? [] : campaignEmailChartsData?.chartsCount}
                        title={t(l.CountOfSentMessage)}
                    />
                </div>
                <div className="c-report-visual-wrapper-inner-item">
                    <CampaignDataChart
                        isLoading={isLoadingCampaignEmailChartsData}
                        data={isNil(campaignEmailChartsData?.pieChart) ? {} : campaignEmailChartsData?.pieChart}
                        title={t(l.CountOfSentMessage)}
                    />
                </div>
                <div className="c-report-visual-wrapper-inner-item">
                    <CampaignEmailActivity
                        isLoading={isLoadingCampaignEmailActivity}
                        data={campaignEmailActivityData}
                    />
                </div>
            </div>
        </div>
    );
};

CampaignReportVisualization.propTypes = {
    campaignId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    isLoadingSchedule: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
};

CampaignReportVisualization.defaultProps = {
    isActive: true,
};

export default memo(CampaignReportVisualization);
