import moment from 'moment';
import { isArray, isEmpty, head, isUndefined, isFunction, isString, isBoolean } from 'lodash';
// Import Constants
import { FilterHolderFields, DateFormat } from 'constants/common';

const { textInput, select, multiSelect, rangeDate, singleDate, multiSelectAutocomplete, autocompleteOptionList } =
    FilterHolderFields;

const dateFormat = (value, formatType = DateFormat) => {
    return isFunction(value?.format)
        ? value.format(formatType)
        : isString(value)
        ? moment.utc(value).format(formatType)
        : value;
};

export const mapToTagListModel = (filters = []) => {
    return filters
        .filter(({ value, type }) => {
            return (type === rangeDate.type || type === singleDate.type) && isArray(value)
                ? !isEmpty(value?.filter((item) => item !== null))
                : !isEmpty(value) || isBoolean(value);
        })
        .map(({ displayName, value, data, type, valueFieldKey }) => {
            let result = {
                label: displayName,
                valueFieldKey,
                originalValue: value,
            };

            switch (type) {
                case textInput.type:
                    result.value = value;
                    break;
                case select.type:
                    result.value = head(data.filter((dataItem) => value === dataItem.value))?.label;
                    break;
                case multiSelect.type:
                    // TODO: concat symbol bugs
                    result.value = value.map(
                        (valueItem) => head(data.filter((dataItem) => dataItem?.value === valueItem))?.label,
                    );
                    break;
                case rangeDate.type:
                    result.value = `${dateFormat(value[0])}  - ${dateFormat(value[1])}`;
                    break;
                case singleDate.type:
                    result.value = moment(value[0]).format(DateFormat);
                    break;
                case multiSelectAutocomplete.type:
                    result.value = value.map(
                        (valueItem) => head(data.filter((dataItem) => dataItem?.value === valueItem))?.label,
                    );
                    break;
                case autocompleteOptionList.type:
                    result.value = head(data.filter((dataItem) => dataItem?.value === value))?.label;
                    break;
                default:
                    result.value = value;
            }

            return result;
        });
};

export const resetFilterFields = (filterFields = [], overwritableFilterFieldsList) => {
    return filterFields.map((filterField) => {
        let matchedOverwritableField = head(
            overwritableFilterFieldsList.filter(
                (overwritableField) => overwritableField.valueFieldKey === filterField.valueFieldKey,
            ),
        );

        if (!isUndefined(matchedOverwritableField)) {
            filterField.value = matchedOverwritableField?.originalValue;
        } else {
            filterField.value = FilterHolderFields[filterField.type].defaultValue;
        }

        return filterField;
    });
};

export const createAddFilterTagDTO = (filtersTagKey, filterTagGroupName, filtersTags = []) => {
    return {
        FilterTagName: filtersTagKey,
        FilterGroup: {
            Label: filterTagGroupName,
            Filters: filtersTags.map((filterTag) => {
                return { Label: filterTag?.label, Values: [filterTag] };
            }),
        },
    };
};

export const createDeleteFilterTagDTO = (filtersTagKey, filterTagData = {}) => {
    return {
        FilterTagName: filtersTagKey,
        FilterGroupLabel: filterTagData?.Label,
    };
};

export const isAlreadyExistFiltersTagName = (filtersTagName = '', filtersTagsList = []) => {
    return (
        filtersTagsList.filter((filter) => filter.Label.trim().toLowerCase() === filtersTagName.trim().toLowerCase())
            .length > 0
    );
};
