import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Modal, Icon } from '@geneui/components';
import { CustomTabs } from 'components';
// Import Hooks
import { useHasPermission } from 'hooks';
// Import Constants
import { tabsKey, tabsToSwitchKey, CampaignTabsConfig } from './config';
import { PERMISSIONS, l } from 'constants/common';
// Import SCSS
import 'assets/scss/campaignPage.scss';

const CampaignTabs = ({
    tabActiveKey,
    switchers,
    setTabActiveKey,
    setSwitchers,
    segmentContent,
    templateContent,
    bonusContent,
    selectedRows,
    isDisabled,
}) => {
    const { t } = useTranslation();

    const { hasPermission } = useHasPermission();
    const hasSwitcher = useMemo(
        () => hasPermission(PERMISSIONS.ViewAwards),
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [tabs, setTabs] = useState(tabsCreator());

    function changeSwitcherHandler(tabKey) {
        return (checked) => {
            if (!checked && !switchers[tabsToSwitchKey[tabKey]]) {
                setIsVisibleModal(true);
                return;
            }

            setSwitchers({ ...switchers, [tabKey]: checked });

            if (checked) {
                tabChangeHandler(tabKey, true);
            } else if (tabKey === tabActiveKey) {
                tabChangeHandler(tabsToSwitchKey[tabKey], true);
            }
        };
    }

    const tabChangeHandler = useCallback(
        (key, fromModal = false) => {
            if (!fromModal && key !== tabsKey.Segment && !switchers[key]) {
                setSwitchers({ ...switchers, [key]: true });
            }

            setTabActiveKey(key);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [switchers],
    );

    const onCancel = () => {
        setIsVisibleModal(false);
    };

    const onOk = () => {
        let tabChangeKey;
        const tmpSwitchers = Object.keys(switchers).reduce((acc, current) => {
            if (!switchers[current]) {
                tabChangeKey = current;
            }
            acc = { ...acc, [current]: !switchers[current] };
            return acc;
        }, {});
        setSwitchers(tmpSwitchers);
        tabChangeHandler(tabChangeKey, true);
        setIsVisibleModal(false);
    };

    function getContent(tabKey) {
        switch (tabKey) {
            case tabsKey.Segment:
                return segmentContent;
            case tabsKey.Template:
                return templateContent;
            case tabsKey.Bonus:
                return bonusContent;
            default:
                return segmentContent;
        }
    }

    function tabsCreator() {
        return CampaignTabsConfig.map(({ tabKey, buttonContent, permissions }) => {
            return {
                tabKey,
                permissions,
                tabTitle: buttonContent(selectedRows[tabKey]),
                tabContent: !isDisabled ? getContent(tabKey) : null,
                hasSwitcher: hasSwitcher && (tabKey === tabsKey.Template || tabKey === tabsKey.Bonus),
                switchChangeHandler: changeSwitcherHandler(tabKey),
                switcherChecked: switchers[tabKey],
            };
        });
    }

    useEffect(() => {
        setTabs(tabsCreator);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchers, selectedRows]);

    return (
        <div className="campaign-tabs-wrapper">
            <CustomTabs
                isDisabled={isDisabled}
                activeTabKey={tabActiveKey}
                tabsConfig={tabs}
                tabChangeHandler={tabChangeHandler}
                customCssClassNames="campaign-info-button-group"
            />
            {isVisibleModal && (
                <Modal
                    background="dark-background"
                    position="top"
                    title={t(l.CampaignSwitchChangeTitleMessage)}
                    size={'content-size'}
                    className="crm-modal crm-archive-modal"
                    appearance="compact"
                    closable={false}
                    onCancel={onCancel}
                    cancelText={t(l.Cancel)}
                    okText={t(l.Ok)}
                    closeOnClickOutside={true}
                    visible={isVisibleModal}
                    onOK={onOk}
                >
                    <div>
                        <div className="crm-circle-icon-cnt">
                            <Icon type="bc-icon-error-info" />
                        </div>
                    </div>
                    <span>{t(l.CampaignSwitchChangeMessage)}</span>
                </Modal>
            )}
        </div>
    );
};

CampaignTabs.propTypes = {
    tabActiveKey: PropTypes.string.isRequired,
    setTabActiveKey: PropTypes.func.isRequired,
    switchers: PropTypes.object.isRequired,
    setSwitchers: PropTypes.func.isRequired,
    segmentContent: PropTypes.node,
    templateContent: PropTypes.node,
    bonusContent: PropTypes.node,
    selectedRows: PropTypes.object,
    isDisabled: PropTypes.bool,
};

CampaignTabs.defaultProps = {
    segmentContent: React.node,
    templateContent: React.node,
    bonusContent: React.node,
    selectedRows: {},
    isDisabled: false,
};

export default memo(CampaignTabs);
