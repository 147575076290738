import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

const useFieldValidity = (fieldName, fieldDisplayNameKey, templateLangKey) => {
    const { t } = useTranslation();
    const pageValidation = useSelector((state) => state.pageValidation);

    return useMemo(() => {
        let isValid =
            isNil(pageValidation[templateLangKey + fieldName]?.isValid) ||
            pageValidation[templateLangKey + fieldName].isValid;

        let errorText;

        if (!isValid) {
            errorText = t(pageValidation[templateLangKey + fieldName]?.errorText, {
                fieldName: t(fieldDisplayNameKey),
                count: pageValidation[templateLangKey + fieldName]?.paramValue,
                ...(pageValidation[templateLangKey + fieldName]?.translationVariables &&
                    pageValidation[templateLangKey + fieldName].translationVariables),
            });
        }

        return {
            isValid,
            errorText,
        };
    }, [fieldDisplayNameKey, fieldName, pageValidation, t, templateLangKey]);
};

export default useFieldValidity;
