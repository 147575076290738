import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
// Import  Constants
import { l, ActionsIcons, EmailProviderTypeLabels } from 'constants/common';

const AdminProviderActionsButtons = ({ data, editProviderAction, cloneProviderAction }) => {
    const { t } = useTranslation();

    const isVisibleEditProvider = () => !isEmpty(data) && data.Type === EmailProviderTypeLabels.SMTP;
    const isVisibleCloneProvider = () => !isEmpty(data) && data.Type === EmailProviderTypeLabels.SMTP;

    return (
        <>
            {isVisibleEditProvider() && (
                <Tooltip text={t(l.EditProvider)}>
                    <Button
                        icon={ActionsIcons.Edit}
                        appearance="minimal"
                        color="primary"
                        onClick={() => editProviderAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleCloneProvider() && (
                <Tooltip text={t(l.CloneProvider)}>
                    <Button
                        icon={ActionsIcons.Clone}
                        appearance="minimal"
                        color="primary"
                        onClick={() => cloneProviderAction(data)}
                    />
                </Tooltip>
            )}
        </>
    );
};

AdminProviderActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    editProviderAction: PropTypes.func.isRequired,
    cloneProviderAction: PropTypes.func.isRequired,
};

export default memo(AdminProviderActionsButtons);
