import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import UI components
import { AccessControl } from 'components';
import Option from '@geneui/components/Option';
// Import hook
import { useGoToRoute } from 'hooks';

const Navigation = (props) => {
    const { t } = useTranslation();
    const { navigation } = useSelector((state) => state.header);
    const { goToRoute } = useGoToRoute();
    const { setNavigationVisibilityStatus, navigationList } = props;

    const handleOnClick = (routeModel) => {
        return () => {
            setNavigationVisibilityStatus(false);
            goToRoute(routeModel);
        };
    };

    const isActive = (itemRoute, navigationRoute) => {
        const entityPathname = navigationRoute.split('/')[0];
        return itemRoute.includes(entityPathname);
    };

    return (
        <div className="navigation-popover-content">
            <div className="content">
                {navigationList.map(({ icon, routeModel, permission }, index) => (
                    <AccessControl key={index} permissions={permission}>
                        <Option
                            title={t(routeModel.name)}
                            leftCustomElement={icon}
                            active={isActive(routeModel.path, navigation.route)}
                            onClick={handleOnClick(routeModel)}
                        />
                    </AccessControl>
                ))}
            </div>
        </div>
    );
};

Navigation.propTypes = {
    setNavigationVisibilityStatus: PropTypes.func.isRequired,
    setNavigation: PropTypes.func.isRequired,
    navigationList: PropTypes.array.isRequired,
};

export default memo(Navigation);
