import { CRMRequestService, postCancelableRequest, getCancelableRequest } from './http.config';

const TemplateLanguagesHttpService = {
    getTemplateLanguagesList: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Partner/GetTemplateLanguages',
        });
    },

    editTemplateLanguages: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Partner/UpdateTemplateLanguages',
        });
    },
};

export default TemplateLanguagesHttpService;
