import { isNull, keys } from 'lodash';
// Import Services
import { TemplateSymbolsCountFields } from 'services/template';
// Import Constants
import { ErrorShowType, l, TemplateInputTypes } from 'constants/common';

const bodyScenario = {
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'maxLength',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: TemplateSymbolsCountFields.FastexNotification,
        },
    ],
};

const subjectScenario = {
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'minLength',
            ErrorMessage: l.MustContainMinimumCharacters,
            ParametersValues: '2',
        },
        {
            ValidationType: 'maxLength',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: '63',
        },
    ],
};

const notificationCategoryScenario = {
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
    ],
};

const fastexNotificationTemplateValidationScenario = () => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    CrossValidation: {
        isValid: null,
        errorText: '',
        value: {},
        type: ErrorShowType.Toaster,
        validationCredentials: [
            {
                ValidationType: 'templateCrossValidation',
                ErrorMessage: '',
                ParametersValues: [TemplateInputTypes.Body],
            },
        ],
    },
    defaultLangBody: { ...bodyScenario },
    defaultLangSubject: { ...subjectScenario },
    defaultLangNotificationCategory: { ...notificationCategoryScenario },
});

function FastexNotificationTemplateScenarioPropertiesCreator(data, editCloneMode) {
    if (editCloneMode) {
        return keys(data).reduce((acc, id) => {
            acc[`${id}Body`] = {
                ...bodyScenario,
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].Body,
            };
            acc[`${id}Subject`] = {
                ...subjectScenario,
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].Subject,
            };
            acc[`${id}NotificationCategory`] = {
                ...notificationCategoryScenario,
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].Subject,
            };
            return acc;
        }, {});
    }
    return data.reduce((acc, id) => {
        acc[`${id}Body`] = { ...bodyScenario };
        acc[`${id}Subject`] = { ...subjectScenario };
        acc[`${id}NotificationCategory`] = { ...notificationCategoryScenario };
        return acc;
    }, {});
}

export { fastexNotificationTemplateValidationScenario, FastexNotificationTemplateScenarioPropertiesCreator };
