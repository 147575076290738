import { cloneDeep, isEqual } from 'lodash';
import { useState, useRef } from 'react';

const useFilterFieldsState = (defaultInputsFilters, changeTimeout = 300) => {
    const [filters, setFilters] = useState(() => cloneDeep(defaultInputsFilters));
    const filtersRef = useRef(filters);

    const [appliedFilters, setAppliedFilters] = useState(() => cloneDeep(defaultInputsFilters));
    const appliedFiltersRef = useRef(appliedFilters);

    const [isResetDisabled, setIsResetDisabled] = useState(true);
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);

    const [timeoutKey, setTimeoutKey] = useState(null);

    const SetFilters = (newFilters) => {
        filtersRef.current = newFilters;
        setFilters(() => newFilters);
    };

    const SetAppliedFilters = (newAppliedFilters) => {
        appliedFiltersRef.current = newAppliedFilters;
        setAppliedFilters(() => newAppliedFilters);
    };

    const isEqualFilters = (filter1, filter2) => {
        const tmpAppliedFilters = filter1.map((item) => ({ ...item, data: null }));
        const tmpFilters = filter2.map((item) => ({ ...item, data: null }));
        return isEqual(tmpAppliedFilters, tmpFilters);
    };

    const changeFilters = (newFilters, changeApplyBtnDisabledState = true) => {
        SetFilters(cloneDeep(newFilters));
        clearTimeout(timeoutKey);
        if (changeApplyBtnDisabledState) {
            setTimeoutKey(
                setTimeout(() => {
                    setIsApplyDisabled(isEqualFilters(appliedFiltersRef.current, filtersRef.current));
                }, changeTimeout),
            );
        }
    };

    const applyFilters = () => {
        SetAppliedFilters(cloneDeep(filtersRef.current));
        setIsResetDisabled(isEqualFilters(appliedFiltersRef.current, defaultInputsFilters));
        setIsApplyDisabled(true);
    };
    const resetFilters = () => {
        clearTimeout(timeoutKey);
        setTimeoutKey(null);

        SetFilters(cloneDeep(defaultInputsFilters));
        applyFilters();
    };

    return { filters, appliedFilters, changeFilters, applyFilters, resetFilters, isResetDisabled, isApplyDisabled };
};

export default useFilterFieldsState;
