import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop, toString } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Components
import { AccessControl } from 'components';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
// Import Hooks
import { useGACustomEvent } from 'hooks';
// Import Constants
import {
    StateModesLabels,
    CampaignStatusLabels,
    l,
    PERMISSIONS,
    ActionsIcons,
    GACategoryEvents,
} from 'constants/common';

const { GADetailsCategory } = GACategoryEvents;

const CampaignActionsButtons = ({
    data,
    unArchiveCampaignAction,
    archiveCampaignAction,
    deleteCampaignAction,
    cloneCampaignAction,
    editCampaignAction,
    viewCampaignReportAction,
    infoCampaignAction,
    stopSendCampaignAction,
    changeModeCampaignAction,
}) => {
    const { State, Status, IsNonMarketing, ArchivedDate } = data;
    const status = toString(Status);

    const { t } = useTranslation();
    const { logCustomEvent } = useGACustomEvent();

    const isArchive = !isNil(ArchivedDate);
    const isLive = State === StateModesLabels.Live;
    const isDraft = State === StateModesLabels.Draft;
    const isCompleted = status === CampaignStatusLabels.Completed;
    const isStoped = status === CampaignStatusLabels.Stoped;
    const isInProgress = status === CampaignStatusLabels.InProgress;
    const isIdle = status === CampaignStatusLabels.Idle;

    const isVisibleDelete = () => !isArchive && !isCompleted && !isStoped && !isInProgress && !isIdle;
    const isVisibleEdit = () => !isArchive && !isCompleted && !isStoped && !isInProgress;
    const isVisibleChangeMode = () => !isArchive && !isCompleted && !isStoped && !isInProgress && !isLive;
    const isVisibleArchive = () => (!isArchive && isCompleted) || (!isArchive && isStoped);
    const isVisibleStopSend = () => !isArchive && !isCompleted && !isStoped && !isDraft;

    let permissionsProps = { permissions: PERMISSIONS.EditCampaign };
    if (IsNonMarketing) {
        permissionsProps = { mandatoryPermissions: [PERMISSIONS.EditCampaign, PERMISSIONS.ManageNonMarketingCampaign] };
    }

    const onInfoAction = () => {
        logCustomEvent(
            GADetailsCategory.name,
            GADetailsCategory.events.campaignInfoActionButton,
            'campaign info action button',
            1,
        );
        infoCampaignAction(data);
    };

    const onReportAction = () => {
        logCustomEvent(
            GADetailsCategory.name,
            GADetailsCategory.events.campaignReportActionButton,
            'campaign report action button',
            1,
        );
        viewCampaignReportAction(data);
    };

    return (
        <>
            {isVisibleDelete() && (
                <AccessControl permissions={PERMISSIONS.DeleteCampaign}>
                    <Tooltip text={t(l.DeleteCampaign)}>
                        <Button
                            icon={ActionsIcons.Delete}
                            appearance="minimal"
                            color="primary"
                            onClick={() => deleteCampaignAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleEdit() && (
                <AccessControl {...permissionsProps}>
                    <Tooltip text={t(l.EditCampaign)}>
                        <Button
                            icon={ActionsIcons.Edit}
                            appearance="minimal"
                            color="primary"
                            onClick={() => editCampaignAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isArchive && (
                <Tooltip text={t(l.UnarchiveCampaign)}>
                    <Button
                        icon={ActionsIcons.UnArchive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => unArchiveCampaignAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleArchive() && (
                <Tooltip text={t(l.ArchiveCampaign)}>
                    <Button
                        icon={ActionsIcons.Archive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => archiveCampaignAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleChangeMode() && (
                <Tooltip text={t(l.ChangeCampaignMode)}>
                    <Button
                        icon={ActionsIcons.TransferBold}
                        appearance="minimal"
                        color="primary"
                        onClick={() => changeModeCampaignAction(data)}
                    />
                </Tooltip>
            )}
            {!isDraft && (
                <Tooltip text={t(l.CampaignReport)}>
                    <Button
                        icon={ActionsIcons.ViewReport}
                        appearance="minimal"
                        color="primary"
                        onClick={onReportAction}
                    />
                </Tooltip>
            )}
            {isVisibleStopSend() && (
                <AccessControl permissions={PERMISSIONS.ExecuteCampaign}>
                    <Tooltip text={t(l.StopCampaign)}>
                        <Button
                            icon={ActionsIcons.StatusStop}
                            appearance="minimal"
                            color="primary"
                            onClick={() => stopSendCampaignAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            <AccessControl permissions={PERMISSIONS.CreateCampaign}>
                <Tooltip text={t(l.CloneCampaign)}>
                    <Button
                        icon={ActionsIcons.Clone}
                        appearance="minimal"
                        color="primary"
                        onClick={() => cloneCampaignAction(data)}
                    />
                </Tooltip>
            </AccessControl>
            {infoCampaignAction && (
                <Tooltip text={t(l.CampaignInfo)}>
                    <Button icon={ActionsIcons.Stop} appearance="minimal" color="primary" onClick={onInfoAction} />
                </Tooltip>
            )}
        </>
    );
};

CampaignActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    unArchiveCampaignAction: PropTypes.func,
    archiveCampaignAction: PropTypes.func,
    deleteCampaignAction: PropTypes.func,
    cloneCampaignAction: PropTypes.func,
    viewCampaignReportAction: PropTypes.func,
    stopSendCampaignAction: PropTypes.func,
    changeModeCampaignAction: PropTypes.func,
    editCampaignAction: PropTypes.func,
    infoCampaignAction: PropTypes.func,
};

CampaignActionsButtons.defaultProps = {
    unArchiveCampaignAction: noop,
    archiveCampaignAction: noop,
    deleteCampaignAction: noop,
    cloneCampaignAction: noop,
    editCampaignAction: noop,
    viewCampaignReportAction: noop,
    stopSendCampaignAction: noop,
    changeModeCampaignAction: noop,
};

export default memo(CampaignActionsButtons);
