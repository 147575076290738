import React, { useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isString, isUndefined } from 'lodash';
import { List } from 'react-virtualized';
import classNames from 'classnames';
// Import UI Components
import { Option, Empty, ExtendedInput, BusyLoader, Tooltip } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const OptionListContent = ({
    list,
    keys,
    titles,
    rowCount,
    searchHandler,
    optionValue,
    contentTop,
    optionSelectHandler,
    listWidth,
    listHeight,
    listRowHeight,
    selectedPropertyKey,
    searchValue,
    hideTitleWrapper,
    emptySize,
    isLoading,
}) => {
    const { t } = useTranslation();
    const searchRef = useRef();

    const getRow = (item) => {
        if (!isUndefined(item)) {
            return keys.map((element, index) => {
                return (
                    <Tooltip
                        key={`${item[selectedPropertyKey]}_${index}`}
                        title={isString(item[element]) ? item[element] : null}
                    >
                        <span className={`crm-option-column-${index} ellipsis-text`}>{item[element]}</span>
                    </Tooltip>
                );
            });
        }
    };

    const getTitle = () => {
        return titles.map((element, index) => {
            return (
                <span key={element} className={`crm-option-header-column-${index}`}>
                    {element}
                </span>
            );
        });
    };

    const init = () => {
        searchRef.current.focus();
    };

    useEffect(init, []);

    return (
        <div className="crm-option-list-content" style={{ top: `${contentTop}px` }}>
            <ExtendedInput ref={searchRef} value={searchValue} onChange={(e) => searchHandler(e)} />
            {!hideTitleWrapper && (
                <Option title={getTitle()} border="bottom" className="crm-option-list-content-column-names" />
            )}
            <div
                className={classNames('crm-option-list-content__option-list', {
                    'small-empty-option-list': isEmpty(list) && emptySize === 'small',
                })}
            >
                <BusyLoader isBusy={isLoading}>
                    {!isLoading && (
                        <>
                            {!isEmpty(list) ? (
                                <List
                                    width={listWidth}
                                    height={listHeight}
                                    rowCount={rowCount}
                                    rowHeight={listRowHeight}
                                    rowRenderer={({ index, style }) => {
                                        return (
                                            <Option
                                                style={style}
                                                key={list[index][selectedPropertyKey]}
                                                leftCustomElement={getRow(list[index])}
                                                onClick={() => optionSelectHandler(list[index])}
                                                active={list[index][selectedPropertyKey] === optionValue}
                                            />
                                        );
                                    }}
                                />
                            ) : (
                                <Empty title={t(l.NoDataToDisplay)} size={emptySize} />
                            )}
                        </>
                    )}
                </BusyLoader>
            </div>
        </div>
    );
};

OptionListContent.propTypes = {
    list: PropTypes.array.isRequired,
    keys: PropTypes.array.isRequired,
    titles: PropTypes.array.isRequired,
    rowCount: PropTypes.number,
    searchHandler: PropTypes.func.isRequired,
    optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    optionSelectHandler: PropTypes.func.isRequired,
    contentTop: PropTypes.number,
    listWidth: PropTypes.number,
    listHeight: PropTypes.number,
    listRowHeight: PropTypes.number,
    selectedPropertyKey: PropTypes.string,
    searchValue: PropTypes.string,
    emptySize: PropTypes.string,
    hideTitleWrapper: PropTypes.bool,
    isLoading: PropTypes.bool,
};

OptionListContent.defaultProps = {
    rowCount: 0,
    contentTop: 50,
    emptySize: 'big',
    selectedPropertyKey: 'value',
    searchValue: '',
    listWidth: 258,
    listHeight: 240,
    listRowHeight: 40,
    hideTitleWrapper: false,
    isLoading: false,
};

export default memo(OptionListContent);
