/* eslint-disable react/display-name */
import React from 'react';
import { RoutesList } from 'routes';
// Import Components
import { TittleLink, LabelTag, CopyToClipboard } from 'components';
//Import UI Components
import { Icon } from '@geneui/components';
// Import Constants
import {
    FilterHolderFields,
    FilterComparisions,
    DateTimeFormat,
    l,
    CampaignStatusLabels,
    CampaignStatus,
    StateModesLabels,
    FullStateModes,
    DataTableColumnsCustomTypes,
    ArchiveStates,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';
import { isNil } from 'lodash';
const { CAMPAIGN_REPORT } = RoutesList;

const { customMomentWithoutTimezoneConversion } = Helpers;

const { Equals, Like, Between, IsBlank } = FilterComparisions;
const { textInput, select, rangeDate, multiSelect } = FilterHolderFields;

const FilterHolderFieldsConfigs = {
    defaultInputsData: {
        defaultInputsDataIds: {
            CategoryId: 4,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CampaignId',
                comparision: Equals,
                displayName: l.CampaignId,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Name',
                comparision: Like,
                displayName: l.Title,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedBy.Name',
                comparision: Like,
                displayName: l.User,
                value: textInput.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Status',
                comparision: Equals,
                displayName: l.Status,
                value: select.defaultValue,
                data: CampaignStatus,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CategoryId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: Equals,
                displayName: l.Label,
                value: multiSelect.defaultValue,
                data: [],
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.CreatedDate,
                value: rangeDate.defaultValue,
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'NextRunDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.NextRunDate,
                value: rangeDate.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'State',
                comparision: Equals,
                displayName: l.Mode,
                value: select.defaultValue,
                data: FullStateModes,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ArchivedDate',
                comparision: IsBlank,
                displayName: l.Archive,
                value: select.defaultValue,
                data: ArchiveStates,
            },
        ],
    },
};

const addGoalBlocker = (status) => {
    return +status === +CampaignStatusLabels.Completed || +status === +CampaignStatusLabels.Stoped;
};

const getTitle = (row) => {
    return row.data.Name.Name;
};

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.CampaignId),
                dataKey: 'CampaignId',
                sortable: true,
                colRenderer: (id) => <CopyToClipboard>{id}</CopyToClipboard>,
            },
            {
                text: t(l.Title),
                dataKey: 'Name',
                sortable: true,
                isPermanent: true,
                // eslint-disable-next-line react/prop-types
                colRenderer: ({ Name, CampaignId, HasBonus }) => {
                    return (
                        <div className="crm-list-column-title-icon-components">
                            <TittleLink path={CAMPAIGN_REPORT} name={Name} queryParams={{ campaignId: CampaignId }} />
                            {HasBonus && <Icon type="bc-icon-send-bonus-block" />}
                        </div>
                    );
                },
            },
            {
                text: t(l.Provider),
                dataKey: 'ProviderType',
                sortable: false,
            },
            {
                text: t(l.User),
                dataKey: 'CreatedBy.Name',
                sortable: true,
            },
            {
                text: t(l.Status),
                dataKey: 'Status',
                sortable: true,
                colRenderer: (status) => (
                    <div className={`crm-status-badge-cnt crm-cj-status-label-${status}`}>
                        {t(CampaignStatusLabels[status])}
                    </div>
                ),
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.CreatedDate),
                dataKey: 'CreatedDate',
                sortable: true,
                formatter: (CreatedDate) => {
                    return CreatedDate && customMomentWithoutTimezoneConversion(CreatedDate).format(DateTimeFormat);
                },
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.LastRunDate),
                dataKey: 'LastRunDate',
                sortable: true,
                formatter: (LastRunDate) => {
                    return LastRunDate && customMomentWithoutTimezoneConversion(LastRunDate).format(DateTimeFormat);
                },
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.NextRunDate),
                dataKey: 'NextRunDate',
                sortable: true,
                formatter: (NextRunDate) => {
                    return NextRunDate && customMomentWithoutTimezoneConversion(NextRunDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Goal),
                dataKey: 'GoalName',
                sortable: false,
                colRenderer: (_name, _index, row) => (
                    <div className="set-category">
                        {!isNil(row?.data?.GoalName) ? (
                            <LabelTag
                                color={`var(${`--status-label-goal-color`})`}
                                labelName={row?.data?.GoalName}
                                onClick={() => {
                                    !addGoalBlocker(row.data.Status) && row.addGoalFn(row?.data);
                                }}
                                icon={
                                    !addGoalBlocker(row.data.Status) ? (
                                        <button className="remove-btn" onClick={() => row.deleteGoalFn(row?.data)}>
                                            <Icon type="bc-icon-is-not" />
                                        </button>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        ) : !addGoalBlocker(row.data.Status) ? (
                            <button className="set-category-text" onClick={() => row.addGoalFn(row?.data)}>
                                {t(l.SetGoal)}
                            </button>
                        ) : (
                            <>{t(l.NA)}</>
                        )}
                    </div>
                ),
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.LastModified),
                dataKey: 'ModifiedDate',
                sortable: true,
                formatter: (ModifiedDate) => {
                    return ModifiedDate && customMomentWithoutTimezoneConversion(ModifiedDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Mode),
                dataKey: 'State',
                sortable: true,
                colRenderer: (state) => (
                    <div className={`crm-mode-badge-cnt crm-cj-mode-label-${state}`}>{t(StateModesLabels[state])}</div>
                ),
            },
        ],
    };
};

const modalsStateSetDictKeys = {
    deleteCampaign: 'deleteCampaign',
    archiveCampaign: 'archiveCampaign',
    unarchiveCampaign: 'unarchiveCampaign',
    stopSendCampaign: 'stopSendCampaign',
    changeModeCampaign: 'changeModeCampaign',
    infoCampaign: 'infoCampaign',
    setGoalCampaign: 'setGoalCampaign',
    deleteGoalCampaign: 'deleteGoalCampaign',
    deleteTemplate: 'deleteTemplate',
    testTemplate: 'testTemplate',
    archiveTemplate: 'archiveTemplate',
    unarchiveTemplate: 'unarchiveTemplate',
    editTemplate: 'editTemplate',
    deleteSegment: 'deleteSegment',
    convertToReport: 'convertToReport',
    archiveSegment: 'archiveSegment',
    unarchiveSegment: 'unarchiveSegment',
    editSegment: 'editSegment',
};

export { FilterHolderFieldsConfigs, DataTableColumnsConfigs, getTitle, modalsStateSetDictKeys };
