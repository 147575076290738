import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty, has } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import components
import { DataTable, DownloadToolbar } from 'components';
// Import constants
import { l } from 'constants/common';
// Import Services
import { SegmentHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Hooks
import { useRequest } from 'hooks';
// Import SCSS
import 'assets/scss/staticSegmentInfo.scss';

const { saveToDisk } = Helpers;

const StaticSegmentInfo = ({ staticSegmentInfoData }) => {
    const { t } = useTranslation();

    const [uploadedClients, setUploadedClients] = useState([]);
    const [uploadedClientsColumns, setUploadedClientsColumns] = useState([]);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isDisableDownload, setIsDisableDonwload] = useState(true);

    const { doPostRequest } = useRequest();
    const { downloadStaticSegmentInfoRequest } = useRef({
        downloadStaticSegmentInfoRequest: SegmentHttpService.downloadStaticSegmentInfo(),
    }).current;

    const mapUploadedClientsColumns = (columns) =>
        columns.map((columns, index) => ({
            index,
            text: t(columns.DisplayName),
            dataKey: columns.ColumnName,
            sortable: false,
        }));

    const mapUploadedClients = (data) => {
        const tmpUploadedClients = data?.Data?.map((elem, index) => ({
            index,
            actionBar: false,
            dragDisable: true,
            hasHover: true,
            data: data.Columns.reduce(
                (accumulator, { ColumnName }, columnIndex) => {
                    if (ColumnName === 'ClientId') {
                        const clientId = data?.Data[index][data?.Columns[columnIndex]?.ColumnName];
                        accumulator[ColumnName] = clientId === '0' ? t(l.NotClientYet) : clientId;
                        return accumulator;
                    }
                    accumulator[ColumnName] = !isNil(elem[ColumnName]) ? elem[ColumnName].toString() : elem[ColumnName];
                    return accumulator;
                },
                { index },
            ),
        }));
        return isNil(tmpUploadedClients) ? [] : tmpUploadedClients;
    };

    useEffect(() => {
        if (!isNil(staticSegmentInfoData)) {
            setUploadedClientsColumns(mapUploadedClientsColumns(staticSegmentInfoData.Columns));
            setUploadedClients(mapUploadedClients(staticSegmentInfoData));
            setIsDisableDonwload(isEmpty(staticSegmentInfoData));
        }
        /*eslint react-hooks/exhaustive-deps:0*/
    }, [staticSegmentInfoData]);

    const getDataTableName = () => <span>{t(l.First200Clients)}</span>;

    const downloadClickHandler = (type, downloadDropdownValue) => {
        const fileName = `${staticSegmentInfoData.Name}_Segment`;
        const requestBody = {
            DocumentType: type,
            Id: staticSegmentInfoData.SegmentId,
            DownloadRowsCount: downloadDropdownValue,
            ReportType: 0,
            TopCount: downloadDropdownValue,
            fileName,
        };
        setIsFileLoading(true);

        return doPostRequest(downloadStaticSegmentInfoRequest.request, {
            requestBody,
        }).then((res) => {
            if (!has(res, 'HasError')) {
                saveToDisk(res, type, fileName);
            }

            setIsFileLoading(false);
        });
    };
    const getHeaderActions = () => {
        return (
            <DownloadToolbar
                downloadClickHandler={downloadClickHandler}
                isFileLoading={isFileLoading}
                isDisableDownload={isDisableDownload}
            />
        );
    };

    return (
        <div className="static-segment-info-container">
            <DataTable
                columnKey="dataKey"
                rowKey="index"
                name={getDataTableName()}
                data={uploadedClients}
                dataCount={uploadedClients.length}
                columns={uploadedClientsColumns}
                isLoading={isNil(staticSegmentInfoData)}
                withPagination={false}
                withPageSelector={false}
                headerActions={getHeaderActions()}
            />
        </div>
    );
};

StaticSegmentInfo.propTypes = {
    staticSegmentInfoData: PropTypes.object,
};

StaticSegmentInfo.defaultProps = {
    staticSegmentInfoData: null,
};

export default memo(StaticSegmentInfo);
