import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { HolderWrapper } from 'components';
import CJBlockInfoSidebarOptionList from './CJBlockInfoSidebarOptionList';
// Import Services
import { getBlocksGroup } from 'services/customerJourney';
// Import Constants
import { l } from 'constants/common';

const CJBlockInfoSidebar = ({
    isDisabled,
    defaultOpened,
    blockInfoData,
    elements,
    isInfoSideBarOpened,
    infoSidebarToggleHandler,
}) => {
    const { t } = useTranslation();

    const getTitle = () => {
        return (
            <>
                <p className={'crm-cj-block-info-sidebar_title-block-type'}>{`${t(
                    getBlocksGroup(blockInfoData?.data?.metaData?.Group)?.titleKey,
                )}: ${t(l[`CJBlock${blockInfoData?.data?.blockType}`])}`}</p>
                <p className={'crm-cj-block-info-sidebar_title-block-name'}>{blockInfoData?.data?.name}</p>
            </>
        );
    };

    return (
        <HolderWrapper
            isDisabled={isDisabled}
            title={getTitle()}
            opened={isInfoSideBarOpened}
            position="left"
            expandText={t(l.Expand)}
            collapseText={t(l.Collapse)}
            defaultOpened={defaultOpened}
            className="crm-cj-block-info-sidebar"
            filterIconType="bc-icon-info"
            openedWidth={'400px'}
            onChange={infoSidebarToggleHandler}
            footer={<></>}
            disableOnHover
        >
            {!isDisabled && (
                <CJBlockInfoSidebarOptionList
                    elements={elements}
                    options={blockInfoData?.data?.metaData?.Options}
                    apiModel={blockInfoData?.data?.apiModel}
                    blockId={blockInfoData?.id}
                />
            )}
        </HolderWrapper>
    );
};

CJBlockInfoSidebar.propTypes = {
    isDisabled: PropTypes.bool,
    defaultOpened: PropTypes.bool,
    blockInfoData: PropTypes.object.isRequired,
    infoSidebarToggleHandler: PropTypes.func.isRequired,
    elements: PropTypes.array.isRequired,
    isInfoSideBarOpened: PropTypes.bool,
};

CJBlockInfoSidebar.defaultProps = {
    isDisabled: false,
    defaultOpened: false,
    isInfoSideBarOpened: false,
};

export default memo(CJBlockInfoSidebar);
