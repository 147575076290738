import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import UI Components
import { Paper } from '@geneui/components';
//Import Components
import { ConfirmationModal, CustomTabs, LabelTag } from 'components';
import FireBaseSettingsContent from './FireBaseSettingsContent';
import OneSignalSettingsContent from './OneSignalSettingsContent';
//Import Constants
import { ActionsIcons, AlertTypes, l, ModalsClassNames, PushNotificationProviderKeys } from 'constants/common';
//Import Service
import { ProviderHttpService } from 'services/http';
//Import Hooks
import { useRequest, useToaster } from 'hooks';

const { success } = AlertTypes;

const PushNotificationSettingsSection = () => {
    const { t } = useTranslation();
    const { doGetRequest, doPostRequest } = useRequest();
    const { showToaster } = useToaster();

    const [isPushNotificationProvidersLoading, setIsPushNotificationProvidersLoading] = useState(false);
    const [pushNotificationProviders, setPushNotificationProviders] = useState(null);

    const [isSetDefaultModalOpen, setIsSetDefaultModalOpen] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [modalData, setModalData] = useState(null);

    const { getPushNotificationProviders, setDefaultPushNotificationProvider } = useMemo(
        () => ({
            getPushNotificationProviders: ProviderHttpService.getPushNotificationProviders(),
            setDefaultPushNotificationProvider: ProviderHttpService.setDefaultPushNotificationProvider(),
        }),
        [],
    );

    const defaultLabel = useMemo(
        () => <LabelTag color={`var(${`--status-label-goal-color`})`} labelName={t(l.Default)} />,
        [t],
    );

    const getPushNotificationProvidersData = useCallback(() => {
        setIsPushNotificationProvidersLoading(true);

        doGetRequest(getPushNotificationProviders.request, {
            successCallback: (data) => {
                if (isNil(data)) return;

                setPushNotificationProviders({
                    DefaultNotificationType: data.DefaultNotificationType,
                    OneSignal: {
                        appId: data.OneSignal?.AppId,
                        appKey: data.OneSignal?.AppKey,
                    },
                    FireBase: {
                        projectId: data.Firebase?.ProjectId,
                        privateKeyId: data.Firebase?.PrivateKeyId,
                        clientId: data.Firebase?.ClientId,
                        clientEmail: data.Firebase?.ClientEmail,
                    },
                });
            },
        }).finally(() => {
            setIsPushNotificationProvidersLoading(false);
        });
    }, [doGetRequest, getPushNotificationProviders.request]);

    const setDefaultPushNotificationProviderAction = useCallback(
        (notificationType) => {
            const firebaseKey = PushNotificationProviderKeys.FireBase;
            setModalData({
                notificationType,
                DefaultProviderName: notificationType === firebaseKey ? t(l.OneSignal) : t(l.FireBase),
                Name: notificationType === firebaseKey ? t(l.FireBase) : t(l.OneSignal),
            });
            setIsSetDefaultModalOpen(true);
        },
        [t],
    );

    const onSetDefaultHandler = () => {
        setIsModalLoading(true);

        doPostRequest(setDefaultPushNotificationProvider.request, {
            queryString: {
                notificationType: modalData.notificationType,
            },
            successCallback: () => {
                getPushNotificationProvidersData();

                showToaster(success, t(l.PushNotificationProviderSuccessfullySetAsDefault));
                onCloseModalHandler();
            },
        }).finally(() => {
            setIsModalLoading(false);
        });
    };

    const onCloseModalHandler = () => {
        setIsSetDefaultModalOpen(false);
        setIsModalLoading(false);
        setModalData(null);
    };

    const init = () => {
        getPushNotificationProvidersData();
    };

    const cleanUp = () => {
        return () => {
            getPushNotificationProviders.cancel('PushNotificationSettingsSection:getPushNotificationProviders');
            setDefaultPushNotificationProvider.cancel('ProviderActionsModals:setDefaultPushNotificationProvider');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    const tabsConfig = useMemo(() => {
        const baseProps = {
            isLoading: isPushNotificationProvidersLoading,
            onRefetchData: getPushNotificationProvidersData,
            onSetDefault: setDefaultPushNotificationProviderAction,
        };

        return [
            {
                tabTitle: (
                    <div className="crm-push-notification-settings-tab-title">
                        {t(l.FireBase)}
                        {pushNotificationProviders?.DefaultNotificationType === PushNotificationProviderKeys.FireBase &&
                            defaultLabel}
                    </div>
                ),
                tabKey: '0',
                tabContent: (
                    <FireBaseSettingsContent
                        {...baseProps}
                        initialData={pushNotificationProviders?.FireBase}
                        isDefault={
                            pushNotificationProviders?.DefaultNotificationType === PushNotificationProviderKeys.FireBase
                        }
                    />
                ),
            },
            {
                tabTitle: (
                    <div className="crm-push-notification-settings-tab-title">
                        {t(l.OneSignal)}
                        {pushNotificationProviders?.DefaultNotificationType ===
                            PushNotificationProviderKeys.OneSignal && defaultLabel}
                    </div>
                ),
                tabKey: '1',
                tabContent: (
                    <OneSignalSettingsContent
                        {...baseProps}
                        initialData={pushNotificationProviders?.OneSignal}
                        isDefault={
                            pushNotificationProviders?.DefaultNotificationType ===
                            PushNotificationProviderKeys.OneSignal
                        }
                    />
                ),
            },
        ];
    }, [
        defaultLabel,
        getPushNotificationProvidersData,
        isPushNotificationProvidersLoading,
        pushNotificationProviders,
        setDefaultPushNotificationProviderAction,
        t,
    ]);

    return (
        <>
            <Paper className="push-notification-settings card" paperDirection="column">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name ellipsis-text">
                                    {t(l.PushNotificationSettings)}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="content">
                    <CustomTabs tabsConfig={tabsConfig} />
                </div>
            </Paper>
            {isSetDefaultModalOpen && (
                <ConfirmationModal
                    onOk={onSetDefaultHandler}
                    onCancel={onCloseModalHandler}
                    isVisibleModal
                    titleText={t(l.SetDefaultPushNotificationProvider)}
                    questionLabel={t(l.AreYouSureYouWantToSetDefaultProvider, {
                        defaultProviderName: `"${modalData?.DefaultProviderName}"`,
                        currentProviderName: `"${modalData?.Name}"`,
                    })}
                    isLoading={isModalLoading}
                    iconType={ActionsIcons.SetDefault}
                    actionLabel={t(l.SetDefault)}
                    className={ModalsClassNames.Start}
                />
            )}
        </>
    );
};

export default memo(PushNotificationSettingsSection);
