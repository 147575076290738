import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'lodash';
// Import Hooks
import { useMenuItem, useRequest } from 'hooks';
// Import Services
import { TemplateHttpService, UtilsHttpService } from 'services/http';
// Import Components
import { UsedInSection } from 'components';
// Import UI Components
import { Icon, Tooltip, SkeletonLoader } from '@geneui/components';
// Import Constants
import { TemplateTypesLabels, MenuItemIds, l } from 'constants/common';

const { Email, Internal, SMS, CMSPopUp, PopUp, Telegram, PushNotification, DynamicPopUp } = TemplateTypesLabels;

const getMenuItemId = (type) => {
    switch (type) {
        case CMSPopUp:
            return MenuItemIds.CMSPopUpNotification;
        case PopUp:
            return MenuItemIds.PopUpNotification;
        default:
            return;
    }
};

const GeneralInfo = ({ templateId, isPredefined, name, description, type, additional }) => {
    const { t } = useTranslation();

    const { doGetRequest } = useRequest();

    const [urlTypesData, setUrlTypesData] = useState(null);

    const menuItemId = getMenuItemId(type);

    const { convertedData, isLoading, isLoadingConfig, restore } = useMenuItem(menuItemId, false);

    const { getCampaignsByTemplate, getPopUpComparisons } = useMemo(
        () => ({
            getCampaignsByTemplate: TemplateHttpService.getCampaignsByTemplate(),
            getPopUpComparisons: UtilsHttpService.getPopUpComparisons(),
        }),
        [],
    );

    const [isLoadingUsedIn, setIsLoadingUsedIn] = useState(false);
    const [usedInCampaigns, setUsedInCampaigns] = useState(null);

    const getUsages = () => {
        setIsLoadingUsedIn(true);
        doGetRequest(getCampaignsByTemplate.request, {
            queryString: { templateId },
            successCallback: (data) => {
                setUsedInCampaigns(data);
                setIsLoadingUsedIn(false);
            },
        });
    };

    const getUrlComparisonTypes = () => {
        doGetRequest(getPopUpComparisons.request, {
            successCallback: (data) => {
                const popUpNotificationUrlComparisons = data.reduce(
                    (acc, { Id, Name }) => ({ ...acc, [Id]: Name }),
                    {},
                );
                setUrlTypesData(popUpNotificationUrlComparisons);
            },
        });
    };

    const init = () => {
        if (!isPredefined) {
            getUsages();
        }

        if (!isPredefined && type === PopUp) {
            getUrlComparisonTypes();
            restore([+additional.Id]);
        }

        if (type === CMSPopUp && !isNil(additional?.Alias)) {
            restore([+additional.Alias]);
        }
    };

    const destroyComponent = () => {
        return () => {
            getCampaignsByTemplate.cancel('TemplateModal:GeneralInfo:getCampaignsByTemplate');
            setIsLoadingUsedIn(false);
            setUsedInCampaigns(null);
        };
    };

    useEffect(init, []);

    useEffect(destroyComponent, []);

    return (
        <div className="segment-modal-head">
            <div className="title-wrapper">
                <p className="title-wrapper-text">{t(l.GeneralInfo)}</p>
                <span className="title-wrapper-line" />
            </div>

            <Tooltip text={templateId} position="bottom">
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.TemplateId)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{templateId}</span>
                </div>
            </Tooltip>

            <Tooltip text={name} position="bottom">
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.Title)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{name}</span>
                </div>
            </Tooltip>

            <Tooltip text={description} position="bottom">
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.Description)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">
                        {isEmpty(description) ? t(l.NA) : description}
                    </span>
                </div>
            </Tooltip>

            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{t(l.Type)}</span>
                <span className="ellipsis-text kv-value kv-value-css">
                    {
                        <div className="crm-template-type-label">
                            {type === Email && <Icon type="icon bc-icon-message-closed crm-template-type-icon email" />}
                            {type === Internal && <Icon type="bc-icon-sms crm-template-type-icon internal" />}
                            {type === SMS && <Icon type="bc-icon-message-closed crm-template-type-icon sms" />}
                            {type === PopUp && <Icon type="bc-icon-message-closed crm-template-type-icon popup" />}
                            {type === DynamicPopUp && (
                                <Icon type="bc-icon-message-closed crm-template-type-icon dynamic-pop-up" />
                            )}
                            {type === CMSPopUp && <Icon type="bc-icon-message-closed crm-template-type-icon popup" />}
                            {type === Telegram && (
                                <Icon type="bc-icon-send-telegram-message-block crm-template-type-icon telegram" />
                            )}
                            {type === PushNotification && (
                                <Icon type="bc-icon-bell-outline crm-template-type-icon push-notification" />
                            )}
                            <span>{TemplateTypesLabels[type]}</span>
                        </div>
                    }
                </span>
            </div>

            {type === CMSPopUp && !isNil(additional?.Alias) && (
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.CMSPopUp)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">
                        <SkeletonLoader isBusy={isLoading || isLoadingConfig}>
                            {convertedData.find((item) => item.value.toString() === additional.Alias)?.label ?? t(l.NA)}
                        </SkeletonLoader>
                    </span>
                </div>
            )}

            {type === PopUp && !isNil(additional) && (
                <>
                    <Tooltip text={additional.Alias} position="bottom">
                        <div className="key-value-h">
                            <span className="ellipsis-text kv-label">{t(l.Name)}</span>
                            <span className="ellipsis-text kv-value kv-value-css">
                                <SkeletonLoader isBusy={isLoading || isLoadingConfig}>
                                    {convertedData.find((item) => item.value.toString() === additional.Id)?.label ??
                                        t(l.NA)}
                                </SkeletonLoader>
                            </span>
                        </div>
                    </Tooltip>

                    <Tooltip text={urlTypesData?.[additional.Page.Comparison]} position="bottom">
                        <div className="key-value-h">
                            <span className="ellipsis-text kv-label">{t(l.Operator)}</span>
                            <span className="ellipsis-text kv-value kv-value-css">
                                <SkeletonLoader isBusy={isNil(urlTypesData)}>
                                    {urlTypesData?.[additional.Page.Comparison] ?? t(l.NA)}
                                </SkeletonLoader>
                            </span>
                        </div>
                    </Tooltip>

                    <Tooltip text={additional.Page.Value} position="bottom">
                        <div className="key-value-h">
                            <span className="ellipsis-text kv-label">{t(l.Route)}</span>
                            <span className="ellipsis-text kv-value kv-value-css">
                                {isEmpty(additional.Page.Value) ? t(l.NA) : additional.Page.Value}
                            </span>
                        </div>
                    </Tooltip>
                </>
            )}

            <UsedInSection
                isLoadingUsedIn={isLoadingUsedIn}
                usedInData={usedInCampaigns}
                isVisible={!isPredefined}
                notUsedInDisplayNameKey={l.TemplateIsNotUsed}
            />
        </div>
    );
};

GeneralInfo.propTypes = {
    templateId: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isPredefined: PropTypes.bool,
    additional: PropTypes.object,
};

GeneralInfo.defaultProps = {
    isPredefined: false,
};

export default memo(GeneralInfo);
