import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import UI components
import { Modal } from '@geneui/components';
// Import Components
import { DataTable } from 'components';
// Import Constants
import { LanguagesFlagsPaths } from 'constants/utils';
import { l } from 'constants/common';

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.Language),
                dataKey: 'Language',
                sortable: true,
                // eslint-disable-next-line react/display-name
                colRenderer: (language, _index, row) => {
                    const { Id } = row.data;

                    return (
                        <div className="select-languages-block">
                            <span className="select-language-img">
                                <img src={LanguagesFlagsPaths[Id]} alt={language} />
                            </span>
                            <p>{language}</p>
                        </div>
                    );
                },
            },
        ],
    };
};

const SelectLanguageWithSearchModal = ({ languageList, onCancel, onOk }) => {
    const { t } = useTranslation();

    const [values, setValues] = useState();

    const handleClickOnCheckbox = (selected) => {
        setValues(selected);
    };

    const handleSave = () => {
        onOk(values);
    };

    const init = () => {
        setValues(languageList.filter((item) => item?.data?.isRowSelected));
    };

    useEffect(init, []);

    const { columns } = DataTableColumnsConfigs(t);

    return (
        <Modal
            background="dark-background"
            position="center"
            title={t(l.SelectLanguage)}
            className="crm-modal add-language c-add-lang"
            closable={false}
            onCancel={onCancel}
            cancelText={t(l.Cancel)}
            okText={t(l.Save)}
            closeOnClickOutside={true}
            visible={true}
            onOK={handleSave}
        >
            {!isNil(values) && (
                <DataTable
                    rowKey="Id"
                    columnKey="dataKey"
                    withSearch={true}
                    withPagination={false}
                    withPageSelector={false}
                    isColumnsSortable={true}
                    data={languageList}
                    dataCount={languageList.length}
                    columns={columns}
                    getSelectedRowsList={handleClickOnCheckbox}
                    defaultMultiSelectedRows={values}
                    isHasRowMultiSelect={true}
                    fromSelectedItemsModal={false}
                />
            )}
        </Modal>
    );
};

SelectLanguageWithSearchModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    languageList: PropTypes.array.isRequired,
};

export default memo(SelectLanguageWithSearchModal);
