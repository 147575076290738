// Import constants
import { ErrorShowType, l, MonthDayYearFormat } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMoment } = Helpers;

const ScheduleScenario = (schedule) => ({
    SchedulerStartDate: {
        isValid: null,
        value: {
            fromDate: customMoment(schedule.SchedulerStartDate.fromDate).format(MonthDayYearFormat),
            toDate: customMoment(schedule.SchedulerStartDate.toDate).format(MonthDayYearFormat),
            fromTime: schedule.SchedulerStartDate.fromTime,
            timeZone: schedule.SchedulerStartDate.timeZone,
        },
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'fromDateBiggerThanCurrentDate',
                ErrorMessage: l.StartDateMustBeBiggerThanCurrentDate,
                ParametersValues: 'false',
            },
            {
                ValidationType: 'dateCompareThanDate',
                ErrorMessage: l.StartDateMustBeSmallerThanEndDate,
                ParametersValues: 'false',
            },
        ],
    },
    SchedulerEndDate: {
        isValid: null,
        value: {
            fromDate: customMoment(schedule.SchedulerEndDate.fromDate).format(MonthDayYearFormat),
            toDate: customMoment(schedule.SchedulerEndDate.toDate).format(MonthDayYearFormat),
            fromTime: schedule.SchedulerEndDate.fromTime,
            timeZone: schedule.SchedulerEndDate.timeZone,
        },
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'toDateBiggerThanCurrentDate',
                ErrorMessage: l.EndDateMustBeBiggerThanCurrentDate,
                ParametersValues: 'false',
            },
            {
                ValidationType: 'dateCompareThanDate',
                ErrorMessage: l.EndDateMustBeBiggerThanStartDate,
                ParametersValues: 'false',
            },
        ],
    },
    SchedulerStartTime: {
        isValid: null,
        value: schedule.SchedulerStartDate.fromTime,
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'startTimeValidation',
                ErrorMessage: l.InvalidTime,
            },
        ],
    },
});

const ParametersScenario = (value) => ({
    isValid: false,
    errorText: '',
    value: value || '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
    ],
});

const EmailRecipientsScenario = (values) => ({
    EmailRecipients: {
        isValid: true,
        value: values.EmailRecipients,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'hasDeactivatedSelection',
                ErrorMessage: l.RemoveUnavailableSelection,
            },
        ],
    },
});

const ExportTypesScenario = (values) => ({
    ExportTypes: {
        isValid: true,
        forceValidation: true,
        value: values.ExportTypes,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'isEmpty',
                ErrorMessage: l.ExportFileFormatCannotBeEmpty,
            },
        ],
    },
});

const reportExecuteScheduleValidationScenario = () => ({});

function reportExecuteScheduleValidationCreate(data) {
    if (data.schedule) {
        return ScheduleScenario(data.property);
    } else if (data.parameter) {
        const { key, value } = data.property;
        return { [key]: ParametersScenario(value) };
    } else if (data.emailRecipients) {
        return EmailRecipientsScenario(data.property);
    } else if (data.exportTypes) {
        return ExportTypesScenario(data.property);
    }

    return null;
}
export { reportExecuteScheduleValidationScenario, reportExecuteScheduleValidationCreate };
