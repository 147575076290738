// Import Constants
import { FilterHolderFields, EntityStates, FilterComparisions, l, ArchiveStates } from 'constants/common';

const { Equals, Like, Between, In, IsBlank } = FilterComparisions;
const { textInput, select, rangeDate, multiSelect } = FilterHolderFields;

const getTitle = (row) => {
    return row.data.Name;
};

const FilterHolderFieldsConfigs = {
    idFieldName: 'TemplateId',
    storeKey: 'templateList',
    defaultInputsData: {
        defaultInputsDataIds: {
            Channel: 3,
            CategoryId: 4,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'TemplateId',
                comparision: Equals,
                displayName: l.TemplateId,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Name',
                comparision: Like,
                displayName: l.Title,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedBy.Name',
                comparision: Like,
                displayName: l.User,
                value: textInput.defaultValue,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'DeliveryMethod',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Channel,
                value: multiSelect.defaultValue,
                data: [],
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CategoryId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: Equals,
                displayName: l.Label,
                value: multiSelect.defaultValue,
                data: [],
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.CreatedDate,
                value: rangeDate.defaultValue,
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ModifiedDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.LastModified,
                value: rangeDate.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'IsUsed',
                comparision: Equals,
                displayName: l.State,
                value: select.defaultValue,
                data: EntityStates,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ArchivedDate',
                comparision: IsBlank,
                displayName: l.Archive,
                value: select.defaultValue,
                data: ArchiveStates,
            },
        ],
    },
};

export { FilterHolderFieldsConfigs, getTitle };
