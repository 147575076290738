import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isNil, isEmpty, keys } from 'lodash';
// Import UI components
import { Tabs, Tab, ExtendedInput, RadioGroup, Dropdown, Checkbox, Icon, Tooltip } from '@geneui/components';
// Import Components
import { CurrencyAutoFillSection } from 'components';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { l, BonusCalculationTypes, DynamicBonusTypes, BonusTypesLabels } from 'constants/common';
import { DynamicBonusTypesRadioGroupOptionsConfig } from './configs';

const { getCurrencyLabel } = Helpers;

const BonusCalculation = ({
    bonusType,
    bonusDetails,
    bonusDetailsAmounts,
    setBonusDetailsAmounts,
    radioGroupValue,
    setRadioGroupValue,
    bonusCalculationActiveTabKey,
    setBonusCalculationActiveTabKey,
    bonusCalculateActions,
    setBonusCalculateAction,
    bonusCalculateAction,
    bonusKind,
    setBonusKind,
    bonusKinds,
    considerLimits,
    setConsiderLimits,
    percentage,
    setPercentage,
    isVisibleRadioSelection,
    isDynamicDisabled,
    selectedWallet,
    setSelectedWallet,
    supportedCurrencies,
    supportedWallets,
}) => {
    const { t } = useTranslation();

    const pageValidation = useSelector((state) => state.pageValidation);
    const { partnerCurrencies } = useSelector((state) => state.partnerSettings);

    const secondaryCurrenciesDropdownData = useMemo(() => {
        if (isEmpty(partnerCurrencies)) {
            return [{ label: t(l.PrimaryWallet), value: null }];
        } else {
            const tmpResult = partnerCurrencies
                .filter((currency) => {
                    return currency.IsWalletAutocreatable;
                })
                .map((currency) => {
                    return {
                        label: currency.CurrencyId,
                        value: currency.CurrencyId,
                    };
                });
            tmpResult.unshift({ label: t(l.PrimaryWallet), value: null });
            return tmpResult;
        }
    }, [partnerCurrencies, t]);

    const { Settings } = useSelector((state) => state.userData);
    const currencyId = Settings.CurrencyId;

    const totalCurrencyRates = useSelector((state) => state.currencyRates);
    const currencyRates = useMemo(() => totalCurrencyRates[currencyId], [totalCurrencyRates, currencyId]);
    const { convertedData: currenciesDropdownData, isLoading: isLoadingCurrencyData } = useMenuItem(104);

    const radioGroupChangeHandler = (event) => {
        setRadioGroupValue(event.target.value);
    };

    const bonusCalculationTabChangeHandler = (key) => {
        setBonusCalculationActiveTabKey(key);
    };

    const bonusKindChangeHandler = (event) => {
        setBonusKind({ ...bonusKind, [radioGroupValue]: event.value });
    };

    const bonusCalculateActionChangeHandler = (event) => {
        setBonusCalculateAction(event.value);
    };

    const considerLimitsChangeHandler = (event) => {
        return setConsiderLimits({ ...considerLimits, [radioGroupValue]: event.target.checked });
    };

    const percentageValueChangeHandler = (event) => {
        return setPercentage({ ...percentage, [radioGroupValue]: event.target.value });
    };

    const staticBonusDetailsChangeHandler = (currencyCode) => {
        return (event) => {
            setBonusDetailsAmounts((prev) => ({ ...prev, [currencyCode]: event.target.value.replace('-', '') }));
        };
    };

    const onAutoFillClickHandler = (data) => {
        const { amount, selectedCurrency } = data;
        const result = keys(bonusDetailsAmounts).reduce((acc, val) => {
            acc[val] = totalCurrencyRates[val][selectedCurrency].Rate * amount;
            return acc;
        }, {});
        setBonusDetailsAmounts(result);
    };

    const isVisibleWalletSelection = useMemo(() => {
        return (
            !isEmpty(supportedWallets) &&
            ([BonusTypesLabels.FreeSpin, BonusTypesLabels.BoostBonus].includes(bonusType) ||
                supportedWallets.some((supportedWallet) => supportedCurrencies.includes(supportedWallet.CurrencyId)))
        );
    }, [supportedWallets, supportedCurrencies, bonusType]);

    return (
        <>
            {isVisibleWalletSelection && (
                <div className="wallet-selection-container">
                    <Dropdown
                        label={l.Wallet}
                        labelAppearance="swap"
                        value={selectedWallet}
                        onChange={(item) => setSelectedWallet(item.value)}
                        data={secondaryCurrenciesDropdownData}
                        hasSearch={true}
                        placeholder={l.Wallet}
                        className="wallet-dropdown"
                    />
                    <Tooltip text={t(l.BonusWalletInfo)}>
                        <Icon type="bc-icon-info" />
                    </Tooltip>
                </div>
            )}
            {isVisibleRadioSelection && (
                <Tabs
                    type="button"
                    position="top"
                    activeKey={bonusCalculationActiveTabKey}
                    onChange={bonusCalculationTabChangeHandler}
                >
                    <Tab key={BonusCalculationTypes.Static} title={t(l.Static)}>
                        <div className="bonus-calculation-static-tab-container">
                            {bonusDetails?.length > 1 && isNil(selectedWallet) && (
                                <CurrencyAutoFillSection
                                    dropdownData={currenciesDropdownData}
                                    onOk={onAutoFillClickHandler}
                                    isLoading={isLoadingCurrencyData}
                                    alertMessage={t(l.CurrencyAutoFillInfoAlert)}
                                />
                            )}

                            {bonusDetails
                                .filter(
                                    (x) =>
                                        isNil(selectedWallet) ||
                                        x.CurrencyCode === selectedWallet ||
                                        bonusType === BonusTypesLabels.FreeSpin,
                                )
                                .map(({ MinAmount, CurrencyCode, MaxAmount }, index) => (
                                    <div key={index} className="amount-wrap">
                                        <div className="amount-wrap-inner" key={index}>
                                            <div className="amount-text">
                                                <p className="amount-text-code"> {CurrencyCode}</p>
                                                <p className="amount-text-count">{`${t(
                                                    l.Amount,
                                                )} ${MinAmount} - ${MaxAmount}`}</p>
                                            </div>
                                            <ExtendedInput
                                                type="number"
                                                placeholder={t(l.Amount)}
                                                value={bonusDetailsAmounts[CurrencyCode]}
                                                onChange={staticBonusDetailsChangeHandler(CurrencyCode)}
                                                appearance="outline"
                                                isValid={
                                                    isNil(
                                                        pageValidation[CurrencyCode + BonusCalculationTypes.Static]
                                                            ?.isValid,
                                                    ) ||
                                                    pageValidation[CurrencyCode + BonusCalculationTypes.Static]?.isValid
                                                }
                                                errorText={t(
                                                    pageValidation[CurrencyCode + BonusCalculationTypes.Static]
                                                        ?.errorText,
                                                    {
                                                        fieldName: t(l.Amount),
                                                        count: pageValidation[
                                                            CurrencyCode + BonusCalculationTypes.Static
                                                        ]?.paramValue,
                                                    },
                                                )}
                                                label={getCurrencyLabel(
                                                    t(l.Amount),
                                                    bonusDetailsAmounts[CurrencyCode],
                                                    CurrencyCode,
                                                    currencyId,
                                                    currencyRates,
                                                )}
                                                labelAppearance="swap"
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Tab>
                    <Tab key={BonusCalculationTypes.Dynamic} title={t(l.Dynamic)} disabled={isDynamicDisabled}>
                        <>
                            <RadioGroup
                                name="controlled"
                                options={DynamicBonusTypesRadioGroupOptionsConfig.map((item) => ({
                                    value: item.value,
                                    label: (
                                        <>
                                            <p>{t(item.label)}</p>
                                            <Tooltip text={t(item.tooltip)} position="bottom">
                                                <Icon type="bc-icon-info" />
                                            </Tooltip>
                                        </>
                                    ),
                                }))}
                                value={radioGroupValue}
                                onChange={radioGroupChangeHandler}
                            />
                            <div className="bonus-items-wrap">
                                <div className="bonus-holder">
                                    {!isNil(radioGroupValue) && radioGroupValue !== DynamicBonusTypes.FromFile && (
                                        <>
                                            <div className="bonus-holder-title">
                                                <p className="ellipsis-text">{t(l.BonusKind)}</p>
                                                <Tooltip text={t(l.TooltipBonusCalculationBonusKind)} position="bottom">
                                                    <Icon type="bc-icon-info" />
                                                </Tooltip>
                                            </div>
                                            <Dropdown
                                                defaultValue={bonusKind[radioGroupValue]}
                                                isMultiSelect={false}
                                                disabled={false}
                                                inputSize="default"
                                                appearance="outline"
                                                data={bonusKinds[radioGroupValue]}
                                                onChange={bonusKindChangeHandler}
                                                isValid={
                                                    isNil(pageValidation.bonusKind?.isValid) ||
                                                    pageValidation.bonusKind?.isValid
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="bonus-limits">
                                    <div className="bonus-holder">
                                        {radioGroupValue === DynamicBonusTypes.Object && (
                                            <>
                                                <div className="bonus-holder-title">
                                                    <p className="ellipsis-text">{t(l.BonusCalculateAction)}</p>
                                                    <Tooltip
                                                        text={t(l.TooltipBonusCalculationAction)}
                                                        position="bottom"
                                                    >
                                                        <Icon type="bc-icon-info" />
                                                    </Tooltip>
                                                </div>
                                                <Dropdown
                                                    defaultValue={bonusCalculateAction}
                                                    isMultiSelect={false}
                                                    disabled={false}
                                                    hasSearch={false}
                                                    inputSize="default"
                                                    placeholder="Dropdown title"
                                                    appearance="outline"
                                                    data={bonusCalculateActions}
                                                    onChange={bonusCalculateActionChangeHandler}
                                                    isValid={
                                                        isNil(pageValidation.bonusCalculateAction?.isValid) ||
                                                        pageValidation.bonusCalculateAction?.isValid
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div className="percentage-block">
                                        {!isNil(radioGroupValue) && (
                                            <>
                                                <div className="bonus-holder-title">
                                                    <p className="ellipsis-text">{t(l.Percentage)}</p>
                                                    <Tooltip
                                                        text={t(l.TooltipBonusCalculationPercentage)}
                                                        position="bottom"
                                                    >
                                                        <Icon type="bc-icon-info" />
                                                    </Tooltip>
                                                </div>
                                                <ExtendedInput
                                                    type="number"
                                                    placeholder={t(l.Percentage)}
                                                    value={percentage[radioGroupValue]}
                                                    onChange={percentageValueChangeHandler}
                                                    appearance="outline"
                                                    isValid={
                                                        isNil(pageValidation.percentage?.isValid) ||
                                                        pageValidation.percentage?.isValid
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div className="limits-check">
                                        <Checkbox
                                            label={t(l.ConsiderLimits)}
                                            checked={considerLimits[radioGroupValue]}
                                            onChange={considerLimitsChangeHandler}
                                        />
                                        <Tooltip text={t(l.TooltipBonusCalculationConsiderLimits)} position="bottom">
                                            <Icon type="bc-icon-info" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Tab>
                </Tabs>
            )}
        </>
    );
};

BonusCalculation.propTypes = {
    bonusType: PropTypes.number.isRequired,
    bonusDetails: PropTypes.array.isRequired,
    bonusDetailsAmounts: PropTypes.object.isRequired,
    setBonusDetailsAmounts: PropTypes.func.isRequired,
    setRadioGroupValue: PropTypes.func.isRequired,
    bonusCalculationActiveTabKey: PropTypes.string.isRequired,
    setBonusCalculationActiveTabKey: PropTypes.func.isRequired,
    bonusCalculateActions: PropTypes.array.isRequired,
    setBonusCalculateAction: PropTypes.func.isRequired,
    setBonusKind: PropTypes.func.isRequired,
    bonusKinds: PropTypes.object.isRequired,
    percentage: PropTypes.object.isRequired,
    setPercentage: PropTypes.func.isRequired,
    considerLimits: PropTypes.object.isRequired,
    setConsiderLimits: PropTypes.func.isRequired,
    bonusKind: PropTypes.object,
    bonusCalculateAction: PropTypes.string,
    radioGroupValue: PropTypes.number,
    isDynamicDisabled: PropTypes.bool,
    isVisibleRadioSelection: PropTypes.bool,
    selectedWallet: PropTypes.string,
    setSelectedWallet: PropTypes.func,
    supportedCurrencies: PropTypes.array,
    supportedWallets: PropTypes.array,
};

export default memo(BonusCalculation);
