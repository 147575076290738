/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
// Import UI Components
import Switch from 'react-switch';

const CustomTab = ({
    tabKey,
    tabTitle,
    hasSwitcher,
    switchChangeHandler,
    switcherChecked,
    index,
    getTabKey,
    tabClickHandler,
    activeKey,
    isValid,
    isDisabled,
}) => {
    const customTab = useMemo(
        () =>
            hasSwitcher ? (
                <li
                    key={getTabKey(tabKey, index)}
                    className={classNames('crm-custom-tabs-tab-with-switcher', {
                        active: getTabKey(tabKey, index) === activeKey,
                        'active-switcher': switcherChecked,
                    })}
                >
                    <button className={'crm-custom-tabs-tab'} onClick={tabClickHandler(getTabKey(tabKey, index))}>
                        <span className="test">{tabTitle}</span>
                    </button>
                    <Switch
                        onChange={switchChangeHandler}
                        checked={switcherChecked}
                        width={36}
                        height={18}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className="crm-custom-tab-switcher"
                    />
                </li>
            ) : (
                <li
                    key={getTabKey(tabKey, index)}
                    className={classNames({ active: getTabKey(tabKey, index) === activeKey, invalid: !isValid })}
                >
                    <button onClick={tabClickHandler(getTabKey(tabKey, index))} disabled={isDisabled}>
                        {tabTitle}
                    </button>
                </li>
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeKey, switcherChecked, tabTitle, isValid],
    );
    return customTab;
};

CustomTab.propTypes = {
    index: PropTypes.number.isRequired,
    getTabKey: PropTypes.func.isRequired,
    tabClickHandler: PropTypes.func.isRequired,
    activeKey: PropTypes.string.isRequired,
    tabKey: PropTypes.string.isRequired,
    tabTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    hasSwitcher: PropTypes.bool,
    switchChangeHandler: PropTypes.func,
    switcherChecked: PropTypes.bool,
    isValid: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

CustomTab.defaultProps = {
    switcherChecked: false,
    hasSwitcher: false,
    switchChangeHandler: noop,
    isValid: true,
    isDisabled: false,
};

export default CustomTab;
