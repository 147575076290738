import { CRMRequestService, getCancelableRequest, postCancelableRequest } from './http.config';

const CampaignNotificationsHttpService = {
    getNotifications: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/EventNotification/GetNotifications',
        });
    },
    saveNotifications: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/EventNotification/Save',
        });
    },
};

export default CampaignNotificationsHttpService;
