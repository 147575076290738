import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

const CJEmpty = ({ getUpdate }) => {
    useEffect(() => {
        getUpdate({ value: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};

CJEmpty.propTypes = {
    getUpdate: PropTypes.func.isRequired,
};

CJEmpty.defaultProps = {};

export default memo(CJEmpty);
