import { DynamicSegmentActionsTypes as Types } from 'actions/types';

const addNewFilter = (payload) => ({ type: Types.ADD_FILTER_CONTAINER_ITEM, payload });
const addNewFilterList = (payload) => ({ type: Types.ADD_FILTER_CONTAINER_LIST, payload });
const editFilter = (payload) => ({ type: Types.EDIT_FILTER_CONTAINER, payload });
const deleteFilter = (payload) => ({ type: Types.DELETE_FILTER_CONTAINER_LIST, payload });
const deleteFilterSchemaList = (payload) => ({ type: Types.DELETE_FILTER_SCHEMA_LIST, payload });
const deleteFiltersItem = (payload) => ({ type: Types.DELETE_FILTER_CONTAINER_ITEM, payload });
const setDefaultFilterList = (payload) => ({ type: Types.SET_FILTER_LIST, payload });
const clearFilterList = () => ({ type: Types.CLEAR_FILTER_CONTAINER });
const clearInfoFilterList = () => ({ type: Types.CLEAR_INFO_FILTER_CONTAINER });
const setInfoDefaultFilterList = (payload) => ({ type: Types.SET_INFO_FILTER_LIST, payload });
const deleteSubSegment = (payload) => ({ type: Types.DELETE_SUB_SEGMENT, payload });
const setMainSegmentInitialData = (payload) => ({ type: Types.SET_MAIN_SEGMENT_INITIAL_DATA, payload });
const addNewSubSegment = (payload) => ({ type: Types.ADD_SUB_SEGMENT, payload });
const changeSubSegmentName = (payload) => ({ type: Types.CHANGE_SUB_SEGMENT_NAME, payload });

export default {
    addNewFilter,
    addNewFilterList,
    editFilter,
    deleteFilter,
    deleteFilterSchemaList,
    deleteFiltersItem,
    setDefaultFilterList,
    clearFilterList,
    clearInfoFilterList,
    setInfoDefaultFilterList,
    deleteSubSegment,
    setMainSegmentInitialData,
    addNewSubSegment,
    changeSubSegmentName,
};
