import moment from 'moment-timezone';
import { isEmpty, isString, isNumber, isUndefined, isArray, isNil, last, isObject, keys, groupBy, has } from 'lodash';
// Import Constants
import {
    ScheduleModalViews,
    WeekDaysKeyList,
    Hours,
    Days,
    DateTimeWithoutSecondsFormat,
    l,
    TimeFormatWithoutSeconds,
    DownloadFileTypes,
    Months,
    ModalActionsConfig,
    FilterComparisions,
    HexOpacityCodes,
} from 'constants/common';
import { BrowserStorageKeys } from 'constants/browserStorage';
// Import Components
import { UsageViewModel } from 'components/common/UsedInSection/constants';

const { mainView, hourlyView, dailyView, weeklyView, monthlyView, annuallyView, notRepeatView } = ScheduleModalViews;

function monthArrayForDropdown(t) {
    return Months.map((month, i) => {
        return {
            label: t(l[month]),
            value: i,
            key: month,
        };
    });
}

const getStringElementsByIndexArray = (array, arrayOfIndex, t) => {
    return arrayOfIndex.reduce((acc, itemIndex, i, givenArray) => {
        acc += `${t(l[array[itemIndex]])}${i === givenArray.length - 1 ? '' : ','}`;
        return acc;
    }, '');
};

function weeksArrayForDropdown(t) {
    return WeekDaysKeyList.map((week, i) => {
        return {
            label: t(l[week]),
            value: `${i}`,
            key: week,
        };
    });
}

const getDays = (valueAsString = false) => {
    const days = [];

    for (let i = 1; i <= 31; i++) {
        days.push({ label: i, value: valueAsString ? `${i}` : i });
    }

    return days;
};

const customMoment = (value) => {
    const storageTimezone = localStorage.getItem(BrowserStorageKeys.timezone);
    const timezone = !isNil(storageTimezone) && storageTimezone !== 'null' ? storageTimezone : 0;
    return !isNil(value) ? moment(value).utcOffset(timezone * 60) : moment().utcOffset(timezone * 60);
};

const customMomentWithoutTimezoneConversion = (value) => {
    return !isNil(value) ? moment(value).utcOffset(0) : moment().utcOffset(0);
};

const queryParamsDecoder = (search) => {
    const searchValue = search.replace('?', '');
    if (isEmpty(searchValue)) return {};
    try {
        const result = JSON.parse(decodeURIComponent(searchValue));
        return result ?? {};
    } catch (e) {
        console.error(e);
    }
    return {};
};

const combineUrlParams = (queryParams = {}) => {
    const params = keys(queryParams).reduce((acc, item) => {
        if (isArray(queryParams[item])) {
            acc =
                acc +
                queryParams[item].reduce((acc2, item2) => {
                    acc2 = acc2 + `${item}=${encodeURIComponent(item2)}&`;
                    return acc2;
                }, '');
        } else {
            acc = acc + `${item}=${encodeURIComponent(queryParams[item])}&`;
        }
        return acc;
    }, '?');

    return params.slice(0, params.length - 1);
};

const nativeQueryParamsDecoder = (params) => {
    const searchParams = new URLSearchParams(params);
    const result = {};

    for (const [key, value] of searchParams) {
        result[key] = value;
    }

    return result;
};

const queryParamsEncoder = (data) => {
    return `?${encodeURIComponent(JSON.stringify(data))}`;
};

const getQueryParamNamespace = (prefix, value) => {
    return `${prefix}${value}`;
};

const getDataTableKey = (value) => {
    return getQueryParamNamespace('dataTable', value);
};

const getFilterHolderKey = (value) => {
    return getQueryParamNamespace('filterHolder', value);
};

const getFiltersTagKey = (value) => {
    return getQueryParamNamespace('filtersTagGroup', value);
};

const openUrlInNewTab = (url) => {
    if (!isEmpty(url)) return;
    const tab = window.open(url, '_blank');
    tab.focus();
};

const getByteLen = (normalVal) => {
    normalVal = String(normalVal);

    let byteLen = 0;
    for (let i = 0; i < normalVal.length; i++) {
        let c = normalVal.charCodeAt(i);
        byteLen +=
            c < 1 << 7
                ? 1
                : c < 1 << 11
                ? 2
                : c < 1 << 16
                ? 3
                : c < 1 << 21
                ? 4
                : c < 1 << 26
                ? 5
                : c < 1 << 31
                ? 6
                : Number.NaN;
    }
    return byteLen;
};

const Years = () => {
    let years = [];
    const dateStart = customMoment();
    const dateEnd = customMoment().add(11, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'));
        dateStart.add(1, 'year');
    }

    return years;
};

const cronStringEncoder = (key = '', value = {}) => {
    const { hours, days, weeks, months, years, hour, day, month, year } = value;
    let hoursValues = [];
    let daysValues = [];
    let weeksValues = [];
    let monthsValues = [];
    let yearsValues = [];

    for (let i = 0; i < hours?.length; i++) {
        hoursValues.push(Hours[hours[i]]);
    }
    for (let i = 0; i < days?.length; i++) {
        daysValues.push(Days[days[i]]);
    }
    for (let i = 0; i < weeks?.length; i++) {
        weeksValues.push(WeekDaysKeyList[weeks[i]]);
    }
    for (let i = 0; i < months?.length; i++) {
        monthsValues.push(Days[months[i]]);
    }
    for (let i = 0; i < years?.length; i++) {
        yearsValues.push(Years()[years[i]]);
    }

    switch (key) {
        case hourlyView:
            if (isEmpty(hours)) {
                return `${hour ? `*/${hour}` : '*/'} * * ? *`;
            } else {
                return `${hoursValues} * * ? *`;
            }
        case dailyView:
            if (isEmpty(days)) {
                return `${day ? `*/${day}` : '*/'} * ? *`;
            } else {
                return `${daysValues} * ? *`;
            }
        case weeklyView:
            return `? * ${isEmpty(weeksValues) ? '*/' : weeksValues} *`;
        case monthlyView:
            return (
                (isEmpty(days) ? `${day ? `*/${day}` : '*/'}` : daysValues) +
                ' ' +
                (isEmpty(months) ? `${month ? `*/${month}` : '*/'}` : monthsValues) +
                ' ? *'
            );
        case annuallyView:
            return (
                (isEmpty(days) ? `${day ? `*/${day}` : '*/'}` : daysValues) +
                ' ' +
                (isEmpty(months) ? `${month ? `*/${month}` : '*/'}` : monthsValues) +
                ' ? ' +
                (isEmpty(years) ? `${year ? `*/${year}` : '*/'}` : yearsValues)
            );
        default:
            return '';
    }
};

const cronStringDecoder = (value = '') => {
    let result = {
        startTime: customMoment().format(TimeFormatWithoutSeconds),
        startDate: customMoment().format(),
        endDate: customMoment().format(),
        hour: '',
        day: '',
        month: '',
        year: '',
        hours: [],
        days: [],
        weeks: [],
        months: [],
        years: [],
        viewType: mainView,
    };

    result.viewType = notRepeatView;

    if (!isEmpty(value)) {
        let splittedCron = value.split('?');
        let leftChunk = splittedCron[0].split(' ');
        let rightChunk = splittedCron[1].split(' ');
        let rightChunkAsteriskCount = splittedCron[1].match(/\*/g) === null ? 0 : splittedCron[1].match(/\*/g).length;
        let hourChunk = leftChunk[2] || '';
        let dayChunk = leftChunk[3] || '';
        let weekChunk = rightChunkAsteriskCount ? rightChunk[2] || '' : '';
        let monthChunk = leftChunk[4] || '';
        let yearChunk = rightChunk[1] || '';

        const isSpecificHoursSelected =
            dayChunk === '*' &&
            weekChunk === '' &&
            monthChunk === '*' &&
            yearChunk === '*' &&
            hourChunk.length > 0 &&
            !hourChunk.includes('*');
        const isHourIntervalSelected = hourChunk.includes('/');

        if (isSpecificHoursSelected || isHourIntervalSelected) {
            result.hours = isSpecificHoursSelected
                ? hourChunk
                      .split(',')
                      .filter((hour) => hour)
                      .map((hour) => {
                          return Hours.indexOf(hour);
                      })
                : [];
            result.hour = isHourIntervalSelected ? hourChunk.split('/')[1] : '';
            result.startTime = `00:${leftChunk[1]}`;
        } else {
            result.startTime = `${hourChunk}:${leftChunk[1]}`;
        }

        if (dayChunk.includes('*')) {
            result.day = dayChunk.substr(2);
        } else {
            result.days = dayChunk
                .split(',')
                .filter((day) => day)
                .map((day) => {
                    return Days.indexOf(day);
                });
        }

        if (rightChunkAsteriskCount === 2) {
            leftChunk.push('');
            result.weeks = weekChunk
                .split(',')
                .filter((weekDayKey) => weekDayKey)
                .map((weekDayKey) => {
                    return WeekDaysKeyList.indexOf(weekDayKey);
                });
        }

        if (monthChunk.includes('*')) {
            result.month = monthChunk.substr(2);
        } else {
            result.months = monthChunk
                .split(',')
                .filter((month) => month)
                .map((month) => {
                    return Days.indexOf(month);
                });
        }

        if (yearChunk.includes('*')) {
            result.year = yearChunk.substr(2);
        } else {
            result.years = yearChunk
                .split(',')
                .filter((year) => year)
                .map((year) => {
                    return Years().indexOf(year);
                });
        }

        const isHourSelected = result.hour || result.hours.length || hourChunk[1] === '/';
        const isDaySelected = result.day || result.days.length || dayChunk[1] === '/';
        const isWeekSelected = result.weeks.length || weekChunk[1] === '/';
        const isMonthSelected = result.month || result.months.length || monthChunk[1] === '/';
        const isYearSelected = result.year || result.years.length || yearChunk[1] === '/';

        if (isWeekSelected) {
            result.viewType = weeklyView;
        } else if (isYearSelected) {
            result.viewType = annuallyView;
        } else if (isMonthSelected) {
            result.viewType = monthlyView;
        } else if (isDaySelected) {
            result.viewType = dailyView;
        } else if (isHourSelected) {
            result.viewType = hourlyView;
        }
    }

    return result;
};

const getDateFormat = (date, timeZone = 0, format = DateTimeWithoutSecondsFormat) => {
    return moment.utc(date).utcOffset(timeZone).format(format);
};

const getDate = (date, timeZone = 0) => {
    return moment(date).utcOffset(timeZone * 60);
};

const getDateWithTime = (value, time) => {
    return moment(value).set('hour', time[0]).set('minute', time[1]);
};

const convertMinutesToHHMM = (minutes) => {
    const duration = moment.duration(minutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();

    return moment({ hours, minutes: mins }).format('HH:mm');
};

const generateRequestFilterField = (name, comparison, values) => {
    if (!isString(name) && !isNumber(comparison)) return;

    let correctValue;
    if (comparison !== FilterComparisions.IsBlank) {
        if (values === 'true' || values === true) {
            correctValue = [1];
        } else if (values === 'false' || values === false) {
            correctValue = [0];
        } else if (isUndefined(values) || values === 'all') {
            correctValue = [];
        } else if (isArray(values)) {
            correctValue = values;
            // TODO: see case when values = ['true', 'false']
        } else {
            correctValue = [values];
        }
    } else {
        if (isUndefined(values)) {
            correctValue = [];
        } else {
            correctValue = [values];
        }
    }

    return {
        Name: name,
        Comparision: comparison,
        Values: correctValue,
    };
};

const hexToRgb = (hexCode) => {
    const colorIngridients = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
    let result = null;

    if (!isEmpty(colorIngridients)) {
        result = {
            r: parseInt(colorIngridients[1], 16),
            g: parseInt(colorIngridients[2], 16),
            b: parseInt(colorIngridients[3], 16),
        };
    }

    return !isNil(result) ? `${result.r} ${result.g} ${result.b}` : false;
};

const getNamesString = (list = []) => {
    if (!isArray(list)) return;
    return list.map(({ Name }) => Name).toString();
};

const getUsedInConfirmationText = (t, typeLabel, list = [], isUsedInObjectsLabel) => {
    const types = groupBy(list, 'Type');
    const tObject = { 6: typeLabel };

    let index = 0;

    UsageViewModel.forEach((item) => {
        if (has(types, item.type)) {
            tObject[2 * index] = t(item.displayNameKey);
            tObject[2 * index + 1] = (index > 0 ? ` ${t(l.And)} ` : '') + getNamesString(types[item.type]);

            index++;
        }
    });

    return t(isUsedInObjectsLabel, tObject);
};

const readFileAsync = (fileData) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(fileData);
        reader.onload = () => {
            resolve(new Uint8Array(reader.result));
        };
        reader.onerror = reject;
    });
};

const readFileBase64StringAsync = (fileData) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });
};

const readTextFileAsync = (fileData) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsText(fileData);
    });
};

const getPlusOneDayDate = () => {
    return new Date().setDate(new Date().getDate() + 1);
};

const saveToDisk = (data, type, fileName) => {
    const { fileType, fileExtension } = DownloadFileTypes[type];
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
    window.URL.revokeObjectURL(url);
};

const downloadDropdownDataCreator = (t) => {
    return [
        { value: null, label: t(l.All) },
        { value: '10', label: t(l.TopResultsByCount, { count: 10 }) },
        { value: '20', label: t(l.TopResultsByCount, { count: 20 }) },
        { value: '50', label: t(l.TopResultsByCount, { count: 50 }) },
        { value: '100', label: t(l.TopResultsByCount, { count: 100 }) },
    ];
};

const checkDataCount = (dataCount, count, pageNumber, pageSize) => {
    return pageNumber !== 0 && dataCount === count + pageNumber * pageSize;
};

const getFlagsValues = (value) => {
    let result = [];
    let count = 0;
    while (!isNil(value) && value >> count !== 0) {
        if (((value >> count) & 1) !== 0) {
            result.push(Math.pow(2, count).toString());
        }
        count++;
    }
    return result;
};

const isOnlyMinus = (value) => {
    return value === '-';
};

const hasInFilters = (filters, action) => {
    const config = ModalActionsConfig[action];
    if (!isNil(ModalActionsConfig[action]) && config.independent !== true) {
        if (isNil(filters) || !isArray(filters)) {
            return true;
        }
        const tmpFilters = filters.reduce((acc, item) => {
            if (!isNil(item?.Name)) {
                acc[item.Name] = item;
            }
            return acc;
        }, {});

        const filter = tmpFilters[config.dataKey];
        if (isNil(filter?.Values) || isEmpty(filter.Values)) {
            return true;
        }
        const typeKey = isArray(config.typeKey) ? config.typeKey : [config.typeKey];
        typeKey.forEach((key) => {
            if (filter.Values.includes(key.toString()) || filter.Values.includes(+key) || filter.Values.includes(key)) {
                return true;
            }
        });
    }
    return false;
};

const getPaginationPageNumber = (listCount, selectorSize, currentPageNumber, listRemovedCount) => {
    if (currentPageNumber === 1) {
        return 1;
    }
    const newPagesCount = Math.ceil((listCount - listRemovedCount) / selectorSize);
    if (newPagesCount <= currentPageNumber) {
        return newPagesCount > 0 ? newPagesCount : 1;
    }
    return currentPageNumber;
};

const getPageNumber = (filters, action, listCount, selectorSize, currentPageNumber, listRemovedCount) => {
    if (hasInFilters(filters, action)) {
        return currentPageNumber;
    }

    return getPaginationPageNumber(listCount, selectorSize, currentPageNumber, listRemovedCount);
};

const isObjectsEquals = (firstObj, secondObj) => {
    return JSON.stringify(firstObj) === JSON.stringify(secondObj);
};

const getBlockType = (typeName) => {
    return last(typeName.split('.'));
};

const itemsIsValid = (items) => {
    return items.reduce((acc, item) => {
        acc = acc && item.isValid;
        return acc;
    }, true);
};

const createTranslatableEventConfig = (value, type) => {
    return `EVENT_${value}_${type.toUpperCase()}`;
};

const getDomain = (hostname) => {
    try {
        const matched = hostname.match(/^(?:.*?\.)?(?<domain>[a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/);
        return matched.groups.domain;
    } catch {
        return '';
    }
};

const replaceDomainFromEnvironmentURL = (url) => {
    const tmpURL = `${window.location.protocol}//${url}`;
    if (process.env.REACT_APP_MODE === 'development') {
        return tmpURL;
    }
    const toDomain = getDomain(window.location.hostname);
    const fromDomain = getDomain(new URL(tmpURL).hostname);
    return tmpURL.replace(fromDomain, toDomain);
};

const getTranslatableErrorText = (t, errorMessages, getLabel = (message) => message.errorMessage) => {
    let result = '';
    errorMessages.forEach((message, index) => {
        const messageLabel = getLabel(message);
        const errorParams = {};
        if (!isNil(message?.errorParams) && isObject(message.errorParams)) {
            keys(message.errorParams).forEach((key) => {
                if (isString(message.errorParams[key])) {
                    errorParams[key] = t(message.errorParams[key]);
                } else if (isObject(message.errorParams[key])) {
                    if (!isNil(message.errorParams[key]?.value)) {
                        errorParams[key] =
                            !isNil(message.errorParams[key].isTranslated) && message.errorParams[key].isTranslated
                                ? message.errorParams[key].value
                                : t(message.errorParams[key].value);
                    }
                }
            });
        }

        result += t(messageLabel, { ...errorParams });
        if (index !== errorMessages.length - 1) {
            result += '\n';
        }
    });
    return result;
};

const calculateTemplateBodyLengthWithRegEx = (regexConfig, value) => {
    regexConfig.forEach((obj) => {
        const re = new RegExp(obj?.Pattern, 'g');
        value = value.replace(re, obj?.Replacement);
    });
    return value;
};

const hasLabel = (data, label) => (data & label) === label;

const formatNumber = (value, precision = 2) => {
    return Number(value)
        .toFixed(precision)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getCurrencyLabel = (originalLabel, value, currency, defaultCurrencyId, currencyRates) => {
    if (currency === defaultCurrencyId || isEmpty(currency) || !has(currencyRates, currency)) {
        return originalLabel;
    } else {
        const result = (currencyRates[currency]?.Rate * +value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${originalLabel} / ${result} ${defaultCurrencyId}`;
    }
};

const getBonusSupportedCurrencies = (bonus) => {
    const bonusDetails = bonus?.BonusDetails ?? [];
    if (isEmpty(bonusDetails)) {
        return ((JSON.parse(bonus?.InternalDesc ?? null) ?? {}).MoneyRequirenments ?? []).map(
            (item) => item?.CurrencyId,
        );
    }
    return bonusDetails.map(({ CurrencyCode }) => CurrencyCode);
};

const getMultiselectDropdownValueAndMap = (selections) => {
    const options = selections.reduce(
        (acc, selection) => {
            acc.values.push(selection.value);
            acc.valueMap[selection.value] = true;

            return acc;
        },
        { values: [], valueMap: {} },
    );

    return options;
};

const componentToHex = (c) => {
    var hex = c.toString(16);
    // eslint-disable-next-line eqeqeq
    return hex.length == 1 ? '0' + hex : hex;
};

const rgbToHex = (r, g, b) => '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);

const getHexColorWithOpacity = (color, opacity) => {
    return (typeof color === 'string' && color.startsWith('#') ? color : rgbToHex(...color)) + HexOpacityCodes[opacity];
};

export default {
    generateRequestFilterField,
    queryParamsDecoder,
    nativeQueryParamsDecoder,
    queryParamsEncoder,
    combineUrlParams,
    openUrlInNewTab,
    getByteLen,
    cronStringEncoder,
    cronStringDecoder,
    getDate,
    getDateFormat,
    monthArrayForDropdown,
    getDays,
    hexToRgb,
    getUsedInConfirmationText,
    getDateWithTime,
    readFileAsync,
    getPlusOneDayDate,
    customMoment,
    customMomentWithoutTimezoneConversion,
    saveToDisk,
    downloadDropdownDataCreator,
    checkDataCount,
    weeksArrayForDropdown,
    getStringElementsByIndexArray,
    getDataTableKey,
    getFilterHolderKey,
    getFiltersTagKey,
    getFlagsValues,
    readFileBase64StringAsync,
    readTextFileAsync,
    isOnlyMinus,
    getPageNumber,
    isObjectsEquals,
    getBlockType,
    itemsIsValid,
    createTranslatableEventConfig,
    getMultiselectDropdownValueAndMap,
    replaceDomainFromEnvironmentURL,
    getTranslatableErrorText,
    calculateTemplateBodyLengthWithRegEx,
    formatNumber,
    hasLabel,
    getCurrencyLabel,
    getBonusSupportedCurrencies,
    convertMinutesToHHMM,
    getHexColorWithOpacity,
};
