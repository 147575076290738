// Import Services
import { generatePartialHtml } from 'services/template';

function createMosaicoConfigsViaHTMLString(htmlString) {
    const wrapperHTML = generatePartialHtml(htmlString);

    return {
        metadata: {
            template: '/mosaico/templates/versafix-1/template-versafix-1.html',
            name: 'No name',
            created: new Date().getTime(),
            editorversion: '0.18.10',
            templateversion: '1.3.8',
        },
        content: {
            type: 'template',
            customStyle: false,
            preheaderVisible: true,
            titleText: 'TITLE',
            mainBlocks: {
                type: 'blocks',
                blocks: [
                    {
                        type: 'blockHTML',
                        customStyle: false,
                        id: 'ko_blockHTML_1',
                        externalBackgroundImage: {
                            type: 'image',
                            src: null,
                            url: null,
                            alt: null,
                        },
                        backgroundImage: {
                            type: 'image',
                            src: null,
                            url: null,
                            alt: null,
                        },
                        externalBackgroundColor: null,
                        backgroundColor: null,
                        bigTitleStyle: {
                            type: 'bigTitleStyle',
                            face: null,
                            color: null,
                            size: null,
                            align: null,
                        },
                        longText: wrapperHTML,
                    },
                ],
            },
            theme: {
                type: 'theme',
                frameTheme: {
                    type: 'frameTheme',
                    backgroundColor: '#3f3f3f',
                    longTextStyle: {
                        type: 'longTextStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#919191',
                        size: '13',
                        lineHeight: null,
                        align: null,
                        linksColor: '#cccccc',
                    },
                    blockSpacing: {
                        type: 'blockSpacing',
                        topPadding: '4',
                        bottomPadding: '4',
                        verticalSpacing: '5',
                        horizontalSpacing: '9',
                    },
                },
                contentTheme: {
                    type: 'contentTheme',
                    longTextStyle: {
                        type: 'longTextStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#3f3f3f',
                        size: '13',
                        lineHeight: 'normal',
                        align: 'left',
                        linksColor: '#3f3f3f',
                    },
                    externalBackgroundColor: '#bfbfbf',
                    blockSpacing: {
                        type: 'blockSpacing',
                        topPadding: '13',
                        bottomPadding: '13',
                        verticalSpacing: '5',
                        horizontalSpacing: '9',
                    },
                    externalTextStyle: {
                        type: 'textStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#f3f3f3',
                        size: '18',
                        align: null,
                    },
                    backgroundColor: '#ffffff',
                    titleTextStyle: {
                        type: 'textStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#3f3f3f',
                        size: '18',
                        align: 'left',
                    },
                    buttonStyle: {
                        type: 'buttonStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#3f3f3f',
                        size: '13',
                        align: 'left',
                        buttonColor: '#bfbfbf',
                        radius: '4',
                        borderWidth: '0',
                        borderColor: '#3f3f3f',
                        verticalPadding: '6',
                        horizontalPadding: '18',
                    },
                    bigTitleStyle: {
                        type: 'bigTitleStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#3f3f3f',
                        size: '22',
                        align: 'center',
                    },
                    hrStyle: {
                        type: 'hrStyle',
                        color: '#3f3f3f',
                        hrWidth: '100',
                        hrHeight: '1',
                    },
                    bigButtonStyle: {
                        type: 'buttonStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#3f3f3f',
                        size: '22',
                        align: 'center',
                        buttonColor: '#bfbfbf',
                        radius: '4',
                        borderWidth: '0',
                        borderColor: '#3f3f3f',
                        verticalPadding: '12',
                        horizontalPadding: '14',
                    },
                    shareButtonStyle: {
                        type: 'shareButtonStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        iconColorType: 'black',
                        color: '#3f3f3f',
                        size: '13',
                        align: null,
                        buttonColor: '#bfbfbf',
                        radius: '4',
                    },
                },
                bodyTheme: {
                    type: 'bodyTheme',
                    externalBackgroundColor: '#bfbfbf',
                    backgroundColor: '#ffffff',
                    bigTitleStyle: {
                        type: 'bigTitleStyle',
                        face: 'Arial, Helvetica, sans-serif',
                        color: '#3f3f3f',
                        size: '22',
                        align: 'center',
                    },
                },
            },
        },
    };
}

export { createMosaicoConfigsViaHTMLString };
