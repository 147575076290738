import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';

const CJBlockNameInfo = ({ data, elements }) => {
    const { t } = useTranslation();
    const [blockName, setBlockName] = useState();

    useEffect(() => {
        setBlockName(elements.find((el) => el.id === data?.value)?.data?.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.Name)} value={blockName} isLoading={isNil(blockName)} />
        </>
    );
};

CJBlockNameInfo.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
};

export default memo(CJBlockNameInfo);
