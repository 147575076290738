/* eslint-disable react/jsx-no-bind */
import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { has, isNil, isUndefined, isFunction } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Modal, Icon, ExtendedInput, Checkbox, Button } from '@geneui/components';
// Import Component
import PreSaveValidationModalWarningContent from './PreSaveValidationModalWarningContent';
import { ButtonWithLoader } from 'components';
// Import Hooks
import { useValidator, useGoToRoute, useQueryParams } from 'hooks';
// Import Constants
import { l, ModalsActions, SaveModesLabels, StateModesLabels } from 'constants/common';

const PreSaveValidationModal = ({
    onCancel,
    onOk,
    title,
    defaultName,
    defaultDescription,
    handleNameChange,
    handleDescriptionChange,
    route,
    pageName,
    saveButtonLabel,
    bodyContent,
    isValidBodyContent,
    isVisibleLeavePageOption,
    isVisibleTitleInModal,
    isVisibleDescriptionInModal,
    alertContent,
    withWarningsAlertContent,
    infoLabel,
}) => {
    const { t } = useTranslation();
    const { goToRoute } = useGoToRoute();

    const goToRouteQueryParams = useQueryParams();
    const { Name, Description } = useSelector((state) => state.pageValidation);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(defaultName);
    const validator = useValidator();
    const [description, setDescription] = useState(defaultDescription);
    const [leavePage, setLeavePage] = useState(true);

    function handleCancel() {
        handleNameChange(null, name);
        handleDescriptionChange(null, description);
        onCancel(ModalsActions.CLOSE);
    }

    function handleSave() {
        setIsLoading(true);
        onOk(name, description, leavePage).then((data) => {
            setIsLoading(false);
            if (!data?.HasError) {
                if (leavePage) {
                    const decodedQueryParams = goToRouteQueryParams.decode();
                    if (isFunction(data?.customRoute)) {
                        data.customRoute(decodedQueryParams);
                    } else {
                        if (has(decodedQueryParams, 'backParams')) {
                            goToRoute(route, null, decodedQueryParams.backParams);
                        } else {
                            goToRoute(route);
                        }
                    }
                } else {
                    onCancel(ModalsActions.SAVE, data);
                }
            }
        });
    }

    const changeNameHandler = (e, value) => {
        const resultValue = !isNil(value) ? value : e.target.value.trimStart();
        setName(resultValue);
        validator({ Name: resultValue });
    };

    const changeDescriptionHandler = (e, value) => {
        const resultValue = !isNil(value) ? value : e.target.value.trimStart();
        setDescription(resultValue);
        validator({ Description: resultValue });
    };

    const blurNameHandler = (e) => {
        changeNameHandler(null, e.target.value.trimEnd());
    };

    const blurDescriptionHandler = (e) => {
        changeDescriptionHandler(null, e.target.value.trimEnd());
    };

    function handleLeavePageCheckboxChange() {
        setLeavePage(!leavePage);
    }

    const init = () => {
        validator({ Name: name });
        validator({ Description: description });
    };

    useEffect(init, []);

    const withBodyContent = !isUndefined(bodyContent);
    const isActiveSave = Name?.isValid && Description?.isValid && (!withBodyContent || isValidBodyContent);

    return (
        <Modal
            background="dark-background"
            position="top"
            title={
                saveButtonLabel === SaveModesLabels[1] || saveButtonLabel === SaveModesLabels[2]
                    ? t(l.SaveModeTitle, {
                          mode: `"${t(StateModesLabels[SaveModesLabels[saveButtonLabel]])}"`,
                      })
                    : t(title)
            }
            className="crm-modal c-validation-popup"
            closable={false}
            closeOnClickOutside={true}
            visible={true}
            isOkActive={!isVisibleTitleInModal || isActiveSave}
            size="content-size"
            footer={
                <>
                    <Button appearance="minimal" disabled={isLoading} onClick={handleCancel}>
                        {t(l.Close)}
                    </Button>
                    <ButtonWithLoader
                        isLoading={isLoading}
                        onClick={handleSave}
                        isDisabled={!isActiveSave && isVisibleTitleInModal}
                    >
                        {t(l.Save)}
                    </ButtonWithLoader>
                </>
            }
        >
            <>
                {!isNil(infoLabel) && (
                    <div className="validation-popup-info">
                        <div className="v-popup-info-icon-cont">
                            <Icon className="v-popup-info-icon" type={'bc-icon-info'} />
                        </div>
                        <p>{t(infoLabel)}</p>
                    </div>
                )}
                <div className="validation-popup-body">
                    {isVisibleTitleInModal && (
                        <ExtendedInput
                            isValid={!isVisibleTitleInModal || Name.isValid}
                            value={name}
                            placeholder={t(l.Title)}
                            errorText={t(Name?.errorText, { fieldName: t(l.Name), count: Name?.paramValue })}
                            onChange={changeNameHandler}
                            onBlur={blurNameHandler}
                        />
                    )}
                    {!isUndefined(bodyContent) && bodyContent}
                    {isVisibleDescriptionInModal && (
                        <ExtendedInput
                            type="textarea"
                            isValid={!isVisibleDescriptionInModal || Description.isValid}
                            value={description}
                            onChange={changeDescriptionHandler}
                            onBlur={blurDescriptionHandler}
                            errorText={t(Description?.errorText, {
                                fieldName: t(l.Description),
                                count: Description?.paramValue,
                            })}
                            inputSize={'small'}
                            placeholder={t(l.Description)}
                        />
                    )}
                    {!isUndefined(alertContent) && alertContent}
                    {withWarningsAlertContent && <PreSaveValidationModalWarningContent />}
                    {isVisibleLeavePageOption && (
                        <div className="v-popup-checkbox">
                            <Checkbox
                                checked={leavePage}
                                label={t(l.PreSavePopUpGoToMassage, { pageName })}
                                onChange={handleLeavePageCheckboxChange}
                            />
                        </div>
                    )}
                </div>
            </>
        </Modal>
    );
};

PreSaveValidationModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    defaultName: PropTypes.string.isRequired,
    defaultDescription: PropTypes.string.isRequired,
    handleNameChange: PropTypes.func.isRequired,
    handleDescriptionChange: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    pageName: PropTypes.string.isRequired,
    saveButtonLabel: PropTypes.string.isRequired,
    bodyContent: PropTypes.node,
    isVisibleLeavePageOption: PropTypes.bool,
    isVisibleTitleInModal: PropTypes.bool,
    isVisibleDescriptionInModal: PropTypes.bool,
    isValidBodyContent: PropTypes.bool,
    alertContent: PropTypes.node,
    withWarningsAlertContent: PropTypes.bool,
    infoLabel: PropTypes.string,
};

PreSaveValidationModal.defaultProps = {
    infoLabel: l.PutTheFinalTouchBeforeSaving,
};

export default memo(PreSaveValidationModal);
