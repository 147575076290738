import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import UI Components
import { Button } from '@geneui/components';
//Import Constants
import { ActionsIcons } from 'constants/common';

const BonusActionsButtons = ({ data, infoBonusAction }) => {
    return (
        <>
            <Button
                icon={ActionsIcons.Stop}
                appearance="minimal"
                color="primary"
                onClick={() => infoBonusAction(data)}
            />
        </>
    );
};

BonusActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    infoBonusAction: PropTypes.func,
};

BonusActionsButtons.defaultProps = {
    infoBonusAction: noop,
};

export default memo(BonusActionsButtons);
