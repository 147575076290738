import { AdminMenuItemsListActionTypes as Types } from './types';

const setMenuItemsListTableFilters = (filters) => ({
    type: Types.SET_MENUITEMS_LIST_TABLE_FILTERS,
    payload: filters,
});

const setMenuItemsListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_MENUITEMS_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setMenuItemsListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_MENUITEMS_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setMenuItemsListTableSorting = (sorting) => ({
    type: Types.SET_MENUITEMS_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetMenuItemsListState = () => ({
    type: Types.RESET_MENUITEMS_LIST_STATE,
});

export default {
    setMenuItemsListTableFilters,
    setMenuItemsListTablePagingPageNumber,
    setMenuItemsListTablePagingPageSize,
    setMenuItemsListTableSorting,
    resetMenuItemsListState,
};
