import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import UI Components
import { Icon, Option, Scrollbar, BusyLoader } from '@geneui/components';

const PredefinedListMenu = ({ listItemClickHandler, infoItemClickHandler, getList }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);

    const handleListItemClick = (item) => {
        listItemClickHandler(item);
    };

    const handleInfoItemClick = (item) => {
        infoItemClickHandler(item);
    };

    const itemContent = (item) => {
        return (
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleListItemClick(item);
                }}
            ></button>
        );
    };

    const infoContent = (item) => {
        return (
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleInfoItemClick(item);
                }}
            >
                <Icon type="bc-icon-info" />
            </button>
        );
    };

    const init = () => {
        getList().then(({ Data }) => {
            setList(Data?.Data?.map((item) => ({ title: item.Name, ...item })) ?? []);
            setIsLoading(false);
        });
    };

    useEffect(init, []);

    return (
        <>
            {isLoading ? (
                <BusyLoader isBusy type="spinner" spinnerSize="medium" />
            ) : (
                <Scrollbar autoHeightMax={500} autoHeight={true}>
                    {list.map((item, index) => {
                        return (
                            <Option
                                key={index}
                                title={item.title}
                                content={itemContent(item)}
                                rightCustomElement={infoContent(item)}
                            />
                        );
                    })}
                </Scrollbar>
            )}
        </>
    );
};

PredefinedListMenu.propTypes = {
    listItemClickHandler: PropTypes.func,
    infoItemClickHandler: PropTypes.func,
    getList: PropTypes.func.isRequired,
};

PredefinedListMenu.defaultProps = {
    listItemClickHandler: noop,
    infoItemClickHandler: noop,
};

export default memo(PredefinedListMenu);
