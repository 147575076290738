import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
// Import hooks
import { useListBaseRequestBody, useGoToRoute, useRequest } from 'hooks';
// Import services
import { TemplateHttpService } from 'services/http';
// Import components
import PredefinedListMenu from './PredefinedListMenu';
import { TemplateInfoModal } from 'components';
// Import constants
import { ModalsActions, TemplateTypesLabels } from 'constants/common';
import { PredefinedListMenuRequestModel } from './constants';
import { RoutesList } from 'routes';
const {
    TEMPLATE_PREDEFINED_EMAIL,
    TEMPLATE_PREDEFINED_DYNAMIC_POP_UP,
    TEMPLATE_PREDEFINED_SMS,
    TEMPLATE_PREDEFINED_INTERNAL_MESSAGE,
    TEMPLATE_PREDEFINED_SOCIAL_TELEGRAM,
    TEMPLATE_PREDEFINED_POP_UP_NOTIFICATION,
    TEMPLATE_PREDEFINED_PUSH_NOTIFICATION,
} = RoutesList;

const PredefinedTemplateListMenu = ({
    handleClose,
    predefinedInfoModalVisibleState,
    setPredefinedInfoModalVisibleState,
}) => {
    const { Filters, Paging, Sorting } = PredefinedListMenuRequestModel.templates;
    const { goToRoute } = useGoToRoute();
    const { doPostRequest } = useRequest();
    const [selectedInfoItem, setSelectedInfoItem] = useState(null);
    const [baseRequestBody] = useListBaseRequestBody(Filters, Paging, Sorting);

    const { getTemplatesRequest } = useRef({
        getTemplatesRequest: TemplateHttpService.getTemplateList(),
    }).current;

    const handleInfoItemClick = (item) => {
        setSelectedInfoItem(item);
        setPredefinedInfoModalVisibleState(true);
    };

    const handleListItemClick = (item) => {
        const { DeliveryMethod, TemplateId } = item;
        const params = { templateId: TemplateId };

        switch (DeliveryMethod) {
            case TemplateTypesLabels.Email:
                goToRoute(TEMPLATE_PREDEFINED_EMAIL, params);
                break;
            case TemplateTypesLabels.SMS:
                goToRoute(TEMPLATE_PREDEFINED_SMS, params);
                break;
            case TemplateTypesLabels.Internal:
                goToRoute(TEMPLATE_PREDEFINED_INTERNAL_MESSAGE, params);
                break;
            case TemplateTypesLabels.Telegram:
                goToRoute(TEMPLATE_PREDEFINED_SOCIAL_TELEGRAM, params);
                break;
            case TemplateTypesLabels.PopUp:
                goToRoute(TEMPLATE_PREDEFINED_POP_UP_NOTIFICATION, params);
                break;
            case TemplateTypesLabels.DynamicPopUp:
                goToRoute(TEMPLATE_PREDEFINED_DYNAMIC_POP_UP, params);
                break;
            case TemplateTypesLabels.PushNotification:
                goToRoute(TEMPLATE_PREDEFINED_PUSH_NOTIFICATION, params);
                break;
            default:
        }
        handleClose();
    };

    const modalCloseHandler = (action) => {
        if (action !== ModalsActions.CLOSE) {
            handleClose();
        }
        setPredefinedInfoModalVisibleState(false);
    };

    const getPredefinedTemplateList = () => {
        return doPostRequest(getTemplatesRequest.request, { requestBody: baseRequestBody });
    };

    const cleanUp = () => {
        return () => {
            setSelectedInfoItem(null);
            setPredefinedInfoModalVisibleState(false);
            getTemplatesRequest.cancel('PredefinedTemplateListMenu:getTemplatesRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <>
            {predefinedInfoModalVisibleState && !isNil(selectedInfoItem) && (
                <TemplateInfoModal
                    templateId={selectedInfoItem.TemplateId}
                    isPredefined={true}
                    handleClose={modalCloseHandler}
                    isVisible={predefinedInfoModalVisibleState}
                />
            )}

            <PredefinedListMenu
                infoItemClickHandler={handleInfoItemClick}
                listItemClickHandler={handleListItemClick}
                getList={getPredefinedTemplateList}
            />
        </>
    );
};

PredefinedTemplateListMenu.propTypes = {
    handleClose: PropTypes.func,
    predefinedInfoModalVisibleState: PropTypes.bool.isRequired,
    setPredefinedInfoModalVisibleState: PropTypes.func.isRequired,
};

PredefinedTemplateListMenu.defaultProps = {
    handleClose: noop,
};

export default memo(PredefinedTemplateListMenu);
