import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import UI Components
import { Icon } from '@geneui/components';
// Import Constants
import { BonusTypesLabels, BonusTypesIcons, l, DateTimeWithoutSecondsFormat, BonusStateLabels } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

const GeneralInfo = ({ id, title, description, type, startDate, endDate, state, walletType, internalMessageTitle }) => {
    const { t } = useTranslation();

    const getContent = (data) => {
        return isNil(data) ? t(l.NA) : customMomentWithoutTimezoneConversion(data).format(DateTimeWithoutSecondsFormat);
    };

    const { convertedData: BalanceTypesData, isLoading: isLoadingBalanceTypesData } = useMenuItem(106);

    return (
        <>
            <div className="segment-modal-head">
                <div className="title-wrapper">
                    <p className="title-wrapper-text">{t(l.GeneralInfo)}</p>
                    <span className="title-wrapper-line" />
                </div>
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.ID)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{id}</span>
                </div>
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.Title)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{title}</span>
                </div>
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.Description)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{description ?? t(l.NA)}</span>
                </div>
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.Type)}</span>
                    <div className="ellipsis-text kv-value kv-value-css">
                        <div className="static-segment-info-item">
                            {!isNil(BonusTypesIcons[type]) && <Icon type={BonusTypesIcons[type]} />}
                            <p>{t(BonusTypesLabels[type])}</p>
                        </div>
                    </div>
                </div>
                {!isNil(state) && (
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.State)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{t(BonusStateLabels[state])}</span>
                    </div>
                )}

                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.StartDate)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{getContent(startDate)}</span>
                </div>

                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.EndDate)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{getContent(endDate)}</span>
                </div>

                {!isEmpty(walletType) && !isLoadingBalanceTypesData && (
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.WalletType)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">
                            {BalanceTypesData.find((item) => item.value === walletType)?.label}
                        </span>
                    </div>
                )}
                {!isNil(internalMessageTitle) && (
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.InternalMessageTitle)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{internalMessageTitle}</span>
                    </div>
                )}
            </div>
        </>
    );
};

GeneralInfo.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    state: PropTypes.number.isRequired,
    walletType: PropTypes.string,
    internalMessageTitle: PropTypes.string,
};

GeneralInfo.defaultProps = {
    walletType: '',
    internalMessageTitle: null,
};

export default memo(GeneralInfo);
