// Import Constants
import { ErrorShowType, l } from 'constants/common';

const cmsPopUpNotificationTemplateValidationScenario = () => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    PopUpId: {
        isValid: null,
        value: '',
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.PleaseSelectPopUp,
                ParametersValues: null,
            },
        ],
    },
});

export { cmsPopUpNotificationTemplateValidationScenario };
