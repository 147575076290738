import { AdminPartnersListActionTypes as Types } from './types';

const setAdminPartnersListTableFilters = (filters) => ({
    type: Types.SET_ADMIN_PARTNERS_LIST_TABLE_FILTERS,
    payload: filters,
});

const setAdminPartnersListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_ADMIN_PARTNERS_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setAdminPartnersListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_ADMIN_PARTNERS_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setAdminPartnersListTableSorting = (sorting) => ({
    type: Types.SET_ADMIN_PARTNERS_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetAdminPartnersListState = () => ({
    type: Types.RESET_ADMIN_PARTNERS_LIST_STATE,
});

export default {
    setAdminPartnersListTableFilters,
    setAdminPartnersListTablePagingPageNumber,
    setAdminPartnersListTablePagingPageSize,
    setAdminPartnersListTableSorting,
    resetAdminPartnersListState,
};
