import React, { memo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, sortBy, isArray, noop, isEmpty, first } from 'lodash';
import { useFormik } from 'formik';
// Import Services
import Helpers from 'services/Helpers';
// Import UI Components
import { SkeletonLoader } from '@geneui/components';
// Import Components
import { OptionDropdown } from 'components';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import Constants
import { l } from 'constants/common';

const { getTranslatableErrorText } = Helpers;

const getDefaultTranslatableErrorText = (t, error) => {
    return getTranslatableErrorText(t, [{ errorMessage: error?.label, errorParams: error }]);
};

const MenuItemDropdown = forwardRef(
    (
        {
            defaultValue,
            getUpdate,
            onIsLoadingChange,
            menuItemId,
            isMultiselect,
            displayName,
            supportedValues,
            placeholder,
            disabled,
            validationSchema,
            isValid,
            errorText,
            getTranslatableErrorText,
            className,
            // TODO: remove this tmp solution after the fix of the issue
            shouldMoveToRight,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const [showSelected, setShowSelected] = useState(false);

        const { data, searchData, isLoading, search, config, isLoadingConfig, isLoadingRestore, restore } = useMenuItem(
            menuItemId,
            false,
        );

        const [listData, setListData] = useState([]);
        const [listColumns, setListColumns] = useState([]);

        const [searchValue, setSearchValue] = useState({});

        const getValue = (value) => {
            return isNil(value) ? null : `${value}`;
        };

        const getValues = (value) => {
            if (isNil(value)) {
                return isMultiselect ? [] : null;
            }
            if (isMultiselect && isArray(value)) {
                return value.map(getValue);
            }
            return getValue(value);
        };

        const formik = useFormik({
            initialValues: {
                value: getValues(defaultValue?.value),
            },
            onSubmit: noop,
            validationSchema: validationSchema,
        });

        const { setFieldValue, errors, submitForm } = formik;

        const [value, setValue] = useState(
            isNil(defaultValue?.value)
                ? []
                : isArray(defaultValue?.value)
                ? defaultValue?.value
                : [defaultValue?.value],
        );

        const [optionLabel, setOptionLabel] = useState('');

        const getColumns = (config) => {
            return isNil(config)
                ? []
                : sortBy(
                      config.option.groups
                          .flat()
                          .filter((item) => !item.isId)
                          .map((item) => {
                              return {
                                  key: item.name,
                                  filterable: item.filterable,
                                  label: t(
                                      `${
                                          config.option.keyGroupNameColumn.name === item.name ? displayName : item.name
                                      }Label${!isEmpty(item.idColumn) && item.idColumn.filterable ? 'WithId' : ''}`,
                                  ),
                                  idColumn: item.idColumn,
                                  orderKey: item.orderKey,
                              };
                          }),
                      'orderKey',
                  );
        };

        const getData = (data) => {
            const supported = supportedValues?.map((item) => item?.toString());
            return (data ?? [])
                .map((item) => {
                    const result = getColumns(config).reduce(
                        (acc, column) => {
                            acc[column.key] = item[column.key];
                            if (!isNil(column.idColumn) && column.idColumn.filterable) {
                                acc[column.key] = `${acc[column.key]} (${item[column.idColumn.name]})`;
                            }
                            return acc;
                        },
                        { [config.keyColumnName]: item[config.keyColumnName].toString() },
                    );
                    return result;
                })
                .filter((item) => {
                    return (
                        (!showSelected || value.includes(item[config?.keyColumnName].toString())) &&
                        (!isNil(supported) ? supported.includes(item[config?.keyColumnName].toString()) : true)
                    );
                });
        };

        useEffect(() => {
            if (!isNil(config?.option?.keyGroupNameColumn?.name)) {
                if (value.length > 1) {
                    setOptionLabel(t(l.OptionsSelected, { count: value.length }));
                } else {
                    setOptionLabel(
                        data
                            .filter((item) => value.includes(item[config.keyColumnName].toString()))
                            .map((item) => item[config.option.keyGroupNameColumn.name])
                            .join(', '),
                    );
                }
            }
            setFieldValue('value', isMultiselect ? value : isEmpty(value) ? null : first(value));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, data]);

        useEffect(() => {
            getUpdate({ value: isEmpty(value) ? null : isMultiselect ? value : first(value) });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        useEffect(() => {
            onIsLoadingChange(isLoading);
        }, [isLoading, onIsLoadingChange]);

        const clearHandler = () => {
            setValue([]);
        };

        const searchHandler = (e) => {
            setSearchValue(e);
            search(e);
        };

        const showSelectedChangeHandler = (e) => setShowSelected(() => e);

        useEffect(() => {
            getUpdate({ isValid: !errors.value });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [errors]);

        useEffect(() => {
            submitForm();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (!isLoadingConfig && !isEmpty(config) && !isEmpty(value) && config.isAutocomplete) {
                restore(value);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isLoadingConfig, config]);

        useEffect(() => {
            setListData(() => getData(showSelected ? data : searchData));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showSelected, data, searchData, value]);

        useEffect(() => {
            setListColumns(() => getColumns(config));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [config]);

        useImperativeHandle(ref, () => ({
            updateValue(value) {
                setValue(isNil(value) ? [] : isArray(value) ? value : [value]);
            },
            reset() {
                clearHandler();
            },
        }));

        return (
            <div className={className}>
                <SkeletonLoader isBusy={isLoadingConfig || isLoadingRestore || (isLoading && !config?.isAutocomplete)}>
                    {!isLoadingConfig && !isLoadingRestore && !(isLoading && !config?.isAutocomplete) && (
                        <OptionDropdown
                            optionLabel={optionLabel}
                            isValid={!errors.value && isValid}
                            errorText={getTranslatableErrorText(t, errors?.value) || errorText}
                            onClear={clearHandler}
                            valueKey={config?.keyColumnName}
                            value={value}
                            isLoading={isLoading}
                            isMultiselect={isMultiselect}
                            columns={listColumns}
                            list={listData}
                            rowCount={listData?.length}
                            onChange={setValue}
                            onSearch={searchHandler}
                            showSelected={showSelected}
                            onChangeShowSelected={showSelectedChangeHandler}
                            defaultSearchValue={searchValue}
                            placeholder={placeholder}
                            disabled={disabled}
                            // TODO: remove this tmp solution after the fix of the issue
                            shouldMoveToRight={shouldMoveToRight}
                        />
                    )}
                </SkeletonLoader>
            </div>
        );
    },
);

MenuItemDropdown.displayName = 'MenuItemDropdown';

MenuItemDropdown.propTypes = {
    getUpdate: PropTypes.func.isRequired,
    onIsLoadingChange: PropTypes.func,
    menuItemId: PropTypes.number.isRequired,
    displayName: PropTypes.isRequired,
    defaultValue: PropTypes.object,
    validationSchema: PropTypes.object,
    isMultiselect: PropTypes.bool,
    supportedValues: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    errorText: PropTypes.string,
    getTranslatableErrorText: PropTypes.func,
    className: PropTypes.string,
    // TODO: remove this tmp solution after the fix of the issue
    shouldMoveToRight: PropTypes.bool,
};

MenuItemDropdown.defaultProps = {
    isMultiselect: false,
    onIsLoadingChange: noop,
    displayName: '',
    supportedValues: null,
    validationSchema: null,
    placeholder: '',
    disabled: false,
    isValid: true,
    errorText: '',
    getTranslatableErrorText: getDefaultTranslatableErrorText,
    className: '',
    // TODO: remove this tmp solution after the fix of the issue
    shouldMoveToRight: false,
};
export default memo(MenuItemDropdown);
