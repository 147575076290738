import { isObject } from 'lodash';
// Import Services
import { TemplateHttpService, BonusHttpService } from 'services/http';
// Import Constants
import {
    l,
    CommunicationType,
    CommunicationStatus,
    PERMISSIONS,
    AlertTypes,
    FilterComparisions,
    FilterHolderFields,
} from 'constants/common';

const { Equals, Between, In, Like } = FilterComparisions;
const { textInput, rangeDate, multiSelect, multiSelectAutocomplete } = FilterHolderFields;

const getRequestBody = (searchValue, sortColumn) => {
    return {
        Filters: [{ Name: 'Name', Comparision: Like, Values: [searchValue] }],
        Pageing: { PageSize: 100, PageNumber: 1 },
        Sorting: { Name: sortColumn, Direction: 'desc' },
    };
};

const CommunicationListFilterConfig = {
    defaultInputsData: {
        defaultInputsDataIds: {
            TemplateId: 4,
            BonusId: 5,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ClientId',
                comparision: Equals,
                displayName: l.PlayerId,
                value: '',
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'SentDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.SentDate,
                value: [null, null],
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Channel',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Channel,
                value: [],
                data: CommunicationType,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Status',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Status,
                value: [],
                data: CommunicationStatus,
            },
            {
                type: multiSelectAutocomplete.type,
                labelAppearance: 'swap',
                valueFieldKey: 'TemplateId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Template,
                value: [],
                data: [],
                getRequest: TemplateHttpService.getTemplateList,
                getData(requestObj, searchValue) {
                    return new Promise((resolve, reject) => {
                        requestObj
                            .request(getRequestBody(searchValue, 'CreatedDate'))
                            .then((response) => {
                                const { HasError, AlertMessage, Data } = response;
                                if (!HasError) {
                                    resolve(
                                        isObject(Data)
                                            ? Data.Data.map((item) => ({ label: item.Name, value: item.TemplateId }))
                                            : [],
                                    );
                                } else {
                                    reject({ AlertType: AlertTypes.error, AlertMessage: AlertMessage });
                                }
                            })
                            .catch((error) => {
                                reject({ AlertType: AlertTypes.danger, AlertMessage: error });
                            });
                    });
                },
            },
            {
                type: multiSelectAutocomplete.type,
                labelAppearance: 'swap',
                valueFieldKey: 'BonusId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Award,
                value: [],
                data: [],
                permission: PERMISSIONS.ViewAwards,
                getRequest: BonusHttpService.getBonusList,
                getData(requestObj, searchValue) {
                    return new Promise((resolve, reject) => {
                        requestObj
                            .request(getRequestBody(searchValue, 'Id'), {
                                isIncludeExpired: true,
                            })
                            .then((response) => {
                                const { HasError, AlertMessage, Data } = response;
                                if (!HasError) {
                                    resolve(
                                        isObject(Data)
                                            ? Data.Data.map((item) => ({ label: item.Name, value: item.Id }))
                                            : [],
                                    );
                                } else {
                                    reject({ AlertType: AlertTypes.error, AlertMessage: AlertMessage });
                                }
                            })
                            .catch((error) => {
                                reject({ AlertType: AlertTypes.danger, AlertMessage: error });
                            });
                    });
                },
            },
        ],
    },
};

const getCommunicationsRequestBody = (name, value) => {
    return {
        Filters: [{ Name: name, Comparision: FilterComparisions.In, Values: value }],
        Pageing: { PageSize: 20, PageNumber: 1 },
        Sorting: { Name: 'SentDate', Direction: 'desc' },
    };
};

export { CommunicationListFilterConfig, getCommunicationsRequestBody };
