// Import Services
import Helpers from 'services/Helpers';
// Import Constants
import { DateToISOStringFormat } from 'constants/common';

const { customMoment } = Helpers;

const getStartDate = (dateValue) => {
    return !dateValue?.toISOString ? dateValue : dateValue.format(DateToISOStringFormat);
};

const getEndDate = (dateValue) => {
    return !dateValue?.toISOString ? dateValue : dateValue.startOf('day').format(DateToISOStringFormat);
};

const datePickerConfigs = {
    initStartDate: getStartDate(customMoment().startOf('day')),
    initEndDate: getEndDate(customMoment().add(7, 'days').startOf('day')),
    min: '7/10/2019',
    max: '12/31/2099',
};

export { datePickerConfigs, getStartDate, getEndDate };
