import React, { memo, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, values, cloneDeep } from 'lodash';
// Import Services
import { CustomerJourneyHttpService } from 'services/http';
import { getMatchedProperties, mapPropertiesToCascadeDropdownModel } from 'services/customerJourney';
// Import Components
import { DynamicVariableOption, CJTemplateId } from './index';
// Import UI Components
import { SkeletonLoader } from '@geneui/components';
// Import Hooks
import { useRequest } from 'hooks';

const CJTemplate = ({ defaultValue, getUpdate, option, elements, collectedProperties, node, updateDependency }) => {
    const { t } = useTranslation();
    const [templateId, setTemplateId] = useState(defaultValue?.templateId);
    const [dynamicVariables, setDynamicVariables] = useState(defaultValue?.dynamicVariables ?? {});
    const [isLoading, setIsLoading] = useState(!isNil(defaultValue?.templateId));

    const { doGetRequest } = useRequest();

    const { getTemplateModels } = useMemo(
        () => ({
            getTemplateModels: CustomerJourneyHttpService.getTemplateModels(),
        }),
        [],
    );

    useEffect(() => {
        if (!isNil(templateId)) {
            setIsLoading(true);
            setDynamicVariables({});
            doGetRequest(getTemplateModels.request, {
                queryString: { templateId: templateId },
                successCallback: (data) => {
                    const dependencyObject = {
                        dependencyItemId: templateId,
                        templateId: templateId,
                        dynamicVariables: data.reduce((acc, { FieldName, DisplayNameKey, WfType }) => {
                            acc[FieldName] = {
                                fieldName: FieldName,
                                displayNameKey: DisplayNameKey,
                                wfType: WfType,
                            };

                            return acc;
                        }, {}),
                    };
                    updateDependency(dependencyObject);

                    setDynamicVariables(() => {
                        return data.reduce((acc, item) => {
                            const { FieldName, DisplayNameKey, WfType } = item;
                            acc[FieldName] = {
                                fieldName: FieldName,
                                displayNameKey: DisplayNameKey,
                                wfType: WfType,
                                value: {},
                                isChecked: false,
                                ...(defaultValue.templateId === templateId
                                    ? (defaultValue?.dynamicVariables ?? {})[FieldName] ?? {}
                                    : {}),
                            };
                            return acc;
                        }, {});
                    });
                },
            }).then(() => setIsLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId]);

    useEffect(() => {
        getUpdate({ templateId: templateId, dynamicVariables: dynamicVariables });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId, dynamicVariables]);

    const dynamicVariablesChangeHandler = (fieldName) => {
        return (data) => {
            if (!isNil(data)) {
                setDynamicVariables((prev) => {
                    const result = cloneDeep(prev);
                    result[fieldName] = { ...result[fieldName], ...data };
                    return result;
                });
            }
        };
    };

    const templateChangeHandler = (data) => {
        setTemplateId(data?.value);
        setDynamicVariables({});
    };

    const cjTemplateIdDefaultValue = useMemo(
        () => ({
            value: templateId,
        }),
        [templateId],
    );

    const untypedData = useMemo(
        () => mapPropertiesToCascadeDropdownModel(collectedProperties ?? []),
        [collectedProperties],
    );

    return (
        <div>
            <CJTemplateId defaultValue={cjTemplateIdDefaultValue} getUpdate={templateChangeHandler} option={option} />
            <div className="crm-template-dynamic-variables-container">
                <SkeletonLoader isBusy={isLoading}>
                    {!isLoading &&
                        values(dynamicVariables).map((item, index) => {
                            return (
                                <DynamicVariableOption
                                    key={`${templateId}_${index}`}
                                    label={isNil(item.wfType) ? item.fieldName : t(item.displayNameKey)}
                                    elements={elements}
                                    properties={collectedProperties}
                                    option={option}
                                    node={node}
                                    data={
                                        isNil(item.wfType)
                                            ? untypedData
                                            : mapPropertiesToCascadeDropdownModel(
                                                  getMatchedProperties(item.wfType, collectedProperties),
                                              )
                                    }
                                    getUpdate={dynamicVariablesChangeHandler(item.fieldName)}
                                    defaultValue={{ isChecked: item.isChecked, value: item.value }}
                                />
                            );
                        })}
                </SkeletonLoader>
            </div>
        </div>
    );
};

CJTemplate.propTypes = {
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    updateDependency: PropTypes.func.isRequired,
    defaultValue: PropTypes.object,
    collectedProperties: PropTypes.array,
};

CJTemplate.defaultProps = {
    defaultValue: { templateId: null },
};

export default memo(CJTemplate);
