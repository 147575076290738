import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper, CJReferenceInArgumentInfoBlock } from 'components/CustomerJourney/infoBlocks';
// Import Components
import { l } from 'constants/common';

const CJDTDaysLaterInfoBlock = ({ data, elements, option, blockId }) => {
    const { t } = useTranslation();

    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.Offset)} value={t(data?.value?.params?.offset)} />
            <CJReferenceInArgumentInfoBlock
                data={{
                    blockId: data?.value?.params?.property?.parentValue,
                    propertyName: data?.value?.params?.property?.value,
                    logicFunction: data?.value?.params?.property?.logicFunction,
                }}
                elements={elements}
                option={option}
                blockId={blockId}
            />
        </>
    );
};

CJDTDaysLaterInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
};

export default memo(CJDTDaysLaterInfoBlock);
