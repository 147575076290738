export default {
    Administration: 'Administration',
    PartnerList: 'Partners',
    CJBlockManagement: 'CJ Block Management',
    AddPartner: 'Add Partner',
    RemovePartner: 'Remove Partner',
    AdministrationProviders: 'Administration Providers',
    AdministrationSettings: 'Administration Settings',
    AddSetting: 'Add Setting',
    Setting: 'Setting',
    EditSetting: 'Edit Setting',
    CloneSetting: 'Clone Setting',
    DeleteSetting: 'Delete Setting',
    SettingWasEditedSuccessfully: 'The setting was edited successfully',
    SettingWasClonedSuccessfully: 'The setting was cloned successfully',
    SettingWasCreatedSuccessfully: 'The setting was created successfully',
    BaseKind: 'Base Kind',
    SettingName: 'Setting Name',
    PartnerName: 'Partner Name',
    SelfKind: 'Self Kind',
    CreateSetting: ' Create Setting',
    Value: 'Value',
    EditProvider: 'Edit Provider',
    CreateProvider: 'Create Provider',
    CloneProvider: 'Clone Provider',
    ProviderId: 'Provider Id',
    ProviderCreated: 'Provider Created',
    ProviderUpdated: 'Provider Updated',
    Port: 'Port',
    PartnerEmail: 'Partner Email',
    PartnerId: 'Partner Id',
    Parallelism: 'Parallelism',
    DelayTimeout: 'Delay Timeout',
    IsDefault: 'Is Default',
    IsCrmActivated: 'Is Crm Activated',
    Unknown: 'Unknown',
    Trial: 'Trial',
    Production: 'Production',
    Inactive: 'Inactive',
    Lost: 'Lost',
    Terminated: 'Terminated',
    PartnerTimeZone: 'Partner Time Zone',
    FiltersTimeZone: 'Filters Time Zone',
    SubscriptionName: 'Subscription Name',
    ActiveClients: 'Clients (Active/Total)',
    Workflows: 'Workflows',
    Communications: 'Communications',
    IgnoreTrackedLinks: 'Ignore Tracked Links',
    AllowTrackEmailLinks: 'Email',
    AllowTrackInternalLinks: 'Internal',
    AllowTrackSMSLinks: 'SMS',
    AllowTrackTelegramLinks: 'Telegram',
    AllowTrackPopUpLinks: 'Builder PopUp',
    AllowTrackPushNotificationLinks: 'Push Notification',
    AllowTrackCMSPopUpLinks: 'CMS PopUp',
    AllowTrackViberLinks: 'Viber',
    AddLink: 'Add Link',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    AreYouSureYouWantToChangePartnerActivation: 'Are you sure you want to {{toState}} {{partnerId}} {{name}} partner.',
    CRMIsNotActivated: 'CRM Is Not Activated',
    DataWarehouse: 'DataWarehouse',
    UIType: 'UIType',
    IsKPI: 'IsKPI',
    IsFilter: 'IsFilter',
    ExportType: 'ExportType',
    NotInjectUnsubscribeLink: 'Not Inject Unsubscribe Link',
    CreateClassifier: 'Create Classifier',
    EditClassifier: 'Edit Classifier',
    CloneClassifier: 'Clone Classifier',
    Classifiers: 'Classifiers',
    MenuItems: 'MenuItems',
    Id: 'Id',
    GroupId: 'GroupId',
    NumId: 'NumId',
    Options: 'Options',
    Query: 'Query',
    Classifier: 'Classifier',
    Custom: 'Custom',
    Multifield: 'Multifield',
    DeleteClassifier: 'Delete Classifier',
    ClassifierWasCreatedSuccessfully: 'Classifier was created Successfully',
    ClassifierWasClonedSuccessfully: 'Classifier was clone Successfully',
    ClassifierWasUpdatedSuccessfully: 'Classifier was updated Successfully',
    CreateMenuItem: 'Create menuItem',
    EditMenuItem: ' Edit menuItem',
    CloneMenuItem: 'Clone menuItem',
    AdministrationClassifiers: 'Administration Classifiers',
    AdministrationMenuItems: 'Administration MenuItems',
    DeleteMenuItem: 'Delete menuItem',
    MenuItemWasCreatedSuccessfully: 'MenuItem was created successfully',
    MenuItemWasUpdatedSuccessfully: 'MenuItem was updated successfully',
    SystemMessage: 'System Message',
    MaintenanceDateAndTimeRangeUTC: 'Maintenance Date and Time Range (UTC)',
    PartnerGroupName: 'Partner Group Name',
    PartnerGroupId: 'Partner Group Id',
    PartnerGroup: 'Partner Group',
    PartnerGroupPermissionWarning: 'You do not have permission to change Partner Group settings',
};
