import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Components
import { l, WeekDaysKeyList } from 'constants/common';

const CJDTWeekdayInfoBlock = ({ data }) => {
    const { t } = useTranslation();

    return (
        <CJInfoBlockLabelValueWrapper label={t(l.Weekday)} value={t(WeekDaysKeyList[data?.value?.params?.weekday])} />
    );
};

CJDTWeekdayInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJDTWeekdayInfoBlock);
