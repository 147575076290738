const TemplateType = {
    SMS: 'SMS',
    Hoory: 'Hoory',
    FastexNotification: 'FastexNotification',
    Internal: 'Internal',
    Telegram: 'Telegram',
    Email: 'Email',
    PushNotification: 'PushNotification',
    CustomerJourneyVisualization: 'CustomerJourneyVisualization',
    PopUp: 'PopUp',
    CMSPopUp: 'CMSPopUp',
    Viber: 'Viber',
    DynamicPopUp: 'DynamicPopUp',
};

const TemplateSymbolsCountFields = {
    Telegram: '1000',
    PushNotification: '4000',
    Viber: '1000',
    Hoory: '6000',
    FastexNotification: '200',
};

const ViberTemplateConfig = {
    DefaultValidityPeriodInMinutes: '480',
    MaxValidityPeriodInMinutes: '1440',
    MinValidityPeriodInMinutes: '10',
};

export { TemplateType, TemplateSymbolsCountFields, ViberTemplateConfig };
