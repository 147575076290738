import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Import UI components
import { Dropdown } from '@geneui/components';
// Import scss
import 'assets/scss/validatableDropdown.scss';

const ValidatableDropdown = ({ errorText, isValid, wrapperClassName, ...rest }) => {
    return (
        <div className={classNames({ 'crm-invalid-dropdown': !isValid }, { [wrapperClassName]: wrapperClassName })}>
            <Dropdown autocomplete="off" {...rest} />
            <p>{errorText}</p>
        </div>
    );
};

ValidatableDropdown.propTypes = {
    isValid: PropTypes.bool,
    errorText: PropTypes.string,
    wrapperClassName: PropTypes.string,
};

ValidatableDropdown.defaultProps = {
    isValid: true,
    errorText: '',
    wrapperClassName: '',
};

export default ValidatableDropdown;
