import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop, values as valuesOfObj } from 'lodash';
import { useFormik } from 'formik';
// Import Components
import { ValidatableDropdown } from 'components';
// Import Constants
import { MonthsAndDays, l } from 'constants/common';
import { dayOfMonthInitialValueCreator } from './config';

const DayOfYear = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: dayOfMonthInitialValueCreator(defaultValue, option?.Required, t),
        onSubmit: noop,
    });
    const { values, setFieldValue } = formik;
    const { dates, months, monthsData, daysData } = values;

    const dateChangeHandler = (e) => {
        setFieldValue('dates', e.value);
    };

    const monthChangeHandler = (e) => {
        setFieldValue('months', e.value);
        const arrayOfDays = [];
        for (let i = 0; i < valuesOfObj(MonthsAndDays)[e.value]; i++) {
            arrayOfDays.push({ label: i + 1, value: `${i + 1}` });
        }
        setFieldValue('daysData', arrayOfDays);
        setFieldValue('dates', '1');
    };

    useEffect(() => {
        getUpdate({
            dates,
            months,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates, months]);

    return (
        <>
            <ValidatableDropdown
                isMultiSelect={false}
                disabled={false}
                inputSize="default"
                placeholder={t(l.Month)}
                appearance="outline"
                value={months}
                data={monthsData}
                onChange={monthChangeHandler}
                searchPlaceholderText={t(l.Search)}
                noDataText={t(l.NoDataFound)}
                // isValid={isValidMonth}
            />
            <ValidatableDropdown
                isMultiSelect={false}
                disabled={false}
                inputSize="default"
                appearance="outline"
                value={dates}
                data={daysData}
                onChange={dateChangeHandler}
                // isValid={!(isValidMonth && !isValidDay)}
            />
        </>
    );
};

DayOfYear.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(DayOfYear);
