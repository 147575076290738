import React from 'react';
// Import UI components
import { Radio } from '@geneui/components';
import { LabelTag } from 'components';
// Import constants
import { l, LabelsModalViews } from 'constants/common';

const LabelsModalView = {
    manageLabelsView: {
        key: LabelsModalViews.manageLabelsView,
        isVisibleSave: false,
        title: l.ManageLabels,
        isClose: true,
        columns: [
            {
                text: l.Title,
                dataKey: l.Name,
                sortable: false,
                // eslint-disable-next-line react/display-name
                colRenderer: (value, _index, row) => {
                    const { Color } = row.data;
                    return <LabelTag color={Color} labelName={value} />;
                },
            },
            {
                text: l.Description,
                dataKey: l.Description,
                sortable: false,
            },
        ],
    },
    setLabelView: {
        key: LabelsModalViews.setLabelView,
        isVisibleSave: true,
        title: l.SetLabel,
        isClose: true,
        columns: [
            {
                width: 90,
                sortable: false,
                // eslint-disable-next-line react/display-name
                colRenderer: (_value, _index, row) => (
                    <Radio
                        value={row.data.ObjectCategoryId}
                        checked={row.isChecked}
                        onChange={(event) => {
                            const { value } = event.target;
                            row.selectLabelHandler(value);
                            return value;
                        }}
                    />
                ),
            },
            {
                text: l.Title,
                dataKey: l.Name,
                sortable: false,
                // eslint-disable-next-line react/display-name
                colRenderer: (value, _index, row) => <LabelTag color={row.data.Color} labelName={value} />,
            },
            {
                text: l.Description,
                dataKey: l.Description,
                sortable: false,
            },
        ],
    },
    editLabelView: {
        key: LabelsModalViews.editLabelView,
        isVisibleSave: true,
        title: l.EditLabel,
        isClose: false,
    },
    createLabelView: {
        key: LabelsModalViews.createLabelView,
        isVisibleSave: true,
        title: l.CreateLabel,
        isClose: false,
    },
};

const LabelsColors = {
    colors: [
        {
            value: '#FF0000',
        },
        {
            value: '#DC143C',
        },
        {
            value: '#FF00FF',
        },
        {
            value: '#BA55D3',
        },
        {
            value: '#4B0082',
        },
        {
            value: '#7B68EE',
        },
        {
            value: '#1E90FF',
        },
        {
            value: '#00BFFF',
        },
        {
            value: '#00FFFF',
        },
        {
            value: '#20B2AA',
        },
        {
            value: '#32CD32',
        },
        {
            value: '#9ACD32',
        },
        {
            value: '#808000',
        },
        {
            value: '#FFA500',
        },
        {
            value: '#FF8C00',
        },
        {
            value: '#FF4500',
        },
        {
            value: '#A52A2A',
        },
        {
            value: '#800000',
        },
    ],
};

export { LabelsModalView, LabelsColors };
