import {
    CRMRequestService,
    CRMRequestDownloadLinkService,
    CRMRequestServiceDownload,
    getCancelableRequest,
    postCancelableRequest,
    deleteCancelableRequest,
} from './http.config';

const SegmentHttpService = {
    getSegmentList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/List',
        });
    },
    getSegment: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ segmentId, isClone }) => `/Segment/Get?id=${segmentId}&isClone=${isClone}`,
        });
    },
    deleteSegment: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `/Segment/Delete?id=${segmentId}`,
        });
    },
    deleteSegments: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Segment/Delete/`,
        });
    },
    convertToReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/CreateAdHocReportFromSegment',
        });
    },
    getReportModel: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `/Segment/GetReportModel?segmentId=${segmentId}`,
        });
    },
    unarchiveSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `/Segment/unArchive?id=${segmentId}`,
        });
    },
    unarchiveSegments: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Segment/unArchive`,
        });
    },
    archiveSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `Segment/Archive?id=${segmentId}`,
        });
    },
    archiveSegments: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Segment/Archive`,
        });
    },
    getSegmentClientCount: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `/Segment/GetSegmentClientCount?id=${segmentId}`,
        });
    },
    getCampaignsBySegment: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `/Segment/CampaignsBySegmentId?segmentid=${segmentId}`,
        });
    },
    getStaticSegmentTop200Client: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `/Segment/GetSegmentTop200CLientsList?id=${segmentId}`,
        });
    },
    uploadStaticSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: () => '/Segment/UploadFile',
        });
    },
    uploadNonCustomerSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: () => '/NonCustomer/Upload1',
        });
    },
    saveStaticSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: () => '/Segment/SaveFromList',
        });
    },
    getSegmentCount: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/Count',
        });
    },
    getCompositeSegmentCount: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/CompositeSegmentCount',
        });
    },
    getSegmentById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ segmentId, isClone }) => `/Segment/Get?id=${segmentId}&isClone=${isClone}`,
        });
    },
    getQueryConfigurationsList: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/SegmentationCategory/List`,
        });
    },
    saveDynamicSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/Create',
        });
    },
    editDynamicSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/Update',
        });
    },
    getDynamicSegmentPreview: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/PreviewReportResult',
        });
    },
    getClientPreview: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/PreviewClient',
        });
    },
    getCompositeSegmentPreview: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/CompositePreviewReportResult',
        });
    },
    applyNonCustomerSegmentColumns: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/NonCustomer/ValidateFile',
        });
    },
    saveNonCustomerSegmentColumns: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/NonCustomer/Upload2',
        });
    },
    getNonCustomerSegmentPreview: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ segmentId }) => `/NonCustomer/Preview?id=${segmentId}`,
        });
    },
    downloadStaticSegmentInfo: () => {
        return postCancelableRequest({
            requestService: CRMRequestServiceDownload,
            endpointPath: () => '/Segment/GetExcelFromDb',
        });
    },
    downloadUploadedSegment: () => {
        return postCancelableRequest({
            requestService: CRMRequestServiceDownload,
            endpointPath: ({ segmentId }) =>
                `/Segment/DownloadUploadedSegment?segmentId=${segmentId}&DocumentType=xlsx`,
        });
    },
};

export default SegmentHttpService;
