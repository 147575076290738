import { AdminProviderActionTypes as Types } from './types';

const setAdminProviderTableFilters = (filters) => ({
    type: Types.SET_ADMIN_PROVIDER_TABLE_FILTERS,
    payload: filters,
});

const setAdminProviderTablePageNumber = (pageNumber) => ({
    type: Types.SET_ADMIN_PROVIDER_TABLE_PAGE_NUMBER,
    payload: pageNumber,
});

const setAdminProviderTablePageSize = (pageSize) => ({
    type: Types.SET_ADMIN_PROVIDER_TABLE_PAGE_SIZE,
    payload: pageSize,
});

const setAdminProviderTableSorting = (sorting) => ({
    type: Types.SET_ADMIN_PROVIDER_TABLE_SORTING,
    payload: sorting,
});

const resetAdminProviderState = () => ({
    type: Types.RESET_ADMIN_PROVIDER_STATE,
});

export default {
    setAdminProviderTableFilters,
    setAdminProviderTablePageNumber,
    setAdminProviderTablePageSize,
    setAdminProviderTableSorting,
    resetAdminProviderState,
};
