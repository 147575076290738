import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
// Import UI Components
import { Modal, Dropdown, Button, Radio, BusyLoader } from '@geneui/components';
// Import Components
import { ButtonWithLoader } from 'components';
// Import Actions
import { HeaderAction } from 'actions';
// Import Services
import { AuthHttpService } from 'services/http';
// Import Constants
import { l, Appearance, ViewMode } from 'constants/common';
// Import Images
import darkModeImg from 'assets/images/dark-mode-image.svg';
import lightModeImg from 'assets/images/light-mode-image.svg';

const UserProfileSettingsModal = ({ closeModal, saveHandler }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setApplicationModes } = HeaderAction;

    const { partnerCurrencies, settings } = useSelector((state) => state.header);

    const { ActivePartner } = settings;

    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);

    const [selectedPartnerCurrency, setSelectedPartnerCurrency] = useState(settings.Settings.CurrencyId);
    const [initialPartnerCurrency, setInitialPartnerCurrency] = useState(settings.Settings.CurrencyId);

    const [viewMode, setViewMode] = useState(
        `${isNil(ViewMode[settings.Settings.ViewMode]) ? ViewMode.Normal : settings.Settings.ViewMode}`,
    );
    const [viewTheme, setViewTheme] = useState(
        `${isNil(Appearance[settings.Settings.Appearance]) ? Appearance.Light : settings.Settings.Appearance}`,
    );
    const [isLoading, setIsLoading] = useState(false);

    const initialModeValues = useRef({
        ViewMode: viewMode,
        Appearance: viewTheme,
    });

    const partnerCurrencyRef = useRef(null);

    const isPartnerCurrencyChanged = initialPartnerCurrency !== selectedPartnerCurrency;
    const isViewModeChanged = +viewMode !== +initialModeValues.current.ViewMode;
    const isViewThemeChanged = +viewTheme !== +initialModeValues.current.Appearance;

    const partnerCurrencyChangeHandler = (e) => {
        setSelectedPartnerCurrency(e.value);
    };

    const viewModeChangeHandler = (value) => {
        return () => {
            setViewMode(value);
            dispatch(setApplicationModes({ Appearance: viewTheme, ViewMode: value }));
        };
    };

    const viewThemeChangeHandler = (value) => {
        return () => {
            setViewTheme(value);
            dispatch(setApplicationModes({ ViewMode: viewMode, Appearance: value }));
        };
    };

    const onCLoseModalHandler = () => {
        dispatch(setApplicationModes({ ...initialModeValues.current }));
        closeModal(false);
    };

    const onSaveHandler = () => {
        setIsSaveButtonLoading(true);

        saveHandler(
            {
                Currency: selectedPartnerCurrency,
                Appearance: viewTheme,
                ViewMode: viewMode,
            },
            !isPartnerCurrencyChanged,
        ).then(() => {
            setIsSaveButtonLoading(false);
            closeModal(false);
        });
    };

    const partnerCurrenciesDropdownData = useMemo(() => {
        return [
            { label: `${l.SyncWithPartnerCurrency} (${ActivePartner.CurrencyId})`, value: null },
            ...partnerCurrencies,
        ];
    }, [ActivePartner, partnerCurrencies]);

    useEffect(() => {
        setIsLoading(true);
        AuthHttpService.getUserSettings()
            .then(({ Data, HasError, AlertMessage }) => {
                if (HasError) {
                    throw new Error(AlertMessage);
                }

                setSelectedPartnerCurrency(Data.Currency);
                setInitialPartnerCurrency(Data.Currency);
                setViewMode(`${Data.ViewMode}`);
                setViewTheme(`${Data.Appearance}`);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <Modal
            background="dark-background"
            position="top"
            title="Settings"
            className="crm-modal settings-modal-cont"
            appearance="compact"
            onCancel={onCLoseModalHandler}
            closable={false}
            closeOnClickOutside={true}
            visible={true}
            size="content-size"
            footer={
                <>
                    <Button appearance="minimal" onClick={onCLoseModalHandler}>
                        {t(l.Close)}
                    </Button>
                    <ButtonWithLoader
                        isLoading={isSaveButtonLoading}
                        onClick={onSaveHandler}
                        disabled={!(isPartnerCurrencyChanged || isViewModeChanged || isViewThemeChanged)}
                    >
                        {t(l.Save)}
                    </ButtonWithLoader>
                </>
            }
        >
            <div className="settings-popup-container">
                <BusyLoader isBusy={isLoading}>
                    <div className="settings-popup-c">
                        <Dropdown
                            ref={partnerCurrencyRef}
                            labelAppearance="swap"
                            isMultiSelect={false}
                            disabled={false}
                            hasSearch={true}
                            onChange={partnerCurrencyChangeHandler}
                            value={selectedPartnerCurrency}
                            inputSize="big"
                            placeholder={t(l.CurrencyForReports)}
                            appearance="outline"
                            data={partnerCurrenciesDropdownData}
                            noDataText={t(l.NoDataFound)}
                        />
                    </div>

                    <div className="title-wrapper">
                        <p className="title-wrapper-text">{t(l.Appearance)}</p>
                        <span className="title-wrapper-line" />
                    </div>

                    <div className="settings-popup-mode settings-el-layout">
                        <button
                            className="settings-popup-radio-button"
                            onClick={viewThemeChangeHandler('0')}
                            // eslint-disable-next-line react/no-unknown-property
                            appearance="minimal"
                        >
                            <img alt="" src={lightModeImg} />
                            <div className="settings-popup-radio-button-title">
                                <Radio checked={viewTheme === '0'} />
                                <p>{t(l.Light)}</p>
                            </div>
                        </button>
                        <button
                            className="settings-popup-radio-button"
                            onClick={viewThemeChangeHandler('1')}
                            // eslint-disable-next-line react/no-unknown-property
                            appearance="minimal"
                        >
                            <img alt="" src={darkModeImg} />
                            <div className="settings-popup-radio-button-title">
                                <Radio checked={viewTheme === '1'} />
                                <p>{t(l.Dark)}</p>
                            </div>
                        </button>
                    </div>

                    <div className="title-wrapper">
                        <p className="title-wrapper-text">{t(l.ViewMode)}</p>
                        <span className="title-wrapper-line" />
                    </div>

                    <div className="settings-popup-view settings-el-layout">
                        <button
                            className="settings-popup-radio-button"
                            onClick={viewModeChangeHandler('0')}
                            // eslint-disable-next-line react/no-unknown-property
                            appearance="minimal"
                        >
                            <span className="view-mode-item view-mode-item-1">Aa</span>
                            <div className="settings-popup-radio-button-title">
                                <Radio checked={viewMode === '0'} />
                                <p>{t(l.Small)}</p>
                            </div>
                        </button>
                        <button
                            className="settings-popup-radio-button"
                            onClick={viewModeChangeHandler('1')}
                            // eslint-disable-next-line react/no-unknown-property
                            appearance="minimal"
                        >
                            <span className="view-mode-item view-mode-item-2">Aa</span>
                            <div className="settings-popup-radio-button-title">
                                <Radio checked={viewMode === '1'} />
                                <p>{t(l.Normal)}</p>
                            </div>
                        </button>
                        <button
                            className="settings-popup-radio-button"
                            onClick={viewModeChangeHandler('2')}
                            // eslint-disable-next-line react/no-unknown-property
                            appearance="minimal"
                        >
                            <span className="view-mode-item view-mode-item-3">Aa</span>
                            <div className="settings-popup-radio-button-title">
                                <Radio checked={viewMode === '2'} />
                                <p>{t(l.Large)}</p>
                            </div>
                        </button>
                    </div>
                </BusyLoader>
            </div>
        </Modal>
    );
};

UserProfileSettingsModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
};

export default UserProfileSettingsModal;
