import React, { memo, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { noop, isNil, first, isEmpty } from 'lodash';
// Import UI Components
import { Switcher, Tooltip } from '@geneui/components';
import { DataTable, DownloadOrSendToEmailToolbar } from 'components';
import { CommunicationActionsModals, CommunicationActionsButtons } from 'components/CommunicationList';
// Import Hooks
import { useListBaseRequestBody, useToaster, useRequest, useQueryParams, useGACustomEvent } from 'hooks';
// Import Services
import { CommunicationHttpService } from 'services/http';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';
import { DataTableColumnsConfigs, CommunicationPagingOptionsShowAll } from './config.js';
// Import SCSS
import 'assets/scss/segmentListPage.scss';

const { GAFiltersCategory } = GACategoryEvents;

// eslint-disable-next-line react/display-name
const CommunicationList = forwardRef(
    (
        { listAction, getHeaderActions, isShowVerticalDots, isShowColumnChooser, listSelectorKey, defaultFilter },
        ref,
    ) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const { showToaster } = useToaster();
        const { doPostRequest } = useRequest();
        const { logCustomEvent } = useGACustomEvent();

        const { tableData } = useSelector((state) => state[listSelectorKey]);
        const { filters, paging, sorting } = tableData;

        const isInit = useRef(false);
        const dataTableRef = useRef();

        const timeoutId = useRef(-1);

        const communicationConfigurationQueryParams = useQueryParams('communicationConfiguration');

        const [isShowAllMode, setIsShowAllMode] = useState(() => {
            const decodedValue = communicationConfigurationQueryParams.decode();
            return !isEmpty(decodedValue) && !!decodedValue?.showAll;
        });

        const [
            baseRequestBody,
            // eslint-disable-next-line no-unused-vars
            _newFilterField,
            setBaseRequestBodyFilters,
            setBaseRequestBodyPagingPageNumber,
            setBaseRequestBodyPagingPageSize,
            setBaseRequestBodySorting,
        ] = useListBaseRequestBody(filters, paging, sorting);

        const {
            setCommunicationListTablePagingPageNumber,
            setCommunicationListTablePagingPageSize,
            setCommunicationListTableSorting,
            resetCommunicationListState,
        } = listAction;

        const [isCommunicationListLoading, setIsCommunicationListLoading] = useState(false);
        const [communicationListFilters, setCommunicationListFilters] = useState(baseRequestBody);
        const [communicationListData, setCommunicationListData] = useState([]);
        const [communicationListDataCount, setCommunicationListDataCount] = useState(0);

        const [isResendModalOpenedState, setIsResendModalOpenedState] = useState(false);
        const [isInfoModalOpenedState, setIsInfoModalOpenedState] = useState(false);
        const [isFileLoading, setIsFileLoading] = useState(false);

        const [openedModalData, setOpenedModalData] = useState({});

        const { columns } = DataTableColumnsConfigs(t);

        // Communication fetch part
        const { getCommunicationsRequest, downloadCommunicationsReportRequest } = useRef({
            getCommunicationsRequest: CommunicationHttpService.getCommunicationList(),
            downloadCommunicationsReportRequest: CommunicationHttpService.downloadCommunicationsReport(),
        }).current;

        const getCommunications = useCallback(
            (filter = communicationListFilters) => {
                setIsCommunicationListLoading(true);
                doPostRequest(getCommunicationsRequest.request, {
                    requestBody: filter,
                    queryString: { withTotalCount: isShowAllMode },
                    successCallback: ({ Data, Count }) => {
                        setCommunicationListData(getTableRows(Data));
                        setCommunicationListDataCount(Count);
                    },
                }).then(() => {
                    setIsCommunicationListLoading(false);
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [communicationListFilters, isShowAllMode],
        );

        const hasPromotion = () => {
            const hasPromotion = communicationListFilters?.Filters.some((item) => item.Name === 'PromotionId');

            if (!isNil(defaultFilter) && !hasPromotion) {
                communicationListFilters.Filters = [...defaultFilter.Filters];
            }
        };

        const resendCommunicationAction = (communication) => {
            setOpenedModalData(communication);
            setIsResendModalOpenedState(true);
        };

        const modalsStateSetDict = {
            resendCommunication: {
                key: 'resendCommunication',
                fn: setIsResendModalOpenedState,
            },
            infoCommunication: {
                key: 'infoCommunication',
                fn: setIsInfoModalOpenedState,
            },
        };

        const onCloseModalHandler = (modalStateKey, _action, alertType, alertMessage, _count = 1) => {
            showToaster(alertType, alertMessage);
            modalsStateSetDict[modalStateKey].fn(false);
        };

        const getRowActionBar = (row) => {
            const { data } = row;

            return (
                <>
                    <CommunicationActionsButtons data={data} resendCommunicationAction={resendCommunicationAction} />
                </>
            );
        };

        // Data table part
        const setPagingPageNumberHandler = (pageNumber) => {
            setBaseRequestBodyPagingPageNumber(pageNumber);
            setCommunicationListFilters(baseRequestBody);
            dispatch(setCommunicationListTablePagingPageNumber(pageNumber));
        };

        const setPagingPageSizeHandler = (pageSize) => {
            setBaseRequestBodyPagingPageSize(pageSize);
            dispatch(setCommunicationListTablePagingPageSize(pageSize));
            setCommunicationListFilters(baseRequestBody);
        };

        const setSortingHandler = (sortingDirection, columnName) => {
            setBaseRequestBodySorting(sortingDirection, columnName);
            dispatch(setCommunicationListTableSorting(baseRequestBody.Sorting));
            setCommunicationListFilters(baseRequestBody);
        };

        const getTableColumns = () => {
            return columns;
        };

        const getTableRows = (communicationList) => {
            return communicationList.map(
                ({
                    CommunicationId,
                    ClientId,
                    ClientName,
                    SentDate,
                    Channel,
                    Status,
                    Message,
                    PromotionName,
                    PromotionType,
                    ParentId,
                    PromotionId,
                    ObjectId,
                    CanResend,
                    ObjectName,
                }) => {
                    return {
                        hasHover: true,
                        dragDisable: true,
                        nestedData: [],
                        data: {
                            rowKey: CommunicationId,
                            isRowOpend: false,
                            CommunicationId,
                            ClientId,
                            ClientName,
                            SentDate,
                            Channel,
                            Status,
                            Message,
                            PromotionName,
                            PromotionType,
                            ParentId,
                            PromotionId,
                            ObjectId,
                            CanResend,
                            ObjectName,
                        },
                    };
                },
            );
        };

        const getTableRefreshFn = () => {
            hasPromotion();
            getCommunications(communicationListFilters);
        };

        const getDataTableName = () => <span>{t(l.Communications)}</span>;

        useImperativeHandle(ref, () => ({
            reset() {
                dataTableRef.current.reset();
            },
        }));

        const dynamicProps = useMemo(() => {
            return !isShowAllMode
                ? { recevedCurrentPageSize: first(CommunicationPagingOptionsShowAll[isShowAllMode]).value }
                : {};
        }, [isShowAllMode]);

        const showAllModeChangeHandler = () => {
            logCustomEvent(
                GAFiltersCategory.name,
                GAFiltersCategory.events[`${listSelectorKey}All`],
                'communication report page',
                1,
            );
            setIsShowAllMode((prev) => {
                if (!isNil(dataTableRef?.current)) {
                    dataTableRef.current.changeTableConfig({
                        pageNumber: 1,
                        pageSize: first(CommunicationPagingOptionsShowAll[!prev])?.value,
                    });
                }
                communicationConfigurationQueryParams.historyReplace({ showAll: !prev });
                return !prev;
            });
        };

        // Filter update part
        useEffect(() => {
            if (defaultFilter) {
                communicationListFilters.Filters = [...communicationListFilters.Filters, ...defaultFilter.Filters];
                isInit.current = true;
            }

            if (isInit.current) {
                getCommunications(communicationListFilters);
            }
            /* eslint-disable react-hooks/exhaustive-deps */
        }, [communicationListFilters]);

        useEffect(() => {
            setBaseRequestBodyFilters(filters);
            setCommunicationListFilters(baseRequestBody);
            isInit.current = true;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filters, isShowAllMode]);

        const cleanUp = () => {
            return () => {
                // Cancel all async processes
                getCommunicationsRequest.cancel('CommunicationList:getCommunicationsRequest');
                // Reset store state
                dispatch(resetCommunicationListState());
                if (timeoutId.current !== -1) {
                    clearInterval(timeoutId.current);
                }
            };
        };

        const getRequestBody = () => {
            setIsFileLoading(true);
            hasPromotion();
            return {
                Filters: communicationListFilters.Filters,
                ...(!isShowAllMode && { Pageing: communicationListFilters.Pageing }),
            };
        };

        const downloadAllOrSendEmailClickHandler = (isDownload) => {
            setIsFileLoading(true);
            return doPostRequest(downloadCommunicationsReportRequest.request, {
                requestBody: getRequestBody(),
                queryString: { isDownload },
                successCallback: (data) => {
                    isDownload && window.open(data?.Url);
                },
            }).then(({ AlertType, AlertMessage }) => {
                showToaster(AlertType, AlertMessage);
                setIsFileLoading(false);
            });
        };

        const headerActions = () => {
            return (
                <>
                    <div className="table-header-filter-switcher-container">
                        {getHeaderActions()}
                        <Switcher
                            label={
                                <Tooltip text={t(l.ShowAllTooltipInfo)} position="bottom">
                                    <span>{t(l.ShowAll)}</span>
                                </Tooltip>
                            }
                            size="big"
                            checked={isShowAllMode}
                            onChange={showAllModeChangeHandler}
                        />
                    </div>
                </>
            );
        };

        useEffect(cleanUp, []);

        return (
            <>
                <DataTable
                    rowKey="CommunicationId"
                    columnKey="dataKey"
                    name={getDataTableName()}
                    isColumnsSortable={true}
                    tableKey={'dataTableCommunication'}
                    withPagination={true}
                    setLabelObjectIdKey="CommunicationId"
                    labelNameKey="CategoryName"
                    labelColorKey="CategoryColor"
                    data={communicationListData}
                    dataCount={communicationListDataCount}
                    rowActionBar={getRowActionBar}
                    columns={getTableColumns()}
                    isLoading={isCommunicationListLoading}
                    isShowRefreshButton={true}
                    isShowColumnChooser={isShowColumnChooser}
                    isShowVerticalDots={isShowVerticalDots}
                    setPagingPageSize={setPagingPageSizeHandler}
                    setPagingPageNumber={setPagingPageNumberHandler}
                    sorting={{
                        name: communicationListFilters.Sorting?.Name,
                        direction: communicationListFilters.Sorting?.Direction,
                    }}
                    setSorting={setSortingHandler}
                    onRefreshClick={getTableRefreshFn}
                    headerActions={headerActions()}
                    withQueryParams={true}
                    ref={dataTableRef}
                    pagingOptions={CommunicationPagingOptionsShowAll[isShowAllMode]}
                    TitleActions={
                        <DownloadOrSendToEmailToolbar
                            downloadAction={() => downloadAllOrSendEmailClickHandler(true)}
                            sendToEmailAction={() => downloadAllOrSendEmailClickHandler(false)}
                            isFileLoading={isFileLoading}
                            isDisabledDownload={isNil(communicationListDataCount) || communicationListDataCount === 0}
                        />
                    }
                    {...dynamicProps}
                />

                <CommunicationActionsModals
                    isResendModalOpenedState={isResendModalOpenedState}
                    isInfoModalOpenedState={isInfoModalOpenedState}
                    onCloseModalHandler={onCloseModalHandler}
                    modalsStateSetDict={modalsStateSetDict}
                    openedModalData={openedModalData}
                />
            </>
        );
    },
);

CommunicationList.propTypes = {
    listSelectorKey: PropTypes.string.isRequired,
    isShowVerticalDots: PropTypes.bool,
    listAction: PropTypes.shape({
        setCommunicationListTableFilters: PropTypes.func.isRequired,
        setCommunicationListTablePagingPageNumber: PropTypes.func.isRequired,
        setCommunicationListTablePagingPageSize: PropTypes.func.isRequired,
        setCommunicationListTableSorting: PropTypes.func.isRequired,
        resetCommunicationListState: PropTypes.func.isRequired,
    }).isRequired,
    getHeaderActions: PropTypes.func,
    isShowColumnChooser: PropTypes.bool,
    defaultFilter: PropTypes.object,
};

CommunicationList.defaultProps = {
    getHeaderActions: noop,
    isShowVerticalDots: true,
    isShowColumnChooser: true,
};

export default memo(CommunicationList);
