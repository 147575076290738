import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { noop, isEmpty } from 'lodash';
// Import UI components
import { ButtonWithLoader } from 'components';
import { Modal, ExtendedInput, Button } from '@geneui/components';
// Import constants
import { l } from 'constants/common';

const SingleInputModal = ({
    isModalVisible,
    titleTextLabel,
    onCancel,
    onOk,
    onOkBtnTextLabel,
    inputName,
    setInputName,
    inputPlaceholderLabel,
    inputNoteLabel,
    isLoading,
    externalValidationFn,
    externalValidationErrorMessage,
    ...restProps
}) => {
    const { t } = useTranslation();
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);

    const formik = useFormik({
        initialValues: { inputValue: inputName },
        onSubmit: noop,
        validationSchema: Yup.object({
            inputValue: Yup.string()
                .matches(/^[A-Za-z0-9]/, t(l.FirstCharacterCantBeASymbol, { fieldName: inputPlaceholderLabel }))
                .min(3, t(l.MustContainMinimumCharacters, { fieldName: inputPlaceholderLabel, count: 3 }))
                .test(
                    'withoutSpaceInTheEnd',
                    t(l.MustContainMinimumCharacters, { fieldName: inputPlaceholderLabel, count: 3 }),
                    (value) => value?.trimEnd().length >= 3,
                )
                .max(50, t(l.MustContainMaximumCharacters, { fieldName: inputPlaceholderLabel, count: 50 }))
                // test function for custom validation from outside
                .test('customValidationFn', externalValidationErrorMessage, externalValidationFn),
        }),
    });

    const { values, errors, touched, setFieldValue, setFieldTouched, validateForm, submitForm } = formik;

    const inputNameChangeHandler = (value) => {
        setInputName(value);
        setFieldValue('inputValue', value, false).then(() => setFieldTouched('inputValue'));
        setFieldTouched('inputValue');
        setIsOkBtnEnabled(value.length >= 3 && externalValidationFn(value));
    };

    const handleSave = () => {
        submitForm().then(() =>
            validateForm(values).then((formErrors) => {
                if (isEmpty(formErrors)) {
                    onOk();
                }
            }),
        );
    };

    return (
        <Modal
            background="dark-background"
            className="crm-single-input-modal"
            position="center"
            title={t(l[titleTextLabel])}
            closable={false}
            closeOnClickOutside={true}
            visible={isModalVisible}
            onCancel={onCancel}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader
                        color="primary"
                        onClick={handleSave}
                        isLoading={isLoading}
                        isDisabled={!isOkBtnEnabled}
                    >
                        {t(l[onOkBtnTextLabel])}
                    </ButtonWithLoader>
                </>
            }
            {...restProps}
        >
            <>
                {!isEmpty(inputNoteLabel) && <p className="crm-note-text">{t(l[inputNoteLabel])}</p>}
                <ExtendedInput
                    placeholder={t(l[inputPlaceholderLabel])}
                    value={values.inputValue}
                    onChange={(e) => inputNameChangeHandler(e.target.value)}
                    isValid={!(touched.inputValue && errors.inputValue)}
                    errorText={touched.inputValue && errors.inputValue}
                    labelAppearance="swap"
                />
            </>
        </Modal>
    );
};

SingleInputModal.propTypes = {
    titleTextLabel: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    setInputName: PropTypes.func.isRequired,
    inputPlaceholderLabel: PropTypes.string,
    inputNoteLabel: PropTypes.string,
    isModalVisible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    onOkBtnTextLabel: PropTypes.string,
    isLoading: PropTypes.bool,
    externalValidationErrorMessage: PropTypes.string,
    externalValidationFn: PropTypes.func,
};

SingleInputModal.defaultProps = {
    inputPlaceholderLabel: '',
    inputNoteLabel: '',
    isModalVisible: false,
    onCancel: noop,
    onOk: noop,
    onOkBtnTextLabel: l.Save,
    isLoading: false,
    externalValidationErrorMessage: '',
    externalValidationFn: () => true,
};

export default memo(SingleInputModal);
