import { TemplateActionsTypes as Types } from 'actions/types';

const addNewTemplateList = (payload) => ({ type: Types.ADD_TEMPLATE_LIST, payload });
const setTemplateAsDefault = (payload) => ({ type: Types.SET_TEMPLATE_AS_DEFAULT, payload });
const changeTemplateValue = (payload) => ({ type: Types.CHANGE_TEMPLATE_VALUE, payload });
const setTemplateValue = (payload) => ({ type: Types.SET_TEMPLATE_LIST, payload });
const setTemplateInitialData = (payload) => ({ type: Types.SET_TEMPLATE_INITIAL_DATA, payload });
const clearTemplateList = (payload) => ({ type: Types.CLEAR_TEMPLATE_LIST, payload });
const deleteTemplateLanguage = (payload) => ({ type: Types.DELETE_TEMPLATE_LANGUAGE, payload });
const setUIConfig = (payload) => ({ type: Types.SET_EDITOR_CONFIG, payload });

export default {
    addNewTemplateList,
    setTemplateAsDefault,
    clearTemplateList,
    changeTemplateValue,
    setTemplateValue,
    setTemplateInitialData,
    deleteTemplateLanguage,
    setUIConfig,
};
