import { CampaignLiveMonitorListActionTypes as Types } from './types';

const setCampaignLiveMonitorListTableFilters = (filters) => ({
    type: Types.SET_CAMPAIGN_LIVE_MONITOR_LIST_TABLE_FILTERS,
    payload: filters,
});

export default {
    setCampaignLiveMonitorListTableFilters,
};
