import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNil, noop } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
// Import UI components
import { Checkbox, Icon, Tooltip } from '@geneui/components';
// Import constants
import { DraggableTypes } from './service';

const Row = (props) => {
    const { isChecked, onClick, name, isPermanent, isDraggable, dropRow, getDraggingRow, hasInfoTooltip, withSelect } =
        props;
    const [_isChecked, setIsChecked] = useState(!isPermanent && isChecked);
    const itemRef = useRef({});
    const [itemClientWidth, setItemClientWidth] = useState(itemRef?.current?.clientWidth);

    useEffect(() => {
        if (!isPermanent) {
            setIsChecked(() => isChecked);
        } else {
            onClick(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked]);

    const handleClick = (e) => {
        const { checked } = e.currentTarget;
        setIsChecked(checked);
        onClick(checked);
    };

    // eslint-disable-next-line no-unused-vars
    const [__, drop] = useDrop({
        accept: DraggableTypes.Row,
        drop: (item, _monitor) => {
            dropRow(item, getDraggingRow());
        },
        collect: (monitor) => {
            return {
                isOver: !!monitor.isOver(),
            };
        },
    });

    // eslint-disable-next-line no-unused-vars
    const [_, drag, preview] = useDrag({
        item: {
            ...getDraggingRow(),
            type: DraggableTypes.Row,
            style: { width: itemClientWidth },
            withSelect: withSelect,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    preview(getEmptyImage(), { captureDraggingState: true });

    const attachRef = (current) => {
        itemRef.current = current;
        setItemClientWidth(itemRef.current?.clientWidth);
        if (isDraggable) {
            drag(current);
            drop(current);
        }
    };

    return (
        <div
            className={classNames('d-a-d-item', {
                active: true,
                selected: _isChecked,
                'selected-item': isPermanent,
            })}
            ref={attachRef}
        >
            <div className="d-a-d-item-label-part">
                {withSelect && (
                    <Checkbox checked={_isChecked ?? false} indeterminate={isNil(_isChecked)} onChange={handleClick} />
                )}
                <span>{name}</span>
                {hasInfoTooltip && (
                    <Tooltip text={props?.description} position="right">
                        <Icon type="bc-icon-info" />
                    </Tooltip>
                )}
            </div>

            {isDraggable && <Icon type="bc-icon-drag" />}
        </div>
    );
};

Row.propTypes = {
    isChecked: PropTypes.bool,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    isPermanent: PropTypes.bool,
    onClick: PropTypes.func,
    isDraggable: PropTypes.bool,
    dropRow: PropTypes.func,
    getDraggingRow: PropTypes.func,
    description: PropTypes.string,
    hasInfoTooltip: PropTypes.bool.isRequired,
    withSelect: PropTypes.bool,
};

Row.defaultProps = {
    withSelect: true,
    isChecked: false,
    isPermanent: false,
    name: '',
    onClick: noop,
    isDraggable: false,
    getDraggingRow: noop,
    dropRow: noop,
};

export default Row;
