import { CampaignPageSegmentListActionTypes as Types } from 'actions/types';
import { cloneDeep } from 'lodash';
import { campaignPageSegmentInitialState } from 'constants/listDefaultFilters';

export default (state = cloneDeep(campaignPageSegmentInitialState), action) => {
    switch (action.type) {
        case Types.RESET_CAMPAIGN_PAGE_SEGMENT_LIST_STATE:
            state = cloneDeep(campaignPageSegmentInitialState);
            return Object.assign({}, state, {});
        case Types.SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_FILTERS:
            state.tableData.filters = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER:
            state.tableData.paging.PageNumber = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE:
            state.tableData.paging.PageSize = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_SORTING:
            state.tableData.sorting = { ...action.payload };
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
