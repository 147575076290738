import { isNull, keys } from 'lodash';
// Import Constants
import { ErrorShowType, l, TemplateInputTypes } from 'constants/common';
// Import Services
import { TemplateSymbolsCountFields, ViberTemplateConfig } from 'services/template';

const buttonTextInputScenario = (value = { text: '', url: '' }) => ({
    isValid: true,
    errorText: '',
    value: { text: value?.text, url: value?.url },
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'viberButtonText',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'viberButtonTextMaxLength',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: '30',
        },
    ],
});

const buttonUrlInputScenario = (value = { text: '', url: '' }) => ({
    isValid: true,
    errorText: '',
    value: { text: value?.text, url: value?.url },
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'isUrlValidOrEmptyString',
            ErrorMessage: l.EnterValidUrl,
            ParametersValues: null,
        },
        {
            ValidationType: 'viberButtonUrl',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
    ],
});

const bodyScenario = (state) => ({
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'maxLengthWithRegEx',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: { ...state, fieldName: 'Body', count: TemplateSymbolsCountFields.Viber },
        },
    ],
});

const validityPeriodScenario = () => ({
    isValid: null,
    errorText: '',
    value: ViberTemplateConfig.DefaultValidityPeriodInMinutes,
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'lessThanOrEqual',
            ErrorMessage: l.MustBeSmallerOrEqualThan,
            ParametersValues: ViberTemplateConfig.MaxValidityPeriodInMinutes,
        },
        {
            ValidationType: 'greaterThanOrEqual',
            ErrorMessage: l.MustBeBiggerOrEqualThan,
            ParametersValues: ViberTemplateConfig.MinValidityPeriodInMinutes,
        },
    ],
});

const SMSBodyScenario = () => ({
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'minLengthOptional',
            ErrorMessage: l.MustContainMinimumCharacters,
            ParametersValues: 3,
        },
    ],
});

const pageSMSPartsScenario = (state) => {
    return {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'lessThanOrEqual',
                ErrorMessage: l.MaxCharactersCountForSMSPartIsReached,
                ParametersValues: state.maxSMSPartsCount,
            },
        ],
    };
};

const viberTemplateValidationScenario = (state) => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    CrossValidation: {
        isValid: null,
        errorText: '',
        value: {},
        type: ErrorShowType.Toaster,
        validationCredentials: [
            {
                ValidationType: 'templateCrossValidationViber',
                ErrorMessage: '',
                ParametersValues: [TemplateInputTypes.Body],
            },
            ...(state.isCascade
                ? [
                      {
                          ValidationType: 'templateCrossValidationSMS',
                          ErrorMessage: '',
                          ParametersValues: [TemplateInputTypes.SMSBody],
                      },
                  ]
                : []),
        ],
    },
    defaultLangButtonText: {
        ...buttonTextInputScenario(),
    },
    defaultLangButtonUrl: {
        ...buttonUrlInputScenario(),
    },
    defaultLangBody: { ...bodyScenario(state) },
    defaultLangValidityPeriod: { ...validityPeriodScenario(state) },
    ...(state.isCascade && { defaultLangSMSBody: { ...SMSBodyScenario(state) } }),
    ...(state.isCascade && { defaultLangSMSParts: { ...pageSMSPartsScenario(state) } }),
});

const viberTemplateScenarioPropertiesCreator = (data, editCloneMode, state) => {
    if (editCloneMode) {
        return keys(data).reduce((acc, id) => {
            acc[`${id}Body`] = {
                ...bodyScenario(state),
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].Body,
            };
            acc[`${id}ValidityPeriod`] = {
                ...validityPeriodScenario(state),
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].ValidityPeriod,
            };
            acc[`${id}ButtonText`] = {
                ...buttonTextInputScenario({ text: data[id].ButtonText, url: data[id].ButtonUrl }),
                isValid: isNull(data[id].isValid) ? null : true,
            };
            acc[`${id}ButtonUrl`] = {
                ...buttonUrlInputScenario({ text: data[id].ButtonText, url: data[id].ButtonUrl }),
                isValid: isNull(data[id].isValid) ? null : true,
            };

            if (state.isCascade) {
                acc[`${id}SMSBody`] = {
                    ...SMSBodyScenario(state),
                    isValid: isNull(data[id].isValid) ? null : true,
                    value: data[id].SMSBody,
                };
                acc[`${id}SMSParts`] = {
                    ...pageSMSPartsScenario(state),
                    isValid: isNull(data[id].isValid) ? null : true,
                    value: data[id].SMSParts,
                };
            }
            return acc;
        }, {});
    }

    return data.reduce((acc, id) => {
        acc[`${id}Body`] = { ...bodyScenario(state) };
        acc[`${id}ValidityPeriod`] = { ...validityPeriodScenario(state) };
        acc[`${id}ButtonText`] = { ...buttonTextInputScenario() };
        acc[`${id}ButtonUrl`] = { ...buttonUrlInputScenario() };

        if (state.isCascade) {
            acc[`${id}SMSBody`] = { ...SMSBodyScenario(state) };
            acc[`${id}SMSParts`] = { ...pageSMSPartsScenario(state) };
        }

        return acc;
    }, {});
};

export { viberTemplateValidationScenario, viberTemplateScenarioPropertiesCreator };
