export default {
    ViewTranslation: 'ViewTranslation',
    ManageUsers: 'ManageUsers',
    ViewCountries: 'ViewCountries',
    ViewCurrencies: 'ViewCurrencies',
    ViewPaymentTypes: 'ViewPaymentTypes',
    ViewClients: 'ViewClients',
    ViewPlayers: 'ViewPlayers',
    ViewLanguages: 'ViewLanguages',
    ViewMarkets: 'ViewMarkets',
    ViewUsers: 'ViewUsers',
    ManageClients: 'ManageClients',
    ManagePlayers: 'ManagePlayers',
    ViewClientProfiles: 'ViewClientProfiles',
    ManageClientProfiles: 'ManageClientProfiles',
    ViewDocuments: 'ViewDocuments',
    ViewSports: 'ViewSports',
    ViewOtherSportMarkets: 'ViewOtherSportMarkets',
    ViewPartnersBooking: 'ViewPartnersBooking',
    ManagePartnersBooking: 'ManagePartnersBooking',
    ViewWithdrawalRequests: 'ViewWithdrawalRequests',
    ManageWithdrawalRequests: 'ManageWithdrawalRequests',
    ManageChat: 'ManageChat',
    ViewAccount: 'ViewAccount',
    ManageAccount: 'ManageAccount',
    AllPartners: 'AllPartners',
    ViewSettings: 'ViewSettings',
    ManageSettings: 'ManageSettings',
    ManageClientMessage: 'ManageClientMessage',
    ViewClientMessage: 'ViewClientMessage',
    ViewMatch: 'ViewMatch',
    ViewRegions: 'ViewRegions',
    ViewCompetitions: 'ViewCompetitions',
    ViewAccountTurnover: 'ViewAccountTurnover',
    ViewBalance: 'ViewBalance',
    ViewReports: 'ViewReports',
    ViewDepositRequests: 'ViewDepositRequests',
    ManageDepositRequests: 'ManageDepositRequests',
    ViewPaymentTypesReport: 'ViewPaymentTypesReport',
    ManageClientBalance: 'ManageClientBalance',
    ViewClientBalance: 'ViewClientBalance',
    ViewClientAccounts: 'ViewClientAccounts',
    ViewBetReport: 'ViewBetReport',
    ViewBalanceReport: 'ViewBalanceReport',
    ViewGames: 'ViewGames',
    ManagePartnerMatchPackages: 'ManagePartnerMatchPackages',
    ViewPartnerMatchPackages: 'ViewPartnerMatchPackages',
    ViewClientTransactions: 'ViewClientTransactions',
    ViewClientLogins: 'ViewClientLogins',
    ViewClientSportBets: 'ViewClientSportBets',
    ViewClientCasinoBets: 'ViewClientCasinoBets',
    ViewOddsAdditionalCols: 'ViewOddsAdditionalCols',
    ViewClientLogHistory: 'ViewClientLogHistory',
    ViewOddsFeed: 'ViewOddsFeed',
    ManageOddsFeed: 'ManageOddsFeed',
    ViewAgentSubAccounts: 'ViewAgentSubAccounts',
    ManageAgentSubAccounts: 'ManageAgentSubAccounts',
    ViewDashBoardActivePlayers: 'ViewDashBoardActivePlayers',
    ViewDashBoardNewRegistrations: 'ViewDashBoardNewRegistrations',
    ViewDashBoardCasinoBets: 'ViewDashBoardCasinoBets',
    ViewDashBoardSportBets: 'ViewDashBoardSportBets',
    ViewDashBoardTopFiveGames: 'ViewDashBoardTopFiveGames',
    ViewDashBoardTopFiveMatches: 'ViewDashBoardTopFiveMatches',
    ViewDashBoardTopFivePlayers: 'ViewDashBoardTopFivePlayers',
    ViewDashBoardTopFiveSelections: 'ViewDashBoardTopFiveSelections',
    ViewMenuDashBoard: 'ViewMenuDashBoard',
    ViewAgentMembers: 'ViewAgentMembers',
    ManageAgentMembers: 'ManageAgentMembers',
    ViewAgentTransfers: 'ViewAgentTransfers',
    ManageAgentTransfers: 'ManageAgentTransfers',
    ViewAgentSystem: 'ViewAgentSystem',
    ManageAgentSystem: 'ManageAgentSystem',
    ViewCasinoReport: 'ViewCasinoReport',
    ViewCompetitionReport: 'ViewCompetitionReport',
    ViewMarketReport: 'ViewMarketReport',
    ViewPaymentReport: 'ViewPaymentReport',
    ViewSalesReport: 'ViewSalesReport',
    ViewSportReport: 'ViewSportReport',
    ViewMenuReport: 'ViewMenuReport',
    ViewAgentGroups: 'ViewAgentGroups',
    ManageAgentGroups: 'ManageAgentGroups',
    ViewAgentSummaryBalanceStatement: 'ViewAgentSummaryBalanceStatement',
    ViewAgentBettingReport: 'ViewAgentBettingReport',
    ViewAgentTransferReport: 'ViewAgentTransferReport',
    ManageSportLimits: 'ManageSportLimits',
    ViewAgentPtGroups: 'ViewAgentPtGroups',
    ViewAgentCommissionGroups: 'ViewAgentCommissionGroups',
    ViewAgentBetLimitGroups: 'ViewAgentBetLimitGroups',
    ManageAgentPtGroups: 'ManageAgentPtGroups',
    ManageAgentCommissionGroups: 'ManageAgentCommissionGroups',
    ManageAgentBetLimitGroups: 'ManageAgentBetLimitGroups',
    ViewAgentMenu: 'ViewAgentMenu',
    ViewAgentMarketReport: 'ViewAgentMarketReport',
    ViewCasino: 'ViewCasino',
    ViewTemplate: 'ViewTemplate',
    CreateTemplate: 'CreateTemplate',
    EditTemplate: 'EditTemplate',
    DeleteTemplate: 'DeleteTemplate',
    ViewSegment: 'ViewSegment',
    CreateSegment: 'CreateSegment',
    EditSegment: 'EditSegment',
    DeleteSegment: 'DeleteSegment',
    ViewCampaign: 'ViewCampaign',
    CreateCampaign: 'CreateCampaign',
    EditCampaign: 'EditCampaign',
    DeleteCampaign: 'DeleteCampaign',
    ExecuteCampaign: 'ExecuteCampaign',
    ViewOCampaign: 'ViewOCampaign',
    CreateOCampaign: 'CreateOCampaign',
    EditOCampaign: 'EditOCampaign',
    DeleteOCampaign: 'DeleteOCampaign',
    ExecuteOCampaign: 'ExecuteOCampaign',
    ViewAddHocReport: 'ViewAddHocReport',
    CreateAddHocReport: 'CreateAddHocReport',
    EditAddHocReport: 'EditAddHocReport',
    DeleteAddHocReport: 'DeleteAddHocReport',
    ExecuteAddHocReport: 'ExecuteAddHocReport',
    Create: 'Create Campaign',
    CreateGroup: 'CreateGroup',
    Delete: 'Delete AdHocReportResult',
    DeleteBonus: 'DeleteBonus',
    EditAdHocReportResult: 'EditAdHocReportResult',
    EditBonus: 'EditBonus',
    Execute: 'Execute Segment',
    ExecuteSegment: 'ExecuteSegment',
    ExecuteTemplate: 'ExecuteTemplate',
    ManageAddHocReport: 'ManageAddHocReport',
    ManageCampaign: 'ManageCampaign',
    ManageEmailTemplates: 'ManageEmailTemplates',
    ManageOCampaign: 'ManageOCampaign',
    ManageSegment: 'ManageSegment',
    ManageTemplate: 'ManageTemplate',
    ViewAdHocReportResult: 'ViewAdHocReportResult',
    ViewBonus: 'ViewBonus',
    ViewEmailTemplates: 'ViewEmailTemplates',
    ViewGroup: 'ViewGroup',
    ViewUser: 'ViewUser',
    AllDocuments: 'AllDocuments',
    ViewDashBoardTopFiveMatchesByStake: 'ViewDashBoardTopFiveMatchesByStake',
    ViewDashBoardTopFiveSportsbookPlayers: 'ViewDashBoardTopFiveSportsbookPlayers',
    ViewCMS: 'ViewCMS',
    ViewPopulars: 'ViewPopulars',
    MakeCorrection: 'MakeCorrection',
    ManageBonus: 'ManageBonus',
    ManagePopulars: 'ManagePopulars',
    ManualSettlement: 'ManualSettlement',
    ViewDashBoardBottomFiveMatches: 'ViewDashBoardBottomFiveMatches',
    ViewDashBoardBottomFiveSelections: 'ViewDashBoardBottomFiveSelections',
    BEViewCasinoBonus: 'BEViewCasinoBonus',
    BEViewSportBonus: 'BEViewSportBonus',
    BEManageCasinoBonus: 'BEManageCasinoBonus',
    BEManageSportBonus: 'BEManageSportBonus',
    ViewVerificationStep: 'ViewVerificationStep',
    ManageVerificationStep: 'ManageVerificationStep',
    ManageClientBonuses: 'ManageClientBonuses',
    ViewClientTurnoverReport: 'ViewClientTurnoverReport',
    ViewMatchTurnoverReport: 'ViewMatchTurnoverReport',
    ViewCashDeskReport: 'ViewCashDeskReport',
    ViewFutureLiveMatches: 'ViewFutureLiveMatches',
    ViewDailySalesReport: 'ViewDailySalesReport',
    PreMatchBets: 'PreMatchBets',
    AllMatches: 'AllMatches',
    BetShopBetsOnly: 'BetShopBetsOnly',
    ViewAffiliate: 'ViewAffiliate',
    AllBetshops: 'AllBetshops',
    ManageAgentRelationship: 'ManageAgentRelationship',
    ViewMarketTypeReport: 'ViewMarketTypeReport',
    ManageLoyaltyRates: 'ManageLoyaltyRates',
    ManageLoyaltyLevels: 'ManageLoyaltyLevels',
    ManageLoyaltyGameMultiplayers: 'ManageLoyaltyGameMultiplayers',
    ManageLoyaltyGameGroups: 'ManageLoyaltyGameGroups',
    ViewLoyaltyRates: 'ViewLoyaltyRates',
    ViewLoyaltyLevels: 'ViewLoyaltyLevels',
    ViewLoyaltyGameMultiplayers: 'ViewLoyaltyGameMultiplayers',
    ManageClientCredit: 'ManageClientCredit',
    ManageAgentCredit: 'ManageAgentCredit',
    AssignAgentCredit: 'AssignAgentCredit',
    ViewClientDocuments: 'ViewClientDocuments',
    ViewBetShopBets: 'ViewBetShopBets',
    ViewCashDeskShiftReport: 'ViewCashDeskShiftReport',
    SGPlayersView: 'SGPlayersView',
    SGPlayersEdit: 'SGPlayersEdit',
    SGChatView: 'SGChatView',
    SGTablesView: 'SGTablesView',
    SGTournamentsView: 'SGTournamentsView',
    SGTournamentsCreateEdit: 'SGTournamentsCreateEdit',
    SGNotificationsView: 'SGNotificationsView',
    SGNotificationsCreateEdit: 'SGNotificationsCreateEdit',
    SGStatisticsRake: 'SGStatisticsRake',
    SGStatisticsTournament: 'SGStatisticsTournament',
    SGGamesView: 'SGGamesView',
    SGPlayerBlock: 'SGPlayerBlock',
    SGPartnersView: 'SGPartnersView',
    SGPartnerCreate: 'SGPartnerCreate',
    SGTableCreateEdit: 'SGTableCreateEdit',
    SGStatisticsPlayer: 'SGStatisticsPlayer',
    SGTournamentTicketsCreate: 'SGTournamentTicketsCreate',
    ManageTriggeredCampaign: 'ManageTriggeredCampaign',
    ManagePushCampaign: 'ManagePushCampaign',
    ResetUserPassword: 'ResetUserPassword',
    AllowCmsSkinsListUse: 'AllowCmsSkinsListUse',
    ViewCmsSkins: 'ViewCmsSkins',
    ManageCmsSkins: 'ManageCmsSkins',
    ViewCmsSystemSettings: 'ViewCmsSystemSettings',
    ManageCmsSystemSettings: 'ManageCmsSystemSettings',
    ViewCmsSkinSettings: 'ViewCmsSkinSettings',
    ManageCmsSkinSettings: 'ManageCmsSkinSettings',
    ViewCmsGameProviders: 'ViewCmsGameProviders',
    ManageCmsGameProviders: 'ManageCmsGameProviders',
    ViewCmsGameCategories: 'ViewCmsGameCategories',
    ManageCmsGameCategories: 'ManageCmsGameCategories',
    ViewCmsGameFeatures: 'ViewCmsGameFeatures',
    ManageCmsGameFeatures: 'ManageCmsGameFeatures',
    ViewCmsGameThemes: 'ViewCmsGameThemes',
    ManageCmsGameThemes: 'ManageCmsGameThemes',
    ViewCmsAllGames: 'ViewCmsAllGames',
    ManageCmsAllGames: 'ManageCmsAllGames',
    ViewCmsSkinGames: 'ViewCmsSkinGames',
    ManageCmsSkinGames: 'ManageCmsSkinGames',
    ViewCmsJoinGames: 'ViewCmsJoinGames',
    ManageCmsJoinGames: 'ManageCmsJoinGames',
    ViewCmsGamesReference: 'ViewCmsGamesReference',
    ManageCmsGamesReference: 'ManageCmsGamesReference',
    ViewCmsSkinCategoriesProviders: 'ViewCmsSkinCategoriesProviders',
    ManageCmsSkinCategoriesProviders: 'ManageCmsSkinCategoriesProviders',
    ViewCmsSkinsProvidersReport: 'ViewCmsSkinsProvidersReport',
    ManageCmsSkinsProvidersReport: 'ManageCmsSkinsProvidersReport',
    ViewCmsMedias: 'ViewCmsMedias',
    ManageCmsMedias: 'ManageCmsMedias',
    ViewCmsPopups: 'ViewCmsPopups',
    ManageCmsPopups: 'ManageCmsPopups',
    ViewCmsBanners: 'ViewCmsBanners',
    ManageCmsBanners: 'ManageCmsBanners',
    ViewCmsNews: 'ViewCmsNews',
    ManageCmsNews: 'ManageCmsNews',
    ViewCmsPromotions: 'ViewCmsPromotions',
    ManageCmsPromotions: 'ManageCmsPromotions',
    ViewCmsPages: 'ViewCmsPages',
    ManageCmsPages: 'ManageCmsPages',
    ViewCmsBackgrounds: 'ViewCmsBackgrounds',
    ManageCmsBackgrounds: 'ManageCmsBackgrounds',
    ViewCmsLiveCasinoBuilder: 'ViewCmsLiveCasinoBuilder',
    ManageCmsLiveCasinoBuilder: 'ManageCmsLiveCasinoBuilder',
    ViewCmsSkinPayments: 'ViewCmsSkinPayments',
    ManageCmsSkinPayments: 'ManageCmsSkinPayments',
    ViewCmsAvailableSystemSettings: 'ViewCmsAvailableSystemSettings',
    ViewCmsAvailableSkinSettings: 'ViewCmsAvailableSkinSettings',
    ManageCmsAvailableSkinSettings: 'ManageCmsAvailableSkinSettings',
    ViewCmsCustomCss: 'ViewCmsCustomCss',
    ManageCmsCustomCss: 'ManageCmsCustomCss',
    ViewCmsLogo: 'ViewCmsLogo',
    ManageCmsLogo: 'ManageCmsLogo',
    ViewCmsProducts: 'ViewCmsProducts',
    ManageCmsProducts: 'ManageCmsProducts',
    ViewPromoCodes: 'ViewPromoCodes',
    ViewClientPromoCodes: 'ViewClientPromoCodes',
    ManageWorkflow: 'ManageWorkflow',
    ViewWithdrawDepositInfo: 'ViewWithdrawDepositInfo',
    Viewmaskedemail: 'Viewmaskedemail',
    ViewClientContacts: 'ViewClientContacts',
    ViewBetFightsDashboard: 'ViewBetFightsDashboard',
    ViewClientNote: 'ViewClientNote',
    DeleteClientNote: 'DeleteClientNote',
    ManageClientNote: 'ManageClientNote',
    ViewFriendShip: 'ViewFriendShip',
    test: 'test',
    ManageNonMarketingCampaign: 'ManageNonMarketingCampaign',
    ViewMaskedPhone: 'ViewMaskedPhone',
    ManageNonCustomer: 'ManageNonCustomer',
    ViewCustomReport: 'ViewCustomReport',
    ExecuteCustomReport: 'ExecuteCustomReport',
    CRMAdmin: 'CRMAdmin',
    CRMManager: 'CRMManager',
    ViewEventNotifications: 'ViewEventNotifications',
    ViewAwards: 'ViewAwards',
    ViewWorkflow: 'ViewWorkflow',
    CreateWorkflow: 'CreateWorkflow',
    EditWorkflow: 'EditWorkflow',
    DeleteWorkflow: 'DeleteWorkflow',
    ExecuteWorkflow: 'ExecuteWorkflow',
    ManageFreeBetBalance: 'ManageFreeBetBalance',
};
