/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import classNames from 'classnames';
// Import UI Components
import { Switcher, Icon } from '@geneui/components';

const CustomReportParameterWidget = ({
    onClickHandler,
    parameterName,
    parameterValue,
    isSelected,
    isRequired,
    isChecked,
    switcherChangeHandler,
    isValid,
}) => {
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={classNames('channel-tab-holder', {
                selected: isSelected,
                isValid: !isValid,
                selectedInValid: isSelected && !isValid, //TODO: Schedule CSS
            })}
            onClick={onClickHandler}
        >
            {!isNil(parameterName) && (
                <div className="channel-tab-inner">
                    <div className="channel-tab-head">
                        <div className="channel-tab-icon">
                            <Icon type={'bc-icon-filter-list'} />
                        </div>
                        <p className="campaign-tab-heading">{parameterName}</p>
                    </div>
                    <Switcher disabled={isRequired} checked={isChecked} onChange={switcherChangeHandler} />
                    <p className="campaign-tab-title">{parameterValue}</p>
                </div>
            )}
        </div>
    );
};

CustomReportParameterWidget.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
    parameterName: PropTypes.string,
    parameterValue: PropTypes.string,
    isSelected: PropTypes.bool,
    isRequired: PropTypes.bool,
    isChecked: PropTypes.bool,
    switcherChangeHandler: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
};
CustomReportParameterWidget.defaultProps = {
    parameterName: '',
    parameterValue: '',
    isSelected: false,
    isRequired: false,
    isChecked: false,
    switcherChangeHandler: PropTypes.func,
    isValid: false,
};

export default memo(CustomReportParameterWidget);
