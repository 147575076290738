import { CRMRequestService, postCancelableRequest } from './http.config';

const AwardHttpService = {
    getAwardsList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Awarding/List',
        });
    },

    createAward: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Awarding/Create',
        });
    },

    editAward: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Awarding/Update',
        });
    },
};
export default AwardHttpService;
