import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import UI Components
import { Modal, Icon, Button } from '@geneui/components';
import { ButtonWithLoader } from 'components';
// Import Constants
import { l } from 'constants/common';

const ConfirmationModal = ({
    isVisibleModal,
    titleText,
    onCancel,
    onOk,
    iconType,
    actionLabel,
    cancelLabel,
    className,
    isOkActive,
    isLoading,
    allowDataList,
    notAllowDataList,
    allowDataLabel,
    notAllowDataLabel,
    questionLabel,
    isBulkAction,
}) => {
    const { t } = useTranslation();

    const { selectedCount, selectedAllowed, selectedNotAllowed } = {
        selectedCount: allowDataList?.length + notAllowDataList?.length,
        selectedAllowed: allowDataList,
        selectedNotAllowed: notAllowDataList,
    };

    const getLabel = () => {
        if (isEmpty(allowDataList)) {
            return t(notAllowDataLabel);
        }
        if (isEmpty(notAllowDataList)) {
            return t(allowDataLabel, {
                selectedCount: allowDataList.length,
            });
        }
        return questionLabel;
    };

    return (
        <Modal
            background="dark-background"
            position="top"
            title={titleText}
            size="content-size"
            className={`crm-modal ${className}`}
            appearance="compact"
            closable={false}
            closeOnClickOutside={true}
            visible={isVisibleModal}
            isOkActive={isOkActive}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {t(cancelLabel)}
                    </Button>
                    <ButtonWithLoader color="primary" onClick={onOk} isLoading={isLoading} isDisabled={!isOkActive}>
                        {actionLabel}
                    </ButtonWithLoader>
                </>
            }
        >
            <>
                <div className="crm-circle-icon-cnt">
                    <Icon type={iconType} />
                </div>
                <div className="crm-allowd-not-allowd-cnt">
                    <Trans
                        defaults={isBulkAction ? getLabel() : questionLabel}
                        values={{ selectedCount }}
                        components={{
                            selectedAllowed: (
                                <ul>
                                    {selectedAllowed?.map((item, index) => (
                                        <li key={index}>{item.name}</li>
                                    ))}
                                </ul>
                            ),
                            selectedNotAllowed: (
                                <ul>
                                    {selectedNotAllowed?.map((item, index) => (
                                        <li key={index}>{item.name}</li>
                                    ))}
                                </ul>
                            ),
                        }}
                    />
                </div>
            </>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    isVisibleModal: PropTypes.bool.isRequired,
    titleText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    isOkActive: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    iconType: PropTypes.string,
    actionLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string,
    className: PropTypes.string.isRequired,
    allowDataList: PropTypes.array,
    notAllowDataList: PropTypes.array,
    allowDataLabel: PropTypes.string,
    notAllowDataLabel: PropTypes.string,
    questionLabel: PropTypes.string,
    isBulkAction: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
    isOkActive: true,
    isBulkAction: false,
    cancelLabel: l.Cancel,
};

export default memo(ConfirmationModal);
