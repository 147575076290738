export default class UIComparisonObject {
    constructor(
        comparison = null,
        displayName = null,
        viewType = null,
        elementType = null,
        colorUI = null,
        source = null,
        validation = null,
        minValue = null,
        maxValue = null,
        columnId = null,
        filterId = null,
        hashKey = null,
        applicationColumnId = null,
        schemaId = null,
        schemaName = null,
        isAggregated = null,
        isKPI = null,
    ) {
        this.comparison = comparison;
        this.displayName = displayName;
        this.viewType = viewType;
        this.elementType = elementType;
        this.colorUI = colorUI;
        this.source = source;
        this.validation = validation;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.columnId = columnId;
        this.filterId = filterId;
        this.hashKey = hashKey;
        this.applicationColumnId = applicationColumnId;
        this.schemaId = schemaId;
        this.schemaName = schemaName;
        this.isAggregated = isAggregated;
        this.isKPI = isKPI;
    }
}
