/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useRef, memo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isNil, values, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
// Import Actions
import { DynamicSegmentActions } from 'actions';
// Import UI Components
import { ModuleTitle, Breadcrumb, BusyLoader, Empty, Tag, Icon, ExtendedInput } from '@geneui/components';
// Import Components
import { UIHashComparisonContainer } from './UIHashContainers';
import DynamicSegmentList from './DynamicSegmentList';
import FiltersContainer from './FiltersContainer';
import DynamicSegmentFilterFormWrapper from './DynamicSegmentFilterFormWrapper';
// Import Constants
import { objectTypesMap } from './constants';
// Import Services
import { getByHashKey, getInitialList } from './service';
// Import Constants
import { l } from 'constants/common';
// Import SCSS
import 'assets/scss/dynamicSegment.scss';

const { clearFilterList } = DynamicSegmentActions;

// eslint-disable-next-line react/display-name
const DynamicSegment = forwardRef(
    (
        { queryConfigurations, clientsCount, setClientsCount, columnsReport, pageType, tabActiveId, setTabActiveId },
        ref,
    ) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [activeList, setActiveList] = useState([]);
        const [editingFilter, setEditingFilter] = useState(null);
        const [navigationList, setNavigationList] = useState([{ title: 'Filters', slug: null }]);
        const [filterableList, setFilterableList] = useState([]);
        const [filteredList, setFilteredList] = useState([]);
        const [searchInputValue, setSearchInputValue] = useState('');
        const [searchedValue, setSearchedValue] = useState('');
        const [searchableFilterList, setSearchableFilterList] = useState([]);
        const [readOnlyFilterContainer, setReadOnlyFilterContainer] = useState(false);
        const inputRef = useRef(null);
        let list = [];
        let navigationListContainer = [];

        /** Making search input focused on mount **/
        useEffect(() => inputRef.current.focus(), [filterableList]);

        /** Initializing filters list and setting activeList and filterableList state on mount **/
        useEffect(() => {
            setInitialList();
            if (!isEmpty(queryConfigurations)) {
                const filtered = Object.keys(queryConfigurations)
                    .filter((key) => key.includes('categoriesFilters') && !key.includes('root'))
                    .reduce((obj, key) => {
                        obj[key] = queryConfigurations[key];
                        return obj;
                    }, {});
                let resultSearchedFilterList = [];
                values(filtered).forEach((filterObj) => {
                    resultSearchedFilterList = resultSearchedFilterList.concat(
                        filterObj.nextItems.filter((nextItem) => !nextItem.hashKey.includes('categoriesFilters')),
                    );
                });
                setSearchableFilterList(resultSearchedFilterList);
            }

            // eslint-disable-next-line
        }, [queryConfigurations]);

        useEffect(cleanUp, []);

        useImperativeHandle(ref, () => ({
            reset() {
                resetActiveList();
                setSearchedValue('');
                setEditingFilter(null);
            },
        }));

        function cleanUp() {
            return () => {
                dispatch(clearFilterList());
            };
        }

        function setInitialList() {
            const initialList = initializeFiltersList();
            setActiveList([initialList]);
            setFilterableList([initialList]);
        }

        /** Initializing filters list **/
        function initializeFiltersList() {
            return getInitialList(queryConfigurations) ? getInitialList(queryConfigurations).nextItems : [];
        }

        function resetActiveList() {
            const initialList = initializeFiltersList();
            setActiveList([initialList]);
            setFilterableList([initialList]);
            setNavigationList([{ title: t(l.Filters), slug: null }]);
            navigationListContainer = [];
            list = [];
        }

        /** Bread crumbs click navigation handling **/
        function handleClickNavigation({ slug }) {
            if (slug) {
                handleChangeActiveList(slug);
            } else if (slug && filteredList) {
                setFilterableList([filteredList]);
            } else {
                const initialList = initializeFiltersList();
                setActiveList([initialList]);
                setFilterableList([initialList]);
                setNavigationList([{ title: t(l.Filters), slug: null }]);
            }
        }

        /** Handling click on active filter list item **/
        function handleChangeActiveList(hashKey, displayName, id = null) {
            let item = cloneDeep(getByHashKey(hashKey, queryConfigurations));
            !isNil(id) && resetActiveList();
            searchInputValue && setSearchedValue(searchInputValue);
            setSearchInputValue('');
            navigationListContainer.push({
                title: displayName || item.parentDisplayName,
                slug: hashKey,
            });

            if (item.parentHashKey === `${objectTypesMap.categoriesFilters}root`) {
                list.push(item.nextItems);
                list.push(getInitialList(queryConfigurations).nextItems);

                setActiveList(list.reverse());
                setFilterableList(list);

                if (searchInputValue && list.length === 2) {
                    list[0] = findFilter(list[0], searchInputValue);
                    setFilteredList(list);
                    setFilterableList(list);
                } else if (searchInputValue && list.length === 3) {
                    list[1] = findFilter(list[1], searchInputValue);

                    setFilteredList(list);
                    setFilterableList(list);
                }
                navigationListContainer.push({ title: t(l.Filters), slug: null });
                setNavigationList(navigationListContainer.reverse());
                list = [];
            } else if (!item.nextItems) {
                !isNil(id) ? (item.id = id) : delete item.id;
                list.push(item);
                handleChangeActiveList(item.parentHashKey);
            } else if (item && item.nextItems) {
                list.push(item.nextItems);
                handleChangeActiveList(item.parentHashKey);
            }
        }

        /** Setting the search input value and calling the active list filter function **/
        function setInputValue(event) {
            const {
                target: { value },
            } = event;
            searchValueSetter(value);
        }

        /** Filtering the active list and changing it's state (filterableList) **/
        function handleChangeSearch(value) {
            const foundFilters = findFilter(activeList[activeList.length - 1], value);
            const resultFilteredList = [...filterableList];
            resultFilteredList[activeList.length - 1] = foundFilters;
            setFilterableList(resultFilteredList);
        }

        /** Filtering the active list array (filterableList) **/
        function findFilter(list, value) {
            return list && list[0] && value
                ? list.filter(({ displayName }) => {
                      return displayName.toLowerCase().includes(value.toLowerCase());
                  })
                : undefined;
        }

        function getColumnType(index) {
            if (activeList[index + 1] instanceof UIHashComparisonContainer) return false;
            return activeList.length - index <= 2;
        }

        function tagDeleteHandler(e) {
            e.stopPropagation();
            setSearchedValue('');
        }

        function searchValueSetter(value) {
            const tmpValue = value.trimStart();
            if (tmpValue || searchInputValue) {
                setSearchInputValue(tmpValue);
                handleChangeSearch(tmpValue);
                if (tmpValue) {
                    const searchedFilteredData = findFilter(searchableFilterList, tmpValue.trimEnd());
                    const result = [isEmpty(searchedFilteredData) ? [null] : searchedFilteredData];

                    setFilterableList(result);
                    setActiveList(result);
                } else {
                    setInitialList();
                }
            }
        }

        function tagClickHandler() {
            searchValueSetter(searchedValue);
        }

        function editModeCancelHandler() {
            resetActiveList();
            setEditingFilter(null);
        }

        return (
            <div className="create-dynamic-segment-p-w-content">
                <div className="create-dynamic-segment-p-w-c-i-column">
                    <div className="create-dynamic-segment-p-w-c-i-column-head">
                        <ModuleTitle
                            title={
                                <Breadcrumb data={navigationList} collapsed={false} onClick={handleClickNavigation} />
                            }
                            description=" "
                        />
                        <div className="crm-dynamic-segment-header crm-full-width p-20 c-crm-s-header">
                            <ExtendedInput
                                type="text"
                                canClear={true}
                                icon={'bc-icon-search'}
                                className="search-holder crm-content-width dynamic-segment-search-input"
                                placeholder={`Search in`}
                                onChange={setInputValue}
                                value={searchInputValue}
                                ref={inputRef}
                            />
                            {searchedValue && (
                                <Tag
                                    appearance={'light'}
                                    name={searchedValue}
                                    size={'big'}
                                    onClick={tagClickHandler}
                                    icons={<Icon type="bc-icon-clear-small" onClick={tagDeleteHandler} />}
                                />
                            )}
                        </div>
                    </div>
                    <div className="create-dynamic-segment-p-w-c-i-column-content">
                        {activeList[0] && activeList[0].length > 0 ? (
                            <>
                                <div
                                    className={classNames('create-dynamic-segment-p-w-c-i-c-content-column', {
                                        'crm-editable-state': readOnlyFilterContainer,
                                    })}
                                >
                                    {filterableList.map((elem, index) => (
                                        <DynamicSegmentList
                                            setReadOnlyFilterContainer={setReadOnlyFilterContainer}
                                            key={index}
                                            mainLength={activeList.length}
                                            handleChangeActiveColumnsList={handleChangeActiveList}
                                            resetActiveList={resetActiveList}
                                            list={elem}
                                            full={getColumnType(index)}
                                            selected={
                                                navigationList[index + 1] ? navigationList[index + 1].title : null
                                            }
                                        />
                                    ))}
                                </div>

                                <div className="create-dynamic-segment-p-w-c-i-c-content-column">
                                    {activeList.length < 2 && (
                                        <Empty
                                            appearance="greyscale"
                                            withImage
                                            title={t(l.SelectAFilter)}
                                            subTitle={t(l.SelectAFilterAndStartBuildingASegment)}
                                        />
                                    )}
                                    {filterableList[filterableList.length - 1] instanceof UIHashComparisonContainer && (
                                        <DynamicSegmentFilterFormWrapper
                                            data={filterableList[filterableList.length - 1]}
                                            setReadOnlyFilterContainer={setReadOnlyFilterContainer}
                                            editModeCancelHandler={editModeCancelHandler}
                                            listLength={activeList.length}
                                            tabActiveId={tabActiveId}
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            <BusyLoader
                                isBusy
                                type="spinner"
                                spinnerSize={'big'}
                                className="crm-full-width crm-justify-content-center"
                            />
                        )}
                    </div>
                </div>
                <div className="create-dynamic-segment-p-w-c-i-column">
                    <FiltersContainer
                        readOnly={readOnlyFilterContainer}
                        setReadOnly={setReadOnlyFilterContainer}
                        editingFilter={editingFilter}
                        setEditingFilter={setEditingFilter}
                        resetActiveList={resetActiveList}
                        clientsCount={clientsCount}
                        setClientsCount={setClientsCount}
                        handleChangeActiveList={handleChangeActiveList}
                        columnsReport={columnsReport}
                        pageType={pageType}
                        tabActiveId={tabActiveId}
                        setTabActiveId={setTabActiveId}
                        editModeCancelHandler={editModeCancelHandler}
                    />
                </div>
            </div>
        );
    },
);

DynamicSegment.propTypes = {
    queryConfigurations: PropTypes.object,
    clientsCount: PropTypes.object.isRequired,
    setClientsCount: PropTypes.func.isRequired,
    columnsReport: PropTypes.array,
    pageType: PropTypes.string,
    tabActiveId: PropTypes.string.isRequired,
    setTabActiveId: PropTypes.func.isRequired,
};

export default memo(DynamicSegment);
