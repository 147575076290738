import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Collapse, Panel } from '@geneui/components';
// Import Components
import { KeyValueItems } from 'components';
// Import Constants
import { l } from 'constants/common';

const CollapseItemsGroup = ({ data, isAllActive }) => {
    const { t } = useTranslation();

    return (
        <div className="collapse-items-group">
            <Collapse allActive={isAllActive}>
                {data.map(({ name, items, isTranslated }, index) => {
                    return (
                        <Panel title={isTranslated ? name : t(l[name])} key={index}>
                            <KeyValueItems items={items} vertical={true} />
                        </Panel>
                    );
                })}
            </Collapse>
        </div>
    );
};

CollapseItemsGroup.propTypes = {
    data: PropTypes.array.isRequired,
    isAllActive: PropTypes.bool,
};

CollapseItemsGroup.defaultProps = {
    isAllActive: false,
};

export default CollapseItemsGroup;
