import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isArray, isNil, isObject } from 'lodash';
// Import UI Components
import { Copy } from '@geneui/components';
// Import Constants
import { Position } from 'constants/common';

const CopyToClipboard = ({
    children,
    value,
    size,
    position,
    classNameCopy,
    copyTooltipText,
    copiedTooltipText,
    isVisible,
    fixedPosition,
    withWrapper,
    showOnHover,
}) => {
    const childRef = useRef(null);

    const isWrapped = !isObject(children) || withWrapper || isArray(children);

    const components = [
        isWrapped ? (
            <span key={'content'} ref={childRef}>
                {children}
            </span>
        ) : (
            <React.Fragment key={'content'}>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                        ref: (ref) => (childRef.current = ref),
                    }),
                )}
            </React.Fragment>
        ),
        <Copy
            key={'copy'}
            value={value}
            contentRef={isNil(value) ? childRef : null}
            showOnHover={showOnHover}
            size={size}
            className={classNames(classNameCopy, {
                'copy-icon': isNil(size),
            })}
            copyTooltipText={copyTooltipText}
            copiedTooltipText={copiedTooltipText}
            isVisible={isVisible}
        />,
    ];

    if (position === Position.Left) {
        components.reverse();
    }

    return (
        <span
            className={classNames('crm-copy-to-clipboard-fixed-position-wrapper', {
                'space-preserved-left': !fixedPosition && position === Position.Left,
                'space-preserved-right': !fixedPosition && position === Position.Right,
            })}
        >
            {components}
        </span>
    );
};

CopyToClipboard.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'big']),
    position: PropTypes.oneOf([Position.Left, Position.Right]),
    value: PropTypes.string,
    classNameCopy: PropTypes.string,
    copyTooltipText: PropTypes.string,
    copiedTooltipText: PropTypes.string,
    showOnHover: PropTypes.bool,
    isVisible: PropTypes.bool,
    fixedPosition: PropTypes.bool,
    withWrapper: PropTypes.bool,
};

CopyToClipboard.defaultProps = {
    position: Position.Right,
    fixedPosition: false,
    showOnHover: true,
    withWrapper: false,
};

export default memo(CopyToClipboard);
