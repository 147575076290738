import { CRMRequestService, getCancelableRequest, postCancelableRequest } from './http.config';

const DashboardHttpService = {
    getDashboardWidgets: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Dashboard/GetDashboardWidgetsConfig',
        });
    },
    setDashboardWidgets: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Dashboard/SetSelectedWidgets',
        });
    },
    getDashboardActiveUsers: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDashboardActiveUsers?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getRetentionRates: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetRetentionRates?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getConversionRates: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetConversionRates?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDollarRetentionRates: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDollarRetentionRates?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDAURates: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDAUMAURate?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDashboardActiveClientsAverageRevenue: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDashboardActiveClientsAverageRevenue?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDashboardReactivationRate: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDashboardReactivationRate?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDashboardCustomerLifetimeValue: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDashboardCustomerLifetimeValue?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDashboardInActionPromotions: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetInActionPromotions?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getCampaignsGeneralInfo: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetCampaingsGeneralInfo?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDashboardOnBoardActivity: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDashboardOnBoardActivity?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getDashboardClientActivity: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDashboardClientActivity?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    GetDashboardCommunicationMetrics: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetDashboardCommunicationMetrics?startDate=${startDate}&endDate=${endDate}`,
        });
    },
    getRFMChart: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ productId, date }) => `/Dashboard/GetRFMChart?productId=${productId}&date=${date}`,
        });
    },
    getDepositCumulativeChartData: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Dashboard/GetDepositCumulativeChartData',
        });
    },
    getPromotionsTimeline: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ startDate, endDate }) =>
                `/Dashboard/GetPromotionsTimeline?startDate=${startDate}&endDate=${endDate}`,
        });
    },
};

export default DashboardHttpService;
