/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { SkeletonLoader } from '@geneui/components';
// Import Constants
import {
    l,
    StateModesLabels,
    CustomerJourneyStatusLabels,
    DateTimeWithoutSecondsFormat,
    TimeZonePack,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';
const { customMomentWithoutTimezoneConversion } = Helpers;

const CustomerJourneyGeneralDetails = ({
    schedule,
    mode,
    status,
    user,
    createdDate,
    lastModifier,
    lastModifiedDate,
}) => {
    const { t } = useTranslation();

    const getContent = (data) => {
        return isNil(data) ? t(l.NA) : customMomentWithoutTimezoneConversion(data).format(DateTimeWithoutSecondsFormat);
    };

    return (
        <>
            <div className="details-section">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name">{t(l.GeneralDetails)}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="general-details-content">
                    <div className="general-details-column">
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.AutoStart)}</p>
                            <SkeletonLoader isBusy={isNil(status)}>
                                {!isNil(status) && (
                                    <p className="g-details-subtitle">{getContent(schedule?.StartDate)}</p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.AutoEnd)}</p>
                            <SkeletonLoader isBusy={isNil(status)}>
                                {!isNil(status) && (
                                    <p className="g-details-subtitle">{getContent(schedule?.EndDate)}</p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.TimeZone)}</p>
                            <SkeletonLoader isBusy={isNil(status)}>
                                {!isNil(status) && (
                                    <p className="g-details-subtitle">
                                        {isNil(schedule?.TimeZone) ? t(l.NA) : TimeZonePack[schedule?.TimeZone]}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.Mode)}</p>
                            <SkeletonLoader isBusy={isNil(mode)}>
                                {!isNil(mode) && <p className="g-details-subtitle">{StateModesLabels[mode]}</p>}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.Status)}</p>
                            <SkeletonLoader isBusy={isNil(status)}>
                                {!isNil(status) && (
                                    <p className="g-details-subtitle">{CustomerJourneyStatusLabels[status]}</p>
                                )}
                            </SkeletonLoader>
                        </div>
                    </div>
                    <div className="general-details-column">
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.User)}</p>
                            <SkeletonLoader isBusy={isNil(user)}>
                                {!isNil(user) && <p className="g-details-subtitle">{user}</p>}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.CreatedDate)}</p>
                            <SkeletonLoader isBusy={isNil(createdDate)}>
                                {!isNil(createdDate) && <p className="g-details-subtitle">{getContent(createdDate)}</p>}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.LastModified)}</p>
                            <SkeletonLoader isBusy={isNil(lastModifiedDate)}>
                                {!isNil(lastModifiedDate) && (
                                    <p className="g-details-subtitle">{getContent(lastModifiedDate)}</p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.LastModifier)}</p>
                            <SkeletonLoader isBusy={isNil(lastModifier)}>
                                {!isNil(lastModifier) && (
                                    <p className="g-details-subtitle">{isNil(lastModifier) ? t(l.NA) : lastModifier}</p>
                                )}
                            </SkeletonLoader>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

CustomerJourneyGeneralDetails.propTypes = {
    schedule: PropTypes.object.isRequired,
    mode: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    lastModifier: PropTypes.string.isRequired,
    lastModifiedDate: PropTypes.string.isRequired,
};

export default memo(CustomerJourneyGeneralDetails);
