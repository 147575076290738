import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Components
import { DataTable } from 'components';

const PlayersDataTable = ({ isLoading, data, config }) => {
    const { t } = useTranslation();
    const columns = config(t).columns.map((item, index) => ({
        ...item,
        index: index,
    }));

    const mapRows = () => {
        const tmpRows = data?.Data?.map((elem, index) => ({
            index,
            actionBar: false,
            dragDisable: true,
            hasHover: true,
            data: columns.reduce(
                (accumulator, element) => {
                    accumulator[element.dataKey] = elem[element.dataKey];
                    return accumulator;
                },
                { index },
            ),
        }));
        return isNil(tmpRows) ? [] : tmpRows;
    };

    return (
        <DataTable
            columnKey="dataKey"
            rowKey="index"
            data={mapRows()}
            dataCount={mapRows().length}
            columns={columns}
            isLoading={isLoading}
            isVisibleOnlyDataTable={true}
            withPagination={false}
            withPageSelector={false}
        />
    );
};

PlayersDataTable.propTypes = {
    config: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    data: PropTypes.object,
};

PlayersDataTable.defaultProps = {
    isLoading: true,
    data: [],
};

export default memo(PlayersDataTable);
