import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
//Import constants
import { SegmentTypesLabels, DefinitionTypes, SegmentObjectType, l, PERMISSIONS, ActionsIcons } from 'constants/common';
// Import UI components
import { AccessControl } from 'components';
// Import UI components
import { Button, Tooltip } from '@geneui/components';

const SegmentActionsButtons = ({
    data,
    convertToReportAction,
    unArchiveSegmentAction,
    archiveSegmentAction,
    deleteSegmentAction,
    cloneSegmentAction,
    editSegmentAction,
    infoSegmentAction,
    isInfoVisible,
    isViewMode,
}) => {
    const { IsUsed, Type, DefinitionType, TargetObject, ArchivedDate } = data;

    const { t } = useTranslation();

    const isPredefined = DefinitionType === DefinitionTypes.predefined;
    const isArchive = !isNil(ArchivedDate);
    const isStatic = Type === SegmentTypesLabels.Static;

    const isVisibleDelete = () => !isViewMode && !IsUsed && !isPredefined;
    const isVisibleClone = () => !isViewMode && !isStatic;
    const isVisibleEdit = () => !isViewMode && !isArchive && !isPredefined && !isStatic;
    const isVisibleArchive = () => !isViewMode && !isArchive && !isPredefined;
    const isVisibleUnArchive = () => !isViewMode && isArchive;
    const isVisibleConvertToReport = () => !isViewMode && TargetObject !== SegmentObjectType.NonCustomer;

    return (
        <>
            {isVisibleDelete() && (
                <AccessControl permissions={PERMISSIONS.DeleteSegment}>
                    <Tooltip text={t(l.DeleteSegment)}>
                        <Button
                            icon={ActionsIcons.Delete}
                            appearance="minimal"
                            color="primary"
                            onClick={() => deleteSegmentAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleEdit() && (
                <AccessControl permissions={PERMISSIONS.EditSegment}>
                    <Tooltip text={t(l.EditSegment)}>
                        <Button
                            icon={ActionsIcons.Edit}
                            appearance="minimal"
                            color="primary"
                            onClick={() => editSegmentAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleArchive() && (
                <Tooltip text={t(l.ArchiveSegment)}>
                    <Button
                        icon={ActionsIcons.Archive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => archiveSegmentAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleUnArchive() && (
                <Tooltip text={t(l.UnarchiveSegment)}>
                    <Button
                        icon={ActionsIcons.UnArchive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => unArchiveSegmentAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleConvertToReport() && (
                <AccessControl permissions={PERMISSIONS.CreateAddHocReport}>
                    <Tooltip text={t(l.CreateReport)}>
                        <Button
                            icon={ActionsIcons.ConvertToReport}
                            appearance="minimal"
                            color="primary"
                            onClick={() => convertToReportAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleClone() && (
                <AccessControl permissions={PERMISSIONS.CreateSegment}>
                    <Tooltip text={t(l.CloneSegment)}>
                        <Button
                            icon={ActionsIcons.Clone}
                            appearance="minimal"
                            color="primary"
                            onClick={() => cloneSegmentAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isInfoVisible && (
                <Tooltip text={t(l.SegmentInfo)}>
                    <Button
                        icon={ActionsIcons.Stop}
                        appearance="minimal"
                        color="primary"
                        onClick={() => infoSegmentAction(data)}
                    />
                </Tooltip>
            )}
        </>
    );
};

SegmentActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    convertToReportAction: PropTypes.func,
    unArchiveSegmentAction: PropTypes.func,
    archiveSegmentAction: PropTypes.func,
    deleteSegmentAction: PropTypes.func,
    cloneSegmentAction: PropTypes.func,
    editSegmentAction: PropTypes.func,
    infoSegmentAction: PropTypes.func,
    isInfoVisible: PropTypes.bool,
    isViewMode: PropTypes.bool,
};

SegmentActionsButtons.defaultProps = {
    convertToReportAction: noop,
    unArchiveSegmentAction: noop,
    archiveSegmentAction: noop,
    deleteSegmentAction: noop,
    cloneSegmentAction: noop,
    editSegmentAction: noop,
    infoSegmentAction: noop,
    isInfoVisible: false,
    isViewMode: false,
};

export default memo(SegmentActionsButtons);
