import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Components
import DynamicVariables from './DynamicVariables';
import TemplateTextInput from './TemplateTextInput';
// Import Constants
import { l, TemplateInputTypes } from 'constants/common';
// Import Hooks
import { useBind, useFieldValidity, useInputInsertion } from 'hooks';
// Import Services
import { TemplateSymbolsCountFields, TemplateType } from 'services/template';
import { Helpers } from 'services';

const { calculateTemplateBodyLengthWithRegEx } = Helpers;

const PushTemplateContent = ({
    templateData,
    handleChange,
    templateLangKey,
    dynamicVariables,
    shortenerAndTrackerSettings,
}) => {
    const { t } = useTranslation();

    const subjectValidationState = useFieldValidity(
        TemplateInputTypes.TitleOfPushNotification,
        l.Subject,
        templateLangKey,
    );
    const bodyValidationState = useFieldValidity(TemplateInputTypes.Body, l.Body, templateLangKey);

    const { insertionHandler, updateInsertionHandler, insertionOptions, setInsertionOptions } = useInputInsertion();

    const handleSubjectChange = useBind(handleChange, TemplateInputTypes.TitleOfPushNotification);
    const updateSubjectInsertionHandler = useBind(updateInsertionHandler, TemplateInputTypes.TitleOfPushNotification);
    const updateBodyInsertionHandler = useBind(updateInsertionHandler, TemplateInputTypes.Body);

    const inputRef = useRef(null);

    const templateBody = templateData?.[TemplateInputTypes.Body];
    const subjectValue = templateData?.[TemplateInputTypes.TitleOfPushNotification];

    const [pageSymbolsCount, setPageSymbolsCount] = useState(Helpers.getByteLen(templateBody));
    const [bodyValue, setBodyValue] = useState(templateBody);

    const updateDynamicVariableBodyOptions = useCallback(() => {
        setInsertionOptions({
            channel: TemplateType.PushNotification,
            inputFilterKey: '',
            hintText: '',
            focusedInputKey: TemplateInputTypes.Body,
        });
    }, [setInsertionOptions]);

    const updateDynamicVariableSubjectOptions = useCallback(() => {
        setInsertionOptions({
            channel: TemplateType.PushNotification,
            inputFilterKey: 'IsSubjectItem',
            hintText: '',
            focusedInputKey: TemplateInputTypes.TitleOfPushNotification,
        });
    }, [setInsertionOptions]);

    useEffect(() => {
        const result = calculateTemplateBodyLengthWithRegEx(shortenerAndTrackerSettings, bodyValue);
        setPageSymbolsCount(Helpers.getByteLen(result));
    }, [bodyValue, shortenerAndTrackerSettings]);

    const handleBodyChange = (e) => {
        const value = e.target.value;

        setBodyValue(value);
        handleChange(TemplateInputTypes.Body, value);
    };

    const insertTextAtCursorBody = (textToInsert) => {
        const { selectionStart } = inputRef.current;
        const firstPart = bodyValue.slice(0, selectionStart);
        const secondPart = bodyValue.slice(selectionStart, bodyValue.length);
        const resultValue = `${firstPart} {${textToInsert}} ${secondPart}`;
        inputRef.current.setSelectionRange(resultValue.length, resultValue.length);
        inputRef.current.focus();

        setBodyValue(resultValue);
        handleChange(TemplateInputTypes.Body, resultValue);
    };

    updateBodyInsertionHandler(insertTextAtCursorBody);

    return (
        <>
            <div>
                <DynamicVariables
                    dynamicVariables={dynamicVariables}
                    clickHandler={insertionHandler}
                    insertionOptions={insertionOptions}
                />
                <div className="crm-create-template-page-c-c-h-inputs-row">
                    <TemplateTextInput
                        displayNameKey={l.Subject}
                        value={subjectValue}
                        onChange={handleSubjectChange}
                        onBlur={handleSubjectChange}
                        validationState={subjectValidationState}
                        updateInsertionHandler={updateSubjectInsertionHandler}
                        onFocus={updateDynamicVariableSubjectOptions}
                    />
                </div>
            </div>
            <div className="crm-create-template-page-c-c-content">
                <div className="crm-messaging-row">
                    <div className="crm-messaging-row-head">
                        <p>{t(l.SymbolsCountIn, { pageName: t(l.PushNotification) })}</p>
                        <span>{`(${pageSymbolsCount} ${t(l.Bytes)} / ${
                            TemplateSymbolsCountFields[TemplateType.PushNotification]
                        } ${t(l.Bytes)})`}</span>
                    </div>
                    <div className={`crm-messaging-row-content ${bodyValidationState.isValid ? '' : 'error-color'}`}>
                        <ExtendedInput
                            ref={inputRef}
                            type="textarea"
                            value={bodyValue}
                            isValid={bodyValidationState.isValid}
                            placeholder={t(l.TypeYourText)}
                            onChange={handleBodyChange}
                            onFocus={updateDynamicVariableBodyOptions}
                        />
                    </div>
                    <p className="error-text">{bodyValidationState.errorText}</p>
                </div>
            </div>
        </>
    );
};

PushTemplateContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
    shortenerAndTrackerSettings: PropTypes.array,
};

PushTemplateContent.defaultProps = {
    shortenerAndTrackerSettings: [],
};

export default PushTemplateContent;
