import React, { memo, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty, isNil, noop } from 'lodash';
// Import Components
import { CustomFields } from 'components';
//  Import Services
import { UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { l, ObjectTypeIds } from 'constants/common';
import { BusyLoader } from '@geneui/components';

const CJPreSaveModalCustomFields = ({
    customFieldsValues,
    setCustomFieldsValues,
    addValidationProperties,
    onCustomFieldsLoading,
}) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();
    const [customFields, setCustomFields] = useState(null);
    const [isCustomFieldsLoading, setIsCustomFieldsLoading] = useState(true);
    const [isCustomFieldOptionsLoading, setIsCustomFieldOptionsLoading] = useState(false);
    const [dropDownSettings, setDropDownSettings] = useState();

    const pageValidation = useSelector((state) => state.pageValidation);

    useEffect(() => {
        onCustomFieldsLoading(isCustomFieldsLoading || isCustomFieldOptionsLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomFieldsLoading, isCustomFieldOptionsLoading]);

    useEffect(() => {
        if (isNil(customFields) || isEmpty(customFields)) {
            return;
        }
    }, [customFields, pageValidation]);

    const { getPartnerCustomFieldsRequest } = useMemo(
        () => ({
            getPartnerCustomFieldsRequest: UtilsHttpService.getPartnerCustomFields(),
        }),
        [],
    );

    const getPartnerCustomFields = () => {
        doPostRequest(getPartnerCustomFieldsRequest.request, {
            queryString: { objectType: ObjectTypeIds.RealtimePromotion },
            successCallback: (Data) => {
                setCustomFields(Data);
                setIsCustomFieldsLoading(false);
            },
        });
    };

    const init = () => {
        getPartnerCustomFields();
    };

    useEffect(init, []);

    if (isNil(customFields) || isEmpty(customFields)) {
        if (isCustomFieldsLoading) {
            return <BusyLoader isBusy={isCustomFieldsLoading} type="spinner" spinnerSize="medium" />;
        }
        return <></>;
    }

    return (
        <>
            <div className="title-wrapper">
                <p className="title-wrapper-text">{t(l.CustomFields)}</p>
                <span className="title-wrapper-line" />
            </div>
            <div className="crm-cj-pre-save-modal-custom-fields">
                <CustomFields
                    customFieldsValues={customFieldsValues}
                    setCustomFieldsValues={setCustomFieldsValues}
                    customFields={customFields}
                    isLoaded={true}
                    addValidationProperties={addValidationProperties}
                    setDropDownSettings={setDropDownSettings}
                    onCustomFieldOptionsLoading={setIsCustomFieldOptionsLoading}
                    dropDownSettings={dropDownSettings}
                />
            </div>
        </>
    );
};

CJPreSaveModalCustomFields.propTypes = {
    customFieldsValues: PropTypes.array,
    setCustomFieldsValues: PropTypes.func,
    addValidationProperties: PropTypes.func,
    onCustomFieldsLoading: PropTypes.func,
};

CJPreSaveModalCustomFields.defaultProps = {
    customFieldsValues: [],
    setCustomFieldsValues: noop,
    addValidationProperties: noop,
    onCustomFieldsLoading: noop,
};

export default memo(CJPreSaveModalCustomFields);
