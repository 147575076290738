/* eslint-disable react/display-name */
import React from 'react';
import { isNil } from 'lodash';
// Import UI Components
import { Icon } from '@geneui/components';
// Import Components
import { CopyToClipboard } from 'components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import {
    DynamicBonusTypes,
    TemplateTypesLabels,
    FilterComparisions,
    BonusTypesLabels,
    BonusTypesIcons,
    DateTimeFormat,
    DataTableColumnsCustomTypes,
    l,
    ExpirationTypes,
    Position,
} from 'constants/common';

const { Equals, Like } = FilterComparisions;
const { generateRequestFilterField, customMomentWithoutTimezoneConversion } = Helpers;

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.ID),
                dataKey: 'Id',
                sortable: true,
                colRenderer: (id) => <CopyToClipboard>{id}</CopyToClipboard>,
            },
            {
                text: t(l.Title),
                dataKey: 'Name',
                sortable: true,
                colRenderer: (title) => <CopyToClipboard position={Position.Left}>{title}</CopyToClipboard>,
            },
            {
                text: t(l.Type),
                dataKey: 'Type',
                sortable: true,
                colRenderer: (type) => (
                    <div className="crm-segment-type-label">
                        {!isNil(BonusTypesIcons[type]) && <Icon type={BonusTypesIcons[type]} />}
                        <span className="crm-segment-type-text">{BonusTypesLabels[type]}</span>
                    </div>
                ),
            },

            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.StartDate),
                dataKey: 'StartDate',
                sortable: true,
                formatter: (StartDate) => {
                    return customMomentWithoutTimezoneConversion(StartDate).format(DateTimeFormat);
                },
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.EndDate),
                dataKey: 'EndDate',
                sortable: true,
                formatter: (EndDate) => {
                    return isNil(EndDate) ? '' : customMomentWithoutTimezoneConversion(EndDate).format(DateTimeFormat);
                },
            },
        ],
    };
};

const DynamicBonusTypesRadioGroupOptionsConfig = [
    { label: l.Object, tooltip: l.TooltipBonusCalculationObject, value: DynamicBonusTypes.Object },
    { label: l.KPI, tooltip: l.TooltipBonusCalculationKPI, value: DynamicBonusTypes.KPI },
    { label: l.FromFile, tooltip: l.TooltipBonusCalculationFromFile, value: DynamicBonusTypes.FromFile },
];

const InternalTemplateListConfig = {
    paging: {
        PageSize: 1000,
        PageNumber: 1,
    },
    sorting: {
        Name: 'CreatedDate',
        Direction: 'desc',
    },
    filters: [
        generateRequestFilterField('DeliveryMethod', Equals, [TemplateTypesLabels.Internal]),
        generateRequestFilterField('State', Equals, [TemplateTypesLabels.Internal]),
    ],
    nameFilterConfig: {
        name: 'Name',
        comparision: Like,
    },
};

const ExpirationTypesDropdownData = (t) => {
    return [
        { label: t(l.FromPayoutDate), value: ExpirationTypes.ExpirationPeriod },
        { label: t(l.ToGivenDate), value: ExpirationTypes.ExpirationDate },
    ];
};

export {
    DataTableColumnsConfigs,
    DynamicBonusTypesRadioGroupOptionsConfig,
    InternalTemplateListConfig,
    ExpirationTypesDropdownData,
};
