import { CRMRequestService, postCancelableRequest, getCancelableRequest } from './http.config';

const AdministrationHttpService = {
    // CJBlockManagement requests
    getCustomerJourneyBlocks: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Admin/Workflow/BlockList',
        });
    },
    addPartnerToCJBlock: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Admin/Workflow/AddPartnerBlock',
        });
    },
    removePartnerFromCJBlock: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Admin/Workflow/RemovePartnerBlock',
        });
    },
    // Settings requests
    getSettingList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/Setting/List',
        });
    },
    getSettingConfigurations: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Admin/Setting/Configurations',
        });
    },
    createSetting: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/Setting/Create',
        });
    },
    updateSetting: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/Setting/Update',
        });
    },
    deleteSetting: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/Setting/Delete',
        });
    },
    getEmailProviderList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/EmailProvider/List',
        });
    },
    setEmailProvider: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/EmailProvider/Create',
        });
    },
    updateEmailProvider: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/EmailProvider/Update',
        });
    },
    getEmailProvider: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ providerId }) => `/Admin/EmailProvider/Get?emailProviderId=${providerId}`,
        });
    },
    getPartnerList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/Partner/List',
        });
    },
    getPartnerGroups: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/Partner/GetPartnerGroups',
        });
    },
    changePartnerActivation: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Admin/Partner/UpdatePartnerActivation',
        });
    },
    // DataWarehouse requests
    getDBSchema: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/Schema/List?partnerId=${partnerId}`,
        });
    },
    updateDBRowSchema: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/Schema/Update?partnerId=${partnerId}`,
        });
    },
    getClassifiersList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/Classifier/List?partnerId=${partnerId}`,
        });
    },
    createClassifier: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/Classifier/Create?partnerId=${partnerId}`,
        });
    },
    deleteClassifier: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId, classifierId }) =>
                `/Admin/Classifier/Delete?partnerId=${partnerId}&classifierId=${classifierId}`,
        });
    },
    updateClassifier: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/Classifier/Update?partnerId=${partnerId}`,
        });
    },
    getClassifier: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId, classifierId }) =>
                `/Admin/Classifier/GetById?partnerId=${partnerId}&classifierId=${classifierId}`,
        });
    },
    getMenuItemsList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/MenuItem/List?partnerId=${partnerId}`,
        });
    },
    getMenuItem: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId, menuItemId }) =>
                `/Admin/MenuItem/GetById?partnerId=${partnerId}&menuItemId=${menuItemId}`,
        });
    },
    createMenuItem: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/MenuItem/Create?partnerId=${partnerId}`,
        });
    },
    deleteMenuItem: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId, menuItemId }) =>
                `/Admin/MenuItem/Delete?partnerId=${partnerId}&menuItemId=${menuItemId}`,
        });
    },
    updateMenuItem: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ partnerId }) => `/Admin/MenuItem/Update?partnerId=${partnerId}`,
        });
    },
};

export default AdministrationHttpService;
