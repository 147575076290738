import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, values } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// Import Components
import { PartnersOptionList } from 'components';
// Import UI Components
import { Button, Popover, Tooltip } from '@geneui/components';
// Import Actions
import { HeaderAction } from 'actions';
// Impoart Services
import { AuthHttpService } from 'services/http';
import { publish } from 'services/event';
// Import Hooks
import { useGoToRoute, useOnClickOutside } from 'hooks';
// Import Constants
import { l, GlobalEvents } from 'constants/common';

const Partners = ({ setIsLoading, setPartnersVisibilityStatus, isVisible, toggleHandler }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setSettingsFetch } = HeaderAction;

    const { goToRoute } = useGoToRoute();
    const { route: path } = useSelector((state) => state.header.navigation);

    const { partners, settings } = useSelector((state) => state.header);
    const { PartnerDataSourceId: partnerDataSourceId } = useSelector((state) => state.partnerSettings);

    const { ActivePartner } = settings;
    const PartnerName = ActivePartner.Name;
    const PartnerId = ActivePartner.PartnerId;
    const optionListRef = useRef(null);

    const [partnerGroupsList, setPartnerGroupsList] = useState([]);

    useEffect(() => {
        if (!isEmpty(partners) && partnerDataSourceId !== undefined) {
            const mapToPartnerGroupsModel = (partnersList, partnerDataSourceId) => {
                const result = partnersList.reduce((acc, val) => {
                    const groupData = val.PartnerGroup;
                    if (!acc[groupData.PartnerGroupId]) {
                        acc[groupData.PartnerGroupId] = {
                            partnerGroupId: groupData.PartnerGroupId,
                            partnerGroupName: groupData.Name,
                            partnerGroupDescription: groupData.Description,
                            partners: [],
                        };
                    }

                    const partnerExternalId = val.PartnerExternalConnections.find(
                        (externalConnection) =>
                            externalConnection.PartnerDataSource.PartnerDataSourceId === partnerDataSourceId,
                    )?.ExternalId;

                    acc[groupData.PartnerGroupId].partners.push({
                        ...val,
                        displayId: partnerExternalId ?? val.PartnerId,
                    });
                    return acc;
                }, {});

                return result;
            };
            setPartnerGroupsList(values(mapToPartnerGroupsModel(partners, partnerDataSourceId)));
        }
    }, [partners, partnerDataSourceId]);

    const selectPartner = ({ value: partnerId }) => {
        if (PartnerId === partnerId) return;

        setPartnersVisibilityStatus(false);
        setIsLoading(true);
        dispatch(setSettingsFetch());

        AuthHttpService.changePartner(partnerId).then(() => {
            publish(GlobalEvents.PartnerChanged, partnerId);
            goToRoute({ path });
        });
    };

    useOnClickOutside(
        optionListRef,
        useCallback(() => {
            if (isVisible) {
                setPartnersVisibilityStatus(false);
            }
        }, [isVisible, setPartnersVisibilityStatus]),
    );

    return (
        <div ref={optionListRef}>
            <Popover
                isOpen={isVisible}
                extendTargetWidth={false}
                minHeight={332}
                maxHeight={352}
                scrollbarNeeded={false}
                align="end"
            >
                <div className="crm-option-list-content-wrapper partner-popover-content navigation-popover-content">
                    <div className="partners-button-wrap">
                        <Tooltip className="crm-c-tooltip" text={PartnerName} position="bottom">
                            <Button
                                className="crm-option-list-content__toggle-button option-list-button"
                                onClick={toggleHandler}
                                icon={isVisible ? 'bc-icon-arrow-up' : 'bc-icon-arrow-down'}
                            >
                                {isEmpty(PartnerName) ? t(l.SelectPartner) : PartnerName}
                            </Button>
                        </Tooltip>
                    </div>
                    {isVisible && (
                        <PartnersOptionList
                            partnerGroupsListData={partnerGroupsList}
                            titles={[t(l.Partner), t(l.ID)]}
                            partnerSelectHandler={selectPartner}
                        />
                    )}
                </div>
            </Popover>
        </div>
    );
};

Partners.propTypes = {
    setIsLoading: PropTypes.func.isRequired,
    setPartnersVisibilityStatus: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    toggleHandler: PropTypes.func.isRequired,
};

Partners.defaultProps = {
    isVisible: false,
};

export default memo(Partners);
