/* eslint-disable jsx-a11y/iframe-has-title */
import React, { memo } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import Components
import { TelegramPreview, ViberPreview, FastexNotificationPreview } from './Preview';
// Import Constants
import {
    l,
    DisplayModeStyles,
    TemplateTypesLabels,
    DisplayModeClassNames,
    DeviceTypeClassNames,
    DynamicPopUpDeviceTypesLabels,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { convertMinutesToHHMM } = Helpers;

const TemplateInfoTab = ({ body, subject, isSubjectVisible, configs, deliveryMethod }) => {
    const { t } = useTranslation();

    const getSubContent = (deliveryMethod, body) => {
        if (
            isSubjectVisible &&
            ![TemplateTypesLabels.Viber, TemplateTypesLabels.Telegram, TemplateTypesLabels.DynamicPopUp].includes(
                deliveryMethod,
            )
        ) {
            return [{ label: t(l.Subject), value: subject }];
        } else if (deliveryMethod === TemplateTypesLabels.Viber) {
            const parsedBody = JSON.parse(body);
            return [
                {
                    label: t(l.ValidityPeriod),
                    value: isNil(parsedBody?.ValidityPeriod)
                        ? t(l.NA)
                        : convertMinutesToHHMM(parsedBody.ValidityPeriod),
                },
            ];
        } else if (deliveryMethod === TemplateTypesLabels.DynamicPopUp) {
            const parsedBody = JSON.parse(body);
            const deviceType = t(DynamicPopUpDeviceTypesLabels[parsedBody.Platforms]);
            const urlTypes = configs.urlTypesData;
            const urlType = urlTypes.find((item) => item.value === parsedBody.Page.Comparison)?.label;

            return [
                { label: t(l.UrlType), value: urlType ?? t(l.NA) },
                { label: t(l.Url), value: parsedBody.Page.Value },
                { label: t(l.DeviceType), value: t(deviceType) },
            ];
        } else {
            return [];
        }
    };

    const getKeyValueGroup = (data) => {
        if (data.length === 0) {
            return null;
        }

        return (
            <>
                {data.map(({ label, value }, index) => (
                    <div key={index} className="key-value-h">
                        <span className="ellipsis-text kv-label">{label}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{value}</span>
                    </div>
                ))}
            </>
        );
    };

    const getBody = (deliveryMethod, body) => {
        if (deliveryMethod === TemplateTypesLabels.Viber) {
            const viberTemplate = JSON.parse(body);

            return renderToString(
                <ViberPreview
                    text={viberTemplate.Text}
                    buttonText={viberTemplate.ButtonText}
                    buttonUrl={viberTemplate.ButtonUrl}
                    mediaUrl={viberTemplate.MediaUrl}
                    displayMode={configs?.modeConfig?.type}
                />,
            );
        } else if (deliveryMethod === TemplateTypesLabels.Telegram) {
            const telegramTemplate = JSON.parse(body);

            return renderToString(
                <TelegramPreview
                    text={telegramTemplate.Text}
                    buttonText={telegramTemplate.ButtonText}
                    buttonUrl={telegramTemplate.ButtonUrl}
                    mediaUrl={telegramTemplate.MediaUrl}
                    displayMode={configs?.modeConfig?.type}
                />,
            );
        } else if (deliveryMethod === TemplateTypesLabels.FastexNotification) {
            const fastexNotificationTemplate = JSON.parse(body);

            return renderToString(
                <FastexNotificationPreview
                    body={fastexNotificationTemplate.Body}
                    group={fastexNotificationTemplate.Group}
                    isImportant={fastexNotificationTemplate.IsImportant}
                    sectionUrl={fastexNotificationTemplate.SectionUrl}
                    displayMode={configs?.modeConfig?.type}
                />,
            );
        } else if (deliveryMethod === TemplateTypesLabels.DynamicPopUp) {
            return JSON.parse(body).Content;
        } else {
            return body;
        }
    };

    return (
        <div className="inner-wrap-tabs-holder-info">
            {getKeyValueGroup(getSubContent(deliveryMethod, body))}
            <div className={DisplayModeClassNames[configs?.modeConfig?.type]}>
                <div className={DeviceTypeClassNames[configs?.bodyConfig?.type]}>
                    <iframe
                        id={configs?.bodyConfig?.titleKey}
                        title={configs?.bodyConfig?.titleKey}
                        style={{ height: '210px' }}
                        srcDoc={`<style>${DisplayModeStyles[configs?.modeConfig?.type]}</style>${getBody(
                            deliveryMethod,
                            body,
                        )}`}
                    />
                </div>
            </div>
        </div>
    );
};

TemplateInfoTab.propTypes = {
    body: PropTypes.string.isRequired,
    configs: PropTypes.object.isRequired,
    subject: PropTypes.string,
    isSubjectVisible: PropTypes.bool,
    deliveryMethod: PropTypes.number.isRequired,
};

TemplateInfoTab.defaultProps = {
    subject: '',
    isSubjectVisible: true,
};

export default memo(TemplateInfoTab);
