import React from 'react';
import PropTypes from 'prop-types';
import { has, values } from 'lodash';
// Import UI Components
import { Title } from '@geneui/components';
//Import Components
import { KeyValueItems } from 'components';

const KeyValueItemsGroup = ({ itemsGroup }) => {
    const mapper = (array) => {
        const filterWithValue = (obj) => {
            Object.keys(obj).forEach((key) => {
                if (!has(obj[key], 'value')) {
                    delete obj[key];
                }
            });
            return obj;
        };
        const newArray = array.map((group) => ({
            ...group,
            keyValueItemsData: filterWithValue(group.keyValueItemsData),
        }));
        return newArray;
    };

    return (
        <div className="segment-modal-head">
            {mapper(itemsGroup).map((itemGroup, index) => {
                return (
                    <div key={index}>
                        <Title text={itemGroup.title} withLine />
                        <KeyValueItems items={values(itemGroup.keyValueItemsData)} />
                    </div>
                );
            })}
        </div>
    );
};
KeyValueItemsGroup.propTypes = {
    itemsGroup: PropTypes.array.isRequired,
};

export default KeyValueItemsGroup;
