import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
//Import Components
import ActivityTimeLine from './ActivityTimeLine';
//Import UI components
import { Modal, Button } from '@geneui/components';
//Import Constants
import { l } from 'constants/common';
//Import SCSS
import 'assets/scss/segmentModal.scss';

const ActivityTimeLineModal = ({ handleClose, isVisible, workflowId }) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                title={t(l.ActivityTimeline)}
                size={'content-size'}
                closable={false}
                visible={isVisible}
                onCancel={handleClose}
                closeOnClickOutside={true}
                className={'cj-activity-timeline-modal'}
                footer={
                    <Button appearance="minimal" onClick={handleClose}>
                        {t(l.Close)}
                    </Button>
                }
            >
                <ActivityTimeLine workflowId={workflowId} />
            </Modal>
        </>
    );
};

ActivityTimeLineModal.propTypes = {
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func,
    workflowId: PropTypes.number,
};

ActivityTimeLineModal.defaultProps = {
    handleClose: noop,
    isVisible: false,
};

export default memo(ActivityTimeLineModal);
