import {
    CRMRequestService,
    CRMRequestDownloadLinkService,
    postCancelableRequest,
    deleteCancelableRequest,
    getCancelableRequest,
} from './http.config';

const CustomerJourneyHttpService = {
    getCustomerJourneyList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Workflow/List',
        });
    },
    deleteCustomerJourney: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `/Workflow/Delete?id=${workflowId}`,
        });
    },
    deleteCustomerJourneys: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Workflow/Delete/`,
        });
    },
    archiveCustomerJourney: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/Archive?id=${workflowId}`,
        });
    },
    unarchiveCustomerJourney: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `/Workflow/Unarchive?id=${workflowId}`,
        });
    },
    archiveCustomerJourneys: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/Archive`,
        });
    },
    unarchiveCustomerJourneys: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Workflow/Unarchive`,
        });
    },
    stopSendCustomerJourney: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/Suspend?id=${workflowId}`,
        });
    },
    startCustomerJourney: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/Start?id=${workflowId}`,
        });
    },
    getCustomerJourneyBlocks: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/Blocks`,
        });
    },
    getCustomerJourneySchema: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ promotionType }) => `Workflow/Schema?promotionType=${promotionType}`,
        });
    },
    getCustomerJourneyPreviewById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ workflowId, isClone }) => `Workflow/Preview?id=${workflowId}&isClone=${isClone}`,
        });
    },
    getCustomerJourneyClientListById: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/GetBlockReport`,
        });
    },
    createCustomerJourney: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/Create`,
        });
    },
    setCustomerJourneyBlockWorkingState: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/SetBlockWorkingState`,
        });
    },
    editCustomerJourney: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/Update?id=${workflowId}`,
        });
    },
    getCustomerJourneyClientsCounts: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/State`,
        });
    },
    downloadWorkflowAllReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: () => `Workflow/DownloadReport`,
        });
    },
    downloadWorkflowBlockReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: () => `Workflow/DownloadWorkflowBlockReport`,
        });
    },
    getCustomerJourneyTimeline: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/GetTimeline?workflowId=${workflowId}`,
        });
    },
    resumeCustomerJourney: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/Resume?id=${workflowId}`,
        });
    },
    getCustomerJourneyGeneralDetails: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/GetGeneralDetails?id=${workflowId}`,
        });
    },
    createCustomerJourneyVisualization: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Visualization/CreateWorkflowVisualization',
        });
    },
    editCustomerJourneyVisualization: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Visualization/UpdateWorkflowVisualization',
        });
    },
    getCustomerJourneyVisualizationById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Visualization/GetWorkflowVisualization?id=${workflowId}`,
        });
    },
    getBlockReportConfigs: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ workflowId }) => `Workflow/GetBlockReportConfigs?workflowId=${workflowId}`,
        });
    },
    getBlockReportDefaultConfigs: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Workflow/GetBlockReportDefaultConfigs',
        });
    },
    saveBlockReportConfig: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'Workflow/SaveBlockReportConfig',
        });
    },
    getTotals: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/GetTotals`,
        });
    },
    sendToEmailWorkflowAllReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: () => `Workflow/GenerateReport`,
        });
    },
    sendToEmailWorkflowBlockReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: () => `Workflow/GenerateBlockReport`,
        });
    },
    getCustomerJourneyLimits: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Workflow/GetLimits`,
        });
    },
    getTemplateModels: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ templateId }) => `Workflow/GetTemplateModels?templateId=${templateId}`,
        });
    },
};

export default CustomerJourneyHttpService;
