import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import constants
import { l, MonthDayYearWithTimeFormat } from 'constants/common';
// Import Services
import { Helpers } from 'services';
//TODO: HA beautify duration show minutes when hours < 1, also you can use moment.duration(90,'minutes').humanize() with translation

const { customMoment } = Helpers;

const MaintenanceInfo = ({ startDate, endDate, isUnderMaintenance }) => {
    const { t } = useTranslation();

    const startDateTime = isUnderMaintenance ? new Date().getTime() : new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    const formattedStartDate = customMoment(startDateTime).format(MonthDayYearWithTimeFormat);
    const durationInHours = moment.duration(endDateTime - startDateTime).asHours();
    const formattedHours = parseFloat(durationInHours.toFixed(1)).toString();

    return (
        <div className="crm-header-maintenance-main-content">
            <p className="maintenance-title">{isUnderMaintenance ? t(l.UnderMaintenance) : t(l.UpcomingMaintenance)}</p>
            <p className="maintenance-content">
                {!isUnderMaintenance && <span className="maintenance-content-date">{formattedStartDate}</span>} (
                {t(l.Duration)}: {formattedHours} {t(l.Hours)})
            </p>
        </div>
    );
};

MaintenanceInfo.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    isUnderMaintenance: PropTypes.bool.isRequired,
};

export default MaintenanceInfo;
