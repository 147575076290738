import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI components
import { ButtonWithLoader } from 'components';
import { Modal, Dropdown, Button } from '@geneui/components';
// Import constants
import { l } from 'constants/common';

const SingleDropdownModal = ({
    data,
    isModalVisible,
    titleTextLabel,
    onCancel,
    onOk,
    dropdownChangeHandler,
    value,
    isLoading,
    isOkBtnEnabled,
    placeholder,
}) => {
    const { t } = useTranslation();
    return (
        <Modal
            background="dark-background"
            className="crm-single-input-modal"
            position="center"
            title={t(l[titleTextLabel])}
            closable={false}
            closeOnClickOutside={true}
            visible={isModalVisible}
            onCancel={onCancel}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader color="primary" onClick={onOk} isLoading={isLoading} isDisabled={!isOkBtnEnabled}>
                        {t(l.Add)}
                    </ButtonWithLoader>
                </>
            }
        >
            <>
                <Dropdown
                    inputSize="default"
                    labelAppearance={'swap'}
                    isMultiSelect={false}
                    disabled={false}
                    hasSearch={true}
                    label="placeholder"
                    onChange={dropdownChangeHandler}
                    value={value}
                    placeholder={placeholder}
                    appearance="outline"
                    data={data}
                />
            </>
        </Modal>
    );
};

SingleDropdownModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    titleTextLabel: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    dropdownChangeHandler: PropTypes.func.isRequired,
    data: PropTypes.array,
    value: PropTypes.number,
    isLoading: PropTypes.bool,
    isOkBtnEnabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

SingleDropdownModal.defaultProps = {
    isLoading: false,
    isOkBtnEnabled: false,
};

export default memo(SingleDropdownModal);
