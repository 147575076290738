import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
// Import UI components
import { SegmentItemsConfig } from './config';
// Import components
import SegmentItem from './SegmentItem';
// Import hooks
import { useValidator } from 'hooks';
const { maxCountOfSegments, enableDropHere } = SegmentItemsConfig;

const SegmentItems = ({ getRowByIndex, dropAcceptType, firstSegment, setFirstSegment, segments, setSegments }) => {
    const validator = useValidator();
    const pageValidation = useSelector((state) => state.pageValidation);
    const mapSegmentItem = ({ data }) => {
        return { title: data.Name, type: data.Type, segmentId: data.SegmentId, enableDropHere: false };
    };

    const pushDropHereIfNecessary = (tmpSegment) => {
        if (tmpSegment.length !== maxCountOfSegments) {
            const push = tmpSegment.reduce((acc, value) => (acc = acc && !value.enableDropHere), true);
            if (push) {
                tmpSegment.push({ ...enableDropHere });
            }
        }
    };

    const clearHandler = (isFirst, index) => {
        return () => {
            if (isFirst) {
                setFirstSegment({ ...enableDropHere });
                validator({ ChoosedSegmentsFirstChild: false, ChoosedSegments: false });
            } else {
                setSegmentsValue(index, false);
            }
        };
    };

    const dropHandler = (isFirst, index) => {
        return (rowIndex) => {
            if (isFirst) {
                setFirstSegment(mapSegmentItem(getRowByIndex(rowIndex)));
                validator({ ChoosedSegmentsFirstChild: true, ChoosedSegments: !segments[0].enableDropHere });
            } else {
                setSegmentsValue(index, true, rowIndex);
            }
        };
    };

    const setSegmentsValue = (index, isDrop, rowIndex) => {
        const tmpSegments = [...segments];
        if (isDrop) {
            tmpSegments[index] = mapSegmentItem(getRowByIndex(rowIndex));
        } else {
            tmpSegments.splice(index, 1);
        }
        pushDropHereIfNecessary(tmpSegments);
        setSegments(tmpSegments);
        validator({ ChoosedSegments: !tmpSegments[0]?.enableDropHere });
    };

    return (
        <div>
            <div className="droping-container-info-inner">
                <SegmentItem
                    dropSegment={dropHandler(true)}
                    itemData={firstSegment}
                    acceptType={dropAcceptType}
                    onClear={clearHandler(true)}
                    isValid={
                        isNil(pageValidation.ChoosedSegmentsFirstChild?.isValid) ||
                        pageValidation.ChoosedSegmentsFirstChild?.isValid
                    }
                />
            </div>
            {segments.map((item, index) => (
                <div className="droping-container-info-inner" key={index}>
                    <SegmentItem
                        key={index}
                        dropSegment={dropHandler(false, index)}
                        itemData={item}
                        acceptType={dropAcceptType}
                        onClear={clearHandler(false, index)}
                        isValid={
                            isNil(item?.isValid)
                                ? index !== 0 ||
                                  isNil(pageValidation.ChoosedSegments?.isValid) ||
                                  pageValidation.ChoosedSegments.isValid
                                : item.isValid
                        }
                    />
                </div>
            ))}
        </div>
    );
};

SegmentItems.propTypes = {
    getRowByIndex: PropTypes.func.isRequired,
    firstSegment: PropTypes.object.isRequired,
    segments: PropTypes.array.isRequired,
    setFirstSegment: PropTypes.func.isRequired,
    setSegments: PropTypes.func.isRequired,
    dropAcceptType: PropTypes.string.isRequired,
};

export default memo(SegmentItems);
