import { isArray, isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';

const useOnClickOutside = (refs, callback, isSelfClickable = false) => {
    const checkIfClickIsNotOutside = useCallback(
        (event) => {
            if (isEmpty(refs)) {
                return true;
            } else if (!isArray(refs)) {
                if (!refs.current) {
                    return true;
                }
                return refs.current.contains(event.target);
            } else {
                return refs.some((ref) => {
                    return !ref.current || ref.current?.contains(event.target);
                });
            }
        },
        [refs],
    );

    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (checkIfClickIsNotOutside(event) || isSelfClickable) {
                    return;
                }
                callback(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and callback to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [refs, callback, isSelfClickable, checkIfClickIsNotOutside],
    );
};

export default useOnClickOutside;
