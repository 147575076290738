import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useFormik } from 'formik';
// Import Components
import { ValidatableDropdown } from 'components';
// Import Services
import { Helpers } from 'services';

const { getDays } = Helpers;

const DayOfMonth = ({ defaultValue, getUpdate, option }) => {
    const formik = useFormik({
        initialValues: { dates: defaultValue?.dates || (option?.Required ? '1' : '') },
        onSubmit: noop,
    });
    const { values, setFieldValue } = formik;
    const { dates } = values;

    const dateChangeHandler = (e) => {
        setFieldValue('dates', e.value);
    };

    useEffect(() => {
        getUpdate({
            dates: dates,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates]);

    return (
        <>
            <ValidatableDropdown
                isMultiSelect={false}
                disabled={false}
                inputSize="default"
                appearance="outline"
                value={dates}
                data={getDays(true)}
                onChange={dateChangeHandler}
                // isValid={!(isValidMonth && !isValidDay)}
            />
        </>
    );
};

DayOfMonth.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(DayOfMonth);
