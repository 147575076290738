import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
// Import UI components
import { ReportItemsConfig } from './config';
// Import components
import ReportItem from './ReportItem';
// Import hooks
import { useValidator } from 'hooks';
const { maxCountOfReports, enableDropHere } = ReportItemsConfig;

const ReportItems = ({ getRowByIndex, dropAcceptType, firstReport, setFirstReport, reports, setReports }) => {
    const validator = useValidator();
    const pageValidation = useSelector((state) => state.pageValidation);
    const mapReportItem = ({ data }) => {
        return { title: data.Name, type: data.ReportType, reportId: data.ReportId, enableDropHere: false };
    };

    const pushDropHereIfNecessary = (tmpReport) => {
        if (tmpReport.length !== maxCountOfReports) {
            const push = tmpReport.reduce((acc, value) => (acc = acc && !value.enableDropHere), true);
            if (push) {
                tmpReport.push({ ...enableDropHere });
            }
        }
    };

    const clearHandler = (isFirst, index) => {
        return () => {
            if (isFirst) {
                setFirstReport({ ...enableDropHere });
                validator({ ChoosedReportsFirstChild: false, ChoosedReports: false });
            } else {
                setReportsValue(index, false);
            }
        };
    };

    const dropHandler = (isFirst, index) => {
        return (rowIndex) => {
            if (isFirst) {
                setFirstReport(mapReportItem(getRowByIndex(rowIndex)));
                validator({ ChoosedReportsFirstChild: true, ChoosedReports: !reports[0].enableDropHere });
            } else {
                setReportsValue(index, true, rowIndex);
            }
        };
    };

    const setReportsValue = (index, isDrop, rowIndex) => {
        const tmpReports = [...reports];
        if (isDrop) {
            tmpReports[index] = mapReportItem(getRowByIndex(rowIndex));
        } else {
            tmpReports.splice(index, 1);
        }
        pushDropHereIfNecessary(tmpReports);
        setReports(tmpReports);
        validator({ ChoosedReports: !tmpReports[0]?.enableDropHere });
    };

    return (
        <div>
            <div className="droping-container-info-inner">
                <ReportItem
                    dropReport={dropHandler(true)}
                    itemData={firstReport}
                    acceptType={dropAcceptType}
                    onClear={clearHandler(true)}
                    isValid={
                        isNil(pageValidation.ChoosedReportsFirstChild?.isValid) ||
                        pageValidation.ChoosedReportsFirstChild?.isValid
                    }
                />
            </div>
            {reports.map((item, index) => (
                <div className="droping-container-info-inner" key={index}>
                    <ReportItem
                        key={index}
                        dropReport={dropHandler(false, index)}
                        itemData={item}
                        acceptType={dropAcceptType}
                        onClear={clearHandler(false, index)}
                        isValid={
                            isNil(item?.isValid)
                                ? index !== 0 ||
                                  isNil(pageValidation.ChoosedReports?.isValid) ||
                                  pageValidation.ChoosedReports.isValid
                                : item.isValid
                        }
                    />
                </div>
            ))}
        </div>
    );
};

ReportItems.propTypes = {
    getRowByIndex: PropTypes.func.isRequired,
    firstReport: PropTypes.object.isRequired,
    reports: PropTypes.array.isRequired,
    setFirstReport: PropTypes.func.isRequired,
    setReports: PropTypes.func.isRequired,
    dropAcceptType: PropTypes.string.isRequired,
};

export default memo(ReportItems);
