import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// Import UI Components
import { SkeletonLoader, Tooltip } from '@geneui/components';

const KeyValueItemBase = ({
    label,
    value,
    isLoading,
    className,
    classNames,
    withLabelTooltip,
    labelTooltipText,
    isTranslated,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <div className={classnames(classNames.pair, className)} {...rest}>
            {withLabelTooltip ? (
                <Tooltip text={labelTooltipText}>
                    <p className={classNames.key}>{isTranslated ? label : t(label)}</p>
                </Tooltip>
            ) : (
                <p className={classNames.key}>{isTranslated ? label : t(label)}</p>
            )}
            <div className={classNames.skeleton}>
                <SkeletonLoader isBusy={isLoading}>
                    {!isLoading && <p className={classNames.value}>{value}</p>}
                </SkeletonLoader>
            </div>
        </div>
    );
};

KeyValueItemBase.propTypes = {
    isLoading: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    classNames: PropTypes.object.isRequired,
    withLabelTooltip: PropTypes.bool,
    labelTooltipText: PropTypes.string,
    isTranslated: PropTypes.bool,
};

export default KeyValueItemBase;
