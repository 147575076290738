/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isNil, take } from 'lodash';
// Import UI Components
import { Button, Icon } from '@geneui/components';
import Block from './Block';
// Import Constants
import { l } from 'constants/common';
import { flowSidebarBlockGroupMinVisibleCount } from './config';

const BlockGroup = ({ titleKey, blocks, blocksTypes, cssClassName, defaultBlockIcon, isReadOnly }) => {
    const { t } = useTranslation();

    const [isBlocksVisible, setIsBlocksVisible] = useState(true);
    const [visibleBlocksCount, setVisibleBlocksCount] = useState(flowSidebarBlockGroupMinVisibleCount);

    const hasVisibleBlocksCountConfiguratorBtn = blocks?.length > flowSidebarBlockGroupMinVisibleCount;

    const visibleBlocksCountConfiguratorBtnHandler = () => {
        if (visibleBlocksCount > flowSidebarBlockGroupMinVisibleCount) {
            setVisibleBlocksCount(flowSidebarBlockGroupMinVisibleCount);
        } else {
            setVisibleBlocksCount(blocks.length);
        }
    };

    const blocksVisibilityToggleHandler = () => {
        setIsBlocksVisible(!isBlocksVisible);
    };

    return (
        <div
            className={classNames(`crm-flow-sidebar-block-group ${cssClassName}`, {
                'crm-flow-sidebar-block-group-read-only': isReadOnly,
            })}
        >
            <div className="crm-flow-sidebar-block-group-head" onClick={blocksVisibilityToggleHandler}>
                <Icon type={isBlocksVisible && !isReadOnly ? 'bc-icon-arrow-up' : 'bc-icon-arrow-down'} />
                <span>{t(titleKey)}</span>
            </div>

            {isBlocksVisible && !isReadOnly && (
                <div className="crm-flow-sidebar-block-group-body">
                    {take(blocks, visibleBlocksCount).map((block, index) => {
                        const { Name, CustomAttributes } = block;
                        const iconType = !isNil(CustomAttributes?.BCIcon) ? CustomAttributes?.BCIcon : defaultBlockIcon;

                        return (
                            <Block
                                key={index}
                                titleKey={Name}
                                type={blocksTypes}
                                groupType={blocksTypes}
                                defaultIcon={iconType}
                                cssClassName={cssClassName}
                                metaData={block}
                            />
                        );
                    })}

                    {hasVisibleBlocksCountConfiguratorBtn && (
                        <Button
                            flexibility="content-size"
                            size="medium"
                            appearance="outline"
                            onClick={visibleBlocksCountConfiguratorBtnHandler}
                        >
                            {visibleBlocksCount > flowSidebarBlockGroupMinVisibleCount ? t(l.Less) : t(l.More)}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

BlockGroup.propTypes = {
    titleKey: PropTypes.string.isRequired,
    blocks: PropTypes.array.isRequired,
    blocksTypes: PropTypes.string.isRequired,
    defaultBlockIcon: PropTypes.string.isRequired,
    cssClassName: PropTypes.string,
    isReadOnly: PropTypes.bool,
};

BlockGroup.defaultProps = {
    cssClassName: '',
    isReadOnly: false,
};

export default memo(BlockGroup);
