import { l } from 'constants/common';

const UsageTypes = {
    WORKFLOW: 'Workflow',
    CAMPAIGN: 'Campaign',
    SEGMENT: 'Segment',
    ADHOC_REPORT: 'AdHocReport',
    COMPOSITE_REPORT: 'CompositeReport',
    NOTIFICATION_CAMPAIGN: 'NotificationCampaign',
    WORKFLOW_REPORT: 'WorkflowReport',
};

const UsageViewModel = [
    { displayNameKey: l.UsageInCampaigns, type: UsageTypes.CAMPAIGN },
    { displayNameKey: l.UsageInWorkflows, type: UsageTypes.WORKFLOW },
    { displayNameKey: l.UsageInSegment, type: UsageTypes.SEGMENT },
    { displayNameKey: l.UsageInCompositeReport, type: UsageTypes.COMPOSITE_REPORT },
    { displayNameKey: l.UsageInWorkflowReport, type: UsageTypes.WORKFLOW_REPORT },
    { displayNameKey: l.UsageInReport, type: UsageTypes.ADHOC_REPORT },
];

export { UsageViewModel, UsageTypes };
