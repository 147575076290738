import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Import Components
import { ManageTagModal } from 'components';

const ClientActionsModals = ({
    isManageTagModalOpen,
    isManageTagsFromFileModalOpen,
    onCloseModalHandler,
    modalsStateSetDict,
    openedModalData,
}) => {
    const manageTagModalCloseHandler = (resetSelectedRows) =>
        onCloseModalHandler(modalsStateSetDict.manageTag.key, resetSelectedRows);
    const manageTagFromFileModalCloseHandler = () => onCloseModalHandler(modalsStateSetDict.manageTagFromFile.key);

    return (
        <>
            {!isEmpty(openedModalData.clientIds) && isManageTagModalOpen && (
                <ManageTagModal
                    handleClose={manageTagModalCloseHandler}
                    isVisible={isManageTagModalOpen}
                    clientIds={openedModalData.clientIds}
                    isFromFileMode={false}
                />
            )}
            {isManageTagsFromFileModalOpen && (
                <ManageTagModal
                    handleClose={manageTagFromFileModalCloseHandler}
                    isVisible={isManageTagsFromFileModalOpen}
                />
            )}
        </>
    );
};

ClientActionsModals.propTypes = {
    isManageTagModalOpen: PropTypes.bool.isRequired,
    isManageTagsFromFileModalOpen: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.any.isRequired,
};

export default memo(ClientActionsModals);
