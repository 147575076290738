import React, { memo, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, first, isEqual, isNil, last, unionBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import components
import { DataTableHeaderFilter, LabelTag, DownloadOrSendToEmailToolbar, ClientId } from 'components';
// Import UI components
import { SkeletonLoader, Button, BusyLoader, Tooltip, Icon } from '@geneui/components';
// Import constants
import {
    CampaignStatusLabels,
    FilterComparisions,
    DateTimeFormat,
    l,
    CampaignLogStatusLabels,
    CampaignStatusColors,
} from 'constants/common';
import { CampaignLiveMonitoringConfig, CampaignLiveMonitorFilterConfig } from './config';
// Import services
import { CampaignHttpService } from 'services/http';
import { Helpers } from 'services';
// Import hooks
import { useRequest, useListBaseRequestBody, useToaster } from 'hooks';
// Import actions
import { CampaignLiveMonitorListAction } from 'actions';

const { customMomentWithoutTimezoneConversion } = Helpers;
const { setCampaignLiveMonitorListTableFilters } = CampaignLiveMonitorListAction;

const CampaignLiveMonitoring = ({ campaignId, name: _name }) => {
    const dispatch = useDispatch();
    const { showToaster } = useToaster();

    const { tableData } = cloneDeep(useSelector((state) => state.campaignLiveMonitorList));
    const { filters } = tableData;

    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();
    const [campaignStatusData, setCampaignStatusData] = useState();

    const [isVisibleLoadMore, setIsVisibleLoadMore] = useState(false);
    const [isLoadingLoadMore, setIsLoadingLoadMore] = useState(false);

    const [logData, setLogData] = useState();
    const [isLoadingLogData, setIsLoadingLogData] = useState(true);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isDisableDownload, setIsDisableDonwload] = useState(true);

    const minCampaignLogId = useRef(0);
    const maxCampaignLogId = useRef(0);
    const timeoutId = useRef(-1);

    const [
        baseRequestBody,
        newFilterField,
        setFilters,
        // eslint-disable-next-line no-unused-vars
        _setPagingPageNumber,
        setPagingPageSize,
    ] = useListBaseRequestBody([], CampaignLiveMonitoringConfig.paging, CampaignLiveMonitoringConfig.sorting);

    const [baseRequestBodyLoadMore, newFilterFieldLoadMore, setFiltersLoadMore] = useListBaseRequestBody(
        [],
        CampaignLiveMonitoringConfig.paging,
        CampaignLiveMonitoringConfig.sorting,
    );
    const { getCampaignStatusRequest, getCampaignLogListRequest, downloadCampaignLogsRequest } = useRef({
        getCampaignStatusRequest: CampaignHttpService.getCampaignStatus(),
        getCampaignLogListRequest: CampaignHttpService.getCampaignLogList(),
        downloadCampaignLogsRequest: CampaignHttpService.downloadCampaignLogs(),
    }).current;

    const reset = () => {
        minCampaignLogId.current = 0;
        maxCampaignLogId.current = 0;
        setIsVisibleLoadMore(false);
    };

    const onResetFilters = () => {
        reset();

        const filterFieldsKeys = CampaignLiveMonitorFilterConfig.map(({ valueFieldKey }) => valueFieldKey);
        const newCampaignLogIdFilter = newFilterField(
            CampaignLiveMonitoringConfig.campaignLogIdFilterName,
            FilterComparisions.GreaterThan,
            [0],
        );

        setFilters([...filters.filter(({ Name }) => !filterFieldsKeys.includes(Name)), newCampaignLogIdFilter]);
        dispatch(setCampaignLiveMonitorListTableFilters(baseRequestBody.Filters));
        getCampaignLogs(baseRequestBody, setIsLoadingLoadMore, addBelowLogData, false, true, true);
    };

    const onFilterApply = (filtersFields) => {
        reset();
        // Filter holder filters
        const newCampaignIdFilter = newFilterField(
            CampaignLiveMonitoringConfig.campaignIdFilterName,
            FilterComparisions.Equals,
            [campaignId],
        );

        const localFilters = CampaignLiveMonitorFilterConfig.map(({ valueFieldKey, comparision }) => {
            if (valueFieldKey === 'Status') {
                if (
                    filtersFields[valueFieldKey]?.includes(CampaignLogStatusLabels.Succes) &&
                    filtersFields[valueFieldKey]?.includes(CampaignLogStatusLabels.Failed)
                ) {
                    filtersFields[valueFieldKey] = [];
                }
                if (filtersFields[valueFieldKey]?.includes(CampaignLogStatusLabels.Failed)) {
                    comparision = FilterComparisions.NotEquals;
                    filtersFields[valueFieldKey] = CampaignLogStatusLabels.Succes;
                }
            }
            return newFilterField(valueFieldKey, comparision, filtersFields[valueFieldKey]);
        });
        if (!isEqual(localFilters, filters)) {
            setFilters([...filters, ...localFilters, newCampaignIdFilter]);
            dispatch(setCampaignLiveMonitorListTableFilters(baseRequestBody.Filters));
        }
        getCampaignLogs(baseRequestBody, setIsLoadingLoadMore, addBelowLogData, false, true, true);
    };

    const getCampaignLogs = (
        requestBody,
        setIsLoading,
        setData,
        isSetMinId = false,
        isSetMaxId = true,
        isResetTable = false,
    ) => {
        doPostRequest(getCampaignLogListRequest.request, {
            requestBody: requestBody,
            successCallback: (Data) => {
                if (isSetMinId || minCampaignLogId.current === 0) {
                    const minLogId = last(Data?.Data)?.CampaignLogId;
                    if (Data.Count > CampaignLiveMonitoringConfig.paging.PageSize) {
                        // TODO Data.Data.length <> pageSize
                        setIsVisibleLoadMore(true);
                    }
                    minCampaignLogId.current = minLogId;
                }
                if (isSetMaxId) {
                    if (+campaignStatusData?.Status === +CampaignStatusLabels.InProgress) {
                        timeoutId.current = setTimeout(
                            getCampaignStatus,
                            CampaignLiveMonitoringConfig.getStatusInterval,
                        );
                    }
                    maxCampaignLogId.current = first(Data?.Data)?.CampaignLogId;
                }

                setData(isNil(Data?.Data) ? [] : Data.Data, isResetTable);
                setIsDisableDonwload(isNil(Data?.Count) || Data?.Count === 0);
            },
        }).then(() => {
            setIsLoading(false);
        });
    };

    const addAboveLogData = (data, isResetTable) => {
        const tmpLogData = isResetTable ? [] : isNil(logData) ? [] : logData;
        setLogData(unionBy(data, tmpLogData, CampaignLiveMonitoringConfig.comparatorPropName));
    };
    const addBelowLogData = (data, isResetTable) => {
        const tmpLogData = isResetTable ? [] : isNil(logData) ? [] : logData;
        setLogData(unionBy(tmpLogData, data, CampaignLiveMonitoringConfig.comparatorPropName));
    };

    const getCampaignStatus = () => {
        doGetRequest(getCampaignStatusRequest.request, {
            queryString: { campaignId: campaignId },
            successCallback: (Data) => setCampaignStatusData(Data),
        });
    };

    useEffect(() => {
        if (!isNil(campaignStatusData)) {
            const newCampaignIdFilter = newFilterField(
                CampaignLiveMonitoringConfig.campaignIdFilterName,
                FilterComparisions.Equals,
                [campaignId],
            );
            const newCampaignLogIdFilter = newFilterField(
                CampaignLiveMonitoringConfig.campaignLogIdFilterName,
                FilterComparisions.GreaterThan,
                [maxCampaignLogId.current],
            );
            setFilters([newCampaignIdFilter, newCampaignLogIdFilter]);
            setPagingPageSize(
                maxCampaignLogId.current === 0
                    ? CampaignLiveMonitoringConfig.paging.PageSize
                    : CampaignLiveMonitoringConfig.newPageSize,
            );
            getCampaignLogs(baseRequestBody, setIsLoadingLogData, addAboveLogData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignStatusData]);

    useEffect(() => {
        if (!isNil(campaignId)) {
            reset();
            getCampaignStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId]);

    const loadMoreClickHandler = () => {
        setIsLoadingLoadMore(true);
        const newCampaignIdFilter = newFilterFieldLoadMore(
            CampaignLiveMonitoringConfig.campaignIdFilterName,
            FilterComparisions.Equals,
            [campaignId],
        );
        const newCampaignLogIdFilter = newFilterFieldLoadMore(
            CampaignLiveMonitoringConfig.campaignLogIdFilterName,
            FilterComparisions.LessThan,
            minCampaignLogId.current,
        );
        setFiltersLoadMore([...filters, newCampaignIdFilter, newCampaignLogIdFilter]);
        getCampaignLogs(baseRequestBodyLoadMore, setIsLoadingLoadMore, addBelowLogData, true, false);
    };

    const getRequestBody = () => {
        setIsFileLoading(true);
        const newCampaignIdFilter = newFilterField(
            CampaignLiveMonitoringConfig.campaignIdFilterName,
            FilterComparisions.Equals,
            [campaignId],
        );
        setFilters([...filters, newCampaignIdFilter]);
        return {
            Filters: baseRequestBody.Filters,
        };
    };

    const downloadAllOrSendEmailClickHandler = (isDownload) => {
        const requestBody = getRequestBody();
        return doPostRequest(downloadCampaignLogsRequest.request, {
            requestBody,
            queryString: { isDownload, campaignId },
            successCallback: (data) => {
                isDownload && window.open(data?.Url);
            },
        }).then(({ AlertType, AlertMessage }) => {
            showToaster(AlertType, AlertMessage);
            setIsFileLoading(false);
        });
    };

    const getHeaderActions = () => {
        return (
            <>
                <p>{t(l.Status)}:</p>
                <SkeletonLoader isBusy={isNil(campaignStatusData)}>
                    {!isNil(campaignStatusData?.Status) && (
                        <LabelTag
                            color={`var(${CampaignStatusColors[campaignStatusData?.Status]})`}
                            labelName={t(CampaignStatusLabels[campaignStatusData?.Status])}
                        />
                    )}
                </SkeletonLoader>
                {+campaignStatusData?.Status === +CampaignStatusLabels.Error && (
                    <Tooltip text={campaignStatusData?.Message} position="bottom">
                        <Icon type="bc-icon-info" />
                    </Tooltip>
                )}
                <DownloadOrSendToEmailToolbar
                    downloadAction={() => downloadAllOrSendEmailClickHandler(true)}
                    sendToEmailAction={() => downloadAllOrSendEmailClickHandler(false)}
                    isFileLoading={isFileLoading}
                    isDisabledDownload={isDisableDownload}
                />
            </>
        );
    };

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getCampaignStatusRequest.cancel('CampaignLiveMonitoring:getCampaignStatusRequest');
            getCampaignLogListRequest.cancel('CampaignLiveMonitoring:getCampaignLogListRequest');
            if (timeoutId.current !== -1) {
                clearTimeout(timeoutId.current);
            }
        };
    };

    useEffect(cleanUp, []);

    return (
        <div className="live-monitoring-section">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name">{t(l.LiveMonitoring)}</div>
                        </div>
                    </div>
                </li>
                <li className="right-content"> {getHeaderActions()} </li>
            </ul>
            <div className="live-monitor-filter">
                <DataTableHeaderFilter
                    defaultInputsData={CampaignLiveMonitorFilterConfig}
                    onApply={onFilterApply}
                    onReset={onResetFilters}
                />
            </div>
            <div className="live-monitoring-table">
                <div className="live-monitoring-table-header">
                    <div>{t(l.DateTime)}</div>
                    <div>{t(l.ClientId)}</div>
                    <div>{t(l.Status)}</div>
                    <div>{t(l.ExecutionId)}</div>
                    <div>{t(l.Message)}</div>
                </div>

                <div className="live-monitoring-table-content">
                    <BusyLoader isBusy={isLoadingLogData}>
                        {!isNil(logData) &&
                            logData.map((i, index) => (
                                <div className="live-monitoring-table-content-inner" key={index}>
                                    <div className="live-monitoring-date-block">
                                        <Icon type={i.LogStatus === 0 ? 'bc-icon-success' : 'bc-icon-error'} />
                                        <p>
                                            {customMomentWithoutTimezoneConversion(i.CreatedDate).format(
                                                DateTimeFormat,
                                            )}
                                        </p>
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        <p>
                                            <ClientId clientId={i.ClientId} />
                                        </p>
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        <p>{i.LogStatus === 0 ? t(l.Success) : t(l.Failed)}</p>
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        <p>{i.CampaignExecuteResultId}</p>
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        <p>{i.Message}</p>
                                    </div>
                                </div>
                            ))}
                    </BusyLoader>
                    <BusyLoader isBusy={isLoadingLoadMore}>
                        {!isLoadingLoadMore && isVisibleLoadMore && (
                            <Button
                                color="primary"
                                appearance="minimal"
                                onClick={loadMoreClickHandler}
                                disabled={isLoadingLogData}
                            >
                                {t(l.LoadMore)}
                            </Button>
                        )}
                    </BusyLoader>
                </div>
            </div>
        </div>
    );
};

CampaignLiveMonitoring.propTypes = {
    campaignId: PropTypes.string.isRequired,
    name: PropTypes.string,
};

export default memo(CampaignLiveMonitoring);
