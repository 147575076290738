import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
// Import Components
import { AwardInfoModal } from 'components';
// Import UI components
import { Tooltip } from '@geneui/components';

const AwardId = ({ awardId, awardName }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onClickButtonHandler = () => {
        setIsModalVisible(() => true);
    };

    const onCloseModalHandler = () => {
        setIsModalVisible(() => false);
    };

    return (
        <>
            <Tooltip text={awardName} position="bottom">
                <button className="tittle-text ellipsis-text" onClick={onClickButtonHandler}>
                    {awardName}
                </button>
            </Tooltip>
            {isModalVisible && (
                <AwardInfoModal awardId={awardId} isVisible={isModalVisible} handleClose={onCloseModalHandler} />
            )}
        </>
    );
};

AwardId.propTypes = {
    awardId: PropTypes.number.isRequired,
    awardName: PropTypes.string.isRequired,
};

export default memo(AwardId);
