import { DynamicSegmentActionsTypes as Types } from 'actions/types';

export default (state = { id: '0' }, action) => {
    switch (action.type) {
        case Types.CHANGE_TAB_ACTIVE_INDEX:
            state = action.payload;
            return Object.assign({}, state);
        default:
            return state;
    }
};
