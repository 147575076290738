import React, { memo, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import randomString from 'randomstring';
import { isNil, cloneDeep, isBoolean, isEmpty, noop } from 'lodash';
// Import UI Components
import { Button, RadioGroup } from '@geneui/components';
// Import Components
import CJFilter from './CJFilter';
// Import Constants
import { l, LogicTypesValues } from 'constants/common';

const CJFilterGroup = ({
    defaultValue,
    collectedProperties,
    option,
    node,
    elements,
    onRemove,
    onClone,
    getUpdate,
    limit,
    isDeletable,
    isCloneable,
    withLabel,
    label,
    index: _index,
}) => {
    const { t } = useTranslation();

    const emptyFilterShape = useMemo(
        () => ({
            property: null,
            operator: null,
            rightValue: null,
        }),
        [],
    );

    const logicTypes = useMemo(
        () => [
            { label: t(l.And), value: LogicTypesValues.and },
            { label: t(l.Or), value: LogicTypesValues.or },
        ],
        [t],
    );

    const [filters, setFilters] = useState(defaultValue?.filters || []);

    const [selectedLogic, setSelectedLogic] = useState(
        isNil(defaultValue?.logic) ? LogicTypesValues.and : defaultValue.logic,
    );

    const logicChangeHandler = ({ target }) => {
        setSelectedLogic(target.value);
    };

    const addFilter = () => {
        const newFilters = cloneDeep([{ ...emptyFilterShape, key: randomString.generate() }, ...filters]);
        setFilters(newFilters);
    };

    const addFilterGroup = () => {
        const newFilters = cloneDeep([
            {
                isGroupFilter: true,
                logic: selectedLogic === LogicTypesValues.and ? LogicTypesValues.or : LogicTypesValues.and,
                filters: [{ ...emptyFilterShape, key: randomString.generate() }],
                key: randomString.generate(),
            },
            ...filters,
        ]);
        setFilters(newFilters);
    };

    const updateFilter = (filterIndex) => {
        return (filter) => {
            setFilters((prevFilters) => {
                let newFilters = cloneDeep(prevFilters);
                newFilters[filterIndex] = { ...newFilters[filterIndex], ...filter };
                return newFilters;
            });
        };
    };

    const cloneFilter = (filterIndex) => {
        return () => {
            const newFilters = cloneDeep([{ ...filters[filterIndex], key: randomString.generate() }, ...filters]);
            setFilters(newFilters);
        };
    };

    const removeFilter = (filterIndex) => {
        return () => {
            let newFilters = cloneDeep(filters);
            newFilters.splice(filterIndex, 1);
            setFilters(newFilters);
            if (isEmpty(newFilters) && isDeletable === true) {
                onRemove();
            }
        };
    };

    const init = () => {
        const { filters } = defaultValue;
        let tmpFilters = isNil(filters) ? [] : filters.map((item) => ({ ...item, key: randomString.generate() }));
        if (option?.Required === true && isEmpty(tmpFilters) && isDeletable === false) {
            tmpFilters = cloneDeep([{ ...emptyFilterShape, key: randomString.generate() }]);
        }
        setFilters(tmpFilters);
    };

    useEffect(init, []);

    useEffect(() => {
        if (isNil(selectedLogic) || isNil(filters)) return;

        getUpdate({
            logic: selectedLogic,
            filters,
            isGroupFilter: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLogic, filters]);

    useEffect(() => {
        if (isEmpty(filters) && isDeletable === false && option?.Required === true) {
            setFilters(cloneDeep([{ ...emptyFilterShape, key: randomString.generate() }]));
        }
        getUpdate({
            isValid: filters.reduce((acc, filter) => {
                acc = acc && (isBoolean(filter?.isValid) ? filter.isValid : true);
                return acc;
            }, true),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <div className="crm-cj-filter-condition-block">
            <div className="crm-cj-filter-condition-block-heading">{withLabel && <span>{label}</span>}</div>
            <div className="crm-cj-filter-condition-header">
                {filters.length > 1 && (
                    <RadioGroup name="logic" options={logicTypes} value={selectedLogic} onChange={logicChangeHandler} />
                )}
                <Button onClick={addFilter} appearance="outline" icon="bc-icon-add">
                    {t(l.AddFilter)}
                </Button>
                {(isNil(limit) || limit > 0) && (
                    <Button onClick={addFilterGroup} appearance="outline" icon="bc-icon-add">
                        {t(l.AddFilterGroup)}
                    </Button>
                )}
                {isCloneable && <Button onClick={onClone} icon="bc-icon-clone" appearance="minimal" />}
                {isDeletable && (
                    <Button disabled={false} onClick={onRemove} icon="bc-icon-delete-2" appearance="minimal" />
                )}
            </div>
            <div className="crm-cj-filter-condition-filters-cnt">
                {filters.map((filter, index) => {
                    if (filter?.isGroupFilter) {
                        return (
                            <CJFilterGroup
                                key={filter.key}
                                collectedProperties={collectedProperties}
                                option={option}
                                node={node}
                                elements={elements}
                                onRemove={removeFilter(index)}
                                onClone={cloneFilter(index)}
                                defaultValue={filter}
                                getUpdate={updateFilter(index)}
                                limit={isNil(limit) ? null : limit - 1}
                                label={`${t(l.FilterGroup)} ${index + 1}`}
                                index={index}
                            />
                        );
                    }
                    return (
                        <CJFilter
                            key={filter.key}
                            index={index}
                            properties={collectedProperties}
                            onRemove={removeFilter(index)}
                            onClone={cloneFilter(index)}
                            filter={filter}
                            getUpdate={updateFilter(index)}
                            elements={elements}
                            option={option}
                            node={node}
                            isRemoveDisabled={
                                option?.Required === true && isDeletable === false && filters.length === 1
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

CJFilterGroup.propTypes = {
    defaultValue: PropTypes.shape({
        filters: PropTypes.array,
        logic: PropTypes.string,
    }),
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    getUpdate: PropTypes.func.isRequired,
    withLabel: PropTypes.bool,
    index: PropTypes.number,
    onRemove: PropTypes.func,
    onClone: PropTypes.func,
    collectedProperties: PropTypes.array,
    option: PropTypes.object,
    limit: PropTypes.number,
    isDeletable: PropTypes.bool,
    isCloneable: PropTypes.bool,
    label: PropTypes.string,
};

CJFilterGroup.defaultProps = {
    defaultValue: {
        logic: LogicTypesValues.and,
        filters: [],
    },
    collectedProperties: [],
    onRemove: noop,
    onClone: noop,
    limit: null,
    isDeletable: true,
    isCloneable: true,
    withLabel: true,
    index: 0,
};

export default memo(CJFilterGroup);
