import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import UI components
import { Dropdown } from '@geneui/components';
// Import Constants
import { data } from './config';

const BooleanDropdown = ({ onChange, ...rest }) => {
    const { t } = useTranslation();

    const valueChangeHandler = ({ value }) => {
        onChange(value);
    };

    const getDropdownData = () => {
        return data.map((item) => ({ ...item, label: t(item.label) }));
    };

    return <Dropdown data={getDropdownData()} onChange={valueChangeHandler} {...rest} />;
};

BooleanDropdown.propTypes = {
    onChange: PropTypes.func,
};

BooleanDropdown.defaultProps = { onChange: noop };

export default BooleanDropdown;
