export default {
    IsAllowPushNotification: 'IsAllowPushNotification',
    IsAllowPopUp: 'IsAllowPopUp',
    IsAllowDynamicPopUp: 'IsAllowDynamicPopUp',
    IsAllowCMSPopUp: 'IsAllowCMSPopUp',
    IsBonusIncluded: 'IsBonusIncluded',
    IsEmailTracked: 'IsEmailTracked',
    IsAllowNftBonus: 'IsAllowNftBonus',
    IsAllowViberNotification: 'IsAllowViberNotification',
    IsAllowInternalMessage: 'IsAllowInternalMessage',
    IsAllowHooryMessage: 'IsAllowHooryMessage',
    IsAllowFastexNotification: 'IsAllowFastexNotification',
};
