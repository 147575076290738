import React, { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Services
import { Helpers } from 'services';
import { TemplateType } from 'services/template';
// Import Constants
import { l, TemplateInputTypes } from 'constants/common';
// Import SCSS
import 'assets/scss/socialNotification.scss';
import { useFieldValidity, useInputInsertion } from 'hooks';
import DynamicVariables from './DynamicVariables';

const { calculateTemplateBodyLengthWithRegEx } = Helpers;

const HooryTemplateContent = ({
    templateData,
    templateLangKey,
    handleChange,
    shortenerAndTrackerSettings,
    dynamicVariables,
}) => {
    const { t } = useTranslation();

    const { insertionHandler, updateInsertionHandler, insertionOptions, setInsertionOptions } = useInputInsertion();
    const templateBody = templateData?.[TemplateInputTypes.Body];

    const [bodyValue, setBodyValue] = useState(templateBody);

    const inputRef = useRef(null);
    const bodyValidationState = useFieldValidity(TemplateInputTypes.Body, l.Body, templateLangKey);

    const computedBody = useMemo(() => {
        if (isEmpty(shortenerAndTrackerSettings) || isEmpty(templateBody)) return '';
        return calculateTemplateBodyLengthWithRegEx(shortenerAndTrackerSettings, templateBody);
    }, [templateBody, shortenerAndTrackerSettings]);

    const insertTextAtCursor = (textToInsert) => {
        const { selectionStart } = inputRef.current;
        const firstPart = bodyValue.slice(0, selectionStart);
        const secondPart = bodyValue.slice(selectionStart, bodyValue.length);
        const resultValue = `${firstPart} {${textToInsert}} ${secondPart}`;
        inputRef.current.setSelectionRange(resultValue.length, resultValue.length);
        inputRef.current.focus();

        setBodyValue(resultValue);
        handleChange(TemplateInputTypes.Body, resultValue);
    };

    updateInsertionHandler(TemplateInputTypes.Body, insertTextAtCursor);

    const onChange = (e) => {
        const value = e.target.value;

        setBodyValue(value);
        handleChange(TemplateInputTypes.Body, value);
    };

    useEffect(() => {
        setInsertionOptions({
            channel: TemplateType.Hoory,
            inputFilterKey: '',
            hintText: '',
            focusedInputKey: TemplateInputTypes.Body,
        });
    }, [setInsertionOptions]);

    return (
        <>
            <DynamicVariables
                dynamicVariables={dynamicVariables}
                clickHandler={insertionHandler}
                insertionOptions={insertionOptions}
            />
            <div className="crm-create-template-page-c-c-content">
                <div className="crm-messaging-row">
                    <div className="crm-messaging-row-head">
                        <p>{t(l.CharactersCountIn, { pageName: TemplateType.Hoory })}</p>
                        <span>{computedBody.length}</span>
                    </div>

                    <div className={`crm-messaging-row-content ${bodyValidationState.isValid ? '' : 'error-color'}`}>
                        <ExtendedInput
                            ref={inputRef}
                            type="textarea"
                            value={bodyValue}
                            colorBorderOnError={true}
                            isValid={bodyValidationState.isValid}
                            errorText={bodyValidationState.errorText}
                            placeholder={t(l.TypeYourText)}
                            onChange={onChange}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

HooryTemplateContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    shortenerAndTrackerSettings: PropTypes.array.isRequired,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
};

export default HooryTemplateContent;
