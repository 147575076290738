import React, { memo, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { BusyLoader, Modal, Button, Tooltip, Icon } from '@geneui/components';
// Import Components
import { PlayersDataTable, DownloadToolbar } from 'components';
import CustomerJourneyTrackedLinksContent from './CustomerJourneyTrackedLinksContent';

// Import Constants
import { l, CampaignType, DownloadExtensions } from 'constants/common';
import { PlayersColumns, ModalTypes, ModalTitleLabels } from './config';
// Import Services
import { CommunicationHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Hooks
import { useRequest } from 'hooks';
// Import SCSS
import 'assets/scss/customerJourneyReportPage.scss';

const { saveToDisk } = Helpers;

const CustomerJourneyProgressData = ({ reportData, isLoadingReport, workflowId }) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();

    const [trackedLinks, setTrackedLinks] = useState([]);
    const [selectedModalType, setSelectedModalType] = useState();
    const [playersData, setPlayersData] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoadedModal, setIsLoadedModal] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isDownloadAllLoading, setIsDownloadAllLoading] = useState(false);

    const [isDisableDownload, setIsDisableDownload] = useState(true);

    const TrackedLinkIdsRef = useRef();

    const onCloseHandler = () => {
        setIsVisible(false);
    };

    const getFooter = () => {
        return (
            <Button appearance="minimal" onClick={onCloseHandler}>
                {t(l.Close)}
            </Button>
        );
    };

    const getContent = () => {
        if (selectedModalType === ModalTypes.TrackedLink || selectedModalType === ModalTypes.UnsubscribeLink) {
            return (
                <CustomerJourneyTrackedLinksContent
                    ref={TrackedLinkIdsRef}
                    workflowId={workflowId}
                    data={trackedLinks}
                    templateId={selectedTemplateId}
                    isLoading={isLoadedModal}
                    linkType={selectedModalType}
                    setIsDisableDownload={setIsDisableDownload}
                    downloadAllClickHandler={downloadAllClickHandler}
                    downloadClickHandler={downloadClickHandler}
                    isFileLoading={isFileLoading}
                    isDisableDownload={isDisableDownload}
                    isDownloadAllLoading={isDownloadAllLoading}
                />
            );
        }
        return (
            <div className="c-j-report-players-table">
                <div className="c-j-report-page-table">
                    <div className="c-j-report-download">
                        <DownloadToolbar
                            downloadClickHandler={downloadClickHandler}
                            withCountDropdown={true}
                            withTypeSelector={false}
                            isFileLoading={isFileLoading}
                            isDisableDownload={isDisableDownload}
                        />
                        <Tooltip text={t(l.TooltipDownloadTypeInfo)} position="top">
                            <Icon type="bc-icon-info" />
                        </Tooltip>
                    </div>
                    <PlayersDataTable
                        isFileLoading={isFileLoading}
                        downloadClickHandler={downloadClickHandler}
                        isDisableDownload={isDisableDownload}
                        config={PlayersColumns}
                        isLoading={isLoadedModal}
                        data={playersData}
                    />
                </div>
            </div>
        );
    };

    const {
        getWorkflowClickReportByCampaignType,
        getWorkflowOpenReportByCampaignTypeRequest,
        getWorkflowTrackedLinksRequest,
        getWorkflowTrackLinkReportByIdRequest,
        downloadTrackLinkReportRequest,
        downloadClickReportRequest,
        downloadOpenReportRequest,
    } = useMemo(
        () => ({
            getWorkflowClickReportByCampaignType: CommunicationHttpService.getClickReportByCampaignType(),
            getWorkflowOpenReportByCampaignTypeRequest: CommunicationHttpService.getOpenReportByCampaignType(),
            getWorkflowTrackedLinksRequest: CommunicationHttpService.getTrackedLinks(),
            getWorkflowTrackLinkReportByIdRequest: CommunicationHttpService.getTrackLinkReportById(),
            downloadTrackLinkReportRequest: CommunicationHttpService.downloadTrackLinkReport(),
            downloadClickReportRequest: CommunicationHttpService.downloadClickReport(),
            downloadOpenReportRequest: CommunicationHttpService.downloadOpenReport(),
        }),
        [],
    );

    const downloadAllClickHandler = () => {
        setIsDownloadAllLoading(true);

        const templateName = reportData?.find((t) => t?.TemplateId === selectedTemplateId)?.TemplateName;
        const trackLinkIds = trackedLinks.map(({ TrackLinkId }) => {
            return TrackLinkId;
        });

        const requestBody = {
            TrackLinkIdes: trackLinkIds,
            CampaignType: CampaignType.WorkFlow,
            CampaignId: workflowId,
            TemplateId: selectedTemplateId,
        };

        doPostRequest(downloadTrackLinkReportRequest.request, {
            requestBody,
        }).then((res) => {
            saveToDisk(res, DownloadExtensions.xlsx, templateName);
            setIsDownloadAllLoading(false);
        });
    };

    const downloadClickHandler = (type, downloadDropdownValue) => {
        setIsFileLoading(true);

        const trackLinkId = TrackedLinkIdsRef?.current?.getRadioSelectedRow();
        const templateName = reportData?.find((t) => t?.TemplateId === selectedTemplateId)?.TemplateName;

        const requestBody = {
            TrackLinkIdes: [trackLinkId],
            TopCount: downloadDropdownValue,
            CampaignType: CampaignType.WorkFlow,
            CampaignId: workflowId,
            TemplateId: selectedTemplateId,
        };

        switch (selectedModalType) {
            case ModalTypes.TrackedLink:
                return doPostRequest(downloadTrackLinkReportRequest.request, {
                    requestBody,
                }).then((res) => {
                    saveToDisk(
                        res,
                        type,
                        `${templateName}_${trackedLinks
                            .find((r) => r.TrackLinkId === trackLinkId)
                            ?.Url?.replace('/', '_')}`,
                    );
                    setIsFileLoading(false);
                });
            case ModalTypes.UnsubscribeLink:
                return doPostRequest(downloadTrackLinkReportRequest.request, {
                    requestBody,
                }).then((res) => {
                    saveToDisk(
                        res,
                        type,
                        `${templateName}_${trackedLinks
                            .find((r) => r.TrackLinkId === trackLinkId)
                            ?.Url?.replace('/', '_')}`,
                    );
                    setIsFileLoading(false);
                });
            case ModalTypes.Click:
                return doPostRequest(downloadClickReportRequest.request, {
                    requestBody,
                }).then((res) => {
                    saveToDisk(res, type, `${templateName}_${ModalTypes.Click}`);
                    setIsFileLoading(false);
                });
            case ModalTypes.Open:
                return doPostRequest(downloadOpenReportRequest.request, {
                    requestBody,
                }).then((res) => {
                    saveToDisk(res, type, `${templateName}_${ModalTypes.Open}`);
                    setIsFileLoading(false);
                });
            default:
                return noop;
        }
    };

    const openCountOnclickHandler = (templateId) => {
        setSelectedModalType(ModalTypes.Open);
        initModal(getWorkflowOpenReportByCampaignTypeRequest.request, templateId);
    };

    const clickCountOnclickHandler = (templateId) => {
        setSelectedModalType(ModalTypes.Click);
        initModal(getWorkflowClickReportByCampaignType.request, templateId);
    };

    const clickCountLinkOnclickHandler = (templateId, modalType) => {
        setSelectedModalType(modalType);
        setTrackedLinks([]);
        const requestBody = getRequestBody(templateId);
        doPostRequest(getWorkflowTrackedLinksRequest.request, {
            requestBody,
            successCallback: (Data) => {
                setTrackedLinks(Data);
            },
        }).then(() => {
            setIsLoadedModal(false);
        });
    };

    const initModal = (request = null, templateId) => {
        if (!isNil(request)) {
            setPlayersData([]);

            const requestBody = getRequestBody(templateId);
            doPostRequest(request, {
                requestBody,
                successCallback: (Data) => {
                    setPlayersData({ Data });
                    setIsDisableDownload(isEmpty(Data));
                },
            }).then(() => {
                setIsLoadedModal(false);
            });
        }
    };

    const getRequestBody = (templateId) => {
        setIsLoadedModal(true);
        setIsVisible(true);
        setSelectedTemplateId(templateId);
        return {
            CampaignId: workflowId,
            CampaignType: CampaignType.WorkFlow,
            TemplateId: templateId,
        };
    };

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getWorkflowClickReportByCampaignType.cancel(
                'CustomerJourneyProgressData:getWorkflowClickReportByCampaignType',
            );
            getWorkflowOpenReportByCampaignTypeRequest.cancel(
                'CustomerJourneyProgressData:getWorkflowGeneralReportRequest',
            );
            getWorkflowTrackedLinksRequest.cancel('CustomerJourneyProgressData:getTrackedLinksRequest');
            getWorkflowTrackLinkReportByIdRequest.cancel('CustomerJourneyProgressData:getTrackLinkReportByIdRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <>
            <Modal
                title={t(l[ModalTitleLabels[selectedModalType]])}
                visible={isVisible}
                withPortal={true}
                closable={false}
                className="clicked-tracked-modal c-tracked-modal-wrapper"
                footer={getFooter()}
                onCancel={onCloseHandler}
            >
                {getContent()}
            </Modal>
            <div className="live-monitoring-table-wrp">
                <div className="live-monitoring-table-header">
                    <div>{t(l.TemplateName)}</div>
                    <div>{t(l.TrackedLink)}</div>
                    <div>{t(l.Open)}</div>
                    <div>{t(l.Click)}</div>
                    <div>{t(l.ClickOnUnsubscribeLink)}</div>
                </div>

                <div className="live-monitoring-table-content">
                    <BusyLoader isBusy={isLoadingReport}>
                        {!isNil(reportData) &&
                            reportData.map((i) => (
                                <div className="live-monitoring-table-content-inner" key={i.TemplateId}>
                                    <div className="live-monitoring-t-row">
                                        <p>{i.TemplateName}</p>
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        {i.TrackLink > 0 ? (
                                            <button
                                                onClick={() =>
                                                    clickCountLinkOnclickHandler(i.TemplateId, ModalTypes.TrackedLink)
                                                }
                                            >
                                                {i.TrackLink}
                                            </button>
                                        ) : (
                                            <p>{i.TrackLink ?? t(l.NA)}</p>
                                        )}
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        {i.Open > 0 ? (
                                            <button onClick={() => openCountOnclickHandler(i.TemplateId)}>
                                                {i.Open}
                                            </button>
                                        ) : (
                                            <p>{i.Open ?? t(l.NA)}</p>
                                        )}
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        {i.Click > 0 ? (
                                            <button onClick={() => clickCountOnclickHandler(i.TemplateId)}>
                                                {i.Click}
                                            </button>
                                        ) : (
                                            <p>{i.Click ?? t(l.NA)}</p>
                                        )}
                                    </div>
                                    <div className="live-monitoring-t-row">
                                        {i.Unsubscribed > 0 ? (
                                            <button
                                                onClick={() =>
                                                    clickCountLinkOnclickHandler(
                                                        i.TemplateId,
                                                        ModalTypes.UnsubscribeLink,
                                                    )
                                                }
                                            >
                                                {i.Unsubscribed}
                                            </button>
                                        ) : (
                                            <p>{i.Unsubscribed ?? t(l.NA)}</p>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </BusyLoader>
                </div>
            </div>
        </>
    );
};

CustomerJourneyProgressData.propTypes = {
    workflowId: PropTypes.string,
    isLoadingReport: PropTypes.bool,
    reportData: PropTypes.array,
};

CustomerJourneyProgressData.defaultProps = {
    reportData: [],
};

export default memo(CustomerJourneyProgressData);
