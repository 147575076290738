import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Button } from '@geneui/components';
// Import Constants
import { NotActiveImage } from 'constants/utils';
import { l } from 'constants/common';

const NotActive = () => {
    const { t } = useTranslation();

    const buttonClickHandler = () => {
        window.location.href = process.env.REACT_APP_BME_URL;
    };

    return (
        <div className="crm-not-active">
            <img alt="" src={NotActiveImage.path} />
            <p>{t(l.CRMIsNotActiveTittle)}</p>
            <p>{t(l.CRMIsNotActiveDescription)}</p>
            <Button appearance="outline" color="default" onClick={buttonClickHandler}>
                {t(l.GoToBME)}
            </Button>
        </div>
    );
};

export default memo(NotActive);
