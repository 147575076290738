import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'hooks';
// Import UI Components
import { Modal, Icon, Button, SkeletonLoader, Alert } from '@geneui/components';
import { ButtonWithLoader } from 'components';
// Import Constants
import { l, RealtimePromotionTypes } from 'constants/common';
import { CustomerJourneyHttpService } from 'services/http';

const ConfirmationModalWithWarning = ({
    onOk,
    onCancel,
    titleText,
    questionLabel,
    isLoading,
    iconType,
    actionLabel,
    promotionType,
    cancelLabel,
}) => {
    const { doGetRequest } = useRequest();
    const { t } = useTranslation();

    const [isOkActive, setIsOkActive] = useState(false);
    const [isLoadingLimit, setIsLoadingLimit] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const { getCustomerJourneyLimits } = useMemo(
        () => ({
            getCustomerJourneyLimits: CustomerJourneyHttpService.getCustomerJourneyLimits(),
        }),
        [],
    );

    useEffect(() => {
        setIsLoadingLimit(true);
        doGetRequest(getCustomerJourneyLimits.request, {
            successCallback: (data) => {
                let limit, count;

                if (promotionType === +RealtimePromotionTypes.ActionCampaign) {
                    limit = data.InstantWorkflowLimit;
                    count = data.InstantWorkflowCount;
                } else if (promotionType === +RealtimePromotionTypes.CustomerJourney) {
                    limit = data.WorkflowLimit;
                    count = data.WorkflowCount;
                }
                setIsOkActive(count < limit);
                const alert = t(l.CustomerJourneyLimitWarningMessage, {
                    limit: limit.toString(),
                    count: count.toString(),
                });
                setAlertMessage(alert);
                setIsLoadingLimit(false);
            },
        });
    }, [doGetRequest, getCustomerJourneyLimits.request, promotionType, t]);

    return (
        <Modal
            background="dark-background"
            position="top"
            title={titleText}
            size="content-size"
            className="crm-modal"
            appearance="compact"
            closable={false}
            closeOnClickOutside={true}
            visible={true}
            isOkActive={isOkActive}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {cancelLabel}
                    </Button>
                    <ButtonWithLoader color="primary" onClick={onOk} isLoading={isLoading} isDisabled={!isOkActive}>
                        {actionLabel}
                    </ButtonWithLoader>
                </>
            }
        >
            <div className="confirmation-modal-warning-icon-container">
                <div className="crm-circle-icon-cnt">
                    <Icon type={iconType} />
                </div>
                <div className="crm-allowd-not-allowd-cnt">{questionLabel}</div>
            </div>
            <SkeletonLoader isBusy={isLoadingLimit}>
                <Alert
                    className="pre-save-warning-alert"
                    closable="false"
                    title={t(l.PreSaveModalWarningMessage)}
                    message={alertMessage}
                    type="warning"
                    iconProps={{ type: 'bc-icon-info' }}
                />
            </SkeletonLoader>
        </Modal>
    );
};

ConfirmationModalWithWarning.propTypes = {
    titleText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    iconType: PropTypes.string,
    actionLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string,
    questionLabel: PropTypes.string,
    promotionType: PropTypes.number,
};

export default memo(ConfirmationModalWithWarning);
