import React, { memo, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNode } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { find, isArray, isNil, noop } from 'lodash';
import { yupSchemaValidate, getReferencePropertyErrorMessage } from 'validators/service.validator';
import { CJBlockNameOptionValidationSchema } from 'validators/schemas.validation';
// Import Components
import { OptionList } from 'components';
// Import Services
import { getCustomerJourneyBlockTranslatableErrorText } from 'services/customerJourney';
// Import Constants
import { CustomerJourneyGroupBlockTypes } from 'constants/common';

const CJBlockName = ({ defaultValue, getUpdate, option, node, elements, collectedProperties }) => {
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const { CustomAttributes } = option;

    const supportedValues = useMemo(
        () => CustomAttributes?.SupportedValues?.map((value) => CustomerJourneyGroupBlockTypes[value]) ?? [],
        [CustomAttributes],
    );

    const dataList = useRef();
    const [dataSearchedList, setSearchedDataList] = useState([]);

    const [searchValue, setSearchValue] = useState('');

    const [selectedBlockData, setSelectedBlockData] = useState({ id: defaultValue?.value });
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);

    const selectedValueValidation = (value) => {
        const schema = CJBlockNameOptionValidationSchema(elements, node);
        const tmpValidationResult = yupSchemaValidate(schema, { value }, (e) => {
            setErrorMessages([getReferencePropertyErrorMessage(e)]);
        });

        setIsValid(() => tmpValidationResult);
    };

    const selectBlockHandler = (data) => {
        setSelectedBlockData(data);
        setIsVisiblePopover(false);
    };

    const searchBlockHandler = ({ target }) => {
        setSearchValue(target.value);
        setSearchedDataList(() => dataList.current.filter((item) => item.name.includes(target.value)));
    };

    const popoverToggler = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    useEffect(() => {
        const listData = (
            !isNil(collectedProperties) && isArray(collectedProperties)
                ? collectedProperties.map(({ id, name }) => ({ id, name })) // map is only to clean object because collected properties has more state
                : []
        ).filter(({ id }) => supportedValues.includes(elements.find((el) => el.id === id)?.type));

        dataList.current = listData;
        setSearchedDataList(listData);
    }, [collectedProperties, elements, supportedValues]);

    useEffect(() => {
        getUpdate({ isValid: isValid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    useEffect(() => {
        getUpdate({ value: selectedBlockData?.id });
        selectedValueValidation(selectedBlockData?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBlockData]);

    useEffect(() => {
        const selectedBlock = find(elements, (el) => isNode(el) && !isNil(el?.id) && el?.id === defaultValue?.value);
        if (!isNil(selectedBlock)) {
            setSelectedBlockData({ id: selectedBlock?.id, name: selectedBlock?.data?.name });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <OptionList
            optionLabel={selectedBlockData?.name}
            toggleHandler={popoverToggler}
            isVisible={isVisiblePopover}
            setIsVisible={setIsVisiblePopover}
            keys={['name']}
            titles={[t(CustomAttributes?.DisplayName)]}
            selectedPropertyKey="id"
            list={dataSearchedList}
            rowCount={dataSearchedList?.length}
            searchHandler={searchBlockHandler}
            optionValue={selectedBlockData?.id}
            optionSelectHandler={selectBlockHandler}
            contentTop={45}
            contentWrapperClassName="cj-option-list-content-wrapper"
            searchValue={searchValue}
            isLoading={false}
            isLoadingList={false}
            isValid={isValid}
            errorText={(!isValid && getCustomerJourneyBlockTranslatableErrorText(t, errorMessages)) || ''}
        />
    );
};

CJBlockName.propTypes = {
    collectedProperties: PropTypes.array.isRequired,
    defaultValue: PropTypes.any.isRequired,
    option: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    getUpdate: PropTypes.func,
};

CJBlockName.defaultProps = {
    getUpdate: noop,
};

export default memo(CJBlockName);
