import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { SkeletonLoader, Tooltip, Icon } from '@geneui/components';
// Import Components
import { AccessControl } from 'components';
// Import Constants
import { l, PERMISSIONS } from 'constants/common';

const ActivityKPI = ({ isLoading, data, bonusCount, title }) => {
    const { t } = useTranslation();

    const { SendCount, OpenCount, ClickCount } = data;
    return (
        <div className="campaign-kpi">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name">{t(title)}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <div className="c-deep-view-block">
                <div className="c-deep-view-block-item color-pink">
                    <div className="c-deep-view-block-item-content">
                        <div className="c-deep-view-block-item-title-wrapper">
                            <p className="c-deep-view-block-item-title">{t(l.SendCount)}</p>
                        </div>
                        <SkeletonLoader isBusy={isLoading}>
                            <p className="c-deep-view-block-item-subtitle">{isNil(SendCount) ? l.NA : SendCount}</p>
                        </SkeletonLoader>
                    </div>
                </div>
                <div className="c-deep-view-block-item color-yellow">
                    <div className="c-deep-view-block-item-content">
                        <div className="c-deep-view-block-item-title-with-tooltip-wrapper">
                            <div className="c-deep-view-block-item-title-wrapper">
                                <p className="c-deep-view-block-item-title">{t(l.OpenCount)}</p>
                            </div>
                            <Tooltip text={t(l.OpenCountTooltipInfoMessage)}>
                                <Icon className="bc-icon-info-48" />
                            </Tooltip>
                        </div>
                        <SkeletonLoader isBusy={isLoading}>
                            <p className="c-deep-view-block-item-subtitle">{isNil(OpenCount) ? l.NA : OpenCount}</p>
                        </SkeletonLoader>
                    </div>
                </div>
                <div className="c-deep-view-block-item color-green">
                    <div className="c-deep-view-block-item-content">
                        <div className="c-deep-view-block-item-title-wrapper">
                            <p className="c-deep-view-block-item-title">{t(l.ClickedCount)}</p>
                        </div>
                        <SkeletonLoader isBusy={isLoading}>
                            <p className="c-deep-view-block-item-subtitle">{isNil(ClickCount) ? l.NA : ClickCount}</p>
                        </SkeletonLoader>
                    </div>
                </div>
                <AccessControl permissions={PERMISSIONS.ViewAwards}>
                    {!isNil(bonusCount) && (
                        <div className="c-deep-view-block-item color-blue">
                            <div className="c-deep-view-block-item-content">
                                <div className="c-deep-view-block-item-title-wrapper">
                                    <p className="c-deep-view-block-item-title">{t(l.BonusCount)}</p>
                                </div>
                                <SkeletonLoader isBusy={isLoading}>
                                    <p className="c-deep-view-block-item-subtitle">{bonusCount}</p>
                                </SkeletonLoader>
                            </div>
                        </div>
                    )}
                </AccessControl>
            </div>
        </div>
    );
};

ActivityKPI.propTypes = {
    data: PropTypes.object.isRequired,
    bonusCount: PropTypes.number,
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
};

ActivityKPI.defaultProps = {
    isLoading: false,
};

export default memo(ActivityKPI);
