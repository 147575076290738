import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
// Import actions
import { PageValidationAction } from 'actions';
// Import services
import { validationScenarios } from 'services';

const { setPageValidation, clearPageValidation, addPageValidationScenarioFields, removePageValidationScenarioFields } =
    PageValidationAction;

const useValidation = (pageName, setManually = false, pageType) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({});

    useEffect(init, []);

    function init() {
        !setManually && setValidationScenario();
    }

    function setValidationScenario(data) {
        setState({ ...state, ...data });
        dispatch(setPageValidation(validationScenarios[pageName].scenario(data, pageType)));
    }

    function addValidationProperties(data, editCloneMode, addData = state) {
        dispatch(
            addPageValidationScenarioFields(
                validationScenarios[pageName].propertiesCreator(data, editCloneMode, addData),
            ),
        );
    }

    function removeValidationProperties(fieldNames) {
        dispatch(removePageValidationScenarioFields(fieldNames));
    }

    function clearValidationScenario() {
        dispatch(clearPageValidation());
    }

    function resetValidationScenario() {
        dispatch(setPageValidation(validationScenarios[pageName].scenario(state)));
    }

    return {
        addValidationProperties,
        removeValidationProperties,
        clearValidationScenario,
        resetValidationScenario,
        setValidationScenario,
    };
};

export default useValidation;
