import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isUndefined, noop } from 'lodash';

const LabelTag = ({ color, labelName, icon, onClick }) => {
    const [labelTagColor, setLabelTagColor] = useState();
    const [labelBgColor, setLabelBgColor] = useState();

    const setColors = () => {
        const bgColor = color.includes('rgba') ? color.slice(0, color.length - 2) + '0.1)' : color + '17';

        setLabelTagColor(color);
        setLabelBgColor(bgColor);
    };

    useEffect(setColors, [color]);

    return (
        <p
            title={labelName}
            style={{ '--label-tag-color': labelTagColor, '--label-tag-bg-color': labelBgColor }}
            className="crm-label-tag c-l-tag"
        >
            <button onClick={onClick}>{labelName}</button>
            {!isUndefined(icon) && icon}
        </p>
    );
};

LabelTag.propTypes = {
    color: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    icon: PropTypes.node,
    onClick: PropTypes.func,
};

LabelTag.defaultProps = {
    onClick: noop,
};

export default memo(LabelTag);
