import React, { memo, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { CJMenuItemDropDownValidationSchema, CJListOptionValidationSchema } from 'validators/schemas.validation';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Components
import { MenuItemDropdown } from 'components';

// eslint-disable-next-line react/display-name
const CJMenuItemDropdown = forwardRef(
    (
        {
            defaultValue,
            getUpdate,
            option,
            isMultiselect,
            // TODO: remove this tmp solution after the fix of the issue
            shouldMoveToRight,
        },
        ref,
    ) => {
        const menuItemDropdownRef = useRef();

        useImperativeHandle(ref, () => ({
            reset() {
                menuItemDropdownRef.current.reset();
            },
        }));

        const {
            CustomAttributes: { MenuItemId, SupportedValues, DisplayName },
        } = option;

        const validationSchema = isMultiselect
            ? CJListOptionValidationSchema(option)
            : CJMenuItemDropDownValidationSchema(option);

        return (
            <MenuItemDropdown
                defaultValue={defaultValue}
                getUpdate={getUpdate}
                menuItemId={MenuItemId}
                isMultiselect={isMultiselect}
                displayName={DisplayName}
                supportedValues={SupportedValues}
                validationSchema={validationSchema}
                getTranslatableErrorText={getCustomerJourneyFormFieldTranslatableErrorText}
                className="crm-cj-menu-item-dropdown"
                ref={menuItemDropdownRef}
                // TODO: remove this tmp solution after the fix of the issue
                shouldMoveToRight={shouldMoveToRight}
            />
        );
    },
);

CJMenuItemDropdown.propTypes = {
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    defaultValue: PropTypes.object,
    isMultiselect: PropTypes.bool,
    // TODO: remove this tmp solution after the fix of the issue
    shouldMoveToRight: PropTypes.bool,
};

CJMenuItemDropdown.defaultProps = {
    isMultiselect: false,
    // TODO: remove this tmp solution after the fix of the issue
    shouldMoveToRight: false,
};

export default memo(CJMenuItemDropdown);
