import { SegmentListActionTypes as Types } from 'actions/types';
import { cloneDeep } from 'lodash';
import { segmentInitialState } from 'constants/listDefaultFilters';

export default (state = cloneDeep(segmentInitialState), action) => {
    switch (action.type) {
        case Types.RESET_SEGMENT_LIST_STATE:
            state = cloneDeep(segmentInitialState);
            return Object.assign({}, state, {});
        case Types.SET_SEGMENT_LIST_TABLE_FILTERS:
            state.tableData.filters = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER:
            state.tableData.paging.PageNumber = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE:
            state.tableData.paging.PageSize = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_SEGMENT_LIST_TABLE_SORTING:
            state.tableData.sorting = { ...action.payload };
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
