import React from 'react';
import PropTypes from 'prop-types';
import { isArray, isNil, noop } from 'lodash';
// Import UI Components
import { Tag } from '@geneui/components';
// Import Components
import CJFilterTag from './CJFilterTag';

const CJFilterGroupTag = ({ filterTag, index, isLoading, isViewMode, elements, removeTag }) => {
    const removeFilterTagHandler = (index) => {
        return (e) => {
            if (isArray(e)) {
                removeTag([index, ...e]);
            } else {
                removeTag([]);
            }
        };
    };

    return (
        <div className="crm-filter-tag-wrapper">
            {!isNil(filterTag) && (
                <>
                    {filterTag?.isGroupFilter === true ? (
                        <>
                            {filterTag.filtersTag.map((item, i) => (
                                <>
                                    <CJFilterGroupTag
                                        key={item.key}
                                        filterTag={item}
                                        index={i}
                                        elements={elements}
                                        removeTag={removeFilterTagHandler(i)}
                                        isLoading={isLoading}
                                        isViewMode={isViewMode}
                                    />

                                    {filterTag.filtersTag.length > 1 && i !== filterTag.filtersTag.length - 1 && (
                                        <Tag name={filterTag.logic} appearance="minimal" />
                                    )}
                                </>
                            ))}
                        </>
                    ) : (
                        <CJFilterTag
                            key={filterTag?.key}
                            label={filterTag?.label}
                            stringBuilder={filterTag?.stringBuilder}
                            operator={filterTag?.operator}
                            customAttributes={filterTag?.CustomAttributes}
                            optionType={filterTag?.optionType}
                            index={index}
                            rightValue={filterTag?.rightValue}
                            elements={elements}
                            removeTag={removeFilterTagHandler(null)}
                            isLoading={isLoading}
                            isViewMode={isViewMode}
                        />
                    )}
                </>
            )}
        </div>
    );
};

CJFilterGroupTag.propTypes = {
    elements: PropTypes.array.isRequired,
    filterTag: PropTypes.object,
    isLoading: PropTypes.bool,
    isViewMode: PropTypes.bool,
    removeTag: PropTypes.func,
    index: PropTypes.number,
};

CJFilterGroupTag.defaultProps = {
    filterTag: null,
    index: null,
    isLoading: true,
    isViewMode: false,
    removeTag: noop,
};

export default CJFilterGroupTag;
