import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useUserConfirmation } from 'hooks';

const BrowserRouterWrapper = ({ children }) => {
    const { setConfirmationCallback } = useUserConfirmation();

    const getConfirmation = (_message, callback) => {
        setConfirmationCallback(callback);
    };

    return <BrowserRouter getUserConfirmation={getConfirmation}>{children}</BrowserRouter>;
};

export default BrowserRouterWrapper;
