import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { values } from 'lodash';
//Import UI Components
import { RadioGroup, Icon } from '@geneui/components';
//Import Constants
import { DisplayModeTypes, ModeTabInfos } from 'constants/common';

/**
 * @component
 * @property {string}  activeTab - The active tab key.
 */
const DisplayModeTabs = ({ activeTab, setActiveTab }) => {
    const { t } = useTranslation();

    return (
        <RadioGroup
            name="controlled"
            options={values(ModeTabInfos).map((item) => {
                return {
                    value: item.type,
                    label: (
                        <div className="controlled-icon-block">
                            <Icon type={item.iconType} />
                            {t(item.titleKey)}
                        </div>
                    ),
                };
            })}
            type="tab"
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
        />
    );
};

DisplayModeTabs.propTypes = {
    setActiveTab: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
};

DisplayModeTabs.defaultProps = {
    activeTab: DisplayModeTypes.LIGHT_MODE,
};

export default memo(DisplayModeTabs);
