import { DataTableColumnsCustomTypes, SortDirectionTypes } from 'constants/common';

const CustomTypes = {
    [DataTableColumnsCustomTypes.Date]: {
        sortFn: (first, second, column, direction) => {
            const fistDate = new Date(first.data[column]);
            const secondDate = new Date(second.data[column]);

            if (fistDate === secondDate) {
                return 0;
            }

            if (direction === SortDirectionTypes.ASC) {
                return fistDate > secondDate ? 1 : -1;
            } else if (direction === SortDirectionTypes.DESC) {
                return fistDate > secondDate ? -1 : 1;
            }
            return 0;
        },
    },
};

export { CustomTypes };
