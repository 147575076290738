import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Constants
import { l } from 'constants/common';

const TelegramPreview = ({ text, mediaUrl, buttonText, buttonUrl }) => {
    const { t } = useTranslation();

    return (
        <div>
            {mediaUrl && (
                <div style={{ display: 'flex', 'align-items': 'center', width: '100%', 'margin-top': '12px' }}>
                    <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.MediaUrl)}</h4>
                    <p style={{ margin: 0 }}>{t(l.Yes)}</p>
                </div>
            )}
            {buttonText && (
                <div style={{ display: 'flex', 'align-items': 'center', width: '100%', 'margin-top': '12px' }}>
                    <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.ButtonText)}</h4>
                    <p style={{ margin: 0 }}>{buttonText}</p>
                </div>
            )}
            {buttonUrl && (
                <div style={{ display: 'flex', 'align-items': 'center', width: '100%', 'margin-top': '12px' }}>
                    <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.ButtonUrl)}</h4>
                    <p style={{ margin: 0 }}>{buttonUrl}</p>
                </div>
            )}
            <div style={{ display: 'flex', 'align-items': 'top', width: '100%', 'margin-top': '12px' }}>
                <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.Text)}</h4>
                <p style={{ margin: 0 }}>{text}</p>
            </div>
        </div>
    );
};

TelegramPreview.propTypes = {
    text: PropTypes.string.isRequired,
    mediaUrl: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
};

export default memo(TelegramPreview);
