import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Import Actions
import { HeaderAction } from 'actions';
// Import UI Components
import { Option, Icon } from '@geneui/components';
// Import Components
import UserProfileSettingsModal from './UserProfileSettingsModal';
// Import Constants
import { UserProfilePopoverViews } from './constants';
import { LanguagesFlagsPaths } from 'constants/utils';
import { l } from 'constants/common';
// Import SCSS
import 'assets/scss/settingsPopup.scss';

const UserProfile = ({
    signOutHandler,
    changeLanguageHandler,
    saveSettings,
    settingsModalVisibleState,
    setSettingsModalVisibleState,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setLanguagesList } = HeaderAction;
    const { mainView, languageView } = UserProfilePopoverViews;

    const { languages } = useSelector((state) => state.header);

    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [userProfilePopoverView, setUserProfilePopoverView] = useState(mainView);
    // const [settingsModalVisibleState, setSettingsModalVisibleState] = useState(false);

    const findSelectedLanguage = () => {
        let language = languages.filter((lng) => lng.IsSelected === true);
        if (language.length) {
            setSelectedLanguage(language[0]);
        }
    };

    const handleSave = (newSettings, isOnlyModeChanged) => {
        return saveSettings({ ...newSettings }, isOnlyModeChanged);
    };

    const selectLanguage = (langId) => {
        const languagesList = languages.map(({ IsSelected, Id, ...rest }) => ({
            IsSelected: Id === langId,
            Id,
            ...rest,
        }));

        changeLanguageHandler(langId);
        dispatch(setLanguagesList(languagesList));
        setUserProfilePopoverView(mainView);
    };

    const mainViewConfig = [
        {
            title: t(l.Settings),
            onClick: () => {
                setSettingsModalVisibleState(!settingsModalVisibleState);
            },
        },
        {
            title: `${t(l.Language)} : ${selectedLanguage.DisplayName}`,
            onClick: () => setUserProfilePopoverView(languageView),
        },
        {
            title: t(l.LogOut),
            onClick: signOutHandler,
        },
    ];

    useEffect(findSelectedLanguage, [languages]);

    const mainViewContent = mainViewConfig.map(({ title, onClick }, index) => {
        const rightContent = index === 1 ? <Icon type="bc-icon-arrow-right" /> : null;

        return <Option key={title} title={title} onClick={onClick} rightCustomElement={rightContent} border="bottom" />;
    });

    const languageViewContent = languages.map(({ DisplayName, Id, IsSelected }) => {
        const leftContent = IsSelected ? <Icon className="bc-icon-check" /> : null;

        return (
            <Option
                key={Id}
                title={DisplayName}
                active={IsSelected}
                onClick={() => selectLanguage(Id)}
                leftCustomElement={<img className="crm-lng-img" src={LanguagesFlagsPaths[Id]} alt={DisplayName} />}
                rightCustomElement={leftContent}
            />
        );
    });

    return (
        <>
            <div className="crm-user-profile-cnt">
                {userProfilePopoverView === languageView && (
                    <Option
                        title={t(l.ChooseLanguage)}
                        border="bottom"
                        onClick={() => setUserProfilePopoverView(mainView)}
                        leftCustomElement={<Icon type="bc-icon-arrow-left" />}
                    />
                )}

                {userProfilePopoverView === mainView ? mainViewContent : languageViewContent}
            </div>
            {settingsModalVisibleState && (
                <UserProfileSettingsModal closeModal={setSettingsModalVisibleState} saveHandler={handleSave} />
            )}
        </>
    );
};

UserProfile.propTypes = {
    signOutHandler: PropTypes.func.isRequired,
    changeLanguageHandler: PropTypes.func.isRequired,
    saveSettings: PropTypes.func.isRequired,
    settingsModalVisibleState: PropTypes.bool.isRequired,
    setSettingsModalVisibleState: PropTypes.func.isRequired,
};

export default memo(UserProfile);
