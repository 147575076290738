import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Import UI components
import { Tooltip } from '@geneui/components';
// Import constants
import { TimeZonePack } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMoment } = Helpers;

const UserTime = () => {
    const { settings, timeFormat } = useSelector((state) => state.header);
    const { ActivePartner } = settings;
    const [time, setTime] = useState(customMoment().format(timeFormat));

    useEffect(() => {
        const clearIntervalToken = setInterval(() => setTime(customMoment().format(timeFormat)), 1000);
        return () => clearInterval(clearIntervalToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tooltip style={{ marginTop: '11px' }} text={TimeZonePack[ActivePartner.TimeZone]} position="bottom">
            <p>{time}</p>
        </Tooltip>
    );
};

export default UserTime;
