import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Button, Icon } from '@geneui/components';
// Import constants
import { l, SegmentTypesLabels } from 'constants/common';

const SegmentItemView = ({ type, title, isClearable, onClear }) => {
    const { t } = useTranslation();

    return (
        <div className="drop-here">
            {type === SegmentTypesLabels.Dynamic && <Icon type="bc-icon-dynamic crm-segment-type-icon dynamic" />}
            {type === SegmentTypesLabels.Static && <Icon type="bc-icon-static crm-segment-type-icon static" />}
            {type === SegmentTypesLabels.Composite && <Icon type="bc-icon-composite crm-segment-type-icon composite" />}

            <div>
                <div className="drop-here-title">
                    <span>
                        {t(l.Title)}
                        {':'}
                    </span>
                    <p>{title}</p>
                </div>

                <div className="drop-here-title">
                    <span>
                        {t(l.Type)}
                        {':'}
                    </span>
                    <p>{t(SegmentTypesLabels[type])}</p>
                </div>
                {isClearable && <Button color="primary" appearance="minimal" icon="bc-icon-clear" onClick={onClear} />}
            </div>
        </div>
    );
};

SegmentItemView.propTypes = {
    type: PropTypes.number,
    title: PropTypes.string,
    isClearable: PropTypes.bool,
    onClear: PropTypes.func,
};

SegmentItemView.defaultProps = {
    isClearable: false,
    onClear: noop,
};

export default memo(SegmentItemView);
