import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEqual, noop } from 'lodash';
// Import components
import DataTable from './DataTable';
// Import UI components
import { Modal, Button } from '@geneui/components';
//Import constants
import { ModalsActions, l } from 'constants/common';

const SelectedItemsModal = ({ rows, columns, handleClose, handleSave, isVisible, rowKey, columnKey }) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        setData(cloneDeep(rows));
        setSelectedRows(cloneDeep(rows));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSelectedRowsList = (selectedRows) => {
        setSelectedRows(selectedRows);
    };

    const closeHandler = () => {
        handleClose(ModalsActions.CLOSE);
    };

    const saveHandler = () => {
        handleSave(selectedRows);
        handleClose();
    };

    const compareRows = () => {
        return isEqual(selectedRows.map((i) => i[rowKey]).sort(), data.map((i) => i[rowKey]).sort());
    };

    return (
        <Modal
            visible={isVisible}
            closeOnClickOutside={true}
            className="crm-data-table-selected-items-modal"
            background="dark-background"
            title={t(l.SelectedItems)}
            position="center"
            size="content-size"
            closable={false}
            onCancel={closeHandler}
            footer={
                <>
                    <Button appearance="minimal" onClick={closeHandler}>
                        {t(l.Close)}
                    </Button>
                    <Button onClick={saveHandler} disabled={compareRows()}>
                        {t(l.ApplyChanges)}
                    </Button>
                </>
            }
        >
            {isVisible && (
                <DataTable
                    columnKey={columnKey}
                    rowKey={rowKey}
                    name={t(l.SelectedItems)}
                    data={data}
                    dataCount={data.length}
                    columns={columns}
                    withPagination={false}
                    withPageSelector={false}
                    isHasRowMultiSelect={true}
                    isLoading={false}
                    getSelectedRowsList={getSelectedRowsList}
                    defaultMultiSelectedRows={rows}
                    isVisibleOnlyDataTable={true}
                />
            )}
        </Modal>
    );
};

SelectedItemsModal.propTypes = {
    rows: PropTypes.array,
    columns: PropTypes.array,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
    isVisible: PropTypes.bool,
    rowKey: PropTypes.string,
    columnKey: PropTypes.string,
};

SelectedItemsModal.defaultProps = {
    handleClose: noop,
    handleSave: noop,
};

export default memo(SelectedItemsModal);
