import React, { memo, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, noop, values } from 'lodash';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { Helpers } from 'services';
import { ProviderHttpService } from 'services/http';
// Import UI Components
import { Alert, Button } from '@geneui/components';
//Import Components
import { ConfirmationModal, KeyValueItem, UploadFile } from 'components';
//Import Constants
import { l, AlertTypes, ModalsClassNames, CommonIcons, PushNotificationProviderKeys } from 'constants/common';

const FireBaseSettingsKeys = [
    'type',
    'project_id',
    'private_key_id',
    'private_key',
    'client_email',
    'client_id',
    'auth_uri',
    'token_uri',
    'auth_provider_x509_cert_url',
    'client_x509_cert_url',
    'universe_domain',
];

const { readTextFileAsync } = Helpers;
const { success, error } = AlertTypes;

const FireBaseSettingsContent = ({ isDefault, isLoading, initialData, onRefetchData, onSetDefault }) => {
    const { t } = useTranslation();

    const { doPostRequest } = useRequest();
    const { showToaster } = useToaster();

    const fileInputRef = useRef(null);

    const [selectedFileData, setSelectedFileData] = useState(null);

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isClearModalOpen, setIsClearModalOpen] = useState(false);

    const [isClearSettingsLoading, setIsClearSettingsLoading] = useState(false);
    const [isUploadSettingsLoading, setIsUploadSettingsLoading] = useState(false);

    const isInitialDataEmpty = values(initialData).every((value) => isNil(value));

    const { setFirebaseKeyRequest, removeFirebaseKeyRequest } = useMemo(
        () => ({
            setFirebaseKeyRequest: ProviderHttpService.setFirebaseKey(),
            removeFirebaseKeyRequest: ProviderHttpService.removeFirebaseKey(),
        }),
        [],
    );

    const previewableSettingsFromSelectedFile = useMemo(() => {
        const parsedData = JSON.parse(selectedFileData) ?? {};

        return {
            projectId: parsedData.project_id,
            privateKeyId: parsedData.private_key_id,
            clientId: parsedData.client_id,
            clientEmail: parsedData.client_email,
        };
    }, [selectedFileData]);

    const cancelUploadModal = useCallback(() => setIsUploadModalOpen(false), []);
    const cancelClearModal = useCallback(() => setIsClearModalOpen(false), []);
    const onClear = useCallback(() => setIsClearModalOpen(true), []);

    const checkValidation = (data) => {
        const keys = Object.keys(data);
        const values = Object.values(data);

        if (data.type !== 'service_account') return false;
        if (FireBaseSettingsKeys.some((key) => !keys.includes(key))) return false;
        if (values.some((value) => isEmpty(value))) return false;

        return true;
    };

    const onFileInputChange = (e) => {
        const { files } = e.currentTarget;

        readTextFileAsync(files[0])
            .then((fileData) => {
                if (checkValidation(JSON.parse(fileData))) {
                    setSelectedFileData(fileData);
                    setIsUploadModalOpen(true);
                } else {
                    showToaster(error, t(l.FirebaseSettingsFileIsInvalid));
                }
            })
            .catch(() => showToaster(error, t(l.FirebaseSettingsFileIsInvalid)));

        fileInputRef.current.value = null;
    };

    const onSetDefaultHandler = () => {
        onSetDefault(PushNotificationProviderKeys.FireBase);
    };

    const updateSettings = () => {
        setIsUploadSettingsLoading(true);

        doPostRequest(setFirebaseKeyRequest.request, {
            requestBody: JSON.stringify(selectedFileData),
            successCallback: () => {
                onRefetchData();
                setIsUploadModalOpen(false);
                showToaster(success, t(l.FirebaseSettingsUploadedSuccessfully));
            },
        }).finally(() => {
            setIsUploadSettingsLoading(false);
        });
    };

    const clearSettings = () => {
        setIsClearSettingsLoading(true);

        doPostRequest(removeFirebaseKeyRequest.request, {
            successCallback: () => {
                onRefetchData();
                setIsClearModalOpen(false);
                showToaster(success, t(l.FirebaseSettingsClearedSuccessfully));
            },
        }).finally(() => {
            setIsClearSettingsLoading(false);
        });
    };

    const cleanUp = () => {
        return () => {
            setFirebaseKeyRequest.cancel('FireBaseSettingsContent:setFirebaseKeyRequest');
            removeFirebaseKeyRequest.cancel('FireBaseSettingsContent:removeFirebaseKeyRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <div className="crm-push-notification-providers-settings-content">
            <Alert closable={false} title={t(l.FirebaseDescription)} iconProps={{ type: 'bc-icon-info' }} />
            <div className="fields-btn-group">
                <KeyValueItem
                    label={l.ProjectId}
                    value={initialData?.projectId}
                    vertical={true}
                    isLoading={isLoading}
                />
                <KeyValueItem
                    label={l.PrivateKeyId}
                    value={initialData?.privateKeyId}
                    vertical={true}
                    isLoading={isLoading}
                />
                <KeyValueItem label={l.ClientId} value={initialData?.clientId} vertical={true} isLoading={isLoading} />
                <div className="push-notification-settings-content-action-buttons-container">
                    <Button
                        appearance="outline"
                        onClick={onSetDefaultHandler}
                        disabled={isDefault || isInitialDataEmpty}
                    >
                        {t(l.SetDefault)}
                    </Button>
                    <Button
                        appearance="outline"
                        onClick={onClear}
                        disabled={isClearSettingsLoading || isEmpty(initialData) || isLoading}
                    >
                        {t(l.Clear)}
                    </Button>
                    <UploadFile
                        onChange={onFileInputChange}
                        disabled={isUploadSettingsLoading || isLoading}
                        inputRef={fileInputRef}
                        buttonTextKey={l.Upload}
                        showOnlyButton={true}
                        acceptedExtensions={'.json'}
                    />
                </div>
            </div>
            {isClearModalOpen && (
                <ConfirmationModal
                    onOk={clearSettings}
                    onCancel={cancelClearModal}
                    isVisibleModal={isClearModalOpen}
                    titleText={t(l.ClearSettings)}
                    questionLabel={t(l.ClearFirebaseSettingsDescription)}
                    iconType={CommonIcons.Info}
                    actionLabel={t(l.Continue)}
                    className={ModalsClassNames.UsedIn}
                    isLoading={isClearSettingsLoading}
                />
            )}
            {isUploadModalOpen && (
                <ConfirmationModal
                    onOk={updateSettings}
                    onCancel={cancelUploadModal}
                    isVisibleModal={isUploadModalOpen}
                    titleText={t(l.UploadSettings)}
                    questionLabel={t(l.UploadFirebaseSettingsDescription, {
                        projectId: `"${previewableSettingsFromSelectedFile.projectId}"`,
                        privateKeyId: `"${previewableSettingsFromSelectedFile.privateKeyId}"`,
                        clientId: `"${previewableSettingsFromSelectedFile.clientId}"`,
                        clientEmail: `"${previewableSettingsFromSelectedFile.clientEmail}"`,
                    })}
                    iconType={CommonIcons.Info}
                    actionLabel={t(l.Continue)}
                    className={ModalsClassNames.Warning}
                    isLoading={isUploadSettingsLoading}
                />
            )}
        </div>
    );
};

FireBaseSettingsContent.propTypes = {
    isDefault: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    initialData: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        privateKeyId: PropTypes.string.isRequired,
        clientId: PropTypes.string.isRequired,
        clientEmail: PropTypes.string.isRequired,
    }),
    onRefetchData: PropTypes.func.isRequired,
    onSetDefault: PropTypes.func.isRequired,
};

FireBaseSettingsContent.defaultProps = {
    isDefault: false,
    isLoading: false,
    onRefetchData: noop,
    onSetDefault: noop,
};

export default memo(FireBaseSettingsContent);
