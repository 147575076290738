import React, { memo, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import BonusInfoModalBody from 'components/BonusModal/BonusInfoModalBody';
// Import UI components
import { Modal, Button, BusyLoader } from '@geneui/components';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { BonusHttpService } from 'services/http';
// Import Constants
import { l } from 'constants/common';

const AwardIdModal = ({ awardId, isVisible, handleClose }) => {
    const { t } = useTranslation();

    const { doGetRequest } = useRequest();

    const { getBonusById } = useMemo(
        () => ({
            getBonusById: BonusHttpService.getBonusById(),
        }),
        [],
    );
    const [bonusInfo, setBonusInfo] = useState(null);
    const [isLoadingBonus, setIsLoadingBonus] = useState(false);

    const getBonusInfo = () => {
        setIsLoadingBonus(true);
        doGetRequest(getBonusById.request, {
            queryString: { bonusId: awardId },
            successCallback: (data) => {
                setBonusInfo(data);
            },
        }).then(() => {
            setIsLoadingBonus(false);
        });
    };

    useEffect(() => {
        getBonusInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [awardId]);

    const cleanUp = () => {
        return () => {
            setBonusInfo(null);
            setIsLoadingBonus(false);
            getBonusById.cancel('AwardInfoModal:getBonusById');
        };
    };

    useEffect(cleanUp, [awardId]);

    return (
        <Modal
            withPortal={true}
            closeOnClickOutside={true}
            background="dark-background"
            position="center"
            visible={isVisible}
            closable={false}
            onCancel={handleClose}
            className="segment-list-modal"
            title={
                <div className="segment-list-modal-head">
                    <span className="segment-list-modal-h-text">{t(l.BonusInfo)}</span>
                    <div className="segment-list-modal-h-icons"></div>
                </div>
            }
            footer={
                <Button color="primary" onClick={handleClose}>
                    {t(l.Close)}
                </Button>
            }
        >
            <BusyLoader isBusy={isLoadingBonus} type="spinner" spinnerSize="medium">
                <BonusInfoModalBody
                    id={bonusInfo?.Id}
                    wallet={bonusInfo?.BalanceType}
                    title={bonusInfo?.Name}
                    description={bonusInfo?.Description}
                    startDate={bonusInfo?.StartDate}
                    endDate={bonusInfo?.EndDate}
                    type={bonusInfo?.Type}
                    bonusDetails={bonusInfo?.BonusDetails}
                />
            </BusyLoader>
        </Modal>
    );
};
AwardIdModal.propTypes = {
    awardId: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default memo(AwardIdModal);
