import React, { memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
// Import UI Components
import { Alert, Button, RadioGroup, Icon, Tooltip } from '@geneui/components';
// Import Components
import { DataTable } from 'components';
import CreateTag from './CreateTag';
// Import Constants
import { l, ManageTagActionList, ManageTagActionTypes } from 'constants/common';

const ManageTagList = ({
    action,
    tagsList,
    columns,
    selectedTags,
    isDisabledActions,
    isTagListLoading,
    onSelectedTagsChange,
    onCreateTag,
    onDeleteTag,
    onActionChange,
    alert,
}) => {
    const { t } = useTranslation();

    const dataTableRef = useRef();

    const manageTagActionTranslatedList = useMemo(
        () =>
            ManageTagActionList.map((item) => ({
                value: item.value,
                label: (
                    <>
                        <Icon type={item.icon} />
                        {t(item.label)}
                    </>
                ),
            })),
        [t],
    );

    const isRemoveTagAction = action === ManageTagActionTypes.RemoveTag;

    const getHeaderActions = (isRemoveAction) => {
        return isRemoveAction ? <></> : <CreateTag isCreateTagDisabled={isDisabledActions} onCreateTag={onCreateTag} />;
    };

    const getTableRows = () => {
        return tagsList.map(({ Name, ClientsCount, ClientsCountAll, TagId }) => ({
            hasHover: true,
            dragDisable: true,
            data: {
                index: TagId,
                TagId,
                Name,
                ClientsCount,
                ClientsCountAll,
            },
        }));
    };

    const rowActionBar = (row) => {
        return (
            <Button
                icon="bc-icon-delete-2"
                appearance="minimal"
                color="primary"
                onClick={() => onDeleteTag(row.data)}
            />
        );
    };

    const actionChangeHandler = (data) => {
        dataTableRef.current.resetSelectedRows();
        onActionChange(data);
    };

    return (
        <div className="manage-tag-container">
            <Alert
                className="pre-save-warning-alert"
                closable="false"
                type={alert.type}
                iconProps={{ type: 'bc-icon-info' }}
                title={alert.title}
            />
            <div className="action-container">
                <RadioGroup
                    name="controlled"
                    disabled={isDisabledActions}
                    options={manageTagActionTranslatedList}
                    type="tab"
                    value={action}
                    onChange={actionChangeHandler}
                />
                <Tooltip text={t(l.TooltipManageTagActions)} position="bottom">
                    <Icon type="bc-icon-info" onClick={noop} />
                </Tooltip>
            </div>
            <DataTable
                classNames="client-tag-data-table"
                rowKey="index"
                columnKey="dataKey"
                rowActionBar={rowActionBar}
                withPagination={false}
                withSearch={true}
                withPageSelector={false}
                isHasRowMultiSelect={true}
                isLoading={isTagListLoading}
                headerActions={getHeaderActions(isRemoveTagAction)}
                getSelectedRowsList={onSelectedTagsChange}
                data={getTableRows()}
                dataCount={tagsList.length}
                columns={columns}
                defaultMultiSelectedRows={selectedTags}
                ref={dataTableRef}
            />
        </div>
    );
};

ManageTagList.propTypes = {
    action: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    selectedTags: PropTypes.array.isRequired,
    tagsList: PropTypes.array.isRequired,
    alert: PropTypes.object.isRequired,
    isDisabledActions: PropTypes.bool.isRequired,
    isTagListLoading: PropTypes.bool,
    onSelectedTagsChange: PropTypes.func.isRequired,
    onCreateTag: PropTypes.func.isRequired,
    onDeleteTag: PropTypes.func.isRequired,
    onActionChange: PropTypes.func.isRequired,
};

ManageTagList.defaultProps = {
    isTagListLoading: false,
};

export default memo(ManageTagList);
