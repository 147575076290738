import { AdminProviderActionTypes as Types } from 'actions/types';
import { cloneDeep } from 'lodash';
import { adminProviderInitialState } from 'constants/listDefaultFilters';

export default (state = cloneDeep(adminProviderInitialState), action) => {
    switch (action.type) {
        case Types.RESET_ADMIN_PROVIDER_STATE:
            state = cloneDeep(adminProviderInitialState);
            return Object.assign({}, state, {});
        case Types.SET_ADMIN_PROVIDER_TABLE_FILTERS:
            state.tableData.filters = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_ADMIN_PROVIDER_TABLE_PAGE_NUMBER:
            state.tableData.paging.PageNumber = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_ADMIN_PROVIDER_TABLE_PAGE_SIZE:
            state.tableData.paging.PageSize = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_ADMIN_PROVIDER_TABLE_SORTING:
            state.tableData.sorting = { ...action.payload };
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
