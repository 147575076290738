export const objectTypesMap = {
    comparison: 'comparison:',
    filterComparisons: 'filterComparisons:',
    categoriesFilters: 'categoriesFilters:',
};

export const DefaultColumnsReport = [
    { Id: 76 },
    { Id: 75 },
    { Id: 74 },
    { Id: 73 },
    { Id: 53 },
    { Id: 42 },
    { Id: 40 },
    { Id: 38 },
];

export const hashTypeMap = {
    category: 'category',
    filter: 'filter',
    comparison: 'comparison',
};

export const exportList = [
    { label: 'CSV', value: 1 },
    { label: 'XLS', value: 2 },
];
