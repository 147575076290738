import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper, CJFilterGroupInfo } from 'components/CustomerJourney/infoBlocks';

const CJFilterConditionInfoBlock = ({ data, elements, option, blockId }) => {
    const { t } = useTranslation();
    return (
        <>
            {isNil(data?.filters) ? (
                <CJInfoBlockLabelValueWrapper label={t(l.OptionValue)} value={t(l.NA)} />
            ) : (
                <>
                    {data.filters.map((filter, index) => {
                        return (
                            <CJFilterGroupInfo
                                key={index}
                                data={filter}
                                elements={elements}
                                option={option}
                                blockId={blockId}
                                index={index}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

CJFilterConditionInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
};

export default CJFilterConditionInfoBlock;
