import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Constants
import {
    l,
    TimeZonePack,
    CommunicationAvailabilityScheduleTypeLabels,
    CommunicationAvailabilityScheduleTypes,
} from 'constants/common';
import { CJCommunicationAvailabilityConditionConfig } from 'components/CustomerJourney/config';

const CJCommunicationAvailabilityConditionInfoBlock = ({ data }) => {
    const { t } = useTranslation();

    const scheduleType = useMemo(() => {
        const tmpScheduleType = data?.scheduleType ?? CommunicationAvailabilityScheduleTypeLabels.Always;
        return CommunicationAvailabilityScheduleTypes.find((item) => item.value === tmpScheduleType);
    }, [data]);

    return (
        <>
            {scheduleType.value === CommunicationAvailabilityScheduleTypeLabels.Always ? (
                <CJInfoBlockLabelValueWrapper
                    label={t(l.CommunicationAvailabilityScheduleType)}
                    value={t(scheduleType.label)}
                />
            ) : (
                <>
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.CommunicationAvailabilityScheduleType)}
                        value={t(scheduleType.label)}
                    />
                    <CJInfoBlockLabelValueWrapper label={t(l.TimeZone)} value={TimeZonePack[data?.timeZone ?? 0]} />
                    {!isNil(data?.schedule) &&
                        CJCommunicationAvailabilityConditionConfig[scheduleType.value].map((item, index) => {
                            if (data.schedule[index].isEnable) {
                                return (
                                    <>
                                        <p className={'crm-cj-block-info-sidebar_option-name'}>{t(item.label)}</p>

                                        <CJInfoBlockLabelValueWrapper
                                            label={t(l.CommunicationAvailabilityStartTime)}
                                            value={data?.schedule[index]?.value?.startTime?.value}
                                        />
                                        <CJInfoBlockLabelValueWrapper
                                            label={t(l.CommunicationAvailabilityEndTime)}
                                            value={data?.schedule[index]?.value?.endTime?.value}
                                        />
                                    </>
                                );
                            }
                            return <></>;
                        })}
                </>
            )}
        </>
    );
};

CJCommunicationAvailabilityConditionInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJCommunicationAvailabilityConditionInfoBlock);
