// Import types
import { TemplateActionsTypes as Types } from 'actions/types';
// Import services
import { createTemplateInitialData } from 'services/template';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case Types.SET_TEMPLATE_INITIAL_DATA: {
            return Object.assign({}, { defaultLang: { ...payload } });
        }
        case Types.ADD_TEMPLATE_LIST: {
            payload.data.forEach(({ data }) => {
                state[data.Id] = createTemplateInitialData[payload.type](data.Id, payload?.template, {
                    isCascade: payload.isCascade,
                });
            });
            return Object.assign({}, state, {});
        }
        case Types.SET_TEMPLATE_AS_DEFAULT: {
            state[payload.targetId] = {
                ...state[payload.targetId],
                ...createTemplateInitialData[payload.type](null, payload.template),
            };
            return Object.assign({}, state, {});
        }
        case Types.SET_TEMPLATE_LIST: {
            return Object.assign({}, { ...payload });
        }
        case Types.CHANGE_TEMPLATE_VALUE: {
            const { propertyName, value, templateLangKey } = payload;

            if (state?.[templateLangKey]) {
                state[templateLangKey][propertyName] = value;
            }

            return Object.assign({}, state, {});
        }
        case Types.DELETE_TEMPLATE_LANGUAGE: {
            delete state[payload];

            return Object.assign({}, state, {});
        }
        case Types.CLEAR_TEMPLATE_LIST: {
            return Object.assign(
                {},
                {
                    defaultLang: {
                        ...state.defaultLang,
                        ...createTemplateInitialData[payload.templateType](null, null, {
                            isCascade: payload.isCascade,
                        }),
                    },
                },
            );
        }
        case Types.SET_EDITOR_CONFIG: {
            const { templateLangKey, editorKey, editorConfig } = payload;

            state[templateLangKey].UIConfig = {
                ...state[templateLangKey].UIConfig,
                [editorKey]: editorConfig,
            };

            return Object.assign({}, state);
        }
        default:
            return state;
    }
};
