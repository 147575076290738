import { CRMRequestService, postCancelableRequest } from './http.config';

const ClientTagHttpService = {
    getClientTagsList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ClientTag/Get',
        });
    },
    createClientTag: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ClientTag/Create',
        });
    },
    deleteClientTag: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ tagId }) => `/ClientTag/Delete?tagId=${tagId}`,
        });
    },
    uploadClientList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ClientTag/UploadFile',
        });
    },
    manageClientTags: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ClientTag/UpdateClientTags',
        });
    },
};

export default ClientTagHttpService;
