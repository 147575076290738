import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import Components
import { PlayersDataTable, DownloadOrSendToEmailToolbar } from 'components';
// Import Constants
import { l } from 'constants/common';
import { CampaignBonusReceivedPlayersTableConfig } from './config';

const CampaignBonusReceivedPlayers = ({
    isLoading,
    data,
    downloadAllClickHandler,
    sendToEmailClickHandler,
    isFileLoading,
}) => {
    const { t } = useTranslation();

    return (
        <div className="bonus-received-players">
            <div className="bonus-received-players-inner">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text">
                                <div className="crm-statics-segment-data-table-name">{t(l.BonusReceivedPlayers)}</div>
                            </div>
                        </div>
                    </li>
                    <li className="right-content">
                        <DownloadOrSendToEmailToolbar
                            downloadAction={downloadAllClickHandler}
                            sendToEmailAction={sendToEmailClickHandler}
                            isFileLoading={isFileLoading}
                            isDisabledDownload={isNil(data?.Count) || data?.Count === 0}
                        />
                    </li>
                </ul>
                <PlayersDataTable
                    key="CampaignExecuteId"
                    config={CampaignBonusReceivedPlayersTableConfig}
                    isLoading={isLoading}
                    data={data}
                />
            </div>
        </div>
    );
};

CampaignBonusReceivedPlayers.propTypes = {
    isLoading: PropTypes.bool,
    isFileLoading: PropTypes.bool,
    data: PropTypes.object,
    downloadAllClickHandler: PropTypes.func,
    sendToEmailClickHandler: PropTypes.func,
};

CampaignBonusReceivedPlayers.defaultProps = {
    isLoading: true,
    data: {},
};

export default memo(CampaignBonusReceivedPlayers);
