import { isEmpty, cloneDeep } from 'lodash';
import { DynamicSegmentActionsTypes as Types } from 'actions/types';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case Types.ADD_FILTER_CONTAINER_ITEM: {
            const { data, tab } = payload;
            return {
                ...state,
                [tab.id]: {
                    ...state[tab.id],
                    filters: {
                        ...state[tab.id]?.filters,
                        [data.parentHashKey]: [...(state[tab.id]?.filters[data.parentHashKey] ?? []), data],
                    },
                    id: tab.id,
                    name: tab.name,
                    seqId: tab.seqId,
                },
            };
        }
        case Types.ADD_FILTER_CONTAINER_LIST: {
            const newState = {};
            if (!payload && !isEmpty(isEmpty)) return state;
            Object.keys(payload).forEach((elem) => {
                const filterGroupKey = payload[elem].parentHashKey;
                if (newState[filterGroupKey]) {
                    newState[filterGroupKey] = [payload[elem], ...newState[filterGroupKey]];
                } else {
                    newState[filterGroupKey] = [payload[elem]];
                }
            });
            return newState;
        }
        case Types.SET_FILTER_LIST: {
            return { ...payload };
        }
        case Types.EDIT_FILTER_CONTAINER: {
            const { data, tab } = payload;
            const tmpState = cloneDeep(state);
            const editedState = tmpState[tab.id].filters[data.parentHashKey];
            const index = data.id;
            delete data.id;
            editedState[index] = data;
            tmpState[tab.id].filters[data.parentHashKey] = [...editedState];
            return { ...tmpState };
        }
        case Types.DELETE_FILTER_CONTAINER_ITEM: {
            const { parentHashKey, index, id } = payload;
            const tmpState = cloneDeep(state);
            tmpState[id].filters[parentHashKey] = tmpState[id].filters[parentHashKey].filter((_, i) => {
                return i !== index;
            });
            if (tmpState[id].filters[parentHashKey].length < 1) {
                delete tmpState[id].filters[parentHashKey];
                return { ...tmpState };
            }
            return { ...tmpState };
        }
        case Types.DELETE_FILTER_CONTAINER_LIST: {
            const { tabId, filterOrderIds, filterListHashKey } = payload;
            const tmpState = cloneDeep(state);

            tmpState[tabId].filters[filterListHashKey] = tmpState[tabId].filters[filterListHashKey].filter((_, i) => {
                return !filterOrderIds.includes(i);
            });
            if (isEmpty(tmpState[tabId].filters[filterListHashKey].length)) {
                delete tmpState[tabId].filters[filterListHashKey];
                return { ...tmpState };
            }
            return { ...tmpState };
        }
        case Types.DELETE_FILTER_SCHEMA_LIST: {
            const { tabId, parentHashKeys } = payload;
            const tmpState = cloneDeep(state);
            parentHashKeys.forEach((parentHashKey) => {
                delete tmpState[tabId].filters[parentHashKey];
            });
            return { ...tmpState };
        }
        case Types.CLEAR_FILTER_CONTAINER:
            return {};
        case Types.DELETE_SUB_SEGMENT: {
            delete state[payload];
            return { ...state };
        }
        case Types.SET_MAIN_SEGMENT_INITIAL_DATA: {
            return { ...payload };
        }
        case Types.ADD_SUB_SEGMENT: {
            return { ...state, ...payload };
        }
        case Types.CHANGE_SUB_SEGMENT_NAME: {
            const { value, id } = payload;
            const tmpState = cloneDeep(state);
            tmpState[id].name = value;
            return { ...tmpState };
        }

        default:
            return state;
    }
};
