import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { first, isEmpty, isNil } from 'lodash';
// Import Components
import CJMenuItemDropdown from './CJMenuItemDropDown';
import CJInteger from './CJInteger';

const CJList = ({ defaultValue, getUpdate, option }) => {
    const { CustomAttributes } = option;

    const getInitialValues = (initialValue) => {
        if (isNil(initialValue)) return [];

        return initialValue.map((el) => {
            return {
                value: el,
            };
        });
    };

    const CJIntegerValueChangeHandler = (value) => {
        if (isNil(value?.isValid)) {
            getUpdate({ value: [value?.value] });
        } else {
            getUpdate({ isValid: value?.isValid });
        }
    };

    if (!isNil(CustomAttributes?.MenuItemId)) {
        return (
            <CJMenuItemDropdown
                defaultValue={defaultValue}
                getUpdate={getUpdate}
                option={option}
                isMultiselect
                // TODO: remove this tmp solution after the fix of the issue
                shouldMoveToRight
            />
        );
    } else {
        const integerValue = getInitialValues(defaultValue?.value);

        return (
            <CJInteger
                defaultValue={{
                    value:
                        isNil(first(integerValue)) || isEmpty(first(integerValue)) ? null : +first(integerValue)?.value,
                }}
                getUpdate={CJIntegerValueChangeHandler}
                option={option}
            />
        );
    }
};

CJList.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJList.defaultProps = {
    defaultValue: { value: null },
};

export default memo(CJList);
