import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Modal, Icon, Button } from '@geneui/components';
import { ButtonWithLoader } from 'components';
// Import constants
import { l } from 'constants/common';

const StopScheduleReportModal = ({ isVisibleModal, titleText, confirmationText, onCancel, onOk, isLoading }) => {
    const { t } = useTranslation();

    return (
        <Modal
            background="dark-background"
            position="top"
            title={titleText}
            appearance="compact"
            size="content-size"
            closable={false}
            className="crm-modal crm-stop-modal"
            onCancel={onCancel}
            closeOnClickOutside={true}
            visible={isVisibleModal}
            onOK={onOk}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader color="primary" onClick={onOk} isLoading={isLoading}>
                        {t(l.Stop)}
                    </ButtonWithLoader>
                </>
            }
        >
            <div className="crm-circle-icon-cnt">
                <Icon type="bc-icon-crm-unschedule" />
            </div>
            <span>{t(confirmationText)}</span>
        </Modal>
    );
};

StopScheduleReportModal.propTypes = {
    isVisibleModal: PropTypes.bool.isRequired,
    titleText: PropTypes.string.isRequired,
    confirmationText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default memo(StopScheduleReportModal);
