import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Import Components
import CustomerJourneyGeneralDetails from './CustomerJourneyGeneralDetails';

const CustomerJourneyDetails = ({ schedule, mode, status, user, createdDate, lastModifier, lastModifiedDate }) => {
    return (
        <div className="details-page-wrapper">
            <div className="details-page-wrapper-inner">
                <div className="campaign-general-details">
                    <CustomerJourneyGeneralDetails
                        schedule={schedule}
                        mode={mode}
                        status={status}
                        user={user}
                        createdDate={createdDate}
                        lastModifier={lastModifier}
                        lastModifiedDate={lastModifiedDate}
                    />
                </div>
            </div>
        </div>
    );
};

CustomerJourneyDetails.propTypes = {
    schedule: PropTypes.object.isRequired,
    mode: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    lastModifier: PropTypes.string.isRequired,
    lastModifiedDate: PropTypes.string.isRequired,
};

export default memo(CustomerJourneyDetails);
