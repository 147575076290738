/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useRequest, useData } from 'hooks';
// Import Services
import { TemplateHttpService } from 'services/http';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
import { TemplateInfoModal } from 'components';

const CJTemplateIdInfoBlock = ({ data }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const dataValue = useData(data);

    const [isLoading, setIsLoading] = useState(false);
    const [templateData, setTemplateData] = useState(null);
    const { getTemplate } = useRef({ getTemplate: TemplateHttpService.getTemplate() }).current;
    const [isVisibleTemplateInfo, setIsVisibleTemplateInfo] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        doGetRequest(getTemplate.request, {
            queryString: { templateId: dataValue?.value },
            successCallback: (Data) => {
                setIsLoading(false);
                setTemplateData(Data);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataValue]);

    const openTemplateModal = () => {
        setIsVisibleTemplateInfo(true);
    };

    return (
        <>
            <CJInfoBlockLabelValueWrapper
                label={t(l.Name)}
                value={templateData?.Name}
                isLoading={isLoading}
                onClick={openTemplateModal}
                isClickable={true}
            />
            {isVisibleTemplateInfo && (
                <TemplateInfoModal
                    templateId={templateData?.TemplateId}
                    childItemsInfo={templateData?.ChildItemsInfo}
                    handleClose={() => setIsVisibleTemplateInfo(false)}
                    isVisible={isVisibleTemplateInfo}
                />
            )}
        </>
    );
};

CJTemplateIdInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJTemplateIdInfoBlock);
