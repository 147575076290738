import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import hooks
import { useToaster, useRequest } from 'hooks';
// Import services
import { CampaignHttpService } from 'services/http';
// Import components
import { DataTable, AccessControl, ConfirmationModal } from 'components';
// Import UI components
import { Button, Tooltip } from '@geneui/components';
// Import constants
import { l, AlertTypes, PERMISSIONS, ModalsClassNames } from 'constants/common';

import { CampaignActivityTableConfig } from './config';

const { success } = AlertTypes;

const CampaignActivity = ({ data, campaignId, name, onDateTimeApply }) => {
    const { t } = useTranslation();
    const { showToaster } = useToaster();
    const { doPostRequest } = useRequest();

    const [rows, setRows] = useState([]);
    const [campaignExecuteId, setCampaignExecuteId] = useState();

    const [isRerunModalOpened, setIsRerunModalOpened] = useState(false);
    const [isApplyExecutionDateModalOpened, setIsApplyExecutionDateModalOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const campaignActivityTableConfig = CampaignActivityTableConfig(t);
    const columns = campaignActivityTableConfig.columns.map((item, index) => ({ ...item, index: index }));
    const dateRef = useRef();

    const { rerunCampaignRequest } = useRef({
        rerunCampaignRequest: CampaignHttpService.rerunCampaign(),
    }).current;

    const mapExecutionData = () => {
        const tmpRows = data?.map((elem, index) => ({
            index,
            actionBar: false,
            dragDisable: true,
            hasHover: true,
            data: columns.reduce(
                (accumulator, element) => {
                    accumulator[element.dataKey] = elem[element.dataKey];
                    accumulator['IsRerun'] = elem['IsRerun'];
                    return accumulator;
                },
                { index },
            ),
        }));
        return isNil(tmpRows) ? [] : tmpRows;
    };

    const rerun = () => {
        setIsLoading(true);
        doPostRequest(rerunCampaignRequest.request, {
            requestBody: { CampaignId: campaignId, CampaignExecuteId: campaignExecuteId },
            successCallback: (_Data, AlertMessage) => {
                showToaster(success, AlertMessage);
            },
        }).then(() => {
            setIsLoading(false);
            setIsRerunModalOpened(false);
        });
    };

    const rerunCampaignAction = (id) => {
        setCampaignExecuteId(id);
        setIsRerunModalOpened(true);
    };

    const applyDate = () => {
        onDateTimeApply(dateRef.current);
        setIsApplyExecutionDateModalOpened(false);
    };

    const applyDateAction = (startDate, endDate) => {
        dateRef.current = { startDate: startDate, endDate: endDate };
        setIsApplyExecutionDateModalOpened(true);
    };

    const getRowActionBar = (row) => {
        const { data } = row;
        const { CampaignExecuteId, IsRerun, StartDate, EndDate } = data;
        return (
            <>
                <Tooltip text={t(l.ApplyExecutionDateAndTime)}>
                    <Button
                        icon="bc-icon-date-filter"
                        appearance="minimal"
                        color="primary"
                        onClick={() => applyDateAction(StartDate, EndDate)}
                    />
                </Tooltip>

                <AccessControl permissions={PERMISSIONS.ExecuteCampaign}>
                    <Tooltip text={t(l.ReRunCampaign)}>
                        <Button
                            disabled={!IsRerun}
                            icon="bc-icon-crm-rerun"
                            appearance="minimal"
                            color="primary"
                            onClick={() => rerunCampaignAction(CampaignExecuteId)}
                        />
                    </Tooltip>
                </AccessControl>
            </>
        );
    };

    useEffect(() => {
        setRows(isNil(data) ? [] : mapExecutionData(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div className="details-section">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name">{t(l.CampaignActivity)}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <div>
                {isRerunModalOpened && (
                    <ConfirmationModal
                        onOk={() => rerun()}
                        isVisibleModal={isRerunModalOpened}
                        titleText={t(l.ReRunCampaign)}
                        questionLabel={t(l.DoYouWantToReRunCampaign, { CampaignName: `"${name}"` })}
                        onCancel={() => setIsRerunModalOpened(false)}
                        isLoading={isLoading}
                        iconType="bc-icon-status-play"
                        actionLabel={t(l.ReRun)}
                        className={ModalsClassNames.Start}
                    />
                )}
                {isApplyExecutionDateModalOpened && (
                    <ConfirmationModal
                        onOk={() => applyDate()}
                        isVisibleModal={isApplyExecutionDateModalOpened}
                        titleText={t(l.ExecutionDate)}
                        questionLabel={t(l.TheDateWillBeChangedByThisExecutionStartAndEndDates)}
                        onCancel={() => setIsApplyExecutionDateModalOpened(false)}
                        iconType="bc-icon-status-play"
                        actionLabel={t(l.Confirm)}
                        className={ModalsClassNames.Start}
                    />
                )}
                <DataTable
                    key="CampaignExecuteId"
                    columnKey="dataKey"
                    rowKey="index"
                    name=""
                    data={mapExecutionData()}
                    dataCount={rows.length}
                    columns={columns}
                    isLoading={isNil(data)}
                    withPagination={false}
                    withPageSelector={false}
                    isVisibleOnlyDataTable={true}
                    rowActionBar={getRowActionBar}
                    sorting={campaignActivityTableConfig.defaultSorting}
                />
            </div>
        </div>
    );
};

CampaignActivity.propTypes = {
    campaignId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    data: PropTypes.array,
    onDateTimeApply: PropTypes.func,
};

CampaignActivity.defaultProps = {
    onDateTimeApply: noop,
    data: null,
};

export default memo(CampaignActivity);
