import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//Import Components
import { DataTable } from 'components';
//Import SCSS
import 'assets/scss/segmentModal.scss';
// Import Services
import { CustomerJourneyHttpService } from 'services/http';
//Import Config
import { TimeLineDataTableColumnsConfigs } from './config';
// Import Hooks
import { useRequest } from 'hooks';

const ActivityTimeLine = ({ workflowId }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const { columns, defaultSorting } = TimeLineDataTableColumnsConfigs(t);
    const getTableRows = () => {
        return data.map(({ Date, UserName, Type, Action, Source, Name, Info, SystemMessage }, index) => {
            return {
                hasHover: true,
                dragDisable: true,
                data: {
                    index,
                    isRowOpend: false,
                    Date,
                    UserName,
                    Type,
                    Name,
                    Action: Action.Name,
                    Source: Source.Name,
                    Info,
                    SystemMessage,
                },
            };
        });
    };
    const getTableColumns = () => {
        return columns;
    };

    const { getCustomerJourneyTimeline } = useRef({
        getCustomerJourneyTimeline: CustomerJourneyHttpService.getCustomerJourneyTimeline(),
    }).current;

    const getTimeLineData = () => {
        setIsLoading(true);
        doGetRequest(getCustomerJourneyTimeline.request, {
            queryString: {
                workflowId: workflowId,
            },
            successCallback: (data) => {
                setData(data);
            },
        }).then(() => setIsLoading(false));
    };

    useEffect(getTimeLineData, []);

    return (
        <DataTable
            rowKey="index"
            columnKey="dataKey"
            withPagination={false}
            withSearch={false}
            withPageSelector={false}
            data={getTableRows()}
            dataCount={data?.length}
            columns={getTableColumns()}
            isLoading={isLoading}
            sorting={defaultSorting}
        />
    );
};

ActivityTimeLine.propTypes = {
    workflowId: PropTypes.number.isRequired,
};

export default memo(ActivityTimeLine);
