import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Import UI Components
import { Option, Icon } from '@geneui/components';

const ChildOption = ({
    label,
    value,
    parentValue,
    parentLabel,
    isSelected,
    onSelect,
    isDisabled: _isDisabled,
    isParentLabelVisible,
}) => {
    const dynamicProps = isSelected ? { rightCustomElement: <Icon type="bc-icon-selected" /> } : {};

    return (
        <Option
            className={classNames({ active: isSelected })}
            title={isParentLabelVisible ? `${parentLabel} / ${label}` : label}
            onClick={() => onSelect({ label, value, parentLabel, parentValue })}
            {...dynamicProps}
        />
    );
};

ChildOption.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    parentValue: PropTypes.string.isRequired,
    parentLabel: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isParentLabelVisible: PropTypes.bool,
};

ChildOption.defaultProps = {
    isSelected: false,
    isDisabled: false,
    isParentLabelVisible: false,
};

export default memo(ChildOption);
