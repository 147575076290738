import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Icon } from '@geneui/components';
// Import constants
import { TemplateTypesLabels, l } from 'constants/common';
import { dateTimeFormat } from './config';

const TemplateWidget = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="campaign-tabs-inner">
            <div>
                <div className="campaign-tab-icon template-icon">
                    <Icon type="bc-icon-template" />
                </div>
                <div className="campaign-tab-content-wrapper">
                    {isNil(data) ? (
                        <div>
                            <p className="campaign-tab-heading">{t(l.Template)}</p>
                            <p className="campaign-tab-desc">{t(l.TemplateDescription)}</p>
                        </div>
                    ) : (
                        <div>
                            <div className="campaign-tab-heading-group">
                                <p className="campaign-tab-title">{t(l.Template)}</p>
                                <p className="campaign-tab-subtitle">{data.title}</p>
                            </div>
                            <div className="campaign-tab-content">
                                <div className="campaign-tab-content-part">
                                    <div className="campaign-tab-content-type">
                                        <span>{t(l.Channel)}:</span>
                                        <>
                                            {data.channel === TemplateTypesLabels.Email && (
                                                <Icon type={'bc-icon-message-closed crm-template-type-icon email'} />
                                            )}
                                            {data.channel === TemplateTypesLabels.Internal && (
                                                <Icon type={'bc-icon-sms crm-template-type-icon internal'} />
                                            )}
                                            {data.channel === TemplateTypesLabels.SMS && (
                                                <Icon type={'bc-icon-message-closed crm-template-type-icon sms'} />
                                            )}
                                            {data.channel === TemplateTypesLabels.PopUp && (
                                                <Icon type={'bc-icon-message-closed crm-template-type-icon popup'} />
                                            )}
                                            {data.channel === TemplateTypesLabels.CMSPopUp && (
                                                <Icon type={'bc-icon-message-closed crm-template-type-icon popup'} />
                                            )}
                                            {data.channel === TemplateTypesLabels.Telegram && (
                                                <Icon
                                                    type={
                                                        'bc-icon-send-telegram-message-block crm-template-type-icon telegram'
                                                    }
                                                />
                                            )}
                                            <p>{TemplateTypesLabels[data.channel]}</p>
                                        </>
                                    </div>
                                    <div className="campaign-tab-user-info">
                                        <span>{t(l.User)}:</span>
                                        <p>{data.user}</p>
                                    </div>
                                </div>
                                <div className="campaign-tab-content-part">
                                    <div className="campaign-tab-date">
                                        <span>{t(l.CreatedDate)}:</span>
                                        <p>{dateTimeFormat(data.createdDate)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

TemplateWidget.propTypes = {
    data: PropTypes.object,
};

TemplateWidget.defaultProps = {
    data: null,
};

export default memo(TemplateWidget);
