export default class UIHashBaseContainer {
    constructor() {
        this._parent = null;
    }

    get parent() {
        return this._parent;
    }
    set parent(value) {
        this._parent = value;
    }
    get parentHashKey() {
        return this._parent.hashKey;
    }
    get parentDisplayName() {
        return this._parent.displayName;
    }
}
