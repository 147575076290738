import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { useFormik } from 'formik';
// Import Components
import { ValidatableDropdown } from 'components';
// Import Constants
import { weekdayInitialValueCreator } from './config';

const WeekdayForm = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: weekdayInitialValueCreator(defaultValue, option?.Required, t),
        onSubmit: noop,
    });
    const { values, setFieldValue } = formik;
    const { weekday, weekdayData } = values;

    const weekdayChangeHandler = (e) => {
        setFieldValue('weekday', e.value);
    };

    useEffect(() => {
        getUpdate({
            weekday,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekday]);

    return (
        <>
            <ValidatableDropdown
                isMultiSelect={false}
                disabled={false}
                inputSize="default"
                appearance="outline"
                value={weekday}
                data={weekdayData}
                onChange={weekdayChangeHandler}
            />
        </>
    );
};

WeekdayForm.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(WeekdayForm);
