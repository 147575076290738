/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { SkeletonLoader } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const CJInfoBlockLabelValueWrapper = ({ label, value, isLoading, isClickable, onClick }) => {
    const { t } = useTranslation();

    return (
        <div className={'cj-info-label-value-wrapper'}>
            <p className={'cj-info-label-value-wrapper_label'}>{label}</p>
            <SkeletonLoader isBusy={isLoading}>
                <p className={`cj-info-label-value-wrapper_value ${isClickable && 'cj-info-value'}`} onClick={onClick}>
                    {isNil(value) ? t(l.NA) : value}
                </p>
            </SkeletonLoader>
        </div>
    );
};

CJInfoBlockLabelValueWrapper.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    isClickable: PropTypes.bool,
};

CJInfoBlockLabelValueWrapper.defaultProps = {
    isLoading: false,
    onClick: noop,
    isClickable: false,
};

export default memo(CJInfoBlockLabelValueWrapper);
