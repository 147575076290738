import { CurrencyRatesActionTypes as Types } from './types';

const setCurrencyRatesData = (data) => ({
    type: Types.SET_CURRENCY_RATES,
    payload: data,
});

export default {
    setCurrencyRatesData,
};
