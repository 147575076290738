import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sortBy, sum, values } from 'lodash';
// Import UI components
import { BusyLoader } from '@geneui/components';
// Import Components
import { HolderWrapper } from 'components';
import BlockGroup from './BlockGroup';
// Import Constants
import { l, CustomerJourneyGroupBlockTypes } from 'constants/common';

const { target } = CustomerJourneyGroupBlockTypes;

const FlowEditorSidebar = ({ isDisabled, groupBlocks, defaultOpened }) => {
    const { t } = useTranslation();
    const { blocksCounts, maxBlocksCounts } = useSelector((state) => state.customerJourney);

    const isBlockGroupReadOnly = (blocksTypes, blocksCounts) => {
        const count = sum(values(blocksCounts));
        let result = false;
        if (blocksTypes === target) {
            result = result || blocksCounts[blocksTypes] > 0;
        }
        result = result || count >= maxBlocksCounts;

        return result;
    };

    return (
        <HolderWrapper
            isDisabled={isDisabled}
            title={t(l.Blocks)}
            position="left"
            footer=""
            expandText={t(l.Expand)}
            collapseText={t(l.Collapse)}
            defaultOpened={defaultOpened}
            className="crm-flow-sidebar"
            filterIconType="bc-icon-arrow-right"
        >
            {defaultOpened && isDisabled ? (
                <BusyLoader isBusy type="spinner" spinnerSize="big" />
            ) : (
                sortBy(groupBlocks, ({ orderKey }) => orderKey).map(
                    ({ titleKey, blocks, blocksTypes, cssClassName, defaultBlockIcon }, index) => {
                        return (
                            <BlockGroup
                                key={index}
                                titleKey={titleKey}
                                blocks={blocks.filter(
                                    ({ CustomAttributes }) =>
                                        !(CustomAttributes?.Hidden || CustomAttributes?.Deprecated),
                                )}
                                blocksTypes={blocksTypes}
                                cssClassName={cssClassName}
                                defaultBlockIcon={defaultBlockIcon}
                                isReadOnly={isBlockGroupReadOnly(blocksTypes, blocksCounts)}
                            />
                        );
                    },
                )
            )}
        </HolderWrapper>
    );
};

FlowEditorSidebar.propTypes = {
    isDisabled: PropTypes.bool,
    groupBlocks: PropTypes.array,
    defaultOpened: PropTypes.bool,
};

FlowEditorSidebar.defaultProps = {
    isDisabled: true,
    groupBlocks: [],
    defaultOpened: false,
};

export default memo(FlowEditorSidebar);
