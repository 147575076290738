import { CompositeReportOperation, l } from 'constants/common';

const ReportItemsConfig = {
    maxCountOfReports: 4,
    firstEnableDropItems: 1,
    enableDropHere: { enableDropHere: true },
};

const ReportContainerConfig = {
    radioGroupOptions: [
        { label: l.Union, value: CompositeReportOperation.Union, icon: 'bc-icon-union' },
        {
            label: l.Intersect,
            value: CompositeReportOperation.Intersect,
            icon: 'bc-icon-intersect',
        },
        {
            label: l.FirstPriority,
            value: CompositeReportOperation.FirstPriority,
            icon: 'bc-icon-first-priority',
        },
    ],
};

export { ReportItemsConfig, ReportContainerConfig };
