import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, noop } from 'lodash';
// Import UI Components
import { ExtendedInput, Button, Dropdown, Alert, SkeletonLoader } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const CurrencyAutoFillSection = ({ dropdownData, onOk, alertMessage, withMinMax, isLoading }) => {
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            selectedCurrency: null,
            autoFillMinValue: '',
            autoFillMaxValue: '',
            amount: '',
            isTouched: false,
        },
        onSubmit: noop,
        validationSchema: Yup.object({
            autoFillMinValue: withMinMax
                ? Yup.string().when(['isTouched'], {
                      is: (isTouched) => isTouched,
                      then: Yup.string()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.Min) }))
                          .matches(/^\d{1,}(\.\d{0,9})?$/, t(l.MinCurrencyIsNotValid, { fieldName: l.Min }))
                          .test('min', t(l.MinMustBeSmallerThanMax, { label: t(l.Min) }), function (value) {
                              const max = values.autoFillMaxValue;
                              return !value || !max || +value <= +max;
                          }),
                  })
                : Yup.string().notRequired(),
            autoFillMaxValue: withMinMax
                ? Yup.string().when(['isTouched'], {
                      is: (isTouched) => isTouched,
                      then: Yup.string()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.Max) }))
                          .matches(/^\d{1,}(\.\d{0,9})?$/, t(l.MaxCurrencyIsNotValid, { fieldName: l.Max }))
                          .test('max', t(l.MaxMustBeBiggerThanMin, { label: t(l.Max) }), function (value) {
                              const min = values.autoFillMinValue;
                              return !value || !min || +value >= +min;
                          })
                          .test('max', t(l.MaxMustBeBiggerThanZero, { label: t(l.Max) }), function (value) {
                              return value > 0;
                          }),
                  })
                : Yup.string().notRequired(),
            amount: !withMinMax
                ? Yup.string().when(['isTouched'], {
                      is: (isTouched) => isTouched,
                      then: Yup.string()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.Amount) }))
                          .matches(/^\d{1,}(\.\d{0,9})?$/, t(l.AmountIsNotValid, { fieldName: l.Amount }))
                          .test('max', t(l.AmountMustBeBiggerThanZero, { label: t(l.Amount) }), function (value) {
                              return value > 0;
                          }),
                  })
                : Yup.string().notRequired(),
            selectedCurrency: Yup.string()
                .nullable()
                .when(['isTouched'], {
                    is: (isTouched) => isTouched,
                    then: Yup.string()
                        .required(t(l.CannotBeEmpty, { fieldName: t(l.Currency) }))
                        .nullable(),
                }),
        }),
    });

    const { values, errors, setFieldValue, submitForm, handleBlur } = formik;
    const { autoFillMinValue, autoFillMaxValue, selectedCurrency, amount } = values;

    const currencyOnChangeHandler = (e) => {
        setFieldValue('selectedCurrency', e?.value ?? null).then(() => submitForm());
    };

    const handleAutoFillMinValueChange = (e) => {
        setFieldValue('autoFillMinValue', e.target?.value.replace('-', '')).then(() => submitForm());
    };

    const handleAutoFillMaxValueChange = (e) => {
        setFieldValue('autoFillMaxValue', e.target?.value.replace('-', '')).then(() => submitForm());
    };

    const handleAutoFillAmountValueChange = (e) => {
        setFieldValue('amount', e.target?.value.replace('-', '')).then(() => submitForm());
    };

    useEffect(() => {
        setFieldValue(
            'isTouched',
            !isEmpty(autoFillMinValue) || !isEmpty(autoFillMaxValue) || !isNil(selectedCurrency) || !isEmpty(amount),
        );
    }, [autoFillMinValue, autoFillMaxValue, selectedCurrency, setFieldValue, amount]);

    return (
        <div className="autofill-section-container">
            <div className="min-max-currency-autofill-section-container">
                {withMinMax ? (
                    <>
                        <ExtendedInput
                            autocomplete="off"
                            value={values.autoFillMinValue}
                            onBlur={handleBlur}
                            onChange={handleAutoFillMinValueChange}
                            type="number"
                            name="autoFillMinValue"
                            placeholder={t(l.Min)}
                            labelAppearance="swap"
                            isValid={isEmpty(errors.autoFillMinValue)}
                            errorText={errors.autoFillMinValue}
                        />
                        <ExtendedInput
                            autocomplete="off"
                            onBlur={handleBlur}
                            value={values.autoFillMaxValue}
                            onChange={handleAutoFillMaxValueChange}
                            type="number"
                            name="autoFillMaxValue"
                            placeholder={t(l.Max)}
                            labelAppearance="swap"
                            isValid={isEmpty(errors.autoFillMaxValue)}
                            errorText={errors.autoFillMaxValue}
                        />
                    </>
                ) : (
                    <ExtendedInput
                        autocomplete="off"
                        onBlur={handleBlur}
                        value={amount}
                        onChange={handleAutoFillAmountValueChange}
                        type="number"
                        name="amount"
                        placeholder={t(l.Amount)}
                        labelAppearance="swap"
                        isValid={isEmpty(errors.amount)}
                        errorText={errors.amount}
                    />
                )}
                <SkeletonLoader isBusy={isLoading} width="300px">
                    <Dropdown
                        placeholder={t(l.Currency)}
                        labelAppearance="swap"
                        isMultiSelect={false}
                        hasSearch={true}
                        inputSize="default"
                        appearance="outline"
                        data={dropdownData}
                        searchPlaceholderText={t(l.Search)}
                        noDataText={t(l.NoDataFound)}
                        value={values.selectedCurrency}
                        onChange={currencyOnChangeHandler}
                        clearable={true}
                        isValid={isEmpty(errors.selectedCurrency)}
                        errorText={errors.selectedCurrency}
                    />
                </SkeletonLoader>
                <Button
                    color="primary"
                    appearance="default"
                    onClick={() => onOk(values)}
                    disabled={
                        withMinMax
                            ? isEmpty(values.autoFillMinValue) ||
                              isEmpty(values.autoFillMaxValue) ||
                              isNil(values.selectedCurrency) ||
                              !isEmpty(errors)
                            : isEmpty(values.amount) || isNil(values.selectedCurrency) || !isEmpty(errors)
                    }
                >
                    {t(l.AutoFill)}
                </Button>
            </div>

            <Alert closable={false.toString()} title={alertMessage} iconProps={{ type: 'bc-icon-info' }} />
        </div>
    );
};

CurrencyAutoFillSection.propTypes = {
    onOk: PropTypes.func.isRequired,
    dropdownData: PropTypes.array.isRequired,
    alertMessage: PropTypes.string.isRequired,
    withMinMax: PropTypes.bool,
    isLoading: PropTypes.bool,
};

CurrencyAutoFillSection.defaultProps = {
    withMinMax: false,
    isLoading: false,
};

export default memo(CurrencyAutoFillSection);
