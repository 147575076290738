import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, has } from 'lodash';

// Import UI Components
import { SegmentModal } from 'components/SegmentModal';
import { SingleInputModal, ConfirmationModal } from 'components';
// Import Constants
import { DefinitionTypes, ModalsActions, l, ActionsIcons, ModalsClassNames } from 'constants/common';
// Import Services
import { SegmentHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import Helpers
import { Helpers } from 'services';

const { getUsedInConfirmationText } = Helpers;

// TODO: change all actions with service with doAction methods
const SegmentActionsModals = (props) => {
    const { t } = useTranslation();

    const { doPostRequest, doDeleteRequest } = useRequest();
    const {
        isDeleteModalOpenedState,
        isConvertToReportModalOpenedState,
        isArchiveModalOpenedState,
        isUnArchiveModalOpenedState,
        isInfoModalOpenedState,
        isUsedInConfirmationModalOpenedState,
        onCloseModalHandler,
        modalsStateSetDict,
        openedModalData,
        goToEditSegment,
    } = props;

    const { SegmentId, Name, Type, DefinitionType } = openedModalData;

    const isPredefined = DefinitionType === DefinitionTypes.predefined;
    const [reportName, setReportName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const {
        convertToReportRequest,
        archiveSegmentRequest,
        archiveSegmentsRequest,
        unarchiveSegmentRequest,
        unarchiveSegmentsRequest,
        deleteSegmentRequest,
        deleteSegmentsRequest,
    } = useRef({
        deleteSegmentRequest: SegmentHttpService.deleteSegment(),
        deleteSegmentsRequest: SegmentHttpService.deleteSegments(),
        convertToReportRequest: SegmentHttpService.convertToReport(),
        archiveSegmentRequest: SegmentHttpService.archiveSegment(),
        archiveSegmentsRequest: SegmentHttpService.archiveSegments(),
        unarchiveSegmentRequest: SegmentHttpService.unarchiveSegment(),
        unarchiveSegmentsRequest: SegmentHttpService.unarchiveSegments(),
    }).current;

    const deleteSegment = () => {
        setIsLoading(true);
        doDeleteRequest(deleteSegmentRequest.request, {
            queryString: { segmentId: openedModalData.SegmentId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDict.deleteSegment.key, ModalsActions.DELETE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const deleteSegments = () => {
        setIsLoading(true);
        const { allowDeleteSegments } = openedModalData;
        if (isEmpty(allowDeleteSegments)) {
            return onCloseModalHandler(modalsStateSetDict.deleteSegment.key);
        }
        const allowDeleteSegmentsIds = allowDeleteSegments.map(({ segmentId }) => {
            return segmentId;
        });
        doPostRequest(deleteSegmentsRequest.request, {
            requestBody: allowDeleteSegmentsIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.deleteSegment.key,
                ModalsActions.DELETE,
                AlertType,
                AlertMessage,
                allowDeleteSegmentsIds.length,
            );
            setIsLoading(false);
        });
    };

    const convertToReport = () => {
        setIsLoading(true);
        doPostRequest(convertToReportRequest.request, {
            requestBody: { SegmentId: openedModalData.SegmentId, Name: reportName },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseConvertToReportModal(
                true,
                modalsStateSetDict.convertToReport.key,
                ModalsActions.CONVERT_TO_REPORT,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const archiveSegment = () => {
        setIsLoading(true);
        doPostRequest(archiveSegmentRequest.request, {
            requestBody: null,
            queryString: { segmentId: openedModalData.SegmentId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDict.archiveSegment.key, ModalsActions.ARCHIVE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const archiveSegments = () => {
        setIsLoading(true);
        const { allowArchiveSegments } = openedModalData;
        if (isEmpty(allowArchiveSegments)) {
            return onCloseModalHandler(modalsStateSetDict.archiveSegment.key);
        }
        const allowArchiveSegmentsIds = allowArchiveSegments.map(({ segmentId }) => {
            return segmentId;
        });
        doPostRequest(archiveSegmentsRequest.request, {
            requestBody: allowArchiveSegmentsIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.archiveSegment.key,
                ModalsActions.ARCHIVE,
                AlertType,
                AlertMessage,
                allowArchiveSegmentsIds.length,
            );
            setIsLoading(false);
        });
    };

    const unarchiveSegment = () => {
        setIsLoading(true);
        doPostRequest(unarchiveSegmentRequest.request, {
            requestBody: null,
            queryString: { segmentId: openedModalData.SegmentId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.unarchiveSegment.key,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const unarchiveSegments = () => {
        setIsLoading(true);
        const { allowUnarchiveSegments } = openedModalData;
        if (isEmpty(allowUnarchiveSegments)) {
            return onCloseModalHandler(modalsStateSetDict.archiveSegment.key);
        }
        const allowUnarchiveSegmentsIds = allowUnarchiveSegments.map(({ segmentId }) => {
            return segmentId;
        });
        doPostRequest(unarchiveSegmentsRequest.request, {
            requestBody: allowUnarchiveSegmentsIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.unarchiveSegment.key,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
                allowUnarchiveSegmentsIds.length,
            );
            setIsLoading(false);
        });
    };

    const onCloseConvertToReportModal = (isOk, modalStateKey, action, alertType, alertMessage) => {
        if (isOk === true) {
            onCloseModalHandler(modalStateKey, action, alertType, alertMessage);
        } else {
            onCloseModalHandler(modalsStateSetDict.convertToReport.key, null, alertType, alertMessage);
        }
        setReportName('');
    };

    const isBulkAction = () => {
        return (
            has(openedModalData, 'allowDeleteSegments') ||
            has(openedModalData, 'allowArchiveSegments') ||
            has(openedModalData, 'allowUnarchiveSegments')
        );
    };

    const cleanUp = () => {
        return () => {
            deleteSegmentRequest.cancel('SegmentListPage:deleteSegmentRequest');
            convertToReportRequest.cancel('SegmentListPage:convertToReportRequest');
            archiveSegmentRequest.cancel('SegmentListPage:archiveSegmentRequest');
            unarchiveSegmentRequest.cancel('SegmentListPage:unarchiveSegmentRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        !isEmpty(openedModalData) && (
            <>
                {isDeleteModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? deleteSegments : deleteSegment}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowDeleteSegments) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.deleteSegment.key)}
                        isVisibleModal={isDeleteModalOpenedState}
                        titleText={isBulkAction() ? t(l.DeleteSelectedSegments) : t(l.DeleteSegment)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Delete}
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                        allowDataList={openedModalData.allowDeleteSegments}
                        notAllowDataList={openedModalData.notAllowDeleteSegments}
                        allowDataLabel={l.AllowDeleteSelectedSegments}
                        notAllowDataLabel={l.NotAllowDeleteSelectedSegments}
                        questionLabel={
                            isBulkAction()
                                ? l.DeleteSelectedSegmentsQuestion
                                : t(l.AreYouSureYouWantToDeleteSegment, {
                                      segmentName: `"${Name?.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isConvertToReportModalOpenedState && (
                    <SingleInputModal
                        titleTextLabel={l.ConvertToReport}
                        isModalVisible={isConvertToReportModalOpenedState}
                        onCancel={onCloseConvertToReportModal}
                        onOk={convertToReport}
                        onOkBtnTextLabel={l.Convert}
                        inputName={reportName}
                        setInputName={setReportName}
                        inputPlaceholderLabel={l.ReportName}
                        isLoading={isLoading}
                    />
                )}

                {isArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? archiveSegments : archiveSegment}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowArchiveSegments) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.archiveSegment.key)}
                        isVisibleModal={isArchiveModalOpenedState}
                        titleText={isBulkAction() ? t(l.ArchiveSelectedSegments) : t(l.ArchiveSegment)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Archive}
                        actionLabel={t(l.Archive)}
                        className={ModalsClassNames.Archive}
                        allowDataList={openedModalData.allowArchiveSegments}
                        notAllowDataList={openedModalData.notAllowArchiveSegments}
                        allowDataLabel={l.AllowArchiveSelectedSegments}
                        notAllowDataLabel={l.NotAllowArchiveSelectedSegments}
                        questionLabel={
                            isBulkAction()
                                ? l.ArchiveSelectedSegmentsQuestion
                                : t(l.AreYouSureYouWantToArchiveSegment, {
                                      segmentName: `"${Name?.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isUnArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? unarchiveSegments : unarchiveSegment}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowUnarchiveSegments) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.unarchiveSegment.key)}
                        isVisibleModal={isUnArchiveModalOpenedState}
                        titleText={isBulkAction() ? t(l.UnarchiveSelectedSegments) : t(l.UnarchiveSegment)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.UnArchive}
                        actionLabel={t(l.Unarchive)}
                        className={ModalsClassNames.UnArchive}
                        allowDataList={openedModalData.allowUnarchiveSegments}
                        notAllowDataList={openedModalData.notAllowUnarchiveSegments}
                        allowDataLabel={l.AllowUnarchiveSelectedSegments}
                        notAllowDataLabel={l.NotAllowUnarchiveSelectedSegments}
                        questionLabel={
                            isBulkAction()
                                ? l.UnarchiveSelectedSegmentsQuestion
                                : t(l.AreYouSureYouWantToUnarchiveSegment, {
                                      segmentName: `"${Name?.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isUsedInConfirmationModalOpenedState && (
                    <ConfirmationModal
                        onOk={() => goToEditSegment(openedModalData?.segment)}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.editSegment.key)}
                        isVisibleModal={isUsedInConfirmationModalOpenedState}
                        titleText={t(l.EditSegment)}
                        questionLabel={getUsedInConfirmationText(
                            t,
                            l.Segment,
                            openedModalData?.objects,
                            l.IsUsedInObjectsLabel,
                        )}
                        iconType={ActionsIcons.UsedIn}
                        actionLabel={t(l.Continue)}
                        className={ModalsClassNames.UsedIn}
                    />
                )}

                {isInfoModalOpenedState && (
                    <SegmentModal
                        targetObject={openedModalData?.TargetObject}
                        segmentId={SegmentId}
                        segmentType={Type}
                        handleClose={() => onCloseModalHandler(modalsStateSetDict.infoSegment.key)}
                        isVisible={isInfoModalOpenedState}
                        isPredefined={isPredefined}
                    />
                )}
            </>
        )
    );
};

SegmentActionsModals.propTypes = {
    isDeleteModalOpenedState: PropTypes.bool.isRequired,
    isConvertToReportModalOpenedState: PropTypes.bool.isRequired,
    isArchiveModalOpenedState: PropTypes.bool.isRequired,
    isUnArchiveModalOpenedState: PropTypes.bool.isRequired,
    isInfoModalOpenedState: PropTypes.bool.isRequired,
    isUsedInConfirmationModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.any.isRequired,
    goToEditSegment: PropTypes.func,
};

export default memo(SegmentActionsModals);
