import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import hooks
import { useRequest } from 'hooks';
// Import services
import { SegmentHttpService } from 'services/http';
// Import UI components
import { RadioGroup, Icon, ModuleTitle, Button, BusyLoader, Tooltip } from '@geneui/components';
// Import components
import SegmentItems from './SegmentItems';
import SegmentReportPreviewModal from 'components/SegmentReportPreviewModal';
// Import constants
import { SegmentContainerConfig, SegmentItemsConfig } from './config';
import { l } from 'constants/common';

const { defaultColumnsReport, minSegmentsCount, radioGroupOptions } = SegmentContainerConfig;
const { maxCountOfSegments } = SegmentItemsConfig;

const SegmentsContainer = ({
    getRowByIndex,
    firstSegment,
    setFirstSegment,
    segments,
    setSegments,
    radioGroupValue,
    setRadioGroupValue,
    getConfig,
    isLoading,
    dropAcceptType,
    clientsCount,
    setClientsCount,
}) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();

    const [clientsData, setClientsData] = useState(null);
    const [countLoading, setCountLoading] = useState(false);
    const [isVisibleModal, setIsVisibleModal] = useState(false);

    const { getSegmentCount, getCompositeSegmentPreview } = useRef({
        getSegmentCount: SegmentHttpService.getCompositeSegmentCount(),
        getCompositeSegmentPreview: SegmentHttpService.getCompositeSegmentPreview(),
    }).current;

    const isDisabledSegmentContainerActions = () => {
        if (!isNil(firstSegment?.segmentId)) {
            const { Operation, SegmentIds } = getConfig();
            return isNil(Operation) || SegmentIds?.length < minSegmentsCount;
        }
        return true;
    };

    const getSegmentReports = (compositeConfig) => {
        const config = { ...compositeConfig };
        config.Columns = defaultColumnsReport;
        doPostRequest(getCompositeSegmentPreview.request, {
            requestBody: config,
            successCallback: (Data) => setClientsData(Data.Data[0]),
        });
    };

    const handleClickPreview = () => {
        setIsVisibleModal(true);
        const config = { Config: getConfig() };
        getSegmentReports(config);
        getSegmentTotalAndSelectedCount(config);
    };

    const getSegmentTotalAndSelectedCount = (config) => {
        setCountLoading(true);
        doPostRequest(getSegmentCount.request, {
            requestBody: config,
            successCallback: (Data) => setClientsCount(Data),
        }).then(() => {
            setCountLoading(false);
        });
    };

    const getSegmentTotalAndSelectedCountClickHandler = () => {
        const config = { Config: getConfig() };
        getSegmentTotalAndSelectedCount(config);
    };

    const closeModal = () => {
        setIsVisibleModal(false);
        setClientsData(null);
    };

    const getCount = () => {
        // eslint-disable-next-line no-extra-boolean-cast
        let count = !!firstSegment?.enableDropHere ? 0 : 1;
        // for each because can drop enable first not 1 or more fields
        segments.forEach((element) => {
            // eslint-disable-next-line no-extra-boolean-cast
            count += !!element.enableDropHere ? 0 : 1;
        });
        return count;
    };

    const getRadioGroupOptions = () => {
        return radioGroupOptions.map((item) => {
            return {
                label: (
                    <div className="union-icon">
                        <Icon type={item.icon} /> {t(item.label)}
                    </div>
                ),
                value: item.value,
            };
        });
    };

    return (
        <div className="segment-container-inner">
            <ModuleTitle title="SegmentContainer" description=" " />

            <BusyLoader isBusy={isLoading && isNil(dropAcceptType)} type="spinner" spinnerSize="medium">
                {!isNil(dropAcceptType) && (
                    <div className="segment-container-inner-inside">
                        <div className="total-block">
                            <div className="total-block-inner">
                                <p>{`${t(l.Total)} ${clientsCount.Total} / ${clientsCount.Count}`}</p>
                                <Icon
                                    disabled={isDisabledSegmentContainerActions()}
                                    className={`crm-blue${countLoading ? ' rotate' : ''}`}
                                    type={'bc-icon-refresh'}
                                    onClick={getSegmentTotalAndSelectedCountClickHandler}
                                />
                            </div>
                            <div className="total-block-inner">
                                <Button
                                    color="primary"
                                    appearance="minimal"
                                    icon="bc-icon-view-doc"
                                    className="ml-10"
                                    disabled={isDisabledSegmentContainerActions()}
                                    onClick={handleClickPreview}
                                />
                            </div>
                        </div>
                        <div className="union-block">
                            {!isNil(radioGroupValue) && (
                                <>
                                    <RadioGroup
                                        name="controlled"
                                        options={getRadioGroupOptions()}
                                        type="tab"
                                        value={radioGroupValue}
                                        onChange={(e) => setRadioGroupValue(e.target.value)}
                                    />
                                    <Tooltip
                                        style={{ marginTop: '5px' }}
                                        text={
                                            <div>
                                                <div>
                                                    <span>{t(l.Union)}</span>
                                                    <p>{t(l.UnionTooltipText)}</p>
                                                </div>
                                                <div>
                                                    <span>{t(l.Intersect)}</span>
                                                    <p>{t(l.IntersectTooltipText)}</p>
                                                </div>
                                                <div>
                                                    <span>{t(l.Difference)}</span>
                                                    <p>{t(l.DifferenceTooltipText)}</p>
                                                </div>
                                            </div>
                                        }
                                        position="bottom"
                                    >
                                        <Icon type="bc-icon-info" />
                                    </Tooltip>
                                </>
                            )}
                        </div>
                        <div className="droping-container">
                            <div className="droping-container-inner">
                                <div className="droping-container-info">
                                    {!isEmpty(segments) && !isEmpty(firstSegment) && (
                                        <SegmentItems
                                            getRowByIndex={getRowByIndex}
                                            firstSegment={firstSegment}
                                            setFirstSegment={setFirstSegment}
                                            segments={segments}
                                            setSegments={setSegments}
                                            dropAcceptType={dropAcceptType}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="segment-txt">
                            <span className="segment-count">
                                {getCount()}
                                {'/'}
                                {maxCountOfSegments + 1}
                            </span>
                            {t(l.Segments)}
                        </div>
                    </div>
                )}
                {isVisibleModal && (
                    <SegmentReportPreviewModal
                        isVisible={isVisibleModal}
                        closeHandler={closeModal}
                        clientsData={clientsData}
                    />
                )}
            </BusyLoader>
        </div>
    );
};

SegmentsContainer.propTypes = {
    getRowByIndex: PropTypes.func.isRequired,
    firstSegment: PropTypes.object.isRequired,
    segments: PropTypes.array.isRequired,
    setFirstSegment: PropTypes.func.isRequired,
    setSegments: PropTypes.func.isRequired,
    radioGroupValue: PropTypes.number.isRequired,
    setRadioGroupValue: PropTypes.func.isRequired,
    getConfig: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    dropAcceptType: PropTypes.string.isRequired,
    clientsCount: PropTypes.object.isRequired,
    setClientsCount: PropTypes.func.isRequired,
};

export default memo(SegmentsContainer);
