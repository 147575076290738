import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import UI Components
import { RadioGroup } from '@geneui/components';
// Import Components
import { ConfirmationModal, BlockEditor, HtmlCodeEditor, JoditEditor, MosaicoEditor } from 'components';
// Import Constants
import { l, ModalsClassNames, TemplateEditorTypes } from 'constants/common';
// Import Hooks
import { useValidator } from 'hooks';
// Import SCSS
import 'assets/scss/htmlEditors.scss';
// Import Services
import { getTemplateValidationTranslatableErrorText } from 'services/template';

const joditEmptyContent = '<p><br></p>';

const dynamicVariablesHintTexts = {
    [TemplateEditorTypes.HtmlCodeEditor]: '',
    [TemplateEditorTypes.JoditEditor]: '',
    [TemplateEditorTypes.MosaicoEditor]: l.DynamicVariablesAreInsideTheEditor,
    [TemplateEditorTypes.BlockEditor]: l.DynamicVariablesAreInsideTheEditor,
};

const HTMLEditors = ({
    tabOptions,
    htmlText,
    handleChange,
    config,
    handleConfigChange,
    templateLangKey,
    getAssets,
    addAsset,
    bodyPropertyName,
    validationState,
    dynamicVariables,
    templateType,
    isPartialHTML,
    isInitialFocus,
    updateInsertionHandler,
    onEditorFocus,
}) => {
    const { t } = useTranslation();

    const validator = useValidator(getTemplateValidationTranslatableErrorText(t));

    const tempEditorModeRef = useRef(null);
    const validatorTimeoutRef = useRef();
    const [isChangeModeWarningModalOpened, setIsChangeModeWarningModalOpened] = useState(false);
    const [confirmationTexts, setConfirmationTexts] = useState({
        titleText: '',
        questionLabel: '',
    });

    const editorMode = config?.editorMode || tabOptions[0].value;

    const joditBlurHandler = (value) => {
        const cleanedValue = value === joditEmptyContent ? '' : value;
        handleChange(cleanedValue);
    };

    const onEditorModeChange = (e) => {
        const selectedEditorMode = e.target.value;

        const selectedOption = tabOptions.find((option) => option.value === selectedEditorMode);
        const selectedOptionDisplayName = t(selectedOption.label);

        tempEditorModeRef.current = selectedEditorMode;

        setConfirmationTexts({
            titleText: t(l.SwitchEditorModeTitle, { mode: selectedOptionDisplayName }),
            questionLabel: l.AreYouSureYouWantToSwitchEditorMode,
        });
        setIsChangeModeWarningModalOpened(true);
    };

    const handleEditorFocus = useCallback(() => {
        onEditorFocus(dynamicVariablesHintTexts[editorMode]);
    }, [editorMode, onEditorFocus]);

    const changeModeConfirmHandler = () => {
        handleConfigChange({ editorMode: tempEditorModeRef.current });
        setIsChangeModeWarningModalOpened(false);
        onEditorFocus(dynamicVariablesHintTexts[tempEditorModeRef.current]);
    };

    const handleMosaicoConfigChange = useCallback(
        (config) => {
            handleConfigChange({ config, editorMode });
        },
        [editorMode, handleConfigChange],
    );

    const handleJoditChange = useCallback(
        (value) => {
            if (validatorTimeoutRef.current) {
                clearTimeout(validatorTimeoutRef.current);
            }

            validatorTimeoutRef.current = setTimeout(() => {
                validator({ [templateLangKey + bodyPropertyName]: value === joditEmptyContent ? '' : value });
            }, 300);
        },
        [bodyPropertyName, templateLangKey, validator],
    );

    const handleChangeSync = useCallback((value) => handleChange(value, 0), [handleChange]);

    useEffect(() => {
        if (isInitialFocus) {
            handleEditorFocus();
        }
    }, [handleEditorFocus, isInitialFocus]);

    return (
        <div className="html-editors">
            <div className="editor-tabs-container">
                <RadioGroup
                    name="controlled"
                    options={tabOptions}
                    type="tab"
                    value={editorMode}
                    onChange={onEditorModeChange}
                />
            </div>
            <div className="editor-wrapper">
                <div
                    className={classNames('editor-border-wrapper', {
                        error: !validationState.isValid,
                        mosaicoEditor: editorMode === TemplateEditorTypes.MosaicoEditor,
                        htmlCodeEditor: editorMode === TemplateEditorTypes.HtmlCodeEditor,
                        joditEditor: editorMode === TemplateEditorTypes.JoditEditor,
                        blockEditor: editorMode === TemplateEditorTypes.BlockEditor,
                    })}
                >
                    {editorMode === TemplateEditorTypes.MosaicoEditor && (
                        <MosaicoEditor
                            onChange={handleChangeSync}
                            onConfigChange={handleMosaicoConfigChange}
                            config={config?.config}
                            dynamicVariables={dynamicVariables}
                            dynamicVariableChannelKey={templateType}
                            isPartialHTML={isPartialHTML}
                            onFocus={handleEditorFocus}
                            defaultHTML={htmlText}
                        />
                    )}
                    {editorMode === TemplateEditorTypes.BlockEditor && (
                        <BlockEditor
                            dynamicVariables={dynamicVariables}
                            defaultHTML={htmlText}
                            onChange={handleChange}
                            getAssets={getAssets}
                            addAsset={addAsset}
                            isPartialHTML={isPartialHTML}
                            dynamicVariableChannelKey={templateType}
                            onFocus={handleEditorFocus}
                        />
                    )}
                    {editorMode === TemplateEditorTypes.JoditEditor && (
                        <JoditEditor
                            initialValue={htmlText}
                            onBlur={joditBlurHandler}
                            onChange={handleJoditChange}
                            updateInsertionHandler={updateInsertionHandler}
                            onFocus={handleEditorFocus}
                        />
                    )}
                    {editorMode === TemplateEditorTypes.HtmlCodeEditor && (
                        <HtmlCodeEditor
                            value={htmlText}
                            onChange={handleChange}
                            onBlur={handleChange}
                            placeholder={t(l.TypeYourText)}
                            updateInsertionHandler={updateInsertionHandler}
                            onFocus={handleEditorFocus}
                        />
                    )}
                </div>
                <span className="error-text">{validationState.errorText}</span>
            </div>
            <ConfirmationModal
                onOk={changeModeConfirmHandler}
                onCancel={() => setIsChangeModeWarningModalOpened(false)}
                isVisibleModal={isChangeModeWarningModalOpened}
                titleText={confirmationTexts.titleText}
                iconType="bc-icon-danger-48"
                actionLabel={t(l.Switch)}
                className={ModalsClassNames.Warning}
                questionLabel={confirmationTexts.questionLabel}
            />
        </div>
    );
};

HTMLEditors.defaultProps = {
    isPartialHTML: false,
    isInitialFocus: false,
    updateInsertionHandler: noop,
    onEditorFocus: noop,
};

HTMLEditors.propTypes = {
    tabOptions: PropTypes.array.isRequired,
    htmlText: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    config: PropTypes.object,
    handleConfigChange: PropTypes.func,
    templateLangKey: PropTypes.string.isRequired,
    getAssets: PropTypes.func,
    addAsset: PropTypes.func,
    validationState: PropTypes.shape({
        isValid: PropTypes.bool,
        errorText: PropTypes.string,
    }),
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
    templateType: PropTypes.string.isRequired,
    bodyPropertyName: PropTypes.string.isRequired,
    isPartialHTML: PropTypes.bool,
    updateInsertionHandler: PropTypes.func,
    onEditorFocus: PropTypes.func,
    isInitialFocus: PropTypes.bool,
};

export default HTMLEditors;
