import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop, isNil, keys, isEqual, sortBy, cloneDeep, isEmpty } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import UI Components
import { BusyLoader, Modal, Button } from '@geneui/components';
// Import Components
import { FromToBlocks, ButtonWithLoader } from 'components';
// Import Services
import { DashboardHttpService } from 'services/http';
// Import Constants
import { l, WidgetsCategory, Widgets } from 'constants/common';

const WidgetChooser = ({ onSave, modalOpenedState, setModalOpenedState }) => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const fromToBlocksRef = useRef();

    const [widgetChooserIsLoading, setWidgetChooserIsLoading] = useState(true);
    const [widgetChooserSaveIsLoading, setWidgetChooserSaveIsLoading] = useState(false);
    const [widgetChooserSaveIsActive, setWidgetChooserSaveIsActive] = useState(false);
    const [leftWidgetsColumnsData, setLeftWidgetsColumnsData] = useState(null);
    const [rightWidgetsColumnsData, setRightWidgetsColumnsData] = useState(null);

    const mapToLocalModel = (item) => ({
        ...item,
        isVisible: true,
        isChecked: false,
        childrenList: isNil(item.childrenList)
            ? []
            : item.childrenList.map((row) => ({ ...row, isChecked: false, isVisible: true })),
    });

    const { doGetRequest, doPostRequest } = useRequest();

    const { getDashboardWidgetsRequest, setDashboardWidgetsRequest } = useRef({
        getDashboardWidgetsRequest: DashboardHttpService.getDashboardWidgets(),
        setDashboardWidgetsRequest: DashboardHttpService.setDashboardWidgets(),
    }).current;

    const groupByWithCategory = (listItems) => {
        return listItems.reduce((acc, item) => {
            // eslint-disable-next-line react/prop-types
            (acc[item.CategoryId] = acc[item.CategoryId] || []).push(item);
            return acc;
        }, {});
    };
    const groupByWithId = (listItems) => {
        return listItems.reduce((acc, item) => {
            acc[+item.id] = cloneDeep(item.childrenList.map((item) => +item.id));
            return acc;
        }, {});
    };

    const mapChild = (item) => ({ parentId: item.CategoryId, name: item.Name, id: item.WidgetId });

    const getPossible = (rightBlockList) => {
        return rightBlockList
            .reduce((acc, item) => {
                acc = [...acc, ...item.childrenList];
                return acc;
            }, [])
            .map((item) => item.id.toString());
    };

    const widgetChooserSaveHandler = () => {
        const possible = getPossible(data.rightBlockList);
        setWidgetChooserSaveIsLoading(true);
        doPostRequest(setDashboardWidgetsRequest.request, {
            successCallback: () => {
                setLeftWidgetsColumnsData(data.leftBlockList);
                setRightWidgetsColumnsData(data.rightBlockList);
                onSave(groupByWithId(data.rightBlockList));
                setModalOpenedState(false);
            },
            requestBody: possible,
        }).then(() => setWidgetChooserSaveIsLoading(false));
    };

    const mapInitData = (tmpGroupedByCategory, filter = (item) => item.IsVisible) => {
        return keys(tmpGroupedByCategory)
            .map((id) => ({
                id: id,
                name: WidgetsCategory[id],
                childrenList: tmpGroupedByCategory[id].filter(filter).map(mapChild),
            }))
            .map(mapToLocalModel);
    };

    useEffect(() => {
        setWidgetChooserIsLoading(true);
        doGetRequest(getDashboardWidgetsRequest.request, {
            successCallback: (data) => {
                const tmpGroupedByCategory = groupByWithCategory(data.filter((item) => !isNil(Widgets[item.WidgetId])));
                const tmpRightWidgetsColumns = mapInitData(tmpGroupedByCategory);
                const tmpLeftWidgetsColumns = mapInitData(tmpGroupedByCategory, (item) => !item.IsVisible);
                setLeftWidgetsColumnsData(tmpLeftWidgetsColumns);
                setRightWidgetsColumnsData(tmpRightWidgetsColumns);
                // init data
                onSave(groupByWithId(tmpRightWidgetsColumns));
            },
        }).then(() => setWidgetChooserIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFromToBlockUpdates = ({ leftBlockList, rightBlockList }) => {
        const tmpRightBlockList = getPossible(rightBlockList);
        setWidgetChooserSaveIsActive(
            !isEmpty(tmpRightBlockList) &&
                !isEqual(sortBy(getPossible(rightWidgetsColumnsData)), sortBy(tmpRightBlockList)),
        );
        setData({ leftBlockList, rightBlockList });
    };

    const getCustomFooter = () => {
        return (
            <>
                <Button appearance="minimal" onClick={onCancelHandler}>
                    {t(l.Close)}
                </Button>
                <Button
                    disabled={widgetChooserSaveIsLoading || !widgetChooserSaveIsActive}
                    appearance="minimal"
                    onClick={onResetHandler}
                >
                    {t(l.Reset)}
                </Button>
                <ButtonWithLoader
                    color="primary"
                    onClick={widgetChooserSaveHandler}
                    isLoading={widgetChooserSaveIsLoading}
                    disabled={widgetChooserSaveIsLoading || !widgetChooserSaveIsActive}
                >
                    {t(l.Save)}
                </ButtonWithLoader>
            </>
        );
    };

    const onResetHandler = () => {
        fromToBlocksRef.current.reset();
    };

    const onCancelHandler = () => {
        setModalOpenedState(false);
    };

    const cleanUp = () => {
        return () => {
            getDashboardWidgetsRequest.cancel('WidgetChooser:getDashboardWidgetsRequest');
            setDashboardWidgetsRequest.cancel('WidgetChooser:setDashboardWidgetsRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <Modal
            title={t(l.WidgetsChooser)}
            className="d-a-d-popup"
            background="dark-background"
            closable={true}
            position="center"
            onCancel={onCancelHandler}
            cancelText={t(l.Reset)}
            okText={t(l.Save)}
            closeOnClickOutside={true}
            visible={modalOpenedState}
            size="content-size"
            footer={getCustomFooter()}
        >
            <div className="crm-report-d-a-d-container">
                {!widgetChooserIsLoading && !isNil(leftWidgetsColumnsData) && !isNil(rightWidgetsColumnsData) ? (
                    <FromToBlocks
                        leftBlockList={leftWidgetsColumnsData}
                        rightBlockList={rightWidgetsColumnsData}
                        leftBlockWithParent={true}
                        rightBlockWithParent={true}
                        getUpdate={getFromToBlockUpdates}
                        leftBlockWithPosition={false}
                        rightBlockWithPosition={false}
                        leftBlockTitle={t(l.Possible)}
                        rightBlockTitle={t(l.Current)}
                        ref={fromToBlocksRef}
                    />
                ) : (
                    <BusyLoader
                        isBusy={
                            widgetChooserIsLoading || isNil(leftWidgetsColumnsData) || isNil(rightWidgetsColumnsData)
                        }
                        type="spinner"
                        loadingText={t(l.Loading)}
                    />
                )}
            </div>
        </Modal>
    );
};

WidgetChooser.propTypes = {
    modalOpenedState: PropTypes.bool,
    setModalOpenedState: PropTypes.func,
    onSave: PropTypes.func,
};

WidgetChooser.defaultProps = {
    isLoading: false,
    onSave: noop,
    setModalOpenedState: noop,
};

export default WidgetChooser;
