/* eslint-disable jsx-a11y/no-autofocus */
import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, isEmpty, isNil, first, isObject, last } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CJReferenceOptionValidationSchema } from 'validators/schemas.validation';
import { yupSchemaValidate, getReferencePropertyErrorMessage } from 'validators/service.validator';
// Import Constants
import { l, defaultLogicFunction } from 'constants/common';
// Import UI Components
import { Popover, Button, Option, Icon, Tooltip, Empty, Dropdown, ExtendedInput } from '@geneui/components';
// Import Hooks
import { useOnClickOutside } from 'hooks';
// Import Services
import { getCustomerJourneyBlockTranslatableErrorText } from 'services/customerJourney';
// Import SCSS
import 'assets/scss/cascadeDropdown.scss';

const CJCascadeDropdown = ({ data, value, onChange, option, node, elements, onValidationChange, isDisabled }) => {
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [selectedLogicFunction, setSelectedLogicFunction] = useState();

    const selectedOptionValidation = (value, selectedLogicFunction) => {
        let tmpBlockId = null;
        let tmpPropertyName = null;
        let tmpFunctionName = null;
        let tmpBaseTypes = null;
        if (!isNil(value) && isObject(value)) {
            if (!isNil(value.parentValue)) {
                tmpBlockId = value.parentValue;
            }
            if (!isNil(value.value)) {
                tmpPropertyName = value.value;
            }
            if (!isNil(value?.logicFunction)) {
                tmpFunctionName = value.logicFunction;

                tmpBaseTypes = isNil(selectedLogicFunction?.returnType)
                    ? option?.BaseTypes
                    : selectedLogicFunction?.returnType?.BaseTypes;
            }
        }

        const schema = CJReferenceOptionValidationSchema(
            elements,
            node,
            isNil(tmpBaseTypes) ? '' : last(first(tmpBaseTypes).split('.')),
            option?.ModelRelationScope,
            tmpBlockId === node?.id
                ? {
                      typeName: option?.SelfModelTypeName,
                      properties: option?.SelfModelProperties?.Properties,
                  }
                : {},
        );
        const tmpValidationResult = yupSchemaValidate(
            schema,
            { blockId: tmpBlockId, propertyName: tmpPropertyName, logicFunction: tmpFunctionName },
            (e) => {
                setErrorMessages([getReferencePropertyErrorMessage(e)]);
            },
        );

        setIsValid(() => tmpValidationResult);
    };

    useEffect(() => {
        onValidationChange(isValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const cascadeDropdownRef = useRef(null);
    const valueInputRef = useRef(null);
    const searchHandlerTimeout = useRef(null);

    const [isOpened, setIsOpened] = useState(false);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedParent, setSelectedParent] = useState(null);
    const [isVisibleChildrenList, setIsVisibleChildrenList] = useState(false);
    const [childrenList, setChildrenList] = useState([]);
    const [allChildrenList, setAllChildrenList] = useState([]);
    const [logicFunctionsData, setLogicFunctionsData] = useState([]);
    const [isSearchResult, setIsSearchResult] = useState(false);

    const showParents = () => {
        setIsVisibleChildrenList(false);
        setChildrenList([]);
        setSelectedParent(null);
        setIsSearchResult(false);
    };

    const open = () => {
        if (isOpened) return;
        setIsOpened(true);
        valueInputRef.current.focus();
        valueInputRef.current.selectionStart = selectedOptionLabel?.length || 0;
    };

    const close = () => {
        setIsOpened(false);
        setIsVisibleChildrenList(false);
        showParents();
    };

    const toggleDropdown = () => {
        if (isOpened) {
            close();
        } else {
            open();
        }
    };

    useOnClickOutside(
        cascadeDropdownRef,
        useCallback(() => {
            if (isOpened) {
                close();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isOpened]),
    );

    const onSelectParent = (label, value, children, parentIndex) => {
        return () => {
            setIsVisibleChildrenList(true);
            setChildrenList(children);
            setSelectedParent({ label, value, children, parentIndex });
        };
    };

    const onSelectOption = (label, value, optionIndex, parentLabel, parentValue, parentIndex, logicFunctions) => {
        return () => {
            // const { label: parentLabel, value: parentValue, parentIndex } = selectedParent;
            setLogicFunctionsData(
                logicFunctions.map((logicFunction) => ({
                    ...logicFunction,
                    label: t(logicFunction.label),
                })),
            );
            const tmpLogicFunction = first(logicFunctions);
            const optionValue = {
                label,
                value,
                parentLabel,
                parentValue,
                parentIndex,
                optionIndex,
                logicFunction: tmpLogicFunction.value,
            };
            const tmpSelectedLogicFunction = { value: tmpLogicFunction.value, returnType: tmpLogicFunction.returnType };
            setSelectedLogicFunction(tmpSelectedLogicFunction);
            setSelectedOption(optionValue);
            setSelectedParent({ label, value, children: data[parentIndex]?.children, parentIndex });

            setSelectedOptionLabel(`${selectedParent?.label} / ${t(optionValue?.label)}`);

            selectedOptionValidation(optionValue, tmpSelectedLogicFunction);
            onChange(optionValue);
            close();
        };
    };

    const isOptionSelected = (option) => {
        return option?.value === selectedOption?.value && option?.parentValue === selectedOption?.parentValue;
    };

    useEffect(() => {
        if (isNil(value)) {
            selectedOptionValidation(value);
            return;
        }

        const parentDataIndex = data.findIndex((parent) => parent.value === value?.parentValue);
        let tmpSelectedLogicFunction = null;
        if (parentDataIndex !== -1) {
            const parentData = data[parentDataIndex];
            setSelectedParent({
                label: parentData.label,
                value: parentData.value,
                children: parentData.children,
                parentIndex: parentDataIndex,
            });

            const optionDataIndex = parentData.children.findIndex((option) => option.value === value?.value);
            const optionData = parentData.children[optionDataIndex];
            const optionValue = {
                parentLabel: parentData.label,
                parentValue: parentData.value,
                parentIndex: parentDataIndex,
                label: optionData.label,
                value: optionData.value,
                optionIndex: optionDataIndex,
                logicFunction: value?.logicFunction,
            };

            const tmpFunction = optionData.logicFunctions.find((func) => func.value === value?.logicFunction);
            tmpSelectedLogicFunction = { value: tmpFunction?.value, returnType: tmpFunction?.returnType };
            setSelectedLogicFunction(tmpSelectedLogicFunction);
            setSelectedOption(optionValue);

            setSelectedOptionLabel(`${parentData?.label} / ${t(optionData?.label)}`);

            setLogicFunctionsData(
                optionData.logicFunctions.map((logicFunction) => ({
                    ...logicFunction,
                    label: t(logicFunction.label),
                })),
            );
        } else {
            const selectedElement = elements.find((el) => el.id === value?.parentValue);
            if (!isNil(selectedElement)) {
                // TODO: delete when block delete must be delete all reference in api model
                const tmpProperties =
                    !isNil(option?.SelfModelTypeName) && node?.id === selectedElement?.id
                        ? option?.SelfModelProperties
                        : selectedElement?.data?.metaData?.Properties;
                if (!isNil(tmpProperties)) {
                    const tmpProperty = tmpProperties.find((prop) => prop.Name === value?.value);
                    if (!isNil(tmpProperty)) {
                        setSelectedOptionLabel(`${selectedElement?.data.name} / ${t(tmpProperty?.Name)}`);
                        if (!isNil(tmpProperty?.Functions)) {
                            const tmpFunction = tmpProperty.Functions.find(
                                (logicFunction) => logicFunction.value === value?.logicFunction,
                            );
                            tmpSelectedLogicFunction = {
                                value: tmpFunction?.value,
                                returnType: tmpFunction.returnType,
                                isDisabled: true,
                            };
                            setSelectedLogicFunction(tmpSelectedLogicFunction);
                            setLogicFunctionsData(
                                tmpProperty.Functions.map((logicFunction) => ({
                                    ...logicFunction,
                                    label: t(logicFunction.label),
                                })),
                            );
                        }
                    }
                }
            }
        }
        selectedOptionValidation(value, tmpSelectedLogicFunction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const searchHandler = (searchString) => {
        setSelectedOptionLabel(searchString);

        const optionLabel = first(searchString?.split(' / ').reverse());

        clearTimeout(searchHandlerTimeout.current);

        searchHandlerTimeout.current = setTimeout(() => {
            setIsVisibleChildrenList(true);
            setIsSearchResult(true);
            setChildrenList(
                allChildrenList.filter(({ label }) => {
                    return (
                        t(label).toLowerCase().includes(optionLabel.toLowerCase()) ||
                        optionLabel.toLowerCase() === t(label).toLowerCase()
                    );
                }),
            );
        }, 300);
    };

    useEffect(() => {
        if (!isEmpty(data)) {
            setAllChildrenList(
                data.reduce((acc, { children }, index) => {
                    const parentOption = data[index];
                    return acc.concat(
                        children.map((child, childIndex) => {
                            child.optionIndex = childIndex;
                            child.parentIndex = index;
                            child.parentLabel = parentOption.label;
                            child.parentValue = parentOption.value;
                            return child;
                        }),
                    );
                }, []),
            );
        }
    }, [data]);

    const logicFunctionChangeHandler = (event) => {
        const tmpValue = event?.value;
        setSelectedLogicFunction({ value: tmpValue, returnType: event?.returnType });
        setSelectedOption({ ...selectedOption, logicFunction: tmpValue });
        onChange({ ...selectedOption, logicFunction: tmpValue });
    };

    return (
        <>
            <div ref={cascadeDropdownRef} className={classNames('crm-cascade-dropdown', { active: isOpened })}>
                <div className={classNames('crm-cascade-dropdown-input', { invalid: !isValid })}>
                    <ExtendedInput
                        ref={valueInputRef}
                        onFocus={open}
                        onChange={({ currentTarget }) => searchHandler(currentTarget?.value)}
                        value={selectedOptionLabel}
                        placeholder={t(l.Property)}
                        label={t(l.Property)}
                        labelAppearance="swap"
                        isValid={isDisabled || isValid}
                        errorText={getCustomerJourneyBlockTranslatableErrorText(t, errorMessages)}
                        disabled={isDisabled}
                    />
                    <Button appearance="minimal" icon="bc-icon-arrow-down" onClick={toggleDropdown} />
                </div>
                {isOpened && (
                    <Popover
                        isOpen={isOpened}
                        extendTargetWidth={false}
                        maxHeight={206}
                        height={206}
                        scrollbarNeeded={true}
                    >
                        <div className="crm-cascade-dropdown-content">
                            {isEmpty(data) ? (
                                <Empty title={t(l.NoDataToDisplay)} />
                            ) : (
                                <>
                                    {!isVisibleChildrenList ? (
                                        data.map(({ label, value, children }, parentIndex) => {
                                            return (
                                                <Tooltip
                                                    text={label}
                                                    position="right"
                                                    transitionDuration={0.001}
                                                    key={parentIndex}
                                                >
                                                    <Option
                                                        key={parentIndex}
                                                        title={label}
                                                        rightCustomElement={<Icon type="bc-icon-arrow-right" />}
                                                        onClick={onSelectParent(label, value, children, parentIndex)}
                                                    />
                                                </Tooltip>
                                            );
                                        })
                                    ) : (
                                        <div>
                                            {!isSearchResult && (
                                                <Tooltip
                                                    text={selectedParent?.label}
                                                    transitionDuration={0.001}
                                                    position="right"
                                                >
                                                    <Option
                                                        title={selectedParent?.label}
                                                        onClick={showParents}
                                                        leftCustomElement={<Icon type="bc-icon-arrow-left" />}
                                                        border="bottom"
                                                    />
                                                </Tooltip>
                                            )}

                                            {childrenList.map((option) => {
                                                const {
                                                    label,
                                                    value,
                                                    optionIndex,
                                                    parentLabel,
                                                    parentValue,
                                                    parentIndex,
                                                    logicFunctions,
                                                } = option;
                                                return (
                                                    <Tooltip
                                                        text={
                                                            isSearchResult ? `${parentLabel} / ${t(label)}` : t(label)
                                                        }
                                                        position="right"
                                                        transitionDuration={0.001}
                                                        key={`${optionIndex}_${parentLabel}_${label}`}
                                                    >
                                                        <Option
                                                            key={`${optionIndex}_${parentLabel}_${label}`}
                                                            className={classNames({ active: isOptionSelected(option) })}
                                                            title={
                                                                isSearchResult
                                                                    ? `${parentLabel} / ${t(label)}`
                                                                    : t(label)
                                                            }
                                                            onClick={onSelectOption(
                                                                label,
                                                                value,
                                                                optionIndex,
                                                                parentLabel,
                                                                parentValue,
                                                                parentIndex,
                                                                logicFunctions,
                                                            )}
                                                        />
                                                    </Tooltip>
                                                );
                                            })}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </Popover>
                )}
            </div>

            <div className="crm-cascade-dropdown-function">
                {!isEmpty(logicFunctionsData) &&
                    (logicFunctionsData?.length > 1 || first(logicFunctionsData)?.value !== defaultLogicFunction) && (
                        <Dropdown
                            isMultiSelect={false}
                            disabled={selectedLogicFunction?.isDisabled === true || isDisabled}
                            hasSearch={true}
                            inputSize="default"
                            placeholder={t(l.Function)}
                            label={t(l.Function)}
                            labelAppearance="swap"
                            appearance="outline"
                            data={logicFunctionsData}
                            onChange={logicFunctionChangeHandler}
                            value={selectedLogicFunction?.value}
                            searchPlaceholderText={t(l.Search)}
                            noDataText={t(l.NoDataFound)}
                        />
                    )}
            </div>
        </>
    );
};

CJCascadeDropdown.propTypes = {
    value: PropTypes.string.isRequired, // TODO value prop should be optional
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
            children: PropTypes.array.isRequired,
        }),
    ).isRequired,
    defaultValue: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }),
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object,
    onChange: PropTypes.func,
    onValidationChange: PropTypes.func,
    isDisabled: PropTypes.bool,
};

CJCascadeDropdown.defaultProps = {
    defaultClassNames: '',
    onChange: noop,
    onValidationChange: noop,
    isDisabled: false,
};

export default memo(CJCascadeDropdown);
