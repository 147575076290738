/* eslint-disable react/display-name */
import React from 'react';
// import Component
import { CopyToClipboard, TittleLink } from 'components';
// Import UI Components
import { Icon } from '@geneui/components';
// Import Constants
import { RoutesList } from 'routes';
import {
    FilterHolderFields,
    FilterComparisions,
    l,
    CustomerJourneyStatus,
    CustomerJourneyStatusLabels,
    StateModesLabels,
    DateTimeFormat,
    DataTableColumnsCustomTypes,
    EntityStates,
    ArchiveStates,
    RealtimePromotionTypes,
    PromotionTypesDropdownData,
} from 'constants/common';
import { Helpers } from 'services';

const { REALTIME_JOURNEY_VIEW, REALTIME_ACTION_VIEW } = RoutesList;

const { customMomentWithoutTimezoneConversion } = Helpers;
const { In, Equals, Like, Between, IsBlank } = FilterComparisions;
const { textInput, select, rangeDate, multiSelect } = FilterHolderFields;

const getTitle = (row) => {
    return row.data.Name.Name;
};

const FilterHolderFieldsConfigs = {
    idFieldName: 'WorkflowId',
    storeKey: 'customerJourneyList',
    defaultInputsData: {
        defaultInputsDataIds: {
            CategoryId: 4,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'WorkflowId',
                comparision: Equals,
                displayName: l.WorkflowId,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Name',
                comparision: Like,
                displayName: l.Title,
                value: textInput.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Status',
                comparision: Equals,
                displayName: l.Status,
                value: select.defaultValue,
                data: CustomerJourneyStatus,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatorName',
                comparision: Like,
                displayName: l.User,
                value: textInput.defaultValue,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CategoryId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Label,
                value: multiSelect.defaultValue,
                data: [],
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.CreatedDate,
                value: rangeDate.defaultValue,
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'StartDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.AutoStart,
                value: rangeDate.defaultValue,
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'EndDate',
                comparision: Between,
                popoverAlign: 'end',
                displayName: l.AutoEnd,
                value: rangeDate.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'IsUsed',
                comparision: Equals,
                displayName: l.State,
                value: select.defaultValue,
                data: EntityStates,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ArchivedDate',
                comparision: IsBlank,
                displayName: l.Archive,
                value: select.defaultValue,
                data: ArchiveStates,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'PromotionType',
                comparision: Equals,
                displayName: l.PromotionType,
                value: select.defaultValue,
                data: PromotionTypesDropdownData,
            },
        ],
    },
};

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.WorkflowId),
                dataKey: 'WorkflowId',
                sortable: true,
                colRenderer: (id) => <CopyToClipboard>{id}</CopyToClipboard>,
            },
            {
                text: t(l.Title),
                dataKey: 'Name',
                sortable: true,
                isPermanent: true,
                // eslint-disable-next-line react/prop-types
                colRenderer: ({ Name, WorkflowId, Status, HasVisualization, PromotionType }) => {
                    const path =
                        PromotionType === +RealtimePromotionTypes.ActionCampaign
                            ? REALTIME_ACTION_VIEW
                            : REALTIME_JOURNEY_VIEW;
                    const isStarting = +Status === +CustomerJourneyStatusLabels.Starting;
                    const isUpdating = +Status === +CustomerJourneyStatusLabels.Updating;
                    const isResuming = +Status === +CustomerJourneyStatusLabels.Resuming;
                    const isSuspending = +Status === +CustomerJourneyStatusLabels.Suspending;
                    const isMiddle = () => isUpdating || isStarting || isResuming || isSuspending;
                    const isClickable = Status !== +CustomerJourneyStatusLabels.Invalid && !isMiddle();
                    return (
                        <div className="crm-list-column-title-icon-components">
                            <TittleLink
                                isClickable={isClickable}
                                path={path}
                                name={Name}
                                queryParams={{ workflowId: WorkflowId }}
                            />
                            {HasVisualization && <Icon type="bc-icon-visualization" />}
                        </div>
                    );
                },
            },
            {
                text: t(l.User),
                dataKey: 'CreatorName',
                sortable: true,
            },
            {
                text: t(l.Status),
                dataKey: 'Status',
                sortable: true,
                colRenderer: (status) => (
                    <div className={`crm-status-badge-cnt crm-cj-status-label-${status}`}>
                        {t(CustomerJourneyStatusLabels[status])}
                    </div>
                ),
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.CreatedDate),
                dataKey: 'CreatedDate',
                sortable: true,
                formatter: (CreatedDate) => {
                    return CreatedDate && customMomentWithoutTimezoneConversion(CreatedDate).format(DateTimeFormat);
                },
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.AutoStart),
                dataKey: 'StartDate',
                sortable: true,
                formatter: (StartDate) => {
                    return StartDate && customMomentWithoutTimezoneConversion(StartDate).format(DateTimeFormat);
                },
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.AutoEnd),
                dataKey: 'EndDate',
                sortable: true,
                formatter: (EndDate) => {
                    return EndDate && customMomentWithoutTimezoneConversion(EndDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Mode),
                dataKey: 'State',
                sortable: true,
                colRenderer: (state) => (
                    <div className={`crm-mode-badge-cnt crm-cj-mode-label-${state}`}>{t(StateModesLabels[state])}</div>
                ),
            },
            {
                text: t(l.State),
                dataKey: 'IsUsed',
                sortable: true,
                colRenderer: (isUsed) => (
                    <div className={`crm-used-or-not-used-badge-cnt crm-used-or-not-used-label-${isUsed}`}>
                        {isUsed ? t(l.Used) : t(l.NotUsed)}
                    </div>
                ),
            },
            {
                text: t(l.PromotionType),
                dataKey: 'PromotionType',
                sortable: true,
                colRenderer: (promotionType) => <div>{t(RealtimePromotionTypes[promotionType])} </div>,
            },
        ],
    };
};

export { FilterHolderFieldsConfigs, DataTableColumnsConfigs, getTitle };
