import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { UtilsHttpService } from 'services/http';
import { subscribe } from 'services/event';
// Import Actions
import { ReportColumnsAction } from 'actions';
// Import Constants
import { GlobalEvents, SubscriberSources } from 'constants/common';

const { setReportColumns, clearReportColumns } = ReportColumnsAction;

let filteredColumns = new WeakMap();
let isRequested = false;

const getFilteredColumns = (predicate, reportColumns) => {
    if (isNil(predicate) || isEmpty(reportColumns)) {
        return reportColumns;
    }

    if (!filteredColumns.has(predicate)) {
        filteredColumns.set(predicate, predicate([...reportColumns]));
    }

    return filteredColumns.get(predicate);
};

/**
 * @typedef {Object} ReportColumn
 */

/**
 * A custom React Hook that provides access to report columns and allows filtering based on a predicate logic.
 * @param {(columns: ReportColumn[]) => ReportColumn[]} predicate An optional predicate function used to filter the report columns. It should accept a column as a parameter and return a boolean.
 * If provided, only columns satisfying the predicate will be included in the result.
 * @returns {[ReportColumn[], boolean]}
 */
const useReportColumns = (predicate) => {
    const { doGetRequest } = useRequest();
    const dispatch = useDispatch();
    const reportColumns = useSelector((state) => state.reportColumns);

    const { getAllKPIs } = useMemo(
        () => ({
            getAllKPIs: UtilsHttpService.getAllKPIs(),
        }),
        [],
    );

    useEffect(() => {
        if (isEmpty(reportColumns) && !isRequested) {
            isRequested = true;

            subscribe(GlobalEvents.PartnerChanged, SubscriberSources.ReportColumn, () => {
                dispatch(clearReportColumns());
                filteredColumns = new WeakMap();
                isRequested = false;
            });

            doGetRequest(getAllKPIs.request, {
                successCallback: (columns) => {
                    dispatch(setReportColumns(columns));
                },
            });
        }
    }, [dispatch, doGetRequest, getAllKPIs.request, reportColumns]);

    return [getFilteredColumns(predicate, reportColumns), isEmpty(reportColumns)];
};

export default useReportColumns;
