import { isNil, keys, values } from 'lodash';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { DynamicPopUpDeviceTypesLabels, TemplateInputTypes, TemplateTypesLabels, l } from 'constants/common';
import { TemplateType, ViberTemplateConfig } from './config';

const { getTranslatableErrorText } = Helpers;

function createTemplateResultData(Name, Description, data, type, eventId) {
    return values(data).map((template) => {
        let body = template[TemplateInputTypes.Body];

        if (type === TemplateType.DynamicPopUp) {
            body = {
                Content: replaceLinksTarget(body, '_top', '_blank'),
                Page: { Comparison: template[TemplateInputTypes.UrlType], Value: template[TemplateInputTypes.Url] },
                Platforms: template[TemplateInputTypes.DeviceType],
            };

            body = JSON.stringify(body);
        }

        return {
            ...template,
            Body: body,
            ...(template.UIConfig && { UIConfig: JSON.stringify(template.UIConfig) }),
            DeliveryMethod: `${TemplateTypesLabels[type]}`,
            Description,
            Name: template.Language ? `${Name} (${template.Language})` : Name,
            EventId: eventId,
        };
    });
}

const groupDynamicVariablesByDisplayName = (channels) => {
    const groupedDynamicVariables = [];

    channels.forEach((channel) => {
        channel.variables.forEach((dynamicVariable) => {
            const foundVariable = groupedDynamicVariables.find(
                (item) => item.displayNameKey === dynamicVariable.DisplayNameKey,
            );

            if (foundVariable) {
                foundVariable.channels[channel.type] = dynamicVariable;
            } else {
                groupedDynamicVariables.push({
                    displayNameKey: dynamicVariable.DisplayNameKey,
                    channels: {
                        [channel.type]: dynamicVariable,
                    },
                });
            }
        });
    });

    return groupedDynamicVariables;
};

//todo: don't remove until backend endpoint will be changed to all template models
function createPushNotificationTemplateResultData(
    Name,
    Description,
    data,
    type,
    { appTypesValue, uploadedImageUrl, routeValue, cascadeValue },
    eventId,
) {
    const tmpCascadeValue = { ...cascadeValue };

    return values(data).map(({ Language, Body, TitleOfPushNotification, TemplateId }) => {
        return {
            Language,
            DeliveryMethod: `${TemplateTypesLabels[type]}`,
            Description,
            Name: Language ? `${Name} (${Language})` : Name,
            Body: JSON.stringify({
                message_title: TitleOfPushNotification,
                message_body: Body,
                app_type: appTypesValue,
                extra_info: {
                    ...tmpCascadeValue,
                    image_url: uploadedImageUrl,
                    notification_routes: routeValue,
                },
            }),
            EventId: eventId,
            TemplateId: TemplateId,
        };
    });
}

function createPushNotificationTemplateDefaultData(data) {
    return data.reduce((acc, template) => {
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            TitleOfPushNotification: JSON.parse(template.Body).message_title,
            Body: JSON.parse(template.Body).message_body,
            isDeletable: !(template?.IsUsed ?? false),
        };
        return acc;
    }, {});
}

function createPopUpNotificationTemplateResultData(
    Name,
    Description,
    data,
    type,
    { popUpTypeValue, urlTypeValue, urlValue, popUpTypesData },
    eventId,
) {
    const tmpPopUpType = popUpTypesData.find((item) => item.value === popUpTypeValue);

    return values(data).map(({ Language, TemplateId }) => {
        return {
            DeliveryMethod: `${TemplateTypesLabels[type]}`,
            Description,
            Name: Language ? `${Name} (${Language})` : Name,
            Body: JSON.stringify({
                Alias: tmpPopUpType?.Alias,
                Page: { Comparison: urlTypeValue, Value: urlValue },
                Id: popUpTypeValue,
            }),
            TemplateId: TemplateId,
            EventId: eventId,
        };
    });
}

function htmlStringToDomElement(htmlText) {
    const domParser = new DOMParser();
    return domParser.parseFromString(htmlText, 'text/html');
}

function replaceLinksTarget(htmlText, targetType, excludeType) {
    const lastClosingTag = htmlText.match(/<\/([A-z]{3,})>$/)?.[1];
    const htmlDocument = htmlStringToDomElement(htmlText);
    const links = htmlDocument.querySelectorAll(`a:not([target='${excludeType}'])`);

    links.forEach((link) => {
        link.setAttribute('target', targetType);
    });

    switch (lastClosingTag) {
        case 'html':
            return htmlDocument.documentElement.outerHTML;
        case 'body':
            return htmlDocument.body.outerHTML;
        default:
            return htmlDocument.body.innerHTML;
    }
}

function generatePartialHtml(htmlTextOrDocument) {
    const htmlDocument =
        typeof htmlTextOrDocument === 'string' ? htmlStringToDomElement(htmlTextOrDocument) : htmlTextOrDocument;
    const wrapper = document.createElement('div');
    const styles = htmlDocument.head.querySelectorAll('style');

    // Apply Body attributes to the wrapper to keep appearance consistent
    htmlDocument.body.getAttributeNames().forEach((attributeName) => {
        const attributeValue = htmlDocument.body.getAttribute(attributeName);

        wrapper.setAttribute(attributeName, attributeValue);
    });
    styles.forEach((styles) => wrapper.appendChild(styles));

    wrapper.innerHTML = htmlDocument.body.innerHTML;

    return wrapper.outerHTML;
}

function createCMSPopUpNotificationTemplateResultData(name, description, data, popUpId) {
    return values(data).map(({ Language, TemplateId }) => {
        return {
            DeliveryMethod: `${TemplateTypesLabels[TemplateType.CMSPopUp]}`,
            Description: description,
            Name: Language ? `${name} (${Language})` : name,
            Body: JSON.stringify({ Alias: popUpId, Id: popUpId }),
            TemplateId: TemplateId,
        };
    });
}

function createViberNotificationTemplateResultData(Name, Description, data, type, isCascade, EventId) {
    return values(data).map(
        ({ Body, Language, UploadedImageUrl, ButtonText, ButtonUrl, SMSBody, TemplateId, ValidityPeriod }) => {
            return {
                Language,
                DeliveryMethod: `${TemplateTypesLabels[type]}`,
                Description,
                Name: Language ? `${Name} (${Language})` : Name,
                Body: JSON.stringify({
                    Text: Body,
                    ValidityPeriod: ValidityPeriod,
                    ButtonText: ButtonText,
                    ButtonUrl: ButtonUrl,
                    MediaUrl: UploadedImageUrl,
                    ...(isCascade && { SmsText: SMSBody }),
                }),
                TemplateId: TemplateId,
                EventId: EventId,
            };
        },
    );
}

function createViberNotificationTemplateDefaultData(data, isCascade, t) {
    return data.reduce((acc, template) => {
        const parsedBody = JSON.parse(template.Body);
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            Body: parsedBody.Text,
            ValidityPeriod: parsedBody.ValidityPeriod,
            ButtonText: parsedBody.ButtonText,
            ButtonUrl: parsedBody.ButtonUrl,
            UploadedImageUrl: parsedBody.MediaUrl,
            isDeletable: !(template?.IsUsed ?? false),
            FileName: parsedBody.MediaUrl === '' ? '' : t(l.File),
            ...(isCascade && { SMSBody: parsedBody.SmsText ?? '' }),
        };
        return acc;
    }, {});
}

function createFastexNotificationTemplateResultData(Name, Description, data, type, EventId) {
    return values(data).map(
        ({ Body, Language, NotificationCategory, SectionUrl, IsImportant, TemplateId, Subject }) => {
            return {
                Language,
                DeliveryMethod: `${TemplateTypesLabels[type]}`,
                Description,
                Name: Language ? `${Name} (${Language})` : Name,
                Body: JSON.stringify({
                    Body: Body,
                    Group: NotificationCategory,
                    SectionUrl: SectionUrl,
                    IsImportant: IsImportant,
                }),
                Subject: Subject,
                TemplateId: TemplateId,
                EventId: EventId,
            };
        },
    );
}

function createFastexNotificationTemplateDefaultData(data) {
    return data.reduce((acc, template) => {
        const parsedBody = JSON.parse(template.Body);
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            Body: parsedBody.Body,
            NotificationCategory: parsedBody.Group,
            SectionUrl: parsedBody.SectionUrl,
            IsImportant: parsedBody.IsImportant,
            isDeletable: !(template?.IsUsed ?? false),
        };
        return acc;
    }, {});
}

function createTelegramNotificationTemplateResultData(Name, Description, data, type, EventId) {
    return values(data).map(({ Body, Language, UploadedImageUrl, ButtonText, ButtonUrl, TemplateId }) => {
        return {
            Language,
            DeliveryMethod: `${TemplateTypesLabels[type]}`,
            Description,
            Name: Language ? `${Name} (${Language})` : Name,
            Body: JSON.stringify({
                Text: Body,
                ButtonText: ButtonText,
                ButtonUrl: ButtonUrl,
                MediaUrl: UploadedImageUrl,
            }),
            TemplateId: TemplateId,
            EventId: EventId,
        };
    });
}

function createTelegramNotificationTemplateDefaultData(data, t) {
    return data.reduce((acc, template) => {
        const parsedBody = JSON.parse(template.Body);
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            Body: parsedBody.Text,
            ButtonText: parsedBody.ButtonText,
            ButtonUrl: parsedBody.ButtonUrl,
            UploadedImageUrl: parsedBody.MediaUrl,
            isDeletable: !(template?.IsUsed ?? false),
            FileName: parsedBody.MediaUrl === '' ? '' : t(l.File),
        };
        return acc;
    }, {});
}

function createPopUpNotificationTemplateDefaultData(data) {
    return data.reduce((acc, template) => {
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            TitleOfPopUpNotification: JSON.parse(template.Body).message_title,
            Body: JSON.parse(template.Body).message_body,
            isDeletable: !(template?.IsUsed ?? false),
        };
        return acc;
    }, {});
}

function createCMSPopUpNotificationTemplateDefaultData(data) {
    return data.reduce((acc, template) => {
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            Body: JSON.parse(template.Body),
        };
        return acc;
    }, {});
}

function createTemplateDefaultData(data) {
    return data.reduce((acc, template) => {
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            UIConfig: JSON.parse(template.UIConfig),
            isDeletable: !(template?.IsUsed ?? false),
        };
        return acc;
    }, {});
}

function createDynamicPopUpTemplateDefaultData(data) {
    return data.reduce((acc, template) => {
        const parsedBody = JSON.parse(template.Body);
        template.ParentTemplateId = null;
        acc[template.Language || 'defaultLang'] = {
            ...template,
            [TemplateInputTypes.Body]: parsedBody.Content,
            [TemplateInputTypes.DeviceType]: parsedBody.Platforms,
            [TemplateInputTypes.UrlType]: parsedBody.Page.Comparison,
            [TemplateInputTypes.Url]: parsedBody.Page.Value,
            UIConfig: JSON.parse(template.UIConfig),
            isDeletable: !(template?.IsUsed ?? false),
        };
        return acc;
    }, {});
}

function createLanguageModalData(data, selectedItems) {
    return data.reduce((acc, { Name, Id }, index) => {
        if (!selectedItems.includes(Id)) {
            acc.push({
                hasHover: true,
                dragDisable: true,
                nestedData: [],
                data: {
                    isRowSelected: selectedItems.includes(Id),
                    index,
                    Language: Name,
                    Id,
                },
            });
        }
        return acc;
    }, []);
}

const createSelectLanguageModalData = (data, selectedItems) => {
    return data.reduce((acc, { Name, Id }, index) => {
        acc.push({
            hasHover: true,
            dragDisable: true,
            nestedData: [],
            data: {
                isRowSelected: selectedItems.includes(Id),
                index,
                Language: Name,
                Id,
            },
        });
        return acc;
    }, []);
};

// replacing @Model.LastName format to {LastName} format, it is needed to replace models coming from AngularJS project
const replaceOldPlaceholders = (templateData, groupedTemplateDynamicVariables, templateType) => {
    return keys(templateData).reduce((acc, key) => {
        acc[key] = { ...templateData[key] };
        groupedTemplateDynamicVariables.forEach((item) => {
            if (!(templateType in item.channels)) return;

            const { FieldName, Name } = item.channels[templateType];
            acc[key].Body = replacePlaceholder(acc[key].Body, Name, FieldName);
            if (!isNil(acc[key].Subject)) {
                acc[key].Subject = replacePlaceholder(acc[key].Subject, Name, FieldName);
            }
        });
        return acc;
    }, {});
};

const replacePlaceholder = (source, subString, replacement) => {
    let regex = new RegExp(subString, 'g');
    return source.replace(regex, `{${replacement}}`);
};

const createTemplateInitialData = {
    SMS: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
        };
    },
    Hoory: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
        };
    },
    FastexNotification: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
            Subject: template?.Subject ?? '',
            SectionUrl: template?.SectionUrl ?? '',
            IsImportant: template?.IsImportant ?? false,
            NotificationCategory: template?.NotificationCategory ?? '',
        };
    },
    Internal: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
            Subject: template?.Subject ?? '',
            ...(template?.UIConfig && { UIConfig: template?.UIConfig }),
        };
    },
    Telegram: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
            ButtonUrl: template?.ButtonUrl ?? '',
            ButtonText: template?.ButtonText ?? '',
            UploadedImageUrl: template?.UploadedImageUrl ?? '',
            FileName: template?.FileName ?? '',
        };
    },
    Email: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
            Subject: template?.Subject ?? '',
            ...(template?.UIConfig && { UIConfig: template?.UIConfig }),
        };
    },
    DynamicPopUp: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
            UrlType: template?.UrlType ?? '',
            Url: template?.Url ?? '',
            DeviceType: template?.DeviceType ?? DynamicPopUpDeviceTypesLabels.Both,
            ...(template?.UIConfig && { UIConfig: template?.UIConfig }),
        };
    },
    PushNotification: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
            Subject: template?.Subject ?? '',
            TitleOfPushNotification: template?.TitleOfPushNotification ?? '',
        };
    },
    CustomerJourneyVisualization: (id = null, template = null) => {
        return {
            Body: template?.Body ?? { name: '', description: '' },
            Language: id,
        };
    },
    PopUp: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
            TitleOfPopUpNotification: template?.TitleOfPopUpNotification ?? '',
        };
    },
    CMSPopUp: (id = null, template = null) => {
        return {
            Body: template?.Body ?? '',
            Language: id,
        };
    },
    Viber: (id = null, template = null, { isCascade } = {}) => {
        return {
            Body: template?.Body ?? '',
            ValidityPeriod: template?.ValidityPeriod ?? ViberTemplateConfig.DefaultValidityPeriodInMinutes,
            Language: id,
            ButtonUrl: template?.ButtonUrl ?? '',
            ButtonText: template?.ButtonText ?? '',
            UploadedImageUrl: template?.UploadedImageUrl ?? '',
            FileName: template?.FileName ?? '',
            ...(isCascade && { SMSBody: template?.SMSBody ?? '' }),
            ...(isCascade && { SMSParts: template?.SMSParts ?? '' }),
        };
    },
};

const getTemplateCustomGetLabel = (message) => {
    return message.errorMessage;
};

const getTemplateValidationTranslatableErrorText =
    (t) =>
    ({ validationResultValue }) => {
        return getTranslatableErrorText(t, validationResultValue, getTemplateCustomGetLabel);
    };

export {
    createTemplateResultData,
    groupDynamicVariablesByDisplayName,
    createTemplateDefaultData,
    createTemplateInitialData,
    createLanguageModalData,
    createSelectLanguageModalData,
    createPushNotificationTemplateDefaultData,
    createPushNotificationTemplateResultData,
    createPopUpNotificationTemplateDefaultData,
    createPopUpNotificationTemplateResultData,
    createDynamicPopUpTemplateDefaultData,
    createCMSPopUpNotificationTemplateResultData,
    createCMSPopUpNotificationTemplateDefaultData,
    replaceOldPlaceholders,
    getTemplateValidationTranslatableErrorText,
    createViberNotificationTemplateResultData,
    createViberNotificationTemplateDefaultData,
    createFastexNotificationTemplateResultData,
    createFastexNotificationTemplateDefaultData,
    createTelegramNotificationTemplateResultData,
    createTelegramNotificationTemplateDefaultData,
    generatePartialHtml,
    htmlStringToDomElement,
};
