import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Constants
import { l } from 'constants/common';
// Import UI Components
import { Tag } from '@geneui/components';
// Import Components
import { CJInfoBlockLabelValueWrapper, CJFilterInfo } from 'components/CustomerJourney/infoBlocks';

const CJFilterGroupInfo = ({ data, elements, index, option, blockId }) => {
    const { t } = useTranslation();
    return (
        <>
            {isNil(data) ? (
                <CJInfoBlockLabelValueWrapper label={t(l.OptionValue)} value={t(l.NA)} />
            ) : (
                <>
                    {data.isGroupFilter === true ? (
                        <div className="cj-filter-group-condition-info-block-property-wrapper">
                            <div className="crm-cj-block-info-sidebar_option-name-wrapper">
                                <p className="crm-cj-block-info-sidebar_option-name">
                                    {t(l.FilterGroup) + ' ' + (index + 1)}
                                </p>

                                {!isNil(data?.logic) && <Tag name={data?.logic} appearance="minimal" />}
                            </div>

                            <div>
                                {data.filters.map((filter, index) => {
                                    return (
                                        <CJFilterGroupInfo
                                            key={index}
                                            data={filter}
                                            elements={elements}
                                            option={option}
                                            blockId={blockId}
                                            index={index}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <CJFilterInfo
                            filter={data}
                            elements={elements}
                            option={option}
                            blockId={blockId}
                            index={index}
                        />
                    )}
                </>
            )}
        </>
    );
};

CJFilterGroupInfo.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
    index: PropTypes.number,
};

export default CJFilterGroupInfo;
