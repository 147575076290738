import {
    CRMRequestService,
    CRMRequestDownloadLinkService,
    postCancelableRequest,
    CRMRequestServiceDownload,
} from './http.config';

const CommunicationHttpService = {
    getCommunicationList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { withTotalCount: true },
            endpointPath: ({ withTotalCount }) => `/Communication/List?withTotalCount=${withTotalCount}`,
        });
    },
    resendCommunication: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ communicationId }) => `/Communication/Send?communicationId=${communicationId}`,
        });
    },
    getCampaignGeneralReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Communication/GetCampaignGeneralReport`,
        });
    },
    getClickReportByCampaignType: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Communication/GetClickReportByCampaignType`,
        });
    },
    getOpenReportByCampaignType: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Communication/GetOpenReportByCampaignType`,
        });
    },
    getTrackedLinks: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Communication/GetTrackedLinks`,
        });
    },
    getTrackLinkReportById: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Communication/GetTrackLinkReportById`,
        });
    },
    getUnsubscribedClientsReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Communication/GetUnsubscribedClientsReport`,
        });
    },
    downloadTrackLinkReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestServiceDownload,
            endpointPath: () => `/Communication/DownloadTrackLinkReport`,
        });
    },
    downloadClickReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestServiceDownload,
            endpointPath: () => `/Communication/DownloadClickReport`,
        });
    },
    downloadOpenReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestServiceDownload,
            endpointPath: () => `/Communication/DownloadOpenReport`,
        });
    },
    getPromotionList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Promotion/List',
        });
    },
    downloadCommunicationsReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: ({ isDownload }) => `/Communication/DownloadCommunicationsReport?isDownload=${isDownload}`,
        });
    },
};

export default CommunicationHttpService;
