import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Tooltip } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const AdminProviderPartnersInfo = ({ partners }) => {
    const { t } = useTranslation();

    const partnerIdName = (partners ?? []).map((partner) => `${partner.PartnerId} - ${partner.Name}`).join(', ');

    return (
        <Tooltip text={partnerIdName}>
            <span className="ellipsis-text">
                {partners?.length === 1 ? partnerIdName : `${partners?.length ?? 0} ${t(l.Partner)}`}
            </span>
        </Tooltip>
    );
};

AdminProviderPartnersInfo.propTypes = {
    partners: PropTypes.array.isRequired,
};

export default memo(AdminProviderPartnersInfo);
