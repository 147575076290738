import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { CampaignHttpService } from 'services/http';
// Import UI Components
import { BusyLoader, Modal, Button } from '@geneui/components';
// Import Components
import { PlayersDataTable } from 'components';
// Import Constants
import { l } from 'constants/common';
import { TrackedLinksClickPlayersConfig } from './config';
// import css
import 'assets/scss/campaignReport.scss';

const CampaignTrackedLinks = ({ data, campaignId }) => {
    const { doPostRequest } = useRequest();
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [isLoadedModal, setIsLoadedModal] = useState(false);
    const [playersData, setPlayersData] = useState({});

    const { getListOfClientsClickedTemplateLinkRequest } = useRef({
        getListOfClientsClickedTemplateLinkRequest: CampaignHttpService.listOfClientsClickedTemplateLink(),
    }).current;

    const onCloseHandler = () => {
        setIsVisible(false);
    };

    const getFooter = () => {
        return (
            <Button appearance="minimal" onClick={onCloseHandler}>
                {t(l.Close)}
            </Button>
        );
    };

    const getContent = () => {
        return (
            <PlayersDataTable
                key="ClientId"
                config={TrackedLinksClickPlayersConfig}
                isLoading={isLoadedModal}
                data={playersData}
            />
        );
    };

    const initModal = (trackedLinkId) => {
        setPlayersData([]);
        setIsVisible(true);
        setIsLoadedModal(true);
        doPostRequest(getListOfClientsClickedTemplateLinkRequest.request, {
            queryString: { campaignId: campaignId, trackedLinkId: trackedLinkId },
            successCallback: (Data) => {
                setPlayersData(Data);
            },
        }).then(() => {
            setIsLoadedModal(false);
        });
    };

    return (
        <>
            <Modal
                title={t(l.ClientsClickedTrackedLink)}
                visible={isVisible}
                closable={false}
                className="segment-list-modal"
                footer={getFooter()}
            >
                {getContent()}
            </Modal>
            <div className="details-section">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name">{t(l.TrackedLinks)}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="tracked-links-section">
                    <BusyLoader isBusy={isNil(data)}>
                        {!isNil(data) && isEmpty(data) ? (
                            <div className="c-tracked-list-content">
                                <p className="c-tracked-list-content-item">{l.NA}</p>
                                <p className="c-tracked-list-content-item">{l.NA}</p>
                            </div>
                        ) : (
                            !isNil(data) &&
                            data.map((item, index) => {
                                return (
                                    <div key={index} className="tracked-link">
                                        <a href={`${item.Url}`} target="_blank" rel="noopener noreferrer">
                                            <span>{`${item.Url}`}</span>
                                        </a>
                                        <button onClick={() => initModal(item.TrackLinkId)}>
                                            {`${item.ClickedCount}`}
                                        </button>
                                    </div>
                                );
                            })
                        )}
                    </BusyLoader>
                </div>
            </div>
        </>
    );
};

CampaignTrackedLinks.propTypes = {
    data: PropTypes.array,
    campaignId: PropTypes.string.isRequired,
};

CampaignTrackedLinks.defaultProps = {
    data: [],
};

export default memo(CampaignTrackedLinks);
