import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, isNil, isString, noop, trim } from 'lodash';
// Import constants
import { l } from 'constants/common';
// Import scss
import 'assets/scss/uploadFile.scss';

const UploadFile = ({
    inputRef,
    value,
    onChange,
    disabled,
    errorText,
    buttonTextKey,
    showOnlyButton,
    inputTitle,
    acceptedExtensions,
}) => {
    const { t } = useTranslation();

    return (
        <div className={inputTitle ? 'extension-input-filed-el' : ''} data-title={t(l.P12Certificate)}>
            <div
                className={`crm-uploader-block-wrapper ${disabled ? 'disabled' : ''} ${
                    errorText ? 'browse-file-input' : ''
                }`}
            >
                {!showOnlyButton && (
                    <div className="crm-uploader-block-data">
                        <p className={classNames({ 'is-placeholder': !value })}>
                            {isNil(value) || !isString(value) || isEmpty(trim(value)) ? t(l.NoFileChosen) : value}
                        </p>
                    </div>
                )}
                <label
                    className={classNames('crm-uploader-button', {
                        'crm-uploader-button-show-only-button': showOnlyButton,
                    })}
                >
                    <input
                        type="file"
                        ref={inputRef}
                        onChange={onChange}
                        disabled={disabled}
                        accept={acceptedExtensions}
                    />
                    <span>{t(buttonTextKey)}</span>
                </label>
                {errorText && <p className="information-message color-danger choose-file">{errorText}</p>}
            </div>
        </div>
    );
};

UploadFile.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    inputRef: PropTypes.object,
    disabled: PropTypes.bool,
    showOnlyButton: PropTypes.bool,
    errorText: PropTypes.string,
    buttonTextKey: PropTypes.string,
    inputTitle: PropTypes.string,
    acceptedExtensions: PropTypes.string,
};

UploadFile.defaultProps = {
    value: null,
    onChange: noop,
    inputRef: React.createRef(),
    disabled: false,
    showOnlyButton: false,
    errorText: '',
    buttonTextKey: l.Browse,
    inputTitle: '',
    acceptedExtensions: '.xls,.xlsx,.csv',
};

export default memo(UploadFile);
