import { isEmpty, keys } from 'lodash';
import { PageValidationActionTypes as Types } from 'actions/types';

export default (state = {}, { payload, type }) => {
    switch (type) {
        case Types.SET_PAGE_VALIDATION_DATA: {
            state = payload;
            return Object.assign({}, state);
        }
        case Types.CHANGE_PAGE_VALIDATION_DATA: {
            if (!isEmpty(state)) {
                return Object.assign({}, { ...state, ...payload });
            }
            return Object.assign({}, state);
        }
        case Types.ADD_PAGE_VALIDATION_SCENARIO_FIELDS: {
            return Object.assign({}, { ...state, ...payload });
        }
        case Types.REMOVE_PAGE_VALIDATION_SCENARIO_FIELDS: {
            state = keys(state).reduce((acc, key) => {
                if (!payload.includes(key)) {
                    acc[key] = state[key];
                }
                return acc;
            }, {});
            return Object.assign({}, state);
        }
        case Types.CLEAR_PAGE_VALIDATION_DATA: {
            return {};
        }
        default:
            return state;
    }
};
