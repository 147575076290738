import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Checkbox } from '@geneui/components';
// Import Components
import { BooleanDropdown } from 'components';
import { CJDescriptionInfoWrapper } from 'components/CustomerJourney';

const CJBoolean = ({ defaultValue, getUpdate, option, fromFilter }) => {
    const { CustomAttributes } = option;
    const { t } = useTranslation();
    const [checked, setChecked] = useState(defaultValue?.value);

    const onChangeHandler = ({ target }) => {
        setChecked(target?.checked);
    };

    const valueChangeHandler = (value) => {
        setChecked(value);
    };

    useEffect(() => {
        getUpdate({ value: isNil(checked) ? false : checked });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    useEffect(() => {
        getUpdate({ isValid: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return fromFilter ? (
        <BooleanDropdown defaultValue={checked ?? false} onChange={valueChangeHandler} hasSearch={false} />
    ) : (
        <CJDescriptionInfoWrapper description={CustomAttributes?.Description}>
            <Checkbox label={t(CustomAttributes?.DisplayName)} checked={checked} onChange={onChangeHandler} />
        </CJDescriptionInfoWrapper>
    );
};

CJBoolean.propTypes = {
    defaultValue: PropTypes.object,
    fromFilter: PropTypes.bool,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJBoolean.defaultProps = {
    defaultValue: { checked: false },
    fromFilter: false,
};

export default memo(CJBoolean);
