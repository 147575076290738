import { RouteLeaveConfirmationActionTypes as Types } from './types';

const setPageName = (payload) => ({
    type: Types.SET_PAGE_NAME,
    payload,
});

const setHasDataChange = (payload) => ({
    type: Types.SET_HAS_DATA_CHANGE,
    payload,
});

const setIsChangeSaved = (payload) => ({
    type: Types.SET_IS_CHANGE_SAVED,
    payload,
});

const resetHasDataChange = () => ({
    type: Types.RESET_HAS_DATA_CHANGE,
});

export default {
    setPageName,
    setHasDataChange,
    setIsChangeSaved,
    resetHasDataChange,
};
