/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Services
import { getStartDate, getEndDate } from 'services/dashboard';
// Import UI Components
import { Widget, Icon, DatePickerInput, Tooltip, SkeletonLoader } from '@geneui/components';
// Import Hooks
import { useAutocompleteRequest, useGACustomEvent, useRequest } from 'hooks';
// Import configs
import { datePickerConfigs } from './config';
// Import Constants
import { GACategoryEvents, WidgetsCompStatus } from 'constants/common';

const { GADateFilterCategory } = GACategoryEvents;

const KPIWidgetWrapper = ({ item, parentDate }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const { logCustomEvent } = useGACustomEvent();
    const [date, setDate] = useState(parentDate);
    const [comparisonText, setComparisonText] = useState(0);
    const [comparisonStatus, setComparisonStatus] = useState('initial');
    const [isLoading, setIsLoading] = useState(false);
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    useEffect(() => {
        if (parentDate !== date && !isNil(comparisonText)) {
            item.hasDatePicker && dateInputChangeHandler(parentDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentDate]);

    function getWidgetData(dateData) {
        setIsLoading(true);

        const requestObj = getAutocompleteRequest(item.request);
        doGetRequest(requestObj.request, {
            queryString: item.queryString || { startDate: getStartDate(dateData[0]), endDate: getEndDate(dateData[1]) },
            successCallback: ({ previousPeriodValue, currentPeriodValue, currency }) => {
                const compStatus = isNil(currentPeriodValue)
                    ? WidgetsCompStatus.initial
                    : !isNil(currentPeriodValue) && isNil(previousPeriodValue)
                    ? WidgetsCompStatus.up
                    : currentPeriodValue > previousPeriodValue
                    ? WidgetsCompStatus.up
                    : currentPeriodValue < previousPeriodValue
                    ? WidgetsCompStatus.down
                    : WidgetsCompStatus.initial;
                setComparisonText(
                    !isNil(currentPeriodValue)
                        ? `${currentPeriodValue}${item.hasCurrency ? (isNil(currency) ? '' : currency) : ''}`
                        : `0 ${item.hasCurrency ? (isNil(currency) ? '' : currency) : ''}`,
                );
                setComparisonStatus(compStatus);
                setIsLoading(false);
            },
            errorCallback: () => setIsLoading(false),
        });
    }

    function dateInputChangeHandler(e) {
        const startDate = getStartDate(e[0]);
        const endDate = getEndDate(e[1]);

        logCustomEvent(
            GADateFilterCategory.name,
            GADateFilterCategory.events[`${item.gaEventNameKey}Date`],
            'change widget date',
            `${startDate} - ${endDate}`,
        );
        setDate([startDate, endDate]);
    }

    useEffect(() => {
        getWidgetData(date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date[0], date[1]]);

    const cleanUp = () => {
        return () => {
            cancelRequest();
        };
    };

    useEffect(cleanUp, []);

    return (
        <Widget
            img={item.widgetImg}
            text={
                <SkeletonLoader height="20px" width="40%" isBusy={isLoading}>
                    {t(item.title)}
                </SkeletonLoader>
            }
            className={item.className}
            showComparisonIcons={true}
            comparisonText={!isLoading && `${comparisonText}${item.withPercentage ? '%' : ''}`}
            comparisonStatus={comparisonStatus}
            type="default"
            headerActions={
                item.hasDatePicker ? (
                    <>
                        <DatePickerInput.WithRange
                            onChange={dateInputChangeHandler}
                            value={date}
                            min={datePickerConfigs.min}
                            max={datePickerConfigs.max}
                        />
                        <Tooltip text={t(item.tooltip)} position="bottom">
                            <Icon className="bc-icon-info-48" />
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip text={t(item.tooltip)} position="bottom">
                        <Icon className="bc-icon-info-48" />
                    </Tooltip>
                )
            }
        />
    );
};

KPIWidgetWrapper.propTypes = {
    item: PropTypes.object.isRequired,
    parentDate: PropTypes.array.isRequired,
};

export default KPIWidgetWrapper;
