import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import components
import { DataTable } from 'components';
// Import constants
import { l } from 'constants/common';
import { DataTableColumnsConfigs } from './configs';

const BonusInfo = ({ bonusDetails }) => {
    const { t } = useTranslation();

    const getDataTableName = () => <span>{t(l.Amounts)}</span>;

    const mapBonusDetails = (data) => {
        return (
            data?.map(({ Id, CurrencyCode, MaxAmount, MinAmount }, index) => ({
                index: index,
                actionBar: false,
                dragDisable: true,
                hasHover: true,
                data: {
                    dataKey: Id,
                    index: index,
                    CurrencyCode,
                    MaxAmount,
                    MinAmount,
                },
            })) ?? []
        );
    };

    const mapBonusDetailsColumns = () => {
        return DataTableColumnsConfigs(t).columns.map((item, index) => ({ ...item, index: index }));
    };

    return (
        <>
            <DataTable
                key="BonusInfo"
                columnKey="dataKey"
                rowKey="index"
                name={getDataTableName()}
                data={mapBonusDetails(bonusDetails)}
                dataCount={bonusDetails?.length ?? 0}
                columns={mapBonusDetailsColumns()}
                isLoading={isNil(bonusDetails)}
                withPagination={false}
                withPageSelector={false}
            />
        </>
    );
};

BonusInfo.propTypes = {
    bonusDetails: PropTypes.array.isRequired,
};

export default memo(BonusInfo);
