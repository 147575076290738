import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
//Import constants
import { SegmentTypesLabels, CompositeSegmentOperation, l, SegmentObjectType } from 'constants/common';
import { Icon } from '@geneui/components';
// Import components
import { UsedInSection } from 'components';

const GeneralInfo = ({
    segmentId,
    type,
    name,
    description,
    isPredefined,
    numberOfCustomersData,
    usedInCampaigns,
    compositeConfiguration,
    targetObject,
    onClickNumberOfCustomers,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="segment-modal-head">
                {/*For title text can add active-state class*/}
                <div className="segment-modal-info-inner">
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.SegmentId)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{segmentId}</span>
                    </div>
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.Title)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{name}</span>
                    </div>

                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.Description)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">{description}</span>
                    </div>
                    {!isNil(compositeConfiguration) && (
                        <div className="key-value-h">
                            <span className="ellipsis-text kv-label">{t(l.Operation)}</span>
                            <span className="ellipsis-text kv-value kv-value-css">
                                {t(CompositeSegmentOperation[compositeConfiguration.Operation])}
                            </span>
                        </div>
                    )}

                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.Type)}</span>
                        <div className="ellipsis-text kv-value kv-value-css">
                            <div className="static-segment-info-item">
                                {type === SegmentTypesLabels.Dynamic && (
                                    <Icon type="bc-icon-dynamic crm-segment-type-icon dynamic" />
                                )}
                                {type === SegmentTypesLabels.Static && (
                                    <Icon type="bc-icon-static crm-segment-type-icon static" />
                                )}
                                {type === SegmentTypesLabels.Composite && (
                                    <Icon type="bc-icon-composite crm-segment-type-icon composite" />
                                )}
                                <p>{t(SegmentTypesLabels[type])}</p>
                            </div>
                        </div>
                    </div>

                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">
                            {targetObject === SegmentObjectType.Client
                                ? t(l.NumberOfCustomers)
                                : t(l.NumberOfNonCustomers)}
                        </span>
                        <div className="ellipsis-text kv-value kv-value-css crm-segment-filters-header-blocks crm-segment-filters-header-left-side">
                            {!isNil(numberOfCustomersData) && (
                                <>
                                    {`${numberOfCustomersData.Count} / ${numberOfCustomersData.Total}`}
                                    <Icon
                                        className={`crm-blue${numberOfCustomersData.isLoading ? ' rotate' : ''}`}
                                        type={'bc-icon-refresh'}
                                        onClick={onClickNumberOfCustomers}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <UsedInSection
                        isLoadingUsedIn={isNil(usedInCampaigns)}
                        usedInData={usedInCampaigns}
                        isVisible={!isPredefined}
                        notUsedInDisplayNameKey={l.SegmentIsNotUsed}
                    />
                </div>
            </div>
        </>
    );
};

GeneralInfo.propTypes = {
    type: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    numberOfCustomersData: PropTypes.object,
    usedInCampaigns: PropTypes.array,
    compositeConfiguration: PropTypes.object,
    isPredefined: PropTypes.bool,
    targetObject: PropTypes.number.isRequired,
    segmentId: PropTypes.number.isRequired,
    onClickNumberOfCustomers: PropTypes.func,
};

GeneralInfo.defaultProps = {
    isPredefined: false,
    numberOfCustomersData: null,
    usedInCampaigns: null,
    compositeConfiguration: null,
    onClickNumberOfCustomers: noop,
};

export default memo(GeneralInfo);
