// Import Constants
import { CustomerJourneyGroupBlockTypes, CustomerJourneyGroupBlockTypesLabels } from 'constants/common';

export const nodeWidth = 220;
export const nodeHeight = 80;

export const connectionLineType = 'bezier';
export const arrowHeadType = 'arrowclosed';

export const blocksGroupEnum = {
    1: {
        orderKey: 0,
        titleKey: CustomerJourneyGroupBlockTypesLabels.target,
        blocksTypes: CustomerJourneyGroupBlockTypes.target,
        cssClassName: 'crm-cj-target-group',
        defaultBlockIcon: 'bc-icon-transfer-to',
    },
    2: {
        orderKey: 1,
        titleKey: CustomerJourneyGroupBlockTypesLabels.action,
        blocksTypes: CustomerJourneyGroupBlockTypes.action,
        cssClassName: 'crm-cj-action-group',
        defaultBlockIcon: 'bc-icon-transfer-to',
    },
    5: {
        orderKey: 2,
        titleKey: CustomerJourneyGroupBlockTypesLabels.condition,
        blocksTypes: CustomerJourneyGroupBlockTypes.condition,
        cssClassName: 'crm-cj-condition-group',
        defaultBlockIcon: 'bc-icon-filter-block',
    },
    3: {
        orderKey: 3,
        titleKey: CustomerJourneyGroupBlockTypesLabels.trigger,
        blocksTypes: CustomerJourneyGroupBlockTypes.trigger,
        cssClassName: 'crm-cj-trigger-group',
        defaultBlockIcon: 'bc-icon-transfer-to',
    },
};

export const CJBlockColumnsTypes = {
    String: 0,
    Number: 1,
    DateTime: 2,
    Boolean: 3,
    Decimal: 4,
    Int: 5,
    Int32: 6,
    Blank: 99,
    Date: 100,
};

export const CJBlockColumnsTypesNames = {
    String: 'String',
    Number: 'Number',
    DateTime: 'DateTime',
    Boolean: 'Boolean',
    Decimal: 'Decimal',
    Int: 'Int',
    Int32: 'Int32',
    Blank: 'Blank',
    Date: 'Date',
    Money: 'Money',
};
