import React, { memo, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Modal, Button } from '@geneui/components';
// Import Components
import { ButtonWithLoader } from 'components';
import CECModalBody from './CECModalBody';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { AdministrationHttpService } from 'services/http';
// Import Constants
import { l, PageTypes } from 'constants/common';

const AdminProviderCECModal = ({ isVisibleModal, onCancel, onOk, openedModalData, isLoading, type }) => {
    const { t } = useTranslation();

    const { doGetRequest } = useRequest();

    const [provider, setProvider] = useState({});
    const [isInitialProviderDataLoading, setIsInitialProviderDataLoading] = useState(false);

    const { getEmailProviderRequest } = useMemo(
        () => ({
            getEmailProviderRequest: AdministrationHttpService.getEmailProvider(),
        }),
        [],
    );

    const getTitle = (type) => {
        switch (type) {
            case PageTypes.create:
                return l.CreateProvider;
            case PageTypes.edit:
                return l.EditProvider;
            case PageTypes.clone:
                return l.CloneProvider;
            default:
                return '';
        }
    };

    const init = () => {
        if (type !== PageTypes.create) {
            setIsInitialProviderDataLoading(true);
            doGetRequest(getEmailProviderRequest.request, {
                queryString: {
                    providerId: openedModalData.EmailProviderId,
                },
                successCallback: (data) => {
                    if (type === PageTypes.clone) {
                        setProvider({
                            ...data,
                            EmailProviderId: openedModalData.EmailProviderId,
                            PartnerId: data.Partners[0].PartnerId,
                        });
                    } else {
                        setProvider({ ...data, EmailProviderId: openedModalData.EmailProviderId });
                    }
                    setIsInitialProviderDataLoading(false);
                },
            });
        }
    };

    const cleanUp = () => {
        return () => {
            getEmailProviderRequest.cancel('AdminProviderCECModal:getEmailProviderRequest');
        };
    };

    useEffect(init, []);

    useEffect(cleanUp, []);

    return (
        <Modal
            size="content-size"
            background="dark-background"
            position="center"
            title={t(getTitle(type))}
            className="crm-modal crm-set-goal-modal"
            appearance="default"
            closable={false}
            closeOnClickOutside={false}
            visible={isVisibleModal}
            onCancel={onCancel}
            footer={
                <>
                    <Button color="default" appearance={'minimal'} onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader isLoading={isLoading} color="primary" onClick={() => onOk(provider)}>
                        {t(l.Save)}
                    </ButtonWithLoader>
                </>
            }
        >
            <CECModalBody data={provider} setData={setProvider} type={type} isLoading={isInitialProviderDataLoading} />
        </Modal>
    );
};

AdminProviderCECModal.propTypes = {
    type: PropTypes.string.isRequired,
    isVisibleModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    openedModalData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default memo(AdminProviderCECModal);
