import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';

const CJDefaultInfoBlock = ({ data }) => {
    const { t } = useTranslation();
    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.OptionValue)} value={data?.value} />
        </>
    );
};

CJDefaultInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJDefaultInfoBlock);
