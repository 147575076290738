import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { has, noop } from 'lodash';
// Import UI Components
import { Breadcrumb } from '@geneui/components';
// Import Hooks
import { useGoToRoute, useQueryParams } from 'hooks';

const StatefulBreadcrumb = ({ items, onClick, ...rest }) => {
    const { goToRoute } = useGoToRoute();
    const goToRouteQueryParams = useQueryParams();

    const onClickHandler = (item) => {
        if (has(item, 'routeTo')) {
            const decodedQueryParams = goToRouteQueryParams.decode();
            goToRoute(item.routeTo, null, decodedQueryParams.backParams);
        } else {
            onClick(item);
        }
    };

    return <Breadcrumb onClick={onClickHandler} data={items} {...rest} />;
};

StatefulBreadcrumb.propTypes = {
    items: PropTypes.arrayOf({
        key: PropTypes.any.isRequired,
        title: PropTypes.string.isRequired,
        routeTo: PropTypes.shape({
            path: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    }),
    onClick: PropTypes.func,
};

StatefulBreadcrumb.defaultProps = {
    items: [],
    onClick: noop,
};

export default memo(StatefulBreadcrumb);
