import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import Constants
import { defaultLogicFunction, l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';

const CJReferenceInArgumentInfoBlock = ({ data, elements, option, blockId }) => {
    const { t } = useTranslation();

    const block = elements.find((item) => item?.id === data?.blockId);
    let logicFunction = null;
    if (data?.logicFunction !== defaultLogicFunction) {
        let properties = block?.data?.metaData?.Properties;

        if (data?.blockId === blockId && !isNil(option.SelfModelTypeName)) {
            properties = option?.SelfModelProperties?.Properties;
        }

        if (!isNil(properties)) {
            const property = properties.find((property) => property?.Name === data?.propertyName);
            if (!isNil(property?.Functions)) {
                logicFunction = property.Functions.find((logicFunction) => logicFunction.value === data?.logicFunction);
            }
        }
    }

    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.Block)} value={block?.data?.name} />
            <CJInfoBlockLabelValueWrapper label={t(l.Property)} value={t(data?.propertyName)} />
            {!isNil(logicFunction) && logicFunction.value !== defaultLogicFunction && (
                <CJInfoBlockLabelValueWrapper label={t(l.Function)} value={t(logicFunction?.label)} />
            )}
        </>
    );
};

CJReferenceInArgumentInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
};

export default memo(CJReferenceInArgumentInfoBlock);
