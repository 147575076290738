import { ReportColumnsActionTypes as Types } from 'actions/types';

export default (state = [], { type, payload }) => {
    switch (type) {
        case Types.CLEAR_REPORT_COLUMNS:
            return [];
        case Types.SET_REPORT_COLUMNS:
            return [...payload];
        default:
            return state;
    }
};
