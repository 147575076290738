import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Import Components
import HTMLEditors from './HTMLEditors';
import DynamicVariables from './DynamicVariables';
import TemplateTextInput from './TemplateTextInput';
// Import Constants
import { l, TemplateEditorModes, TemplateInputTypes } from 'constants/common';
// Import Hooks
import { useBind, useFieldValidity, useInputInsertion } from 'hooks';
// Import Services
import { TemplateType } from 'services/template';

// temporarily hardcoded value, it shows sub content name for the same template type, it enables possibility to have different contents for the same template
const templateFieldWithConfig = 'Body';

const EmailTemplateContent = ({
    templateData,
    handleChange,
    handleUIConfigChange,
    templateLangKey,
    getAssets,
    addAsset,
    dynamicVariables,
}) => {
    const { t } = useTranslation();

    const subjectValidationState = useFieldValidity(TemplateInputTypes.Subject, l.Subject, templateLangKey);
    const bodyValidationState = useFieldValidity(TemplateInputTypes.Body, l.Body, templateLangKey);

    const { insertionHandler, updateInsertionHandler, insertionOptions, setInsertionOptions } = useInputInsertion();

    const handleSubjectChange = useBind(handleChange, TemplateInputTypes.Subject);
    const handleBodyChange = useBind(handleChange, TemplateInputTypes.Body);
    const handleBodyUIConfigChange = useBind(handleUIConfigChange, templateFieldWithConfig);
    const updateSubjectInsertionHandler = useBind(updateInsertionHandler, TemplateInputTypes.Subject);
    const updateBodyInsertionHandler = useBind(updateInsertionHandler, TemplateInputTypes.Body);

    const radioGroupOptions = useMemo(
        () => TemplateEditorModes.Email.map((option) => ({ ...option, label: t(option.label) })),
        [t],
    );
    const bodyValue = templateData?.[TemplateInputTypes.Body];
    const subjectValue = templateData?.[TemplateInputTypes.Subject];
    const uiConfig = templateData?.UIConfig;

    const updateDynamicVariableBodyOptions = useCallback(
        (hintText) => {
            setInsertionOptions({
                channel: TemplateType.Email,
                hintText,
                inputFilterKey: '',
                focusedInputKey: TemplateInputTypes.Body,
            });
        },
        [setInsertionOptions],
    );

    const updateDynamicVariableSubjectOptions = useCallback(() => {
        setInsertionOptions({
            channel: TemplateType.Email,
            hintText: '',
            inputFilterKey: 'IsSubjectItem',
            focusedInputKey: TemplateInputTypes.Subject,
        });
    }, [setInsertionOptions]);

    return (
        <div>
            <DynamicVariables
                dynamicVariables={dynamicVariables}
                clickHandler={insertionHandler}
                insertionOptions={insertionOptions}
            />
            <div className="crm-create-template-page-c-c-h-inputs-row">
                <TemplateTextInput
                    displayNameKey={l.Subject}
                    value={subjectValue}
                    onChange={handleSubjectChange}
                    onBlur={handleSubjectChange}
                    validationState={subjectValidationState}
                    updateInsertionHandler={updateSubjectInsertionHandler}
                    onFocus={updateDynamicVariableSubjectOptions}
                />
            </div>
            <HTMLEditors
                tabOptions={radioGroupOptions}
                htmlText={bodyValue}
                handleChange={handleBodyChange}
                config={uiConfig?.[templateFieldWithConfig]}
                handleConfigChange={handleBodyUIConfigChange}
                templateLangKey={templateLangKey}
                getAssets={getAssets}
                addAsset={addAsset}
                validationState={bodyValidationState}
                dynamicVariables={dynamicVariables}
                templateType={TemplateType.Email}
                bodyPropertyName={TemplateInputTypes.Body}
                updateInsertionHandler={updateBodyInsertionHandler}
                onEditorFocus={updateDynamicVariableBodyOptions}
            />
        </div>
    );
};

EmailTemplateContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleUIConfigChange: PropTypes.func.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    getAssets: PropTypes.func,
    addAsset: PropTypes.func,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
};

export default EmailTemplateContent;
