import React from 'react';
import { isNil, keys, last } from 'lodash';
// Import Components
import { SegmentModal } from 'components';
// Import Constants
import { CustomersTypesLabels } from 'constants/common';

const modalConfig = {
    Segment: {
        acceptTypes: ['WfSegmentId', 'WfSegmentId[]', 'WfKpiConfig'],
        render(item, handleClose) {
            return (
                <SegmentModal
                    targetObject={+CustomersTypesLabels.Customers}
                    segmentId={item?.Id}
                    segmentType={item?.Type}
                    handleClose={handleClose}
                    isVisible={true}
                />
            );
        },
    },
};

const getModalConfig = (baseTypes = []) => {
    const formsTypes = keys(modalConfig).reduce((acc, item) => {
        acc = {
            ...acc,
            ...modalConfig[item].acceptTypes.reduce((types, type) => {
                types[type] = item;
                return types;
            }, {}),
        };
        return acc;
    }, {});

    const matchedType = baseTypes
        .map((baseType) => last(baseType.split('.')))
        .find((baseType) => !isNil(formsTypes[baseType]));

    if (!isNil(matchedType)) {
        return modalConfig[formsTypes[matchedType]];
    }
    return {};
};

export { getModalConfig };
