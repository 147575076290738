// Import constants
import { FilterHolderFields, FilterComparisions, l, ReportType, ReportStatuses, ArchiveStates } from 'constants/common';

const { Equals, Like, Between, IsBlank } = FilterComparisions;
const { textInput, select, rangeDate, multiSelect } = FilterHolderFields;

const getTitle = (row) => {
    return row.data.Name.Name;
};

const FilterHolderFieldsConfigs = {
    storeKey: 'reportList',
    idFieldName: 'ReportId',
    defaultInputsData: {
        defaultInputsDataIds: {
            CategoryId: 4,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ReportId',
                comparision: Equals,
                displayName: l.ReportId,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Name',
                comparision: Like,
                displayName: l.Title,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedBy.Name',
                comparision: Like,
                displayName: l.User,
                value: textInput.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ReportType',
                comparision: Equals,
                displayName: l.ReportType,
                value: select.defaultValue,
                data: ReportType,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CategoryId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: Equals,
                displayName: l.Label,
                value: multiSelect.defaultValue,
                data: [],
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.CreatedDate,
                value: rangeDate.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ModifiedBy.Name',
                comparision: Like,
                displayName: l.LastModifier,
                value: textInput.defaultValue,
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ModifiedDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.ModifiedDate,
                value: rangeDate.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'State',
                comparision: Equals,
                displayName: l.Status,
                value: select.defaultValue,
                data: ReportStatuses,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ArchivedDate',
                comparision: IsBlank,
                displayName: l.Archive,
                value: select.defaultValue,
                data: ArchiveStates,
            },
        ],
    },
};

export { FilterHolderFieldsConfigs, getTitle };
