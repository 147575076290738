import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
//Import Constants
import { l, ActionsIcons } from 'constants/common';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';

const ConfigureFilterTagActionsButtons = ({ data, deleteFilterTagAction, applyFiltersAction }) => {
    const { t } = useTranslation();

    return (
        <>
            <Tooltip text={t(l.DeleteFilterTag)}>
                <Button
                    icon={ActionsIcons.Delete}
                    appearance="minimal"
                    color="primary"
                    onClick={() => deleteFilterTagAction(data)}
                />
            </Tooltip>
            <Tooltip text={t(l.ApplyFilterTag)}>
                <Button
                    icon="bc-icon-arrow-forward"
                    appearance="minimal"
                    color="primary"
                    onClick={() => applyFiltersAction(data?.Filters)}
                />
            </Tooltip>
        </>
    );
};

ConfigureFilterTagActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    deleteFilterTagAction: PropTypes.func,
    applyFiltersAction: PropTypes.func,
};

ConfigureFilterTagActionsButtons.defaultProps = {
    deleteFilterTagAction: noop,
    applyFiltersAction: noop,
};

export default memo(ConfigureFilterTagActionsButtons);
