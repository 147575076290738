// Import constants
import { ErrorShowType, l } from 'constants/common';

const UrlScenario = {
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
    ],
};

const popUpNotificationTemplateValidationScenario = () => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    UrlValue: { ...UrlScenario },
});

function popUpNotificationTemplateScenarioPropertiesCreator(data) {
    if (data !== '0') {
        return {
            UrlValue: {
                ...UrlScenario,
            },
        };
    }
    return {};
}

export { popUpNotificationTemplateScenarioPropertiesCreator, popUpNotificationTemplateValidationScenario };
