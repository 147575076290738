import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';

const CJMoneyInfoBlock = ({ data }) => {
    const { t } = useTranslation();
    const { data: listData } = useMenuItem('6');
    const MenuItem = listData?.find(({ Id }) => Id === data.currencyCode);
    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.Amount)} value={data?.amount} />
            <CJInfoBlockLabelValueWrapper label={t(l.Currency)} value={MenuItem?.Name} />
        </>
    );
};

CJMoneyInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJMoneyInfoBlock);
