import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Import UI Components
import { Icon, Tooltip } from '@geneui/components';
// Import Configs
import { comparisonIconsTypes } from './config';

function DynamicSegmentListItem({ text, color, handleClick, selected, comparisonIconType, type, description }) {
    const ComparisonIcon = comparisonIconsTypes[comparisonIconType] || false;
    const iconType = selected ? 'bc-icon-folder-opened' : 'bc-icon-folder-closed';
    return (
        <li
            className={`crm-flex crm-align-items-center crm-dynamic-segment-list-item
            ${selected ? 'crm-active' : ''}`}
            onClick={handleClick}
            role="presentation"
        >
            {ComparisonIcon ? (
                <Icon
                    className="crm-dynamic-segment-list-item-icon"
                    type={ComparisonIcon}
                    style={{ backgroundColor: color }}
                />
            ) : type !== 'category' ? (
                <span className="crm-dynamic-segment-list-item-color-circle">
                    <span style={{ backgroundColor: color }} />
                </span>
            ) : (
                // todo it's a really bad idea!
                <Icon
                    className={'crm-dynamic-segment-list-item-icon folder'}
                    type={iconType}
                    style={{ '--dynamic-segment-list-item-icon': color }}
                />
            )}
            <h6>{text}</h6>
            {!isEmpty(description) && (
                <Tooltip text={description} position="right">
                    <Icon className="tabs-info-icon" type="bc-icon-info" />
                </Tooltip>
            )}
        </li>
    );
}

DynamicSegmentListItem.propTypes = {
    text: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
    handleClick: PropTypes.func,
    resetActiveList: PropTypes.func,
    full: PropTypes.bool,
    selected: PropTypes.bool,
    comparisonIconType: PropTypes.number,
    type: PropTypes.string,
};
DynamicSegmentListItem.defaultProps = {
    full: true,
    comparisonIconType: -1,
    description: '',
};

export default DynamicSegmentListItem;
