import { CampaignPageCommunicationListActionTypes as Types } from './types';

const setCommunicationListTableFilters = (filters) => ({
    type: Types.SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_FILTERS,
    payload: filters,
});

const setCommunicationListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setCommunicationListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setCommunicationListTableSorting = (sorting) => ({
    type: Types.SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetCommunicationListState = () => ({
    type: Types.RESET_CAMPAIGN_PAGE_COMMUNICATION_LIST_STATE,
});

export default {
    setCommunicationListTableFilters,
    setCommunicationListTablePagingPageNumber,
    setCommunicationListTablePagingPageSize,
    setCommunicationListTableSorting,
    resetCommunicationListState,
};
