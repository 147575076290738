const BrowserStorageKeys = {
    accessToken: 'accessToken',
    isCRMSignIn: 'isCRMSignIn',
    isWaitForSSOResponse: 'isWaitForSSOResponse',
    directUrl: 'directUrl',
    referrerUrl: 'referrerUrl',
    timezone: 'timezone',
};

export { BrowserStorageKeys };
