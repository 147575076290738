import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CJProviderIdValidationSchema } from 'validators/schemas.validation';
// Import Components
import { ValidatableDropdown } from 'components';
// Import UI Components
import { SkeletonLoader } from '@geneui/components';
// Import Services
import { UtilsHttpService } from 'services/http';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Hooks
import { useRequest } from 'hooks';

const CJProviderId = ({ defaultValue, getUpdate, option, updateDependency }) => {
    const { t } = useTranslation();
    const { CustomAttributes } = option;
    const { DeliveryMethod } = CustomAttributes;
    const { doPostRequest } = useRequest();
    const [providerData, setProviderData] = useState(null);
    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
        },
        onSubmit: noop,
        validationSchema: CJProviderIdValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm } = formik;
    const { value } = values;

    const onChangeProviderIdHandler = (data) => {
        setFieldValue('value', data?.value);
        if (!isNil(data?.value)) {
            updateDependency({ ...data, dependencyItemId: `${data.value}_${DeliveryMethod}` });
        }
        getUpdate({ value: data?.value });
    };

    const { getPartnerMessageProviders } = useRef({
        getPartnerMessageProviders: UtilsHttpService.getPartnerMessageProviders(),
    }).current;

    useEffect(() => {
        submitForm();
        doPostRequest(getPartnerMessageProviders.request, {
            requestBody: { DeliveryMethod },
            successCallback: (Data) => {
                setProviderData(Data.map(({ Name, Id }) => ({ label: Name, value: Id })));
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="crm-cj-money-cmp">
            <SkeletonLoader isBusy={isNil(providerData)}>
                {providerData && (
                    <ValidatableDropdown
                        placeholder={t(CustomAttributes?.DisplayName)}
                        label={t(CustomAttributes?.DisplayName)}
                        labelAppearance="swap"
                        data={providerData}
                        isValid={!errors.value}
                        errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
                        defaultValue={value}
                        onChange={onChangeProviderIdHandler}
                        clearable={true}
                    />
                )}
            </SkeletonLoader>
        </div>
    );
};

CJProviderId.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    updateDependency: PropTypes.func.isRequired,
};

CJProviderId.defaultProps = {
    defaultValue: { value: '' },
};

export default memo(CJProviderId);
