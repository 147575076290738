import React, { memo } from 'react';
import PropTypes from 'prop-types';

const RandomComponentWithHeadline = ({ children, className, headline }) => {
    return (
        <div className={className || ''}>
            {headline && <p className="crm-date-form-simple-tab-input-title">{headline}</p>}
            {children}
        </div>
    );
};

RandomComponentWithHeadline.propTypes = {
    className: PropTypes.string,
    headline: PropTypes.string,
    children: PropTypes.node,
};

export default memo(RandomComponentWithHeadline);
