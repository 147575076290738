import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import Constants
import { l } from 'constants/common';

const FastexNotificationPreview = ({ body, sectionUrl, group, isImportant }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div style={{ display: 'flex', 'align-items': 'center', width: '100%', 'margin-top': '12px' }}>
                <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.Category)}</h4>
                <p style={{ margin: 0 }}>{group}</p>
            </div>
            <div style={{ display: 'flex', 'align-items': 'center', width: '100%', 'margin-top': '12px' }}>
                <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.MarkAsImportant)}</h4>
                <p style={{ margin: '0 0 0 2rem' }}>{isImportant ? t(l.Yes) : t(l.No)}</p>
            </div>
            <div style={{ display: 'flex', 'align-items': 'center', width: '100%', 'margin-top': '12px' }}>
                <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.SectionUrl)}</h4>
                <p style={{ margin: 0 }}>{isEmpty(sectionUrl) ? t(l.NA) : sectionUrl}</p>
            </div>
            <div style={{ display: 'flex', 'align-items': 'top', width: '100%', 'margin-top': '12px' }}>
                <h4 style={{ 'min-width': '100px', padding: 0, margin: 0 }}>{t(l.Text)}</h4>
                <p style={{ margin: 0 }}>{body}</p>
            </div>
        </div>
    );
};

FastexNotificationPreview.propTypes = {
    body: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    sectionUrl: PropTypes.string,
    isImportant: PropTypes.bool,
};

export default memo(FastexNotificationPreview);
