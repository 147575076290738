import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isString, noop } from 'lodash';
import classNames from 'classnames';
// Import Components
import ViberCascadeContent from './ViberCascadeContent';
import TelegramTemplateContent from './TelegramTemplateContent';
import HooryTemplateContent from './HooryTemplateContent';
import FastexNotificationTemplateContent from './FastexNotificationTemplateContent';
import DynamicPopUpContent from './DynamicPopUpContent';
import EmailTemplateContent from './EmailTemplateContent';
import InternalTemplateContent from './InternalTemplateContent';
import PushTemplateContent from './PushTemplateContent';
import SMSTemplateContent from './SMSTemplateContent';
// Import Services
import { TemplateType, getTemplateValidationTranslatableErrorText } from 'services/template';
// Import Actions
import { TemplateActions } from 'actions';
// Import Hooks
import { useValidator } from 'hooks';

const { changeTemplateValue, setUIConfig } = TemplateActions;

const CommonTemplateContent = ({
    dynamicVariables,
    templateLangKey,
    templateType,
    templateData,
    maxSMSPartsCount,
    shortenerAndTrackerSettings,
    getAssets,
    addAsset,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const validator = useValidator(getTemplateValidationTranslatableErrorText(t));

    const isSMSTemplate = templateType === TemplateType.SMS;
    const isHooryTemplate = templateType === TemplateType.Hoory;
    const isFastexNotificationTemplate = templateType === TemplateType.FastexNotification;
    const isInternalTemplate = templateType === TemplateType.Internal;
    const isViberTemplate = templateType === TemplateType.Viber;
    const isTelegramTemplate = templateType === TemplateType.Telegram;
    const isEmailTemplate = templateType === TemplateType.Email;
    const isDynamicPopUpTemplate = templateType === TemplateType.DynamicPopUp;
    const isPushNotificationTemplate = templateType === TemplateType.PushNotification;

    const inputIntervalTokenRef = useRef(null);

    const handleChange = useCallback(
        (propertyName, value, changeTimeout = 300) => {
            clearTimeout(inputIntervalTokenRef.current);

            if (isString(value)) {
                value = value?.trim();
            }

            if (changeTimeout === 0) {
                dispatch(changeTemplateValue({ propertyName, value, templateLangKey }));
                validator({ [templateLangKey + propertyName]: value });
            } else {
                inputIntervalTokenRef.current = setTimeout(() => {
                    dispatch(changeTemplateValue({ propertyName, value, templateLangKey }));
                    validator({ [templateLangKey + propertyName]: value });
                }, changeTimeout);
            }
        },
        [dispatch, templateLangKey, validator],
    );

    const handleConfigChange = useCallback(
        (editorKey, config) => {
            dispatch(
                setUIConfig({
                    templateLangKey,
                    editorKey,
                    editorConfig: config,
                }),
            );
        },
        [dispatch, templateLangKey],
    );

    return (
        <div className="crm-scroll-holder sms-template">
            <div className="crm-scrollable-container">
                <div
                    className={classNames('crm-create-template-page-c-container', {
                        viber: isViberTemplate,
                        telegram: isTelegramTemplate,
                        sms: isSMSTemplate,
                        push: isPushNotificationTemplate,
                        email: isEmailTemplate,
                        hoory: isHooryTemplate,
                        'fastex-notification': isFastexNotificationTemplate,
                        'dynamic-pop-up': isDynamicPopUpTemplate,
                    })}
                >
                    {isPushNotificationTemplate && (
                        <PushTemplateContent
                            templateData={templateData}
                            handleChange={handleChange}
                            templateLangKey={templateLangKey}
                            dynamicVariables={dynamicVariables}
                            shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                        />
                    )}
                    {isEmailTemplate && (
                        <EmailTemplateContent
                            templateData={templateData}
                            handleChange={handleChange}
                            handleUIConfigChange={handleConfigChange}
                            templateLangKey={templateLangKey}
                            getAssets={getAssets}
                            addAsset={addAsset}
                            dynamicVariables={dynamicVariables}
                        />
                    )}
                    {isInternalTemplate && (
                        <InternalTemplateContent
                            templateData={templateData}
                            handleChange={handleChange}
                            handleUIConfigChange={handleConfigChange}
                            templateLangKey={templateLangKey}
                            getAssets={getAssets}
                            addAsset={addAsset}
                            dynamicVariables={dynamicVariables}
                        />
                    )}
                    {isDynamicPopUpTemplate && (
                        <DynamicPopUpContent
                            templateData={templateData}
                            handleChange={handleChange}
                            handleUIConfigChange={handleConfigChange}
                            templateLangKey={templateLangKey}
                            getAssets={getAssets}
                            addAsset={addAsset}
                            dynamicVariables={dynamicVariables}
                        />
                    )}
                    {isViberTemplate && (
                        <ViberCascadeContent
                            templateData={templateData}
                            templateLangKey={templateLangKey}
                            handleChange={handleChange}
                            dynamicVariables={dynamicVariables}
                            maxSMSPartsCount={maxSMSPartsCount}
                            shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                        />
                    )}
                    {isTelegramTemplate && (
                        <TelegramTemplateContent
                            templateData={templateData}
                            templateLangKey={templateLangKey}
                            handleChange={handleChange}
                            dynamicVariables={dynamicVariables}
                            shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                        />
                    )}
                    {isSMSTemplate && (
                        <SMSTemplateContent
                            templateData={templateData}
                            templateLangKey={templateLangKey}
                            handleChange={handleChange}
                            dynamicVariables={dynamicVariables}
                            maxSMSPartsCount={maxSMSPartsCount}
                            shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                        />
                    )}
                    {isHooryTemplate && (
                        <HooryTemplateContent
                            templateData={templateData}
                            templateLangKey={templateLangKey}
                            shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                            handleChange={handleChange}
                            dynamicVariables={dynamicVariables}
                        />
                    )}
                    {isFastexNotificationTemplate && (
                        <FastexNotificationTemplateContent
                            templateData={templateData}
                            templateLangKey={templateLangKey}
                            shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                            handleChange={handleChange}
                            dynamicVariables={dynamicVariables}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

CommonTemplateContent.propTypes = {
    templateBody: PropTypes.any,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
    templateLangKey: PropTypes.string.isRequired,
    templateType: PropTypes.oneOf([
        TemplateType.SMS,
        TemplateType.Internal,
        TemplateType.Telegram,
        TemplateType.Email,
        TemplateType.DynamicPopUp,
        TemplateType.PushNotification,
        TemplateType.Viber,
    ]),
    templateData: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    maxSMSPartsCount: PropTypes.number,
    getAssets: PropTypes.func,
    addAsset: PropTypes.func,
    shortenerAndTrackerSettings: PropTypes.array,
};

CommonTemplateContent.defaultProps = {
    templateType: TemplateType.SMS,
    templateBody: '',
    getAssets: noop,
    addAsset: noop,
    shortenerAndTrackerSettings: [],
};

export default CommonTemplateContent;
