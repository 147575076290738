import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isNil, values, keys, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Dropdown } from '@geneui/components';
// Import Constants
import { l, TimeZonePack } from 'constants/common';
import { dynamicDateData, cjDateTimeInitialParamsCreator } from './config';

const CJDateTime = ({ defaultValue, getUpdate, ...rest }) => {
    const { t } = useTranslation();
    const { settings } = useSelector((state) => state.header);
    const { ActivePartner } = settings;

    const [dropdownValue, setDropdownValue] = useState(defaultValue?.value?.template || 'today');
    const [timeZone, setTimeZone] = useState(defaultValue?.value?.timeZone ?? ActivePartner.TimeZone);
    const [params, setParams] = useState(cjDateTimeInitialParamsCreator(defaultValue?.value?.params));
    const Component = dynamicDateData[dropdownValue].component;

    const dropDownChangeHandler = (e) => {
        setDropdownValue(e.value);
        setParams({});
        getUpdate({ value: { template: e.value, params: {}, timeZone: timeZone } });
    };

    const getParamsUpdate = (paramsData) => {
        if (!isNil(paramsData?.isValid)) {
            getUpdate({ isValid: paramsData?.isValid });
        } else {
            const resultParams = { ...params, ...paramsData };
            setParams(resultParams);
            getUpdate({ value: { template: dropdownValue, params: resultParams, timeZone: timeZone } });
        }
    };

    const timeZoneChangeHandler = (selectedTimeZone) => {
        const tmpTimeZone = selectedTimeZone?.value;
        setTimeZone(tmpTimeZone);
        getUpdate({ value: { template: dropdownValue, params: params, timeZone: tmpTimeZone } });
    };

    useEffect(() => {
        if (isNil(defaultValue?.value?.template)) {
            getUpdate({ value: { template: dynamicDateData.today.value, params: {}, timeZone: timeZone } });
            getUpdate({ isValid: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Dropdown
                data={values(dynamicDateData)}
                onChange={dropDownChangeHandler}
                value={dropdownValue}
                placeholder={t(l.DateTime)}
                label={t(l.DateTime)}
                labelAppearance="swap"
            />
            <Component defaultValue={defaultValue?.value?.params} getUpdate={getParamsUpdate} {...rest} />
            <Dropdown
                flexibility="full-width"
                hasSearch={false}
                placeholder={t(l.TimeZone)}
                label={t(l.TimeZone)}
                labelAppearance="swap"
                onChange={timeZoneChangeHandler}
                value={timeZone}
                appearance="outline"
                data={sortBy(
                    keys(TimeZonePack).map((key) => ({ label: TimeZonePack[key], value: +key })),
                    ['value'],
                )}
                noDataText={t(l.NoDataFound)}
            />
        </>
    );
};

CJDateTime.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
};

export default memo(CJDateTime);
