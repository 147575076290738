import { ReportColumnsActionTypes as Types } from 'actions/types';

const setReportColumns = (columns) => ({
    type: Types.SET_REPORT_COLUMNS,
    payload: columns,
});

const clearReportColumns = () => ({
    type: Types.CLEAR_REPORT_COLUMNS,
});

export default {
    setReportColumns,
    clearReportColumns,
};
