import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CJDoubleValidationSchema } from 'validators/schemas.validation';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText, getDoubleValue } from 'services/customerJourney';
// Import UI Components
import { ExtendedInput } from '@geneui/components';

const CJDouble = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const { CustomAttributes } = option;
    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
        },
        onSubmit: noop,
        validationSchema: CJDoubleValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm } = formik;
    const { value } = values;

    const onChangeHandler = ({ target }) => {
        setFieldValue('value', getDoubleValue(target?.value));
    };

    useEffect(() => {
        getUpdate({ isValid: !errors?.value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        getUpdate({ value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ExtendedInput
            type="number"
            placeholder={t(CustomAttributes?.DisplayName)}
            label={t(CustomAttributes?.DisplayName)}
            labelAppearance="swap"
            onChange={onChangeHandler}
            value={value}
            isValid={!errors.value}
            errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
        />
    );
};

CJDouble.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJDouble.defaultProps = {
    defaultValue: { value: '' },
};

export default memo(CJDouble);
