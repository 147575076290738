import { CampaignPageBonusListActionTypes as Types } from './types';

const setBonusListTableFilters = (filters) => ({
    type: Types.SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_FILTERS,
    payload: filters,
});

const setBonusListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setBonusListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setBonusListTableSorting = (sorting) => ({
    type: Types.SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_SORTING,
    payload: sorting,
});

export default {
    setBonusListTableFilters,
    setBonusListTablePagingPageNumber,
    setBonusListTablePagingPageSize,
    setBonusListTableSorting,
};
