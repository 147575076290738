import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { first, isNil, last, noop } from 'lodash';
// Import UI Components
import { Button } from '@geneui/components';
// Import Components
import CJCascadeDropDown from '../CJCascadeDropdown';
// Import Services
import { getMatchedProperties, mapPropertiesToCascadeDropdownModel } from 'services/customerJourney';
// Import Constants
import { l, CJArgumentValuesTypes, defaultLogicFunction } from 'constants/common';
import { formsTypes } from '../config';

const ReferenceWrapper = ({
    defaultValue,
    collectedProperties,
    optionType,
    Component,
    label,
    getUpdate,
    option,
    node,
    elements,
    ...rest
}) => {
    const { t } = useTranslation();

    const [isVisibleReference, setIsVisibleReference] = useState();
    const [properties, setProperties] = useState([]);

    const handleToggleCmpView = () => {
        if (isVisibleReference) {
            getUpdate({});
        } else {
            getUpdate(
                { blockId: null, propertyName: null, logicFunction: defaultLogicFunction },
                CJArgumentValuesTypes.ReferenceInArgument,
            );
        }
        setIsVisibleReference(!isVisibleReference);
    };

    useEffect(() => {
        const optionType = last(first(option?.BaseTypes)?.split('.'));
        const matchedProperties = getMatchedProperties(optionType, collectedProperties);
        setProperties(mapPropertiesToCascadeDropdownModel(matchedProperties));
        setIsVisibleReference(
            !isNil(defaultValue?.argumentInType) &&
                defaultValue.argumentInType === CJArgumentValuesTypes.ReferenceInArgument,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cascadeDropdownChangeHandler = ({ parentValue, value, logicFunction }) => {
        if (!isNil(parentValue) && !isNil(value)) {
            getUpdate(
                { blockId: parentValue, propertyName: value, logicFunction },
                CJArgumentValuesTypes.ReferenceInArgument,
            );
        }
    };

    return (
        <div className="crm-cj-reference-cmp">
            {optionType !== formsTypes.FilterCondition.optionType && (
                <Button
                    icon={isVisibleReference ? 'bc-icon-unlink' : 'bc-icon-link'}
                    appearance="minimal"
                    onClick={handleToggleCmpView}
                >
                    {isVisibleReference ? t(l.PrimitiveValue) : t(l.Reference)}
                </Button>
            )}
            {!isNil(isVisibleReference) && (
                <>
                    {optionType !== formsTypes.FilterCondition.optionType && isVisibleReference ? (
                        <CJCascadeDropDown
                            value={
                                isNil(defaultValue?.blockId)
                                    ? null
                                    : {
                                          parentValue: defaultValue?.blockId,
                                          value: defaultValue?.propertyName,
                                          logicFunction: defaultValue?.logicFunction,
                                      }
                            } // TODO: when cascade drop down is full ready
                            data={properties}
                            option={option}
                            node={node}
                            onChange={cascadeDropdownChangeHandler}
                            elements={elements}
                        />
                    ) : (
                        <Component
                            collectedProperties={collectedProperties}
                            label={label}
                            getUpdate={getUpdate}
                            defaultValue={defaultValue}
                            option={option}
                            node={node}
                            elements={elements}
                            {...rest}
                        />
                    )}
                </>
            )}
        </div>
    );
};

ReferenceWrapper.propTypes = {
    collectedProperties: PropTypes.array.isRequired,
    optionType: PropTypes.string.isRequired,
    Component: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.any.isRequired,
    getUpdate: PropTypes.func,
    option: PropTypes.object,
    node: PropTypes.object,
    elements: PropTypes.array,
};

ReferenceWrapper.defaultProps = {
    getUpdate: noop,
};

export default memo(ReferenceWrapper);
