// Import constants
import { ErrorShowType, l } from 'constants/common';

const compositeReportValidationScenario = () => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    ChoosedReportsFirstChild: {
        isValid: null,
        value: false,
        type: ErrorShowType.Toaster,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.PleaseAddFirstReport,
                ParametersValues: 'false',
            },
        ],
    },
    ChoosedReports: {
        isValid: null,
        value: false,
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.ReportContainerMustContainMinimumTwoReports,
                ParametersValues: 'false',
            },
        ],
    },
    Reports: {
        isValid: null,
        value: [],
        type: ErrorShowType.Toaster,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'unique',
                ErrorMessage: l.ReportContainerMustContainUniqueReports,
                ParametersValues: null,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.ReportContainerMustContainMinimumTwoReports,
                ParametersValues: '2',
            },
        ],
    },
});

export { compositeReportValidationScenario };
