import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
// Import  Constants
import { l, ActionsIcons } from 'constants/common';

const CommunicationActionsButtons = ({ data, resendCommunicationAction }) => {
    const { CanResend } = data;
    const isVisibleResend = () => CanResend;

    const { t } = useTranslation();

    return (
        <>
            {isVisibleResend() && (
                <Tooltip text={t(l.ResendAction)}>
                    <Button
                        icon={ActionsIcons.Resend}
                        appearance="minimal"
                        color="primary"
                        onClick={() => resendCommunicationAction(data)}
                    />
                </Tooltip>
            )}
        </>
    );
};

CommunicationActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    resendCommunicationAction: PropTypes.func,
};

CommunicationActionsButtons.defaultProps = {
    resendCommunicationAction: noop,
};

export default memo(CommunicationActionsButtons);
