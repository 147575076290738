/* eslint-disable react/jsx-no-bind */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
// Import UI components
import { Icon } from '@geneui/components';
// Import components
import FilterGroupItem from './FilterGroupItem';
import { ConfirmationModal } from 'components';
// Import container
import { UIHashComparisonContainer } from '../UIHashContainers';
// Import actions
import { DynamicSegmentActions } from 'actions';
// Import constants
import { l, ModalsClassNames, ActionsIcons } from 'constants/common';
// Import services
import { Helpers } from 'services';

const { hexToRgb } = Helpers;
const { deleteFilter } = DynamicSegmentActions;

const FilterGroup = ({
    groupDisplayName,
    list,
    color,
    resetActiveList,
    setEditingFilter,
    editingFilter,
    handleChangeActiveList,
    withoutActions,
    setReadOnly,
    readOnly,
    setClientsCount,
    tabActiveIndex,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    function setDeleteModalOpen() {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    }

    function handleDeleteGroupFilters() {
        dispatch(
            deleteFilter({
                filterListHashKey: list[0].filter.parentHashKey,
                filterOrderIds: list.map((item) => item.orderId),
                tabId: tabActiveIndex,
            }),
        );
        setClientsCount({ Total: 0, Count: 0 });
        setDeleteModalOpen();
    }

    function toFilterListCategory() {
        handleChangeActiveList(list[0].filter.parentHashKey);
    }

    const rgbColor = hexToRgb(color);

    return (
        <div className="crm-dynamic-segment-filters-item-wrapper">
            <div
                className="crm-dynamic-segment-filters-item"
                style={{
                    '--kpi-group-rgb-color': `rgb(${rgbColor})`,
                    '--kpi-group-rgb-color-disabled': `rgb(${rgbColor} / 40%)`,
                }}
            >
                <ConfirmationModal
                    isVisibleModal={isOpenDeleteModal}
                    onOk={handleDeleteGroupFilters}
                    onCancel={setDeleteModalOpen}
                    questionLabel={t(l.DoYouWantToDeleteThisFilterGroupFromDynamicSegmentFilterContainer)}
                    titleText={t(l.DeleteFilterGroupFromDynamicSegmentFilterContainer)}
                    iconType={ActionsIcons.Delete}
                    actionLabel={t(l.Delete)}
                    className={ModalsClassNames.Delete}
                />
                <div className="crm-dynamic-segment-filters-item__header crm-flex crm-justify-content-between crm-align-items-center">
                    <div className="crm-dynamic-segment-filters-item__header__title">{groupDisplayName}</div>
                    <div
                        className={`crm-dynamic-segment-filters-item__header__icons crm-flex crm-justify-content-between ${groupDisplayName
                            .replace(/\s+/g, '-')
                            .toLowerCase()}`}
                    >
                        {!withoutActions && (
                            <Icon type="bc-icon-add" onClick={toFilterListCategory} disabled={readOnly} />
                        )}
                        {!withoutActions && (
                            <Icon type="bc-icon-trash" onClick={setDeleteModalOpen} disabled={readOnly} />
                        )}
                    </div>
                </div>
                {list.map(({ filter, orderId }, index) => {
                    return (
                        <FilterGroupItem
                            resetActiveList={resetActiveList}
                            key={index}
                            item={filter}
                            setEditingFilter={setEditingFilter}
                            editingFilter={editingFilter}
                            handleChangeActiveList={handleChangeActiveList}
                            index={orderId}
                            withoutActions={withoutActions}
                            setReadOnly={setReadOnly}
                            readOnly={readOnly}
                            additionalData={filter.additionalData}
                            setClientsCount={setClientsCount}
                            tabActiveIndex={tabActiveIndex}
                        />
                    );
                })}
            </div>
        </div>
    );
};

FilterGroup.propTypes = {
    groupDisplayName: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.instanceOf(UIHashComparisonContainer)).isRequired,
    color: PropTypes.string,
    handleChangeActiveList: PropTypes.func,
    withoutActions: PropTypes.bool,
    readOnly: PropTypes.bool,
    editingFilter: PropTypes.string,
    setReadOnly: PropTypes.func,
    resetActiveList: PropTypes.func,
    setEditingFilter: PropTypes.func,
    clientsCount: PropTypes.string.isRequired,
    setClientsCount: PropTypes.func.isRequired,
    tabActiveIndex: PropTypes.number.isRequired,
};

FilterGroup.defaultProps = {
    groupDisplayName: '',
    withoutActions: false,
    editingFilter: '',
    resetActiveList: noop,
    setEditingFilter: noop,
    readOnly: false,
    setReadOnly: noop,
    handleChangeActiveList: noop,
};

export default memo(FilterGroup);
