import React, { memo, useState, useEffect, useCallback, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isArray, noop, isEmpty, isNil } from 'lodash';
// Import Components
import { DataTable } from 'components';
import { ReportActionsButtons, ReportActionsModals } from 'components/ReportList';
// Import Hooks
import { useListBaseRequestBody, useToaster, useGoToRoute, useRequest, useQueryParams } from 'hooks';
// Import Services
import { ReportHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Configs
import { DataTableColumnsConfigs } from './config';
// Import Constants
import { TargetTypeEnum, l, ReportTypesLabels, ModalsActions, AlertTypes } from 'constants/common';
import { RoutesList } from 'routes';
// Import SCSS
import 'assets/scss/reportListPage.scss';

const {
    REPORT_EXECUTED_LIST,
    REPORT_EDIT,
    REPORT_CLONE,
    REPORT_SCHEDULE,
    REPORT_EXECUTE,
    REPORT_COMPOSITE_EDIT,
    REPORT_COMPOSITE_CLONE,
    REPORT_CUSTOMER_JOURNEY_EDIT,
    REPORT_CUSTOMER_JOURNEY_CLONE,
} = RoutesList;
const { getPageNumber } = Helpers;

// eslint-disable-next-line react/display-name
const ReportList = forwardRef(
    (
        {
            tableKey,
            getHeaderActions,
            dataTableClassNames,
            withManageLabels,
            isShowVerticalDots,
            isShowColumnChooser,
            isHasRowMultiSelect,
            isHasBulkActions,
            withQueryParams,
            listAction,
            listSelectorKey,
            getTitle,
            isViewMode,
            rowsUpdateCallback,
            isHasRowRadioSelect,
            getRadioSelectedRow,
            defaultRadioSelectedRow,
            getDragAcceptType,
            dragDisable,
            CustomPreview,
        },
        ref,
    ) => {
        const dispatch = useDispatch();
        const { search } = useLocation();
        const { t } = useTranslation();
        const { showToaster } = useToaster();
        const { goToRoute } = useGoToRoute();
        const { doPostRequest } = useRequest();

        const { tableData } = useSelector((state) => state[listSelectorKey]);

        const { filters, paging, sorting, sortingThen } = tableData;

        const [
            baseRequestBody,
            // eslint-disable-next-line no-unused-vars
            _newFilterField,
            setBaseRequestBodyFilters,
            setPagingPageNumber,
            setPagingPageSize,
            setSorting,
        ] = useListBaseRequestBody(filters, paging, sorting, sortingThen);

        const {
            resetReportListState,
            setReportListTablePagingPageNumber,
            setReportListTablePagingPageSize,
            setReportListTableSorting,
        } = listAction;

        const [isReportListLoading, setIsReportListLoading] = useState(false);
        const [reportListFilters, setReportListFilters] = useState(baseRequestBody);
        const [reportListData, setReportListData] = useState([]);
        const [reportListDataCount, setReportListDataCount] = useState(0);

        const [isDeleteModalOpenedState, setIsDeleteModalOpenedState] = useState(false);
        const [isStopScheduleModalOpenedState, setIsStopScheduleModalOpenedState] = useState(false);
        const [isInfoModalOpenedState, setIsInfoModalOpenedState] = useState(false);
        const [isExecuteModalOpenedState, setIsExecuteModalOpenedState] = useState(false);
        const [isUsedInConfirmationModalOpenedState, setIsUsedInConfirmationModalOpenedState] = useState(false);
        const [isArchiveModalOpenedState, setIsArchiveModalOpenedState] = useState(false);
        const [isUnarchiveModalOpenedState, setIsUnarchiveModalOpenedState] = useState(false);
        const [openedModalData, setOpenedModalData] = useState({});

        const { columns } = DataTableColumnsConfigs(t);
        const isInit = useRef(false);
        const dataTableRef = useRef();

        const { getReportsRequest, getReportsByReport } = useMemo(
            () => ({
                getReportsRequest: ReportHttpService.getReportList(),
                getReportsByReport: ReportHttpService.getReportsByReport(),
            }),
            [],
        );

        const goToRouteQueryParams = useQueryParams();

        const getReports = useCallback(
            (filter = reportListFilters) => {
                setIsReportListLoading(true);
                doPostRequest(getReportsRequest.request, {
                    requestBody: filter,
                    successCallback: ({ Data, Count }) => {
                        const rows = getTableRows(Data || []);
                        setReportListData(rows);
                        setReportListDataCount(Count);
                        setIsReportListLoading(false);
                        rowsUpdateCallback(rows);
                    },
                    errorCallback: () => {
                        setIsReportListLoading(false);
                    },
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [reportListFilters],
        );

        const goToEditReport = (report) => {
            const { ReportId, ReportType } = report;
            const params = { reportId: ReportId };

            const route =
                ReportType === ReportTypesLabels.Report
                    ? REPORT_EDIT
                    : ReportType === ReportTypesLabels.CompositeReport
                    ? REPORT_COMPOSITE_EDIT
                    : REPORT_CUSTOMER_JOURNEY_EDIT;

            goToRoute(route, params, goToRouteQueryParams.encode({ backParams: search }));
        };

        // Actions part
        const deleteReportAction = (report) => {
            setOpenedModalData(report);
            setIsDeleteModalOpenedState(true);
        };

        const executeReportAction = (report) => {
            if (report.HasParameters) {
                const { ReportId, ReportType, Name } = report;
                const params = { reportId: ReportId, reportType: ReportType, reportName: Name.Name };
                goToRoute(REPORT_EXECUTE, params, goToRouteQueryParams.encode({ backParams: search }));
            } else {
                setOpenedModalData(report);
                setIsExecuteModalOpenedState(true);
            }
        };

        const scheduleReportAction = (report) => {
            const { ReportId, ReportType, Name } = report;
            const params = { reportId: ReportId, reportType: ReportType, reportName: Name.Name };
            goToRoute(REPORT_SCHEDULE, params, goToRouteQueryParams.encode({ backParams: search }));
        };

        const stopScheduleReportAction = (report) => {
            setOpenedModalData(report);
            setIsStopScheduleModalOpenedState(true);
        };

        const infoReportAction = (report) => {
            setOpenedModalData({ ...report, isViewMode });
            setIsInfoModalOpenedState(true);
        };

        const getReportVersionListAction = (report) => {
            const { ReportId, ReportType } = report;
            const params = { reportId: ReportId, reportType: ReportType };
            goToRoute(REPORT_EXECUTED_LIST, params, goToRouteQueryParams.encode({ backParams: search }));
        };

        const cloneReportAction = (report) => {
            const { ReportId, ReportType } = report;
            const params = { reportId: ReportId };

            const route =
                ReportType === ReportTypesLabels.Report
                    ? REPORT_CLONE
                    : ReportType === ReportTypesLabels.CompositeReport
                    ? REPORT_COMPOSITE_CLONE
                    : REPORT_CUSTOMER_JOURNEY_CLONE;

            goToRoute(route, params);
        };

        const editReportAction = (report) => {
            if (report.ReportType === ReportTypesLabels.Report) {
                doPostRequest(getReportsByReport.request, {
                    requestBody: {
                        reportId: report.ReportId,
                        reportType: report.ReportType,
                    },
                    successCallback: (Data) => {
                        if (isEmpty(Data)) {
                            goToEditReport(report);
                        } else {
                            setOpenedModalData({ objects: Data.map(({ Name, Type }) => ({ Name, Type })), report });
                            setIsUsedInConfirmationModalOpenedState(true);
                        }
                    },
                });
            } else {
                goToEditReport(report);
            }
        };

        const archiveReportAction = (report) => {
            setOpenedModalData(report);
            setIsArchiveModalOpenedState(true);
        };

        const unarchiveReportAction = (report) => {
            setOpenedModalData(report);
            setIsUnarchiveModalOpenedState(true);
        };

        const modalsStateSetDict = {
            deleteReport: {
                key: 'deleteReport',
                fn: setIsDeleteModalOpenedState,
            },
            stopScheduleReport: {
                key: 'stopScheduleReport',
                fn: setIsStopScheduleModalOpenedState,
            },
            infoReport: {
                key: 'infoReport',
                fn: setIsInfoModalOpenedState,
            },
            executeReport: {
                key: 'executeReport',
                fn: setIsExecuteModalOpenedState,
            },
            editReport: {
                key: 'editReport',
                fn: setIsUsedInConfirmationModalOpenedState,
            },
            archiveReport: {
                key: 'archiveReport',
                fn: setIsArchiveModalOpenedState,
            },
            unarchiveReport: {
                key: 'unarchiveReport',
                fn: setIsUnarchiveModalOpenedState,
            },
        };
        const onCloseModalHandler = (modalStateKey, action, alertType, alertMessage, count = 1) => {
            if (
                action === ModalsActions.UNSCHEDULE_REPORT ||
                action === ModalsActions.DELETE ||
                action === ModalsActions.EXECUTE_REPORT ||
                action === ModalsActions.ARCHIVE ||
                (action === ModalsActions.UNARCHIVE && modalStateKey !== modalsStateSetDict.infoReport.key)
            ) {
                dataTableRef.current.resetSelectedRows();
                dataTableRef.current.changePaginationPageNumber(
                    getPageNumber(
                        baseRequestBody.Filters,
                        action,
                        reportListDataCount,
                        reportListFilters.Pageing.PageSize,
                        reportListFilters.Pageing.PageNumber,
                        count,
                    ),
                );
            }
            showToaster(alertType, alertMessage);

            if (action === ModalsActions.SCHEDULE_REPORT && alertType === AlertTypes.danger) {
                modalsStateSetDict[modalStateKey].fn(true);
            } else {
                modalsStateSetDict[modalStateKey].fn(false);
            }
        };

        const getRowActionBar = (row) => {
            const { data } = row;

            return (
                <>
                    <ReportActionsButtons
                        data={data}
                        isInfoVisible={true}
                        getReportVersionListAction={getReportVersionListAction}
                        scheduleReportAction={scheduleReportAction}
                        stopScheduleReportAction={stopScheduleReportAction}
                        deleteReportAction={deleteReportAction}
                        cloneReportAction={cloneReportAction}
                        editReportAction={editReportAction}
                        infoReportAction={infoReportAction}
                        executeReportAction={executeReportAction}
                        archiveReportAction={archiveReportAction}
                        unarchiveReportAction={unarchiveReportAction}
                        isViewMode={isViewMode}
                    />
                </>
            );
        };

        // Data table part
        const setPagingPageNumberHandler = (pageNumber) => {
            setPagingPageNumber(pageNumber);
            dispatch(setReportListTablePagingPageNumber(pageNumber));
            setReportListFilters(baseRequestBody);
        };

        const setPagingPageSizeHandler = (pageSize) => {
            setPagingPageSize(pageSize);
            dispatch(setReportListTablePagingPageSize(pageSize));
            setReportListFilters(baseRequestBody);
        };

        const setSortingHandler = (sortingDirection, columnName) => {
            setSorting(sortingDirection, columnName);
            dispatch(setReportListTableSorting(baseRequestBody.Sorting));
            setReportListFilters(baseRequestBody);
        };

        const getTableColumns = () => {
            return columns.map((item) => ({ ...item, sortable: item.sortable && dragDisable }));
        };

        const getTableRows = (reportList) => {
            return reportList.map(
                ({
                    Name,
                    State,
                    CreatorName,
                    ModifierName,
                    ReportType,
                    ReportId,
                    CreatedDate,
                    ModifiedDate,
                    CategoryId,
                    CategoryName,
                    CategoryColor,
                    HasParameters,
                    CompositeExecutionCount,
                    SelfExecutionCount,
                    IsUsed,
                    ArchivedDate,
                    IsExported,
                }) => {
                    return {
                        hasHover: true,
                        dragDisable: dragDisable,
                        nestedData: [],
                        className: !isNil(ArchivedDate) ? 'crm-data-table-row-archive' : '',
                        data: {
                            rowKey: `${ReportId}${ReportType}`,
                            isRowOpend: false,
                            State,
                            Name: {
                                Name,
                                ReportId,
                                ReportType,
                                SelfExecutionCount,
                                isViewMode,
                            },
                            'CreatedBy.Name': CreatorName,
                            'ModifiedBy.Name': ModifierName,
                            ReportType,
                            ReportId,
                            CreatedDate,
                            ModifiedDate,
                            CategoryId,
                            CategoryName,
                            CategoryColor,
                            HasParameters,
                            SelfExecutionCount,
                            CompositeExecutionCount,
                            IsUsed,
                            ArchivedDate,
                            IsExported,
                        },
                    };
                },
            );
        };

        const getTableRefreshFn = () => {
            getReports(reportListFilters);
        };

        const getDataTableName = () => <span>{t(l.Reports)}</span>;

        const isAllow = (report) => {
            const { ReportType, IsUsed, ArchivedDate } = report;
            const isArchive = !isNil(ArchivedDate);

            return {
                delete: !(ReportType === ReportTypesLabels.CustomReport) && !IsUsed,
                archive: !isArchive,
                unarchive: isArchive,
            };
        };

        useImperativeHandle(ref, () => ({
            resetSelectedRows() {
                dataTableRef.current.resetSelectedRows();
            },
            reset() {
                dataTableRef.current.reset();
            },
        }));

        const cleanUp = () => {
            return () => {
                // Cancel all async processes
                getReportsRequest.cancel('ReportListPage:getReportsRequest');
                getReportsByReport.cancel('ReportListPage:getReportsByReport');
                // Reset store state
                resetReportListState && dispatch(resetReportListState());
            };
        };

        useEffect(cleanUp, []);

        useEffect(() => {
            if (isInit.current) {
                getReports(reportListFilters);
            }
            /* eslint-disable react-hooks/exhaustive-deps */
        }, [reportListFilters]);

        useEffect(() => {
            setBaseRequestBodyFilters(filters);
            setReportListFilters(baseRequestBody);

            isInit.current = true;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filters]);

        const getReportsNamesIds = (selectedRows) => {
            if (!isArray(selectedRows)) return;

            let allowDeleteReports = [];
            let notAllowDeleteReports = [];
            let allowArchiveReports = [];
            let notAllowArchiveReports = [];
            let allowUnarchiveReports = [];
            let notAllowUnarchiveReports = [];

            selectedRows.forEach(({ data }) => {
                const { ReportId, Name, ReportType } = data.Name;
                const isAllowData = isAllow(data);
                if (isAllowData.delete) {
                    allowDeleteReports.push({ reportId: ReportId, name: Name, reportType: ReportType });
                }

                if (!isAllowData.delete) {
                    notAllowDeleteReports.push({ reportId: ReportId, name: Name, reportType: ReportType });
                }
                if (isAllowData.archive) {
                    allowArchiveReports.push({ reportId: ReportId, name: Name, reportType: ReportType });
                } else {
                    notAllowArchiveReports.push({ reportId: ReportId, name: Name, reportType: ReportType });
                }

                if (isAllowData.unarchive) {
                    allowUnarchiveReports.push({ reportId: ReportId, name: Name, reportType: ReportType });
                } else {
                    notAllowUnarchiveReports.push({ reportId: ReportId, name: Name, reportType: ReportType });
                }
            });

            return {
                allowDeleteReports: allowDeleteReports,
                notAllowDeleteReports: notAllowDeleteReports,
                allowArchiveReports: allowArchiveReports,
                notAllowArchiveReports: notAllowArchiveReports,
                allowUnarchiveReports: allowUnarchiveReports,
                notAllowUnarchiveReports: notAllowUnarchiveReports,
            };
        };

        const deleteSelectedRows = (selectedRows) => {
            const namesIds = {
                allowDeleteReports: getReportsNamesIds(selectedRows).allowDeleteReports,
                notAllowDeleteReports: getReportsNamesIds(selectedRows).notAllowDeleteReports,
            };

            deleteReportAction(namesIds);
        };

        const archiveSelectedRows = (selectedRows) => {
            const reportNamesIds = getReportsNamesIds(selectedRows);
            const namesIds = {
                allowArchiveReports: reportNamesIds.allowArchiveReports,
                notAllowArchiveReports: reportNamesIds.notAllowArchiveReports,
            };
            archiveReportAction(namesIds);
        };

        const unarchiveSelectedRows = (selectedRows) => {
            const reportNamesIds = getReportsNamesIds(selectedRows);
            const namesIds = {
                allowUnarchiveReports: reportNamesIds.allowUnarchiveReports,
                notAllowUnarchiveReports: reportNamesIds.notAllowUnarchiveReports,
            };
            unarchiveReportAction(namesIds);
        };

        return (
            <>
                <DataTable
                    classNames={dataTableClassNames}
                    getTitle={getTitle}
                    rowKey="rowKey"
                    columnKey="dataKey"
                    name={getDataTableName()}
                    tableKey={tableKey}
                    isColumnsSortable={true}
                    withPagination={true}
                    withManageLabels={withManageLabels}
                    setLabelObjectIdKey={'ReportId'}
                    labelNameKey={'CategoryName'}
                    labelColorKey={'CategoryColor'}
                    labelObjectType={TargetTypeEnum.Report}
                    data={reportListData}
                    dataCount={reportListDataCount}
                    rowActionBar={getRowActionBar}
                    isRowExpandable={false}
                    columns={getTableColumns()}
                    isLoading={isReportListLoading}
                    isShowRefreshButton={true}
                    isShowVerticalDots={isShowVerticalDots}
                    isShowColumnChooser={isShowColumnChooser}
                    setPagingPageSize={setPagingPageSizeHandler}
                    setPagingPageNumber={setPagingPageNumberHandler}
                    sorting={
                        dragDisable
                            ? {
                                  name: reportListFilters.Sorting?.Name,
                                  direction: reportListFilters.Sorting?.Direction,
                              }
                            : {}
                    }
                    setSorting={setSortingHandler}
                    onRefreshClick={getTableRefreshFn}
                    headerActions={getHeaderActions()}
                    isHasRowMultiSelect={isHasRowMultiSelect}
                    isHasBulkActions={isHasBulkActions}
                    archiveSelectedRows={archiveSelectedRows}
                    unarchiveSelectedRows={unarchiveSelectedRows}
                    deleteSelectedRows={deleteSelectedRows}
                    recevedCurrentPageNumber={reportListFilters.Pageing.PageNumber}
                    withQueryParams={withQueryParams}
                    isHasRowRadioSelect={isHasRowRadioSelect}
                    getRadioSelectedRow={getRadioSelectedRow}
                    defaultRadioSelectedRow={defaultRadioSelectedRow}
                    getDragAcceptType={getDragAcceptType}
                    CustomPreview={CustomPreview}
                    ref={dataTableRef}
                />

                <ReportActionsModals
                    isDeleteModalOpenedState={isDeleteModalOpenedState}
                    isStopScheduleModalOpenedState={isStopScheduleModalOpenedState}
                    isInfoModalOpenedState={isInfoModalOpenedState}
                    isExecuteModalOpenedState={isExecuteModalOpenedState}
                    isUsedInConfirmationModalOpenedState={isUsedInConfirmationModalOpenedState}
                    isArchiveModalOpenedState={isArchiveModalOpenedState}
                    isUnarchiveModalOpenedState={isUnarchiveModalOpenedState}
                    onCloseModalHandler={onCloseModalHandler}
                    modalsStateSetDict={modalsStateSetDict}
                    openedModalData={openedModalData}
                    goToEditReport={goToEditReport}
                />
            </>
        );
    },
);

ReportList.propTypes = {
    listSelectorKey: PropTypes.string.isRequired,
    listAction: PropTypes.shape({
        setReportListTableFilters: PropTypes.func.isRequired,
        setReportListTablePagingPageNumber: PropTypes.func.isRequired,
        setReportListTablePagingPageSize: PropTypes.func.isRequired,
        setReportListTableSorting: PropTypes.func.isRequired,
        resetReportListState: PropTypes.func.isRequired,
    }).isRequired,
    getRadioSelectedRow: PropTypes.func,
    isShowVerticalDots: PropTypes.bool,
    withManageLabels: PropTypes.bool,
    isViewMode: PropTypes.bool,
    getHeaderActions: PropTypes.func,
    rowsUpdateCallback: PropTypes.func,
    isHasRowMultiSelect: PropTypes.bool,
    isHasRowRadioSelect: PropTypes.bool,
    isHasBulkActions: PropTypes.bool,
    isShowColumnChooser: PropTypes.bool,
    defaultRadioSelectedRow: PropTypes.object,
    getDragAcceptType: PropTypes.func,
    CustomPreview: PropTypes.node,
    dragDisable: PropTypes.bool,
    tableKey: PropTypes.string,
    withQueryParams: PropTypes.bool,
    dataTableClassNames: PropTypes.string,
    getTitle: PropTypes.func,
};

ReportList.defaultProps = {
    getHeaderActions: noop,
    rowsUpdateCallback: noop,
    getRadioSelectedRow: noop,
    isShowVerticalDots: true,
    withManageLabels: false,
    isViewMode: false,
    isHasRowMultiSelect: false,
    isHasRowRadioSelect: false,
    isHasBulkActions: false,
    isShowColumnChooser: true,
    defaultRadioSelectedRow: null,
    getDragAcceptType: noop,
    dragDisable: false,
    tableKey: 'reportListDataTable',
    withQueryParams: false,
    dataTableClassNames: 'default-height',
    getTitle: noop,
};

export default memo(ReportList);
