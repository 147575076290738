import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Import Constants
import { DisplayModeTypes } from 'constants/common';

const displayModeStyles = {
    [DisplayModeTypes.LIGHT_MODE]: {
        textColor: '#292929',
        backgroundColor: '#efefef',
        imageBorderColor: '#e4e4e4',
    },
    [DisplayModeTypes.DARK_MODE]: {
        textColor: '#FFFFFF',
        backgroundColor: '#3f4b5b',
        imageBorderColor: '#0c455c',
    },
};

const ViberPreview = ({ text, mediaUrl, buttonText, buttonUrl, displayMode }) => (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div
            style={{
                width: `${mediaUrl ? 286 : 332}px`,
                backgroundColor: displayModeStyles[displayMode].backgroundColor,
                borderRadius: '16px',
            }}
        >
            <div
                style={{
                    padding: '16px  16px  12px  16px',
                    color: displayModeStyles[displayMode].textColor,
                    wordBreak: 'break-all',
                }}
            >
                {text}
            </div>
            {mediaUrl && (
                <a href={buttonUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div
                        style={{
                            backgroundImage: `url(${mediaUrl})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            margin: '4px 0 16px 0',
                            width: '286px',
                            height: '286px',
                            boxSizing: 'border-box',
                            border: `1px solid ${displayModeStyles[displayMode].imageBorderColor}`,
                        }}
                    ></div>
                </a>
            )}
            {buttonText && (
                <a href={buttonUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div
                        style={{
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 16px',
                            padding: '0 20px',
                            color: '#FFFFFF',
                            backgroundColor: '#8851eb',
                            wordBreak: 'break-all',
                            fontFamily: 'sans-serif',
                            textAlign: 'center',
                            borderRadius: '20px',
                        }}
                    >
                        {buttonText}
                    </div>
                </a>
            )}
            <div
                style={{
                    height: '30px',
                    lineHeight: '30px',
                    paddingRight: '20px',
                    color: '#a3aab1',
                    fontFamily: 'sans-serif',
                    textAlign: 'right',
                }}
            >
                00:00
            </div>
        </div>
    </div>
);

ViberPreview.propTypes = {
    text: PropTypes.string.isRequired,
    mediaUrl: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    displayMode: PropTypes.string,
};

ViberPreview.defaultProps = {
    displayMode: DisplayModeTypes.LIGHT_MODE,
};

export default memo(ViberPreview);
