import { isNumber, isArray, isString, isBoolean, values } from 'lodash';
import { Helpers } from 'services';

const useListBaseRequestBody = (filters, paging, sorting, sortingThen) => {
    let baseRequestBody = {
        Filters: filters,
        Pageing: paging,
        Sorting: sorting,
        SortingThen: sortingThen,
    };

    const newFilterField = Helpers.generateRequestFilterField;

    const setFilters = (filtersList) => {
        if (!isArray(filtersList)) return;
        let latestFilters = {};
        filtersList.forEach((filter) => {
            latestFilters[filter.Name] = filter;
        });
        baseRequestBody.Filters = values(latestFilters);
    };

    const setPagingPageNumber = (pageNumber) => {
        if (!isNumber(pageNumber)) return;
        baseRequestBody.Pageing.PageNumber = pageNumber;
    };

    const setPagingPageSize = (pageSize) => {
        if (!isNumber(pageSize) && !isString(pageSize)) return;
        if (pageSize === 'all') {
            pageSize = null;
        }
        baseRequestBody.Pageing.PageSize = pageSize;
    };

    const setSorting = (sortingDirection, columnName) => {
        if (!isBoolean(sortingDirection) && !isString(columnName)) {
            return;
        }
        baseRequestBody.Sorting.Direction = sortingDirection;
        baseRequestBody.Sorting.Name = columnName;
    };

    return [baseRequestBody, newFilterField, setFilters, setPagingPageNumber, setPagingPageSize, setSorting];
};

export default useListBaseRequestBody;
