/* eslint-disable jsx-a11y/no-autofocus */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, keys, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
// Import Constants
import { l, MonthDayYearFormat, DateToISOStringFormat, TimeFormatWithoutSeconds, TimeZonePack } from 'constants/common';
// Import UI Components
import { Checkbox, Tooltip, Icon, TimePicker, Dropdown, Label } from '@geneui/components';
// Import Components
import { ValidatableDatePickerInput } from 'components';
import CJPreSaveModalCustomFields from './CJPreSaveModalCustomFields';
// Import Services
import Helpers from 'services/Helpers';

const { customMoment } = Helpers;

const CJPreSaveModalBodyContent = ({
    defaultSchedule,
    isVisibleStates,
    isValidStates,
    onChange,
    addValidationProperties,
    defaultCustomFields,
    onCustomFieldsChange,
    onCustomFieldsLoading,
}) => {
    const { t } = useTranslation();
    const { settings } = useSelector((state) => state.header);
    const { ActivePartner } = settings;

    const minDate = customMoment().add(-1, 'days').format(MonthDayYearFormat);

    const isVisibleStartDate = isVisibleStates?.startDate;
    const isVisibleEndDate = isVisibleStates?.endDate;

    const [isActiveStartDate, setIsActiveStartDate] = useState(false);
    const [autoStartDate, setAutoStartDate] = useState();
    const [autoStartTime, setAutoStartTime] = useState();
    const [timeZone, setTimeZone] = useState(ActivePartner.TimeZone);
    const [isActiveEndDate, setIsActiveEndDate] = useState(false);
    const [autoEndDate, setAutoEndDate] = useState();
    const [autoEndTime, setAutoEndTime] = useState();

    const checkboxChangeHandler = (setterFn) => {
        return ({ target }) => {
            const { checked } = target;
            setterFn(checked);
        };
    };

    const timePickerChangeHandler = (setterFn) => {
        return ({ target }) => {
            const { value } = target;
            setterFn(value);
        };
    };

    const datePickerChangeHandler = (setterFn) => {
        return (value) => {
            setterFn(value);
        };
    };

    const timeZoneChangeHandler = (selectedTimeZone) => {
        setTimeZone(selectedTimeZone?.value);
    };

    useEffect(() => {
        if (isNil(autoStartDate)) return;

        if (isEmpty(autoStartTime)) {
            setAutoStartTime(customMoment().format(TimeFormatWithoutSeconds));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoStartDate]);

    useEffect(() => {
        if (isNil(autoEndDate)) return;

        if (isEmpty(autoEndTime)) {
            setAutoEndTime(customMoment().format(TimeFormatWithoutSeconds));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoEndDate]);

    useEffect(() => {
        let startDate = null,
            endDate = null;

        if (!isNil(autoStartDate) && !isNil(autoStartTime) && isActiveStartDate && isVisibleStartDate) {
            const tmpStartTime = autoStartTime.split(':');
            startDate = moment(autoStartDate, MonthDayYearFormat)
                .set({ hour: tmpStartTime[0], minute: tmpStartTime[1] })
                .format(DateToISOStringFormat);
        }

        if (!isNil(autoEndDate) && !isNil(autoEndTime) && isActiveEndDate && isVisibleEndDate) {
            const tmpEndTime = autoEndTime.split(':');
            endDate = moment(autoEndDate, MonthDayYearFormat)
                .set({ hour: tmpEndTime[0], minute: tmpEndTime[1] })
                .format(DateToISOStringFormat);
        }

        onChange({
            StartDate: startDate,
            EndDate: endDate,
            CronSchedule: null,
            TimeZone: timeZone,
            isActiveStartDate,
            isActiveEndDate,
            startTime: autoStartTime,
            endTime: autoEndTime,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoStartDate, autoStartTime, autoEndDate, autoEndTime, timeZone, isActiveStartDate, isActiveEndDate]);

    const init = () => {
        if (!isNil(defaultSchedule)) {
            const { StartDate, EndDate, TimeZone } = defaultSchedule;

            if (!isNil(StartDate) && isVisibleStartDate) {
                setIsActiveStartDate(true);
                const tmpStartDate = moment(StartDate, DateToISOStringFormat);
                setAutoStartDate(tmpStartDate.format(MonthDayYearFormat));
                setAutoStartTime(tmpStartDate.format(TimeFormatWithoutSeconds));
            }

            if (!isNil(EndDate) && isVisibleEndDate) {
                setIsActiveEndDate(true);
                const tmpEndDate = moment(EndDate, DateToISOStringFormat);
                setAutoEndDate(tmpEndDate.format(MonthDayYearFormat));
                setAutoEndTime(tmpEndDate.format(TimeFormatWithoutSeconds));
            }

            if (!isNil(TimeZone)) {
                setTimeZone(TimeZone);
            }
        }
    };

    useEffect(init, []);

    return (
        <div className="crm-cj-pre-save-modal-body-content">
            {(isVisibleStartDate || isVisibleEndDate) && (
                <div className="title-wrapper">
                    <p className="title-wrapper-text">{t(l.Schedule)}</p>
                    <span className="title-wrapper-line" />
                </div>
            )}
            {isVisibleStartDate && (
                <div>
                    <div>
                        <Checkbox
                            label={t(l.AutoStart)}
                            checked={isActiveStartDate}
                            onChange={checkboxChangeHandler(setIsActiveStartDate)}
                        />
                        <Tooltip text={t(l.CJAutoStartInputInfo)} position="right">
                            <Icon type="bc-icon-info" />
                        </Tooltip>
                    </div>
                    <div>
                        {isActiveStartDate && (
                            <>
                                <ValidatableDatePickerInput
                                    onChange={datePickerChangeHandler(setAutoStartDate)}
                                    value={autoStartDate}
                                    placeholder={t(l.AutoStart)}
                                    min={minDate}
                                    isTouched={true}
                                    isValid={isValidStates?.startDate?.isValid}
                                    errorText={isValidStates?.startDate?.errorMessage}
                                />
                                <TimePicker
                                    appearance="outline"
                                    value={autoStartTime}
                                    onChange={timePickerChangeHandler(setAutoStartTime)}
                                    showSeconds={false}
                                    isValid={isValidStates?.startTime?.isValid}
                                    errorText={isValidStates?.startTime?.errorMessage}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
            {isVisibleEndDate && (
                <div>
                    <div>
                        <Checkbox
                            label={t(l.AutoEnd)}
                            checked={isActiveEndDate}
                            onChange={checkboxChangeHandler(setIsActiveEndDate)}
                        />
                        <Tooltip text={t(l.CJAutoEndInputInfo)} position="right">
                            <Icon type="bc-icon-info" />
                        </Tooltip>
                    </div>
                    <div>
                        {isActiveEndDate && (
                            <>
                                <ValidatableDatePickerInput
                                    onChange={datePickerChangeHandler(setAutoEndDate)}
                                    value={autoEndDate}
                                    placeholder={t(l.AutoEnd)}
                                    min={minDate}
                                    isTouched={true}
                                    isValid={isValidStates?.endDate?.isValid}
                                    errorText={isValidStates?.endDate?.errorMessage}
                                />
                                <TimePicker
                                    appearance="outline"
                                    value={autoEndTime}
                                    onChange={timePickerChangeHandler(setAutoEndTime)}
                                    showSeconds={false}
                                    isValid={isValidStates?.endTime?.isValid}
                                    errorText={isValidStates?.endTime?.errorMessage}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
            {((isVisibleStartDate && isActiveStartDate) || (isVisibleEndDate && isActiveEndDate)) && (
                <div>
                    <div>
                        <Label>{t(l.TimeZone)}</Label>
                        <Tooltip text={t(l.CJAutoStartEndTimeZoneInfo)} position="right">
                            <Icon type="bc-icon-info" />
                        </Tooltip>
                    </div>
                    <div>
                        <Dropdown
                            flexibility="full-width"
                            hasSearch={true}
                            onChange={timeZoneChangeHandler}
                            value={timeZone}
                            appearance="outline"
                            data={sortBy(
                                keys(TimeZonePack).map((key) => ({ label: TimeZonePack[key], value: +key })),
                                ['value'],
                            )}
                            noDataText={t(l.NoDataFound)}
                        />
                    </div>
                </div>
            )}
            <CJPreSaveModalCustomFields
                customFieldsValues={defaultCustomFields}
                setCustomFieldsValues={onCustomFieldsChange}
                addValidationProperties={addValidationProperties}
                onCustomFieldsLoading={onCustomFieldsLoading}
            />
        </div>
    );
};

CJPreSaveModalBodyContent.propTypes = {
    defaultSchedule: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    addValidationProperties: PropTypes.func.isRequired,
    isVisibleStates: PropTypes.object.isRequired,
    isValidStates: PropTypes.object.isRequired,
    defaultCustomFields: PropTypes.object.isRequired,
    onCustomFieldsChange: PropTypes.func.isRequired,
    onCustomFieldsLoading: PropTypes.func,
};

export default memo(CJPreSaveModalBodyContent);
