import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Button, Icon } from '@geneui/components';
// Import constants
import { l, ReportTypesLabels } from 'constants/common';

const ReportItemView = ({ type, title, isClearable, onClear }) => {
    const { t } = useTranslation();

    return (
        <div className="drop-here">
            {type === ReportTypesLabels.Report && (
                <Icon type="icon bc-icon-crm-standart-report crm-segment-type-icon dynamic" />
            )}

            <div>
                <div className="drop-here-title">
                    <span>
                        {t(l.Title)}
                        {':'}
                    </span>
                    <p>{title}</p>
                </div>

                <div className="drop-here-title">
                    <span>
                        {t(l.Type)}
                        {':'}
                    </span>
                    <p>{t(ReportTypesLabels[type])}</p>
                </div>
                {isClearable && <Button color="primary" appearance="minimal" icon="bc-icon-clear" onClick={onClear} />}
            </div>
        </div>
    );
};

ReportItemView.propTypes = {
    type: PropTypes.number,
    title: PropTypes.string,
    isClearable: PropTypes.bool,
    onClear: PropTypes.func,
};

ReportItemView.defaultProps = {
    isClearable: false,
    onClear: noop,
};

export default memo(ReportItemView);
