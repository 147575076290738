import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Import Components
import CJFilterGroup from './CJFilterGroup';
// Import Constants
import { LogicTypesValues, FilterGroupLimit } from 'constants/common';

const CJFilterCondition = ({
    collectedProperties,
    label,
    groupLabel,
    defaultValue,
    getUpdate,
    option,
    node,
    elements,
}) => {
    return (
        <div className="crm-cj-filter-condition-cmp">
            {label}
            <CJFilterGroup
                collectedProperties={collectedProperties}
                option={option}
                node={node}
                elements={elements}
                defaultValue={defaultValue}
                getUpdate={getUpdate}
                limit={FilterGroupLimit}
                isDeletable={false}
                isCloneable={false}
                withLabel={true}
                label={groupLabel}
            />
        </div>
    );
};

CJFilterCondition.propTypes = {
    defaultValue: PropTypes.shape({
        filters: PropTypes.array,
        logic: PropTypes.string,
    }),
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    getUpdate: PropTypes.func.isRequired,
    label: PropTypes.string,
    groupLabel: PropTypes.string,
    collectedProperties: PropTypes.array,
    option: PropTypes.object,
};

CJFilterCondition.defaultProps = {
    defaultValue: {
        logic: LogicTypesValues.and,
        filters: [],
    },
    collectedProperties: [],
};

export default memo(CJFilterCondition);
