import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
// Import Constants
import { DefaultLangId } from 'constants/common';

i18n.use(XHR).init({
    debug: false,
    fallbackLng: DefaultLangId,
    load: 'languageOnly',
    keySeparator: false,

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {},

    react: {
        useSuspense: false,
    },

    ns: ['translations'],
    defaultNS: 'translations',
});

export default i18n;
