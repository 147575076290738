import { PageValidationActionTypes as Types } from './types';

const setPageValidation = (payload) => ({
    type: Types.SET_PAGE_VALIDATION_DATA,
    payload,
});

const changePageValidation = (payload) => ({
    type: Types.CHANGE_PAGE_VALIDATION_DATA,
    payload,
});

const clearPageValidation = () => ({
    type: Types.CLEAR_PAGE_VALIDATION_DATA,
});

const addPageValidationScenarioFields = (payload) => ({
    type: Types.ADD_PAGE_VALIDATION_SCENARIO_FIELDS,
    payload,
});

const removePageValidationScenarioFields = (payload) => ({
    type: Types.REMOVE_PAGE_VALIDATION_SCENARIO_FIELDS,
    payload,
});

export default {
    setPageValidation,
    changePageValidation,
    clearPageValidation,
    addPageValidationScenarioFields,
    removePageValidationScenarioFields,
};
