import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { has, isEmpty } from 'lodash';
// Import UI Components
import { ConfirmationModal, CustomerJourneyInfoModal, ConfirmationModalWithWarning } from 'components';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { ModalsActions, l, ActionsIcons, ModalsClassNames } from 'constants/common';
// Import Services
import { CustomerJourneyHttpService } from 'services/http';
// Import Helpers
import { Helpers } from 'services';

const { getUsedInConfirmationText } = Helpers;

// TODO: change all actions with service with doAction methods
const CustomerJourneyActionsModals = ({
    isDeleteModalOpenedState,
    isUsedInConfirmationModalOpenedState,
    isArchiveModalOpenedState,
    isUnArchiveModalOpenedState,
    isStopSendModalOpenedState,
    isStartCustomerJourneyModalOpenedState,
    isResumeModalOpenedState,
    isInfoModalOpenedState,
    onCloseModalHandler,
    modalsStateSetDict,
    openedModalData,
    goToEditCustomerJourney,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { doPostRequest, doDeleteRequest } = useRequest();

    const {
        archiveCustomerJourneyRequest,
        archiveCustomerJourneysRequest,
        unarchiveCustomerJourneyRequest,
        unarchiveCustomerJourneysRequest,
        deleteCustomerJourneyRequest,
        deleteCustomerJourneysRequest,
        stopSendCustomerJourneyRequest,
        startCustomerJourneyRequest,
        resumeCustomerJourneyRequest,
    } = useRef({
        deleteCustomerJourneyRequest: CustomerJourneyHttpService.deleteCustomerJourney(),
        deleteCustomerJourneysRequest: CustomerJourneyHttpService.deleteCustomerJourneys(),
        archiveCustomerJourneyRequest: CustomerJourneyHttpService.archiveCustomerJourney(),
        archiveCustomerJourneysRequest: CustomerJourneyHttpService.archiveCustomerJourneys(),
        unarchiveCustomerJourneyRequest: CustomerJourneyHttpService.unarchiveCustomerJourney(),
        unarchiveCustomerJourneysRequest: CustomerJourneyHttpService.unarchiveCustomerJourneys(),
        stopSendCustomerJourneyRequest: CustomerJourneyHttpService.stopSendCustomerJourney(),
        startCustomerJourneyRequest: CustomerJourneyHttpService.startCustomerJourney(),
        resumeCustomerJourneyRequest: CustomerJourneyHttpService.resumeCustomerJourney(),
    }).current;

    const deleteCustomerJourney = () => {
        setIsLoading(true);
        doDeleteRequest(deleteCustomerJourneyRequest.request, {
            queryString: { workflowId: openedModalData.WorkflowId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.deleteCustomerJourney.key,
                ModalsActions.DELETE,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const deleteCustomerJourneys = () => {
        setIsLoading(true);
        const { allowDeleteCustomerJourneys } = openedModalData;
        if (isEmpty(allowDeleteCustomerJourneys)) {
            return onCloseModalHandler(modalsStateSetDict.deleteCustomerJourney.key);
        }
        const allowDeleteCustomerJourneysIds = allowDeleteCustomerJourneys.map(({ workflowId }) => {
            return workflowId;
        });

        doPostRequest(deleteCustomerJourneysRequest.request, {
            requestBody: allowDeleteCustomerJourneysIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.deleteCustomerJourney.key,
                ModalsActions.DELETE,
                AlertType,
                AlertMessage,
                allowDeleteCustomerJourneys.length,
            );
            setIsLoading(false);
        });
    };

    const archiveCustomerJourney = () => {
        setIsLoading(true);
        doPostRequest(archiveCustomerJourneyRequest.request, {
            requestBody: null,
            queryString: { workflowId: openedModalData.WorkflowId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.archiveCustomerJourney.key,
                ModalsActions.ARCHIVE,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const archiveCustomerJourneys = () => {
        setIsLoading(true);
        const { allowArchiveCustomerJourneys } = openedModalData;
        if (isEmpty(allowArchiveCustomerJourneys)) {
            return onCloseModalHandler(modalsStateSetDict.archiveCustomerJourney.key);
        }
        const allowArchiveCustomerJourneysIds = allowArchiveCustomerJourneys.map(({ workflowId }) => {
            return workflowId;
        });
        doPostRequest(archiveCustomerJourneysRequest.request, {
            requestBody: allowArchiveCustomerJourneysIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.archiveCustomerJourney.key,
                ModalsActions.ARCHIVE,
                AlertType,
                AlertMessage,
                allowArchiveCustomerJourneys.length,
            );
            setIsLoading(false);
        });
    };

    const unarchiveCustomerJourney = () => {
        setIsLoading(true);
        doPostRequest(unarchiveCustomerJourneyRequest.request, {
            requestBody: null,
            queryString: { workflowId: openedModalData.WorkflowId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.unarchiveCustomerJourney.key,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const unarchiveCustomerJourneys = () => {
        setIsLoading(true);
        const { allowUnarchiveCustomerJourneys } = openedModalData;
        if (isEmpty(allowUnarchiveCustomerJourneys)) {
            return onCloseModalHandler(modalsStateSetDict.archiveSegment.key);
        }
        const allowUnarchiveCustomerJourneysIds = allowUnarchiveCustomerJourneys.map(({ workflowId }) => {
            return workflowId;
        });
        doPostRequest(unarchiveCustomerJourneysRequest.request, {
            requestBody: allowUnarchiveCustomerJourneysIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.unarchiveCustomerJourney.key,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
                allowUnarchiveCustomerJourneys.length,
            );
            setIsLoading(false);
        });
    };

    const stopSendCustomerJourney = () => {
        setIsLoading(true);
        doPostRequest(stopSendCustomerJourneyRequest.request, {
            requestBody: null,
            queryString: { workflowId: openedModalData.WorkflowId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.stopSendCustomerJourney.key,
                ModalsActions.CUSTOMER_JOURNEY_STOP,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const startCustomerJourney = () => {
        setIsLoading(true);
        doPostRequest(startCustomerJourneyRequest.request, {
            requestBody: null,
            queryString: { workflowId: openedModalData.WorkflowId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.startCustomerJourney.key,
                ModalsActions.START,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const resumeCustomerJourney = () => {
        setIsLoading(true);
        doPostRequest(resumeCustomerJourneyRequest.request, {
            requestBody: null,
            queryString: { workflowId: openedModalData.WorkflowId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.resumeCustomerJourney.key,
                ModalsActions.RESUME,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const isBulkAction = () => {
        return (
            has(openedModalData, 'allowDeleteCustomerJourneys') ||
            has(openedModalData, 'allowArchiveCustomerJourneys') ||
            has(openedModalData, 'allowUnarchiveCustomerJourneys')
        );
    };

    const cleanUp = () => {
        return () => {
            deleteCustomerJourneyRequest.cancel('CustomerJourneyListPage:deleteCustomerJourneyRequest');
            archiveCustomerJourneyRequest.cancel('CustomerJourneyListPage:archiveCustomerJourneyRequest');
            unarchiveCustomerJourneyRequest.cancel('CustomerJourneyListPage:unarchiveCustomerJourneyRequest');
            stopSendCustomerJourneyRequest.cancel('CustomerJourneyListPage:stopSendCustomerJourneyRequest');
            startCustomerJourneyRequest.cancel('CustomerJourneyListPage:startCustomerJourneyRequest');
            resumeCustomerJourneyRequest.cancel('CustomerJourneyListPage:resumeCustomerJourneyRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        !isEmpty(openedModalData) && (
            <>
                {isDeleteModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? deleteCustomerJourneys : deleteCustomerJourney}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowDeleteCustomerJourneys) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.deleteCustomerJourney.key)}
                        isVisibleModal={isDeleteModalOpenedState}
                        titleText={isBulkAction() ? t(l.DeleteSelectedCustomerJourneys) : t(l.DeleteCustomerJourney)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Delete}
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                        allowDataList={openedModalData.allowDeleteCustomerJourneys}
                        notAllowDataList={openedModalData.notAllowDeleteCustomerJourneys}
                        allowDataLabel={l.AllowDeleteSelectedCustomerJourneys}
                        notAllowDataLabel={l.NotAllowDeleteSelectedCustomerJourneys}
                        questionLabel={
                            isBulkAction()
                                ? l.DeleteSelectedCustomerJourneysQuestion
                                : t(l.AreYouSureYouWantToDeletCustomerJourney, {
                                      workflowName: `"${openedModalData.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isUsedInConfirmationModalOpenedState && (
                    <ConfirmationModal
                        onOk={() => goToEditCustomerJourney(openedModalData?.workflow)}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.editCustomerJourney.key)}
                        isVisibleModal={isUsedInConfirmationModalOpenedState}
                        titleText={t(l.EditCustomerJourney)}
                        questionLabel={getUsedInConfirmationText(
                            t,
                            l.CustomerJourney,
                            openedModalData?.objects,
                            l.IsUsedInObjectsLabel,
                        )}
                        iconType={ActionsIcons.UsedIn}
                        actionLabel={t(l.Continue)}
                        className={ModalsClassNames.UsedIn}
                    />
                )}

                {isArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? archiveCustomerJourneys : archiveCustomerJourney}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowArchiveCustomerJourneys) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.archiveCustomerJourney.key)}
                        isVisibleModal={isArchiveModalOpenedState}
                        titleText={isBulkAction() ? t(l.ArchiveSelectedCustomerJourneys) : t(l.ArchiveCustomerJourney)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Archive}
                        actionLabel={t(l.Archive)}
                        className={ModalsClassNames.Archive}
                        allowDataList={openedModalData.allowArchiveCustomerJourneys}
                        notAllowDataList={openedModalData.notAllowArchiveCustomerJourneys}
                        allowDataLabel={l.AllowArchiveSelectedCustomerJourneys}
                        notAllowDataLabel={l.NotAllowArchiveSelectedCustomerJourneys}
                        questionLabel={
                            isBulkAction()
                                ? l.ArchiveSelectedCustomerJourneysQuestion
                                : t(l.AreYouSureYouWantToArchiveCustomerJourney, {
                                      workflowName: `"${openedModalData.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isUnArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? unarchiveCustomerJourneys : unarchiveCustomerJourney}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowUnarchiveCustomerJourneys) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.unarchiveCustomerJourney.key)}
                        isVisibleModal={isUnArchiveModalOpenedState}
                        titleText={
                            isBulkAction() ? t(l.UnarchiveSelectedCustomerJourneys) : t(l.UnarchiveCustomerJourney)
                        }
                        isLoading={isLoading}
                        iconType={ActionsIcons.UnArchive}
                        actionLabel={t(l.Unarchive)}
                        className={ModalsClassNames.UnArchive}
                        allowDataList={openedModalData.allowUnarchiveCustomerJourneys}
                        notAllowDataList={openedModalData.notAllowUnarchiveCustomerJourneys}
                        allowDataLabel={l.AllowUnarchiveSelectedCustomerJourneys}
                        notAllowDataLabel={l.NotAllowUnarchiveSelectedCustomerJourneys}
                        questionLabel={
                            isBulkAction()
                                ? l.UnarchiveSelectedCustomerJourneysQuestion
                                : t(l.AreYouSureYouWantToUnarchiveCustomerJourney, {
                                      workflowName: `"${openedModalData.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isStopSendModalOpenedState && (
                    <ConfirmationModal
                        onOk={stopSendCustomerJourney}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.stopSendCustomerJourney.key)}
                        isVisibleModal={isStopSendModalOpenedState}
                        titleText={t(l.StopCustomerJourney)}
                        questionLabel={t(l.AreYouSureYouWantToStopCustomerJourney, {
                            workflowName: `"${openedModalData.Name}"`,
                        })}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Stop}
                        actionLabel={t(l.Stop)}
                        className={ModalsClassNames.Stop}
                    />
                )}
                {isStartCustomerJourneyModalOpenedState && (
                    <ConfirmationModalWithWarning
                        onOk={startCustomerJourney}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.startCustomerJourney.key)}
                        titleText={t(l.StartCustomerJourney)}
                        questionLabel={t(l.AreYouSureYouWantToStartCustomerJourney, {
                            workflowName: `"${openedModalData.Name}"`,
                        })}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Start}
                        actionLabel={t(l.Start)}
                        cancelLabel={t(l.Cancel)}
                        className={ModalsClassNames.Start}
                        promotionType={openedModalData.PromotionType}
                    />
                )}
                {isResumeModalOpenedState && (
                    <ConfirmationModalWithWarning
                        onOk={resumeCustomerJourney}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.resumeCustomerJourney.key)}
                        titleText={t(l.ResumeCustomerJourney)}
                        questionLabel={t(l.AreYouSureYouWantToResumeCustomerJourney, {
                            workflowName: `"${openedModalData.Name}"`,
                        })}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Resume}
                        actionLabel={t(l.Resume)}
                        cancelLabel={t(l.Cancel)}
                        className={ModalsClassNames.Start}
                        promotionType={openedModalData.PromotionType}
                    />
                )}
                {isInfoModalOpenedState && (
                    <CustomerJourneyInfoModal
                        workflowId={openedModalData.WorkflowId}
                        promotionType={openedModalData.PromotionType}
                        handleClose={() => onCloseModalHandler(modalsStateSetDict.infoCustomerJourney.key)}
                        isVisible={isInfoModalOpenedState}
                        isLoading={isLoading}
                        hasVisualization={openedModalData.HasVisualization}
                    />
                )}
            </>
        )
    );
};

CustomerJourneyActionsModals.propTypes = {
    isDeleteModalOpenedState: PropTypes.bool.isRequired,
    isUsedInConfirmationModalOpenedState: PropTypes.bool.isRequired,
    isArchiveModalOpenedState: PropTypes.bool.isRequired,
    isUnArchiveModalOpenedState: PropTypes.bool.isRequired,
    isStopSendModalOpenedState: PropTypes.bool.isRequired,
    isStartCustomerJourneyModalOpenedState: PropTypes.bool.isRequired,
    isResumeModalOpenedState: PropTypes.bool.isRequired,
    isInfoModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    goToEditCustomerJourney: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.any.isRequired,
};

export default memo(CustomerJourneyActionsModals);
