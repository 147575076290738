import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
// Import Components
import { TemplateInfoModal } from 'components';
// Import UI components
import { Tooltip } from '@geneui/components';

const TemplateId = ({ templateId, templateName }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onClickButtonHandler = () => {
        setIsModalVisible(() => true);
    };

    const onCloseModalHandler = () => {
        setIsModalVisible(() => false);
    };

    return (
        <>
            <Tooltip text={templateName} position="bottom">
                <button className="tittle-text ellipsis-text" onClick={onClickButtonHandler}>
                    {templateName}
                </button>
            </Tooltip>
            {isModalVisible && (
                <TemplateInfoModal
                    templateId={templateId}
                    isVisible={isModalVisible}
                    handleClose={onCloseModalHandler}
                />
            )}
        </>
    );
};

TemplateId.propTypes = {
    templateId: PropTypes.number.isRequired,
    templateName: PropTypes.string.isRequired,
};

export default memo(TemplateId);
