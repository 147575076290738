import React, { memo, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { CampaignHttpService } from 'services/http';
// Import Components
import { PlayersDataTable } from 'components';
// Import Constants
import { CampaignActivityReceivedPlayersTableConfig } from './config';

const CampaignActivityMetricsReceivedPlayers = ({
    campaignId,
    startDate,
    endDate,
    setIsDisableDownload,
    isLoadingSchedule,
    isActive,
}) => {
    const { doPostRequest } = useRequest();
    const [isLoadingReceivedPlayers, setIsLoadingReceivedPlayers] = useState(true);
    const [isRequestBodyChanged, setIsRequestBodyChanged] = useState(false);
    const [receivedPlayers, setReceivedPlayers] = useState({});

    const { getCampaignEmailsReportRequest } = useMemo(
        () => ({
            getCampaignEmailsReportRequest: CampaignHttpService.getCampaignEmailsReport(),
        }),
        [],
    );

    useEffect(() => {
        if (!isLoadingSchedule && isActive && isRequestBodyChanged) {
            setIsLoadingReceivedPlayers(true);
            setIsRequestBodyChanged(false);

            doPostRequest(getCampaignEmailsReportRequest.request, {
                requestBody: { CampaignId: campaignId, StartDate: startDate, EndDate: endDate },
                successCallback: (Data) => {
                    setReceivedPlayers(Data);
                    setIsDisableDownload(isNil(Data?.Count) || Data?.Count === 0);
                },
            }).then(() => {
                setIsLoadingReceivedPlayers(false);
            });
        }
    }, [
        isLoadingSchedule,
        isActive,
        isRequestBodyChanged,
        campaignId,
        startDate,
        endDate,
        setIsDisableDownload,
        getCampaignEmailsReportRequest,
        doPostRequest,
    ]);

    useEffect(() => {
        setIsRequestBodyChanged(true);
    }, [campaignId, startDate, endDate]);

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getCampaignEmailsReportRequest.cancel(
                'CampaignActivityMetricsReceivedPlayers:getCampaignEmailsReportRequest',
            );
        };
    };

    useEffect(cleanUp, []);

    return (
        <PlayersDataTable
            key="ClientId"
            config={CampaignActivityReceivedPlayersTableConfig}
            isLoading={isLoadingReceivedPlayers}
            data={receivedPlayers}
        />
    );
};

CampaignActivityMetricsReceivedPlayers.propTypes = {
    campaignId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    setIsDisableDownload: PropTypes.func.isRequired,
    isLoadingSchedule: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
};

CampaignActivityMetricsReceivedPlayers.defaultProps = {
    isActive: true,
};

export default memo(CampaignActivityMetricsReceivedPlayers);
