import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { first, isEmpty, isNil, noop } from 'lodash';
// Import UI Components
import { BusyLoader, Modal, Popover, Tooltip } from '@geneui/components';
// Import Hooks
import { useRequest, useOnClickOutside } from 'hooks';
// Import Services
import { TemplateHttpService } from 'services/http';
// Import Constants
import { l } from 'constants/common';
// Import SCSS
import 'assets/scss/htmlCodeEditor.scss';

const iframeBaseTarget = '<base target="_blank">';

const HtmlCodeEditor = ({ onChange, onBlur, onFocus, value, placeholder, updateInsertionHandler }) => {
    const { t } = useTranslation();

    const [editorContent, setEditorContent] = useState(value || '');
    const [moveCursorToPosition, setMoveCursorToPosition] = useState(null);
    const [isGalleryPopoverVisible, setIsGalleryPopoverVisible] = useState(false);
    const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
    const [isGalleryListLoading, setIsGalleryListLoading] = useState(false);
    const [isBrowsePopoverOpen, setIsBrowsePopoverOpen] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [iframeContent, setIframeContent] = useState(editorContent);

    const hiddenFileInput = useRef(null);
    const galleryPopoverRef = useRef(null);
    const buttonRef = useRef(null);
    const intervalToken = useRef(null);
    const textareaRef = useRef(null);

    const { doGetRequest, doPostRequest } = useRequest();

    const { addAssetRequest, getAssetsRequest } = useMemo(
        () => ({
            addAssetRequest: TemplateHttpService.addAsset(),
            getAssetsRequest: TemplateHttpService.getAssets(),
        }),
        [],
    );

    useEffect(() => {
        setEditorContent(value);
        setIframeContent(value);
    }, [value]);

    const handleChange = (e) => {
        const newContent = e.target.value;
        setEditorContent(newContent);
        if (onChange) {
            onChange(newContent);
        }
        clearTimeout(intervalToken.current);
        if (isEmpty(newContent)) {
            setIframeContent(newContent);
        } else {
            intervalToken.current = setTimeout(() => {
                setIframeContent(newContent);
            }, 600);
        }
    };

    const handleBlur = () => {
        if (onBlur) {
            onBlur(editorContent);
        }
    };

    useEffect(() => {
        if (!isNil(moveCursorToPosition)) {
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(moveCursorToPosition, moveCursorToPosition);
            setMoveCursorToPosition(null);
        }
    }, [moveCursorToPosition]);

    const insertTextAtCursor = (textToInsert) => {
        const textarea = textareaRef.current;
        if (!textarea) return;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = `${editorContent.substring(0, start)} {${textToInsert}} ${editorContent.substring(end)}`;
        setEditorContent(newText.trim());
        setIframeContent(newText.trim());
        setMoveCursorToPosition(start + textToInsert.length + 3);

        if (onChange) {
            onChange(newText.trim());
        }
    };

    updateInsertionHandler(insertTextAtCursor);

    const insertImageHtml = (img) => {
        if (!img) return;
        const imgTag = `<img src=${img} alt='image' />`;
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = `${editorContent.substring(0, start)}${imgTag}${editorContent.substring(end)}`;
        setEditorContent(newText);
        setIframeContent(newText);
        onChange(newText);
        onBlur(newText);
    };

    const imageUploadClickHandler = () => {
        hiddenFileInput.current.click();
    };

    const uploadInputChangeHandler = (event) => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    };

    const handleFile = (file) => {
        setIsImageUploadLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        doPostRequest(addAssetRequest.request, {
            requestBody: formData,
            successCallback: (Data) => {
                insertImageHtml(first(Data).Url);
            },
        }).then(() => {
            setIsGalleryPopoverVisible(false);
            setIsImageUploadLoading(false);
        });
    };

    const browseImageHandler = () => {
        setIsGalleryPopoverVisible(false);
        setIsBrowsePopoverOpen(true);
        setIsGalleryListLoading(true);
        doGetRequest(getAssetsRequest.request, {
            successCallback: (data) => {
                setGalleryImages(data);
            },
        }).then(() => {
            setIsGalleryListLoading(false);
        });
    };

    const galleryButtonClickHandler = () => {
        setIsGalleryPopoverVisible((prev) => !prev);
    };

    const handleImageSelect = (e, imgUrl) => {
        e.preventDefault();
        setIsBrowsePopoverOpen(false);
        insertImageHtml(imgUrl);
    };

    useOnClickOutside(
        [galleryPopoverRef, buttonRef],
        useCallback(() => {
            if (isGalleryPopoverVisible) {
                setIsGalleryPopoverVisible(false);
            }
        }, [isGalleryPopoverVisible]),
    );

    return (
        <div className="html-editor-container">
            <div className="code-editor-container">
                <div className="code-editor-header">
                    <Popover
                        className="crm-gallery-popover-container"
                        isOpen={isGalleryPopoverVisible}
                        align="end"
                        extendTargetWidth={false}
                        Content={
                            <div className="crm-gallery-popover-content-container" ref={galleryPopoverRef}>
                                <BusyLoader isBusy={isImageUploadLoading}>
                                    <button onClick={imageUploadClickHandler}>
                                        <i className="icon bc-icon-upload"></i>
                                        <p> {t(l.Upload)}</p>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            style={{ display: 'none' }}
                                            ref={hiddenFileInput}
                                            onChange={uploadInputChangeHandler}
                                            accept="image/png, image/jpeg"
                                        />
                                    </button>

                                    <button onClick={browseImageHandler}>
                                        <i className="icon bc-icon-folder-closed"></i>
                                        <p> Browse </p>
                                    </button>
                                </BusyLoader>
                            </div>
                        }
                    >
                        <button onClick={galleryButtonClickHandler} ref={buttonRef}>
                            <div className="action-gallery">
                                <Tooltip text="Insert Image">
                                    <i className="icon bc-icon-Image"></i>
                                </Tooltip>
                            </div>
                        </button>
                    </Popover>
                </div>

                <textarea
                    ref={textareaRef}
                    className="code-editor-textarea"
                    value={editorContent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={onFocus}
                    rows="18"
                    cols="50"
                    placeholder={placeholder}
                />
            </div>
            <div className="editor-iframe-preview">
                <div className="editor-iframe-preview-header">
                    <p>Html Preview</p>
                </div>
                <iframe id={1} title={'iframe'} srcDoc={`${iframeBaseTarget} ${iframeContent}`} />
            </div>
            <Modal
                closeOnClickOutside={true}
                size="content-size"
                visible={isBrowsePopoverOpen}
                onCancel={() => setIsBrowsePopoverOpen(false)}
                background="dark-background"
                position="center"
                className="gallery-popover-modal"
                disableFooter={true}
                title={t(l.ImageGalleryModalTitle)}
            >
                <BusyLoader isBusy={isGalleryListLoading}>
                    <div className="gallery-popover-container">
                        <div className="gallery-popover-images-container">
                            {galleryImages.map((elem, i) => {
                                return (
                                    <a key={i} href={elem.Url} onClick={(e) => handleImageSelect(e, elem.Url)}>
                                        <img
                                            src={`${elem.Url}?method=cover&width=150&height=150&`}
                                            alt="gallery thumb"
                                            height="150px"
                                        />
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </BusyLoader>
            </Modal>
        </div>
    );
};

HtmlCodeEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    updateInsertionHandler: PropTypes.func,
};

HtmlCodeEditor.defaultProps = {
    onChange: noop,
};

export default HtmlCodeEditor;
