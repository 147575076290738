import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { values } from 'lodash';
//Import UI components
import { RadioGroup, Icon } from '@geneui/components';
//Import constants
import { DeviceTypes, DeviceTabInfos } from 'constants/common';

const DeviceTabs = ({ activeTab, setActiveTab }) => {
    const { t } = useTranslation();

    return (
        <div className="wrap-for-device">
            <RadioGroup
                name="controlled"
                options={values(DeviceTabInfos).map((item) => {
                    return {
                        value: item.type,
                        label: (
                            <div className="controlled-icon-block">
                                <Icon type={item.iconType} />
                                {t(item.titleKey)}
                            </div>
                        ),
                    };
                })}
                type="tab"
                value={activeTab}
                onChange={(e) => setActiveTab(e.target.value)}
            />
        </div>
    );
};

DeviceTabs.propTypes = {
    setActiveTab: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
};

DeviceTabs.defaultProps = {
    activeTab: DeviceTypes.FULL_SCREEN,
};

export default memo(DeviceTabs);
