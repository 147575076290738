import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
// Import Components
import CampaignBonusSummaryByCurrency from './CampaignBonusSummaryByCurrency';
import CampaignBonusReceivedPlayers from './CampaignBonusReceivedPlayers';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { CampaignHttpService } from 'services/http';
// Import Constants
import { CampaignType } from 'constants/common';

const CampaignBonus = ({ campaignId, startDate, endDate, isLoadingSchedule, campaignType, isActive }) => {
    const { doPostRequest, doGetRequest } = useRequest();
    const { showToaster } = useToaster();
    const [isLoadingBonusReceivedPlayers, setIsLoadingBonusReceivedPlayers] = useState(true);
    const [isLoadingBonusSummaryByCurrency, setIsLoadingBonusSummaryByCurrency] = useState(true);
    const [isRequestBodyChanged, setIsRequestBodyChanged] = useState(false);
    const [bonusReceivedPlayers, setBonusReceivedPlayers] = useState({});
    const [bonusSummaryByCurrency, setBonusSummaryByCurrency] = useState([]);
    const [isFileLoading, setIsFileLoading] = useState(false);

    const { getCampaignBonusesReportRequest, getCampaignBonusInfoRequest, downloadBonusReportRequest } = useMemo(
        () => ({
            getCampaignBonusesReportRequest: CampaignHttpService.getCampaignBonusesReport(),
            getCampaignBonusInfoRequest: CampaignHttpService.getCampaignBonusInfo(),
            downloadBonusReportRequest: CampaignHttpService.downloadBonusReport(),
        }),
        [],
    );

    useEffect(() => {
        if (!isLoadingSchedule && isActive && isRequestBodyChanged) {
            setIsRequestBodyChanged(false);

            setIsLoadingBonusReceivedPlayers(true);
            doPostRequest(getCampaignBonusesReportRequest.request, {
                requestBody: {
                    CampaignId: campaignId,
                    StartDate: startDate,
                    EndDate: endDate,
                    CampaignType: campaignType,
                },
                successCallback: (Data) => {
                    setBonusReceivedPlayers(Data);
                },
            }).then(() => {
                setIsLoadingBonusReceivedPlayers(false);
            });

            setIsLoadingBonusSummaryByCurrency(true);
            doGetRequest(getCampaignBonusInfoRequest.request, {
                queryString: { campaignId, startDate, endDate, campaignType },
                successCallback: (Data) => {
                    setBonusSummaryByCurrency(isNil(Data?.Data) ? [] : Data.Data);
                },
            }).then(() => {
                setIsLoadingBonusSummaryByCurrency(false);
            });
        }
    }, [
        campaignId,
        startDate,
        endDate,
        isLoadingSchedule,
        isActive,
        isRequestBodyChanged,
        doPostRequest,
        getCampaignBonusesReportRequest,
        campaignType,
        doGetRequest,
        getCampaignBonusInfoRequest,
    ]);

    useEffect(() => {
        setIsRequestBodyChanged(true);
    }, [campaignId, startDate, endDate]);

    const getRequestBody = () => {
        setIsFileLoading(true);
        return {
            CampaignId: campaignId,
            StartDate: startDate,
            EndDate: endDate,
            ReportType: 0,
            CampaignType: campaignType,
        };
    };

    const downloadAllOrSendEmailClickHandler = (isDownload) => {
        const requestBody = getRequestBody();
        return doPostRequest(downloadBonusReportRequest.request, {
            requestBody,
            queryString: { isDownload },
            successCallback: (data) => {
                isDownload && window.open(data?.Url);
            },
        }).then(({ AlertType, AlertMessage }) => {
            showToaster(AlertType, AlertMessage);
            setIsFileLoading(false);
        });
    };

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getCampaignBonusesReportRequest.cancel('CampaignBonus:getCampaignBonusesReportRequest');
            getCampaignBonusInfoRequest.cancel('CampaignBonus:getCampaignBonusInfoRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <div className="campaign-bonus-wrap">
            <div className="campaign-bonus-wrap-inner">
                <CampaignBonusSummaryByCurrency
                    isLoading={isLoadingBonusSummaryByCurrency}
                    data={bonusSummaryByCurrency}
                />
                <CampaignBonusReceivedPlayers
                    isLoading={isLoadingBonusReceivedPlayers}
                    data={bonusReceivedPlayers}
                    downloadAllClickHandler={() => downloadAllOrSendEmailClickHandler(true)}
                    sendToEmailClickHandler={() => downloadAllOrSendEmailClickHandler(false)}
                    isFileLoading={isFileLoading}
                />
            </div>
        </div>
    );
};

CampaignBonus.propTypes = {
    campaignId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    isLoadingSchedule: PropTypes.bool.isRequired,
    campaignType: PropTypes.string,
    isActive: PropTypes.bool,
};

CampaignBonus.defaultProps = {
    campaignType: CampaignType.AddHoc,
    isActive: true,
};

export default memo(CampaignBonus);
