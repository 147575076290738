import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Import UI components
import { Button } from '@geneui/components';

const ButtonWithLoader = ({ isLoading, defaultClassNames, isDisabled, children, icon, ...rest }) => {
    return (
        <Button
            className={classNames(defaultClassNames, { 'crm-modal-action-btn': isLoading })}
            icon={isLoading ? 'bc-icon-loader' : icon}
            disabled={isLoading || isDisabled}
            {...rest}
        >
            {children}
        </Button>
    );
};

ButtonWithLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    defaultClassNames: PropTypes.string,
    icon: PropTypes.string,
    isDisabled: PropTypes.bool,
};

ButtonWithLoader.defaultProps = {
    defaultClassNames: '',
    icon: '',
    isDisabled: false,
};

export default memo(ButtonWithLoader);
