import React, { memo, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import Services
import { getModalConfig } from './service';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Constants
import { l } from 'constants/common';

const CJMenuItemDropDownInfoBlock = ({ data, customAttributes, option }) => {
    const { t } = useTranslation();

    const {
        data: menuItemData,
        config,
        restore,
        isLoadingConfig,
        isLoadingRestore,
    } = useMenuItem(customAttributes.MenuItemId, false);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalData, setModalData] = useState(() => ({}));

    useEffect(() => {
        restore([data?.value]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, data.value]);
    const MenuItemData = menuItemData?.find((item) => +item.Id === +data?.value);

    const openModal = useCallback((item) => {
        setModalData(item);
        setIsVisibleModal(true);
    }, []);

    const { isClickable, render } = useMemo(() => {
        const config = getModalConfig(option?.BaseTypes);
        return { isClickable: !isEmpty(config), render: config?.render };
    }, [option]);

    return (
        <>
            <CJInfoBlockLabelValueWrapper
                label={t(data?.label ?? l.OptionValue)}
                value={MenuItemData?.Name}
                isLoading={isLoadingConfig || isLoadingRestore}
                onClick={() => openModal(MenuItemData)}
                isClickable={isClickable}
            />

            {isVisibleModal && isClickable && render(modalData, () => setIsVisibleModal(false))}
        </>
    );
};

CJMenuItemDropDownInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(CJMenuItemDropDownInfoBlock);
