import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
// Import Components
import BonusInfoModalBody from './BonusInfoModalBody';
// Import UI Components
import { Modal, Button, Empty } from '@geneui/components';
// Import Constants
import { ModalsActions, l } from 'constants/common';
//Import SCSS
import 'assets/scss/segmentModal.scss';

const BonusModal = ({ handleClose, isVisible, fromCampaignInfo, bonusInfo, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            {fromCampaignInfo ? (
                bonusInfo ? (
                    <BonusInfoModalBody {...rest} />
                ) : (
                    <Empty
                        appearance="greyscale"
                        withImage={true}
                        title={t(l.NoDataFound)}
                        subTitle={t(l.BonusIsNotExist)}
                    />
                )
            ) : (
                <Modal
                    withPortal={true}
                    closeOnClickOutside={true}
                    background="dark-background"
                    position="center"
                    visible={isVisible}
                    closable={false}
                    onCancel={() => handleClose(ModalsActions.CLOSE)}
                    className="segment-list-modal"
                    title={
                        <div className="segment-list-modal-head">
                            <span className="segment-list-modal-h-text">{t(l.BonusInfo)}</span>
                            <div className="segment-list-modal-h-icons"></div>
                        </div>
                    }
                    footer={
                        <Button color="primary" onClick={() => handleClose(ModalsActions.CLOSE)}>
                            {t(l.Close)}
                        </Button>
                    }
                >
                    {isVisible && <BonusInfoModalBody {...rest} />}
                </Modal>
            )}
        </>
    );
};

BonusModal.propTypes = {
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func,
    fromCampaignInfo: PropTypes.bool,
    bonusInfo: PropTypes.object,
};

BonusModal.defaultProps = {
    handleClose: noop,
    isVisible: false,
    fromCampaignInfo: false,
    bonusInfo: null,
};

export default memo(BonusModal);
