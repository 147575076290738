import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isObject, mapKeys, cloneDeep, isEmpty, has } from 'lodash';
// Import Actions
import { HeaderAction } from 'actions';
// Import Constants
import { NavigationList } from 'components/Header/constants';

const useGoToRoute = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { url } = useSelector((state) => state.header.navigation);
    let navigationList = cloneDeep(NavigationList);

    const { setNavigation } = HeaderAction;

    const getRoutePath = useCallback(
        (route, routeParams, queryString) => {
            let { path } = route;
            if (isObject(routeParams)) {
                mapKeys(routeParams, (value, param) => {
                    path = path.replace(`:${param}`, value);
                });
            }
            return !isEmpty(queryString) ? `${url}/${path}${queryString}` : `${url}/${path}`;
        },
        [url],
    );

    const goToRoute = useCallback(
        (route, routeParams, queryString) => {
            history.push(getRoutePath(route, routeParams, queryString), { ...route });
        },
        [getRoutePath, history],
    );

    const goToPreviousRoute = () => {
        history.goBack();
    };

    const buildRouteObject = (location) => {
        let route = {};
        if (has(location, 'pathname') && !isEmpty(location.pathname)) {
            route.path = location.pathname.replace('/crm/', '');
        }
        if (has(location, 'search') && !isEmpty(location.search)) {
            route.queryString = location.search;
        }
        return route;
    };

    const findActiveNavigation = (pathName) => {
        let result = {};
        const entityPathname = pathName.split('/')[2];
        navigationList.forEach((item) => {
            if (item.routeModel.path.includes(entityPathname)) {
                let { name, path } = item.routeModel;
                result = {
                    name: t(name),
                    route: path,
                };
            }
        });
        return result;
    };

    const listen = () => {
        const foundActiveNavigation = findActiveNavigation(pathname);
        dispatch(setNavigation(foundActiveNavigation));

        history.listen((location, _action) => {
            const foundActiveNavigation = findActiveNavigation(location.pathname);
            dispatch(setNavigation(foundActiveNavigation));
        });
    };

    return { goToRoute, goToPreviousRoute, listen, buildRouteObject, getRoutePath };
};

export default useGoToRoute;
