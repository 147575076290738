import React, { memo, useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cloneDeep, first, has, isArray, isEmpty, isNil, isObject, noop } from 'lodash';
import { useFormik } from 'formik';
// Import UI Components
import { Modal, Button, Icon, BusyLoader, Tag } from '@geneui/components';
// Import Components
import {
    Scheduler,
    ButtonWithLoader,
    RecipientSelectors,
    RandomComponentWithHeadline,
    ValidatableDropdown,
} from 'components';
// Import Constants
import {
    ScheduleModalViews,
    l,
    MonthDayYearFormat,
    TimeFormatWithoutSeconds,
    ExportFileFormat,
    DateToISOStringFormat,
    ExportFileFormatLabels,
} from 'constants/common';
import { DefaultDatesDict } from './config';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { ReportHttpService, UtilsHttpService } from 'services/http';
import { Helpers } from 'services';
import moment from 'moment';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
import { resultScheduleDataCreator } from 'services/schedular';
// Import Validation
import { ReportScheduleValidationSchema } from 'validators/schemas.validation';

const { mainView, dailyView, weeklyView, monthlyView, annuallyView, notRepeatView } = ScheduleModalViews;
const { cronStringDecoder, getDateWithTime, customMoment, customMomentWithoutTimezoneConversion, getPlusOneDayDate } =
    Helpers;

const ScheduleReportModal = ({ isVisibleModal, onCancel, onOk, reportId, reportType, isLoading }) => {
    const { settings } = useSelector((state) => state.header);
    const { ActivePartner } = settings;
    const { t } = useTranslation();
    const { doGetRequest, doPostRequest } = useRequest();

    const formik = useFormik({
        initialValues: {
            startDate: customMoment().format(MonthDayYearFormat),
            endDate: customMoment(getPlusOneDayDate()).format(MonthDayYearFormat),
            startTime: customMoment().format(TimeFormatWithoutSeconds),
            datesDict: cloneDeep(DefaultDatesDict),
            selectedDateSelector: notRepeatView,
            modalView: notRepeatView,
            timeZone: ActivePartner.TimeZone,
            selectedFileFormats: [],
            selectedEmails: [],
            selectedFtps: [],
        },
        onSubmit: noop,
        validationSchema: ReportScheduleValidationSchema(),
    });

    const { values, setFieldValue, submitForm, errors, setValues } = formik;
    const {
        startDate,
        endDate,
        startTime,
        datesDict,
        selectedDateSelector,
        modalView,
        timeZone,
        selectedEmails,
        selectedFtps,
        selectedFileFormats,
    } = values;

    const [emails, setEmails] = useState([]);
    const [ftpProviders, setFtpProviders] = useState([]);
    const [isVisibleRecCount, setIsVisibleRecCount] = useState(false);
    const [recurrenceCount, setRecurrenceCount] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedFileFormatsValues, setSelectedFileFormatsValues] = useState([]);

    useEffect(() => {
        setSelectedFileFormatsValues(
            selectedFileFormats?.map((value) => ({
                label: ExportFileFormatLabels[value],
                value: value,
            })),
        );
    }, [selectedFileFormats]);

    useEffect(() => {
        submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteChosenFormat = (e) => {
        const filteredSelectedValues = selectedFileFormats.filter((value) => +value !== +e);
        setFieldValueBuilder('selectedFileFormats', filteredSelectedValues);
    };

    const init = () => {
        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            endDate,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        setIsVisibleRecCount(true);
    };

    const startDateChangeHandler = (value) => {
        let fieldsValues = { ...values, startDate: value };
        if (selectedDateSelector === notRepeatView) {
            fieldsValues.endDate = value;
        }
        setValues(fieldsValues, true);
        const Schedule = resultScheduleDataCreator(
            startTime,
            value,
            fieldsValues.endDate,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
    };

    const setFieldValueBuilder = (filedKey, value) => {
        setFieldValue(filedKey, value, true);
    };

    const startTimeChangeHandler = (e) => {
        const { value } = e.target;
        setFieldValueBuilder('startTime', value);
    };

    const endDateChangeHandler = (value) => {
        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            value,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        setFieldValueBuilder('endDate', value);
    };

    const saveHandler = () => {
        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            endDate,
            selectedDateSelector,
            datesDict,
            timeZone,
        );

        let userIds = selectedEmails.map((item) => item.value);

        let ftpIds = selectedFtps.map((item) => item.value);

        let Providers = [
            { Provider: 0, Recepient: userIds, ExportTypes: selectedFileFormats },
            { Provider: 5, Recepient: ftpIds, ExportTypes: selectedFileFormats },
        ];

        onOk({ Providers: Providers, Schedule: Schedule, ReportId: reportId, Type: reportType });
    };

    const getRecCount = (cron, startDate, endDate, timeZone) => {
        if (!isNil(cron)) {
            doPostRequest(getScheduleRunDates.request, {
                requestBody: { cronExpression: cron, startDate: startDate, endDate: endDate, timeZone: timeZone },
                successCallback: (Data) => setRecurrenceCount(Data.Count),
            });
        }
    };

    const reset = () => {
        let dict = {
            dailyView: datesDict[dailyView],
            weeklyView: datesDict[weeklyView],
            monthlyView: datesDict[monthlyView],
            annuallyView: datesDict[annuallyView],
        };

        dict[modalView] = {
            days: [],
            weeks: [],
            months: [],
            years: [],
            day: '',
            month: '',
            year: '',
            isActivePicker: false,
        };
        setFieldValueBuilder('datesDict', datesDict);
        setFieldValueBuilder('startDate', customMoment().format(MonthDayYearFormat));
        setFieldValueBuilder('endDate', customMoment().format(MonthDayYearFormat));
        setFieldValueBuilder('startTime', customMoment().format(TimeFormatWithoutSeconds));
        setFieldValueBuilder('timeZone', ActivePartner.TimeZone);
        setFieldValueBuilder('selectedDateSelector', notRepeatView);
        setFieldValueBuilder('modalView', mainView);
        setFieldValueBuilder('selectedFtps', []);
        setFieldValueBuilder('selectedEmails', []);
        setFieldValueBuilder('selectedFileFormats', []);
    };

    const setDictData = (data, viewType) => {
        let obj = { ...datesDict };
        obj[viewType] = data;
        setFieldValue('datesDict', obj, true);
    };

    const { getPartnerProviders, getReportSchedule, getScheduleRunDates } = useRef({
        getReportSchedule: ReportHttpService.getReportSchedule(),
        getPartnerProviders: UtilsHttpService.getPartnerProviders(),
        getScheduleRunDates: UtilsHttpService.getScheduleRunDates(),
    }).current;

    const getProviders = () => {
        doGetRequest(getPartnerProviders.request, {
            successCallback: (Data) => {
                let emailList = [];
                let ftpProvidersList = [];

                Data.forEach(({ Type, Name, Id }) => {
                    Type === 0 && emailList.push({ label: Name, value: Id });
                    (Type === 5 || Type === 6) && ftpProvidersList.push({ label: Name, value: Id });
                });

                setEmails(emailList);
                setFtpProviders(ftpProvidersList);
                getSchedule(emailList, ftpProvidersList);
            },
        });
    };

    const getSchedule = (emails, ftps) => {
        doGetRequest(getReportSchedule.request, {
            queryString: { reportId: reportId, type: reportType },
            successCallback: (Data) => {
                let scheduleData = cronStringDecoder();

                let startDate = customMoment();
                let endDate = customMoment();
                let startTime = customMoment().format(TimeFormatWithoutSeconds);
                const { Schedule, Providers } = Data;
                if (isArray(Providers)) {
                    const emailRecipients = first(Providers);
                    setFieldValueBuilder('selectedFileFormats', first(Providers)?.ExportTypes);

                    if (
                        isObject(emailRecipients) &&
                        has(emailRecipients, 'Recepient') &&
                        isArray(emailRecipients.Recepient)
                    ) {
                        let selectedEmailsResult = [];

                        if (!isEmpty(emails)) {
                            emailRecipients.Recepient.forEach((recepientId, index) => {
                                selectedEmailsResult[index] = first(
                                    emails.filter((email) => {
                                        return email.value === recepientId;
                                    }),
                                );
                            });
                        }
                        setFieldValueBuilder('selectedEmails', selectedEmailsResult);
                        setFieldValueBuilder('selectedFileFormats', emailRecipients.ExportTypes);
                    }
                    let ftpRecepients = Providers[1].Recepient;
                    if (!isNil(Providers[2])) {
                        ftpRecepients = ftpRecepients.concat(Providers[2].Recepient);
                    }

                    if (isObject(ftpRecepients)) {
                        let selectedFtpssResult = [];
                        if (!isEmpty(ftps)) {
                            ftpRecepients.forEach((recepientId, index) => {
                                selectedFtpssResult[index] = first(
                                    ftps.filter((ftp) => {
                                        return ftp.value === recepientId;
                                    }),
                                );
                            });
                        }
                        setFieldValueBuilder('selectedFtps', selectedFtpssResult);
                    }
                }

                if (!isNil(Schedule)) {
                    const { StartDate, EndDate, CronTime, CronSchedule, TimeZone } = Schedule;
                    setFieldValueBuilder('timeZone', TimeZone);
                    if (isEmpty(CronTime)) {
                        startTime = customMomentWithoutTimezoneConversion(StartDate).format(TimeFormatWithoutSeconds);
                    } else {
                        setIsVisibleRecCount(true);
                        scheduleData = cronStringDecoder(CronSchedule);
                        startTime = getDateWithTime(customMoment(), CronTime.split(':')).format(
                            TimeFormatWithoutSeconds,
                        );
                        setFieldValueBuilder('selectedDateSelector', scheduleData.viewType);
                        getRecCount(CronSchedule, StartDate, EndDate, TimeZone);
                    }
                    startDate = moment(StartDate, DateToISOStringFormat).format(MonthDayYearFormat);
                    endDate = moment(EndDate, DateToISOStringFormat).format(MonthDayYearFormat);
                }
                setDictData(scheduleData, scheduleData.viewType);
                setFieldValue('startDate', startDate, true);
                setFieldValue('endDate', endDate, true);
                setFieldValue('startTime', startTime, true);
            },
        }).then(() => setIsLoaded(true));
    };

    const calculateRecCount = useCallback(() => {
        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            endDate,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datesDict, endDate, selectedDateSelector, startDate, startTime, timeZone]);

    useEffect(() => {
        calculateRecCount();
    }, [calculateRecCount, modalView]);

    useEffect(getProviders, [isVisibleModal]);
    useEffect(init, []);

    //use ModalFooter component from labels modal
    const getFooter = () => {
        return (
            <>
                <Button appearance="minimal" onClick={onCancel}>
                    {t(l.Close)}
                </Button>
                <Button appearance="minimal" onClick={reset}>
                    {t(l.Reset)}
                </Button>
                <ButtonWithLoader color="primary" onClick={saveHandler} isLoading={isLoading}>
                    {t(l.Save)}
                </ButtonWithLoader>
            </>
        );
    };

    return (
        <Modal
            background="dark-background"
            title={<div className="shedule-title">{t(l.ScheduleReport)}</div>}
            position="center"
            size="content-size"
            closable={false}
            onCancel={onCancel}
            closeOnClickOutside={true}
            visible={isVisibleModal}
            footer={getFooter()}
            className="scheduler-modal-wrap"
        >
            <BusyLoader isBusy={!isLoaded} type="spinner" spinnerSize="big">
                {isLoaded && (
                    <>
                        <div className="providers-wrap">
                            <div className="providers-wrap-inner">
                                <div className="title-wrapper">
                                    <p className="title-wrapper-text">{t(l.ExportFileFormat)}</p>
                                    <span className="title-wrapper-line" />
                                </div>
                                <div className="providers-wrap-row">
                                    <ValidatableDropdown
                                        data={ExportFileFormat}
                                        value={selectedFileFormats}
                                        isMultiSelect={true}
                                        onChange={(values) =>
                                            setFieldValueBuilder(
                                                'selectedFileFormats',
                                                values.map(({ value }) => value),
                                            )
                                        }
                                        errorText={getCustomerJourneyFormFieldTranslatableErrorText(
                                            t,
                                            errors?.selectedFileFormats,
                                        )}
                                        isValid={!errors.selectedFileFormats}
                                    />
                                    <RandomComponentWithHeadline className={'crm-form-single-input-equal'}>
                                        <div className={'crm-multi-select-country-section-body'}>
                                            <div className="crm-multi-select-country-inner">
                                                <p>{t(l.SelectedItems)}</p>
                                                {selectedFileFormatsValues &&
                                                    selectedFileFormatsValues.map(({ label, value }) => (
                                                        <Tag
                                                            name={label}
                                                            key={value}
                                                            appearance="minimal"
                                                            cornerRadius="smooth-radius"
                                                            className={'crm-multi-select-country-section__tag'}
                                                            icons={
                                                                <Icon
                                                                    type={'bc-icon-clear'}
                                                                    onClick={() => deleteChosenFormat(value)}
                                                                />
                                                            }
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                    </RandomComponentWithHeadline>
                                </div>
                            </div>
                        </div>

                        <RecipientSelectors
                            emailRecipientsList={emails}
                            ftpRecipientsList={ftpProviders}
                            setSelectedEmailRecipients={(value) => setFieldValueBuilder('selectedEmails', value)}
                            setSelectedFtpRecipients={(value) => setFieldValueBuilder('selectedFtps', value)}
                            selectedEmailRecipients={selectedEmails}
                            selectedFtpRecipients={selectedFtps}
                        />

                        <Scheduler
                            dictData={datesDict}
                            view={modalView}
                            startDate={startDate}
                            endDate={endDate}
                            startTime={startTime}
                            setView={(value) => setFieldValueBuilder('modalView', value)}
                            setDictData={setDictData}
                            selectedDateSelector={selectedDateSelector}
                            setStartTime={startTimeChangeHandler}
                            setEndDate={endDateChangeHandler}
                            setStartDate={startDateChangeHandler}
                            startDateErrorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.startDate)}
                            endDateErrorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.endDate)}
                            getFooter={getFooter}
                            recurrenceCount={recurrenceCount}
                            isVisibleRecCount={isVisibleRecCount}
                            timeZone={timeZone}
                            setTimeZone={(value) => setFieldValueBuilder('timeZone', value)}
                            viewMode="fullView"
                            setSelectedDateSelector={(value) => setFieldValueBuilder('selectedDateSelector', value)}
                        />
                    </>
                )}
            </BusyLoader>
        </Modal>
    );
};

ScheduleReportModal.propTypes = {
    isVisibleModal: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    reportType: PropTypes.number.isRequired,
    reportId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
};

export default memo(ScheduleReportModal);
