import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Import components
import CampaignGeneralDetails from './CampaignGeneralDetails';
import CampaignActivity from './CampaignActivity';
import CampaignTrackedLinks from './CampaignTrackedLinks';

const CampaignReportDetails = ({
    campaignData,
    templateData,
    segmentData,
    trackLinks,
    schedule,
    bonus,
    bonusesSent,
    sent,
    goal,
    hasGoal,
    campaignId,
    onDateTimeApply,
    recurrenceData,
}) => {
    return (
        <div className="details-page-wrapper">
            <div className="details-page-wrapper-inner">
                <div className="campaign-general-details">
                    <CampaignGeneralDetails
                        campaignData={campaignData}
                        templateData={templateData}
                        segmentData={segmentData}
                        schedule={schedule}
                        bonus={bonus}
                        bonusesSent={bonusesSent}
                        sent={sent}
                        goal={goal}
                        hasGoal={hasGoal}
                        recurrenceData={recurrenceData}
                    />
                </div>
                <div className="campaign-activity">
                    <CampaignActivity
                        campaignId={campaignId}
                        data={campaignData?.Data}
                        name={campaignData?.Name}
                        onDateTimeApply={onDateTimeApply}
                    />
                </div>
                <div className="campaign-tracked-links">
                    <CampaignTrackedLinks data={trackLinks} campaignId={campaignId} />
                </div>
            </div>
        </div>
    );
};

CampaignReportDetails.propTypes = {
    trackLinks: PropTypes.array,
    campaignId: PropTypes.string.isRequired,
    schedule: PropTypes.object.isRequired,
    segmentData: PropTypes.object.isRequired,
    campaignData: PropTypes.object.isRequired,
    templateData: PropTypes.object.isRequired,
    bonus: PropTypes.object.isRequired,
    bonusesSent: PropTypes.object.isRequired,
    sent: PropTypes.object.isRequired,
    goal: PropTypes.object.isRequired,
    hasGoal: PropTypes.bool.isRequired,
    onDateTimeApply: PropTypes.func,
    recurrenceData: PropTypes.object,
};

CampaignReportDetails.defaultProps = {
    trackLinks: [],
};

export default memo(CampaignReportDetails);
