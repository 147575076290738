import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
// Import components
import ReportItemView from './ReportItemView';
// Import constants
import { l } from 'constants/common';

const ReportItem = ({ dropReport, itemData, acceptType, onClear, isValid }) => {
    const { t } = useTranslation();
    const [isHover, setIsHover] = useState(false);

    const [, drop] = useDrop({
        accept: acceptType,
        drop: (item) => {
            dropReport(item.index);
        },
        collect: (monitor) => {
            setIsHover(monitor.isOver());
        },
        canDrop: () => {
            return itemData.enableDropHere;
        },
    });

    return (
        !isNil(itemData) && (
            <div className={`droping-container-row ${isValid ? '' : 'invalid-row'}`} ref={drop}>
                {itemData.enableDropHere ? (
                    <div className={`drop-dash ${isHover ? 'isHoverOn' : 'isNotHover'}`}>{t(l.DropHere)} </div>
                ) : (
                    <ReportItemView
                        type={itemData.type}
                        title={itemData.title?.Name}
                        isClearable={true}
                        onClear={onClear}
                    />
                )}
            </div>
        )
    );
};

ReportItem.propTypes = {
    itemData: PropTypes.object,
    acceptType: PropTypes.string,
    dropReport: PropTypes.func,
    onClear: PropTypes.func,
    isValid: PropTypes.bool,
};

ReportItem.defaultProps = {
    itemData: null,
    acceptType: '',
    dropReport: noop,
    onClear: noop,
    isValid: true,
};

export default memo(ReportItem);
