import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, noop, isEmpty } from 'lodash';
// Import Hooks
import { useToaster, useAutocompleteRequest } from 'hooks';
// Import Components
import { OptionList } from 'components';

const AutocompleteOptionList = ({ data, getRequest, getData, value, onChange, keys, titles, placeholder }) => {
    const [listData, setListData] = useState(data);
    const [searchValue, setSearchValue] = useState('');
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { showToaster } = useToaster();
    const { t } = useTranslation();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const getDataList = (searchValue = '') => {
        setIsLoading(true);
        const requestObj = getAutocompleteRequest(getRequest);

        getData(requestObj, searchValue, showToaster)
            .then((data) => {
                setListData(data);
                setIsLoading(false);
            })
            .catch(({ AlertType, AlertMessage }) => {
                showToaster(AlertType, AlertMessage);
            });
    };

    const handleChange = (data) => {
        onChange(data);
        setIsVisiblePopover(false);
    };

    const onSearch = ({ target }) => {
        setSearchValue(target.value);
        getDataList(target.value);
    };
    const onClear = () => {
        onChange([]);
    };

    const popoverToggler = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    const cleanUp = () => {
        return () => {
            cancelRequest('AutocompleteOptionList:request');
        };
    };

    useEffect(cleanUp, []);

    useEffect(() => {
        getDataList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <OptionList
            optionLabel={!isNil(value) && !isEmpty(data) ? data.find((item) => item?.value === value)?.label : null}
            placeholder={<div className="autocomplete-option-list-placeholder">{t(placeholder)}</div>}
            toggleHandler={popoverToggler}
            isVisible={isVisiblePopover}
            setIsVisible={setIsVisiblePopover}
            keys={keys}
            titles={titles}
            list={listData}
            rowCount={listData.length}
            clearable={true}
            onClear={onClear}
            searchValue={searchValue}
            searchHandler={onSearch}
            optionValue={value}
            optionSelectHandler={handleChange}
            contentTop={45}
            contentWrapperClassName="filter-holder-autocomplete-option-list-content-wrapper"
            isLoadingList={isLoading}
            buttonWrapperClassName="filter-holder-autocomplete-option-list-button-wrapper"
        />
    );
};

AutocompleteOptionList.propTypes = {
    data: PropTypes.array,
    value: PropTypes.array,
    getRequest: PropTypes.func,
    getData: PropTypes.func,
    onChange: PropTypes.func,
    keys: PropTypes.array,
    titles: PropTypes.array,
    placeholder: PropTypes.string,
};

AutocompleteOptionList.defaultProps = {
    data: [],
    value: [],
    keys: [],
    titles: [],
    getRequest: noop,
    getData: noop,
    onChange: noop,
    placeholder: '',
};

export default memo(AutocompleteOptionList);
