import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
// Import Components
import CJCascadeDropDown from '../CJCascadeDropdown';
// Import Constants
import { formsTypes } from '../config';
// Import Services
import { getMatchedProperties, mapPropertiesToCascadeDropdownModel } from 'services/customerJourney';

const CJGuid = ({ defaultValue, getUpdate, option, node, elements, collectedProperties, data, fromFilter }) => {
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        if (fromFilter) {
            setProperties(data);
        } else {
            // use formsTypes.WfGuid.optionType its only has one option type support
            // when need change send option type from EditForm or use getFormContent(option.BaseTypes, formsTypes)?.optionType
            const matchedProperties = getMatchedProperties(formsTypes.WfGuid.optionType, collectedProperties);
            setProperties(mapPropertiesToCascadeDropdownModel(matchedProperties));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cascadeDropdownChangeHandler = ({ parentValue, value, logicFunction }) => {
        if (!isNil(parentValue) && !isNil(value)) {
            getUpdate({ blockId: parentValue, propertyName: value, logicFunction: logicFunction });
        }
    };

    const cascadeDropDownValidationChangeHandler = (isValid) => {
        getUpdate({ isValid });
    };

    return (
        <CJCascadeDropDown
            value={
                isNil(defaultValue?.blockId)
                    ? null
                    : {
                          parentValue: defaultValue?.blockId,
                          value: defaultValue?.propertyName,
                          logicFunction: defaultValue?.logicFunction,
                      }
            }
            data={properties}
            option={option}
            node={node}
            onChange={cascadeDropdownChangeHandler}
            elements={elements}
            onValidationChange={cascadeDropDownValidationChangeHandler}
        />
    );
};

CJGuid.propTypes = {
    collectedProperties: PropTypes.array.isRequired,
    defaultValue: PropTypes.any.isRequired,
    option: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    getUpdate: PropTypes.func,
    fromFilter: PropTypes.bool,
    data: PropTypes.array,
};

CJGuid.defaultProps = {
    getUpdate: noop,
    fromFilter: false,
    data: [],
};

export default memo(CJGuid);
