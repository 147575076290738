import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { isArray, isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SkeletonLoader, Popover, Tag, Label } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';
import { UsageViewModel } from './constants';

/**
 * @component
 *
 * @namespace
 * @property {array}  usedInData                - The data of device tab node.
 * @property {bool}  isLoadingUsedIn            - The boolean which change SkeletonLoader isBusy or not.
 * @property {bool}  isVisible                  - The boolean which show UsedInSection or not.
 * @property {string}  notUsedInDisplayNameKey  - The display name key when usedInData is empty.
 */
const UsedInSection = ({ usedInData, isLoadingUsedIn, isVisible, notUsedInDisplayNameKey }) => {
    const { t } = useTranslation();

    const [isOpenPromotions, setIsOpenPromotions] = useState(false);

    const promotionsClickHandler = () => {
        setIsOpenPromotions(!isOpenPromotions);
    };

    const getUsedInByType = (type) => {
        if (isNil(usedInData) || !isArray(usedInData)) return [];

        return usedInData.filter((usedInItem) => usedInItem.Type === type);
    };

    const renderPopoverTags = (usedInList, usageDisplayNameKey) => {
        return (
            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{}</span>
                <span className="ellipsis-text kv-value kv-value-css">
                    {
                        <div className="popoverTagSection-bc">
                            <div className="popoverTagSectionLabel-bc">
                                {t(usageDisplayNameKey) + ' (' + usedInList.length + ')'}
                            </div>
                            <div className="tagList-bc c-tagList-bc">
                                {usedInList.map((item, index) => {
                                    // TODO: move to SCSS
                                    return (
                                        <Tag
                                            color="#009bb2"
                                            background-color="#ff0000"
                                            key={index}
                                            name={item.Name}
                                            cornerRadius="smooth-radius"
                                            appearance="minimal"
                                            flexibility="compact"
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    }
                </span>
            </div>
        );
    };

    return (
        <>
            {isVisible && (
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.UsedIn)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">
                        {
                            // TODO: move to scss
                            <SkeletonLoader height="20px" isBusy={isLoadingUsedIn}>
                                {isEmpty(usedInData) ? (
                                    t(notUsedInDisplayNameKey)
                                ) : (
                                    <Popover
                                        // extendTargetWidth
                                        className="used-in-popover"
                                        isOpen={isOpenPromotions}
                                        extendTargetWidth={false}
                                        Content={
                                            <div className="used-in-content">
                                                {UsageViewModel.map((model, index) => {
                                                    const usedInByType = getUsedInByType(model.type);

                                                    return (
                                                        <div key={index}>
                                                            <div>
                                                                {!isEmpty(usedInByType) &&
                                                                    renderPopoverTags(
                                                                        usedInByType,
                                                                        model.displayNameKey,
                                                                    )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        }
                                    >
                                        <button onClick={promotionsClickHandler} className="used-in-button">
                                            <Label className="used-in-num">{usedInData.length}</Label>
                                            {t(l.Promotions)}
                                            <i className="bc-icon-arrow-down"></i>
                                        </button>
                                    </Popover>
                                )}
                            </SkeletonLoader>
                        }
                    </span>
                </div>
            )}
        </>
    );
};

UsedInSection.propTypes = {
    isVisible: PropTypes.bool,
    isLoadingUsedIn: PropTypes.bool,
    notUsedInDisplayNameKey: PropTypes.string,
    usedInData: PropTypes.array,
};

UsedInSection.defaultProps = {
    isVisible: true,
    isLoadingUsedIn: true,
    notUsedInDisplayNameKey: '',
    usedInData: [],
};

export default memo(UsedInSection);
