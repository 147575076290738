import React from 'react';
import { isEmpty, isNil, isObject, isString, cloneDeep, isArray, groupBy, noop, first, keys, values } from 'lodash';
// Import Components
import { TargetBlock, ActionBlock, TriggerBlock, ConditionBlock } from './blocks';
// Import Services
import { Helpers } from 'services';
import { optionValueBuilder, detectTemplate } from 'services/customerJourney';
import { BonusHttpService, CustomerJourneyHttpService, UtilsHttpService } from 'services/http';
// Import Constants
import {
    l,
    CustomerJourneyGroupBlockTypes,
    CJArgumentValuesTypes,
    CJReferenceInArgumentType,
    MonthDayYearWithTimeFormat,
    CJDynamicDateInArgumentType,
    WeekDaysKeyList,
    Months,
    LogicTypesValues,
    EventLogType,
    DateTimeFormat,
    DataTableColumnsCustomTypes,
    CJFormulaInArgumentType,
    defaultLogicFunction,
    CommunicationAvailabilityScheduleTypeLabels,
    PageTypes,
    CJDependencyTypes,
    DynamicBonusTypes,
    TimeZonePack,
    KPISources,
    PERMISSIONS,
    timeSpanDefaultValue,
    TimeFormatWithoutSeconds,
    ScheduleModalViews,
} from 'constants/common';
// Import Forms Components
import {
    CJBoolean,
    CJFilterCondition,
    CJMoney,
    CJDouble,
    CJString,
    CJTemplateId,
    CJInteger,
    CJBonus,
    CJPartnerBonusId,
    CJProviderId,
    CJTag,
    CJSchedule,
    CJEmpty,
    CJList,
    CJDateTime,
    CJTimeSpan,
    CJGuid,
    CJBlockName,
    CJCommunicationAvailabilityCondition,
    CJTemplate,
    CJCalculateKPI,
} from './forms';
// Import Info Blocks Components
import {
    CJMoneyInfoBlock,
    CJBooleanInfoBlock,
    CJDefaultInfoBlock,
    CJTemplateIdInfoBlock,
    CJProviderIdInfoBlock,
    CJPartnerBonusIdInfoBlock,
    CJScheduleInfoBlock,
    CJIntegerInfoBlock,
    CJFilterConditionInfoBlock,
    CJListInfoBlock,
    CJBonusInfoBlock,
    CJDateTimeInfoBlock,
    CJTimeSpanInfoBlock,
    CJBlockNameInfo,
    CJReferenceInArgumentInfoBlock,
    CJCommunicationAvailabilityConditionInfoBlock,
    CJTemplateConfigInfoBlock,
    CJCalculateKPIInfoBlock,
} from './infoBlocks';
// Import Validation Schemas
import {
    CJDoubleValidationSchema,
    CJIntegerValidationSchema,
    CJTemplateIdValidationSchema,
    CJStringValidationSchema,
    CJTimeStampValidationSchema,
    CJMoneyValidationSchema,
    CJProviderIdValidationSchema,
    CJPartnerBonusIdValidationSchema,
    CJTagValidationSchema,
    CJScheduleValidationSchema,
    CJListOptionValidationSchema,
    CJCommunicationAvailabilityConditionOptionValidationSchema,
    CJCalculateKPIValidationSchema,
} from 'validators/schemas.validation';

const { hourlyView } = ScheduleModalViews;
const { getFlagsValues, cronStringDecoder, customMomentWithoutTimezoneConversion, customMoment, isOnlyMinus } = Helpers;

const customerJourneyFlowEditorUndoRedoMaxCount = 30;
const deleteKeycode = 46;
const ctrlKeyCode = 17;
const copyKeyCode = 67;
const pasteKeyCode = 86;
const pastedElementsDeviation = 20;
const connectionLineType = 'bezier';
const arrowHeadType = 'arrowclosed';
const maxZoom = 1;
const minZoom = 0.2;

const flowSidebarBlockGroupMinVisibleCount = 3;

const nodeTypes = {
    [CustomerJourneyGroupBlockTypes.target]: TargetBlock,
    [CustomerJourneyGroupBlockTypes.action]: ActionBlock,
    [CustomerJourneyGroupBlockTypes.trigger]: TriggerBlock,
    [CustomerJourneyGroupBlockTypes.condition]: ConditionBlock,
};

const operatorsLabels = {
    eq: l.Equal,
    neq: l.NotEqual,
    gt: l.GreaterThan,
    gte: l.GreaterThanEqual,
    lt: l.LessThan,
    lte: l.LessThanEqual,
    isnotnull: l.IsNotNull,
    isnull: l.IsNull,
    onlyfrom: l.OnlyFrom,
    isempty: l.IsEmpty,
    isnotempty: l.IsNotEmpty,
    startswith: l.StartsWith,
    endswith: l.EndsWith,
    contains: l.Contains,
    doesnotcontain: l.DoesNotContain,
    like: l.Like,
    bcontains: l.Contains,
    bdoesnotcontain: l.DoesNotContain,
};

const dynamicDateData = [
    { label: l.Today, value: 'today' },
    { label: l.Yesterday, value: 'yesterday' },
    { label: l.DaysLater, value: 'daysLater' },
    { label: l.SameDay, value: 'sameDay' },
    { label: l.LastDayOfMonth, value: 'lastDayOfMonth' },
    { label: l.Weekday, value: 'weekday' },
    { label: l.DayOfYear, value: 'dayOfYear' },
    { label: l.Date, value: 'date' },
    { label: l.DateTime, value: 'dateTime' },
    { label: l.Time, value: 'time' },
];

const DynamicTypesRadioGroupOptionsConfig = [
    { label: l.Object, tooltip: l.CJBlockCalculateKPIObjectDescription, value: DynamicBonusTypes.Object },
    { label: l.KPI, tooltip: l.CJBlockCalculateKPIKPIDescription, value: DynamicBonusTypes.KPI },
    { label: l.FromFile, tooltip: l.CJBlockCalculateKPIFromFileDescription, value: DynamicBonusTypes.FromFile },
];

const referenceInArgumentStringBuilder = (value, data, elements, t) => {
    return stringBuilder(value, data, elements, t, () => '');
};

const stringBuilder = (value, data, elements, t, cb) => {
    if (value?.$type === CJReferenceInArgumentType || value?.$type === CJFormulaInArgumentType) {
        const property = formulaToReferenceMapToUIModel(value);
        const block = elements.find((el) => el.id === property.parentValue);
        property.parentLabel = block?.data?.name;
        const stringProperty = `${property?.parentLabel ?? property?.parentValue}/${t(property?.value)}`;
        if (!isNil(property?.logicFunction) && property?.logicFunction !== defaultLogicFunction) {
            const { Functions } = block.data?.metaData?.Properties.find((item) => item.Name === property.value);
            const tmpFunction = Functions.find((item) => item.value === property?.logicFunction);
            return `${t(tmpFunction?.label)} (${stringProperty})`;
        }
        return stringProperty;
    }
    return cb(value, data, elements, t);
};

const mapToApiModel = (value, argumentInType, option, cb) => {
    if (isNil(value)) {
        return optionValueBuilder();
    }

    if (argumentInType === CJArgumentValuesTypes.ReferenceInArgument) {
        const { blockId, propertyName, logicFunction } = value;

        return optionValueBuilder(argumentInType, {
            name: propertyName,
            blockId: blockId,
            logicFunction: logicFunction,
        });
    }

    return cb(value, argumentInType, option);
};

const mapToUIModel = (value, cb) => {
    if (isNil(value) || (isEmpty(value) && (isObject(value) || isString(value)))) {
        return { argumentInType: CJArgumentValuesTypes.DefaultInArgument };
    }

    if (value?.$type === CJReferenceInArgumentType) {
        return {
            blockId: value.Block,
            propertyName: value.Property,
            argumentInType: CJArgumentValuesTypes.ReferenceInArgument,
            logicFunction: defaultLogicFunction,
        };
    } else if (value?.$type === CJFormulaInArgumentType) {
        const referenceInArgumentValue = first(value.Arguments);
        return {
            blockId: referenceInArgumentValue.Block,
            propertyName: referenceInArgumentValue.Property,
            argumentInType: CJArgumentValuesTypes.ReferenceInArgument,
            logicFunction: value.Name,
        };
    }

    return cb(value);
};

const formulaToReferenceMapToUIModel = (value) => {
    if (isNil(value) || isEmpty(value)) {
        return {
            parentValue: null,
            value: null,
            logicFunction: null,
        };
    }
    const mappedValue = mapToUIModel(value, noop);
    return {
        parentValue: mappedValue?.blockId,
        value: mappedValue?.propertyName,
        logicFunction: mappedValue?.logicFunction,
    };
};

const staticInArgumentMaps = {
    mapToApiModel: (value, argumentInType, option) => {
        return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
            const { value: staticValue } = value;

            if (isNil(staticValue)) {
                return null;
            }
            const { Name } = option;

            const result = optionValueBuilder(argumentInType, {
                name: Name,
                value: staticValue,
            });
            return result;
        });
    },
    mapToUIModel: (data) => {
        return mapToUIModel(data, (value) => {
            return {
                value: value,
                argumentInType: CJArgumentValuesTypes.StaticInArgument,
            };
        });
    },
};
const getTemplateItemsApiModel = (templateItems) => {
    if (isNil(templateItems)) {
        return {};
    }
    return values(templateItems)
        .filter((item) => item.isChecked)
        .reduce((acc, item) => {
            acc[item.fieldName] = mapToApiModel(item.value, CJArgumentValuesTypes.ReferenceInArgument);
            return acc;
        }, {});
};

const getTemplateItemsUIModel = (templateItems) => {
    const result = keys(templateItems).reduce((acc, fieldName) => {
        acc[fieldName] = {
            fieldName: fieldName,
            isChecked: true,
            value: mapToUIModel(templateItems[fieldName]),
        };
        return acc;
    }, {});
    return result;
};

const pushDependency = (node, dependencyType, value) => {
    if (isNil(node.data.dependencies)) {
        node.data.dependencies = {};
    }
    if (isNil(node.data.dependencies[dependencyType])) {
        node.data.dependencies[dependencyType] = [];
    }
    node.data.dependencies[dependencyType].push(value);
};

const dependenciesConfig = {
    [CJDependencyTypes.Provider]: {
        dependencyType: CJDependencyTypes.Provider,
        getDependencyValue: (value, optionConfig) => {
            if (isNil(optionConfig?.optionType) || isNil(value)) {
                return null;
            }
            if (optionConfig.optionType === formsTypes.WfProviderId.optionType) {
                return value;
            }
            return null;
        },
        getDependencies: (dependencyItems, { pageType }) => {
            const providersByDeliveryMethod = dependencyItems.reduce((acc, { value, option, node, optionConfig }) => {
                if (!isNil(value) && !isNil(option?.CustomAttributes?.DeliveryMethod)) {
                    const providerId = dependenciesConfig[CJDependencyTypes.Provider].getDependencyValue(
                        value,
                        optionConfig,
                    );
                    if (!isNil(providerId)) {
                        const deliveryMethod = option.CustomAttributes.DeliveryMethod;
                        if (isNil(acc[deliveryMethod])) {
                            acc[deliveryMethod] = {};
                        }
                        if (isNil(acc[deliveryMethod][providerId])) {
                            acc[deliveryMethod][providerId] = [];
                        }

                        acc[deliveryMethod][providerId].push({ node, optionName: option.Name });
                    }
                }
                return acc;
            }, {});

            if (isEmpty(providersByDeliveryMethod)) {
                return new Promise((resolve) => {
                    resolve(null);
                });
            }

            const getProviderByDeliveryMethod = (deliveryMethod) => {
                return UtilsHttpService.getPartnerMessageProviders().request({
                    DeliveryMethod: deliveryMethod,
                });
            };

            return Promise.all(
                keys(providersByDeliveryMethod).map((deliveryMethod) => {
                    return getProviderByDeliveryMethod(deliveryMethod).then((response) => {
                        if (!isNil(response?.Data)) {
                            const providerMap = response.Data.reduce((acc, item) => {
                                acc[item.Id] = {
                                    value: item.Id,
                                    label: item.Name,
                                    dependencyItemId: `${item.Id}_${deliveryMethod}`,
                                };
                                return acc;
                            }, {});

                            const providers = providersByDeliveryMethod[deliveryMethod];
                            keys(providers).forEach((providerId) => {
                                if (isNil(providerMap[providerId])) {
                                    providers[providerId].forEach((item) => {
                                        if (
                                            !isNil(item.node?.data?.apiModel[item.optionName]) &&
                                            PageTypes.view !== pageType
                                        ) {
                                            item.node.data.apiModel[item.optionName] = null;
                                        }
                                    });
                                } else {
                                    providers[providerId].forEach((item) => {
                                        pushDependency(item.node, CJDependencyTypes.Provider, providerMap[providerId]);
                                    });
                                }
                            });
                        }
                    });
                }),
            );
        },
    },
    [CJDependencyTypes.Bonus]: {
        dependencyType: CJDependencyTypes.Bonus,
        getDependencyValue: (value, optionConfig) => {
            if (isNil(optionConfig?.optionType) || isNil(value)) {
                return null;
            }
            if (optionConfig.optionType === formsTypes.WfDynamicBonusId.optionType) {
                return value;
            }
            if (optionConfig.optionType === formsTypes.WfBonus.optionType) {
                return value?.BonusId;
            }
            return null;
        },
        getDependencies: (dependencyItems, { pageType }) => {
            const bonuses = dependencyItems.reduce((acc, { value, option, node, optionConfig }) => {
                const bonusId = dependenciesConfig[CJDependencyTypes.Bonus].getDependencyValue(value, optionConfig);
                if (!isNil(bonusId)) {
                    if (isNil(acc[bonusId])) {
                        acc[bonusId] = [];
                    }
                    acc[bonusId].push({ node, optionName: option.Name });
                }

                return acc;
            }, {});

            if (isEmpty(bonuses)) {
                return new Promise((resolve) => {
                    resolve(null);
                });
            }

            const getBonusByIds = (bonusIds, pageType) => {
                const queryString = { isIncludeExpired: false, isIncludeDeleted: false };
                if (PageTypes.view === pageType) {
                    queryString.isIncludeExpired = true;
                    queryString.isIncludeDeleted = true;
                }

                return BonusHttpService.getBonusByIds().request(bonusIds, queryString);
            };

            return getBonusByIds(keys(bonuses), pageType).then((response) => {
                if (!isNil(response?.Data)) {
                    const bonusMap = response.Data.reduce((acc, item) => {
                        acc[item.Id] = { ...item, dependencyItemId: item.Id };
                        return acc;
                    }, {});
                    keys(bonuses).forEach((bonusId) => {
                        if (isNil(bonusMap[bonusId])) {
                            bonuses[bonusId].forEach((item) => {
                                if (!isNil(item.node?.data?.apiModel[item.optionName]) && PageTypes.view !== pageType) {
                                    item.node.data.apiModel[item.optionName] = null;
                                }
                            });
                        } else {
                            bonuses[bonusId].forEach((item) => {
                                pushDependency(item.node, CJDependencyTypes.Bonus, bonusMap[bonusId]);
                            });
                        }
                    });
                }
            });
        },
    },
    [CJDependencyTypes.Template]: {
        dependencyType: CJDependencyTypes.Template,
        getDependencyValue: (value, optionConfig) => {
            if (isNil(optionConfig?.optionType) || isNil(value)) {
                return null;
            }
            if (optionConfig.optionType === formsTypes.TemplateConfig.optionType) {
                return value?.TemplateId;
            }

            return null;
        },
        getDependencies: (dependencyItems) => {
            const templates = dependencyItems.reduce((acc, { value, option, node, optionConfig }) => {
                const templateId = dependenciesConfig[CJDependencyTypes.Template].getDependencyValue(
                    value,
                    optionConfig,
                );
                if (!isNil(templateId)) {
                    if (isNil(acc[templateId])) {
                        acc[templateId] = [];
                    }
                    acc[templateId].push({ node, value, optionName: option.Name });
                }

                return acc;
            }, {});

            if (isEmpty(templates)) {
                return new Promise((resolve) => {
                    resolve(null);
                });
            }

            const getTemplateModelsById = (templateId) => {
                return CustomerJourneyHttpService.getTemplateModels().request({ templateId: templateId });
            };

            return Promise.all(
                keys(templates).map((templateId) => {
                    return getTemplateModelsById(templateId).then((response) => {
                        if (!isNil(response?.Data)) {
                            const dependencyObject = {
                                dependencyItemId: templateId,
                                templateId: templateId,
                                dynamicVariables: response.Data.reduce((acc, { FieldName, DisplayNameKey, WfType }) => {
                                    acc[FieldName] = {
                                        fieldName: FieldName,
                                        displayNameKey: DisplayNameKey,
                                        wfType: WfType,
                                    };

                                    return acc;
                                }, {}),
                            };
                            templates[templateId].forEach((item) => {
                                if (!isNil(item?.value?.TemplateItems)) {
                                    item.node.data.apiModel[item.optionName].TemplateItems = keys(
                                        item.node.data.apiModel[item.optionName].TemplateItems,
                                    ).reduce((acc, fieldName) => {
                                        if (!isNil(dependencyObject.dynamicVariables[fieldName])) {
                                            acc[fieldName] = item.value.TemplateItems[fieldName];
                                        }
                                        return acc;
                                    }, {});
                                }
                                pushDependency(item.node, CJDependencyTypes.Template, dependencyObject);
                            });
                        }
                    });
                }),
            );
        },
    },
    [CJDependencyTypes.KpiConfig]: {
        dependencyType: CJDependencyTypes.KpiConfig,
        getDependencyValue: (value, optionConfig) => {
            if (isNil(optionConfig?.optionType) || isNil(value)) {
                return null;
            }
            if (optionConfig.optionType === formsTypes.WfKpiConfig.optionType) {
                return value;
            }
            return null;
        },
        getDependencies: (dependencyItems, { pageType }) => {
            const kpiSegments = [];
            const kpiSegmentIds = [];

            dependencyItems.forEach(({ value, node, optionConfig }) => {
                const kpiSegment = dependenciesConfig[CJDependencyTypes.KpiConfig].getDependencyValue(
                    value,
                    optionConfig,
                );

                if (!isNil(kpiSegment)) {
                    kpiSegments.push({ value, node });
                    kpiSegmentIds.push(kpiSegment.SegmentId);
                }
            }, {});

            if (isEmpty(kpiSegments)) {
                return new Promise((resolve) => {
                    resolve(null);
                });
            }

            const getKpiSegmentsByIdes = (kpiSegmentIds) => {
                return UtilsHttpService.getByPath().request({
                    url: '/OptionsList/GetKpiConfigSuitableSegments',
                    queryParams: { Ides: [...new Set(kpiSegmentIds)] },
                });
            };

            return getKpiSegmentsByIdes(kpiSegmentIds).then((response) => {
                if (!isNil(response?.Data) && PageTypes.view !== pageType) {
                    const segments = response.Data.reduce((acc, segment) => {
                        acc[segment.Id] = segment;

                        return acc;
                    }, {});

                    kpiSegments.forEach(({ value, node }) => {
                        const segment = segments[value.SegmentId];

                        if (isNil(segment)) {
                            node.data.apiModel.KpiConfig.SegmentId = '';
                        } else {
                            if (value.DynamicBonusType === DynamicBonusTypes.Object && !isNil(value.ObjectType)) {
                                const hasInSupportedCases = segment.SupportedCases.some(
                                    (item) => item.Type === KPISources.Object && +item.Id === +value.ObjectType,
                                );

                                if (!hasInSupportedCases) {
                                    node.data.apiModel.KpiConfig.ObjectType = '';
                                }
                            }

                            if (value.DynamicBonusType === DynamicBonusTypes.KPI && !isNil(value.ColumnInfoId)) {
                                const hasInSupportedCases = segment.SupportedCases.some(
                                    (item) => item.Type === KPISources.KPI && +item.Id === +value.ColumnInfoId,
                                );

                                if (!hasInSupportedCases) {
                                    node.data.apiModel.KpiConfig.ColumnInfoId = '';
                                }
                            }
                        }
                    });
                }
            });
        },
    },
};

const formsTypes = {
    WfBoolean: {
        Component: CJBoolean,
        isLabelVisible: false,
        optionType: 'WfBoolean',
        infoComponent: CJBooleanInfoBlock,
        stringBuilder: (value, data, elements, t) =>
            stringBuilder(value, data, elements, t, (value) => (value ? 'Yes' : 'No')),
        ...staticInArgumentMaps,
    },
    TemplateConfig: {
        Component: CJTemplate,
        isLabelVisible: false,
        optionType: 'TemplateConfig',
        onlyPrimitive: true,
        infoComponent: CJTemplateConfigInfoBlock,
        dependencyType: CJDependencyTypes.Template,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, _argumentInType, _option) => {
                if (isNil(value?.templateId)) {
                    return null;
                }

                const { templateId, dynamicVariables } = value;
                return {
                    $type: 'BetConstruct.AGP.CRM.Workflow.Service.Models.Options.TemplateConfig, BetConstruct.AGP.CRM.Workflow.Service',
                    TemplateId: templateId,
                    TemplateItems: getTemplateItemsApiModel(dynamicVariables),
                };
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                const { TemplateId, TemplateItems } = value;
                return {
                    templateId: TemplateId,
                    dynamicVariables: getTemplateItemsUIModel(TemplateItems),
                };
            });
        },
    },
    WfInteger: {
        Component: CJInteger,
        isLabelVisible: true,
        optionType: 'WfInteger',
        infoComponent: CJIntegerInfoBlock,
        validationSchema: CJIntegerValidationSchema,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { value: staticValue } = value;

                if (isNil(staticValue) || isOnlyMinus(staticValue)) {
                    return null;
                }
                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: +staticValue,
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                return {
                    value: isNil(value) || isOnlyMinus(value) ? null : (+value).toString(),
                    argumentInType: CJArgumentValuesTypes.StaticInArgument,
                };
            });
        },
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, () => {
                return isNil(data) ? value : data;
            });
        },
    },
    'WfInteger[]': {
        Component: CJList,
        isLabelVisible: true,
        optionType: 'WfInteger[]',
        infoComponent: CJListInfoBlock,
        validationSchema: CJListOptionValidationSchema,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { value: staticValue } = value;
                if (isNil(staticValue) && !isArray(staticValue)) {
                    return null;
                }
                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: staticValue.map((item) => (isNil(item) ? null : +item)),
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                let returnValue = [];
                if (!isNil(value)) {
                    if (isArray(value)) {
                        returnValue = value.map((el) => el?.toString());
                    } else {
                        returnValue = [value?.toString()];
                    }
                }
                return {
                    value: returnValue,
                    argumentInType: CJArgumentValuesTypes.StaticInArgument,
                };
            });
        },
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, (value, data, _elements, _t) => {
                return data || value;
            });
        },
    },
    WfString: {
        Component: CJString,
        isLabelVisible: true,
        optionType: 'WfString',
        infoComponent: CJDefaultInfoBlock,
        validationSchema: CJStringValidationSchema,
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, (value, _data, _elements, _t) => value);
        },
        ...staticInArgumentMaps,
    },
    WfDecimal: {
        Component: CJDouble,
        isLabelVisible: true,
        optionType: 'WfDecimal',
        infoComponent: CJDefaultInfoBlock,
        validationSchema: CJDoubleValidationSchema,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { value: staticValue } = value;

                if (isNil(staticValue) || isOnlyMinus(staticValue)) {
                    return null;
                }
                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: +staticValue,
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                return {
                    value: isNil(value) || isOnlyMinus(value) ? null : value.toString(),
                    argumentInType: CJArgumentValuesTypes.StaticInArgument,
                };
            });
        },

        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, (value) => value);
        },
    },
    WfTimeSpan: {
        Component: CJTimeSpan,
        isLabelVisible: true,
        optionType: 'WfTimeSpan',
        infoComponent: CJTimeSpanInfoBlock,
        validationSchema: CJTimeStampValidationSchema,
        ...staticInArgumentMaps,
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, (value, _data, _elements, t) => {
                const tmpValue = value ?? timeSpanDefaultValue;
                const indexOfDot = tmpValue.indexOf('.');
                return `${t(l.Day)} ${tmpValue.slice(0, indexOfDot)} ${t(l.Time)} ${tmpValue.slice(
                    indexOfDot + 1,
                    tmpValue.length - 3,
                )}`;
            });
        },
    },
    WfDouble: {
        Component: CJDouble,
        isLabelVisible: true,
        optionType: 'WfDouble',
        infoComponent: CJDefaultInfoBlock,
        validationSchema: CJDoubleValidationSchema,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { value: staticValue } = value;

                if (isNil(staticValue) || isOnlyMinus(staticValue)) {
                    return null;
                }
                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: +staticValue,
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                return {
                    value: isNil(value) || isOnlyMinus(value) ? null : value.toString(),
                    argumentInType: CJArgumentValuesTypes.StaticInArgument,
                };
            });
        },
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, (value) => value);
        },
    },
    WfMoney: {
        Component: CJMoney,
        isLabelVisible: true,
        optionType: 'WfMoney',
        validationSchema: CJMoneyValidationSchema,
        infoComponent: CJMoneyInfoBlock,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { amount, currencyCode } = value;
                if ((isNil(amount) && isNil(currencyCode)) || (isEmpty(amount) && isEmpty(currencyCode))) {
                    return null;
                }
                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: {
                        $type: 'BetConstruct.AGP.CRM.Workflow.Service.Models.PropertyTypes.Money, BetConstruct.AGP.CRM.Workflow.Service',
                        Amount: isNil(amount) || isEmpty(amount) || isOnlyMinus(amount) ? null : +amount,
                        Currency: currencyCode,
                    },
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (Value) => {
                return {
                    amount: Value?.Amount?.toString(),
                    currencyCode: Value?.Currency,
                    argumentInType: CJArgumentValuesTypes?.StaticInArgument,
                };
            });
        },
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, ({ Amount, Currency }) => `${Amount} ${Currency}`);
        },
    },
    WfTemplateId: {
        Component: CJTemplateId,
        optionType: 'WfTemplateId',
        infoComponent: CJTemplateIdInfoBlock,
        validationSchema: CJTemplateIdValidationSchema,
        ...staticInArgumentMaps,
    },
    //TODO will be removed after backend changed
    WfStaticBonusId: {
        // eslint-disable-next-line react/display-name
        Component: CJEmpty,
        optionType: 'WfStaticBonusId',
        infoComponent: null,
        mapToApiModel: (_value, _argumentInType, option) => {
            const { Name } = option;

            const result = optionValueBuilder(CJArgumentValuesTypes.StaticInArgument, {
                name: Name,
                value: 0,
            });
            return result;
        },
        mapToUIModel: (_data) => {
            return { value: 0, argumentInType: CJArgumentValuesTypes.StaticInArgument };
        },
    },
    WfBonus: {
        Component: CJBonus,
        isLabelVisible: true,
        optionType: 'WfBonus',
        infoComponent: CJBonusInfoBlock,
        dependencyType: CJDependencyTypes.Bonus,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { bonusId, amounts, triggerType, wallet } = value;
                if (isNil(bonusId)) {
                    return null;
                }
                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: {
                        $type: 'BetConstruct.AGP.CRM.Models.DAL.Bonus, BetConstruct.AGP.CRM.Models',
                        BonusId: bonusId,
                        Amounts: amounts,
                        TriggerType: triggerType,
                        Wallet: wallet,
                    },
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                return {
                    amounts: [...value?.Amounts],
                    bonusId: value?.BonusId,
                    triggerType: value?.TriggerType,
                    wallet: value?.Wallet,
                    argumentInType: CJArgumentValuesTypes?.StaticInArgument,
                };
            });
        },
    },
    WfDynamicBonusId: {
        Component: CJPartnerBonusId,
        optionType: 'WfDynamicBonusId',
        infoComponent: CJPartnerBonusIdInfoBlock,
        validationSchema: CJPartnerBonusIdValidationSchema,
        dependencyType: CJDependencyTypes.Bonus,
        ...staticInArgumentMaps,
    },
    WfTag: {
        Component: CJTag,
        isLabelVisible: true,
        optionType: 'WfTag',
        infoComponent: CJDefaultInfoBlock,
        validationSchema: CJTagValidationSchema,
        ...staticInArgumentMaps,
    },
    WfProviderId: {
        Component: CJProviderId,
        isLabelVisible: false,
        optionType: 'WfProviderId',
        infoComponent: CJProviderIdInfoBlock,
        validationSchema: CJProviderIdValidationSchema,
        dependencyType: CJDependencyTypes.Provider,
        ...staticInArgumentMaps,
    },
    WfSchedule: {
        Component: CJSchedule,
        isLabelVisible: true,
        optionType: 'WfSchedule',
        infoComponent: CJScheduleInfoBlock,
        validationSchema: CJScheduleValidationSchema,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { startDate, endDate, cronSchedule, timeZone, isActiveSchedule } = value;

                if (!isActiveSchedule) {
                    return null;
                }
                const { Name } = option;
                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: {
                        $type: 'BetConstruct.AGP.CRM.Models.DAL.Schedule, BetConstruct.AGP.CRM.Models',
                        StartDate: startDate,
                        EndDate: endDate,
                        CronSchedule: cronSchedule,
                        TimeZone: timeZone,
                    },
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                if (isNil(value)) {
                    return null;
                }
                const {
                    StartDate: startDate,
                    EndDate: endDate,
                    CronSchedule: cronSchedule,
                    TimeZone: timeZone,
                    RecurrenceCount: recurrenceCount,
                } = value;
                const scheduleData = cronStringDecoder(cronSchedule);
                let StartTime = customMomentWithoutTimezoneConversion(startDate).format(TimeFormatWithoutSeconds);

                if (scheduleData.viewType === hourlyView) {
                    scheduleData.startTime = StartTime;
                }

                if (!isNil(cronSchedule) && scheduleData.viewType !== hourlyView) {
                    StartTime = scheduleData.startTime;
                }

                return {
                    startTime: StartTime,
                    endDate: customMomentWithoutTimezoneConversion(endDate).format(MonthDayYearWithTimeFormat),
                    startDate: customMomentWithoutTimezoneConversion(startDate).format(MonthDayYearWithTimeFormat),
                    selectedDateSelector: scheduleData.viewType,
                    datesDict: { [scheduleData.viewType]: scheduleData },
                    timeZone: timeZone,
                    modalView: scheduleData.viewType,
                    recurrenceCount: recurrenceCount,
                    isActiveSchedule: true,
                    cronSchedule: cronSchedule,
                };
            });
        },
    },
    WfDateTime: {
        Component: CJDateTime,
        isLabelVisible: false,
        optionType: 'WfDateTime',
        infoComponent: CJDateTimeInfoBlock,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, ({ value }, _argumentInType, _option) => {
                // TODO: move to cjDateTime config
                const timeZoneValue = value?.timeZone ?? 0;
                const timeZoneManipulations = { Method: 'add', Params: [timeZoneValue * 60, 'minutes'] };
                switch (value?.template) {
                    case 'dayOfYear': {
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'dates', Params: [value?.params?.dates ?? '1'] },
                                { Method: 'months', Params: [value?.params?.months ?? 0] },
                            ],
                            DateOffset: {},
                            timeZone: timeZoneValue,
                            template: 'dayOfYear',
                        };
                    }
                    case 'dayOfMonth': {
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'dates', Params: [value?.params?.dates ?? '1'] },
                            ],
                            DateOffset: {},
                            timeZone: timeZoneValue,
                            template: 'dayOfMonth',
                        };
                    }

                    case 'weekday': {
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'days', Params: [value?.params?.weekday ?? '0'] },
                            ],
                            DateOffset: {},
                            timeZone: timeZoneValue,
                            template: 'weekday',
                        };
                    }
                    case 'time': {
                        const tmpDate = customMoment();
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'minutes', Params: [value?.params?.minutes ?? tmpDate.minute()] },
                                { Method: 'hours', Params: [value?.params?.hours ?? tmpDate.hour()] },
                            ],
                            DateOffset: {},
                            timeZone: timeZoneValue,
                            template: 'time',
                        };
                    }
                    case 'date': {
                        const tmpDate = customMoment();
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'dates', Params: [value?.params?.dates ?? tmpDate.date()] },
                                { Method: 'months', Params: [value?.params?.months ?? tmpDate.month()] },
                                { Method: 'years', Params: [value?.params?.years ?? tmpDate.year()] },
                            ],
                            DateOffset: {},
                            timeZone: timeZoneValue,
                            template: 'date',
                        };
                    }
                    case 'dateTime': {
                        const tmpDate = customMoment();
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'dates', Params: [value?.params?.dates ?? tmpDate.date()] },
                                { Method: 'months', Params: [value?.params?.months ?? tmpDate.month()] },
                                { Method: 'years', Params: [value?.params?.years ?? tmpDate.year()] },
                                { Method: 'minutes', Params: [value?.params?.minutes ?? tmpDate.minute()] },
                                { Method: 'hours', Params: [value?.params?.hours ?? tmpDate.hour()] },
                            ],
                            timeZone: timeZoneValue,
                            template: 'dateTime',
                        };
                    }
                    case 'daysLater':
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'minutes', Params: [0] },
                                { Method: 'hours', Params: [0] },
                                { Method: 'add', Params: [value?.params?.offset ?? 0, 'days'] },
                            ],
                            DateOffset: optionValueBuilder(CJArgumentValuesTypes.ReferenceInArgument, {
                                blockId: value?.params?.property?.parentValue,
                                name: value?.params?.property?.value,
                                logicFunction: value?.params?.property?.logicFunction,
                            }),
                            timeZone: timeZoneValue,
                            template: 'daysLater',
                        };
                    case 'sameDay':
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'minutes', Params: [0] },
                                { Method: 'hours', Params: [0] },
                            ],
                            DateOffset: optionValueBuilder(CJArgumentValuesTypes.ReferenceInArgument, {
                                blockId: value?.params?.property?.parentValue,
                                name: value?.params?.property?.value,
                                logicFunction: value?.params?.property?.logicFunction,
                            }),
                            timeZone: timeZoneValue,
                            template: 'sameDay',
                        };
                    case 'lastDayOfMonth':
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'add', Params: [1, 'months'] },
                                { Method: 'dates', Params: [1] },
                                { Method: 'subtract', Params: [1, 'days'] },
                            ],
                            DateOffset: {},
                            timeZone: timeZoneValue,
                            template: 'lastDayOfMonth',
                        };
                    case 'today':
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'minutes', Params: [0] },
                                { Method: 'hours', Params: [0] },
                            ],
                            timeZone: timeZoneValue,
                            template: 'today',
                        };
                    case 'yesterday':
                        return {
                            $type: CJDynamicDateInArgumentType,
                            Manipulations: [
                                timeZoneManipulations,
                                { Method: 'minutes', Params: [0] },
                                { Method: 'hours', Params: [0] },
                                { Method: 'subtract', Params: [1, 'days'] },
                            ],
                            timeZone: timeZoneValue,
                            template: 'yesterday',
                        };
                    default:
                        break;
                }
                return value;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                // TODO: move to cjDateTime config
                const result = { value: { template: value?.template, timeZone: value?.timeZone ?? 0 } };
                let params = {};
                let manipulations = cloneDeep(value?.Manipulations);
                if (
                    !isNil(manipulations) &&
                    manipulations.length > 0 &&
                    manipulations[0].Method === 'add' &&
                    manipulations[0].Params[1] === 'minutes'
                ) {
                    manipulations.shift();
                    if (manipulations.length === 0) {
                        manipulations = null;
                    }
                }
                switch (value?.template) {
                    case 'dayOfYear':
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            params.dates = getValueInDate(groupedManipulations, 'dates');
                            params.months = getValueInDate(groupedManipulations, 'months');
                        }
                        break;
                    case 'dayOfMonth':
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            params.dates = getValueInDate(groupedManipulations, 'dates');
                        }
                        break;
                    case 'weekday':
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            params.weekday = getValueInDate(groupedManipulations, 'days');
                        }
                        break;
                    case 'time':
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            params.minutes = getValueInDate(groupedManipulations, 'minutes');
                            params.hours = getValueInDate(groupedManipulations, 'hours');
                        }
                        break;
                    case 'date':
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            params.years = getValueInDate(groupedManipulations, 'years');
                            params.dates = getValueInDate(groupedManipulations, 'dates');
                            params.months = getValueInDate(groupedManipulations, 'months');
                        }
                        break;
                    case 'dateTime':
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            params.dates = getValueInDate(groupedManipulations, 'dates');
                            params.months = getValueInDate(groupedManipulations, 'months');
                            params.years = getValueInDate(groupedManipulations, 'years');
                            params.minutes = getValueInDate(groupedManipulations, 'minutes');
                            params.hours = getValueInDate(groupedManipulations, 'hours');
                        }
                        break;
                    case 'daysLater':
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            params.offset = getValueInDate(groupedManipulations, 'add');
                        }
                        params.property = formulaToReferenceMapToUIModel(value?.DateOffset);
                        break;
                    case 'sameDay':
                        params.property = formulaToReferenceMapToUIModel(value?.DateOffset);
                        break;
                    default:
                        //lastDayOfMonth, today, yesterday it's only have template
                        break;
                }
                result.value.params = params;
                return result;
            });
        },
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, (value, _data, elements, t) => {
                const timeZoneLabel = TimeZonePack[value?.timeZone ?? 0];
                let manipulations = cloneDeep(value?.Manipulations);
                if (
                    !isNil(manipulations) &&
                    manipulations.length > 0 &&
                    manipulations[0].Method === 'add' &&
                    manipulations[0].Params[1] === 'minutes'
                ) {
                    manipulations.shift();
                    if (manipulations.length === 0) {
                        manipulations = null;
                    }
                }

                switch (value?.template) {
                    case 'today':
                        return `${t(l.Today)} ${timeZoneLabel}`;
                    case 'yesterday':
                        return `${t(l.Yesterday)} ${timeZoneLabel}`;
                    case 'lastDayOfMonth':
                        return `${t(l.LastDayOfMonth)} ${timeZoneLabel}`;
                    case 'dayOfYear':
                        return `${t(l.DayOfYear)} ${t(l.Month)} ${t(l[Months[manipulations[1].Params[0]]])} ${t(
                            l.Day,
                        )} ${manipulations[0].Params[0]} ${timeZoneLabel}`;
                    case 'dayOfMonth':
                        return `${t(l.DayOfMonth)} ${t(l.Day)} ${manipulations[0].Params[0]} ${timeZoneLabel}`;
                    case 'weekday':
                        return `${t(l.Weekday)} ${t(l[WeekDaysKeyList[manipulations[0].Params[0]]])} ${timeZoneLabel}`;
                    case 'time': {
                        const groupedManipulations = groupBy(manipulations, 'Method');
                        return `${t(l.Time)} ${getValueInDate(groupedManipulations, 'hours')}:${getValueInDate(
                            groupedManipulations,
                            'minutes',
                        )} ${timeZoneLabel}`;
                    }
                    case 'date': {
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            const years = getValueInDate(groupedManipulations, 'years');
                            const dates = getValueInDate(groupedManipulations, 'dates');
                            const months = getValueInDate(groupedManipulations, 'months');
                            return `${t(l.Date)} ${dates}/${months + 1}/${years} ${timeZoneLabel}`;
                        }
                        return '';
                    }
                    case 'dateTime': {
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            const dates = getValueInDate(groupedManipulations, 'dates');
                            const months = getValueInDate(groupedManipulations, 'months');
                            const years = getValueInDate(groupedManipulations, 'years');
                            const minutes = getValueInDate(groupedManipulations, 'minutes');
                            const hours = getValueInDate(groupedManipulations, 'hours');
                            return `${t(l.DateTime)} ${dates}/${
                                months + 1
                            }/${years} ${hours}:${minutes} ${timeZoneLabel}`;
                        }

                        return '';
                    }
                    case 'daysLater': {
                        let offset = '';
                        if (!isNil(manipulations)) {
                            const groupedManipulations = groupBy(manipulations, 'Method');
                            offset = getValueInDate(groupedManipulations, 'add');
                        }
                        const property = formulaToReferenceMapToUIModel(value?.DateOffset);

                        const block = elements.find((el) => el.id === property.parentValue);
                        property.parentLabel = block?.data?.name;
                        const stringProperty = `${t(l.DaysLater)} ${t(l.Offset)} ${offset} ${
                            property?.parentLabel ?? property?.parentValue
                        }/${t(property?.value)}`;

                        if (!isNil(property?.logicFunction) && property?.logicFunction !== defaultLogicFunction) {
                            const { Functions } = block.data.metaData.Properties.find(
                                (item) => item.Name === property.value,
                            );
                            const tmpFunction = Functions.find((item) => item.value === property?.logicFunction);
                            return `${t(tmpFunction?.label)} (${stringProperty}) ${timeZoneLabel}`;
                        }
                        return `${stringProperty} ${timeZoneLabel}`;
                    }

                    case 'sameDay': {
                        const property = formulaToReferenceMapToUIModel(value?.DateOffset);

                        const block = elements.find((el) => el.id === property.parentValue);
                        property.parentLabel = block?.data?.name;
                        const stringProperty = `${t(l.SameDay)} ${property?.parentLabel ?? property?.parentValue}/${t(
                            property?.value,
                        )}`;

                        if (!isNil(property?.logicFunction) && property?.logicFunction !== defaultLogicFunction) {
                            const { Functions } = block.data.metaData.Properties.find(
                                (item) => item.Name === property.value,
                            );
                            const tmpFunction = Functions.find((item) => item.value === property?.logicFunction);
                            return `${t(tmpFunction?.label)} (${stringProperty}) ${timeZoneLabel}`;
                        }
                        return `${stringProperty} ${timeZoneLabel}`;
                    }
                    default:
                        return '';
                }
            });
        },
    },
    WfFlags: {
        Component: CJList,
        isLabelVisible: true,
        optionType: 'WfFlags',
        infoComponent: CJListInfoBlock,
        validationSchema: CJListOptionValidationSchema,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { value: staticValue } = value;
                if (isNil(staticValue) && !isArray(staticValue)) {
                    return 0; // the flag is not selected
                }

                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: staticValue.reduce((acc, item) => {
                        acc = acc | (isNil(item) ? 0 : +item);
                        return acc;
                    }, 0),
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                return {
                    value: getFlagsValues(value),
                    argumentInType: CJArgumentValuesTypes.StaticInArgument,
                };
            });
        },
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, (value, data, _elements, _t) => {
                return data || value;
            });
        },
    },
    WfGuid: {
        Component: CJGuid,
        isLabelVisible: true,
        optionType: 'WfGuid',
        infoComponent: CJReferenceInArgumentInfoBlock,
        onlyPrimitive: true,
        stringBuilder: (value, data, elements, t) => {
            return stringBuilder(value, data, elements, t, noop);
        },
        mapToApiModel: (value, _argumentInType, option) => {
            return mapToApiModel(value, CJArgumentValuesTypes.ReferenceInArgument, option, noop);
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                return {
                    value: value,
                    argumentInType: CJArgumentValuesTypes.StaticInArgument,
                };
            });
        },
    },
    WfBlockName: {
        Component: CJBlockName,
        optionType: 'WfBlockName',
        infoComponent: CJBlockNameInfo,
        onlyPrimitive: true,
        ...staticInArgumentMaps,
    },
    CommunicationAvailabilityCondition: {
        Component: CJCommunicationAvailabilityCondition,
        optionType: 'CommunicationAvailabilityCondition',
        isLabelVisible: true,
        onlyPrimitive: true,
        infoComponent: CJCommunicationAvailabilityConditionInfoBlock,
        validationSchema: CJCommunicationAvailabilityConditionOptionValidationSchema,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                const { timeZone, schedule } = value;
                const { Name } = option;
                const timeSpanSuffix = ':00.0000000';

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: {
                        $type: 'BetConstruct.AGP.CRM.Workflow.Service.Blocks.Conditions.CommunicationAvailabilityByWeekdaysAndTime, BetConstruct.AGP.CRM.Workflow.Service',
                        TimeZone: timeZone,
                        Schedule: schedule.map((item) => {
                            if (item.isEnable) {
                                return {
                                    StartTime: `${item.value.startTime.value ?? ''}${timeSpanSuffix}`,
                                    EndTime: `${item.value.endTime.value ?? ''}${timeSpanSuffix}`,
                                };
                            }
                            return null;
                        }),
                    },
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                const enabledItems = [];
                const tmpSchedule = CJCommunicationAvailabilityConditionConfig[value?.Schedule?.length ?? 0].map(
                    (item, index) => {
                        const timeSpanSuffix = ':00.0000000';
                        const getTime = (time) => {
                            if (isNil(time)) {
                                return null;
                            }
                            return time.substring(0, time.length - timeSpanSuffix.length);
                        };
                        if (!isNil(value.Schedule[index])) {
                            enabledItems.push(index);
                        }

                        return {
                            ...item,
                            isEnable: !isNil(value.Schedule[index]),
                            value: {
                                startTime: { value: getTime(value.Schedule[index]?.StartTime) },
                                endTime: { value: getTime(value.Schedule[index]?.EndTime) },
                            },
                        };
                    },
                );
                if (enabledItems.length === 1) {
                    tmpSchedule[first(enabledItems)].isCheckboxEnable = false;
                }
                return {
                    timeZone: value?.TimeZone,
                    scheduleType: value?.Schedule?.length,
                    schedule: tmpSchedule,
                };
            });
        },
    },
    FilterCondition: {
        Component: CJFilterCondition,
        isLabelVisible: true,
        optionType: 'FilterCondition',
        infoComponent: CJFilterConditionInfoBlock,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, argumentInType, option) => {
                if ((value?.isGroupFilter === true && isEmpty(value?.filters)) || isEmpty(value)) {
                    return null;
                }
                const { Name } = option;

                const result = optionValueBuilder(argumentInType, {
                    name: Name,
                    value: getFilterConditionApiModel(value, true),
                });
                return result;
            });
        },
        mapToUIModel: (data) => {
            return getFilterConditionUIModel(data);
        },
    },
    WfKpiConfig: {
        Component: CJCalculateKPI,
        isLabelVisible: false,
        optionType: 'WfKpiConfig',
        infoComponent: CJCalculateKPIInfoBlock,
        validationSchema: CJCalculateKPIValidationSchema,
        dependencyType: CJDependencyTypes.KpiConfig,
        mapToApiModel: (value, argumentInType, option) => {
            return mapToApiModel(value, argumentInType, option, (value, _argumentInType, _option) => {
                return {
                    $type: 'BetConstruct.AGP.CRM.Models.DAL.KpiConfig, BetConstruct.AGP.CRM.Models',
                    SegmentId: value.segmentId,
                    DynamicBonusType: value.amountSourceType,
                    ...(+value.amountSourceType === DynamicBonusTypes.Object && {
                        ObjectType: value.objectType,
                        Function: value.calculationMethod,
                    }),
                    ...(+value.amountSourceType === DynamicBonusTypes.KPI && { ColumnInfoId: value.columnInfoId }),
                    ...(+value.amountSourceType === DynamicBonusTypes.FromFile && { ObjectType: 1003 }),
                };
            });
        },
        mapToUIModel: (data) => {
            return mapToUIModel(data, (value) => {
                return {
                    segmentId: value.SegmentId,
                    amountSourceType: value.DynamicBonusType,
                    ...(+value.DynamicBonusType === DynamicBonusTypes.Object && {
                        objectType: value.ObjectType,
                        calculationMethod: value.Function,
                    }),
                    ...(+value.DynamicBonusType === DynamicBonusTypes.KPI && { columnInfoId: value.ColumnInfoId }),
                };
            });
        },
    },
};

const getFilterConditionUIModel = (filter) => {
    if (filter?.IsPrimitive === true) {
        return {
            property: getProperty(filter),
            operator: filter?.Operator,
            rightValue: getRightValue(filter),
        };
    }
    return {
        isGroupFilter: true,
        logic: isNil(filter?.Logic) ? LogicTypesValues.and : filter?.Logic,
        filters: filter?.Conditions?.map(getFilterConditionUIModel),
    };
};

const getFilterConditionApiModel = (filter, isParent = false) => {
    if (filter?.isGroupFilter === true) {
        let result = {};
        if (isParent === true) {
            result.$type =
                'BetConstruct.AGP.CRM.Workflow.Blocks.Conditions.LogicalFilterCondition, BetConstruct.AGP.CRM.Workflow';
        }
        result = {
            ...result,
            IsPrimitive: false,
            Logic: isNil(filter?.logic) ? LogicTypesValues.and : filter?.logic,
            Conditions: filter?.filters?.map(getFilterConditionApiModel),
        };

        return result;
    }

    const { property, operator, rightValue } = filter;
    let tmpLeftValue = optionValueBuilder(CJArgumentValuesTypes.ReferenceInArgument, {
        blockId: property?.parentValue,
        name: property?.value,
        logicFunction: property?.logicFunction,
    });
    let tmpRightValue = cloneDeep(rightValue);
    let leftValue = tmpLeftValue;

    if (!isNil(tmpRightValue?.template)) {
        // TODO: change in functions and change switch to array includes
        leftValue = {
            $type: CJDynamicDateInArgumentType,
            Manipulations: [{ Method: 'add', Params: [(tmpRightValue?.timeZone ?? 0) * 60, 'minutes'] }],
            DateOffset: tmpLeftValue,
        };
        switch (tmpRightValue.template) {
            case 'today':
            case 'sameDay':
            case 'yesterday':
            case 'daysLater':
                leftValue.Manipulations.push({ Method: 'minutes', Params: [0] }, { Method: 'hours', Params: [0] });
                break;

            default:
                break;
        }

        switch (tmpRightValue.template) {
            case 'dayOfYear':
            case 'dayOfMonth':
            case 'weekday':
            case 'time':
            case 'date':
            case 'lastDayOfMonth':
                tmpRightValue.DateOffset = tmpLeftValue;
                break;

            default:
                break;
        }

        delete tmpRightValue.template;
    }

    return {
        IsPrimitive: true,
        LeftValue: leftValue,
        Operator: operator,
        RightValue: tmpRightValue,
    };
};
const getValueInDate = (groupedManipulations, property) => {
    if (
        !isNil(groupedManipulations) &&
        !isNil(groupedManipulations[property]) &&
        !isEmpty(groupedManipulations[property]) &&
        !isNil(groupedManipulations[property][0]) &&
        !isNil(groupedManipulations[property][0].Params) &&
        !isEmpty(groupedManipulations[property][0].Params)
    ) {
        return groupedManipulations[property][0].Params[0];
    }
    return null;
};

const getProperty = ({ LeftValue, Operator: _Operator, RightValue }) => {
    let tmpLeftValue = LeftValue;
    const isDynamicDate = !isNil(RightValue?.$type) && RightValue.$type === CJDynamicDateInArgumentType;

    if (isDynamicDate && !isNil(LeftValue?.DateOffset)) {
        tmpLeftValue = LeftValue?.DateOffset;
    }
    return formulaToReferenceMapToUIModel(tmpLeftValue);
};

const getRightValue = ({ LeftValue, Operator: _Operator, RightValue }) => {
    const isDynamicDate = !isNil(RightValue?.$type) && RightValue.$type === CJDynamicDateInArgumentType;

    if (isDynamicDate) {
        const template = detectTemplate({ LeftValue, RightValue });
        return { ...RightValue, template };
    }
    return RightValue;
};

const TimeLineDataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.Date),
                dataKey: 'Date',
                sortable: true,
                formatter: (Date) => {
                    return customMomentWithoutTimezoneConversion(Date).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Action),
                dataKey: 'Action',
                sortable: true,
                // eslint-disable-next-line react/display-name
                colRenderer: (Action) => <div title={t(l[Action])}>{t(l[Action])}</div>,
            },
            {
                text: t(l.Source),
                dataKey: 'Source',
                sortable: true,
                // eslint-disable-next-line react/display-name
                colRenderer: (Source) => <div title={t(l[Source])}>{t(l[Source])}</div>,
            },
            {
                text: t(l.Name),
                dataKey: 'Name',
                sortable: true,
            },
            {
                text: t(l.User),
                dataKey: 'UserName',
                sortable: true,
            },
            {
                text: t(l.Type),
                dataKey: 'Type',
                sortable: true,
                colRenderer: (Type) => <div title={t(l[EventLogType[Type]])}>{t(l[EventLogType[Type]])}</div>,
            },
            {
                text: t(l.SystemMessage),
                dataKey: 'SystemMessage',
                sortable: false,
                permissions: [PERMISSIONS.CRMAdmin, PERMISSIONS.CRMManager],
            },
            {
                text: t(l.Info),
                dataKey: 'Info',
                sortable: false,
                permissions: [PERMISSIONS.CRMAdmin, PERMISSIONS.CRMManager],
                colRenderer: (info) => {
                    const text = Object.entries(info ?? {})
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(', ');
                    return <div title={text}>{text}</div>;
                },
            },
        ],
        defaultSorting: { name: 'Date', direction: 'desc' },
    };
};

const CJCommunicationAvailabilityConditionConfigCreator = (
    label,
    withCheckbox = true,
    isCheckboxEnable = true,
    isEnable = true,
) => {
    return {
        label: label,
        withCheckbox: withCheckbox,
        isEnable: isEnable,
        isCheckboxEnable: isCheckboxEnable,
        value: {
            startTime: { value: null, isValid: true, errorText: null },
            endTime: { value: null, isValid: true, errorText: null },
        },
    };
};

const CJCommunicationAvailabilityConditionConfig = {
    [CommunicationAvailabilityScheduleTypeLabels.Always]: [],
    [CommunicationAvailabilityScheduleTypeLabels.SameEveryDay]: [
        CJCommunicationAvailabilityConditionConfigCreator(l.CommunicationAvailabilitySameEveryDay, false),
    ],
    [CommunicationAvailabilityScheduleTypeLabels.WeekdaysAndWeekend]: [
        CJCommunicationAvailabilityConditionConfigCreator(l.Weekdays),
        CJCommunicationAvailabilityConditionConfigCreator(l.Weekend),
    ],
    [CommunicationAvailabilityScheduleTypeLabels.DaysOfTheWeek]: [
        CJCommunicationAvailabilityConditionConfigCreator(l.Sunday),
        CJCommunicationAvailabilityConditionConfigCreator(l.Monday),
        CJCommunicationAvailabilityConditionConfigCreator(l.Tuesday),
        CJCommunicationAvailabilityConditionConfigCreator(l.Wednesday),
        CJCommunicationAvailabilityConditionConfigCreator(l.Thursday),
        CJCommunicationAvailabilityConditionConfigCreator(l.Friday),
        CJCommunicationAvailabilityConditionConfigCreator(l.Saturday),
    ],
};

export {
    customerJourneyFlowEditorUndoRedoMaxCount,
    deleteKeycode,
    ctrlKeyCode,
    copyKeyCode,
    pasteKeyCode,
    connectionLineType,
    arrowHeadType,
    maxZoom,
    minZoom,
    nodeTypes,
    formsTypes,
    flowSidebarBlockGroupMinVisibleCount,
    operatorsLabels,
    dynamicDateData,
    pastedElementsDeviation,
    TimeLineDataTableColumnsConfigs,
    formulaToReferenceMapToUIModel,
    referenceInArgumentStringBuilder,
    CJCommunicationAvailabilityConditionConfig,
    mapToUIModel, // TODO: need review / if has any usage
    mapToApiModel,
    dependenciesConfig,
    DynamicTypesRadioGroupOptionsConfig,
};
