import React, { memo, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Modal, Dropdown, Alert, Button } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const SetDefaultLanguageModal = ({ titleLabel, data, defaultValue, onCancel, onOk }) => {
    const { t } = useTranslation();

    const templateData = useSelector((state) => state.template);

    const [language, setLanguage] = useState(() => data.find((item) => item.value === defaultValue));

    const withAlert = useMemo(() => templateData[language.value]?.isDeletable ?? true, [templateData, language]);

    return (
        <Modal
            position="top"
            title={t(l[titleLabel])}
            closable={false}
            onCancel={onCancel}
            closeOnClickOutside={true}
            visible={true}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <Button color="primary" onClick={() => onOk(language.value, withAlert)}>
                        {t(l.Confirm)}
                    </Button>
                </>
            }
        >
            <>
                <Dropdown
                    labelAppearance="swap"
                    value={language.value}
                    onChange={(item) => setLanguage(item)}
                    data={data}
                    hasSearch={true}
                    placeholder={t(l.Language)}
                />
                {withAlert && (
                    <Alert
                        className="set-default-language-warning-message"
                        closable={false}
                        title={t(l.WarningMessage)}
                        message={t(l.SetDefaultLanguageWarningMessage, {
                            languageName: language.label,
                        })}
                        type="warning"
                        iconProps={{ type: 'bc-icon-info' }}
                    />
                )}
            </>
        </Modal>
    );
};

SetDefaultLanguageModal.propTypes = {
    titleLabel: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    defaultValue: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
};

export default memo(SetDefaultLanguageModal);
