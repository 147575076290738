// Import constants
import { ScheduleModalViews } from 'constants/common';

const ScheduleModalView = {
    mainView: {
        key: ScheduleModalViews.mainView,
    },
    hourlyView: {
        key: ScheduleModalViews.hourlyView,
        isVisibleSave: true,
        isVisibleHour: true,
    },
    dailyView: {
        key: ScheduleModalViews.dailyView,
        isVisibleSave: true,
        isVisibleDay: true,
    },
    weeklyView: {
        key: ScheduleModalViews.weeklyView,
        isVisibleSave: true,
        isVisibleWeek: true,
    },
    monthlyView: {
        key: ScheduleModalViews.monthlyView,
        isVisibleSave: true,
        isVisibleDay: true,
        isVisibleMonth: true,
    },
    annuallyView: {
        key: ScheduleModalViews.annuallyView,
        isVisibleSave: true,
        isVisibleDay: true,
        isVisibleMonth: true,
        isVisibleYear: true,
    },
    notRepeatView: {
        key: ScheduleModalViews.notRepeatView,
    },
};

export { ScheduleModalView };
