import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { ExtendedInput, Checkbox, BusyLoader } from '@geneui/components';
// Import Components
import { AdminProviderPartnersInfo } from 'components/AdminProviderList';
// Import Constants
import { l, PageTypes } from 'constants/common';

const CECModalBody = ({ setData, data, type, isLoading }) => {
    const { t } = useTranslation();

    return (
        <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
            {type === PageTypes.edit ? (
                <AdminProviderPartnersInfo partners={data.Partners} />
            ) : (
                <ExtendedInput
                    title={t(l.PartnerId)}
                    label={t(l.PartnerId)}
                    placeholder={t(l.PartnerId)}
                    labelAppearance="swap"
                    type="number"
                    min={0}
                    onChange={(e) => setData({ ...data, PartnerId: e.target.value })}
                    value={data.PartnerId}
                />
            )}
            <ExtendedInput
                title={t(l.Name)}
                label={t(l.Name)}
                placeholder={t(l.Name)}
                labelAppearance="swap"
                type="string"
                onChange={(e) => setData({ ...data, Name: e.target.value })}
                value={data.Name}
            />
            <ExtendedInput
                title={t(l.FromAddress)}
                label={t(l.FromAddress)}
                placeholder={t(l.FromAddress)}
                labelAppearance="swap"
                type="string"
                onChange={(e) => setData({ ...data, FromAddress: e.target.value })}
                value={data.FromAddress}
            />
            <ExtendedInput
                title={t(l.Host)}
                label={t(l.Host)}
                placeholder={t(l.Host)}
                labelAppearance="swap"
                type="string"
                onChange={(e) => setData({ ...data, Host: e.target.value })}
                value={data.Host}
            />
            <ExtendedInput
                title={t(l.Port)}
                label={t(l.Port)}
                placeholder={t(l.Port)}
                labelAppearance="swap"
                type="number"
                min={0}
                onChange={(e) => setData({ ...data, Port: e.target.value })}
                value={data.Port}
            />
            <ExtendedInput
                title={t(l.DelayTimeout)}
                label={t(l.DelayTimeout)}
                placeholder={t(l.DelayTimeout)}
                labelAppearance="swap"
                type="number"
                min={1}
                onChange={(e) => setData({ ...data, DelayTimout: e.target.value })}
                value={data.DelayTimout}
            />
            <ExtendedInput
                title={t(l.Parallelism)}
                label={t(l.Parallelism)}
                placeholder={t(l.Parallelism)}
                labelAppearance="swap"
                type="number"
                min={1}
                max={16}
                onChange={(e) => setData({ ...data, MaxDegreeOfParallelism: e.target.value })}
                value={data.MaxDegreeOfParallelism}
            />
            <ExtendedInput
                title={t(l.UserName)}
                label={t(l.UserName)}
                placeholder={t(l.UserName)}
                labelAppearance="swap"
                type="string"
                onChange={(e) => setData({ ...data, UserName: e.target.value })}
                value={data.UserName}
            />
            <ExtendedInput
                title={t(l.Password)}
                label={t(l.Password)}
                placeholder={t(l.Password)}
                labelAppearance="swap"
                type="string"
                onChange={(e) => setData({ ...data, Password: e.target.value })}
                value={data.Password}
            />
            <div style={{ marginTop: '24px' }}>
                <Checkbox
                    label={t(l.SSL)}
                    checked={data.EnableSsl}
                    onChange={(e) => setData({ ...data, EnableSsl: e.target.checked })}
                />
            </div>
            <ExtendedInput
                title={t(l.Description)}
                label={t(l.Description)}
                placeholder={t(l.Description)}
                labelAppearance="swap"
                type="string"
                onChange={(e) => setData({ ...data, Description: e.target.value })}
                value={data.Description}
            />
            <ExtendedInput
                title={t(l.PartnerEmail)}
                label={t(l.PartnerEmail)}
                placeholder={t(l.PartnerEmail)}
                labelAppearance="swap"
                type="string"
                onChange={(e) => setData({ ...data, PartnerEmail: e.target.value })}
                value={data.PartnerEmail}
            />
            <div style={{ marginTop: '24px' }}>
                <Checkbox
                    label={t(l.NotInjectUnsubscribeLink)}
                    checked={data.NotInjectUnsubscribeLink}
                    onChange={(e) => setData({ ...data, NotInjectUnsubscribeLink: e.target.checked })}
                />
            </div>
        </BusyLoader>
    );
};

CECModalBody.defaultProps = {
    isLoading: false,
};

CECModalBody.propTypes = {
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
};

export default memo(CECModalBody);
