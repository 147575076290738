import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Button, ExtendedInput } from '@geneui/components';
// Import Constants
import { RoutesList } from 'routes';
import { l } from 'constants/common';
import { EMAIL_VALIDATION_REGEXP } from 'constants/regexp';
// Import Hooks
import { useGoToRoute } from 'hooks';

const { CRM_SIGN_IN } = RoutesList;

const selectResetPassword = (state) => state.resetPassword;

const ResetPasswordEmailForm = ({ resetPasswordHandler }) => {
    const { t } = useTranslation();
    const { goToRoute, getRoutePath } = useGoToRoute();
    const signInPagePath = getRoutePath(CRM_SIGN_IN);

    const { crmResetPasswordForm } = useSelector(selectResetPassword);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validateOnChange: true,
        validationSchema: Yup.object({
            email: Yup.string()
                .required(t(l.CannotBeEmpty, { fieldName: t(l.Email) }))
                .matches(EMAIL_VALIDATION_REGEXP, t(l.EmailIsNotInCorrectFormat)),
        }),
        onSubmit: resetPasswordHandler,
    });

    const { values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit } = formik;

    const handleGoToLogInPageClick = (e) => {
        e.preventDefault();
        goToRoute(CRM_SIGN_IN);
    };

    return (
        <form onSubmit={handleSubmit} className="crm-auth-form-card">
            <h2 className="crm-auth-form-line crm-auth-form-title">{t(l.CRM)}</h2>

            <h3 className="crm-auth-form-line crm-auth-form-subtitle">{t(l.ForgotPassword)}</h3>

            <p className="crm-auth-form-line crm-auth-form-info">{t(l.WeWillSendYouResetInstructions)}</p>

            <ExtendedInput
                className="crm-auth-form-line"
                name="email"
                type="text"
                autoComplete="email"
                cornerRadius="smooth-radius"
                isValid={touched.email ? !errors.email : undefined}
                labelAppearance="swap"
                label={t(l.Email)}
                placeholder={t(l.Email)}
                errorText={errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
            />

            <div className="crm-auth-form-line">
                <Button
                    type="submit"
                    size="big"
                    flexibility="full-width"
                    disabled={!(dirty && isValid) || crmResetPasswordForm.isLoading}
                    loading={crmResetPasswordForm.isLoading}
                >
                    {t(l.Send)}
                </Button>
            </div>

            <div className="crm-auth-form-line crm-auth-form-back-button">
                <a
                    href={signInPagePath}
                    className="crm-auth-form-link crm-auth-form-link-back-button tittle-text ellipsis-text"
                    onClick={handleGoToLogInPageClick}
                >
                    {t(l.GoToLogInPage)}
                </a>
            </div>
        </form>
    );
};

ResetPasswordEmailForm.propTypes = {
    resetPasswordHandler: PropTypes.func.isRequired,
};

export default memo(ResetPasswordEmailForm);
