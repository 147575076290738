import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, noop } from 'lodash';
//Import service
import { TemplateHttpService, UtilsHttpService } from 'services/http';
//Import UI components
import { ExtendedInput, Modal, Button, BusyLoader, Icon, Label, Dropdown } from '@geneui/components';
import { ButtonWithLoader } from 'components';
//Import constants
import { DropDownRequestTypes, TestSendToInputElementsConfig } from './constants';
import { DynamicInputTypes, ModalsActions, AlertTypes, l } from 'constants/common';
// Import hooks
import { useRequest, useToaster } from 'hooks';
import { useFormik } from 'formik';

const { success } = AlertTypes;

const TemplateTestSendModal = ({ templateId, templateType, handleClose, isVisible, providers }) => {
    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();
    const { showToaster } = useToaster();
    const formik = useFormik({
        initialValues: TestSendToInputElementsConfig[templateType].initialValues,
        onSubmit: noop,
        validationSchema: TestSendToInputElementsConfig[templateType].validationSchema(t),
    });
    const [isLoading, setIsLoading] = useState(true);
    const [modalViewData, setModalViewData] = useState();
    const [dropDownSettings, setDropDownSettings] = useState();
    const [dynamicInputValues, setDynamicInputValues] = useState();
    const { setFieldValue, submitForm, validateForm, values, errors, touched, setFieldTouched } = formik;
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [providerId, setProviderId] = useState(null);

    const { getTemplateModelsById, testSendTemplate, getDataById } = useRef({
        getTemplateModelsById: TemplateHttpService.getTemplateModelsById(),
        testSendTemplate: TemplateHttpService.testSendTemplate(),
        getDataById: UtilsHttpService.getDataById(),
    }).current;

    const testSendClickHandler = () => {
        submitForm().then(() =>
            validateForm(values).then((formErrors) => {
                if (isEmpty(formErrors)) {
                    const testSendTo = formik.values[TestSendToInputElementsConfig[templateType].fieldName];
                    // fill requst model
                    const requestModel = { TemplateId: templateId, To: testSendTo, MessageSenderProfileId: providerId };
                    // fill Profile info for test send
                    requestModel.Profile = {
                        CustomPropertiesList: modalViewData.reduce(
                            (acc, item) => ({ ...acc, [item.FieldName]: dynamicInputValues[item.FieldName] }),
                            {},
                        ),
                    };
                    // add or override Profile object property based tempalte type like internal clint id (Id)
                    const testSendToFielName = TestSendToInputElementsConfig[templateType].fieldName;
                    requestModel.Profile[testSendToFielName] = testSendTo;
                    setIsButtonLoading(true);
                    doPostRequest(testSendTemplate.request, {
                        requestBody: requestModel,
                        successCallback: (_Data, AlertMessage) => showToaster(success, AlertMessage),
                    }).then(() => {
                        handleClose(ModalsActions.TEST_TEMPLATE);
                        setIsButtonLoading(false);
                    });
                }
            }),
        );
    };

    const inputChangeHandler = (value, fieldName) => {
        setDynamicInputValues({ ...dynamicInputValues, [fieldName]: value });
    };

    const renderDynamicInputElement = (data, index) => {
        return (
            <ExtendedInput
                key={index}
                appearance="outline"
                inputSize="big"
                labelAppearance="swap"
                placeholder={t(data.DisplayNameKey)}
                onChange={(e) => inputChangeHandler(e.target.value, data.FieldName)}
            />
        );
    };

    const renderDynamicDropDownElement = (data, index) => {
        return (
            <Dropdown
                key={index}
                isMultiSelect={false}
                disabled={false}
                hasSearch={true}
                inputSize="default"
                placeholder={t(data.DisplayNameKey)}
                appearance="outline"
                data={dropDownSettings[index]}
                searchPlaceholderText={t(l.Search)}
                labelAppearance="swap"
                noDataText={t(l.NoDataFound)}
                onChange={(e) => inputChangeHandler(e.label, data.FieldName)}
            />
        );
    };

    const renderTypeInputElement = () => {
        const inputConfig = TestSendToInputElementsConfig[templateType];
        return (
            <ExtendedInput
                appearance="outline"
                inputSize="big"
                value={formik.values[inputConfig.fieldName]}
                labelAppearance="swap"
                errorText={touched[inputConfig.fieldName] && errors[inputConfig.fieldName]}
                isValid={!(touched[inputConfig.fieldName] && errors[inputConfig.fieldName])}
                placeholder={t(inputConfig.placeholder)}
                onChange={(e) =>
                    setFieldValue(inputConfig.fieldName, e.target.value, true).then(() =>
                        setFieldTouched(inputConfig.fieldName, true),
                    )
                }
            />
        );
    };

    const renderDynamicInputs = () => {
        return modalViewData.map((data, index) => {
            switch (data.Type) {
                case DynamicInputTypes.INPUT:
                    return renderDynamicInputElement(data, index);
                case DynamicInputTypes.DROP_DOWN:
                    return !isNil(dropDownSettings) && renderDynamicDropDownElement(data, index);
                default:
                    // for not supported types
                    return <span key={index} />;
            }
        });
    };

    const getInputSettings = (data) => {
        return data.map((element) => {
            if (element.Type === DynamicInputTypes.DROP_DOWN) {
                const { TypeConfig } = element;
                if (TypeConfig.RequestType === DropDownRequestTypes.GET) {
                    return doGetRequest(getDataById.request, { queryString: { url: TypeConfig.RequestRoute } });
                }
            }
            return null;
        });
    };

    const mapDropDownSettings = (responses) => {
        // for promise all need has error but case dows not cover
        let hasError = false;
        let dropDownData = responses.map((response) => {
            // for cases when promise is null
            if (isNil(response)) return response;

            const { HasError, Data } = response;
            if (!HasError) {
                return Data.map((row) => ({ label: row.Name, value: row.Id }));
            }
            // case when one of one promise has error
            hasError = true;
            return null;
        });
        return { Data: dropDownData, HasError: hasError };
    };

    const dropdownChangeHandler = (e) => {
        setProviderId(e?.value);
    };

    useEffect(() => {
        if (!isNil(modalViewData)) {
            setDynamicInputValues(modalViewData.reduce((acc, item) => ({ ...acc, [item.FieldName]: null }), {}));
            Promise.all(getInputSettings(modalViewData)).then((responses) => {
                const mapDropDownSettingsData = mapDropDownSettings(responses);
                setDropDownSettings(mapDropDownSettingsData.Data);
                setIsLoading(mapDropDownSettingsData.HasError);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalViewData]);

    const cleanUp = () => {
        return () => {
            setIsLoading(true);
            getTemplateModelsById.cancel();
            getDataById.cancel();
            testSendTemplate.cancel();
        };
    };

    const init = () => {
        setIsLoading(true);
        doGetRequest(getTemplateModelsById.request, {
            queryString: { templateId },
            successCallback: (Data) => {
                const resultData = Data.filter((item) => item.IsVisibleInTestSend);
                // filtering only visible data need
                // is loading seting in useEffect couse need API call
                setModalViewData(resultData);
            },
        }).then(() => setIsLoading(false));
    };

    useEffect(init, []);

    useEffect(cleanUp, []);

    return (
        <>
            <Modal
                closeOnClickOutside={true}
                onCancel={() => handleClose(ModalsActions.CLOSE)}
                background="dark-background"
                position="top"
                className="test-template-modal"
                visible={isVisible}
                closable={false}
                size="content-size"
                title={<>{t(l.TestTemplate)}</>}
                footer={
                    <>
                        <Button appearance="minimal" onClick={() => handleClose(ModalsActions.CLOSE)}>
                            {t(l.Close)}
                        </Button>
                        <ButtonWithLoader
                            color="primary"
                            onClick={testSendClickHandler}
                            isLoading={isButtonLoading}
                            isDisabled={isLoading}
                        >
                            {t(l.SendNow)}
                        </ButtonWithLoader>
                    </>
                }
            >
                <div className="text-icon-block">
                    <Icon type="bc-icon-test-48" />
                    <Label className={'test-title-txt'}>{t(l.TestTempalteDescription)}</Label>
                </div>
                {!isEmpty(providers) && (
                    <Dropdown
                        className="config-modal-dropdown"
                        isMultiSelect={false}
                        hasSearch={true}
                        inputSize="default"
                        placeholder={t(l.Providers)}
                        appearance="outline"
                        data={providers}
                        searchPlaceholderText={t(l.Search)}
                        labelAppearance="swap"
                        noDataText={t(l.NoDataFound)}
                        onChange={dropdownChangeHandler}
                        clearable={true}
                    />
                )}

                <Label className={'test-txt'}>{t(l.SendTo)}</Label>
                <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
                    {renderTypeInputElement()}
                    {!isNil(modalViewData) && !isNil(dynamicInputValues) && renderDynamicInputs()}
                </BusyLoader>
            </Modal>
        </>
    );
};

TemplateTestSendModal.propTypes = {
    templateId: PropTypes.number.isRequired,
    templateType: PropTypes.number.isRequired,
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func,
    providers: PropTypes.array,
};

TemplateTestSendModal.defaultProps = {
    isVisible: false,
    handleClose: noop,
    providers: [],
};

export default memo(TemplateTestSendModal);
