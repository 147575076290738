import { MaintenanceActionTypes as Types } from 'actions/types';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case Types.SET_MAINTENANCE_SETTINGS:
            state = payload;
            return Object.assign({}, state, {});
        case Types.CLEAR_MAINTENANCE_SETTINGS:
            state = {};
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
