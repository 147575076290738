// Import Services
import { subscribe } from 'services/event';
// Import Constants
import { PartnerLanguagesActionTypes as Types } from 'actions/types';
import { GlobalEvents, SubscriberSources } from 'constants/common';

const resetPartnerLanguageList = () => ({
    type: Types.RESET_PARTNER_LANGUAGES,
});

const getPartnerLanguageList = (dispatch, getPartnerLanguages) => {
    return getPartnerLanguages().then(({ Data }) => {
        subscribe(GlobalEvents.PartnerChanged, SubscriberSources.PartnerLanguage, () =>
            dispatch(resetPartnerLanguageList()),
        );

        dispatch({
            type: Types.SET_PARTNER_LANGUAGES,
            payload: Data,
        });
        return Data;
    });
};

export default { getPartnerLanguageList };
