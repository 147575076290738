import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Import Hooks
import useGoToRoute from './GoToRoute';
import useQueryParams from './UseQueryParams';
// Import Actions
import { SegmentListAction, TemplateListAction, CustomerJourneyListAction, ReportListAction } from 'actions';
// Import Services
import { Helpers } from 'services';
// Import Configs
import { FilterHolderFieldsConfigs as SegmentConfigs } from 'pages/SegmentListPage/configs';
import { FilterHolderFieldsConfigs as TemplateConfigs } from 'pages/TemplateListPage/configs';
import { FilterHolderFieldsConfigs as CustomerJourneyConfigs } from 'pages/CustomerJourneyListPage/configs';
import { FilterHolderFieldsConfigs as ReportConfigs } from 'pages/ReportListPage/configs';
// Import Constants
import { RoutesList } from 'routes';
import {
    CustomerJourneyStatusLabels,
    MonthDayYearFormat,
    l,
    RealtimePromotionTypes,
    SegmentTypeIcons,
    TemplateTypeIcons,
    ReportTypeIcons,
    RealtimePromotionTypeIcons,
    ObjectTypeIds,
    ReportTypesLabels,
} from 'constants/common';

// Actions
const { setSegmentListTableFilters } = SegmentListAction;
const { setTemplateListTableFilters } = TemplateListAction;
const { setCustomerJourneyListTableFilters } = CustomerJourneyListAction;
const { setReportListTableFilters } = ReportListAction;

const { getFilterHolderKey, generateRequestFilterField } = Helpers;

const ObjectTypeIcons = {
    [ObjectTypeIds.Segment]: ({ Type }) => SegmentTypeIcons[Type],
    [ObjectTypeIds.Template]: ({ DeliveryMethod }) => TemplateTypeIcons[DeliveryMethod],
    [ObjectTypeIds.Campaign]: () => 'bc-icon-campaign',
    [ObjectTypeIds.RealtimePromotion]: ({ PromotionType }) => RealtimePromotionTypeIcons[PromotionType],
    [ObjectTypeIds.Report]: ({ ReportType }) => ReportTypeIcons[ReportType],
};

const generateFilterModel = (filter, defaultConfigs) => {
    const defaultFilters = defaultConfigs.defaultInputsData.defaultInputsDataList;

    return defaultFilters.map(({ valueFieldKey, comparison }) =>
        generateRequestFilterField(valueFieldKey, comparison, filter[valueFieldKey]),
    );
};

const createDispatch = (configs, action) => (id, dispatch) => {
    const filter = generateFilterModel({ [configs.idFieldName]: id.toString() }, configs);

    dispatch(action(filter));
};

const dispatchSegment = createDispatch(SegmentConfigs, setSegmentListTableFilters);
const dispatchTemplate = createDispatch(TemplateConfigs, setTemplateListTableFilters);
const dispatchCustomerJourney = createDispatch(CustomerJourneyConfigs, setCustomerJourneyListTableFilters);
const dispatchReport = createDispatch(ReportConfigs, setReportListTableFilters);

const getRowAction = (data) => {
    const { row, goToRoute, encode, partnerId, setOpenedModalData, modalsStateSetDict, closePopover, dispatch, t } =
        data;

    switch (row.ObjectTypeId) {
        case ObjectTypeIds.Segment:
            return [
                {
                    icon: 'bc-icon-new-window',
                    name: t(l.ViewSegment),
                    description: t(l.ViewSegment),
                    onClick: () => {
                        closePopover && closePopover();
                        goToRoute(
                            RoutesList.SEGMENT_LIST,
                            null,
                            encode({
                                [getFilterHolderKey(l.SegmentList)]: {
                                    SegmentId: row.ObjectId.toString(),
                                },
                                partnerId,
                            }),
                        );
                        dispatchSegment(row.ObjectId, dispatch);
                    },
                },
                {
                    icon: 'bc-icon-info',
                    name: t(l.SegmentInfo),
                    description: t(l.SegmentInfo),
                    onClick: () => {
                        closePopover && closePopover();
                        setOpenedModalData({
                            segment: {
                                id: row.ObjectId.toString(),
                                type: row.ListItem?.Type,
                                definitionType: row.ListItem?.DefinitionType,
                                targetObject: row.ListItem?.TargetObject,
                            },
                        });

                        modalsStateSetDict.infoSegment.fn(true);
                    },
                },
            ];
        case ObjectTypeIds.Template:
            return [
                {
                    icon: 'bc-icon-new-window',
                    name: t(l.ViewTemplate),
                    description: t(l.ViewTemplate),
                    onClick: () => {
                        closePopover && closePopover();
                        goToRoute(
                            RoutesList.TEMPLATE_LIST,
                            null,
                            encode({
                                [getFilterHolderKey(l.TemplateList)]: {
                                    TemplateId: row.ObjectId.toString(),
                                },
                                partnerId,
                            }),
                        );
                        dispatchTemplate(row.ObjectId, dispatch);
                    },
                },
                {
                    icon: 'bc-icon-info',
                    name: t(l.TemplateInfo),
                    description: t(l.TemplateInfo),
                    onClick: () => {
                        closePopover && closePopover();
                        setOpenedModalData({
                            template: {
                                id: row.ObjectId.toString(),
                                childItemsInfo: row.ListItem?.ChildItemsInfo,
                                isGlobal: row.ListItem?.IsGlobal,
                            },
                        });
                        modalsStateSetDict.infoTemplate.fn(true);
                    },
                },
            ];
        case ObjectTypeIds.Campaign:
            return [
                {
                    icon: 'bc-icon-new-window',
                    name: t(l.ViewCampaign),
                    description: t(l.ViewCampaign),
                    onClick: () => {
                        closePopover && closePopover();
                        goToRoute(RoutesList.CAMPAIGN_REPORT, { campaignId: row.ObjectId.toString() });
                    },
                },
                {
                    icon: 'bc-icon-info',
                    name: t(l.CampaignInfo),
                    description: t(l.CampaignInfo),
                    onClick: () => {
                        closePopover && closePopover();
                        setOpenedModalData({
                            campaign: {
                                id: row.ObjectId.toString(),
                            },
                        });

                        modalsStateSetDict.infoCampaign.fn(true);
                    },
                },
            ];
        case ObjectTypeIds.RealtimePromotion: {
            const isViewPageAvailable = ![
                +CustomerJourneyStatusLabels.Updating,
                +CustomerJourneyStatusLabels.Starting,
                +CustomerJourneyStatusLabels.Resuming,
                +CustomerJourneyStatusLabels.Suspending,
                +CustomerJourneyStatusLabels.Invalid,
            ].includes(row.ListItem?.Status);

            return [
                {
                    icon: 'bc-icon-new-window',
                    name: t(l.ViewRealtimePromotion),
                    description: t(l.ViewRealtimePromotion),
                    onClick: () => {
                        closePopover && closePopover();
                        if (isViewPageAvailable) {
                            const route =
                                row.ListItem?.PromotionType === +RealtimePromotionTypes.ActionCampaign
                                    ? RoutesList.REALTIME_ACTION_VIEW
                                    : RoutesList.REALTIME_JOURNEY_VIEW;

                            goToRoute(route, {
                                workflowId: row.ObjectId.toString(),
                            });
                        } else {
                            goToRoute(
                                RoutesList.REALTIME_PROMOTIONS_LIST,
                                null,
                                encode({
                                    [getFilterHolderKey(l.JourneyPage)]: {
                                        WorkflowId: row.ObjectId.toString(),
                                    },
                                    partnerId,
                                }),
                            );
                            dispatchCustomerJourney(row.ObjectId, dispatch);
                        }
                    },
                },
                {
                    icon: 'bc-icon-info',
                    name: t(l.CJInfo),
                    description: t(l.CJInfo),
                    onClick: () => {
                        closePopover && closePopover();
                        setOpenedModalData({
                            realtimePromotion: {
                                id: row.ObjectId.toString(),
                                promotionType: row.ListItem?.PromotionType,
                                hasVisualization: row.ListItem?.HasVisualization,
                            },
                        });

                        modalsStateSetDict.infoRealtimePromotion.fn(true);
                    },
                },
            ];
        }
        case ObjectTypeIds.Report: {
            const isViewPageAvailable = row.ListItem?.SelfExecutionCount > 0;
            const searchActions = [
                {
                    icon: 'bc-icon-new-window',
                    name: t(l.ViewReport),
                    description: t(l.ViewReport),
                    onClick: () => {
                        closePopover && closePopover();
                        if (isViewPageAvailable) {
                            goToRoute(RoutesList.REPORT_EXECUTED_LIST, {
                                reportId: row.ObjectId.toString(),
                                reportType: row.ListItem?.ReportType,
                            });
                        } else {
                            goToRoute(
                                RoutesList.REPORT_LIST,
                                null,
                                encode({
                                    [getFilterHolderKey(l.ReportList)]: {
                                        ReportId: row.ObjectId.toString(),
                                    },
                                    partnerId,
                                }),
                            );
                            dispatchReport(row.ObjectId, dispatch);
                        }
                    },
                },
            ];

            if (row.ListItem?.ReportType !== ReportTypesLabels.CustomReport) {
                searchActions.push({
                    icon: 'bc-icon-info',
                    name: t(l.ReportInfo),
                    description: t(l.ReportInfo),
                    onClick: () => {
                        closePopover && closePopover();
                        setOpenedModalData({
                            report: {
                                ReportId: row.ObjectId.toString(),
                                isViewMode: true,
                                ReportType: row.ListItem?.ReportType,
                                ReportState: row.ListItem?.State,
                                SelfExecutionCount: row.ListItem?.SelfExecutionCount,
                                CompositeExecutionCount: row.ListItem?.CompositeExecutionCount,
                            },
                        });

                        modalsStateSetDict.infoReport.fn(true);
                    },
                });
            }

            return searchActions;
        }
        default:
            break;
    }
};

const mapToUIModel = ({
    data,
    goToRoute,
    encode,
    partnerId,
    setOpenedModalData,
    modalsStateSetDict,
    closePopover,
    dispatch,
    t,
}) => {
    return (data || []).map((row) => ({
        id: String(row.ObjectId),
        rowData: row,
        icon: ObjectTypeIcons[row.ObjectTypeId](row.ListItem),
        title: row.Name,
        name: row.ModifiedByName,
        actions: getRowAction({
            row,
            goToRoute,
            encode,
            partnerId,
            setOpenedModalData,
            modalsStateSetDict,
            closePopover,
            dispatch,
            t,
        }),
        date: {
            date: moment(row.ModifiedDate).format(MonthDayYearFormat),
            labelText: t(l.ModifiedDate),
        },
    }));
};

const selectPartnerId = (state) => state.partnerSettings.PartnerId;

const useSearchRedirectionAndModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const partnerId = useSelector(selectPartnerId);
    const { goToRoute } = useGoToRoute();
    const { encode } = useQueryParams();

    const [openedModalData, setOpenedModalData] = useState({});
    const [isSegmentInfoModalOpen, setIsSegmentInfoModalOpen] = useState(false);
    const [isTemplateInfoModalOpen, setIsTemplateInfoModalOpen] = useState(false);
    const [isCampaignInfoModalOpen, setIsCampaignInfoModalOpen] = useState(false);
    const [isRealtimePromotionInfoModalOpen, setIsRealtimePromotionInfoModalOpen] = useState(false);
    const [isReportInfoModalOpen, setIsReportInfoModalOpen] = useState(false);

    const modalsStateSetDict = useMemo(
        () => ({
            infoSegment: {
                key: 'infoSegment',
                fn: setIsSegmentInfoModalOpen,
            },
            infoTemplate: {
                key: 'infoTemplate',
                fn: setIsTemplateInfoModalOpen,
            },
            infoCampaign: {
                key: 'infoCampaign',
                fn: setIsCampaignInfoModalOpen,
            },
            infoRealtimePromotion: {
                key: 'infoRealtimePromotion',
                fn: setIsRealtimePromotionInfoModalOpen,
            },
            infoReport: {
                key: 'infoReport',
                fn: setIsReportInfoModalOpen,
            },
        }),
        [],
    );

    const addActionsToData = useCallback(
        ({ data, closePopover }) => {
            return mapToUIModel({
                data,
                goToRoute,
                encode,
                partnerId,
                setOpenedModalData,
                modalsStateSetDict,
                closePopover,
                dispatch,
                t,
            });
        },
        [dispatch, encode, goToRoute, modalsStateSetDict, partnerId, t],
    );

    return {
        modalsStateSetDict,
        openedModalData,
        isSegmentInfoModalOpen,
        isTemplateInfoModalOpen,
        isCampaignInfoModalOpen,
        isRealtimePromotionInfoModalOpen,
        isReportInfoModalOpen,

        addActionsToData,
    };
};

export default useSearchRedirectionAndModal;
