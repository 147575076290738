import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
// Import UI Components
import { PieChart, BusyLoader, Empty } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const CampaignDataChart = ({ isLoading, data, title }) => {
    const { t } = useTranslation();
    const { anotherCount, successCount } = data;

    return (
        <div className="report-visualization">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name">{title}</div>
                        </div>
                    </div>
                </li>
            </ul>

            {isLoading ? (
                <BusyLoader spinnerSize="big" isBusy={true} />
            ) : isNumber(successCount) && isNumber(anotherCount) ? (
                <PieChart
                    decimalNumberPrecision={1}
                    fixedTooltipContent={''}
                    data={[
                        {
                            y: successCount,
                            color: '#01ecc5',
                            name: t(l.Success),
                        },
                        {
                            y: anotherCount,
                            color: '#fa0c54',
                            name: t(l.Failed),
                        },
                    ]}
                />
            ) : (
                <Empty title={t(l.NoDataToDisplay)} />
            )}
        </div>
    );
};

CampaignDataChart.propTypes = {
    data: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
};

CampaignDataChart.defaultProps = {
    isLoading: false,
    title: '',
};

export default memo(CampaignDataChart);
