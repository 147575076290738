import { PartnerLanguagesActionTypes as Types } from 'actions/types';

export default (state = {}, { payload, type }) => {
    switch (type) {
        case Types.SET_PARTNER_LANGUAGES: {
            const result = payload.reduce((acc, data) => {
                acc[data.Id] = data;
                return acc;
            }, {});

            return Object.assign({}, { ...result });
        }
        case Types.RESET_PARTNER_LANGUAGES: {
            return {};
        }
        default:
            return state;
    }
};
