// Import Constants
import { DateToISOStringFormat } from 'constants/common';

const getStartDate = (dateValue) => {
    return !dateValue?.toISOString ? dateValue : dateValue.format(DateToISOStringFormat);
};

const getEndDate = (dateValue) => {
    return !dateValue?.toISOString ? dateValue : dateValue.startOf('day').format(DateToISOStringFormat);
};

export { getStartDate, getEndDate };
