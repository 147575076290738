import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import Hooks
import { useData } from 'hooks';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
import CJListMenuItemDropDownInfoBlock from './CJListMenuItemDropDownInfoBlock';
// Import Constants
import { l } from 'constants/common';

const CJListInfoBlock = ({ data, customAttributes, option }) => {
    const { t } = useTranslation();
    const dataValue = useData(data);

    return (
        <>
            {!isNil(customAttributes?.MenuItemId) ? (
                <CJListMenuItemDropDownInfoBlock data={dataValue} customAttributes={customAttributes} option={option} />
            ) : (
                (dataValue?.value ?? []).map((item, i) => {
                    return (
                        <CJInfoBlockLabelValueWrapper key={i} label={t(l.OptionValue)} value={item} isLoading={false} />
                    );
                })
            )}
        </>
    );
};

CJListInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(CJListInfoBlock);
