import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useGACustomEvent, useGoToRoute, useQueryParams } from 'hooks';
// Import UI components
import { Tooltip } from '@geneui/components';
// Import Constants
import { GACategoryEvents } from 'constants/common';

const { GADetailsCategory } = GACategoryEvents;

const TittleLink = ({ path, name, queryParams, isClickable, tooltipConfig }) => {
    const { goToRoute, getRoutePath } = useGoToRoute();
    const { search } = useLocation();
    const { logCustomEvent } = useGACustomEvent();
    const goToRouteQueryParams = useQueryParams();

    const buttonOnClick = useCallback(
        (e) => {
            e.preventDefault();
            logCustomEvent(
                GADetailsCategory.name,
                GADetailsCategory.events[`${path.name}TitleDetails`],
                'open details from name',
                1,
            );
            goToRoute(path, queryParams, goToRouteQueryParams.encode({ backParams: search }));
        },
        [goToRoute, logCustomEvent, path, queryParams, goToRouteQueryParams, search],
    );

    const link = useMemo(
        () => (isClickable ? getRoutePath(path, queryParams, null) : null),
        [getRoutePath, path, queryParams, isClickable],
    );

    return (
        <Tooltip text={name} {...tooltipConfig}>
            {isClickable ? (
                <a className="tittle-text ellipsis-text" onClick={buttonOnClick} href={link}>
                    {name}
                </a>
            ) : (
                <span className="ellipsis-text">{name}</span>
            )}
        </Tooltip>
    );
};

TittleLink.propTypes = {
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    queryParams: PropTypes.string,
    isClickable: PropTypes.bool,
    tooltipConfig: PropTypes.object,
};

TittleLink.defaultProps = {
    isClickable: true,
    tooltipConfig: { position: 'bottom' },
};

export default memo(TittleLink);
