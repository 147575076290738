import { l } from 'constants/common';
import { RoutesList } from 'routes';

const {
    SEGMENT_LIST,
    TEMPLATE_LIST,
    CAMPAIGN_LIST,
    REPORT_LIST,
    REALTIME_PROMOTIONS_LIST,
    CONFIGURATION_NOTIFICATIONS,
    CONFIGURATION,
} = RoutesList;

const dynamicSegmentMetaData = {
    title: l.SaveDynamicSegment,
    pageName: l.SegmentList,
    route: SEGMENT_LIST,
};

const compositeSegmentMetaData = {
    title: l.SaveCompositeSegment,
    pageName: l.SegmentList,
    route: SEGMENT_LIST,
};

const staticSegmentMetaData = {
    title: l.SaveStaticSegment,
    pageName: l.SegmentList,
    route: SEGMENT_LIST,
};

const emailTemplateMetaData = {
    title: l.SaveEmailTemplete,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const dynamicPopUpTemplateMetaData = {
    title: l.SaveDynamicPopUpTemplate,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const smsTemplateMetaData = {
    title: l.SaveSMSTemplete,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const hooryTemplateMetaData = {
    title: l.SaveHooryTemplate,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const fastexNotificationTemplateMetaData = {
    title: l.SaveFastexNotificationTemplate,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const internalMessageTemplateMetaData = {
    title: l.SaveInternalMessageTemplete,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const telegramTemplateMetaData = {
    title: l.SaveTelegramTemplete,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const campaignMetaData = {
    title: l.SaveCampaign,
    pageName: l.CampaignList,
    route: CAMPAIGN_LIST,
};

const customerJourneyMetaData = {
    title: l.SaveCustomerJourney,
    pageName: l.RealtimePromotionsList,
    route: REALTIME_PROMOTIONS_LIST,
};

const reportMetaData = {
    title: l.SaveReport,
    pageName: l.ReportList,
    route: REPORT_LIST,
};

const reportExecuteMetaData = {
    title: l.ExecuteReport,
    pageName: l.ReportList,
    route: REPORT_LIST,
};

const reportScheduleMetaData = {
    title: l.ScheduleReport,
    pageName: l.ReportList,
    route: REPORT_LIST,
};

const pushNotificationTemplateMetaData = {
    title: l.SavePushNotificationTemplate,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const customerJourneyVisualizationMetaData = {
    title: l.SaveCustomerJourneyVisualization,
    pageName: l.RealtimePromotionsList,
    route: REALTIME_PROMOTIONS_LIST,
};

const popUpNotificationTemplateMetaData = {
    title: l.SavePopUpNotificationTemplate,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const cmsPopUpNotificationTemplateMetaData = {
    title: l.SaveCMSPopUpNotificationTemplate,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const NotificationCampaign = {
    title: l.SaveNotification,
    pageName: l.NotificationsList,
    route: CONFIGURATION_NOTIFICATIONS,
};

const templateLanguages = {
    title: l.TemplateLanguages,
    pageName: l.TemplateLanguages,
    route: CONFIGURATION,
};

const compositeReportMetaData = {
    title: l.SaveCompositeReport,
    pageName: l.ReportList,
    route: REPORT_LIST,
};

const customerJourneyReportMetaData = {
    title: l.SaveCustomerJourneyReport,
    pageName: l.ReportList,
    route: REPORT_LIST,
};

const viberTemplateMetaData = {
    title: l.SaveViberTemplate,
    pageName: l.TemplateList,
    route: TEMPLATE_LIST,
};

const realtimeActionCampaignMetaData = {
    title: l.SaveRealtimeActionCampaign,
    pageName: l.RealtimePromotionsList,
    route: REALTIME_PROMOTIONS_LIST,
};

const redirectMetaData = {
    CreateDynamicSegment: dynamicSegmentMetaData,
    CloneDynamicSegment: dynamicSegmentMetaData,
    EditDynamicSegment: dynamicSegmentMetaData,
    PredefinedDynamicSegment: dynamicSegmentMetaData,
    CreateStaticSegment: staticSegmentMetaData,
    CreateCompositeSegment: compositeSegmentMetaData,
    CloneCompositeSegment: compositeSegmentMetaData,
    EditCompositeSegment: compositeSegmentMetaData,
    CreateNonCustomerSegment: staticSegmentMetaData,
    CreateEmailTemplate: emailTemplateMetaData,
    CloneEmailTemplate: emailTemplateMetaData,
    EditEmailTemplate: emailTemplateMetaData,
    PredefinedEmailTemplate: emailTemplateMetaData,
    CreateDynamicPopUpTemplate: dynamicPopUpTemplateMetaData,
    CloneDynamicPopUpTemplate: dynamicPopUpTemplateMetaData,
    EditDynamicPopUpTemplate: dynamicPopUpTemplateMetaData,
    PredefinedDynamicPopUpTemplate: dynamicPopUpTemplateMetaData,
    CreateSMSTemplate: smsTemplateMetaData,
    CloneSMSTemplate: smsTemplateMetaData,
    EditSMSTemplate: smsTemplateMetaData,
    PredefinedSMSTemplate: smsTemplateMetaData,
    CreateHooryTemplate: hooryTemplateMetaData,
    CloneHooryTemplate: hooryTemplateMetaData,
    EditHooryTemplate: hooryTemplateMetaData,
    PredefinedHooryTemplate: hooryTemplateMetaData,
    CreateFastexNotificationTemplate: fastexNotificationTemplateMetaData,
    CloneFastexNotificationTemplate: fastexNotificationTemplateMetaData,
    EditFastexNotificationTemplate: fastexNotificationTemplateMetaData,
    PredefinedFastexNotificationTemplate: fastexNotificationTemplateMetaData,
    CreateInternalMessageTemplate: internalMessageTemplateMetaData,
    CloneInternalMessageTemplate: internalMessageTemplateMetaData,
    EditInternalMessageTemplate: internalMessageTemplateMetaData,
    PredefinedInternalMessageTemplate: internalMessageTemplateMetaData,
    CreateTelegramTemplate: telegramTemplateMetaData,
    CloneTelegramTemplate: telegramTemplateMetaData,
    EditTelegramTemplate: telegramTemplateMetaData,
    PredefinedTelegramTemplate: telegramTemplateMetaData,
    CreateCampaign: campaignMetaData,
    EditCampaign: campaignMetaData,
    CloneCampaign: campaignMetaData,
    CreateCustomerJourney: customerJourneyMetaData,
    EditCustomerJourney: customerJourneyMetaData,
    CloneCustomerJourney: customerJourneyMetaData,
    PredefinedCustomerJourney: customerJourneyMetaData,
    CreateReport: reportMetaData,
    EditReport: reportMetaData,
    CloneReport: reportMetaData,
    CreateReportFromSegment: reportMetaData,
    CreatePushNotificationTemplate: pushNotificationTemplateMetaData,
    ClonePushNotificationTemplate: pushNotificationTemplateMetaData,
    EditPushNotificationTemplate: pushNotificationTemplateMetaData,
    PredefinedPushNotificationTemplate: pushNotificationTemplateMetaData,
    CreateFromPredefinedCustomerJourney: customerJourneyMetaData,
    CreateCustomerJourneyVisualization: customerJourneyVisualizationMetaData,
    EditCustomerJourneyVisualization: customerJourneyVisualizationMetaData,
    CreatePopUpNotificationTemplate: popUpNotificationTemplateMetaData,
    EditPopUpNotificationTemplate: popUpNotificationTemplateMetaData,
    ClonePopUpNotificationTemplate: popUpNotificationTemplateMetaData,
    PredefinedPopUpNotificationTemplate: popUpNotificationTemplateMetaData,
    CreateCMSPopUpNotificationTemplate: cmsPopUpNotificationTemplateMetaData,
    EditCMSPopUpNotificationTemplate: cmsPopUpNotificationTemplateMetaData,
    CloneCMSPopUpNotificationTemplate: cmsPopUpNotificationTemplateMetaData,
    NotificationCampaign: NotificationCampaign,
    ScheduleReport: reportScheduleMetaData,
    ExecuteReport: reportExecuteMetaData,
    CreateCompositeReport: compositeReportMetaData,
    CloneCompositeReport: compositeReportMetaData,
    EditCompositeReport: compositeReportMetaData,
    CreateCustomerJourneyReport: customerJourneyReportMetaData,
    EditCustomerJourneyReport: customerJourneyReportMetaData,
    CloneCustomerJourneyReport: customerJourneyReportMetaData,
    CreateViberTemplate: viberTemplateMetaData,
    EditViberTemplate: viberTemplateMetaData,
    CloneViberTemplate: viberTemplateMetaData,
    TemplateLanguages: templateLanguages,
    CreateActionCampaign: realtimeActionCampaignMetaData,
    EditActionCampaign: realtimeActionCampaignMetaData,
    CloneActionCampaign: realtimeActionCampaignMetaData,
};

export { redirectMetaData };
