import { DynamicSegmentActionsTypes as Types } from 'actions/types';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case Types.SET_INFO_FILTER_LIST: {
            return { ...payload };
        }
        case Types.CLEAR_INFO_FILTER_CONTAINER:
            return {};
        default:
            return state;
    }
};
