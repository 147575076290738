import UIHashBaseContainer from './UIHashBaseContainer';

export default class UIHashGroupContainer extends UIHashBaseContainer {
    constructor() {
        super();
        this._nextItems = [];
    }
    get nextItems() {
        return this._nextItems;
    }
    addNextItem(item) {
        this._nextItems.push(item);
    }
}
