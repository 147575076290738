import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
//Import UI components
import { Tabs, Tab } from '@geneui/components';
// Import Components
import { KeyValueItemsGroup } from 'components';

const CompositeReportGeneralInfoTabs = ({ generalInfo }) => (
    <div className="composite-report-general-info">
        {!isNil(generalInfo) && (
            <Tabs type="box" position="top">
                {generalInfo.map((item) => {
                    return (
                        <Tab key={item.key} title={item.title}>
                            <KeyValueItemsGroup itemsGroup={[item]} />
                        </Tab>
                    );
                })}
            </Tabs>
        )}
    </div>
);

CompositeReportGeneralInfoTabs.propTypes = {
    generalInfo: PropTypes.object.isRequired,
};

export default memo(CompositeReportGeneralInfoTabs);
