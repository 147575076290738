import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Components
import { ValidatableDatePickerInput } from 'components';
// Import Constants
import { dateTimeInitialValueCreator } from './config';
import { DateTimeWithoutSecondsFormat } from 'constants/common';

const DateTimeForm = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: dateTimeInitialValueCreator(defaultValue, option?.Required),
        onSubmit: noop,
        validationSchema: null, // CJDateTimeOptionValidationSchema(option),
    });
    const { values, setFieldValue, errors } = formik;
    const { date } = values;

    const dateChangeHandler = (e) => {
        const tmpDate = moment(e);
        setFieldValue('date', e);
        getUpdate({
            dates: tmpDate.date(),
            years: tmpDate.year(),
            months: tmpDate.month(),
            hours: tmpDate.hour(),
            minutes: tmpDate.minute(),
        });
    };

    const datePickerBlurHandler = () => {
        if (isNil(date)) {
            dateChangeHandler(dateTimeInitialValueCreator(null, option?.Required).date);
        }
    };

    useEffect(() => {
        const tmpDate = moment(date);

        getUpdate({
            dates: tmpDate.date(),
            years: tmpDate.year(),
            months: tmpDate.month(),
            hours: tmpDate.hour(),
            minutes: tmpDate.minute(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ValidatableDatePickerInput
                isTouched={errors.date}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.date)}
                format={DateTimeWithoutSecondsFormat}
                withTime
                onChange={dateChangeHandler}
                value={date}
                onBlur={datePickerBlurHandler}
            />
        </>
    );
};

DateTimeForm.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(DateTimeForm);
