import React from 'react';
import PropTypes from 'prop-types';
import { keys, noop } from 'lodash';
// Import Components
import { CustomerJourneyVisualizationTemplate } from 'components';

const CustomerJourneyVisualizationTemplateGroup = ({ templates, onChange }) => {
    const templateChangeHandlerCreator = (templateKey) => {
        return (data) => {
            onChange(templateKey, data);
        };
    };

    return (
        <div className="cj-visual-info-wrapper">
            {keys(templates).map((templateKey, index) => {
                return (
                    <div className="cj-visual-info-row" key={index}>
                        <CustomerJourneyVisualizationTemplate
                            key={`${index}_${templateKey}`}
                            value={templates[templateKey]}
                            onChange={templateChangeHandlerCreator(templateKey)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

CustomerJourneyVisualizationTemplateGroup.propTypes = {
    templates: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};

CustomerJourneyVisualizationTemplateGroup.defaultProps = {
    onChange: noop,
};

export default CustomerJourneyVisualizationTemplateGroup;
