import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Components
import { ValidatableDatePickerInput } from 'components';
// Import Constants
import { dateInitialValueCreator } from './config';

const DateForm = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: dateInitialValueCreator(defaultValue, option?.Required),
        onSubmit: noop,
        validationSchema: null, // CJDateTimeOptionValidationSchema(option),
    });
    const { values, setFieldValue, errors } = formik;
    const { date } = values;

    const dateChangeHandler = (e) => {
        const tmpDate = moment(e);
        setFieldValue('date', e);
        getUpdate({ dates: tmpDate.date(), years: tmpDate.year(), months: tmpDate.month() });
    };

    const datePickerBlurHandler = () => {
        if (isNil(date)) {
            dateChangeHandler(dateInitialValueCreator(null, option?.Required).date);
        }
    };

    useEffect(() => {
        const tmpDate = moment(date);
        getUpdate({
            dates: tmpDate.date(),
            years: tmpDate.year(),
            months: tmpDate.month(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ValidatableDatePickerInput
                isTouched={errors.date}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.date)}
                onChange={dateChangeHandler}
                value={date}
                onBlur={datePickerBlurHandler}
            />
        </>
    );
};

DateForm.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object,
};

export default memo(DateForm);
