import React, { memo, useRef, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { first, isNil, noop } from 'lodash';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { Helpers } from 'services';
import { UtilsHttpService, ClientTagHttpService } from 'services/http';
// Import UI components
import { Dropdown, Icon, Tooltip } from '@geneui/components';
// Import Components
import { ButtonWithLoader, UploadFile } from 'components';
// Import Constants
import { l, AlertTypes } from 'constants/common';

const MAX_FILE_SIZE = 5242880; //5MB
const VALID_FILE_TYPES = ['xls', 'xlsx'];

const { readFileAsync } = Helpers;
const { warning } = AlertTypes;

const ClientsFileUploader = ({ onFileUploaded, onFileUploading }) => {
    const { t } = useTranslation();

    const { showToaster } = useToaster();
    const fileRef = useRef(null);
    const uploadClientList = useRef();

    const [clientFields, setClientFields] = useState([]);
    const [file, setFile] = useState(null);
    const [clientFieldId, setClientFieldId] = useState(null);
    const [isDisableUploadBtn, setIsDisableUploadBtn] = useState(true);
    const [isDisableFormFields, setIsDisableFormFields] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { doGetRequest, doPostRequest } = useRequest();

    const { getClientFields, uploadClientListCreator } = useMemo(
        () => ({
            getClientFields: UtilsHttpService.getClientFields(),
            uploadClientListCreator: ClientTagHttpService.uploadClientList,
        }),
        [],
    );

    const isValidFileType = (fileName) => {
        return fileName && VALID_FILE_TYPES.indexOf(fileName.split('.').pop()) > -1;
    };

    const formik = useFormik({
        initialValues: { fileData: { name: '', size: 0 } },
        onSubmit: noop,
        validationSchema: Yup.object({
            fileData: Yup.mixed()
                .test('type', t(l.WrongFileFormat), (value) => isValidFileType(value && value.name.toLowerCase()))
                .test('size', t(l.WrongFileSize), (value) => value && value.size <= MAX_FILE_SIZE),
        }),
    });

    const { setFieldValue, errors, values, isValid } = formik;
    const { fileData } = values;

    const onFormSubmit = (e) => {
        e.preventDefault();

        setIsDisableUploadBtn(true);
        setIsLoading(true);
        onFileUploading(true);
        setIsDisableFormFields(true);
        readFileAsync(file)
            .then(
                (fileData) => {
                    const requestBody = {
                        file: {
                            Buffer: [...fileData],
                            FileName: file.name,
                            MediaType: file.type,
                        },
                        ClientFieldId: clientFieldId,
                    };
                    if (!isNil(uploadClientList.current)) {
                        uploadClientList.current.cancel('ManageTagsFromFileModal:uploadClientList');
                    }
                    uploadClientList.current = uploadClientListCreator();
                    doPostRequest(uploadClientList.current.request, {
                        requestBody,
                        successCallback: (data) => {
                            onFileUploaded(data);
                            setIsLoading(false);
                            onFileUploading(false);
                        },
                        errorCallback: () => {
                            setIsDisableUploadBtn(false);
                            setIsDisableFormFields(false);
                            setIsLoading(false);
                            onFileUploading(false);
                        },
                    }).then(({ AlertMessage, AlertType }) => {
                        if (AlertType === AlertTypes.warning) {
                            showToaster(warning, AlertMessage);
                        }
                    });
                },
                (error) => {
                    setIsLoading(false);
                    onFileUploading(false);
                    setIsDisableUploadBtn(false);
                    setIsDisableFormFields(false);
                    console.log(error.message);
                },
            )
            .catch((error) => {
                setIsLoading(false);
                onFileUploading(false);
                setIsDisableUploadBtn(false);
                console.log(error.message);
            });
    };

    useEffect(() => {
        setIsDisableUploadBtn(isNil(file) || isNil(clientFieldId) || !isValid);
    }, [clientFieldId, isValid, file]);

    const fileInputChangeHandler = (e) => {
        const { files, value } = e.currentTarget;
        setFile(first(files));
        const tmpFileName = !value ? null : value;
        setFieldValue('fileData', { name: tmpFileName, size: first(files).size });
    };

    const clientFieldsChangeHandler = (e) => {
        setClientFieldId(e.value);
    };

    const initClientFields = () => {
        const mapClientFields = (data) =>
            data.map((clientField) => ({ label: clientField.Name, value: clientField.Id }));
        doGetRequest(getClientFields.request, {
            successCallback: (Data) => {
                const resultClientFieldData = mapClientFields(Data);
                setClientFields(resultClientFieldData);
                const selectedFieldValue = first(resultClientFieldData)?.value;
                setClientFieldId(selectedFieldValue);
            },
        });
    };

    const init = () => {
        initClientFields();
    };

    const cleanUp = () => {
        return () => {
            getClientFields.cancel('ManageTagsFromFileModal:getClientFields');
            if (!isNil(uploadClientList.current)) {
                uploadClientList.current.cancel('ManageTagsFromFileModal:uploadClientList');
            }
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <div className="clients-file-uploader">
            <form onSubmit={onFormSubmit} className="form">
                <div className="file-browser">
                    <UploadFile
                        onChange={fileInputChangeHandler}
                        value={fileData.name}
                        disabled={isDisableFormFields}
                        errorText={errors.fileData}
                        inputRef={fileRef}
                        acceptedExtensions={'.xls,.xlsx'}
                    />
                </div>
                <Tooltip
                    text={t(l.TooltipManageTagClientsUploadFile, { maxClientCount: 10000, maxFileSize: 5 })}
                    position="bottom"
                >
                    <Icon type="bc-icon-info" />
                </Tooltip>
                <Dropdown
                    className="client-fields"
                    disabled={isDisableFormFields}
                    appearance="outline"
                    noDataWithImage
                    data={clientFields}
                    noDataText={t(l.NoDataFound)}
                    labelAppearance="swap"
                    placeholder={t(l.ClientField)}
                    onChange={clientFieldsChangeHandler}
                    value={clientFieldId}
                    hasSearch={false}
                />
                <Tooltip text={t(l.TooltipClientField)} position="bottom">
                    <Icon type="bc-icon-info" onClick={noop} />
                </Tooltip>
                <ButtonWithLoader
                    className="submit-button"
                    type="submit"
                    isDisabled={isDisableUploadBtn}
                    isLoading={isLoading}
                >
                    {t(l.UploadFile)}
                </ButtonWithLoader>
            </form>
        </div>
    );
};

ClientsFileUploader.propTypes = {
    onFileUploaded: PropTypes.func.isRequired,
    onFileUploading: PropTypes.func,
};

ClientsFileUploader.defaultProps = {
    onFileUploading: noop,
};

export default memo(ClientsFileUploader);
