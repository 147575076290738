import { TemplateListActionTypes as Types } from 'actions/types';
import { cloneDeep } from 'lodash';
import { templateInitialState } from 'constants/listDefaultFilters';

export default (state = cloneDeep(templateInitialState), action) => {
    switch (action.type) {
        case Types.RESET_TEMPLATE_LIST_STATE:
            state = cloneDeep(templateInitialState);
            return Object.assign({}, state, {});
        case Types.SET_TEMPLATE_LIST_TABLE_FILTERS:
            state.tableData.filters = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_NUMBER:
            state.tableData.paging.PageNumber = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_SIZE:
            state.tableData.paging.PageSize = action.payload;
            return Object.assign({}, state, {});
        case Types.SET_TEMPLATE_LIST_TABLE_SORTING:
            state.tableData.sorting = { ...action.payload };
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
