// Import constants
import { ErrorShowType, l } from 'constants/common';

const nonCustomerSegmentValidationScenario = () => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        type: ErrorShowType.Default,
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    fileName: {
        isValid: null,
        value: '',
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'isEmpty',
                ErrorMessage: l.PleaseChooseFile,
                ParametersValues: 'false',
            },
            {
                ValidationType: 'isFileFormatValid',
                ErrorMessage: l.WrongFileFormat,
                ParametersValues: 'xls,xlsx,csv',
            },
        ],
    },
    uploadClients: {
        isValid: null,
        value: '',
        type: ErrorShowType.Toaster,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'isEmpty',
                ErrorMessage: l.PleaseUploadFile,
                ParametersValues: 'false',
            },
        ],
    },
});

export { nonCustomerSegmentValidationScenario };
