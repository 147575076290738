import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Dropdown } from '@geneui/components';

const TDropdown = ({ data, ...rest }) => {
    const { t } = useTranslation();
    const [_data, _setData] = useState(data);

    useEffect(() => {
        _setData(
            data.map((item) => {
                item.label = t(item.label);
                return item;
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return <Dropdown data={_data} {...rest} />;
};

TDropdown.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
        }),
    ),
};

TDropdown.defaultProps = {
    data: [],
};

export default memo(TDropdown);
