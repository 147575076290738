// TODO: remove no-unused-vars
/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Services
import { getFormContent } from 'services/customerJourney';
// Import Components
import { CJReferenceInArgumentInfoBlock } from 'components/CustomerJourney/infoBlocks';
// Import UI Components
import { Icon, Tag } from '@geneui/components';
// Import Constants
import { CJArgumentValuesTypes } from 'constants/common';
import { formsTypes } from '../CustomerJourney/config';
import classNames from 'classnames';

const CJBlockInfoSidebarOptionList = ({ blockId, options, apiModel, elements }) => {
    const { t } = useTranslation();
    const [optionsLength, setOptionsLength] = useState(0);

    useEffect(() => {
        setOptionsLength(options.length);
    }, [options]);

    return (
        <>
            {options.map((option, index) => {
                const { Hidden, BaseTypes, Name, CustomAttributes } = option;
                const { DisplayName } = CustomAttributes;
                const { infoComponent: Component, mapToUIModel, onlyPrimitive } = getFormContent(BaseTypes, formsTypes);
                if (!Hidden && !isNil(Component)) {
                    const data = mapToUIModel(apiModel[Name]);

                    return (
                        <div
                            key={index}
                            className={classNames('crm-cj-block-info-sidebar-panel', {
                                'crm-cj-block-info-sidebar-last-panel': optionsLength === index + 1,
                            })}
                        >
                            <div className={'crm-cj-block-info-sidebar_option-name-wrapper'}>
                                <p className={'crm-cj-block-info-sidebar_option-name'}>{t(DisplayName)}</p>

                                {!isNil(data?.logic) && <Tag name={data?.logic} appearance="minimal" />}

                                {data.argumentInType === CJArgumentValuesTypes.ReferenceInArgument &&
                                    onlyPrimitive !== true && <Icon type="bc-icon-link1" />}
                            </div>
                            <div className={'cj-info-block-wrapper'}>
                                {data.argumentInType === CJArgumentValuesTypes.ReferenceInArgument ? (
                                    <CJReferenceInArgumentInfoBlock
                                        data={data}
                                        elements={elements}
                                        option={option}
                                        blockId={blockId}
                                    />
                                ) : (
                                    <Component
                                        data={data}
                                        elements={elements}
                                        option={option}
                                        blockId={blockId}
                                        customAttributes={CustomAttributes}
                                    />
                                )}
                            </div>
                        </div>
                    );
                }
                return <></>;
            })}
        </>
    );
};

CJBlockInfoSidebarOptionList.propTypes = {
    blockId: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    elements: PropTypes.object.isRequired,
    apiModel: PropTypes.object.isRequired,
};

export default memo(CJBlockInfoSidebarOptionList);
