import React, { memo, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isNil, noop } from 'lodash';
// Import UI Components
import { Tooltip, Popover, Button } from '@geneui/components';
// Import Components
import OptionList from './OptionList';
// Import Hooks
import { useOnClickOutside } from 'hooks';

const OptionDropdown = ({
    placeholder,
    optionLabel,
    tooltipText,
    popoverClassName,
    buttonClassName,
    buttonWrapperClassName,
    popoverMinHeight,
    popoverMaxHeight,
    contentWrapperClassName,
    openIcon,
    closeIcon,
    clearable,
    onChange,
    isMultiselect,
    disabled,
    onClear,
    isValid,
    errorText,
    // TODO: remove this tmp solution after the fix of the issue
    shouldMoveToRight,
    ...restProps
}) => {
    const optionListRef = useRef(null);
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);

    useOnClickOutside(
        optionListRef,
        useCallback(() => {
            if (isVisiblePopover) {
                setIsVisiblePopover(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isVisiblePopover]),
    );

    const valueChangeHandler = (e) => {
        onChange(e);
        if (!isMultiselect) {
            setIsVisiblePopover(false);
        }
    };

    const popoverToggler = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    const clearClickHandler = (e) => {
        e.stopPropagation();
        onClear();
        setIsVisiblePopover(false);
    };

    return (
        <div
            className={classNames('crm-option-dropdown', {
                // TODO: remove this tmp solution after the fix of the issue
                'crm-option-dropdown-right': shouldMoveToRight,
            })}
            ref={optionListRef}
        >
            <Popover
                isOpen={isVisiblePopover}
                extendTargetWidth={false}
                className={classNames('crm-option-list-popover', { [popoverClassName]: popoverClassName })}
                minHeight={popoverMinHeight}
                maxHeight={popoverMaxHeight}
                scrollbarNeeded={false}
                align="end"
            >
                <div
                    className={classNames('crm-option-list-content-wrapper', {
                        [contentWrapperClassName]: contentWrapperClassName,
                        disabled: disabled,
                    })}
                >
                    <div className={buttonWrapperClassName}>
                        <Tooltip className="crm-c-tooltip" text={tooltipText} position={'bottom'}>
                            <Button
                                className={classNames('crm-option-list-content__toggle-button', {
                                    [buttonClassName]: buttonClassName,
                                    'crm-option-list-content__is-not-valid': !isValid,
                                })}
                                onClick={popoverToggler}
                                icon={isVisiblePopover ? closeIcon : openIcon}
                            >
                                {isEmpty(optionLabel) ? (
                                    isNil(placeholder) ? (
                                        ''
                                    ) : (
                                        <div className="option-placeholder">{placeholder}</div>
                                    )
                                ) : (
                                    optionLabel
                                )}
                                {clearable && !isEmpty(optionLabel) && (
                                    <Button
                                        color="default"
                                        appearance="minimal"
                                        icon="bc-icon-clear-small"
                                        onClick={clearClickHandler}
                                    />
                                )}
                            </Button>
                        </Tooltip>
                        <span className="crm-option-list-content-wrapper__error-text">{errorText}</span>
                    </div>
                    {isVisiblePopover && (
                        <OptionList onChange={valueChangeHandler} isMultiselect={isMultiselect} {...restProps} />
                    )}
                </div>
            </Popover>
        </div>
    );
};

OptionDropdown.propTypes = {
    placeholder: PropTypes.string,
    optionLabel: PropTypes.string,
    tooltipText: PropTypes.string,
    popoverClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonWrapperClassName: PropTypes.string,
    popoverMinHeight: PropTypes.number,
    popoverMaxHeight: PropTypes.number,
    contentWrapperClassName: PropTypes.string,
    openIcon: PropTypes.string,
    closeIcon: PropTypes.string,
    clearable: PropTypes.bool,
    onChange: PropTypes.func,
    isMultiselect: PropTypes.bool,
    disabled: PropTypes.bool,
    onClear: PropTypes.func,
    isValid: PropTypes.bool,
    errorText: PropTypes.string,
    // TODO: remove this tmp solution after the fix of the issue
    shouldMoveToRight: PropTypes.bool,
};

OptionDropdown.defaultProps = {
    placeholder: '',
    optionLabel: '',
    tooltipText: '',
    popoverClassName: '',
    buttonClassName: '',
    buttonWrapperClassName: '',
    popoverMinHeight: 332,
    popoverMaxHeight: 352,
    contentWrapperClassName: '',
    openIcon: 'bc-icon-arrow-down',
    closeIcon: 'bc-icon-arrow-down',
    clearable: true,
    isMultiselect: false,
    disabled: false,
    onClear: noop,
    onChange: noop,
    isValid: true,
    errorText: '',
    // TODO: remove this tmp solution after the fix of the issue
    shouldMoveToRight: false,
};

export default memo(OptionDropdown);
