import React, { useState, memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, isObject, isFunction } from 'lodash';
import { RoutesList } from 'routes';
// Import Components
import { DownloadOrSendToEmailToolbar } from 'components';
import { CustomerJourneyActionsModals, CustomerJourneyActionsButtons } from 'components/CustomerJourneyList';
// Import UI components
import { Icon, SkeletonLoader, Tooltip } from '@geneui/components';
// Import Hooks
import { useGoToRoute, useRequest, useToaster, useQueryParams } from 'hooks';
// Import Services
import { Helpers } from 'services';
import { CustomerJourneyHttpService, UtilsHttpService } from 'services/http';
// Import Constants
import {
    l,
    CustomerJourneyStatusLabels,
    TimeZonePack,
    AlertTypes,
    ModalsActions,
    DependencyObjectTypeLabels,
    RealtimePromotionTypes,
} from 'constants/common';
// Import SCSS
import 'assets/scss/pageHeadline.scss';

const { customMomentWithoutTimezoneConversion } = Helpers;
const dateTimeFormatString = 'DD/MM/YYYY HH:mm';
const {
    REALTIME_JOURNEY_EDIT,
    REALTIME_JOURNEY_CLONE,
    REALTIME_JOURNEY_REPORT,
    REALTIME_JOURNEY_VISUALIZATION_CREATE,
    REALTIME_JOURNEY_VISUALIZATION_EDIT,
    REALTIME_PROMOTIONS_LIST,
    REALTIME_ACTION_EDIT,
    REALTIME_ACTION_CLONE,
    REALTIME_ACTION_REPORT,
} = RoutesList;

const CJViewPageHeadline = ({
    viewData,
    isLoading,
    setIsVisibleTimeLine,
    downloadFilters,
    isDisabledDownload,
    promotionType,
    exportAllExceededCellsCount,
}) => {
    const { t } = useTranslation();
    const { goToPreviousRoute, goToRoute } = useGoToRoute();
    const { doPostRequest, doGetRequest } = useRequest();
    const { showToaster } = useToaster();
    const { workflowId } = useParams();
    const [startDate, setStartDate] = useState(t(l.NA));
    const [endDate, setEndDate] = useState(t(l.NA));
    const [timeZone, setTimeZone] = useState(t(l.NA));
    const [createdDate, setCreatedDate] = useState(t(l.NA));
    const [status, setStatus] = useState();
    const [isDownloadAllLoading, setIsDownloadAllLoading] = useState(false);
    const goToRouteQueryParams = useQueryParams();
    const { search } = useLocation();

    const [isDeleteModalOpenedState, setIsDeleteModalOpenedState] = useState(false);
    const [isArchiveModalOpenedState, setIsArchiveModalOpenedState] = useState(false);
    const [isUnArchiveModalOpenedState, setIsUnArchiveModalOpenedState] = useState(false);
    const [isStopSendModalOpenedState, setIsStopSendModalOpenedState] = useState(false);
    const [isResumeModalOpenedState, setIsResumeModalOpenedState] = useState(false);
    const [isStartModalOpenedState, setIsStartModalOpenedState] = useState(false);
    const [isInfoModalOpenedState, setIsInfoModalOpenedState] = useState(false);
    const [isUsedInConfirmationModalOpenedState, setIsUsedInConfirmationModalOpenedState] = useState(false);
    const [openedModalData, setOpenedModalData] = useState({});

    const [data, setData] = useState({ ...viewData, isViewPage: true });

    useEffect(() => {
        if (data !== viewData) {
            setData({ ...viewData, isViewPage: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewData]);

    const { downloadWorkflowAllReportRequest, sendToEmailWorkflowAllReportRequest, getObjectDependencies } = useMemo(
        () => ({
            downloadWorkflowAllReportRequest: CustomerJourneyHttpService.downloadWorkflowAllReport(),
            sendToEmailWorkflowAllReportRequest: CustomerJourneyHttpService.sendToEmailWorkflowAllReport(),
            getObjectDependencies: UtilsHttpService.getObjectDependencies(),
        }),
        [],
    );

    const backButtonClickHandler = () => {
        goToPreviousRoute();
    };

    const modalsStateSetDict = {
        deleteCustomerJourney: {
            key: 'deleteCustomerJourney',
            fn: setIsDeleteModalOpenedState,
        },
        archiveCustomerJourney: {
            key: 'archiveCustomerJourney',
            fn: setIsArchiveModalOpenedState,
        },
        unarchiveCustomerJourney: {
            key: 'unarchiveCustomerJourney',
            fn: setIsUnArchiveModalOpenedState,
        },
        stopSendCustomerJourney: {
            key: 'stopSendCustomerJourney',
            fn: setIsStopSendModalOpenedState,
        },
        startCustomerJourney: {
            key: 'startCustomerJourney',
            fn: setIsStartModalOpenedState,
        },
        resumeCustomerJourney: {
            key: 'resumeCustomerJourney',
            fn: setIsResumeModalOpenedState,
        },
        infoCustomerJourney: {
            key: 'infoCustomerJourney',
            fn: setIsInfoModalOpenedState,
        },
        editCustomerJourney: {
            key: 'editCustomerJourney',
            fn: setIsUsedInConfirmationModalOpenedState,
        },
    };

    useEffect(() => {
        if (isObject(viewData?.Schedule)) {
            const { StartDate, EndDate, TimeZone } = viewData?.Schedule;

            !isEmpty(StartDate) &&
                setStartDate(customMomentWithoutTimezoneConversion(StartDate).format(dateTimeFormatString));
            !isEmpty(EndDate) &&
                setEndDate(customMomentWithoutTimezoneConversion(EndDate).format(dateTimeFormatString));
            !isNil(TimeZone) && setTimeZone(TimeZonePack[TimeZone]);
        }
        !isEmpty(viewData?.CreatedDate) &&
            setCreatedDate(customMomentWithoutTimezoneConversion(viewData?.CreatedDate).format(dateTimeFormatString));

        setStatus(viewData?.Status);
    }, [viewData]);

    const downloadAllClickHandler = () => {
        const requestBody = getRequestBody();
        return doPostRequest(downloadWorkflowAllReportRequest.request, {
            requestBody,
            successCallback: ({ Url }) => {
                window.open(Url);
            },
        }).then(({ AlertMessage, AlertType }) => {
            showToaster(AlertType, AlertMessage);
            isFunction(setIsDownloadAllLoading) && setIsDownloadAllLoading(false);
        });
    };

    const sendToEmailClickHandler = () => {
        const requestBody = getRequestBody();

        return doPostRequest(sendToEmailWorkflowAllReportRequest.request, {
            requestBody,
        }).then(({ AlertMessage, AlertType }) => {
            showToaster(AlertType, AlertMessage);
            isFunction(setIsDownloadAllLoading) && setIsDownloadAllLoading(false);
        });
    };

    const getRequestBody = () => {
        setIsDownloadAllLoading(true);

        return {
            WorkflowId: workflowId,
            FIlters: downloadFilters,
        };
    };

    //Actions Part
    const deleteCustomerJourneyAction = (workflow) => {
        setOpenedModalData(workflow);
        setIsDeleteModalOpenedState(true);
    };

    const archiveCustomerJourneyAction = (workflow) => {
        setOpenedModalData(workflow);
        setIsArchiveModalOpenedState(true);
    };

    const unArchiveCustomerJourneyAction = (workflow) => {
        setOpenedModalData(workflow);
        setIsUnArchiveModalOpenedState(true);
    };

    const stopSendCustomerJourneyAction = (workflow) => {
        setOpenedModalData(workflow);
        setIsStopSendModalOpenedState(true);
    };

    const startCustomerJourneyAction = (workflow) => {
        setOpenedModalData(workflow);
        setIsStartModalOpenedState(true);
    };

    const resumeCustomerJourneyAction = (workflow) => {
        setOpenedModalData(workflow);
        setIsResumeModalOpenedState(true);
    };

    const goToEditCustomerJourney = (workflow) => {
        const { WorkflowId, PromotionType } = workflow;
        const params = {
            workflowId: WorkflowId,
        };
        const route =
            PromotionType === +RealtimePromotionTypes.ActionCampaign ? REALTIME_ACTION_EDIT : REALTIME_JOURNEY_EDIT;
        goToRoute(route, params, goToRouteQueryParams.encode({ backParams: search }));
    };

    const cloneCustomerJourneyAction = (workflow) => {
        const { WorkflowId, PromotionType } = workflow;
        const params = {
            workflowId: WorkflowId,
        };
        const route =
            PromotionType === +RealtimePromotionTypes.ActionCampaign ? REALTIME_ACTION_CLONE : REALTIME_JOURNEY_CLONE;
        goToRoute(route, params);
    };

    const editCustomerJourneyAction = (workflow) => {
        const { WorkflowId } = workflow;

        doGetRequest(getObjectDependencies.request, {
            queryString: { objectId: WorkflowId, dependencyType: DependencyObjectTypeLabels.Workflow },
            successCallback: (Data) => {
                if (isEmpty(Data)) {
                    goToEditCustomerJourney(workflow);
                } else {
                    setOpenedModalData({
                        objects: Data,
                        workflow,
                    });
                    setIsUsedInConfirmationModalOpenedState(true);
                }
            },
        });
    };

    const infoCustomerJourneyAction = (workflow) => {
        setOpenedModalData(workflow);
        setIsInfoModalOpenedState(true);
    };

    const reportCustomerJourneyAction = (workflow) => {
        const { WorkflowId, PromotionType } = workflow;
        const params = { workflowId: WorkflowId };
        const route =
            PromotionType === +RealtimePromotionTypes.ActionCampaign ? REALTIME_ACTION_REPORT : REALTIME_JOURNEY_REPORT;
        goToRoute(route, params);
    };

    const customerJourneyVisualizationAction = (workflow) => {
        const { WorkflowId, HasVisualization } = workflow;
        const params = { workflowId: WorkflowId };
        const path = HasVisualization ? REALTIME_JOURNEY_VISUALIZATION_EDIT : REALTIME_JOURNEY_VISUALIZATION_CREATE;
        goToRoute(path, params, goToRouteQueryParams.encode({ fromViewPage: true }));
    };

    const activityTimelineAction = () => {
        setIsVisibleTimeLine(true);
    };

    const onCloseModalHandler = (modalStateKey, action, alertType, alertMessage, _count = 1) => {
        if (
            action === ModalsActions.ARCHIVE ||
            action === ModalsActions.UNARCHIVE ||
            action === ModalsActions.CUSTOMER_JOURNEY_STOP ||
            action === ModalsActions.START ||
            action === ModalsActions.RESUME
        ) {
            if (alertType === AlertTypes.success || alertType === AlertTypes.warning) {
                window.location.reload(false);
            }
            showToaster(alertType, alertMessage);
        }
        modalsStateSetDict[modalStateKey].fn(false);
        if (action === ModalsActions.DELETE) {
            goToRoute(REALTIME_PROMOTIONS_LIST);
        }
    };

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            downloadWorkflowAllReportRequest.cancel('CJViewPageHeadline:downloadWorkflowAllReportRequest');
            sendToEmailWorkflowAllReportRequest.cancel('CJViewPageHeadline:sendToEmailWorkflowAllReportRequest');
            getObjectDependencies.cancel('CJViewPageHeadline:getObjectDependencies');
        };
    };

    useEffect(cleanUp, []);

    return (
        <div className="crm-page-headline">
            <div className="crm-page-headline_back-button-part">
                <Icon type="bc-icon-close" onClick={backButtonClickHandler} />
            </div>
            {
                // TODO: should be removed after second release phase
                /* <div className="crm-page-headline_page-name-part crm-page-headline-name-col">
                    <p>{t`(titleKey)}</p>
                </div> */
            }
            <div className={'crm-page-headline_content-part'}>
                <div className="page-h-left">
                    <div className="crm-view-page-headline-info-cnt">
                        <div className="crm-view-page-headline-title-panel">
                            <SkeletonLoader isBusy={isLoading} height="40px" width="150px">
                                <span>{viewData?.Name}</span>
                                <div>{isEmpty(viewData?.Description) ? t(l.NA) : viewData?.Description}</div>
                            </SkeletonLoader>
                        </div>

                        <div className="crm-view-page-headline-info-panel">
                            <SkeletonLoader isBusy={isLoading} height="40px" width="100px">
                                <span>{t(l.CreatedDate)}</span>
                                <div>{createdDate}</div>
                            </SkeletonLoader>
                        </div>

                        <div className="crm-view-page-headline-info-panel">
                            <SkeletonLoader isBusy={isLoading} height="40px" width="100px">
                                <span>{t(l.AutoStart)}</span>
                                <div>{startDate}</div>
                            </SkeletonLoader>
                        </div>

                        <div className="crm-view-page-headline-info-panel">
                            <SkeletonLoader isBusy={isLoading} height="40px" width="100px">
                                <span>{t(l.AutoEnd)}</span>
                                <div>{endDate}</div>
                            </SkeletonLoader>
                        </div>
                        <div className="crm-view-page-headline-info-panel">
                            <SkeletonLoader isBusy={isLoading} height="40px" width="100px">
                                <span>{t(l.TimeZone)}</span>
                                <div>{timeZone}</div>
                            </SkeletonLoader>
                        </div>

                        <div className="crm-view-page-headline-info-panel">
                            <SkeletonLoader isBusy={isLoading} height="40px" width="100px">
                                <span>{t(l.Status)}</span>
                                <div className={`crm-status-badge-cnt crm-cj-status-label-${status}`}>
                                    {isNil(status) ? t(l.NA) : t(CustomerJourneyStatusLabels[status])}
                                </div>
                            </SkeletonLoader>
                        </div>
                    </div>
                    <div className="page-h-right"></div>
                </div>
                <SkeletonLoader isBusy={isLoading} height="40px" width="200px">
                    <div className="crm-view-page-headline-action-buttons">
                        <CustomerJourneyActionsButtons
                            data={data}
                            cloneCustomerJourneyAction={cloneCustomerJourneyAction}
                            resumeCustomerJourneyAction={resumeCustomerJourneyAction}
                            reportCustomerJourneyAction={reportCustomerJourneyAction}
                            createCustomerJourneyVisualizationAction={customerJourneyVisualizationAction}
                            editCustomerJourneyVisualizationAction={customerJourneyVisualizationAction}
                            archiveCustomerJourneyAction={archiveCustomerJourneyAction}
                            unArchiveCustomerJourneyAction={unArchiveCustomerJourneyAction}
                            editCustomerJourneyAction={editCustomerJourneyAction}
                            deleteCustomerJourneyAction={deleteCustomerJourneyAction}
                            stopSendCustomerJourneyAction={stopSendCustomerJourneyAction}
                            startCustomerJourneyAction={startCustomerJourneyAction}
                            infoCustomerJourneyAction={infoCustomerJourneyAction}
                            activityTimelineAction={activityTimelineAction}
                            isViewPage={true}
                        />
                    </div>
                </SkeletonLoader>

                <div className="page-h-right">
                    <div className="cj-report-export-warring-icon-container">
                        {exportAllExceededCellsCount > 0 && (
                            <Tooltip
                                text={t(l.CJReportCellsExceedLimitWarning, {
                                    exceededCount: exportAllExceededCellsCount,
                                })}
                            >
                                <Icon type="bc-icon-danger-48" />
                            </Tooltip>
                        )}
                    </div>
                    <div className="cj-report-download-btn">
                        <SkeletonLoader isBusy={isNil(status)} height="40px" width="100px">
                            {status !== +CustomerJourneyStatusLabels.NonStarted &&
                                promotionType !== RealtimePromotionTypes.ActionCampaign && (
                                    <DownloadOrSendToEmailToolbar
                                        downloadAction={downloadAllClickHandler}
                                        sendToEmailAction={sendToEmailClickHandler}
                                        isFileLoading={isDownloadAllLoading}
                                        isDisabledDownload={isDisabledDownload || exportAllExceededCellsCount > 0}
                                    />
                                )}
                        </SkeletonLoader>
                    </div>
                </div>
            </div>
            <CustomerJourneyActionsModals
                isDeleteModalOpenedState={isDeleteModalOpenedState}
                isArchiveModalOpenedState={isArchiveModalOpenedState}
                isUnArchiveModalOpenedState={isUnArchiveModalOpenedState}
                isStopSendModalOpenedState={isStopSendModalOpenedState}
                isStartCustomerJourneyModalOpenedState={isStartModalOpenedState}
                onCloseModalHandler={onCloseModalHandler}
                modalsStateSetDict={modalsStateSetDict}
                openedModalData={openedModalData}
                isResumeModalOpenedState={isResumeModalOpenedState}
                isInfoModalOpenedState={isInfoModalOpenedState}
                isUsedInConfirmationModalOpenedState={isUsedInConfirmationModalOpenedState}
                goToEditCustomerJourney={goToEditCustomerJourney}
            />
        </div>
    );
};

CJViewPageHeadline.propTypes = {
    titleKey: PropTypes.string.isRequired,
    isDisabledDownload: PropTypes.bool.isRequired,
    viewData: PropTypes.object,
    isLoading: PropTypes.bool,
    setIsVisibleTimeLine: PropTypes.func,
    downloadFilters: PropTypes.object,
    promotionType: PropTypes.string,
    exportAllExceededCellsCount: PropTypes.number,
};

CJViewPageHeadline.defaultProps = {
    viewData: {},
    isDisabledDownload: false,
    exportAllExceededCellsCount: 0,
};

export default memo(CJViewPageHeadline);
