import { SignInActionTypes as Types } from 'actions/types';

export default (
    state = {
        crmSignInForm: {
            isLoading: false,
            errorMessage: '',
        },
    },
    action,
) => {
    switch (action.type) {
        case Types.CRM_SIGN_IN_FETCH:
            state.crmSignInForm.isLoading = true;
            state.crmSignInForm.errorMessage = '';
            return Object.assign({}, state, {});
        case Types.CRM_SIGN_IN_SUCCEEDED:
            state.crmSignInForm.isLoading = false;
            return Object.assign({}, state, {});
        case Types.CRM_SIGN_IN_FAILED:
            state.crmSignInForm.isLoading = false;
            state.crmSignInForm.errorMessage = action.payload;
            return Object.assign({}, state, {});
        case Types.CLEAR_CRM_SIGN_IN:
            state.crmSignInForm.isLoading = false;
            state.crmSignInForm.errorMessage = '';
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
