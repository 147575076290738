import React from 'react';
import PropTypes from 'prop-types';
// import ui components
import Holder from '@geneui/components/Holder';
// import css
import 'assets/scss/holderWrapper.scss';

const HolderWrapper = ({ children, isDisabled, ...rest }) => {
    return (
        <div className="crm-holder-wrapper">
            <Holder disabled={isDisabled} {...rest}>
                {children}
            </Holder>
        </div>
    );
};

HolderWrapper.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
};

export default HolderWrapper;
