import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Button } from '@geneui/components';
// Import Components
import CJFilterGroupTag from './CJFilterGroupTag';
// Import Constants
import { l } from 'constants/common';

const FilterTagComponent = ({
    className,
    filterTagsList,
    onAddButtonClick,
    isFilterButtonDisabled,
    elements,
    removeTag,
}) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className="crm-filter-tag-button-wrapper">
                <Button icon="bc-icon-add" onClick={onAddButtonClick} disabled={isFilterButtonDisabled} size="medium">
                    {isEmpty(filterTagsList) ? t(l.AddFilters) : ''}
                </Button>
            </div>
            <CJFilterGroupTag
                filterTag={filterTagsList}
                isLoading={isFilterButtonDisabled}
                elements={elements}
                removeTag={removeTag}
            />
        </div>
    );
};

FilterTagComponent.propTypes = {
    className: PropTypes.string,
    filterTagsList: PropTypes.array,
    onAddButtonClick: PropTypes.func.isRequired,
    removeTag: PropTypes.func,
    isFilterButtonDisabled: PropTypes.bool,
    elements: PropTypes.array.isRequired,
};

FilterTagComponent.defaultProps = {
    className: '',
    filterTagsList: [],
    isFilterButtonDisabled: true,
    removeTag: noop,
};

export default FilterTagComponent;
