import React from 'react';
import PropTypes from 'prop-types';
import { Handle } from 'react-flow-renderer';
// Import UI Components
import BaseBlock from './Base.block';

const ActionBlock = (props) => {
    const { sourcePosition, targetPosition, id } = props;

    return (
        <div className="crm-flow-editor-block">
            <Handle type="target" position={targetPosition} id={`${id}_target`} />
            <BaseBlock node={props} />
            <Handle type="source" position={sourcePosition} id={`${id}_source`} />
        </div>
    );
};

ActionBlock.propTypes = {
    id: PropTypes.string.isRequired,
    sourcePosition: PropTypes.string.isRequired,
    targetPosition: PropTypes.string.isRequired,
};

export default ActionBlock;
