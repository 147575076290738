import {
    CRMRequestService,
    getCancelableRequest,
    postCancelableRequest,
    deleteCancelableRequest,
    CRMRequestServiceDownload,
} from './http.config';

const ExecutedReportHttpService = {
    getExecutedReportList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ReportResult/List',
        });
    },
    deleteExecutedReport: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ executedReportId, type }) => `/ReportResult/Delete?id=${executedReportId}&type=${type}`,
        });
    },
    getExecutedReport: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ executedReportId }) => `/Report/Get?id=${executedReportId}`,
        });
    },

    getExecutedReportResult: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/ReportResult/View`,
        });
    },

    getExecutedReportHistory: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/EventLog/List`,
        });
    },
    downloadExecutedReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestServiceDownload,
            endpointPath: () => `/AdHocReportResult/GetExcel`,
        });
    },
    deleteSelectedExecutedReports: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ type }) => `/ReportResult/Delete?type=${type}`,
        });
    },
    archiveExecutedReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ executedReportId }) => `ReportResult/Archive?id=${executedReportId}`,
        });
    },
    archiveExecutedReports: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `ReportResult/Archive`,
        });
    },
    unarchiveExecutedReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ executedReportId }) => `ReportResult/UnarchiveReportResult?id=${executedReportId}`,
        });
    },
    unarchiveExecutedReports: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `ReportResult/UnarchiveReportResult`,
        });
    },
};

export default ExecutedReportHttpService;
