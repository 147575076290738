import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop, unionBy } from 'lodash';
// Import Hooks
import { useToaster, useAutocompleteRequest } from 'hooks';
// Import UI Components
import { Dropdown } from '@geneui/components';

const AutocompleteDropdown = ({ data, getRequest, getData, value, ...rest }) => {
    const [dropdownDataList, setDropdownDataList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const { showToaster } = useToaster();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const getDataList = (searchValue = '') => {
        const requestObj = getAutocompleteRequest(getRequest);

        getData(requestObj, searchValue, showToaster)
            .then((data) => {
                const tmpListData = !isNil(data) ? unionBy(data, dropdownDataList, 'value') : dropdownDataList;
                setDataList((prev) => unionBy(prev, tmpListData, 'value'));
            })
            .catch(({ AlertType, AlertMessage }) => {
                showToaster(AlertType, AlertMessage);
            });
    };

    const handleChange = (value) => {
        setDropdownDataList(value);
    };

    const onSearch = (value) => {
        getDataList(value);
    };

    const cleanUp = () => {
        return () => {
            cancelRequest('AutocompleteDropdown:request');
        };
    };

    useEffect(cleanUp, []);

    useEffect(() => {
        getDataList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dropdown
            data={dataList}
            onChange={handleChange}
            value={value}
            isMultiSelect={true}
            onSearch={onSearch}
            customSearch={false}
            hideSelectAll={true}
            {...rest}
        />
    );
};

AutocompleteDropdown.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
        }),
    ),
    value: PropTypes.array,
    getRequest: PropTypes.func,
    getData: PropTypes.func,
};

AutocompleteDropdown.defaultProps = {
    data: [],
    value: [],
    getRequest: noop,
    getData: noop,
};

export default memo(AutocompleteDropdown);
