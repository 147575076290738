import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const useMaintenanceSettings = () => {
    const { startDate, endDate } = useSelector((state) => state.maintenance);

    let hasUpcomingMaintenance = false;
    let isUnderMaintenance = false;

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
        const startDateTime = new Date(startDate).getTime();
        const endDateTime = new Date(endDate).getTime();
        const nowTime = new Date().getTime();

        if (startDateTime > nowTime) {
            hasUpcomingMaintenance = true;
        } else if (endDateTime > nowTime) {
            isUnderMaintenance = true;
        }
    }

    return {
        startDate,
        endDate,
        hasUpcomingMaintenance,
        isUnderMaintenance,
    };
};

export default useMaintenanceSettings;
