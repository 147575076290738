// Import Services
import { subscribe } from 'services/event';
import { startHashing } from 'components/DynamicSegment/service';
// Import Constants
import { QueryConfigurationsTypes as Types } from 'actions/types';
import { GlobalEvents, SubscriberSources } from 'constants/common';

const resetQueryConfigurations = () => ({
    type: Types.RESET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS,
});

const getQueryConfigurations = (dispatch, getQueryConfigurationsList) => {
    return getQueryConfigurationsList().then(({ Data }) => {
        const hashedData = startHashing(Data);

        subscribe(GlobalEvents.PartnerChanged, SubscriberSources.QueryConfiguration, () =>
            dispatch(resetQueryConfigurations()),
        );

        dispatch({
            type: Types.SET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS,
            payload: hashedData,
        });
        return hashedData;
    });
};

export default { getQueryConfigurations };
