import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import services
import { Helpers } from 'services';
import { generatePartialHtml, htmlStringToDomElement } from 'services/template';
// Import Configs
import { createMosaicoConfigsViaHTMLString } from './index';

const { replaceDomainFromEnvironmentURL } = Helpers;

const MosaicoEditor = ({
    onChange,
    onConfigChange,
    config,
    defaultHTML,
    dynamicVariables,
    dynamicVariableChannelKey,
    isPartialHTML,
    onFocus,
}) => {
    const { t } = useTranslation();

    const editorRef = useRef(null);
    const baseUrl = replaceDomainFromEnvironmentURL(process.env.REACT_APP_CRM_GALLERY_URL);
    const parsedDynamicVariables = useMemo(
        () =>
            (dynamicVariables || []).map((variable) => ({
                name: t(variable.displayNameKey),
                value: variable.channels[dynamicVariableChannelKey].FieldName,
            })),
        [dynamicVariables, t, dynamicVariableChannelKey],
    );

    const handleChange = ({ html, content, metadata }) => {
        const htmlDocument = htmlStringToDomElement(html);
        const isEmpty = htmlDocument.body.children[0]?.children.length === 0;
        const value = isEmpty ? '' : isPartialHTML ? generatePartialHtml(htmlDocument) : html;

        onChange(value);
        onConfigChange({ content, metadata: JSON.parse(metadata) });
    };

    useEffect(() => {
        editorRef.current.contentWindow.$react = {
            onChange: handleChange,
            onFocus: onFocus,
            dynamicValues: parsedDynamicVariables,
        };

        editorRef.current.onload = () => {
            editorRef.current.contentWindow.contentProviderUrl = replaceDomainFromEnvironmentURL(
                process.env.REACT_APP_CRM_CONTENT_PROVIDER_URL,
            );

            setTimeout(() => {
                if (editorRef.current) {
                    editorRef.current.contentWindow.initMosaico(
                        defaultHTML && !config ? createMosaicoConfigsViaHTMLString(defaultHTML) : config,
                        baseUrl,
                    );
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="crm-mosaico-container">
            <iframe
                style={{ width: '100%', minHeight: '500px', height: '99.9%' }}
                title="mosaico"
                name="crmEditorIFrame"
                src="/mosaico/editor.html"
                ref={editorRef}
            ></iframe>
        </div>
    );
};

MosaicoEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    onConfigChange: PropTypes.func.isRequired,
    config: PropTypes.shape({
        metadata: PropTypes.object,
        content: PropTypes.object,
    }),
    defaultHTML: PropTypes.string,
    dynamicVariables: PropTypes.array.isRequired,
    dynamicVariableChannelKey: PropTypes.string.isRequired,
    isPartialHTML: PropTypes.bool,
    onFocus: PropTypes.func,
};

export default MosaicoEditor;
