import React from 'react';
import { tabsKey } from '../Campaign/config';

const CampaignInfoModalCustomTabsConfig = [
    {
        tabKey: tabsKey.Campaign,
        buttonContent(handleClick, buttonName) {
            return <button onClick={handleClick(tabsKey.Campaign)}>{buttonName}</button>;
        },
        tabKeyForFilter: 'CampaignId',
    },
    {
        tabKey: tabsKey.Segment,
        buttonContent(handleClick, buttonName) {
            return <button onClick={handleClick(tabsKey.Segment)}>{buttonName}</button>;
        },
        tabKeyForFilter: 'SegmentId',
    },
    {
        tabKey: tabsKey.Template,
        buttonContent(handleClick, buttonName) {
            return <button onClick={handleClick(tabsKey.Template)}>{buttonName}</button>;
        },
        tabKeyForFilter: 'TemplateId',
    },
    {
        tabKey: tabsKey.Bonus,
        buttonContent(handleClick, buttonName) {
            return <button onClick={handleClick(tabsKey.Bonus)}>{buttonName}</button>;
        },
        tabKeyForFilter: 'BonusId',
    },
];

export { CampaignInfoModalCustomTabsConfig };
