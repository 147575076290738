import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
// Import UI components
import { Empty } from '@geneui/components';
// Import components
import DynamicSegmentListItem from './DynamicSegmentListItem';
// Import container
import { UIHashComparisonContainer } from './UIHashContainers';

function DynamicSegmentList({ list, handleChangeActiveColumnsList, full, selected, setReadOnlyFilterContainer }) {
    const itemClickHandler = (hashKey, displayName) => {
        setReadOnlyFilterContainer(false);
        handleChangeActiveColumnsList(hashKey, displayName);
    };

    return (
        !(list instanceof UIHashComparisonContainer) && (
            <ul className={`crm-dynamic-segment-list ${!full ? 'crm-closed' : ''}`}>
                <div className="crm-scroll-holder">
                    <div className="crm-scrollable-container">
                        {!isNil(list[0]) ? (
                            list.map((elem, index) => {
                                const { displayName, colorUI, description, hashKey, hashType, comparison } = elem;
                                return (
                                    <DynamicSegmentListItem
                                        key={index}
                                        text={displayName}
                                        color={colorUI}
                                        full={full}
                                        description={description}
                                        type={hashType}
                                        selected={selected === displayName}
                                        handleClick={() => itemClickHandler(hashKey, displayName)}
                                        comparisonIconType={comparison}
                                    />
                                );
                            })
                        ) : (
                            <Empty
                                appearance="greyscale"
                                withImage
                                title={'No Result'}
                                subTitle="Duis tempus justo nec nunc accumsan eleifend."
                            />
                        )}
                    </div>
                </div>
            </ul>
        )
    );
}

DynamicSegmentList.propTypes = {
    list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    handleChangeActiveColumnsList: PropTypes.func,
    full: PropTypes.bool,
    selected: PropTypes.string,
    mainLength: PropTypes.number,
    resetActiveList: PropTypes.func,
    setReadOnlyFilterContainer: PropTypes.func,
};

export default memo(DynamicSegmentList);
