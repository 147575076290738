/* eslint-disable react/jsx-no-bind */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import UI Components
import { Modal, Checkbox } from '@geneui/components';
// Import Components
import { DataTable } from 'components';
// Import Constants
import { l } from 'constants/common';
import { LanguagesFlagsPaths } from 'constants/utils';
// Import Actions
import { TemplateActions } from 'actions';

const { addNewTemplateList } = TemplateActions;

const columns = [
    {
        text: 'Language',
        dataKey: 'Language',
        sortable: true,
        // eslint-disable-next-line react/display-name
        colRenderer: (Language, _index, row) => {
            const { Id } = row.data;

            return (
                <div className="select-languages-block">
                    <span className="select-language-img">
                        <img src={LanguagesFlagsPaths[Id]} alt={Language} />
                    </span>
                    <p>{`${Language}`}</p>
                </div>
            );
        },
    },
];

const LanguageWithSearchModal = ({
    languageList,
    onCancel,
    onOk,
    templateType,
    addValidationProperties,
    defaultTemplate,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [values, setValues] = useState([]);
    const [cloneFromDefault, setCloneFromDefault] = useState(true);
    const { IsAllowViberSmsCascading: isAllowViberSmsCascading } = useSelector((state) => state.partnerSettings);

    function cloneFromDefaultChangeHandler(event) {
        setCloneFromDefault(event.target.checked);
    }
    function handleClickOnCheckbox(selected) {
        setValues(selected);
    }

    function handleSave() {
        if (!isEmpty(values)) {
            if (cloneFromDefault) {
                addValidationProperties(
                    values.reduce((acc, item) => {
                        acc[item.data.Id] = { ...defaultTemplate, isValid: null };
                        return acc;
                    }, {}),
                    true,
                );
                dispatch(
                    addNewTemplateList({
                        data: values,
                        template: defaultTemplate,
                        type: templateType,
                        isCascade: isAllowViberSmsCascading,
                    }),
                );
            } else {
                addValidationProperties(values.map((item) => item.data.Id));
                dispatch(
                    addNewTemplateList({
                        data: values,
                        type: templateType,
                        isCascade: isAllowViberSmsCascading,
                    }),
                );
            }
        }
        onOk();
    }

    return (
        <Modal
            background="dark-background"
            position="center"
            title={t(l.SelectLanguage)}
            className="crm-modal add-language c-add-lang"
            closable={false}
            onCancel={onCancel}
            cancelText={t(l.Cancel)}
            okText={t(l.Save)}
            closeOnClickOutside={true}
            visible={true}
            onOK={handleSave}
        >
            <>
                <Checkbox
                    label={t(l.CloneFromDefault)}
                    checked={cloneFromDefault}
                    onChange={cloneFromDefaultChangeHandler}
                    className="c-add-language-from-default"
                />
                <DataTable
                    rowKey="index"
                    columnKey="dataKey"
                    withSearch={true}
                    withPagination={false}
                    withPageSelector={false}
                    isColumnsSortable={true}
                    data={languageList}
                    dataCount={languageList.length}
                    columns={columns}
                    getSelectedRowsList={handleClickOnCheckbox}
                    isHasRowMultiSelect={true}
                />
            </>
        </Modal>
    );
};

LanguageWithSearchModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    languageList: PropTypes.array.isRequired,
    addValidationProperties: PropTypes.func,
    templateType: PropTypes.string.isRequired,
    defaultTemplate: PropTypes.any.isRequired,
};

export default memo(LanguageWithSearchModal);
