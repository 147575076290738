import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
// Import UI Components
import { Modal, Button } from '@geneui/components';
import { ButtonWithLoader } from 'components';
// Import Components
import { EditForm } from './forms';
// Import Constants
import { l, CustomerJourneyGroupBlockTypesLabels } from 'constants/common';

const EditModal = ({ block, isVisibleModal, onCancel, onOk, isOkActive, isLoading, elements }) => {
    const { t } = useTranslation();

    const blockGroupTypeLabel = CustomerJourneyGroupBlockTypesLabels[block.type];
    const blockTypeLabel = `CJBlock${block?.data?.blockType}`;

    const clonedBlock = cloneDeep(block);

    const onCancelHandler = () => {
        onCancel();
    };

    const onOkHandler = () => {
        onOk({ ...clonedBlock });
    };

    return (
        <Modal
            background="dark-background"
            position="center"
            size="content-size"
            className="crm-modal crm-cj-edit-modal"
            title={`${t(blockGroupTypeLabel)} ${t(blockTypeLabel)}`}
            closable={false}
            closeOnClickOutside={true}
            onCancel={onCancel}
            visible={isVisibleModal}
            isOkActive={isOkActive}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancelHandler}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader
                        color="primary"
                        onClick={onOkHandler}
                        isLoading={isLoading}
                        isDisabled={!isOkActive}
                    >
                        {t(l.Save)}
                    </ButtonWithLoader>
                </>
            }
        >
            <EditForm block={clonedBlock} elements={elements} />
        </Modal>
    );
};

EditModal.propTypes = {
    block: PropTypes.object.isRequired,
    isVisibleModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    elements: PropTypes.array.isRequired,
    isOkActive: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
};

EditModal.defaultProps = {
    isOkActive: true,
};

export default memo(EditModal);
