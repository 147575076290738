import React, { useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { first, isEmpty } from 'lodash';
// Import Components
import { UploadFile, ButtonWithLoader } from 'components';
// Import UI Components
import { ExtendedInput, Icon, TimePicker } from '@geneui/components';
// Import Services
import { Helpers } from 'services';
import {
    TemplateType,
    TemplateSymbolsCountFields,
    getTemplateValidationTranslatableErrorText,
    ViberTemplateConfig,
} from 'services/template';
import { TemplateHttpService } from 'services/http';
// Import Actions
import { TemplateActions } from 'actions';
// Import Hooks
import { useFieldValidity, useRequest, useValidator } from 'hooks';
// Import Constants
import { l, TemplateInputTypes } from 'constants/common';
// Import SCSS
import 'assets/scss/socialNotification.scss';

const { changeTemplateValue } = TemplateActions;
const { calculateTemplateBodyLengthWithRegEx, convertMinutesToHHMM } = Helpers;

const ViberTemplateContent = ({
    templateData,
    templateLangKey,
    handleChange,
    shortenerAndTrackerSettings,
    updateDynamicVariableOptions,
    updateInsertionHandler,
}) => {
    const { t } = useTranslation();

    const templateBody = templateData?.[TemplateInputTypes.Body];
    const validityPeriod = convertMinutesToHHMM(
        templateData?.[TemplateInputTypes.ValidityPeriod] ?? ViberTemplateConfig.DefaultValidityPeriodInMinutes,
    );
    const { doPostRequest } = useRequest();

    const bodyValidationState = useFieldValidity(TemplateInputTypes.Body, l.Body, templateLangKey);
    const buttonTextValidationState = useFieldValidity(TemplateInputTypes.ButtonText, l.ButtonText, templateLangKey);
    const buttonUrlValidationState = useFieldValidity(TemplateInputTypes.ButtonUrl, l.ButtonUrl, templateLangKey);
    const validityPeriodValidationState = useFieldValidity(
        TemplateInputTypes.ValidityPeriod,
        l.ValidityPeriod,
        templateLangKey,
    );

    const dispatch = useDispatch();
    const validator = useValidator(getTemplateValidationTranslatableErrorText(t));

    const [isUploadedImageLoading, setIsUploadedImageLoading] = useState(false);
    const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(true);
    const [file, setFile] = useState(null);
    const [bodyValue, setBodyValue] = useState(templateBody);

    const inputRef = useRef(null);

    const computedBody = useMemo(() => {
        if (isEmpty(shortenerAndTrackerSettings) || isEmpty(templateBody)) return '';
        return calculateTemplateBodyLengthWithRegEx(shortenerAndTrackerSettings, templateBody);
    }, [templateBody, shortenerAndTrackerSettings]);

    const { addAssetRequest } = useMemo(
        () => ({
            addAssetRequest: TemplateHttpService.addAsset(),
        }),
        [],
    );

    const fileInputChangeHandler = (e) => {
        const fileInput = e.currentTarget;
        setFile(first(fileInput.files));
        dispatch(
            changeTemplateValue({
                propertyName: TemplateInputTypes.FileName,
                value: !fileInput.value ? null : first(fileInput.files).name,
                templateLangKey,
            }),
        );
        dispatch(
            changeTemplateValue({
                propertyName: TemplateInputTypes.UploadedImageUrl,
                value: '',
                templateLangKey,
            }),
        );
        setIsUploadBtnDisabled(!fileInput.value);
    };

    const uploadImgHandler = () => {
        setIsUploadedImageLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        doPostRequest(addAssetRequest.request, {
            requestBody: formData,
            successCallback: (Data) => {
                dispatch(
                    changeTemplateValue({
                        propertyName: TemplateInputTypes.UploadedImageUrl,
                        value: first(Data).Url,
                        templateLangKey,
                    }),
                );
                setIsUploadedImageLoading(false);
                setIsUploadBtnDisabled(true);
            },
        });
    };

    const buttonTextInputChangeHandler = (e) => {
        const value = e.target.value;
        dispatch(changeTemplateValue({ propertyName: TemplateInputTypes.ButtonText, value: value, templateLangKey }));
        validator({ [templateLangKey + 'ButtonUrl']: { text: value, url: templateData?.ButtonUrl } });
        validator({ [templateLangKey + 'ButtonText']: { text: value, url: templateData?.ButtonUrl } });
    };

    const buttonUrlInputChangeHandler = (e) => {
        const value = e.target.value;
        dispatch(changeTemplateValue({ propertyName: TemplateInputTypes.ButtonUrl, value: value, templateLangKey }));
        validator({ [templateLangKey + 'ButtonUrl']: { text: templateData?.ButtonText, url: value } });
        validator({ [templateLangKey + 'ButtonText']: { text: templateData?.ButtonText, url: value } });
    };

    const deleteUploadedImageHandler = () => {
        dispatch(
            changeTemplateValue({
                propertyName: TemplateInputTypes.UploadedImageUrl,
                value: '',
                templateLangKey,
            }),
        );
        dispatch(
            changeTemplateValue({
                propertyName: TemplateInputTypes.FileName,
                value: '',
                templateLangKey,
            }),
        );
        setIsUploadBtnDisabled(true);
        setFile(null);
    };

    const onChange = (e) => {
        const value = e.target.value;

        setBodyValue(value);
        handleChange(TemplateInputTypes.Body, value);
    };

    const onBodyFocus = useCallback(() => {
        updateDynamicVariableOptions({ hintText: '', focusedInputKey: TemplateInputTypes.Body });
    }, [updateDynamicVariableOptions]);

    const onButtonTextFocus = () => {
        updateDynamicVariableOptions({
            hintText: l.DynamicVariablesAreNotSupported,
            focusedInputKey: TemplateInputTypes.ButtonText,
        });
    };

    const onButtonUrlFocus = () => {
        updateDynamicVariableOptions({
            hintText: l.DynamicVariablesAreNotSupported,
            focusedInputKey: TemplateInputTypes.ButtonUrl,
        });
    };

    const insertTextAtCursor = (textToInsert) => {
        const { selectionStart } = inputRef.current;
        const firstPart = bodyValue.slice(0, selectionStart);
        const secondPart = bodyValue.slice(selectionStart, bodyValue.length);
        const resultValue = `${firstPart} {${textToInsert}} ${secondPart}`;
        inputRef.current.setSelectionRange(resultValue.length, resultValue.length);
        inputRef.current.focus();

        setBodyValue(resultValue);
        handleChange(TemplateInputTypes.Body, resultValue);
    };

    updateInsertionHandler(TemplateInputTypes.Body, insertTextAtCursor);

    const timePickerChangeHandler = (e) => {
        const value = e.target.value;
        const minutes = moment.duration(value).asMinutes();

        handleChange(TemplateInputTypes.ValidityPeriod, String(minutes));
    };

    return (
        <>
            <div className="crm-create-template-page-c-c-content">
                <div className="crm-messaging-row">
                    <div className="crm-messaging-row-head">
                        <p>{t(l.SymbolsCountIn, { pageName: TemplateType.Viber })}</p>
                        <span>{`(${computedBody.length} ${t(l.Bytes)} / ${
                            TemplateSymbolsCountFields[TemplateType.Viber]
                        } ${t(l.Bytes)})`}</span>
                    </div>
                    <ExtendedInput
                        ref={inputRef}
                        type="textarea"
                        value={bodyValue}
                        colorBorderOnError={true}
                        isValid={bodyValidationState.isValid}
                        errorText={bodyValidationState.errorText}
                        placeholder={t(l.TypeYourText)}
                        onChange={onChange}
                        onFocus={onBodyFocus}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={true}
                    />
                </div>
            </div>
            <div className="viber-template-validity-period-container">
                <div className="viber-template-validity-period-section">
                    <p className="viber-template-validity-period-title">{t(l.ValidityPeriod)}:</p>
                    <div className="viber-template-validity-period-input-container">
                        <TimePicker
                            appearance="outline"
                            showSeconds={false}
                            onChange={timePickerChangeHandler}
                            value={validityPeriod}
                            isValid={validityPeriodValidationState.isValid}
                            errorText={validityPeriodValidationState.errorText}
                        />
                    </div>
                </div>
            </div>
            <div className="social-template-section-container">
                <div className="social-n-details-panel">
                    <div className="social-n-details-panel-content">
                        <div>
                            <div className="social-n-details-panel-choose-file">
                                <p className="social-n-details-panel-choose-file-title"> {t(l.Media)}:</p>
                                <div className="social-n-details-panel-browse">
                                    <UploadFile
                                        value={templateData?.FileName}
                                        onChange={fileInputChangeHandler}
                                        acceptedExtensions=".svg,.png,.jpg"
                                    />
                                </div>
                                <div>
                                    <ButtonWithLoader
                                        onClick={uploadImgHandler}
                                        disabled={isUploadBtnDisabled || isUploadedImageLoading}
                                        isLoading={isUploadedImageLoading}
                                    >
                                        {t(l.Upload)}
                                    </ButtonWithLoader>
                                </div>

                                <div>
                                    <div className="social-c-file-upload-details">
                                        <p>{t(l.MediaPreview)}:</p>
                                        <div className="social-c-file-upload-details-img-wrapper">
                                            {templateData?.UploadedImageUrl && (
                                                <img alt="noImage" src={templateData?.UploadedImageUrl} />
                                            )}
                                        </div>
                                        <Icon
                                            type="bc-icon-trash"
                                            disabled={!templateData?.UploadedImageUrl}
                                            onClick={deleteUploadedImageHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social-inputs-container">
                    <ExtendedInput
                        appearance="outline"
                        inputSize="default"
                        labelAppearance="swap"
                        placeholder={t(l.ButtonText)}
                        onChange={buttonTextInputChangeHandler}
                        value={templateData?.ButtonText}
                        onFocus={onButtonTextFocus}
                        isValid={buttonTextValidationState.isValid}
                        errorText={buttonTextValidationState.errorText}
                    />
                    <ExtendedInput
                        appearance="outline"
                        inputSize="default"
                        labelAppearance="swap"
                        placeholder={t(l.ButtonUrl)}
                        onChange={buttonUrlInputChangeHandler}
                        value={templateData?.ButtonUrl}
                        onFocus={onButtonUrlFocus}
                        isValid={buttonUrlValidationState.isValid}
                        errorText={buttonUrlValidationState.errorText}
                    />
                </div>
            </div>
        </>
    );
};

ViberTemplateContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    shortenerAndTrackerSettings: PropTypes.array.isRequired,
    updateDynamicVariableOptions: PropTypes.func.isRequired,
    updateInsertionHandler: PropTypes.func.isRequired,
};

ViberTemplateContent.defaultProps = {
    focusOnMount: true,
};

export default ViberTemplateContent;
