import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Import UI Components
import { Popover, Icon, BusyLoader, Option } from '@geneui/components';
// Import Components
import { ButtonWithLoader } from 'components';
// Import Hooks
import { useOnClickOutside } from 'hooks';
// Import Constants
import { l } from 'constants/common';
// Import SCSS
import 'assets/scss/downloadToolbar.scss';

const DownloadOrSendToEmailToolbar = ({
    downloadAction,
    sendToEmailAction,
    buttonText,
    isFileLoading,
    isDisabledDownload,
}) => {
    const { t } = useTranslation();
    const toolbarRef = useRef(null);

    const [toolbarVisibilityStatus, setToolbarVisibilityStatus] = useState(false);

    const actionTypes = {
        download: 'download',
        sendtoEmail: 'sendtoEmail',
    };

    const defaultStates = {
        download: false,
        sendtoEmail: false,
    };

    const [isShowLoader, setIsShowLoader] = useState(defaultStates);

    const btnOnclickHandler = () => {
        setToolbarVisibilityStatus(!toolbarVisibilityStatus);
    };

    const sendToEmailClickHandler = () => {
        updateIsShowState(actionTypes.sendtoEmail);
        sendToEmailAction();
    };

    const downloadClickHandler = () => {
        downloadAction();
        updateIsShowState(actionTypes.download);
    };

    const updateIsShowState = (key) => {
        let states = defaultStates;
        states[key] = true;
        setIsShowLoader(states);
    };

    useOnClickOutside(
        toolbarRef,
        useCallback(() => {
            if (toolbarVisibilityStatus) {
                setToolbarVisibilityStatus(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [toolbarVisibilityStatus]),
    );

    return (
        <div className="download-send-to-email-toolbar" ref={toolbarRef}>
            <div className="download-send-to-email-toolbar-inner">
                <Popover
                    isOpen={toolbarVisibilityStatus}
                    extendTargetWidth={false}
                    minHeight={277}
                    align="end"
                    scrollbarNeeded={false}
                >
                    <>
                        <ButtonWithLoader
                            className="user-btn d-toolbar-btn"
                            appearance="minimal"
                            onClick={() => btnOnclickHandler()}
                            isLoading={isFileLoading}
                            isDisabled={isDisabledDownload}
                        >
                            {t(buttonText)}
                            <Icon type={`bc-icon-arrow-${!toolbarVisibilityStatus ? 'down' : 'up'}`} />
                        </ButtonWithLoader>

                        {toolbarVisibilityStatus && (
                            <>
                                <div className="d-toolbar-content">
                                    <div className={classNames(isFileLoading ? 'download-disable' : '')}>
                                        <div className="d-toolbar-menu-row">
                                            {(!isFileLoading || !isShowLoader.download) && (
                                                <Icon type="bc-icon-download" />
                                            )}
                                            {isShowLoader.download && (
                                                <BusyLoader type="spinner" spinnerSize="small" isBusy={isFileLoading} />
                                            )}
                                            <Option title={t(l.DownloadNow)} onClick={downloadClickHandler} />
                                        </div>
                                        <div className="d-toolbar-menu-row">
                                            {(!isFileLoading || !isShowLoader.sendtoEmail) && (
                                                <Icon type="bc-icon-message-closed" />
                                            )}
                                            {isShowLoader.sendtoEmail && (
                                                <BusyLoader type="spinner" spinnerSize="small" isBusy={isFileLoading} />
                                            )}
                                            <Option title={t(l.SendToEmail)} onClick={sendToEmailClickHandler} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                </Popover>
            </div>
        </div>
    );
};

DownloadOrSendToEmailToolbar.propTypes = {
    downloadAction: PropTypes.func.isRequired,
    sendToEmailAction: PropTypes.func.isRequired,
    isFileLoading: PropTypes.bool,
    buttonText: PropTypes.string,
    isDisabledDownload: PropTypes.bool,
};

DownloadOrSendToEmailToolbar.defaultProps = {
    isFileLoading: false,
    buttonText: l.Download,
    isDisabledDownload: false,
};

export default DownloadOrSendToEmailToolbar;
