import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Import Components
import { CJReferenceInArgumentInfoBlock } from 'components/CustomerJourney/infoBlocks';

const CJDTSameDayInfoBlock = ({ data, elements, option, blockId }) => {
    return (
        <CJReferenceInArgumentInfoBlock
            data={{
                blockId: data?.value?.params?.property?.parentValue,
                propertyName: data?.value?.params?.property?.value,
                logicFunction: data?.value?.params?.property?.logicFunction,
            }}
            elements={elements}
            option={option}
            blockId={blockId}
        />
    );
};

CJDTSameDayInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
};

export default memo(CJDTSameDayInfoBlock);
