import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import Services
import { getModalConfig } from './service';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Constants
import { l } from 'constants/common';

const CJListMenuItemDropDownInfoBlock = ({ data, customAttributes, option }) => {
    const { t } = useTranslation();

    const [listData, setListData] = useState(() => []);

    const {
        data: menuItemData,
        config,
        restore,
        isLoadingConfig,
        isLoadingRestore,
    } = useMenuItem(customAttributes.MenuItemId, false);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalData, setModalData] = useState(() => ({}));

    useEffect(() => {
        if (!isNil(config) && !isNil(data?.value)) {
            restore(data.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, data.value]);

    useEffect(() => {
        if (!isNil(data?.value)) {
            setListData(menuItemData.filter((item) => data.value.includes(item.Id)));
        }
    }, [data.value, menuItemData]);

    const openModal = useCallback((item) => {
        setModalData(item);
        setIsVisibleModal(true);
    }, []);

    const { isClickable, render } = useMemo(() => {
        const config = getModalConfig(option?.BaseTypes);
        return { isClickable: !isEmpty(config), render: config?.render };
    }, [option]);

    return (
        <>
            {!isEmpty(listData) &&
                listData.map((item, i) => {
                    return (
                        <CJInfoBlockLabelValueWrapper
                            key={i}
                            label={t(l.OptionValue)}
                            value={item?.Name}
                            isLoading={isLoadingConfig || isLoadingRestore}
                            onClick={() => openModal(item)}
                            isClickable={isClickable}
                        />
                    );
                })}

            {isVisibleModal && isClickable && render(modalData, () => setIsVisibleModal(false))}
        </>
    );
};

CJListMenuItemDropDownInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(CJListMenuItemDropDownInfoBlock);
