import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { first, isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import UI components
import { Dropdown, ExtendedInput, Popover } from '@geneui/components';
// Import constants
import { l, ScheduleModalViews, Hours, Days, WeekDaysKeyList, Months, DateFormat } from 'constants/common';
// Import UI components
import { DateSelector } from 'components';
// Import Services
import { Helpers } from 'services';
import { UtilsHttpService } from 'services/http';
import { isSchedulerUnitPairsInvalid, resultScheduleDataCreator } from 'services/schedular';

const { customMoment } = Helpers;
const { dailyView, weeklyView, monthlyView, annuallyView } = ScheduleModalViews;

const ScheduleCEView = ({
    modalView,
    handleViewChange,
    setDictData,
    dictData,
    timeOptions,
    hideDropdown,
    recurrenceData,
    schedulerValidationErrorText,
    isModalView,
}) => {
    const { key, isVisibleDay, isVisibleWeek, isVisibleMonth, isVisibleYear, isVisibleHour } = modalView;
    const { startDate, startTime, timeZone, endDate } = timeOptions;
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();

    const [hour, setHour] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const [hours, setHours] = useState([]);
    const [days, setDays] = useState([]);
    const [weekDays, setWeekDays] = useState([]);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);

    const [recurrenceDataLocal, setRecurrenceDataLocal] = useState(recurrenceData);

    const { getScheduleRunDates } = useMemo(
        () => ({
            getScheduleRunDates: UtilsHttpService.getScheduleRunDates(),
        }),
        [],
    );

    const options = [
        { label: t(l.Daily), value: dailyView },
        { label: t(l.Weekly), value: weeklyView },
        { label: t(l.Monthly), value: monthlyView },
        { label: t(l.Annually), value: annuallyView },
    ];

    const updateSchedulerInfo = (selectionsObj) => {
        if (!selectionsObj || isSchedulerUnitPairsInvalid(selectionsObj)) {
            return setRecurrenceDataLocal({
                Description: t(l.InvalidUnitPairCombination),
            });
        }

        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            endDate,
            key,
            { [key]: selectionsObj },
            timeZone,
        );

        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
    };

    const onClickHandler = (pairValues) => {
        const initialObj = {
            hours: hours,
            days: days,
            weeks: weekDays,
            months: months,
            years: years,
            hour: hour,
            day: day,
            month: month,
            year: year,
            viewType: key,
            ...pairValues,
        };

        setDictData(initialObj, key);

        if (isModalView) {
            updateSchedulerInfo(initialObj);
        }
    };

    const addHour = (e) => {
        const { value } = e.target;
        if (value > 23 || value === '0') {
            e.preventDefault();
            return;
        }
        setHour(value);
        onClickHandler({ hour: value, hours: [] });
    };

    const addDay = (e) => {
        const { value } = e.target;
        if (value > 31 || value === '0') {
            e.preventDefault();
            return;
        }
        setDay(value);
        onClickHandler({ day: value, days: [] });
    };

    const addMonth = (e) => {
        const { value } = e.target;
        if (+value > 12 || value === '0') {
            e.preventDefault();
            return;
        }
        setMonth(value);
        onClickHandler({ month: value, months: [] });
    };

    const addYear = (e) => {
        const { value } = e.target;
        if (value === '0') {
            e.preventDefault();
            return;
        }
        setYear(value);
        onClickHandler({ year: value, years: [] });
    };

    const dataListToggleHandler = (data, value) => {
        if (data.includes(value)) {
            data = data.filter((item) => item !== value);
        } else {
            data.push(value);
        }

        return data;
    };

    const addHours = (index) => {
        const data = dataListToggleHandler(hours, index);
        setHours(data.sort((nextHour, currentHour) => nextHour - currentHour));
        onClickHandler({ hour: '', hours: data });
    };

    const addDays = (index) => {
        const data = dataListToggleHandler(days, index);
        setDays(data.sort((nextDay, currentDay) => nextDay - currentDay));
        onClickHandler({ day: '', days: data });
    };

    const addWeekDays = (index) => {
        const data = dataListToggleHandler(weekDays, index);
        setWeekDays(data);
        onClickHandler({ weeks: data });
    };

    const addMonths = (index) => {
        const data = dataListToggleHandler(months, index);
        setMonths(data);
        onClickHandler({ month: '', months: data });
    };

    const addYears = (value) => {
        const data = dataListToggleHandler(years, value);
        setYears(data);
        onClickHandler({ year: '', years: data });
    };

    const dropdownOnChange = (e) => {
        setDictData({
            days: days,
            weeks: weekDays,
            months: months,
            years: years,
            day: day,
            month: month,
            year: year,
            viewType: key,
        });
        handleViewChange(e.value);
        init(e.value);
    };

    const getYears = () => {
        let years = [];
        let date = customMoment();
        let currentYear = +date.get('year');

        for (let i = 0; i <= 11; i++) {
            years.push(date.set('year', currentYear++).format('YYYY'));
        }

        return years;
    };

    const init = (viewType) => {
        // let viewTypeKey = isNil(viewType) ? key : viewType;
        let data = dictData[viewType];

        if (isNil(data)) {
            setDictData(
                {
                    hours: [],
                    days: [],
                    weeks: [],
                    months: [],
                    years: [],
                    hour: '',
                    day: '',
                    month: '',
                    year: '',
                    viewType,
                    isActivePicker: false,
                },
                viewType,
            );
        }
    };

    useEffect(() => {
        init(key);
    }, []);

    useEffect(() => {
        let data = dictData[key];

        if (!isNil(data)) {
            setHours(data.hours);
            setDays(data.days);
            setWeekDays(data.weeks);
            setMonths(data.months);
            setYears(data.years);
            setHour(data.hour);
            setDay(data.day);
            setMonth(data.month);
            setYear(data.year);
        }
        /*eslint react-hooks/exhaustive-deps:0*/
    }, [dictData, modalView]);

    useEffect(() => {
        updateSchedulerInfo(dictData[key]);
    }, [modalView]);

    const getRecCount = (cron, startDate, endDate, timeZone) => {
        if (!isNil(cron)) {
            doPostRequest(getScheduleRunDates.request, {
                requestBody: { cronExpression: cron, startDate: startDate, endDate: endDate, timeZone: timeZone },
                successCallback: (Data) => {
                    setRecurrenceDataLocal(Data);
                },
            });
        }
    };

    const getWidthForInfoBlock = () => {
        if (isVisibleYear) return 'lg';
        if (isVisibleMonth) return 'md';

        return 'sm';
    };

    return (
        <div className="scheduler-modal-wrap-inner-annually">
            {!hideDropdown && (
                <>
                    <div className="title-text">{t(l.CreateCustomRecurrence)}</div>
                    <div className="title-next-text">{t(l.CreateAMoreFlexibleScheduleForYourReport)}</div>
                </>
            )}
            {isModalView && (
                <div className={`scheduler-modal-info-wrapper  width-${getWidthForInfoBlock()}`}>
                    <p className="scheduler-modal-info-message">{recurrenceDataLocal?.Description}</p>
                    <div className="scheduler-modal-run-info-wrapper">
                        <div className="scheduler-modal-run-info">
                            <p>{t(l.RecurrenceCount)}</p>
                            <Popover
                                className="next-run-date-popover-container"
                                extendTargetWidth={false}
                                Content={recurrenceDataLocal?.UpcomingExecutions?.map((executionDate, i) => {
                                    return <p key={i}>{moment(executionDate).format(DateFormat)}</p>;
                                })}
                                align="center"
                            >
                                <button disabled={recurrenceDataLocal?.Count === 0}>
                                    {recurrenceDataLocal?.Count}
                                </button>
                            </Popover>
                        </div>
                        <div className="scheduler-modal-run-info">
                            <p>{t(l.NextRunDate)}</p>
                            <span>
                                {recurrenceDataLocal?.UpcomingExecutions &&
                                    moment(first(recurrenceDataLocal?.UpcomingExecutions)).format(DateFormat)}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {!hideDropdown && (
                <Dropdown
                    defaultValue={options.find((item) => item.value === key)}
                    isMultiSelect={false}
                    disabled={false}
                    hasSearch={true}
                    inputSize="default"
                    placeholder={options.find((item) => item.value === key)?.label}
                    appearance="outline"
                    data={options}
                    searchPlaceholderText={t(l.Search)}
                    noDataText={t(l.NoDataFound)}
                    onChange={dropdownOnChange}
                />
            )}
            <div className="scheduler-days-wrap">
                {isVisibleYear && (
                    <div className="scheduler-days-inner">
                        <div className="every-days-block">
                            <span className="every-text">{t(l.Every)}</span>
                            <ExtendedInput type="number" min={1} onChange={addYear} value={year} />
                            <span className="every-text">{t(l.years)}</span>
                        </div>
                        <div className="or-text">{t(l.Or)} </div>
                        <div className="choose-text">{t(l.ChooseCustomYear)}</div>
                        <div className={classNames({ active: true }, 'years-wrap')}>
                            <div className="years-wrap-inner">
                                <DateSelector options={getYears()} onClick={addYears} data={years}></DateSelector>
                            </div>
                        </div>
                    </div>
                )}
                {isVisibleMonth && (
                    <div className="scheduler-days-inner">
                        <div className="every-days-block">
                            <span className="every-text">{t(l.Every)}</span>
                            <ExtendedInput type="number" min={1} max={12} onChange={addMonth} value={month} />
                            <span className="every-text">{t(l.months)}</span>
                        </div>
                        <div className="or-text">{t(l.Or)} </div>
                        <div className="choose-text">{t(l.ChooseCustomMonths)}</div>
                        <div className={classNames({ active: true }, 'months-wrap')}>
                            <DateSelector
                                options={Months.map((month) => l[month])}
                                onClick={addMonths}
                                data={months}
                            ></DateSelector>
                        </div>
                    </div>
                )}
                {isVisibleWeek && (
                    <div className="weekly-wrap">
                        <div className="choose-text">{t(l.ChooseCustomWeekdays)}</div>
                        <div className="weeks">
                            <DateSelector
                                options={WeekDaysKeyList.map((weekDay) => l[weekDay])}
                                onClick={addWeekDays}
                                data={weekDays}
                            ></DateSelector>
                        </div>
                    </div>
                )}
                {isVisibleDay && (
                    <div className="scheduler-days-inner">
                        <div className="every-days-block">
                            <span className="every-text">{t(l.Every)}</span>
                            <ExtendedInput type="number" min={1} max={31} onChange={addDay} value={day} />
                            <span className="every-text">{t(l.Days)}</span>
                        </div>
                        <div className="or-text">{t(l.Or)} </div>
                        <div className="choose-text">{t(l.ChooseCustomDays)}</div>
                        <div className={classNames({ active: true }, 'days-wrap')}>
                            <DateSelector options={Days} onClick={addDays} data={days}></DateSelector>
                        </div>
                    </div>
                )}
                {isVisibleHour && (
                    <div className="scheduler-days-inner">
                        <div className="every-days-block">
                            <span className="every-text">{t(l.Every)}</span>
                            <ExtendedInput type="number" min={1} max={23} onChange={addHour} value={hour} />
                            <span className="every-text">{t(l.Hours)}</span>
                        </div>
                        <div className="or-text">{t(l.Or)} </div>
                        <div className="choose-text">{t(l.ChooseCustomHours)}</div>
                        <div className={classNames({ active: true }, 'days-wrap')}>
                            <DateSelector options={Hours} onClick={addHours} data={hours}></DateSelector>
                        </div>
                    </div>
                )}
            </div>
            {schedulerValidationErrorText && <p className="error-text">{t(schedulerValidationErrorText)}</p>}
        </div>
    );
};

ScheduleCEView.propTypes = {
    modalView: PropTypes.shape({
        key: PropTypes.string.isRequired,
        isVisibleSave: PropTypes.bool,
        isVisibleDay: PropTypes.bool,
        isVisibleWeek: PropTypes.bool,
        isVisibleMonth: PropTypes.bool,
        isVisibleYear: PropTypes.bool,
        isVisibleHour: PropTypes.bool,
    }).isRequired,
    dictData: PropTypes.object,
    handleViewChange: PropTypes.func,
    setDictData: PropTypes.func,
    hideDropdown: PropTypes.bool.isRequired,
    recurrenceData: PropTypes.object,
    schedulerValidationErrorText: PropTypes.string,
    timeOptions: PropTypes.object,
    isModalView: PropTypes.bool,
};

ScheduleCEView.defaultProps = {
    modalView: PropTypes.shape({
        isVisibleSave: false,
        isVisibleDay: false,
        isVisibleWeek: false,
        isVisibleMonth: false,
        isVisibleYear: false,
    }),
};

export default memo(ScheduleCEView);
