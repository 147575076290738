import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ParentRow from './ParentRow';
import { useTranslation } from 'react-i18next';
import Row from './Row';
import PreviewRow from './PreviewRow';
import { Checkbox, Empty, ExtendedInput } from '@geneui/components';
import { cloneDeep, isEmpty, isNil, isObject, noop, sortBy } from 'lodash';
import { usePrevious } from 'hooks';
// Import constants and services
import { getParentState, StateValues, States } from './service';
import { l } from 'constants/common';

// eslint-disable-next-line react/display-name
const Block = forwardRef(
    (
        { blockList, withParent, getUpdate, withPosition, countsLabel, title, hasInfoTooltip, withSelect, withSearch },
        ref,
    ) => {
        const { t } = useTranslation();

        const [listItemsCount, setListItemsCount] = useState(0);
        const [selectedItemsCount, setSelectedItemsCount] = useState(0);
        const [searchInput, setSearchInput] = useState('');
        const [isAllSelected, setIsAllSelected] = useState(false);
        const [blockItems, setBlockItems] = useState(blockList);

        const prevBlockItems = usePrevious(blockItems);

        useImperativeHandle(ref, () => ({
            resetSearch() {
                setSearchInput(() => '');
            },
        }));

        useEffect(() => {
            setBlockItems(() => {
                let tmpBlockList = cloneDeep(blockList);
                if (withParent) {
                    tmpBlockList.forEach((item) => {
                        item.isVisible = getParentVisibility(item.childrenList);
                    });
                } else if (withPosition) {
                    tmpBlockList = sortBy(tmpBlockList, ['index']);
                }
                return tmpBlockList;
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [blockList]);

        const getParentVisibility = (childrenList) => {
            return !isEmpty(childrenList) && childrenList.some((item) => item.isVisible);
        };

        const getItemVisibility = ({ name, searchKey = null }, searchInputNormal) => {
            return (searchKey || name).toLowerCase().includes(searchInputNormal);
        };

        const selectedAllChangeHandler = (e) => {
            const { checked } = e.currentTarget;
            setIsAllSelected(checked);

            setBlockItems((prev) => {
                const tmpBlockItems = cloneDeep(prev);
                tmpBlockItems.forEach((item) => {
                    if (item.isVisible && !item.isPermanent) {
                        item.isChecked = checked;
                        if (withParent) {
                            item.childrenList.forEach((row) => {
                                if (row.isVisible) {
                                    row.isChecked = checked;
                                }
                            });
                        }
                    }
                });

                return tmpBlockItems;
            });
        };

        const isVisibleData = (tmpListItems) => {
            for (const item of tmpListItems) {
                if (withParent) {
                    for (const row of item.childrenList) {
                        if (row.isVisible) {
                            return true;
                        }
                    }
                } else if (item.isVisible) {
                    return true;
                }
            }
            return false;
        };

        const isVisibleSelectAll = (listItems) => {
            const tmpListItems = listItems.filter((item) => !item.isPermanent);
            return isVisibleData(tmpListItems);
        };

        const blockListChanges = (blockItems) => {
            let counter = 0;
            let selectedCounter = 0;
            let tmpState = States.Invisible;

            blockItems.forEach((item) => {
                if (item.isVisible && !item.isPermanent) {
                    if (item.isChecked) {
                        tmpState |= States.Checked;
                    } else if (isNil(item.isChecked)) {
                        tmpState |= States.Intermediate;
                    } else {
                        tmpState |= States.Unchecked;
                    }
                }

                if (withParent) {
                    item.childrenList.forEach((row) => {
                        if (row.isChecked) {
                            selectedCounter++;
                        }
                        counter++;
                    });
                } else {
                    if (item.isChecked) {
                        selectedCounter++;
                    }
                    counter++;
                }
            });

            setIsAllSelected(StateValues[tmpState]);
            setSelectedItemsCount(() => selectedCounter);
            setListItemsCount(() => counter);

            if (JSON.stringify(prevBlockItems) !== JSON.stringify(blockItems)) {
                getUpdate(blockItems, selectedCounter, counter);
            }
        };

        useEffect(() => {
            blockListChanges(blockItems);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [blockItems]);

        const searchHandler = (e) => {
            const value = isObject(e) ? e.target.value : e;
            setSearchInput(value);
        };

        useEffect(() => {
            const searchInputNormal = searchInput.trim().toLowerCase();

            setBlockItems((prev) => {
                const tmpBlockItems = cloneDeep(prev);

                tmpBlockItems.forEach((item) => {
                    if (withParent) {
                        item.childrenList.forEach((childItem) => {
                            childItem.isVisible = getItemVisibility(childItem, searchInputNormal);
                        });
                        item.isVisible = getParentVisibility(item.childrenList);
                        item.isChecked = getParentState(item.childrenList);
                    } else {
                        item.isVisible = getItemVisibility(item, searchInputNormal);
                    }
                });
                return tmpBlockItems;
            });
        }, [searchInput, withParent]);

        const rowClickHandler = (index) => {
            return (isChecked) => {
                setBlockItems((prev) => {
                    const tmpBlockItems = cloneDeep(prev);
                    tmpBlockItems[index].isChecked = isChecked;
                    if (withParent) {
                        tmpBlockItems[index].childrenList.forEach((item) => {
                            if (item.isVisible) {
                                item.isChecked = isChecked;
                            }
                        });
                    }
                    return tmpBlockItems;
                });
            };
        };

        const parentRowClickHandler = (parentIndex) => {
            return (index) => {
                return (checked) => {
                    setBlockItems((prev) => {
                        const tmpBlockItems = cloneDeep(prev);
                        const tmpChildrenList = tmpBlockItems[parentIndex].childrenList;
                        tmpChildrenList[index].isChecked = checked;
                        tmpBlockItems[parentIndex].isChecked = getParentState(tmpChildrenList);
                        return tmpBlockItems;
                    });
                };
            };
        };

        const dropRowHandler = (from, to) => {
            if (from.index !== to.index) {
                setBlockItems((prev) => {
                    const tmpBlockItems = cloneDeep(prev);
                    tmpBlockItems[from.index] = { ...to, index: from.index };
                    tmpBlockItems[to.index] = { ...from, index: to.index };
                    return tmpBlockItems;
                });
            }
        };

        const getDraggingRow = (index) => {
            return () => ({ ...blockItems[index], index: index });
        };

        return (
            <div className="drag-and-drop-inner">
                <div className="d-a-d-top">
                    <p className="drag-and-drop-heading">{title}</p>
                    {withSelect && (
                        <p>
                            {countsLabel} (<span>{selectedItemsCount}</span>/<span>{listItemsCount}</span>)
                        </p>
                    )}
                </div>
                {withSearch && (
                    <div className="drag-and-drop-search">
                        <ExtendedInput
                            placeholder={t(l.Search)}
                            canClear={false}
                            onChange={searchHandler}
                            value={searchInput}
                            icon="bc-icon-search"
                        />
                    </div>
                )}

                <div className="drag-and-drop-choose">
                    {listItemsCount > 0 && isVisibleData(blockItems) ? (
                        <>
                            {withSelect && (
                                <div className="d-a-d-select-all">
                                    {isVisibleSelectAll(blockItems) && (
                                        <Checkbox
                                            label={t(l.SelectAll)}
                                            checked={isAllSelected ?? false}
                                            indeterminate={isNil(isAllSelected)}
                                            onChange={selectedAllChangeHandler}
                                        />
                                    )}
                                </div>
                            )}

                            <div className={classNames('d-a-d-list', { dragging: false })}>
                                <div className="d-a-d-scrollable-cont">
                                    {blockItems
                                        .map((item, index) => ({ item, index }))
                                        .filter(({ item }) => item.isVisible)
                                        .map(({ item, index }) => {
                                            return withParent ? (
                                                <div key={index}>
                                                    <ParentRow
                                                        {...item}
                                                        searchInput={searchInput}
                                                        onClick={rowClickHandler(index)}
                                                        onRowClick={parentRowClickHandler(index)}
                                                        hasInfoTooltip={hasInfoTooltip}
                                                        withSelect={withSelect}
                                                    />
                                                </div>
                                            ) : (
                                                <Row
                                                    key={`${item.id || item.searchKey || item.name}_${item.parentId}`}
                                                    isDraggable={withPosition}
                                                    {...item}
                                                    onClick={rowClickHandler(index)}
                                                    dropRow={dropRowHandler}
                                                    getDraggingRow={getDraggingRow(index)}
                                                    hasInfoTooltip={hasInfoTooltip}
                                                    withSelect={withSelect}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <Empty appearance="greyscale" type="data" title={t(l.NoDataToDisplay)} />
                    )}
                </div>

                <PreviewRow />
            </div>
        );
    },
);

Block.propTypes = {
    title: PropTypes.string.isRequired,
    hasInfoTooltip: PropTypes.bool.isRequired,
    countsLabel: PropTypes.string,
    blockList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
            isVisible: PropTypes.bool,
            isChecked: PropTypes.bool,
            isPermanent: PropTypes.bool,
            searchKey: PropTypes.string,
        }),
    ),
    withParent: PropTypes.bool,
    getUpdate: PropTypes.func,
    resetSearch: PropTypes.string,
    withPosition: PropTypes.bool,
    withSelect: PropTypes.bool,
    withSearch: PropTypes.bool,
};

Block.defaultProps = {
    blockList: [],
    withParent: false,
    getUpdate: noop,
    withPosition: false,
    withSelect: true,
    withSearch: true,
};

export default Block;
