import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
// Import UI components
import Button from '@geneui/components/Button';
// Import components
import { ButtonWithDropDown, ButtonWithLoader } from 'components';
// Import constants
import { l } from 'constants/common';

const ResetSave = ({
    hasAdditionalSettings,
    resetButtonClickHandler,
    disableReset,
    saveButtonClickHandler,
    buttonWithDropDownConfig,
    setMode,
    disableSave,
    isLoadingSave,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Button
                className={`crm-page-headline_button-box_button mr-${hasAdditionalSettings ? 20 : 10}`}
                appearance="outline"
                onClick={resetButtonClickHandler}
                disabled={disableReset}
            >
                {t(l.Reset)}
            </Button>

            {hasAdditionalSettings ? (
                <ButtonWithDropDown
                    name={t(l.Save)}
                    handleSave={saveButtonClickHandler}
                    buttonWithDropDownConfig={buttonWithDropDownConfig}
                    setSaveButtonLabel={setMode}
                    disabled={disableSave}
                />
            ) : (
                <ButtonWithLoader
                    isLoading={isLoadingSave}
                    className="crm-page-headline_button-box_button"
                    color="confirm"
                    onClick={saveButtonClickHandler}
                    isDisabled={disableSave}
                >
                    {t(l.Save)}
                </ButtonWithLoader>
            )}
        </>
    );
};

ResetSave.propTypes = {
    hasAdditionalSettings: PropTypes.bool,
    resetButtonClickHandler: PropTypes.func,
    disableReset: PropTypes.bool,
    saveButtonClickHandler: PropTypes.func,
    buttonWithDropDownConfig: PropTypes.object,
    setMode: PropTypes.func,
    disableSave: PropTypes.bool,
    isLoadingSave: PropTypes.bool,
};

ResetSave.defaultProps = {
    hasAdditionalSettings: false,
    resetButtonClickHandler: noop,
    disableReset: false,
    saveButtonClickHandler: noop,
    buttonWithDropDownConfig: {},
    setMode: noop,
    disableSave: false,
    isLoadingSave: false,
};

export default memo(ResetSave);
