import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Components
import { l } from 'constants/common';

const CJDTDayOfMonthInfoBlock = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.Day)} value={data?.value?.params?.dates} />
        </>
    );
};

CJDTDayOfMonthInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJDTDayOfMonthInfoBlock);
