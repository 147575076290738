import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { has, isEmpty } from 'lodash';
// Import UI Components
import { ExtendedInput, Checkbox } from '@geneui/components';
// Import Components
import { MenuItemDropdown } from 'components';
import DynamicVariables from './DynamicVariables';
import TemplateTextInput from './TemplateTextInput';
// Import Hooks
import { useBind, useFieldValidity, useInputInsertion } from 'hooks';
// Import Services
import { Helpers } from 'services';
import { TemplateType } from 'services/template';
// Import Constants
import { l, MenuItemIds, TemplateInputTypes } from 'constants/common';
// Import SCSS
import 'assets/scss/socialNotification.scss';

const { calculateTemplateBodyLengthWithRegEx } = Helpers;

const FastexNotificationTemplateContent = ({
    templateData,
    templateLangKey,
    handleChange,
    shortenerAndTrackerSettings,
    dynamicVariables,
}) => {
    const { t } = useTranslation();

    const subjectValidationState = useFieldValidity(TemplateInputTypes.Subject, l.Subject, templateLangKey);
    const bodyValidationState = useFieldValidity(TemplateInputTypes.Body, l.Body, templateLangKey);
    const categoryValidationState = useFieldValidity(
        TemplateInputTypes.NotificationCategory,
        l.Category,
        templateLangKey,
    );

    const [isInitialRender, setIsInitialRender] = useState(true);
    const [sectionUrlValue, setSectionUrlValue] = useState(templateData?.[TemplateInputTypes.SectionUrl]);
    const [bodyValue, setBodyValue] = useState(templateData?.[TemplateInputTypes.Body]);

    const { insertionHandler, updateInsertionHandler, updateFocusedInputKey, insertionOptions, setInsertionOptions } =
        useInputInsertion();

    const handleSubjectChange = useBind(handleChange, TemplateInputTypes.Subject);
    const handleBodyChange = useBind(handleChange, TemplateInputTypes.Body);
    const handleCategoryChange = useBind(handleChange, TemplateInputTypes.NotificationCategory);
    const handleSectionUrlChange = useBind(handleChange, TemplateInputTypes.SectionUrl);
    const updateSubjectInsertionHandler = useBind(updateInsertionHandler, TemplateInputTypes.Subject);
    const updateBodyInsertionHandler = useBind(updateInsertionHandler, TemplateInputTypes.Body);

    useEffect(() => setIsInitialRender(false), []);

    const templateBody = templateData?.[TemplateInputTypes.Body];
    const subjectValue = templateData?.[TemplateInputTypes.Subject];

    const inputRef = useRef(null);

    const computedBody = useMemo(() => {
        if (isEmpty(shortenerAndTrackerSettings) || isEmpty(templateBody)) return '';
        return calculateTemplateBodyLengthWithRegEx(shortenerAndTrackerSettings, templateBody);
    }, [templateBody, shortenerAndTrackerSettings]);

    const onBodyChange = (e) => {
        const value = e.target.value;

        setBodyValue(value);
        handleBodyChange(value);
    };

    const insertTextAtCursorBody = (value) => {
        const { selectionStart } = inputRef.current;
        const firstPart = bodyValue.slice(0, selectionStart);
        const secondPart = bodyValue.slice(selectionStart, bodyValue.length);
        const resultValue = `${firstPart} {${value}} ${secondPart}`;
        inputRef.current.setSelectionRange(resultValue.length, resultValue.length);
        inputRef.current.focus();

        setBodyValue(resultValue);
    };

    updateBodyInsertionHandler(insertTextAtCursorBody);

    const bodyFocusHandler = () => {
        setInsertionOptions({
            hintText: '',
            inputFilterKey: '',
        });

        updateFocusedInputKey(TemplateInputTypes.Body);
    };

    const sectionURLFocusHandler = () => {
        setInsertionOptions({
            hintText: l.DynamicVariablesAreNotSupported,
            inputFilterKey: '',
        });

        updateFocusedInputKey(TemplateInputTypes.SectionUrl);
    };

    const sectionURLChangeHandler = (e) => {
        handleSectionUrlChange(e.target.value, 0);
    };

    const categoryChangeHandler = (data) => {
        if (!has(data, 'value') || isInitialRender) return;

        handleCategoryChange(data.value);
    };

    const isImportantChangeHandler = (e) => {
        const value = e.target.checked;
        handleChange(TemplateInputTypes.IsImportant, value, 0);
    };

    const updateDynamicVariableSubjectOptions = useCallback(() => {
        setInsertionOptions({
            hintText: '',
            inputFilterKey: 'IsSubjectItem',
        });
        updateFocusedInputKey(TemplateInputTypes.Subject);
    }, [updateFocusedInputKey, setInsertionOptions]);

    return (
        <>
            <DynamicVariables
                dynamicVariables={dynamicVariables}
                insertionOptions={insertionOptions}
                activeChannel={TemplateType.FastexNotification}
                clickHandler={insertionHandler}
            />
            <div className="crm-create-template-page-c-c-h-inputs-row">
                <TemplateTextInput
                    displayNameKey={l.Subject}
                    value={subjectValue}
                    onChange={handleSubjectChange}
                    onBlur={handleSubjectChange}
                    validationState={subjectValidationState}
                    updateInsertionHandler={updateSubjectInsertionHandler}
                    onFocus={updateDynamicVariableSubjectOptions}
                />
            </div>
            <div className="crm-fnt-content">
                <div className="crm-fnt-sub-content">
                    <MenuItemDropdown
                        menuItemId={MenuItemIds.FastexNotificationCategoryGroup}
                        getUpdate={categoryChangeHandler}
                        displayName={t(l.Category)}
                        placeholder={t(l.Category)}
                        defaultValue={{ value: templateData?.[TemplateInputTypes.NotificationCategory] }}
                        isValid={categoryValidationState.isValid}
                        errorText={categoryValidationState.errorText}
                        className="crm-fnt-category-dropdown"
                    />
                    <Checkbox
                        className="crm-fnt-important-cb"
                        label={l.MarkAsImportant}
                        checked={templateData?.[TemplateInputTypes.IsImportant]}
                        onChange={isImportantChangeHandler}
                    />
                    <ExtendedInput
                        appearance="outline"
                        inputSize="default"
                        labelAppearance="swap"
                        placeholder={t(l.SectionUrl)}
                        onFocus={sectionURLFocusHandler}
                        onBlur={sectionURLChangeHandler}
                        onChange={(e) => setSectionUrlValue(e.target.value)}
                        value={sectionUrlValue}
                    />
                </div>
                <div className="crm-messaging-row">
                    <div className="crm-messaging-row-head">
                        <p>{t(l.CharactersCountIn, { pageName: TemplateType.FastexNotification })}</p>
                        <span>{computedBody.length}</span>
                    </div>
                    <div className={`crm-messaging-row-content ${bodyValidationState.isValid ? '' : 'error-color'}`}>
                        <ExtendedInput
                            ref={inputRef}
                            type="textarea"
                            value={bodyValue}
                            colorBorderOnError={true}
                            isValid={bodyValidationState.isValid}
                            errorText={bodyValidationState.errorText}
                            placeholder={t(l.TypeYourText)}
                            onBlur={onBodyChange}
                            onChange={onBodyChange}
                            onFocus={bodyFocusHandler}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

FastexNotificationTemplateContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    shortenerAndTrackerSettings: PropTypes.array.isRequired,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
};

export default FastexNotificationTemplateContent;
