import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//Import UI Components
import { Modal, Switcher, Tooltip, Icon } from '@geneui/components';
import { SchedulerMainView, ScheduleCEView } from 'components';
// Import Configs
import { ScheduleModalView } from './config';
import { noop } from 'lodash';
// Import Constants
import { ScheduleModalViews, l } from 'constants/common';

const { mainView, notRepeatView, hourlyView } = ScheduleModalView;

const Scheduler = ({
    view,
    viewMode,
    setView,
    setDictData,
    startTime,
    endDate,
    startDate,
    dictData,
    selectedDateSelector,
    setEndDate,
    setStartDate,
    setStartTime,
    getFooter,
    recurrenceData,
    isVisibleRecCount,
    startDateErrorText,
    endDateErrorText,
    startTimeErrorText,
    campaignStatus,
    pageType,
    timeZone,
    setTimeZone,
    setSelectedDateSelector,
    withSwitcher,
    isActiveSchedule,
    setIsActiveSchedule,
    hasImmediately,
    schedulerOffInfoMessage,
    schedulerValidationErrorText,
    isModalView,
    isHourlyEnabled,
}) => {
    const { t } = useTranslation();

    const [modalView, setModalView] = useState(mainView);

    const handleViewChange = (value) => {
        setModalView(ScheduleModalView[value]);
        setView(value);

        if (viewMode === 'fullView' || ScheduleModalView[value].key === hourlyView.key) {
            setDictData(
                {
                    hours: [],
                    days: [],
                    weeks: [],
                    months: [],
                    years: [],
                    hour: '',
                    day: '',
                    month: '',
                    year: '',
                    viewType: ScheduleModalView[value].key,
                    isActivePicker: false,
                },
                ScheduleModalView[value].key,
            );
        }

        setSelectedDateSelector(value);
    };

    const getCreateEditView = () => {
        return (
            <ScheduleCEView
                hideDropdown={viewMode === 'fullView'}
                modalView={modalView}
                handleViewChange={handleViewChange}
                dictData={dictData}
                setDictData={setDictData}
                recurrenceData={recurrenceData}
                schedulerValidationErrorText={schedulerValidationErrorText}
                timeOptions={{
                    startDate: startDate,
                    startTime: startTime,
                    timeZone: timeZone,
                    endDate: endDate,
                }}
                isModalView={isModalView}
            />
        );
    };

    const cronExpressionInfoMessage = useMemo(() => {
        if (selectedDateSelector === ScheduleModalViews.notRepeatView && isActiveSchedule) {
            return '';
        } else if (isActiveSchedule) {
            return recurrenceData.Description ? `(${recurrenceData.Description})` : '';
        } else {
            return `(${schedulerOffInfoMessage})`;
        }
    }, [isActiveSchedule, recurrenceData.Description, schedulerOffInfoMessage, selectedDateSelector]);

    const getMainView = () => {
        return (
            <SchedulerMainView
                handleViewChange={handleViewChange}
                startDate={startDate}
                startTime={startTime}
                startDateErrorText={startDateErrorText}
                endDateErrorText={endDateErrorText}
                startTimeErrorText={startTimeErrorText}
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                setStartTime={setStartTime}
                selectedView={selectedDateSelector}
                recurrenceData={recurrenceData}
                isVisibleRecCount={isVisibleRecCount}
                campaignStatus={campaignStatus}
                pageType={pageType}
                viewMode={viewMode}
                timeZone={timeZone}
                setTimeZone={setTimeZone}
                isHourlyEnabled={isHourlyEnabled}
            />
        );
    };

    const getContent = () => {
        if (modalView === mainView || modalView === notRepeatView) {
            return <></>;
        }
        return (
            <>
                {viewMode === 'withModal' ? (
                    <>
                        <Modal
                            background="dark-background"
                            position="center"
                            size="content-size"
                            closable={false}
                            closeOnClickOutside={true}
                            className="scheduler-modal-wrap"
                            visible={true}
                            footer={getFooter()}
                        >
                            {getCreateEditView()}
                        </Modal>
                    </>
                ) : (
                    getCreateEditView()
                )}
            </>
        );
    };

    useEffect(() => {
        setModalView(ScheduleModalView[view]);
    }, [view, dictData]);

    return (
        <>
            {withSwitcher && (
                <div className="switcher-label-holder">
                    <Switcher checked={isActiveSchedule} onChange={setIsActiveSchedule} label={t(l.SchedulerOnOff)} />
                    {hasImmediately && (
                        <Tooltip text={t(l.TooltipImmediately)}>
                            <Icon type="bc-icon-info" />
                        </Tooltip>
                    )}
                    <p className="scheduler-info-message">{cronExpressionInfoMessage}</p>
                </div>
            )}
            {!withSwitcher && <p className="scheduler-info-message-without-switcher">{cronExpressionInfoMessage}</p>}
            {(viewMode === 'withModal' || viewMode === 'fullView' || modalView === mainView) && getMainView()}
            {getContent()}
        </>
    );
};

Scheduler.propTypes = {
    viewMode: PropTypes.oneOf(['withModal', 'default', 'fullView']),
    isVisibleRecCount: PropTypes.bool,
    recurrenceData: PropTypes.object,
    view: PropTypes.string,
    selectedDateSelector: PropTypes.string,
    startTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    dictData: PropTypes.object,
    setView: PropTypes.func,
    setDictData: PropTypes.func,
    setEndDate: PropTypes.func,
    setStartDate: PropTypes.func,
    setStartTime: PropTypes.func,
    getFooter: PropTypes.func,
    startDateErrorText: PropTypes.string,
    endDateErrorText: PropTypes.string,
    startTimeErrorText: PropTypes.string,
    campaignStatus: PropTypes.number,
    pageType: PropTypes.string,
    timeZone: PropTypes.number,
    setTimeZone: PropTypes.func,
    setSelectedDateSelector: PropTypes.func,
    withSwitcher: PropTypes.bool,
    isActiveSchedule: PropTypes.bool,
    setIsActiveSchedule: PropTypes.func,
    hasImmediately: PropTypes.bool,
    schedulerOffInfoMessage: PropTypes.string,
    schedulerValidationErrorText: PropTypes.string,
    isModalView: PropTypes.bool,
    isHourlyEnabled: PropTypes.bool,
};

Scheduler.defaultProps = {
    startDateErrorText: '',
    endDateErrorText: '',
    campaignStatus: null,
    viewMode: 'default',
    withSwitcher: false,
    isActiveSchedule: false,
    setIsActiveSchedule: noop,
    hasImmediately: false,
};

export default memo(Scheduler);
