import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Icon } from '@geneui/components';
// Import constants
import { SegmentTypesLabels, l } from 'constants/common';
import { dateTimeFormat } from './config';

const SegmentWidget = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="campaign-tabs-inner">
            <div>
                <div className="campaign-tab-icon segment-icon">
                    <Icon type="bc-icon-segment" />
                </div>
                <div className="campaign-tab-content-wrapper">
                    {isNil(data) ? (
                        <div>
                            <p className="campaign-tab-heading">{t(l.Segment)}</p>
                            <p className="campaign-tab-desc">{t(l.SegmentDescription)}</p>
                        </div>
                    ) : (
                        <div>
                            <div className="campaign-tab-heading-group">
                                <p className="campaign-tab-title">{t(l.Segment)}</p>
                                <p className="campaign-tab-subtitle">{data?.title?.Name}</p>
                            </div>
                            <div className="campaign-tab-content">
                                <div className="campaign-tab-content-part">
                                    <div className="campaign-tab-content-type">
                                        <span>{t(l.Type)}:</span>
                                        <>
                                            {data.type === SegmentTypesLabels.Dynamic && (
                                                <Icon type="bc-icon-dynamic crm-segment-type-icon dynamic" />
                                            )}
                                            {data.type === SegmentTypesLabels.Static && (
                                                <Icon type="bc-icon-static crm-segment-type-icon static" />
                                            )}
                                            {data.type === SegmentTypesLabels.Composite && (
                                                <Icon type="bc-icon-composite crm-segment-type-icon composite" />
                                            )}
                                            <p>{t(SegmentTypesLabels[data.type])}</p>
                                        </>
                                    </div>
                                    <div className="campaign-tab-user-info">
                                        <span>{t(l.User)}:</span>
                                        <p>{data.user}</p>
                                    </div>
                                </div>
                                <div className="campaign-tab-content-part">
                                    <div className="campaign-tab-date">
                                        <span>{t(l.CreatedDate)}:</span>
                                        <p>{dateTimeFormat(data.createdDate)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

SegmentWidget.propTypes = {
    data: PropTypes.object,
};

SegmentWidget.defaultProps = {
    data: null,
};

export default memo(SegmentWidget);
