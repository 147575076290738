import React, { memo, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isNil, isEmpty, noop } from 'lodash';
import { CJTimeStampValidationSchema } from 'validators/schemas.validation';
// Import UI Components
import { TimePicker, ExtendedInput } from '@geneui/components';
// Import Constants
import { timeSpanDefaultValue, l } from 'constants/common';
// Import Services
import { getTimeSpanValue, getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';

const CJTimeSpan = ({ defaultValue, getUpdate }) => {
    const { t } = useTranslation();

    const dayInputRef = useRef();

    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
        },
        onSubmit: noop,
        validationSchema: CJTimeStampValidationSchema(),
    });
    const {
        values: { value },
        errors,
        setFieldValue,
        submitForm,
    } = formik;

    const timeChangeHandler = ({ target }) => {
        const tmpValue = isNil(target.value) || isEmpty(target.value.trim()) ? '00:00' : target.value;
        const tmpTimeSpan = value.split('.');

        setFieldValue('value', `${tmpTimeSpan[0]}.${tmpValue}:00`);
    };

    const dayChangeHandler = ({ target }) => {
        const replacedValue = target?.value?.replace('.', '')?.replace('-', '');
        const tempValue = isNil(replacedValue) || isEmpty(replacedValue) ? 0 : +replacedValue;
        const tmpTimeSpan = value.split('.');

        setFieldValue('value', `${tempValue}.${tmpTimeSpan[1]}`);
    };

    const onFocusDayInput = () => {
        if (dayInputRef.current?.value === '0') {
            dayInputRef.current.select();
        }
    };

    const getValue = (value) => {
        if (isNil(value)) {
            setFieldValue('value', timeSpanDefaultValue);
        }

        return getTimeSpanValue(value);
    };

    useEffect(() => {
        getUpdate({ value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = getValue(value);

    return (
        <>
            <ExtendedInput
                ref={dayInputRef}
                type="number"
                placeholder={l.Day}
                label={l.Day}
                labelAppearance="swap"
                onChange={dayChangeHandler}
                onFocus={onFocusDayInput}
                value={data?.day}
            />
            <TimePicker
                appearance="outline"
                showSeconds={false}
                minuteFormat={'mm'}
                hourFormat={'HH'}
                onChange={timeChangeHandler}
                value={data?.time}
                isValid={!errors.value}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
            />
        </>
    );
};

CJTimeSpan.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};
CJTimeSpan.defaultProps = {
    defaultValue: { value: timeSpanDefaultValue },
};

export default memo(CJTimeSpan);
