import { CampaignLiveMonitorListActionTypes as Types } from 'actions/types';
import { cloneDeep } from 'lodash';
import { campaignLiveMonitorInitialState } from 'constants/listDefaultFilters';

export default (state = cloneDeep(campaignLiveMonitorInitialState), action) => {
    switch (action.type) {
        case Types.SET_CAMPAIGN_LIVE_MONITOR_LIST_TABLE_FILTERS:
            state.tableData.filters = action.payload;
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
