const DraggableTypes = {
    LeftRow: 'LeftRow',
    RightRow: 'RightRow',
    Row: 'Row',
};

const States = {
    Invisible: 0,
    Unchecked: 1,
    Checked: 2,
    Intermediate: 3,
};

const StateValues = {
    [States.Invisible]: false,
    [States.Unchecked]: false,
    [States.Checked]: true,
    [States.Intermediate]: null,
};

const getParentState = (tmpChildrenList) => {
    let tmpState = States.Invisible;
    tmpChildrenList.forEach((item) => {
        if (item.isVisible) {
            if (item.isChecked) {
                tmpState |= States.Checked;
            } else {
                tmpState |= States.Unchecked;
            }
        }
    });
    return StateValues[tmpState];
};

export { DraggableTypes, getParentState, StateValues, States };
