import { CampaignListActionTypes as Types } from './types';

const setCampaignListTableFilters = (filters) => ({
    type: Types.SET_CAMPAIGN_LIST_TABLE_FILTERS,
    payload: filters,
});

const setCampaignListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_CAMPAIGN_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setCampaignListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_CAMPAIGN_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setCampaignListTableSorting = (sorting) => ({
    type: Types.SET_CAMPAIGN_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetCampaignListState = () => ({
    type: Types.RESET_CAMPAIGN_LIST_STATE,
});

export default {
    setCampaignListTableFilters,
    setCampaignListTablePagingPageNumber,
    setCampaignListTablePagingPageSize,
    setCampaignListTableSorting,
    resetCampaignListState,
};
