import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
// Import Constants
import { PreparingImag } from 'constants/utils';
import { l } from 'constants/common';

const Preparing = () => {
    const { t } = useTranslation();

    return (
        <div className="crm-preparing">
            <img alt="" src={PreparingImag.path} />
            <p>{t(l.CRMMigrationTittle)}</p>
            <p>{t(l.CRMMigrationDescription)}</p>
        </div>
    );
};

export default memo(Preparing);
