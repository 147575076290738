import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
// Import UI Components
import { BusyLoader } from '@geneui/components';
// Import Components
import GeneralInfo from './GeneralInfo';
import TemplateInfo from './TemplateInfo';
import { TemplateActionsButtons } from 'components';
// Import Services
import { TemplateHttpService } from 'services/http';
// Import Hooks
import { useGoToRoute, useToaster, useRequest, useQueryParams } from 'hooks';
// Import Config
import { modalsStateSetDictKeys } from 'pages/CampaignListPage/config';
// Import Constants
import { TemplateTypesLabels, l, AlertTypes } from 'constants/common';
// Import SCSS
import 'assets/scss/templateInfoModal.scss';
import { RoutesList } from 'routes';
const { warning } = AlertTypes;

const {
    TEMPLATE_CLONE_EMAIL,
    TEMPLATE_CLONE_SMS,
    TEMPLATE_CLONE_INTERNAL_MESSAGE,
    TEMPLATE_CLONE_SOCIAL_TELEGRAM,
    TEMPLATE_CLONE_PUSH_NOTIFICATION,
    TEMPLATE_CLONE_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_DYNAMIC_POP_UP,
    TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_EDIT_EMAIL,
    TEMPLATE_EDIT_SMS,
    TEMPLATE_EDIT_INTERNAL_MESSAGE,
    TEMPLATE_EDIT_SOCIAL_TELEGRAM,
    TEMPLATE_EDIT_PUSH_NOTIFICATION,
    TEMPLATE_EDIT_POP_UP_NOTIFICATION,
    TEMPLATE_EDIT_DYNAMIC_POP_UP,
    TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_SOCIAL_VIBER,
    TEMPLATE_EDIT_SOCIAL_VIBER,
} = RoutesList;

const TemplateInfoModalBody = ({
    isLoading,
    isPredefined,
    templateInfo,
    templateId,
    childItemsInfo,
    getTemplateInfo,
    withActionButtons,
    handleInfoModalActionClick,
    templateProviders,
}) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const { goToRoute } = useGoToRoute();
    const { showToaster } = useToaster();
    const { doGetRequest } = useRequest();
    const goToRouteQueryParams = useQueryParams();

    const { getCampaignsByTemplateRequest } = useMemo(
        () => ({
            getCampaignsByTemplateRequest: TemplateHttpService.getCampaignsByTemplate(),
        }),
        [],
    );

    const additional = useMemo(() => {
        if (
            !isNil(templateInfo?.DeliveryMethod) &&
            (templateInfo.DeliveryMethod === TemplateTypesLabels.PopUp ||
                templateInfo.DeliveryMethod === TemplateTypesLabels.CMSPopUp)
        ) {
            return JSON.parse(templateInfo.Body);
        }

        return null;
    }, [templateInfo]);

    // Actions part
    const deleteTemplateAction = (template) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.deleteTemplate, template);
    };

    const archiveTemplateAction = (template) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.archiveTemplate, template);
    };

    const unarchiveTemplateAction = (template) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.unarchiveTemplate, template);
    };

    const testTemplateAction = (template) => {
        if (
            (template.DeliveryMethod === TemplateTypesLabels.Email ||
                template.DeliveryMethod === TemplateTypesLabels.SMS) &&
            isEmpty(templateProviders[template.DeliveryMethod])
        ) {
            showToaster(warning, t(l.ProviderIsNotIntegrated));
        } else {
            handleInfoModalActionClick(modalsStateSetDictKeys.testTemplate, template);
        }
    };

    const goToEditTemplate = (template) => {
        const { DeliveryMethod, TemplateId } = template;
        const params = { templateId: TemplateId };
        let path;

        switch (DeliveryMethod) {
            case TemplateTypesLabels.Email:
                path = TEMPLATE_EDIT_EMAIL;
                break;
            case TemplateTypesLabels.SMS:
                path = TEMPLATE_EDIT_SMS;
                break;
            case TemplateTypesLabels.Internal:
                path = TEMPLATE_EDIT_INTERNAL_MESSAGE;
                break;
            case TemplateTypesLabels.Telegram:
                path = TEMPLATE_EDIT_SOCIAL_TELEGRAM;
                break;
            case TemplateTypesLabels.PushNotification:
                path = TEMPLATE_EDIT_PUSH_NOTIFICATION;
                break;
            case TemplateTypesLabels.PopUp:
                path = TEMPLATE_EDIT_POP_UP_NOTIFICATION;
                break;
            case TemplateTypesLabels.DynamicPopUp:
                path = TEMPLATE_EDIT_DYNAMIC_POP_UP;
                break;
            case TemplateTypesLabels.CMSPopUp:
                path = TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION;
                break;
            case TemplateTypesLabels.Viber:
                path = TEMPLATE_EDIT_SOCIAL_VIBER;
                break;
            default:
        }

        goToRoute(path, params, goToRouteQueryParams.encode({ backParams: search }));
    };

    const editTemplateAction = (template) => {
        doGetRequest(getCampaignsByTemplateRequest.request, {
            queryString: { templateId: template.TemplateId },
            successCallback: (Data) => {
                if (isEmpty(Data)) {
                    goToEditTemplate(template);
                } else {
                    handleInfoModalActionClick(modalsStateSetDictKeys.editTemplate, {
                        objects: Data.map(({ Name, Type }) => ({ Name, Type })),
                        template,
                    });
                }
            },
        });
    };

    const cloneTemplateAction = (template) => {
        const { DeliveryMethod, TemplateId } = template;
        const params = { templateId: TemplateId };

        switch (DeliveryMethod) {
            case TemplateTypesLabels.Email:
                goToRoute(TEMPLATE_CLONE_EMAIL, params);
                break;
            case TemplateTypesLabels.SMS:
                goToRoute(TEMPLATE_CLONE_SMS, params);
                break;
            case TemplateTypesLabels.Internal:
                goToRoute(TEMPLATE_CLONE_INTERNAL_MESSAGE, params);
                break;
            case TemplateTypesLabels.Telegram:
                goToRoute(TEMPLATE_CLONE_SOCIAL_TELEGRAM, params);
                break;
            case TemplateTypesLabels.PushNotification:
                goToRoute(TEMPLATE_CLONE_PUSH_NOTIFICATION, params);
                break;
            case TemplateTypesLabels.PopUp:
                goToRoute(TEMPLATE_CLONE_POP_UP_NOTIFICATION, params);
                break;
            case TemplateTypesLabels.DynamicPopUp:
                goToRoute(TEMPLATE_CLONE_DYNAMIC_POP_UP, params);
                break;
            case TemplateTypesLabels.CMSPopUp:
                goToRoute(TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION, params);
                break;
            case TemplateTypesLabels.Viber:
                goToRoute(TEMPLATE_CLONE_SOCIAL_VIBER, params);
                break;
            default:
        }
    };

    return (
        <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
            {!isNil(templateInfo) && !isLoading && (
                <>
                    {withActionButtons && (
                        <div className="crm-info-template-action-buttons-container">
                            <TemplateActionsButtons
                                data={templateInfo}
                                isInfoVisible={false}
                                isViewMode={false}
                                testTemplateAction={testTemplateAction}
                                unarchiveTemplateAction={unarchiveTemplateAction}
                                archiveTemplateAction={archiveTemplateAction}
                                deleteTemplateAction={deleteTemplateAction}
                                cloneTemplateAction={cloneTemplateAction}
                                editTemplateAction={editTemplateAction}
                            />
                        </div>
                    )}
                    <GeneralInfo
                        isPredefined={isPredefined}
                        name={templateInfo?.Name}
                        description={templateInfo?.Description}
                        type={templateInfo?.DeliveryMethod}
                        templateId={templateInfo?.TemplateId}
                        subject={templateInfo?.Subject}
                        additional={additional}
                    />

                    {templateInfo?.DeliveryMethod !== TemplateTypesLabels.PopUp &&
                        templateInfo?.DeliveryMethod !== TemplateTypesLabels.CMSPopUp && (
                            <div className="segment-modal-bottom smb-wrap">
                                <div className="title-wrapper">
                                    <p className="title-wrapper-text">{t(l.TemplateInfo)}</p>
                                    <span className="title-wrapper-line" />
                                </div>
                                <TemplateInfo
                                    defaultTemplateId={templateId}
                                    deliveryMethod={templateInfo?.DeliveryMethod}
                                    defaultBody={templateInfo?.Body}
                                    defaultSubject={templateInfo?.Subject}
                                    childItemsInfo={childItemsInfo}
                                    getTemplateInfo={getTemplateInfo}
                                />
                            </div>
                        )}
                </>
            )}
        </BusyLoader>
    );
};

TemplateInfoModalBody.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isPredefined: PropTypes.bool.isRequired,
    templateInfo: PropTypes.object,
    templateId: PropTypes.number.isRequired,
    childItemsInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    getTemplateInfo: PropTypes.func.isRequired,
    withActionButtons: PropTypes.bool,
    templateProviders: PropTypes.object,
    handleInfoModalActionClick: PropTypes.func,
};

TemplateInfoModalBody.defaultProps = {
    templateInfo: null,
    withActionButtons: false,
};

export default TemplateInfoModalBody;
