const channelIcons = {
    Email: 'bc-icon-message-closed crm-template-type-icon email',
    Internal: 'bc-icon-sms crm-template-type-icon internal',
    SMS: 'bc-icon-message-closed crm-template-type-icon sms',
    PopUp: 'bc-icon-message-closed crm-template-type-icon popup',
    DynamicPopUp: 'bc-icon-message-closed crm-template-type-icon dynamic-pop-up',
    Telegram: 'bc-icon-send-telegram-message-block crm-template-type-icon telegram',
    PushNotification: 'bc-icon-bell-outline crm-template-type-icon push-notification',
    0: 'bc-icon-message-closed crm-template-type-icon email',
    1: 'bc-icon-sms crm-template-type-icon internal',
    2: 'bc-icon-message-closed crm-template-type-icon sms',
    3: 'bc-icon-message-closed crm-template-type-icon popup',
    4: 'bc-icon-send-telegram-message-block crm-template-type-icon telegram',
    7: 'bc-icon-bell-outline crm-template-type-icon push-notification',
    12: 'bc-icon-message-closed crm-template-type-icon dynamic-pop-up',
};

export { channelIcons };
