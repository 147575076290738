import { useDispatch, useSelector } from 'react-redux';
import { keys, isEmpty, isBoolean, isNumber, isNil, isFunction } from 'lodash';
// Import actions
import { PageValidationAction } from 'actions';
// Import services
import { validationCases } from 'validators';
// Import constants
import { ErrorShowType, AlertTypes } from 'constants/common';
// Import hooks
import { useToaster } from 'hooks';

const { changePageValidation } = PageValidationAction;

const useValidator = (getCustomValidationTypeErrorText = null, ignoredFields = []) => {
    const dispatch = useDispatch();
    const { showToaster } = useToaster();
    const pageValidation = useSelector((state) => state.pageValidation);

    function getErrorText(validationPropertyValue, validationCredential, validationResultValue) {
        if (isBoolean(validationResultValue)) {
            return validationCredential.ErrorMessage;
        } else if (isNumber(validationResultValue)) {
            if (isNil(validationCredential.ErrorMessages)) {
                return validationCredential.ErrorMessage;
            } else {
                return validationCredential.ErrorMessages[validationResultValue];
            }
        } else if (!isNil(getCustomValidationTypeErrorText) && isFunction(getCustomValidationTypeErrorText)) {
            return getCustomValidationTypeErrorText({
                validationPropertyValue,
                validationCredential,
                validationResultValue,
            });
        }

        return validationResultValue;
    }

    function getWarningText(warningMessages) {
        if (
            !isEmpty(warningMessages) &&
            !isNil(getCustomValidationTypeErrorText) &&
            isFunction(getCustomValidationTypeErrorText)
        ) {
            return getCustomValidationTypeErrorText({ validationResultValue: warningMessages });
        }
        return null;
    }

    function singleValidator(label, value) {
        const validationCredentials = pageValidation[label].validationCredentials;

        let foundValidationCredential = false;
        let validationResultValue = false;
        let warningMessages = [];

        for (let index = 0; index < validationCredentials.length; index++) {
            const validationCredential = validationCredentials[index];
            validationResultValue = validationCases(validationCredential, value);
            if (validationResultValue) {
                if (!isNil(validationResultValue?.errorMessages)) {
                    if (!isEmpty(validationResultValue?.warningMessages)) {
                        warningMessages = [...warningMessages, ...validationResultValue.warningMessages];
                    }
                    if (!isEmpty(validationResultValue.errorMessages)) {
                        validationResultValue = validationResultValue.errorMessages;
                        foundValidationCredential = validationCredentials[index];
                        break;
                    }
                    continue;
                }

                foundValidationCredential = validationCredentials[index];
                break;
            }
        }

        const warningText = getWarningText(warningMessages);

        if (foundValidationCredential) {
            const errorText = getErrorText(value, foundValidationCredential, validationResultValue);

            return {
                ...pageValidation[label],
                isValid: false,
                errorText: errorText,
                warningText: warningText,
                paramValue: foundValidationCredential.ParametersValues,
                value,
                ...(foundValidationCredential.TranslationVariables && {
                    translationVariables: foundValidationCredential.TranslationVariables,
                }),
            };
        } else {
            return {
                ...pageValidation[label],
                isValid: true,
                errorText: '',
                value,
                warningText: warningText,
            };
        }
    }

    function validationChecker(data, validateAll) {
        let hasInvalid = false;
        let errorMessages = '';

        const resultValidationScenario = keys(data).reduce((acc, key) => {
            if (pageValidation[key]) {
                const isValidated =
                    validateAll && pageValidation[key].isValid && pageValidation[key].forceValidation !== true
                        ? pageValidation[key]
                        : singleValidator(
                              key,
                              validateAll ? pageValidation[key].value : data[key],
                              pageValidation[key].validationCredentials,
                              pageValidation[key],
                              validateAll,
                          );
                acc[key] = isValidated;
                if (
                    !isValidated.isValid &&
                    key !== 'Name' &&
                    key !== 'Description' &&
                    ignoredFields.every((field) => !key.startsWith(field))
                ) {
                    hasInvalid = true;
                    if (isValidated.type === ErrorShowType.Toaster) {
                        errorMessages += isValidated.errorText + '\n';
                    }
                }
            }

            return acc;
        }, {});

        if (hasInvalid && validateAll && !isEmpty(errorMessages)) {
            showToaster(AlertTypes.error, errorMessages);
        }
        dispatch(changePageValidation(resultValidationScenario));
        return hasInvalid;
    }

    function validator(data) {
        if (!isEmpty(pageValidation)) {
            if (data) {
                return validationChecker(data, false);
            }
            return validationChecker(pageValidation, true);
        }
    }

    return validator;
};

export default useValidator;
