import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Import Services
import { Helpers } from 'services';
// Import Components
import CJPartnerBonusIdInfoBlock from './CJPartnerBonusIdInfoBlock';
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Constants
import { l, BonusTypesLabels } from 'constants/common';

const { getBonusSupportedCurrencies } = Helpers;

const CJBonusInfoBlock = ({ data, customAttributes }) => {
    const { t } = useTranslation();

    const [bonusData, setBonusData] = useState({});

    const secondaryCurrencyCode = useSelector((state) => state.partnerSettings.SecondaryCurrencyId);

    const isVisibleUseSecondaryCurrency = useMemo(() => {
        const bonusDetails = bonusData?.BonusDetails ?? [];

        return (
            !isEmpty(secondaryCurrencyCode) &&
            (((bonusDetails.length > 1 || data?.triggerType !== 0) &&
                getBonusSupportedCurrencies(bonusData).includes(secondaryCurrencyCode)) ||
                bonusData.Type === BonusTypesLabels.FreeSpin)
        );
    }, [bonusData, secondaryCurrencyCode, data]);

    return (
        <div>
            <CJPartnerBonusIdInfoBlock
                data={{ value: data.bonusId }}
                customAttributes={customAttributes}
                additionalDataUpdate={setBonusData}
            />
            {isVisibleUseSecondaryCurrency && (
                <CJInfoBlockLabelValueWrapper
                    label={t(l.UseSecondaryCurrency, { currencyCode: secondaryCurrencyCode })}
                    value={data.useSecondaryCurrency ? t(l.On) : t(l.Off)}
                />
            )}
            {data.amounts.map(({ CurrencyCode, OriginalValue }, i) => (
                <CJInfoBlockLabelValueWrapper
                    key={i}
                    label={`${t(l.Amount)} ${CurrencyCode}`}
                    value={OriginalValue}
                    isLoading={false}
                />
            ))}
        </div>
    );
};

CJBonusInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
};

export default memo(CJBonusInfoBlock);
