/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Import Hooks
import { useHasPermission } from 'hooks';

const AccessControl = ({ permissions, mandatoryPermissions, children, partnerSetting }) => {
    const [permissionDenied, setPermissionDenied] = useState(true);
    const [partnerSettingsDenied, setPartnerSettingsDenied] = useState(true);
    const { hasPermission, hasPartnerSettings } = useHasPermission();

    useEffect(() => {
        if (!isEmpty(partnerSetting)) {
            setPartnerSettingsDenied(hasPartnerSettings(partnerSetting));
        }
        if (isEmpty(mandatoryPermissions) && !isEmpty(permissions)) {
            setPermissionDenied(!hasPermission(permissions));
        } else {
            // Should have all permissions in user permission list
            setPermissionDenied(!hasPermission(mandatoryPermissions, true));
        }
    }, [permissions]);
    return !permissionDenied & partnerSettingsDenied ? children : <></>;
};

AccessControl.propTypes = {
    permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    mandatoryPermissions: PropTypes.array,
    partnerSetting: PropTypes.string,
};

AccessControl.defaultProps = {
    permissions: [],
    mandatoryPermissions: [],
};

export default memo(AccessControl);
