import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { has, isEmpty } from 'lodash';
// Import Services
import { CampaignHttpService } from 'services/http';
// Import Components
import { ChangeModeCampaignModal, CampaignInfoModal, CampaignAddGoalModal, ConfirmationModal } from 'components';
// Import Hooks
import { useRequest } from 'hooks';
// Import Config
import { modalsStateSetDictKeys } from 'pages/CampaignListPage/config';
// Import Constants
import { ModalsActions, l, ActionsIcons, ModalsClassNames, FilterComparisions, GoalOptions } from 'constants/common';

const CampaignActionsModals = (props) => {
    const { t } = useTranslation();
    const { doDeleteRequest, doPostRequest } = useRequest();

    const [isLoading, setIsLoading] = useState(false);

    const {
        isDeleteGoalModalOpenedState,
        isDeleteModalOpenedState,
        isArchiveModalOpenedState,
        isUnArchiveModalOpenedState,
        isStopSendModalOpenedState,
        isChangeModeCampaignModalOpenedState,
        isInfoModalOpenedState,
        isAddGoalModalOpenedState,
        onCloseModalHandler,
        openedModalData,
        handleInfoModalActionClick,
        templateProviders,
    } = props;

    const {
        archiveCampaignRequest,
        archiveCampaignsRequest,
        unarchiveCampaignRequest,
        unarchiveCampaignsRequest,
        deleteCampaignRequest,
        deleteCampaignsRequest,
        stopSendCampaignRequest,
        changeModeCampaignRequest,
        createGoalCampaign,
        deleteGoalCampaignRequest,
    } = useRef({
        deleteCampaignRequest: CampaignHttpService.deleteCampaign(),
        deleteCampaignsRequest: CampaignHttpService.deleteCampaigns(),
        archiveCampaignRequest: CampaignHttpService.archiveCampaign(),
        archiveCampaignsRequest: CampaignHttpService.archiveCampaigns(),
        unarchiveCampaignRequest: CampaignHttpService.unarchiveCampaign(),
        unarchiveCampaignsRequest: CampaignHttpService.unarchiveCampaigns(),
        stopSendCampaignRequest: CampaignHttpService.stopSendCampaigns(),
        changeModeCampaignRequest: CampaignHttpService.changeModeCampaign(),
        createGoalCampaign: CampaignHttpService.setCampaignGoal(),
        deleteGoalCampaignRequest: CampaignHttpService.deleteCampaignGoal(),
    }).current;

    const deleteCampaign = () => {
        setIsLoading(true);
        doDeleteRequest(deleteCampaignRequest.request, {
            queryString: { campaignId: openedModalData.CampaignId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDictKeys.deleteCampaign, ModalsActions.DELETE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const deleteCampaigns = () => {
        setIsLoading(true);
        const { allowDeleteCampaigns } = openedModalData;
        if (isEmpty(allowDeleteCampaigns)) {
            return onCloseModalHandler(modalsStateSetDictKeys.deleteCampaign);
        }
        const allowDeleteCampaignsIds = allowDeleteCampaigns.map(({ campaignId }) => {
            return campaignId;
        });
        doDeleteRequest(deleteCampaignsRequest.request, {
            queryString: allowDeleteCampaignsIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDictKeys.deleteCampaign,
                ModalsActions.DELETE,
                AlertType,
                AlertMessage,
                allowDeleteCampaignsIds.length,
            );
            setIsLoading(false);
        });
    };

    const archiveCampaign = () => {
        setIsLoading(true);
        doPostRequest(archiveCampaignRequest.request, {
            requestBody: null,
            queryString: { campaignId: openedModalData.CampaignId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDictKeys.archiveCampaign, ModalsActions.ARCHIVE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const archiveCampaigns = () => {
        setIsLoading(true);
        const { allowArchiveCampaigns } = openedModalData;
        if (isEmpty(allowArchiveCampaigns)) {
            return onCloseModalHandler(modalsStateSetDictKeys.archiveCampaign);
        }
        const allowArchiveCampaignsIds = allowArchiveCampaigns.map(({ campaignId }) => {
            return campaignId;
        });
        doPostRequest(archiveCampaignsRequest.request, {
            requestBody: allowArchiveCampaignsIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDictKeys.archiveCampaign,
                ModalsActions.ARCHIVE,
                AlertType,
                AlertMessage,
                allowArchiveCampaignsIds.length,
            );
            setIsLoading(false);
        });
    };

    const unarchiveCampaign = () => {
        setIsLoading(true);
        doPostRequest(unarchiveCampaignRequest.request, {
            requestBody: null,
            queryString: { campaignId: openedModalData.CampaignId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDictKeys.unarchiveCampaign,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const unarchiveCampaigns = () => {
        setIsLoading(true);
        const { allowUnarchiveCampaigns } = openedModalData;
        if (isEmpty(allowUnarchiveCampaigns)) {
            return onCloseModalHandler(modalsStateSetDictKeys.unarchiveCampaign);
        }
        const allowUnarchiveCampaignsIds = allowUnarchiveCampaigns.map(({ campaignId }) => {
            return campaignId;
        });
        doPostRequest(unarchiveCampaignsRequest.request, {
            requestBody: allowUnarchiveCampaignsIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDictKeys.unarchiveCampaign,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
                allowUnarchiveCampaignsIds.length,
            );
            setIsLoading(false);
        });
    };

    const stopSendCampaign = () => {
        setIsLoading(true);
        doPostRequest(stopSendCampaignRequest.request, {
            requestBody: null,
            queryString: { campaignId: openedModalData.CampaignId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDictKeys.stopSendCampaign, ModalsActions.STOP, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const changeModeCampaign = (liveMode) => {
        setIsLoading(true);
        doPostRequest(changeModeCampaignRequest.request, {
            requestBody: {
                CommentText: '', // TODO: should be removed after update DAL nullable property
                ObjectId: openedModalData.CampaignId,
                ObjectType: 3,
                State: liveMode,
            },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDictKeys.changeModeCampaign,
                ModalsActions.CHANGE_MODE_CAMPAIGN,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const setGoalCampaign = (type, count) => {
        setIsLoading(true);
        doPostRequest(createGoalCampaign.request, {
            requestBody: {
                campaignId: openedModalData?.CampaignId,
                Comparision: FilterComparisions.GreaterOrEquals,
                Name: `${GoalOptions[type]} = ${count}`,
                Type: type,
                Value: count,
            },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDictKeys.setGoalCampaign,
                ModalsActions.SET_GOAL,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };
    const deleteGoalCampaign = () => {
        setIsLoading(true);
        doDeleteRequest(deleteGoalCampaignRequest.request, {
            queryString: {
                id: openedModalData?.GoalId,
            },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDictKeys.deleteGoalCampaign,
                ModalsActions.DELETE_GOAL,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const isBulkAction = () => {
        return (
            has(openedModalData, 'allowDeleteCampaigns') ||
            has(openedModalData, 'allowArchiveCampaigns') ||
            has(openedModalData, 'allowUnarchiveCampaigns')
        );
    };

    const cleanUp = () => {
        return () => {
            deleteCampaignRequest.cancel('CampaignListPage:deleteCampaignRequest');
            archiveCampaignRequest.cancel('CampaignListPage:archiveCampaignRequest');
            unarchiveCampaignRequest.cancel('CampaignListPage:unarchiveCampaignRequest');
            stopSendCampaignRequest.cancel('CampaignListPage:stopSendCampaignRequest');
            changeModeCampaignRequest.cancel('CampaignListPage:changeModeCampaignRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        !isEmpty(openedModalData) && (
            <>
                {isDeleteModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? deleteCampaigns : deleteCampaign}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowDeleteCampaigns) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDictKeys.deleteCampaign)}
                        isVisibleModal={isDeleteModalOpenedState}
                        titleText={isBulkAction() ? t(l.DeleteSelectedCampaigns) : t(l.DeleteCampaign)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Delete}
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                        allowDataList={openedModalData.allowDeleteCampaigns}
                        notAllowDataList={openedModalData.notAllowDeleteCampaigns}
                        allowDataLabel={l.AllowDeleteSelectedCampaigns}
                        notAllowDataLabel={l.NotAllowDeleteSelectedCampaigns}
                        questionLabel={
                            isBulkAction()
                                ? l.DeleteSelectedCampaignsQuestion
                                : t(l.AreYouSureYouWantToDeleteCampaign, {
                                      campaignName: `"${openedModalData.Name.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? archiveCampaigns : archiveCampaign}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowArchiveCampaigns) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDictKeys.archiveCampaign)}
                        isVisibleModal={isArchiveModalOpenedState}
                        titleText={isBulkAction() ? t(l.ArchiveSelectedCampaigns) : t(l.ArchiveCampaign)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Archive}
                        actionLabel={t(l.Archive)}
                        className={ModalsClassNames.Archive}
                        allowDataList={openedModalData.allowArchiveCampaigns}
                        notAllowDataList={openedModalData.notAllowArchiveCampaigns}
                        allowDataLabel={l.AllowArchiveSelectedCampaigns}
                        notAllowDataLabel={l.NotAllowArchiveSelectedCampaigns}
                        questionLabel={
                            isBulkAction()
                                ? l.ArchiveSelectedCampaignsQuestion
                                : t(l.AreYouSureYouWantToArchiveCampign, {
                                      campaignName: `"${openedModalData.Name.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isUnArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? unarchiveCampaigns : unarchiveCampaign}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowUnarchiveCampaigns) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDictKeys.unarchiveCampaign)}
                        isVisibleModal={isUnArchiveModalOpenedState}
                        titleText={isBulkAction() ? t(l.UnarchiveSelectedCampaigns) : t(l.UnarchiveCampaign)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.UnArchive}
                        actionLabel={t(l.Unarchive)}
                        className={ModalsClassNames.UnArchive}
                        allowDataList={openedModalData.allowUnarchiveCampaigns}
                        notAllowDataList={openedModalData.notAllowUnarchiveCampaigns}
                        allowDataLabel={l.AllowUnarchiveSelectedCampaigns}
                        notAllowDataLabel={l.NotAllowUnarchiveSelectedCampaigns}
                        questionLabel={
                            isBulkAction()
                                ? l.UnarchiveSelectedCampaignsQuestion
                                : t(l.AreYouSureYouWantToUnarchiveCampaign, {
                                      campaignName: `"${openedModalData.Name.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}
                {isStopSendModalOpenedState && (
                    <ConfirmationModal
                        onOk={stopSendCampaign}
                        onCancel={() => onCloseModalHandler(modalsStateSetDictKeys.stopSendCampaign)}
                        isVisibleModal={isStopSendModalOpenedState}
                        titleText={t(l.StopCampaign)}
                        questionLabel={t(l.AreYouSureYouWantToStopCampign, {
                            campaignName: `"${openedModalData.Name.Name}"`,
                        })}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Stop}
                        actionLabel={t(l.Stop)}
                        className={ModalsClassNames.Stop}
                    />
                )}
                {isChangeModeCampaignModalOpenedState && (
                    <ChangeModeCampaignModal
                        currentMode={openedModalData.State}
                        onOk={changeModeCampaign}
                        onCancel={() => onCloseModalHandler(modalsStateSetDictKeys.changeModeCampaign)}
                        isVisibleModal={isChangeModeCampaignModalOpenedState}
                        titleText={t(l.StopCampaign)}
                        isLoading={isLoading}
                    />
                )}
                {isInfoModalOpenedState && (
                    <CampaignInfoModal
                        campaignId={openedModalData.CampaignId}
                        handleClose={() => onCloseModalHandler(modalsStateSetDictKeys.infoCampaign)}
                        isVisible={isInfoModalOpenedState}
                        data={openedModalData}
                        handleInfoModalActionClick={handleInfoModalActionClick}
                        templateProviders={templateProviders}
                    />
                )}

                {isAddGoalModalOpenedState && (
                    <CampaignAddGoalModal
                        data={openedModalData}
                        isVisibleModal={isAddGoalModalOpenedState}
                        onCancel={() => onCloseModalHandler(modalsStateSetDictKeys.setGoalCampaign)}
                        onOk={setGoalCampaign}
                        isLoading={isLoading}
                    />
                )}
                {isDeleteGoalModalOpenedState && (
                    <ConfirmationModal
                        onOk={deleteGoalCampaign}
                        onCancel={() => onCloseModalHandler(modalsStateSetDictKeys.deleteGoalCampaign)}
                        isVisibleModal={isDeleteGoalModalOpenedState}
                        titleText={t(l.DeleteGoalCampaign)}
                        questionLabel={t(l.AreYouSureYouWantToDeleteGoalCampaign, {
                            campaignName: `"${openedModalData.Name.Name}"`,
                        })}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Delete}
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                    />
                )}
            </>
        )
    );
};

CampaignActionsModals.propTypes = {
    isDeleteModalOpenedState: PropTypes.bool.isRequired,
    isArchiveModalOpenedState: PropTypes.bool.isRequired,
    isUnArchiveModalOpenedState: PropTypes.bool.isRequired,
    isStopSendModalOpenedState: PropTypes.bool.isRequired,
    isInfoModalOpenedState: PropTypes.bool.isRequired,
    isChangeModeCampaignModalOpenedState: PropTypes.bool.isRequired,
    isAddGoalModalOpenedState: PropTypes.bool.isRequired,
    isDeleteGoalModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    openedModalData: PropTypes.any.isRequired,
    handleInfoModalActionClick: PropTypes.func,
    templateProviders: PropTypes.object,
};

export default memo(CampaignActionsModals);
