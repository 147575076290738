import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil, isString } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// Import Constants
import { l, ModalsClassNames, RouteLeaveConfirmationType, NavigationActionTypes } from 'constants/common';
// Import Components
import { ConfirmationModal } from 'components';
// Import Hooks
import { useUserConfirmation } from 'hooks';
// Import Actions
import { RouteLeaveConfirmationAction } from 'actions';

const { resetHasDataChange, setPageName } = RouteLeaveConfirmationAction;

const RouteLeaveConfirmation = ({ routeLeaveConfirmationType, pageName }) => {
    const { t } = useTranslation();

    const { getConfirmationCallback, setConfirmationCallback } = useUserConfirmation();

    const hasDataChange = useSelector((state) => state?.routeLeaveConfirmation?.hasDataChange);
    const isChangeSaved = useSelector((state) => state?.routeLeaveConfirmation?.isChangeSaved);

    const dispatch = useDispatch();

    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [when, setWhen] = useState(routeLeaveConfirmationType === RouteLeaveConfirmationType.Always);

    const navigationActionTypeRef = useRef(NavigationActionTypes.PUSH);
    const isNavigationAcceptedRef = useRef(false);
    const nextLocationRef = useRef(null);

    const init = () => {
        dispatch(setPageName(pageName));
    };

    const cleanUp = () => {
        return () => {
            dispatch(resetHasDataChange());
        };
    };

    useEffect(init, []);

    useEffect(cleanUp, []);

    useEffect(() => {
        if (
            isChangeSaved !== true &&
            (routeLeaveConfirmationType === RouteLeaveConfirmationType.ChangeRelated ||
                (routeLeaveConfirmationType !== RouteLeaveConfirmationType.Always && hasDataChange === null))
        ) {
            setWhen(hasDataChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasDataChange]);

    useEffect(() => {
        if (isChangeSaved) {
            setWhen(false);
        }
    }, [isChangeSaved]);

    const showModal = (location, actionType) => {
        const tmpActionType =
            isNil(actionType) && isString(actionType) ? NavigationActionTypes.PUSH : actionType.toUpperCase();
        navigationActionTypeRef.current = isNil(NavigationActionTypes[tmpActionType])
            ? NavigationActionTypes.PUSH
            : NavigationActionTypes[tmpActionType];

        setIsConfirmationModalVisible(true);
        nextLocationRef.current = location;
    };

    const navigationBlockHandler = (nextLocation, actionType) => {
        if (!isNavigationAcceptedRef.current) {
            showModal(nextLocation, actionType);
            // return empty string for calling BrowserRouter getUserConfirmation
            return '';
        }
        isNavigationAcceptedRef.current = false;
        return true;
    };

    const acceptNavigation = () => {
        dispatch(resetHasDataChange());
        isNavigationAcceptedRef.current = true;
        navigation(true);
    };

    const navigation = (isAccept) => {
        setIsConfirmationModalVisible(false);
        const callback = getConfirmationCallback();
        setConfirmationCallback(null);
        callback(isAccept);
    };

    return (
        <>
            <Prompt when={when} message={navigationBlockHandler} />
            <ConfirmationModal
                isVisibleModal={isConfirmationModalVisible}
                onOk={() => navigation(false)}
                onCancel={acceptNavigation}
                titleText={t(l.LeavePage)}
                questionLabel={t(l.YouHaveUnsavedChangesAreYouSureYouWantToLeavePage, { pageName: t(pageName) })}
                actionLabel={l.Stay}
                cancelLabel={l.Leave}
                className={ModalsClassNames.Warning}
                iconType="bc-icon-danger-48"
                isLoading={false}
            />
        </>
    );
};

RouteLeaveConfirmation.propTypes = {
    routeLeaveConfirmationType: PropTypes.number.isRequired,
    pageName: PropTypes.string.isRequired,
};

export default memo(RouteLeaveConfirmation);
