import { MaintenanceActionTypes as Types } from './types';

const setMaintenanceSettings = (settings) => {
    return {
        type: Types.SET_MAINTENANCE_SETTINGS,
        payload: settings,
    };
};

const clearMaintenanceSettings = () => {
    return {
        type: Types.CLEAR_MAINTENANCE_SETTINGS,
        payload: null,
    };
};

export default {
    setMaintenanceSettings,
    clearMaintenanceSettings,
};
