import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import UI components
import { Button } from '@geneui/components';

import { DataTable } from 'components';
// Import constants
import { l } from 'constants/common';

const ManageSetContent = ({
    data,
    dataCount,
    tableIsLoaded,
    columns,
    createHandler,
    editHandler,
    deleteHandler,
    setObjectCategoryId,
    setIsDisableSave,
    selectedLabelId,
}) => {
    const { t } = useTranslation();

    const [selectedLabel, setSelectedLabel] = useState(selectedLabelId);

    const selectLabelHandler = (value) => {
        setSelectedLabel(value);
        setObjectCategoryId(value);
        setIsDisableSave(false);
    };

    const getDataTableHeaderActions = () => {
        return (
            <Button onClick={createHandler} icon="bc-icon-plus-crm">
                {t(l.CreateLabel)}
            </Button>
        );
    };

    const getTableRows = () => {
        return data.map(({ Name, Description, ObjectCategoryId, Color }, index) => ({
            hasHover: true,
            dragDisable: true,
            isChecked: selectedLabel === ObjectCategoryId,
            selectLabelHandler: selectLabelHandler,
            data: {
                index,
                Name,
                Description,
                ObjectCategoryId,
                Color,
            },
        }));
    };

    const rowActionBar = (row) => {
        return (
            <>
                <Button
                    icon="bc-icon-delete-2"
                    appearance="minimal"
                    color="primary"
                    onClick={() => deleteHandler(row.data)}
                />
                <Button
                    icon="bc-icon-edit"
                    appearance="minimal"
                    color="primary"
                    onClick={() => editHandler(row.data)}
                />
            </>
        );
    };

    return (
        <DataTable
            rowKey="index"
            columnKey="dataKey"
            rowActionBar={rowActionBar}
            withPagination={false}
            withSearch={true}
            withPageSelector={false}
            headerActions={getDataTableHeaderActions()}
            data={getTableRows()}
            dataCount={dataCount}
            columns={columns}
            isLoading={tableIsLoaded}
        />
    );
};

ManageSetContent.propTypes = {
    selectedLabelId: PropTypes.number,
    tableIsLoaded: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    dataCount: PropTypes.number.isRequired,
    createHandler: PropTypes.func.isRequired,
    editHandler: PropTypes.func.isRequired,
    setObjectCategoryId: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    setIsDisableSave: PropTypes.func.isRequired,
};

ManageSetContent.defaultProps = {
    selectedLabelId: 0,
};

export default memo(ManageSetContent);
