import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Jodit from 'jodit-react';
// Import Constants
import { l } from 'constants/common';
// Import services
import { replaceDomainFromEnvironmentURL } from 'services/http';
// Import SCSS
import 'assets/scss/joditEditor.scss';

const JoditEditor = ({ initialValue, onBlur, onChange, updateInsertionHandler, onFocus }) => {
    const { t } = useTranslation();

    const { accessToken } = useSelector((state) => state.auth);
    const { Appearance: uiTheme } = useSelector((state) => state.userData.Settings);
    const baseUrl = replaceDomainFromEnvironmentURL(process.env.REACT_APP_CRM_GALLERY_URL);
    const imageUploadUrl = `${baseUrl}Save/Image`;
    const imagesListUrl = `${baseUrl}List/Image`;
    const ref = useRef();

    const changeHandler = useCallback(
        (value) => {
            onChange(value);
        },
        [onChange],
    );

    useEffect(() => {
        if (!ref.current) return;

        const editor = ref.current.component.currentPlace.editor;
        editor.addEventListener('focus', onFocus);

        return () => {
            editor.removeEventListener('focus', onFocus);
        };
    }, [onFocus, ref]);

    const config = useMemo(() => {
        return {
            readonly: false, // all options from https://xdsoft.net/jodit/docs/
            theme: uiTheme === 1 ? 'dark' : '',
            placeholder: t(l.TypeYourText),
            removeButtons: [
                'classSpan',
                'file',
                'video',
                'speechRecognize',
                'spellcheck',
                'print',
                'about',
                'copy',
                'paste',
            ],
            uploader: {
                url: imageUploadUrl,
                isSuccess: (resp) => !resp.HasError,
                defaultHandlerSuccess: (successData) => {
                    if (!isEmpty(successData.files)) {
                        successData.files.forEach((fileName) => {
                            ref.current.component.selection.insertImage(fileName);
                        });
                    }
                },
                process: (resp) => ({
                    files: resp.Data.map((imgData) => {
                        return imgData.Url;
                    }),
                }),
                headers: {
                    Authentication: accessToken,
                },
            },
            height: 400,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            image: {
                useImageEditor: false,
            },
            filebrowser: {
                permissionsPresets: {
                    allowFileRemove: false,
                    allowFileRename: false,
                    allowFolders: false,
                    allowFolderCreate: false,
                    allowFolderMove: false,
                    allowFolderRemove: false,
                    allowFolderRename: false,
                    allowImageResize: false,
                    allowImageCrop: false,
                },
                getMessage: (resp) => resp.message,
                isSuccess: (res) => !res.HasError,
                showFileName: true,
                showFoldersPanel: false,
                saveStateInStorage: true,
                ajax: {
                    url: imagesListUrl, // URL of the file browser endpoint
                    process: (res) => {
                        return {
                            success: true,
                            time: '2021-12-23 16:30:01',
                            data: {
                                sources: [
                                    {
                                        path: '',
                                        baseurl: '',
                                        files: res.Data.map((imgData) => {
                                            return {
                                                type: 'image',
                                                name: imgData.Url,
                                                size: '250kb',
                                                thumb: `${imgData.Url}?method=cover&width=150&height=150&`,
                                            };
                                        }),
                                        name: 'default',
                                    },
                                ],
                                code: 220,
                            },
                        };
                    },
                    method: 'GET',
                    headers: {
                        Authentication: accessToken,
                    },
                },
            },
        };
    }, [t, accessToken, imageUploadUrl, imagesListUrl, ref, uiTheme]);

    const insertTextAtCursor = (textToInsert) => {
        ref.current.component.selection.insertHTML(` {${textToInsert}} `);
    };

    updateInsertionHandler(insertTextAtCursor);

    return (
        <Jodit
            ref={ref}
            value={initialValue}
            config={config}
            tabIndex={0}
            onBlur={onBlur} // preferred to use ONLY this option to update the content for performance reasons
            onChange={changeHandler}
        />
    );
};

JoditEditor.propTypes = {
    initialValue: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    updateInsertionHandler: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};

export default JoditEditor;
