import { isFunction, noop } from 'lodash';

const confirmationData = { callback: noop };

const useUserConfirmation = () => {
    const setConfirmationCallback = (callback) => {
        confirmationData.callback = !isFunction(callback) ? noop : callback;
    };

    const getConfirmationCallback = () => {
        return confirmationData.callback;
    };

    return { getConfirmationCallback, setConfirmationCallback };
};

export default useUserConfirmation;
