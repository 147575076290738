/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, values, first, trim, includes } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import {
    ModuleTitle,
    Empty,
    Icon,
    ExtendedInput,
    Tabs,
    Tab,
    Tooltip,
    Button,
    Divider,
    Modal,
    BusyLoader,
} from '@geneui/components';
// Import Components
import FilterSchemaList from './FilterSchemaList';
import SegmentReportPreviewModal from 'components/SegmentReportPreviewModal';
// Import Reducers
import { DynamicSegmentActions, DynamicSegmentTabActiveIndexAction } from 'actions';
// Import Services
import { createQueryConfigurationsFromFilters } from 'services/segment';
import { SegmentHttpService } from 'services/http';
// Import Hooks
import { useValidator, useRequest, useAutocompleteRequest, useReportColumns } from 'hooks';
// Import Constants
import { DefaultColumnsReport } from '../constants';
import { l, ModalsClassNames, SegmentFilterApplicationColumnId } from 'constants/common';

const { deleteSubSegment, addNewSubSegment, changeSubSegmentName } = DynamicSegmentActions;
const { changeTabActiveIndex } = DynamicSegmentTabActiveIndexAction;
const TabsQuantity = 5;
const MaxLengthForImmediateDispatch = 14;

function FiltersContainer({
    handleChangeActiveList,
    readOnly,
    setReadOnly,
    resetActiveList,
    editingFilter,
    setEditingFilter,
    clientsCount,
    setClientsCount,
    columnsReport,
    pageType,
    setTabActiveId,
    tabActiveId,
    editModeCancelHandler,
}) {
    const { t } = useTranslation(['translations']);

    const { doPostRequest } = useRequest();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();
    const dispatch = useDispatch();
    const validator = useValidator();
    const intervalToken = useRef(null);

    const dynamicSegmentFiltersData = useSelector((state) => state.dynamicSegment);
    const queryConfigurations = useSelector((state) => state.queryConfigurations);
    const tabActiveIndex = useSelector((state) => state.dynamicSegmentTabActiveIndex.id);

    const [clientsData, setClientsData] = useState(null);
    const [usedInSegmentsTabsLabels, setUsedInSegmentsTabsLabels] = useState('');
    const [subSegmentNameValue, setSubSegmentNameValue] = useState('');
    const [countLoading, setCountLoading] = useState(false);
    const [isOpenedWarningModal, setIsOpenedWarningModal] = useState(false);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [isVisibleInfoMessage, setIsVisibleInfoMessage] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [_filteredColumns, isLoadingReportColumns] = useReportColumns();

    const { getSegmentCount, getDynamicSegmentPreview } = useRef({
        getSegmentCount: SegmentHttpService.getSegmentCount(),
        getDynamicSegmentPreview: SegmentHttpService.getDynamicSegmentPreview,
    }).current;

    useEffect(() => {
        !isEmpty(dynamicSegmentFiltersData) && setSubSegmentNameValue(dynamicSegmentFiltersData[tabActiveId]?.name);
    }, [tabActiveId, dynamicSegmentFiltersData]);

    useEffect(cleanUp, []);

    useEffect(() => {
        validator({ QueryConfigurations: dynamicSegmentFiltersData });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicSegmentFiltersData]);

    function cleanUp() {
        return () => {
            dispatch(changeTabActiveIndex({ id: '0' }));
            getSegmentCount.cancel('FilterContainer:getSegmentCount');
            cancelRequest('FilterContainer:getDynamicSegmentPreview');
        };
    }

    function closeModal() {
        cancelRequest('FilterContainer:getDynamicSegmentPreview');
        setIsVisibleInfoMessage(false);
        setIsVisibleModal(false);
        setClientsData(null);
    }

    const generateSubSegment = (name, index) => {
        const uuid = uuidv4();
        return {
            [uuid]: {
                id: uuid,
                name: name,
                seqId: index,
                filters: {},
            },
        };
    };

    const getSubSegmentName = () => {
        const names = values(dynamicSegmentFiltersData).map((segment) => segment.name);
        for (let i = 1; i < 5; i++) {
            if (!includes(names, `${t(l.SubSegment)} ${i}`)) {
                return `${t(l.SubSegment)} ${i}`;
            }
        }
    };

    const addSubSegmentClickHandler = () => {
        const highestIndex = Math.max(...values(dynamicSegmentFiltersData).map((el) => el.seqId), 0);
        const name = getSubSegmentName();
        dispatch(addNewSubSegment(generateSubSegment(name, highestIndex + 1)));
    };

    const tabChangeHandler = (seqId, key) => {
        editModeCancelHandler();
        dispatch(changeTabActiveIndex({ id: `${seqId}` }));
        setTabActiveId(key);
    };

    const deleteSubSegmentHandler = (id, seqId) => {
        const mainSegment = values(dynamicSegmentFiltersData).find((obj) => obj.isMain);
        dispatch(changeTabActiveIndex({ id: '0' }));
        setTabActiveId(mainSegment.id);
        const usedInSegmentsNames = [];
        const leftSegments = values(dynamicSegmentFiltersData).filter((el) => el.seqId < seqId);
        leftSegments.forEach((segment) => {
            values(segment.filters).forEach((filterGroup) => {
                filterGroup.forEach((filter) => {
                    if (filter.comparison.applicationColumnId === SegmentFilterApplicationColumnId) {
                        if (filter.Value.Data.Value.includes(id)) {
                            usedInSegmentsNames.push(segment.name);
                        }
                    }
                });
            });
        });
        isEmpty(usedInSegmentsNames) && dispatch(deleteSubSegment(id));
        setIsOpenedWarningModal(!isEmpty(usedInSegmentsNames));
        !isEmpty(usedInSegmentsNames) && setUsedInSegmentsTabsLabels(usedInSegmentsNames.join());
    };

    const dispatchNameInputChange = (value, id) => {
        dispatch(changeSubSegmentName({ value, id }));
    };

    const inputNameChangeHandler = (value, id) => {
        setSubSegmentNameValue(value);
        clearTimeout(intervalToken.current);
        if (trim(value) === '' || value.length < MaxLengthForImmediateDispatch) {
            dispatchNameInputChange(value, id);
        } else {
            intervalToken.current = setTimeout(() => {
                dispatchNameInputChange(value, id);
            }, 1000);
        }
    };

    const nameInputOnBlurHandler = (value, id) => {
        if (value.trim() === '') {
            const name = getSubSegmentName();
            dispatchNameInputChange(name, id);
        }
        values(dynamicSegmentFiltersData).forEach((segment) => {
            if (value === segment.name && id !== segment.id) {
                const newName = getSubSegmentName();
                dispatchNameInputChange(newName, id);
            }
        });
    };

    const isSubSegmentValid = (subSegment) => {
        return !isEmpty(subSegment.name) && !isEmpty(subSegment.filters);
    };

    function handleClickPreview() {
        const config = createQueryConfigurationsFromFilters(dynamicSegmentFiltersData, columnsReport);
        setIsVisibleModal(true);
        getSegmentReports(config);
    }

    function getSegmentTotalAndSelectedCount() {
        getSegmentSelectedCount(createQueryConfigurationsFromFilters(dynamicSegmentFiltersData));
    }

    function getSegmentSelectedCount(config) {
        setCountLoading(true);
        doPostRequest(getSegmentCount.request, {
            requestBody: config,
            successCallback: (Data) => {
                setClientsCount(Data);
            },
        }).then(() => {
            setCountLoading(false);
        });
    }

    function getSegmentReports(config) {
        const requestObj = getAutocompleteRequest(getDynamicSegmentPreview);

        doPostRequest(requestObj.request, {
            requestBody: config,
            successCallback: (Data) => {
                setClientsData(first(Data.Data));
            },
            errorCallback: () => {
                setIsVisibleInfoMessage(true);
            },
        });
    }

    const isValidDynamicSegmentFiltersData = () => {
        let isValid = true;
        values(dynamicSegmentFiltersData).forEach((segment) => {
            if (isEmpty(segment.filters) || isEmpty(segment.name)) {
                isValid = false;
            }
        });
        return isValid;
    };

    return (
        <>
            <div className="create-dynamic-segment-p-w-c-i-column-head">
                <ModuleTitle
                    title={t(l.FiltersContainer)}
                    titleIcon="bc-icon-info"
                    titleIconTooltip={t(l.DynamicSegmentFiltersContainerTooltipText)}
                />
            </div>
            {isEmpty(dynamicSegmentFiltersData) ? (
                <BusyLoader
                    isBusy
                    type="spinner"
                    spinnerSize="big"
                    className="crm-full-width crm-justify-content-center"
                />
            ) : (
                <div className="dynamic-segment-tabs-container">
                    <Tabs
                        type="basic"
                        activeKey={tabActiveIndex}
                        actions={
                            <div className="substep-tabs-actions-wrapper">
                                <Icon
                                    type="bc-icon-add"
                                    onClick={addSubSegmentClickHandler}
                                    disabled={values(dynamicSegmentFiltersData).length >= TabsQuantity}
                                />
                            </div>
                        }
                    >
                        {values(dynamicSegmentFiltersData).map((subSegment) => {
                            return (
                                <Tab
                                    key={subSegment.seqId}
                                    className={`${+tabActiveIndex === subSegment.seqId ? 'active' : ''} ${
                                        isSubSegmentValid(subSegment) ? '' : 'invalid-tab'
                                    }`}
                                    title={
                                        <Tooltip text={subSegment.name}>
                                            <div className="ellipsis-text">
                                                <button
                                                    onClick={() => tabChangeHandler(subSegment.seqId, subSegment.id)}
                                                >
                                                    <p>
                                                        {+tabActiveIndex === subSegment.seqId
                                                            ? subSegment.name
                                                            : subSegment.name.slice(0, 4)}
                                                    </p>
                                                </button>
                                                {!subSegment?.isMain && (
                                                    <Icon
                                                        type="bc-icon-clear-small"
                                                        onClick={() =>
                                                            deleteSubSegmentHandler(subSegment.id, subSegment.seqId)
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    {subSegment?.isMain && (
                                        <div className="crm-dynamic-segment-header crm-justify-content-between crm-full-width p-20">
                                            <div className="crm-segment-filters-header-blocks crm-segment-filters-header-left-side">
                                                <p>{`${t(l.Total)} ${clientsCount.Total} / ${clientsCount.Count}`}</p>
                                                <Icon
                                                    disabled={!isValidDynamicSegmentFiltersData()}
                                                    className={`crm-blue${countLoading ? ' rotate' : ''}`}
                                                    type={'bc-icon-refresh'}
                                                    onClick={getSegmentTotalAndSelectedCount}
                                                />
                                            </div>
                                            <div className="crm-segment-filters-header-blocks crm-segment-filters-header-right-side">
                                                {/* // TODO after backend correction */}
                                                {/* <Dropdown
                                                className="crm-blue pr-10"
                                                placeholder={t(l.Export)}
                                                hasSearch={false}
                                                flexibility="content-size"
                                                appearance="light"
                                                disabled={isEmpty(dynamicSegmentFiltersData)}
                                                data={exportList}
                                                onChange={downloadDropdownChangeHandler}
                                            /> */}
                                                <Divider type="vertical" />
                                                <Button
                                                    color="primary"
                                                    appearance="minimal"
                                                    icon="bc-icon-view-doc"
                                                    className="ml-10"
                                                    disabled={
                                                        !isValidDynamicSegmentFiltersData() || isLoadingReportColumns
                                                    }
                                                    onClick={handleClickPreview}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!subSegment?.isMain && (
                                        <div className="dynamic-segment-name-input-container">
                                            <ExtendedInput
                                                type="text"
                                                placeholder={l.Name}
                                                value={subSegmentNameValue}
                                                onChange={(e) => inputNameChangeHandler(e.target.value, subSegment.id)}
                                                appearance="outline"
                                                label={l.Name}
                                                labelAppearance="swap"
                                                onBlur={(e) => nameInputOnBlurHandler(e.target.value, subSegment.id)}
                                            />
                                        </div>
                                    )}
                                    {isEmpty(subSegment.filters) ? (
                                        <div className="create-dynamic-segment-p-w-c-i-c-content-column">
                                            <div className="create-dynamic-segment-p-w-c-i-c-content-c-inner">
                                                <div className="crm-scroll-holder">
                                                    <div className="crm-scrollable-container">
                                                        <div className="empty-container">
                                                            <Empty
                                                                appearance="greyscale"
                                                                withImage={true}
                                                                title={t(l.NoSegmentToDisplay)}
                                                                subTitle={t(
                                                                    l.YourSegmentsWillAppearInThisContainerAsSoonAsYouSelectFilters,
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="create-dynamic-segment-p-w-c-i-c-content-column">
                                            <div className="create-dynamic-segment-p-w-c-i-c-content-c-inner">
                                                <div className="crm-scroll-holder">
                                                    <div
                                                        className={classNames('crm-scrollable-container', {
                                                            'crm-editable-state': readOnly,
                                                        })}
                                                    >
                                                        <FilterSchemaList
                                                            filtersData={subSegment}
                                                            queryConfigurations={queryConfigurations}
                                                            handleChangeActiveList={handleChangeActiveList}
                                                            readOnly={readOnly}
                                                            setReadOnly={setReadOnly}
                                                            resetActiveList={resetActiveList}
                                                            editingFilter={editingFilter}
                                                            setEditingFilter={setEditingFilter}
                                                            setClientsCount={setClientsCount}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Tab>
                            );
                        })}
                    </Tabs>
                </div>
            )}

            {isVisibleModal && (
                <SegmentReportPreviewModal
                    isVisible={isVisibleModal}
                    closeHandler={closeModal}
                    clientsData={clientsData}
                    isVisibleInfoMessage={isVisibleInfoMessage}
                    pageType={pageType}
                />
            )}
            {isOpenedWarningModal && (
                <Modal
                    title={l.DeleteSubSegmentWarningMessage}
                    position="top"
                    className={ModalsClassNames.Warning}
                    appearance="compact"
                    visible={isOpenedWarningModal}
                    size="content-size"
                    closable={false}
                    closeOnClickOutside={true}
                    onCancel={() => setIsOpenedWarningModal(false)}
                    footer={
                        <Button color="primary" appearance="minimal" onClick={() => setIsOpenedWarningModal(false)}>
                            {l.Close}
                        </Button>
                    }
                >
                    <div className="crm-circle-icon-cnt">
                        <Icon type={'bc-icon-info-48'} />
                    </div>
                    <span>{t(l.SubSegmentUsedInMessage, { usedInSegments: usedInSegmentsTabsLabels })}</span>
                </Modal>
            )}
        </>
    );
}

FiltersContainer.propTypes = {
    queryConfigurations: PropTypes.object,
    handleChangeActiveList: PropTypes.func,
    readOnly: PropTypes.bool,
    setReadOnly: PropTypes.func,
    resetActiveList: PropTypes.func,
    editingFilter: PropTypes.string,
    setEditingFilter: PropTypes.func,
    clientsCount: PropTypes.object.isRequired,
    setClientsCount: PropTypes.func.isRequired,
    columnsReport: PropTypes.array,
    pageType: PropTypes.string,
    tabActiveId: PropTypes.string.isRequired,
    setTabActiveId: PropTypes.func.isRequired,
    editModeCancelHandler: PropTypes.func,
};

FiltersContainer.defaultProps = {
    filtersList: [],
    readOnly: false,
    columnsReport: DefaultColumnsReport,
};

export default FiltersContainer;
