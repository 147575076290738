import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
//Import Components
import { AdminProviderCECModal } from 'components';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { AdministrationHttpService } from 'services/http';
// Import Constants
import { l, AlertTypes, PageTypes } from 'constants/common';

const { success } = AlertTypes;

const AdminProviderActionsModals = ({
    isCreateModalOpenedState,
    isEditModalOpenedState,
    isCloneModalOpenedState,
    modalsStateSetDict,
    openedModalData,
    onCloseModalHandler,
}) => {
    const { doPostRequest } = useRequest();
    const { showToaster } = useToaster();

    const [isLoading, setIsLoading] = useState(false);

    const { setEmailProviderRequest, updateEmailProviderRequest } = useMemo(
        () => ({
            setEmailProviderRequest: AdministrationHttpService.setEmailProvider(),
            updateEmailProviderRequest: AdministrationHttpService.updateEmailProvider(),
        }),
        [],
    );

    const createOrCloneProvider = (provider, modalKey) => {
        setIsLoading(true);
        doPostRequest(setEmailProviderRequest.request, {
            requestBody: provider,
            successCallback: ({ AlertType, AlertMessage }) => {
                showToaster(success, l.ProviderCreated);
                onCloseModalHandler({
                    modalStateKey: modalKey,
                    alertType: AlertType,
                    alertMessage: AlertMessage,
                    isDoRefresh: true,
                });
            },
        }).then(() => setIsLoading(false));
    };

    const editProvider = (provider) => {
        setIsLoading(true);
        doPostRequest(updateEmailProviderRequest.request, {
            requestBody: provider,
            successCallback: ({ AlertType, AlertMessage }) => {
                showToaster(success, l.ProviderUpdated);
                onCloseModalHandler({
                    modalStateKey: modalsStateSetDict.editProvider.key,
                    alertType: AlertType,
                    alertMessage: AlertMessage,
                    isDoRefresh: true,
                });
            },
        }).then(() => setIsLoading(false));
    };

    return (
        !isEmpty(openedModalData) && (
            <>
                {isCreateModalOpenedState && (
                    <AdminProviderCECModal
                        onOk={(provider) => createOrCloneProvider(provider, modalsStateSetDict.createProvider.key)}
                        isVisibleModal={isCreateModalOpenedState}
                        onCancel={() =>
                            onCloseModalHandler({
                                modalStateKey: modalsStateSetDict.createProvider.key,
                            })
                        }
                        isLoading={isLoading}
                        type={PageTypes.create}
                    />
                )}
                {isEditModalOpenedState && (
                    <AdminProviderCECModal
                        onOk={editProvider}
                        openedModalData={openedModalData}
                        isVisibleModal={isEditModalOpenedState}
                        onCancel={() =>
                            onCloseModalHandler({
                                modalStateKey: modalsStateSetDict.editProvider.key,
                            })
                        }
                        isLoading={isLoading}
                        type={PageTypes.edit}
                    />
                )}
                {isCloneModalOpenedState && (
                    <AdminProviderCECModal
                        onOk={(provider) => createOrCloneProvider(provider, modalsStateSetDict.cloneProvider.key)}
                        openedModalData={openedModalData}
                        isVisibleModal={isCloneModalOpenedState}
                        onCancel={() =>
                            onCloseModalHandler({
                                modalStateKey: modalsStateSetDict.cloneProvider.key,
                            })
                        }
                        isLoading={isLoading}
                        type={PageTypes.clone}
                    />
                )}
            </>
        )
    );
};

AdminProviderActionsModals.propTypes = {
    isCreateModalOpenedState: PropTypes.bool.isRequired,
    isEditModalOpenedState: PropTypes.bool.isRequired,
    isCloneModalOpenedState: PropTypes.bool.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.any.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
};

export default memo(AdminProviderActionsModals);
