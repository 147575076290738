import React from 'react';
// Import UI Components
import { LabelTag } from 'components';
// Import Constants
import { l } from 'constants/common';

const getManageTagDataTableColumnsConfig = (t) => [
    {
        text: t(l.Title),
        dataKey: 'Name',
        sortable: false,
        colRenderer: (name) => {
            return <LabelTag color="#1473e6" labelName={name} />;
        },
    },
    {
        text: t(l.SelectedClientsCount),
        dataKey: 'ClientsCount',
        sortable: false,
    },
    {
        text: t(l.TotalClientsCount),
        dataKey: 'ClientsCountAll',
        sortable: false,
    },
];

export { getManageTagDataTableColumnsConfig };
