import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import UI components
import { Checkbox, Icon, Tooltip } from '@geneui/components';
// Import components
import { UploadFile, ButtonWithLoader } from 'components';
import GeneralDetailsColumns from './GeneralDetailsColumns';
// Import constants
import { l } from 'constants/common';
//Import scss
import 'assets/scss/nonCustomerSegment.scss';

const NonCustomerSegmentGeneralDetails = ({
    fileName,
    handleFileUpload,
    isFileApplying,
    isFileUploading,
    handleIsIgnoreInvalidsChange,
    handleIsUpdateChange,
    handleIsHeaderIsTheFierstRowChange,
    handleFileInputChange,
    sourceColumns,
    destinationColumns,
    isDisableUploadBtn,
    isDisableCheckBoxes,
    isDisableFormFields,
    columnChangehandler,
    handleGeneralDetailsApply,
    isHeaderIsTheFirstRow,
    fileRef,
}) => {
    const { t } = useTranslation();
    const { fileName: fileNameValidation } = useSelector((state) => state.pageValidation);

    const formSubmitHandler = (e) => {
        e.preventDefault();
        handleFileUpload(e);
    };

    const fileInputChangeHandler = (e) => {
        handleFileInputChange(e);
    };

    const isIgnoreInvalidsChangeHandler = (e) => {
        handleIsIgnoreInvalidsChange(e);
    };

    const isUpdateChangeHandler = (e) => {
        handleIsUpdateChange(e);
    };

    return (
        <div className="form-row-wrap">
            <div className="form-row">
                <div className="module-header cr-position-radius p-top s-big">
                    <div className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text">
                                <div className="crm-statics-segment-data-table-name">
                                    <h3>{t(l.GeneralDetails)}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row-inner">
                    <div className="crm-gen-details-uploader">
                        <UploadFile
                            onChange={fileInputChangeHandler}
                            value={fileName}
                            disabled={isDisableFormFields}
                            errorText={fileNameValidation?.errorText}
                            inputRef={fileRef}
                        />
                    </div>

                    <Tooltip text={t(l.TooltipStaticSegmentUploadFile)} position="bottom">
                        <Icon type="bc-icon-info" />
                    </Tooltip>

                    <Checkbox
                        disabled={isDisableFormFields}
                        label={t(l.HeaderIsTheFirstRow)}
                        onChange={handleIsHeaderIsTheFierstRowChange}
                        checked={isHeaderIsTheFirstRow}
                    />

                    <Tooltip text={t(l.HeaderIsTheFirstRowTooltip)} position="bottom">
                        <Icon type="bc-icon-info" />
                    </Tooltip>

                    <ButtonWithLoader
                        className="crm-gen-details-submit"
                        flexibility="content-size"
                        type="submit"
                        disabled={isDisableUploadBtn}
                        onClick={formSubmitHandler}
                        isLoading={isFileUploading}
                    >
                        {t(l.UploadFile)}
                    </ButtonWithLoader>

                    {!isDisableCheckBoxes && (
                        <div className="crm-gen-details-check-group">
                            <Checkbox label={t(l.IgnoreInvalids)} onChange={isIgnoreInvalidsChangeHandler} />
                            <Tooltip text={t(l.TooltipIgnoreInvalids)} position="bottom">
                                <Icon type="bc-icon-info" />
                            </Tooltip>
                            <Checkbox label={t(l.Update)} onChange={isUpdateChangeHandler} />
                            <Tooltip text={t(l.TooltipCheckboxes)} position="bottom">
                                <Icon type="bc-icon-info" />
                            </Tooltip>
                        </div>
                    )}
                </div>

                <>
                    {destinationColumns && sourceColumns && (
                        <>
                            <div className="title-wrapper">
                                <p className="title-wrapper-text">Mapping Details</p>
                                <span className="title-wrapper-line" />
                            </div>

                            <GeneralDetailsColumns
                                destinationColumns={destinationColumns}
                                sourceColumns={sourceColumns}
                                columnChangehandler={columnChangehandler}
                                handleGeneralDetailsApply={handleGeneralDetailsApply}
                                isFileApplying={isFileApplying}
                            />
                        </>
                    )}
                </>
            </div>
        </div>
    );
};

NonCustomerSegmentGeneralDetails.propTypes = {
    fileName: PropTypes.string,
    isDisableUploadBtn: PropTypes.bool,
    isDisableCheckBoxes: PropTypes.bool,
    isDisableFormFields: PropTypes.bool,
    isHeaderIsTheFirstRow: PropTypes.bool,
    handleFileUpload: PropTypes.func,
    handleIsIgnoreInvalidsChange: PropTypes.func,
    handleIsHeaderIsTheFierstRowChange: PropTypes.func,
    handleIsUpdateChange: PropTypes.func,
    handleFileInputChange: PropTypes.func,
    fileRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    sourceColumns: PropTypes.array,
    isFileApplying: PropTypes.bool,
    isFileUploading: PropTypes.bool,
    destinationColumns: PropTypes.array,
    columnChangehandler: PropTypes.func,
    handleGeneralDetailsApply: PropTypes.func,
};

NonCustomerSegmentGeneralDetails.defaultProps = {
    isDisableUploadBtn: false,
    isDisableCheckBoxes: false,
    isFileUploading: false,
    isDisableFormFields: false,
    isFileApplying: false,
    isHeaderIsTheFirstRow: false,
    handleFileUpload: noop,
    handleIsIgnoreInvalidsChange: noop,
    handleIsHeaderIsTheFierstRowChange: noop,
    handleIsUpdateChange: noop,
    handleFileInputChange: noop,
    fileRef: noop,
    columnChangehandler: noop,
    handleGeneralDetailsApply: noop,
    sourceColumns: null,
    destinationColumns: null,
};
export default memo(NonCustomerSegmentGeneralDetails);
