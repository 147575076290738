import { CRMRequestService, postCancelableRequest, getCancelableRequest } from './http.config';

const BonusHttpService = {
    getBonusList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ isIncludeExpired = false, isIncludeDeleted = false }) =>
                `/Bonus/List?isIncludeExpired=${isIncludeExpired}&isIncludeDeleted=${isIncludeDeleted}`,
        });
    },
    getBonusById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ bonusId }) => `/Bonus/Get?id=${bonusId}`,
        });
    },
    getBonusByIds: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ isIncludeExpired = false, isIncludeDeleted = false }) =>
                `/Bonus/GetByIds?isIncludeExpired=${isIncludeExpired}&isIncludeDeleted=${isIncludeDeleted}`,
        });
    },
    getBonusInfoByCampaignId: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `/Bonus/GetBonusInfo?id=${campaignId}`,
        });
    },
};

export default BonusHttpService;
