import React, { useMemo, memo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import UI Components
import { AdvancedSearch } from '@geneui/components';
import GlobalSearchActionsModals from './GlobalSearchActionsModals';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import Hooks
import {
    useAutocompleteRequest,
    useRequest,
    useHasPermission,
    useGACustomEvent,
    useSearchRedirectionAndModal,
} from 'hooks';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';
import { getObjectTypes } from './config';

const { GASearchCategory } = GACategoryEvents;

const GlobalSearch = () => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const { doGetRequest, doPostRequest } = useRequest();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const [isOpen, setIsOpen] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedObjectTypes, setSelectedObjectTypes] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearchLoading, setIsSearchLoading] = useState(true);
    const { logCustomEvent } = useGACustomEvent();

    const {
        modalsStateSetDict,
        openedModalData,
        isSegmentInfoModalOpen,
        isTemplateInfoModalOpen,
        isCampaignInfoModalOpen,
        isRealtimePromotionInfoModalOpen,
        isReportInfoModalOpen,
        addActionsToData,
    } = useSearchRedirectionAndModal();

    const { getUsersRequest } = useMemo(
        () => ({
            getUsersRequest: UtilsHttpService.getGlobalSearchUsers(),
        }),
        [],
    );

    const onSearch = useCallback(
        (keyword) => {
            setIsOpen(true);
            setSearchKeyword(keyword);
            logCustomEvent(GASearchCategory.name, GASearchCategory.events.globalSearch, 'text input', keyword);
        },
        [logCustomEvent],
    );

    const onObjectTypeSelect = useCallback((objectTypes) => {
        setSelectedObjectTypes(objectTypes.filter((o) => o.checked));
    }, []);

    const onUserSelect = useCallback((users) => {
        setSelectedUsers(users.filter((u) => u.checked));
    }, []);

    const [usersFilterSettings, setUsersFilterSettings] = useState({
        sectionNameText: t(l.FilterByUser),
        onChange: onUserSelect,
        isLoading: true,
        data: [],
    });

    const objectTypeFilterSettings = useMemo(() => {
        const allObjectTypes = getObjectTypes(t);
        const allowedObjectTypes = allObjectTypes.filter(
            ({ permissions }) => isEmpty(permissions) || hasPermission(permissions),
        );
        return { sectionNameText: t(l.FilterByObjectType), onChange: onObjectTypeSelect, data: allowedObjectTypes };
    }, [hasPermission, onObjectTypeSelect, t]);

    const openPopover = useCallback(() => {
        setIsOpen(true);
        if (!isOpen) {
            setIsSearchLoading(true);
        }
    }, [isOpen]);

    const closePopover = useCallback(() => {
        setIsOpen(false);
    }, []);

    const fetchSearchResult = useCallback(() => {
        setIsSearchLoading(true);
        const requestObj = getAutocompleteRequest(UtilsHttpService.getGlobalSearchResult);

        doPostRequest(requestObj.request, {
            requestBody: {
                Name: searchKeyword,
                ObjectTypes: selectedObjectTypes.map((objectType) => objectType.value),
                UserIds: selectedUsers.map((user) => user.value),
            },
            successCallback: (data) => {
                const parsedData = addActionsToData({ data, closePopover });

                setSearchResult(parsedData);
                setIsSearchLoading(false);
            },
            errorCallback: () => {
                setSearchResult([]);
            },
        });
    }, [
        addActionsToData,
        closePopover,
        doPostRequest,
        getAutocompleteRequest,
        searchKeyword,
        selectedObjectTypes,
        selectedUsers,
    ]);

    const onCloseModalHandler = (modalStateKey) => {
        modalsStateSetDict[modalStateKey].fn(false);
    };

    const fetchUsersList = useCallback(() => {
        doGetRequest(getUsersRequest.request, {
            successCallback: (data) => {
                const parsedUsers = data.map((user) => ({
                    id: user.UserId,
                    name: user.Name,
                    value: String(user.UserId),
                    checked: false,
                }));

                setUsersFilterSettings((current) => ({
                    ...current,
                    isLoading: false,
                    data: parsedUsers,
                }));
            },
            errorCallback: () => {
                setUsersFilterSettings((current) => ({
                    ...current,
                    isLoading: false,
                }));
            },
        });
    }, [doGetRequest, getUsersRequest]);

    const extendedInputConfigs = useMemo(
        () => ({
            onFocus: openPopover,
            onIconClick: openPopover,
            type: 'text',
            maxLength: 100,
            placeholder: t(l.Search),
        }),
        [openPopover, t],
    );

    useEffect(() => {
        if (isOpen && isEmpty(usersFilterSettings.data)) {
            fetchUsersList();
        }
    }, [isOpen, fetchUsersList, usersFilterSettings.data]);

    useEffect(() => {
        if (isOpen) {
            fetchSearchResult();
        } else {
            cancelRequest();
        }
    }, [searchKeyword, selectedObjectTypes, selectedUsers, isOpen, fetchSearchResult, cancelRequest]);

    const cleanUp = () => () => {
        cancelRequest();
    };

    useEffect(cleanUp, []);

    return (
        <div className="global-search-container">
            <AdvancedSearch
                isOpen={isOpen}
                onOutsideClick={closePopover}
                data={searchResult}
                position="left"
                noDataText={t(l.NoDataToDisplay)}
                isSearchLoading={isSearchLoading}
                initialData={[]}
                primaryFilterData={objectTypeFilterSettings}
                secondaryFilterData={usersFilterSettings}
                initialDataDescription={t(l.Result)}
                onSearch={onSearch}
                totalCount={searchResult.length}
                totalCountMax={19}
                totalCountText={t(l.Result)}
                extendedInputConfigs={extendedInputConfigs}
            />
            <GlobalSearchActionsModals
                isSegmentInfoModalOpen={isSegmentInfoModalOpen}
                isTemplateInfoModalOpen={isTemplateInfoModalOpen}
                isCampaignInfoModalOpen={isCampaignInfoModalOpen}
                isRealtimePromotionInfoModalOpen={isRealtimePromotionInfoModalOpen}
                isReportInfoModalOpen={isReportInfoModalOpen}
                openedModalData={openedModalData}
                modalsStateSetDict={modalsStateSetDict}
                onCloseModalHandler={onCloseModalHandler}
            />
        </div>
    );
};

export default memo(GlobalSearch);
