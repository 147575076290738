import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Icon } from '@geneui/components';
// Import constants
import { BonusTypesLabels, BonusTypesIcons, l } from 'constants/common';
import { dateTimeFormat } from './config';

const BonusWidget = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="campaign-tabs-inner">
            <div>
                <div className="campaign-tab-icon bonus-icon">
                    <Icon type="bc-icon-promotional-bonus-48" />
                </div>
                <div className="campaign-tab-content-wrapper">
                    {isNil(data) ? (
                        <div>
                            <p className="campaign-tab-heading">{t(l.Bonus)}</p>
                            <p className="campaign-tab-desc">{t(l.BonusDescription)}</p>
                        </div>
                    ) : (
                        <div>
                            <div className="campaign-tab-heading-group">
                                <p className="campaign-tab-title">{t(l.Bonus)}</p>
                                <p className="campaign-tab-subtitle">{data.title}</p>
                            </div>
                            <div className="campaign-tab-content">
                                <div className="campaign-tab-content-part">
                                    <div className="campaign-tab-content-type">
                                        <span className="campaign-tab-title">{t(l.ID)}</span>
                                        <p className="campaign-tab-subtitle">{data.id}</p>
                                    </div>
                                    <div className="campaign-tab-content-type">
                                        <span>{t(l.Type)}:</span>
                                        {!isNil(BonusTypesIcons[data.type]) && (
                                            <Icon type={BonusTypesIcons[data.type]} />
                                        )}
                                        <p>{BonusTypesLabels[data.type]}</p>
                                    </div>
                                </div>
                                <div className="campaign-tab-content-part">
                                    {data.startDate && (
                                        <div className="campaign-tab-date ">
                                            <span>{t(l.StartDate)}:</span>
                                            <p>{dateTimeFormat(data.startDate)}</p>
                                        </div>
                                    )}
                                    {data.endDate && (
                                        <div className="campaign-tab-date">
                                            <span>{t(l.EndDate)}:</span>
                                            <p>{dateTimeFormat(data.endDate)}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

BonusWidget.propTypes = {
    data: PropTypes.object,
};

BonusWidget.defaultProps = {
    data: null,
};

export default memo(BonusWidget);
