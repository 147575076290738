export default class UIHashButtonItem {
    constructor(
        hashKey = null,
        hashType = null,
        displayName = null,
        description = null,
        colorUI = null,
        comparison = null,
        placeholder = null,
    ) {
        this.hashKey = hashKey;
        this.hashType = hashType;
        this.displayName = displayName;
        this.description = description;
        this.colorUI = colorUI;
        this.comparison = comparison;
        this.placeholder = placeholder;
    }
}
