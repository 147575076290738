/* eslint-disable react/jsx-no-bind */
import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isNil } from 'lodash';
// Import Hooks
import { useToaster } from 'hooks';
// Import UI Components
import { BusyLoader } from '@geneui/components';
// Import Containers
import { UIHashComparisonContainer } from './UIHashContainers';
// Import Components
import DynamicSegmentForms from './DynamicSegmentForms';
// Import Reducers
import { DynamicSegmentActions } from 'actions';
// Import Constants
import { AlertTypes, l } from 'constants/common';
// Import SCSS
import 'assets/scss/dynamicSegmentForms.scss';

const { error } = AlertTypes;
const { addNewFilter, editFilter } = DynamicSegmentActions;

function DynamicSegmentFilterFormWrapper({ data, setReadOnlyFilterContainer, editModeCancelHandler, tabActiveId }) {
    const dynamicSegmentFiltersData = useSelector((state) => state.dynamicSegment);
    const { t } = useTranslation();
    const { showToaster } = useToaster();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(null);
    const [errorData, setErrorData] = useState('');
    const [errorValidationScenario, setErrorValidationScenario] = useState(null);
    const { elementType, parentDisplayName } = data;

    useEffect(() => {
        return () => setReadOnlyFilterContainer(false);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    function handleApply() {
        data.setValue(formData);
        const filtersGroup = dynamicSegmentFiltersData[tabActiveId].filters[data.parentHashKey];
        if (filtersGroup) {
            const filteredFiltersGroup = filtersGroup.find((elem) => {
                const compData = { ...data };
                delete compData.id;
                return JSON.stringify(elem) === JSON.stringify(compData);
            });
            if (!filteredFiltersGroup) {
                checkIdExist();
            } else {
                showToaster(error, t(l.FilterListContainsTheSameFilter));
            }
        } else {
            checkIdExist();
        }
    }

    function checkIdExist() {
        isNil(data.id)
            ? dispatch(addNewFilter(cloneDeep({ data, tab: dynamicSegmentFiltersData[tabActiveId] })))
            : dispatch(editFilter(cloneDeep({ data, tab: dynamicSegmentFiltersData[tabActiveId] })));
        editModeCancelHandler();
        setReadOnlyFilterContainer(false);
        setErrorData('');
    }

    const { Component, config } = DynamicSegmentForms[elementType];
    return (
        <Suspense fallback={<BusyLoader isBusy type="spinner" spinnerSize="medium" />}>
            <div className="crm-dynamic-segment-form">
                <div className="crm-dynamic-segment-form-head">{parentDisplayName}</div>
                <Component
                    data={data}
                    config={config}
                    setValidation={setErrorValidationScenario}
                    setError={setErrorData}
                    filterList={dynamicSegmentFiltersData[tabActiveId].filters}
                    validation={errorValidationScenario}
                    error={errorData}
                    setFormData={setFormData}
                    handleApply={handleApply}
                    editModeCancelHandler={editModeCancelHandler}
                />
            </div>
        </Suspense>
    );
}

DynamicSegmentFilterFormWrapper.propTypes = {
    data: PropTypes.instanceOf(UIHashComparisonContainer),
    listLength: PropTypes.number,
    editModeCancelHandler: PropTypes.func,
    setReadOnlyFilterContainer: PropTypes.func,
    tabActiveId: PropTypes.string.isRequired,
};

export default DynamicSegmentFilterFormWrapper;
