import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDragLayer } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';
// Import components
import ReportItemView from './ReportItemView';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const ReportItemPreview = ({ getRowByIndex }) => {
    const previewCollect = usePreview();
    const collect = useDragLayer((monitor) => {
        return {
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getClientOffset(),
            isDragging: monitor.isDragging(),
        };
    });

    if (!previewCollect.display) {
        return null;
    }
    if (!collect.isDragging) {
        return null;
    }

    function getItemStyles(initialOffset, currentOffset, defaultTranslateX = 50, defaultTranslateY = 40) {
        if (!initialOffset || !currentOffset) {
            return {
                display: 'none',
            };
        }
        let { x, y } = currentOffset;
        const transform = `translate(${x - defaultTranslateX}px, ${y - defaultTranslateY}px)`;

        return {
            transform,
            WebkitTransform: transform,
            maxWidth: '498px',
        };
    }
    const { data } = getRowByIndex(previewCollect.item.index);

    const { ReportType, Name } = data;

    return (
        <div style={layerStyles}>
            <div
                className="droping-container-info-inner"
                style={getItemStyles(collect.initialOffset, collect.currentOffset)}
            >
                <ReportItemView type={ReportType} title={Name?.Name} preview />
            </div>
        </div>
    );
};
ReportItemPreview.propTypes = {
    getRowByIndex: PropTypes.func.isRequired,
};
export default memo(ReportItemPreview);
