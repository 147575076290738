import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Components
import { DateFormat, l } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMoment } = Helpers;

const CJDTDateInfoBlock = ({ data }) => {
    const { t } = useTranslation();
    const { dates, months, years } = data?.value?.params;
    const date = customMoment().set('year', years).set('month', months).set('date', dates);

    return <CJInfoBlockLabelValueWrapper label={t(l.Date)} value={date.format(DateFormat)} />;
};

CJDTDateInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJDTDateInfoBlock);
