/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { cloneDeep, noop } from 'lodash';
// Import UI Components
import { Button } from '@geneui/components';
// Import Components
import { FilterFields } from 'components';
// Import Services
import {
    FilterHolderComponentsTypes,
    FilterExistingValueChecker,
    FiltersFieldsMap,
} from 'components/common/FilterFields/service';
// Import Hooks
import { useFilterFieldsState } from 'hooks';
// Import Constants
import { l } from 'constants/common';

const DataTableHeaderFilter = ({ defaultInputsData, onApply, onReset }) => {
    const { t } = useTranslation();

    const {
        filters,
        changeFilters,
        applyFilters: applyChangedFilters,
        resetFilters,
        isResetDisabled,
        isApplyDisabled,
    } = useFilterFieldsState(defaultInputsData);

    const init = () => {
        applyFilters(filters, false);
    };

    useEffect(init, []);

    function applyFilters(data = filters, withOnApply) {
        const resultData = FiltersFieldsMap(data);
        withOnApply && onApply(resultData);
    }

    function handleReset() {
        resetFilters();
        onReset();
    }

    function handleChange(e, changeInputValue, customChange, index, filterInputData) {
        const resultData = changeInputValue(e, customChange, cloneDeep(filters), index, filterInputData);
        changeFilters(resultData);
    }

    function handleApply() {
        if (FilterExistingValueChecker(filters)) {
            applyChangedFilters();
            applyFilters(filters, true);
        }
    }

    return (
        <>
            <FilterFields filterInputsData={filters} handleChange={handleChange} />
            <Button appearance="minimal" onClick={handleReset} disabled={isResetDisabled}>
                {t(l.Reset)}
            </Button>
            <Button color="confirm" onClick={handleApply} disabled={isApplyDisabled}>
                {t(l.Apply)}
            </Button>
        </>
    );
};

const isRequiredDataField = ({ type, data }) => {
    return (
        (type === FilterHolderComponentsTypes.select.keyName && data) ||
        type !== FilterHolderComponentsTypes.select.keyName
    );
};

DataTableHeaderFilter.propTypes = {
    defaultInputsData: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            valueFieldKey: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            value: PropTypes.any,
            data: isRequiredDataField,
        }),
    ).isRequired,
    onApply: PropTypes.func,
    onReset: PropTypes.func,
};

DataTableHeaderFilter.defaultProps = {
    onApply: noop,
    onReset: noop,
};

export default DataTableHeaderFilter;
