import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const BMENewsModal = ({ isReady }) => {
    const { UserId } = useSelector((state) => state.userData);
    const [isInitiated, setIsInitiated] = useState(false);

    useEffect(() => {
        if (isReady) {
            setIsInitiated(true);
        }
    }, [isInitiated, isReady]);

    const scriptOnLoad = (userId) => {
        if (window.NewsModalBC && !process.env.REACT_APP_IGNORE_NEWS_MODAL) {
            window.NewsModalBC.init({
                env: process.env.REACT_APP_BME_NEWS,
                token: process.env.REACT_APP_BME_NEWS_TOKEN,
                identifier: `${userId}`,
                mainColor: '#2BC784',
            });
        }
    };

    return (
        <>
            {isInitiated && (
                <Helmet>
                    <script
                        async
                        src={process.env.REACT_APP_BME_NEWS_URL}
                        onLoad={`(${scriptOnLoad.toString()})(${UserId})`}
                    ></script>
                </Helmet>
            )}
        </>
    );
};

BMENewsModal.propTypes = {
    isReady: PropTypes.bool.isRequired,
};

export default memo(BMENewsModal);
