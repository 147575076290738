import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import UI components
import { Button } from '@geneui/components';
// Import constants
import { l } from 'constants/common';
import { noop } from 'lodash';

const ModalFooter = ({ isDisableSave, isSaveVisible, closeHandler, saveHandler }) => {
    const { t } = useTranslation();

    return (
        <>
            <Button appearance="minimal" onClick={closeHandler}>
                {isSaveVisible ? t(l.Cancel) : t(l.Close)}
            </Button>
            {isSaveVisible && (
                <Button disabled={isDisableSave} onClick={saveHandler}>
                    {t(l.Save)}
                </Button>
            )}
        </>
    );
};

ModalFooter.propTypes = {
    isSaveVisible: PropTypes.bool,
    isDisableSave: PropTypes.bool,
    closeHandler: PropTypes.func.isRequired,
    saveHandler: PropTypes.func,
};

ModalFooter.defaultProps = {
    isSaveVisible: true,
    isDisableSave: true,
    saveHandler: noop,
};

export default memo(ModalFooter);
