import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop, isEmpty } from 'lodash';
import { ReactFlowProvider, isNode } from 'react-flow-renderer';
// Import hooks
import { useGoToRoute, useRequest } from 'hooks';
//Import UI components
import { Modal, Button, BusyLoader } from '@geneui/components';
//Import constants
import { ModalsActions, l, RealtimePromotionTypes } from 'constants/common';
import { RoutesList } from 'routes';
//Import Components
import { FlowEditor, CustomerJourneyGeneralDetails } from 'components';
//Import Services
import { CustomerJourneyHttpService } from 'services/http';
import { groupBlocksBuilder, mapConfigToFlowEditorModel } from 'services/customerJourney';

const { REALTIME_JOURNEY_CLONE } = RoutesList;

const CustomerJourneyPredefinedInfoModal = ({ workflowId, handleClose, isVisible }) => {
    const { t } = useTranslation();
    const { goToRoute } = useGoToRoute();
    const { doGetRequest } = useRequest();
    const [info, setInfo] = useState({ title: null, description: null, createdDate: null });

    const [initialData, setInitialData] = useState([]);
    const [groupBlocks, setGroupBlocks] = useState([]);
    const [initialElements, setInitialElements] = useState();
    const [isGeneralDetailsLoading, setIsGeneralDetailsLoading] = useState(true);

    const { getCustomerJourneyPreviewById } = useRef({
        getCustomerJourneyPreviewById: CustomerJourneyHttpService.getCustomerJourneyPreviewById(),
    }).current;

    const cloneClickHandler = () => {
        const params = { workflowId };
        goToRoute(REALTIME_JOURNEY_CLONE, params);
        handleClose(ModalsActions.CLONE);
    };

    const { getCustomerJourneySchema } = useRef({
        getCustomerJourneySchema: CustomerJourneyHttpService.getCustomerJourneySchema(),
    }).current;

    useEffect(() => {
        doGetRequest(getCustomerJourneySchema.request, {
            queryString: { promotionType: RealtimePromotionTypes.CustomerJourney },
            successCallback: (data) => {
                setGroupBlocks(groupBlocksBuilder(data));
            },
        });
        doGetRequest(getCustomerJourneyPreviewById.request, {
            queryString: { workflowId },
            successCallback: (data) => {
                setInfo({ title: data.Name, description: data.Description, createdDate: data.CreatedDate });
                setIsGeneralDetailsLoading(false);
                setInitialData(JSON.parse(data.Config)?.Blocks);
            },
            errorCallback: (error) => {
                console.log(error);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowId]);

    useEffect(() => {
        if (!isEmpty(initialData) && !isEmpty(groupBlocks)) {
            const tmpElements = mapConfigToFlowEditorModel(initialData, groupBlocks);
            tmpElements.forEach((node) => {
                if (isNode(node)) {
                    node.data.readOnly = true;
                    node.data.hasInfo = false;
                }
            });
            setInitialElements(tmpElements);
        }
    }, [initialData, groupBlocks]);

    return (
        <>
            <Modal
                closeOnClickOutside={true}
                onCancel={() => handleClose(ModalsActions.CLOSE)}
                withPortal={true}
                background="dark-background"
                position="center"
                visible={isVisible}
                closable={false}
                size="content-size"
                className="segment-list-modal crm-template-info-modal"
                title={
                    <div className="segment-list-modal-head">
                        {t(l.PredefinedCustomerJourneyInfo)}
                        <div className="segment-list-modal-h-icons">
                            <Button
                                icon="bc-icon-clone"
                                appearance="minimal"
                                color="primary"
                                onClick={() => cloneClickHandler()}
                            />
                        </div>
                    </div>
                }
                footer={
                    <Button color="default" appearance="minimal" onClick={() => handleClose(ModalsActions.CLOSE)}>
                        {t(l.Close)}
                    </Button>
                }
            >
                <CustomerJourneyGeneralDetails {...info} isLoading={isGeneralDetailsLoading} />

                <BusyLoader isBusy={isEmpty(initialElements)} type="spinner" spinnerSize="small">
                    <>
                        <div className="segment-modal-bottom smb-wrap">
                            <div className="title-wrapper">
                                <p>Journey Blocks</p>
                                <span className="title-wrapper-line" />
                            </div>
                            <ReactFlowProvider>
                                <FlowEditor initialElements={initialElements} readOnly={true} />
                            </ReactFlowProvider>
                        </div>
                    </>
                </BusyLoader>
            </Modal>
        </>
    );
};

CustomerJourneyPredefinedInfoModal.propTypes = {
    workflowId: PropTypes.number.isRequired,
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func,
};

CustomerJourneyPredefinedInfoModal.defaultProps = {
    handleClose: noop,
    isVisible: false,
};

export default memo(CustomerJourneyPredefinedInfoModal);
