import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import UI Components
import { Checkbox, Label, Tooltip } from '@geneui/components';
// Import Component
import CJCascadeDropdown from '../CJCascadeDropdown';
// Import Constants
import { CJArgumentValuesTypes } from 'constants/common';

const DynamicVariableOption = ({ label, elements, option, node, getUpdate, data, defaultValue }) => {
    const [isChecked, setIsChecked] = useState(defaultValue?.isChecked);
    const [property, setProperty] = useState(defaultValue?.value);

    const checkboxChangeHandler = useCallback(() => {
        setIsChecked((prev) => !prev);
    }, []);

    useEffect(() => {
        getUpdate({ isChecked: isChecked, value: property });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked, property]);

    const propertyChangeHandler = useCallback((e) => {
        setProperty({
            blockId: e?.parentValue,
            propertyName: e?.value,
            logicFunction: e?.logicFunction,
            argumentInType: CJArgumentValuesTypes.ReferenceInArgument,
        });
    }, []);

    const toCascadeDropdownValue = (property) => {
        return {
            parentValue: property?.blockId,
            value: property?.propertyName,
            logicFunction: property?.logicFunction,
        };
    };

    return (
        <div className="crm-template-dynamic-variable">
            <div className="crm-template-item-label-container">
                <Tooltip text={label}>
                    <Label>{label}</Label>
                </Tooltip>
                <Checkbox checked={isChecked} onChange={checkboxChangeHandler} />
            </div>
            <div className="crm-cj-template-filter-condition-inputs-right-content">
                <CJCascadeDropdown
                    isDisabled={!isChecked}
                    value={toCascadeDropdownValue(property)}
                    data={data}
                    onChange={propertyChangeHandler}
                    option={{
                        ModelRelationScope: option?.ModelRelationScope,
                        SelfModelTypeName: option?.SelfModelTypeName,
                        SelfModelProperties: option?.SelfModelProperties,
                    }}
                    node={node}
                    elements={elements}
                    onValidationChange={(isValid) => getUpdate({ isValid })}
                />
            </div>
        </div>
    );
};

DynamicVariableOption.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    defaultValue: PropTypes.shape({
        isChecked: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }),
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object.isRequired,
    getUpdate: PropTypes.func,
};

DynamicVariableOption.defaultProps = {
    onChange: noop,
};

export default DynamicVariableOption;
