import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop, head } from 'lodash';
// Import UI Components
import { Modal, Button } from '@geneui/components';
// Import Components
import { DataTable } from 'components';
import ConfigureFilterTagActionsButtons from './ConfigureFilterTagActionsButtons';
import ConfigureFilterTagActionsModals from './ConfigureFilterTagActionsModals';
// Import Hooks
import { useToaster } from 'hooks';
// Import Constants
import { l, ModalsActions } from 'constants/common';
import { DataTableColumnsConfigs } from './config';

const ConfigurationFilterTagModal = ({
    filtersTagKey,
    data,
    isDataLoading,
    isModalVisible,
    onCancel,
    getFiltersTagsList,
    applyFiltersAction,
}) => {
    const { t } = useTranslation();
    const { showToaster } = useToaster();

    const [isDeleteModalOpenedState, setIsDeleteModalOpenedState] = useState(false);
    const [openedModalData, setOpenedModalData] = useState({});

    const getTableRows = () => {
        return data.map(({ Label, Filters }, index) => ({
            hasHover: true,
            dragDisable: true,
            data: {
                index,
                Label,
                Filters,
            },
        }));
    };

    const getColumns = () => {
        return DataTableColumnsConfigs(t).columns;
    };

    const modalsStateSetDict = {
        deleteFilterTag: {
            key: 'deleteFilterTag',
            fn: setIsDeleteModalOpenedState,
        },
    };

    const onCloseModalHandler = (modalStateKey, action, alertType, _alertMessage) => {
        if (action === ModalsActions.DELETE) {
            showToaster(alertType, t(l.FilterGroupWasDeletedSuccessfully));
            getFiltersTagsList();
        }

        modalsStateSetDict[modalStateKey].fn(false);
    };

    // Actions part
    const deleteFilterTagAction = (filterTag) => {
        setOpenedModalData(filterTag);
        setIsDeleteModalOpenedState(true);
    };

    const applyFiltersActionHandler = (filterGroupFilters) => {
        const existingFilters = filterGroupFilters.reduce((acc, filter) => {
            acc = [...acc, head(filter.Values)];
            return acc;
        }, []);
        applyFiltersAction(existingFilters);
    };

    const getRowActionBar = (row) => {
        const { data } = row;

        return (
            <>
                <ConfigureFilterTagActionsButtons
                    data={data}
                    deleteFilterTagAction={deleteFilterTagAction}
                    applyFiltersAction={applyFiltersActionHandler}
                />
            </>
        );
    };

    return (
        <>
            <div className="crm-category-modal-wrap">
                <Modal
                    background="dark-background"
                    position="center"
                    size="content-size"
                    title={t(l.SavedFilters)}
                    closable={false}
                    visible={isModalVisible}
                    onCancel={onCancel}
                    closeOnClickOutside={true}
                    footer={
                        <Button appearance="minimal" onClick={onCancel}>
                            {t(l.Close)}
                        </Button>
                    }
                >
                    <div className="manage-label-popup-content">
                        <DataTable
                            rowKey="index"
                            columnKey="dataKey"
                            withPagination={false}
                            withSearch={true}
                            withPageSelector={false}
                            data={getTableRows()}
                            dataCount={data.length}
                            columns={getColumns()}
                            rowActionBar={getRowActionBar}
                            isLoading={isDataLoading}
                        />
                    </div>
                </Modal>
            </div>

            <ConfigureFilterTagActionsModals
                filtersTagKey={filtersTagKey}
                isDeleteModalOpenedState={isDeleteModalOpenedState}
                onCloseModalHandler={onCloseModalHandler}
                modalsStateSetDict={modalsStateSetDict}
                openedModalData={openedModalData}
            />
        </>
    );
};

ConfigurationFilterTagModal.propTypes = {
    filtersTagKey: PropTypes.string.isRequired,
    data: PropTypes.array,
    isDataLoading: PropTypes.bool.isRequired,
    getFiltersTagsList: PropTypes.func,
    applyFiltersAction: PropTypes.func,
    isModalVisible: PropTypes.bool,
    onCancel: PropTypes.func,
};

ConfigurationFilterTagModal.defaultProps = {
    isModalVisible: false,
    onCancel: noop,
    getFiltersTagsList: noop,
    applyFiltersAction: noop,
};

export default memo(ConfigurationFilterTagModal);
