import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isNil, isString } from 'lodash';
// Import Components
import { KeyValueItemBase } from 'components';
// Import Constants
import { l } from 'constants/common';

const KeyValueItem = ({ isLoading, label, value, classNames, vertical, ...rest }) => {
    const { t } = useTranslation();
    const [classNamesBase, setClassNamesBase] = useState({ ...classNames });

    useEffect(() => {
        setClassNamesBase(() => {
            const result = { ...classNames };
            let additionalClassName = 'key-value-info-content';
            if (vertical) {
                additionalClassName = 'key-value-items-vertical';
                result.pair = `${classNamesBase.pair} ${additionalClassName}`;
            }
            return result;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classNames, vertical]);

    return (
        <KeyValueItemBase
            label={label}
            value={isNil(value) || (isString(value) && value.trim().length === 0) ? t(l.NA) : value}
            isLoading={isLoading}
            classNames={classNamesBase}
            {...rest}
        />
    );
};

KeyValueItem.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    classNames: PropTypes.object,
    vertical: PropTypes.bool,
};

KeyValueItem.defaultProps = {
    classNames: {
        pair: 'key-value-info-content',
        key: 'key-value-items-title',
        value: 'key-value-items-subtitle',
        skeleton: 'key-value-items-skeleton',
    },
    vertical: false,
};

export default KeyValueItem;
