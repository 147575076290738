import { SignInActionTypes as Types } from './types';

const crmSignInFetch = (status) => ({
    type: Types.CRM_SIGN_IN_FETCH,
    payload: status,
});

const crmSignInSucceed = (status) => ({
    type: Types.CRM_SIGN_IN_SUCCEEDED,
    payload: status,
});

const crmSignInFailed = (error) => ({
    type: Types.CRM_SIGN_IN_FAILED,
    payload: error,
});

const clearCRMSignIn = (error) => ({
    type: Types.CLEAR_CRM_SIGN_IN,
    payload: error,
});

export default {
    crmSignInFetch,
    crmSignInSucceed,
    crmSignInFailed,
    clearCRMSignIn,
};
