import React, { memo, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, isObject, noop } from 'lodash';
// Import UI Components
import { BusyLoader } from '@geneui/components';
// Import Components
import { DataTable } from 'components';
import { BonusActionsModals, BonusActionsButtons, BonusOverlayContent } from 'components/BonusList';
// Import hooks
import { useListBaseRequestBody, useRequest } from 'hooks';
// Import services
import { BonusHttpService, TemplateHttpService, UtilsHttpService } from 'services/http';
import { Helpers } from 'services';
// Import configs
import { DataTableColumnsConfigs, InternalTemplateListConfig } from './configs';
// Import constants
import { DynamicBonusTypes, l, BonusTypesLabels, KPISources } from 'constants/common';
// Import scss
import 'assets/scss/templateListPage.scss';
import 'assets/scss/bonus.scss';

const { getBonusSupportedCurrencies } = Helpers;

const BonusList = forwardRef(
    (
        {
            getHeaderActions,
            isShowColumnChooser,
            listAction,
            listSelectorKey,
            isHasRowRadioSelect,
            getRadioSelectedRow,
            bonusDetailsAmounts,
            setBonusDetailsAmounts,
            internalMessage,
            setInternalMessage,
            bonusCalculationRadioGroupValue,
            setBonusCalculationRadioGroupValue,
            bonusCalculationActiveTabKey,
            setBonusCalculationActiveTabKey,
            setBonusCalculateAction,
            bonusCalculateAction,
            bonusKind,
            setBonusKind,
            considerLimits,
            setConsiderLimits,
            percentage,
            setPercentage,
            selectedWallet,
            setSelectedWallet,
            defaultRadioSelectedRow,
            selectedExpirationType,
            setSelectedExpirationType,
            expirationDateValue,
            setExpirationDateValue,
            expirationPeriodValue,
            setExpirationPeriodValue,
        },
        ref,
    ) => {
        const dispatch = useDispatch();
        const { doGetRequest, doPostRequest } = useRequest();
        const { t } = useTranslation();
        const [bonusSelectedRow, setBonusSelectedRow] = useState();
        const { tableData } = useSelector((state) => state[listSelectorKey]);
        const { filters, paging, sorting } = tableData;
        const { partnerCurrencies } = useSelector((state) => state.partnerSettings);

        const isInit = useRef(false);
        const dataTableRef = useRef();

        const [
            internalMessageBaseRequestBody,
            internalMessageNewFilterField,
            setInternalMessageBaseRequestBodyFilters,
        ] = useListBaseRequestBody(
            InternalTemplateListConfig.filters,
            InternalTemplateListConfig.paging,
            InternalTemplateListConfig.sorting,
        );

        const [
            baseRequestBody,
            setBaseRequestBodyFilters,
            // eslint-disable-next-line no-unused-vars
            _newFilterField,
            setBaseRequestBodyPagingPageNumber,
            setBaseRequestBodyPagingPageSize,
            setBaseRequestBodySorting,
        ] = useListBaseRequestBody(filters, paging, sorting);

        const { setBonusListTablePagingPageNumber, setBonusListTablePagingPageSize, setBonusListTableSorting } =
            listAction;

        const [isBonusListLoading, setIsBonusListLoading] = useState(false);
        const [bonusListFilters, setBonusListFilters] = useState(baseRequestBody);
        const [bonusListData, setBonusListData] = useState([]);
        const [bonusListDataCount, setBonusListDataCount] = useState(0);

        const [isInfoModalOpenedState, setIsInfoModalOpenedState] = useState(false);
        const [openedModalData, setOpenedModalData] = useState({});

        const { columns } = DataTableColumnsConfigs(t);

        const {
            getBonusListRequest,
            getTemplatesRequest,
            getCurrenciesRequest,
            getBonusCalculateActionsRequest,
            getBonusObjectsByTypeRequest,
            getFreeSpinExchangeRatesRequest,
            getBonusById,
        } = useMemo(
            () => ({
                getFreeSpinExchangeRatesRequest: UtilsHttpService.getFreeSpinExchangeRates(),
                getBonusListRequest: BonusHttpService.getBonusList(),
                getTemplatesRequest: TemplateHttpService.getTemplateList(),
                getCurrenciesRequest: UtilsHttpService.getCurrencyRates(),
                getBonusCalculateActionsRequest: UtilsHttpService.getBonusCalculateActions(),
                getBonusObjectsByTypeRequest: UtilsHttpService.getBonusObjectsByType(),
                getBonusById: BonusHttpService.getBonusById(),
            }),
            [],
        );

        // Bonus overlay content
        const [internalMessages, setInternalMessages] = useState();
        const [currencies, setCurrencies] = useState();
        const [bonusCalculateActions, setBonusCalculateActions] = useState();
        const [bonusObjects, setBonusObjects] = useState();
        const [bonusKPIs, setBonusKPIs] = useState();
        const [freeSpinCurrencies, setFreeSpinCurrencies] = useState([]);

        const getBonuses = useCallback(
            (filter = bonusListFilters) => {
                setIsBonusListLoading(true);
                doPostRequest(getBonusListRequest.request, {
                    requestBody: filter,
                    successCallback: (Data) => {
                        setBonusListData((isObject(Data) && getTableRows(Data.Data)) || []);
                        setBonusListDataCount(Data.Count);
                        setIsBonusListLoading(false);
                    },
                    errorCallback: () => setIsBonusListLoading(false),
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [bonusListFilters],
        );

        const infoBonusAction = (bonus) => {
            setOpenedModalData(bonus);
            setIsInfoModalOpenedState(true);
        };

        const modalsStateSetDict = {
            infoBonus: {
                key: 'infoBonus',
                fn: setIsInfoModalOpenedState,
            },
        };

        const onCloseModalHandler = (modalStateKey) => {
            modalsStateSetDict[modalStateKey].fn(false);
        };

        const getRowActionBar = (row) => {
            const { data } = row;

            return (
                <>
                    <BonusActionsButtons data={data} infoBonusAction={infoBonusAction} />
                </>
            );
        };

        // Data table part
        const setPagingPageNumberHandler = (pageNumber) => {
            setBaseRequestBodyPagingPageNumber(pageNumber);
            setBonusListFilters(baseRequestBody);
            dispatch(setBonusListTablePagingPageNumber(pageNumber));
        };

        const setPagingPageSizeHandler = (pageSize) => {
            setBaseRequestBodyPagingPageSize(pageSize);
            dispatch(setBonusListTablePagingPageSize(pageSize));
            setBonusListFilters(baseRequestBody);
        };

        const setSortingHandler = (sortingDirection, columnName) => {
            setBaseRequestBodySorting(sortingDirection, columnName);
            dispatch(setBonusListTableSorting(baseRequestBody.Sorting));
            setBonusListFilters(baseRequestBody);
        };

        const getTableColumns = () => {
            return columns;
        };

        const getTableRows = (bonusListData) => {
            return bonusListData.map(
                ({
                    Name,
                    Type,
                    StartDate,
                    EndDate,
                    Id,
                    BonusDetails,
                    InternalDesc,
                    Description,
                    TriggerType,
                    State,
                }) => ({
                    hasHover: true,
                    dragDisable: true,
                    nestedData: [],
                    data: {
                        Name,
                        Type,
                        StartDate,
                        EndDate,
                        Id,
                        BonusDetails,
                        InternalDesc,
                        Description,
                        TriggerType,
                        State,
                    },
                }),
            );
        };

        const getTableRefreshFn = () => {
            getBonuses(bonusListFilters);
        };

        const getDataTableName = () => <span>{t(l.Bonuses)}</span>;

        // Filter update part
        useEffect(() => {
            if (isInit.current) {
                getBonuses(bonusListFilters);
            }
            /* eslint-disable react-hooks/exhaustive-deps */
        }, [bonusListFilters]);

        useEffect(() => {
            setBaseRequestBodyFilters(filters);
            setBonusListFilters(baseRequestBody);
            isInit.current = true;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filters]);

        useImperativeHandle(ref, () => ({
            resetSelectedRows() {
                dataTableRef.current.resetSelectedRows();
            },
            reset() {
                dataTableRef.current.reset();
            },
        }));

        const cleanUp = () => {
            return () => {
                getBonusListRequest.cancel('BonusList:getBonusListRequest');
                getTemplatesRequest.cancel('BonusList:getTemplatesRequest');
                getCurrenciesRequest.cancel('BonusList:getCurrenciesRequest');
                getBonusCalculateActionsRequest.cancel('BonusList:getBonusCalculateActionsRequest');
                getBonusObjectsByTypeRequest.cancel('BonusList:getBonusObjectsByTypeRequest');
                getFreeSpinExchangeRatesRequest.cancel('BonusList:getFreeSpinExchangeRatesRequest');
                getBonusById.cancel('BonusList:getBonusById');
            };
        };

        const getRadioSelected = (row) => {
            if (isNil(row?.data?.Id)) {
                setBonusSelectedRow(row);
                getRadioSelectedRow(row);
            } else {
                doGetRequest(getBonusById.request, {
                    queryString: { bonusId: row.data.Id },
                    successCallback: (data) => {
                        const selectedRow = { data };
                        setBonusSelectedRow(selectedRow);
                        getRadioSelectedRow(selectedRow);
                    },
                });
            }
        };

        const getInternalMessages = (value = '') => {
            const { nameFilterConfig, filters } = InternalTemplateListConfig;
            const { name, comparision } = nameFilterConfig;
            const nameFilter = internalMessageNewFilterField(name, comparision, value);

            setInternalMessageBaseRequestBodyFilters([...filters, nameFilter]);
            return internalMessageBaseRequestBody;
        };

        const init = () => {
            doPostRequest(getTemplatesRequest.request, {
                requestBody: getInternalMessages(),
                successCallback: (Data) => {
                    setInternalMessages(
                        (isObject(Data) && Data.Data.map((item) => ({ label: item.Name, value: item.TemplateId }))) ||
                            [],
                    );
                },
            });

            doGetRequest(getCurrenciesRequest.request, {
                successCallback: (Data) => {
                    setCurrencies((isObject(Data) && Data) || []);
                },
            });
            doGetRequest(getBonusCalculateActionsRequest.request, {
                successCallback: (Data) => {
                    setBonusCalculateActions(
                        (isObject(Data) && Data.map((item) => ({ label: item.Name, value: item.Id }))) || [],
                    );
                },
            });
            doGetRequest(getBonusObjectsByTypeRequest.request, {
                queryString: { type: KPISources.Object },
                successCallback: (Data) => {
                    setBonusObjects(
                        (isObject(Data) && Data.map((item) => ({ label: item.Name, value: item.Id }))) || [],
                    );
                },
            });
            doGetRequest(getBonusObjectsByTypeRequest.request, {
                queryString: { type: KPISources.KPI },
                successCallback: (Data) => {
                    setBonusKPIs((isObject(Data) && Data.map((item) => ({ label: item.Name, value: item.Id }))) || []);
                },
            });
            doGetRequest(getFreeSpinExchangeRatesRequest.request, {
                successCallback: (data) => {
                    setFreeSpinCurrencies(data);
                },
            });
        };

        const setIsVisibleBonusInfoModal = (isVisible) => {
            setIsInfoModalOpenedState(isVisible);
            if (!isNil(bonusSelectedRow?.data) && isVisible === true) {
                setOpenedModalData(bonusSelectedRow.data);
            }
        };

        const supportedWallets = useMemo(() => {
            return partnerCurrencies.filter((el) => el.IsWalletAutocreatable);
        }, [partnerCurrencies]);

        const isTriggerRequired = bonusSelectedRow?.data?.TriggerType !== 0;
        const isBoostBonus = bonusSelectedRow?.data?.Type === BonusTypesLabels.BoostBonus;
        const isNFTBonus = bonusSelectedRow?.data?.Type === BonusTypesLabels.NFTBonus;
        const isTradingPlanBonus = bonusSelectedRow?.data?.Type === BonusTypesLabels.TradingPlan;
        const supportedCurrencies = useMemo(
            () => getBonusSupportedCurrencies(bonusSelectedRow?.data),
            [bonusSelectedRow?.data],
        );

        const isVisibleRadioSelection = !isTriggerRequired;

        const isVisibleCalculationSection =
            !isNFTBonus &&
            (!isTriggerRequired ||
                (!isEmpty(supportedWallets) && (isBoostBonus || supportedCurrencies.includes(selectedWallet))));

        const isVisibleAlert =
            !isNFTBonus &&
            ((isTriggerRequired && isEmpty(supportedWallets)) || !isVisibleRadioSelection) &&
            !isTradingPlanBonus;

        useEffect(init, []);

        useEffect(cleanUp, []);

        return (
            <>
                <DataTable
                    classNames="bonus-list-table"
                    rowKey="Id"
                    columnKey="dataKey"
                    name={getDataTableName()}
                    isColumnsSortable={true}
                    withPagination={true}
                    data={bonusListData}
                    dataCount={bonusListDataCount}
                    rowActionBar={getRowActionBar}
                    columns={getTableColumns()}
                    isLoading={isBonusListLoading}
                    isShowRefreshButton={true}
                    isShowColumnChooser={isShowColumnChooser}
                    setPagingPageSize={setPagingPageSizeHandler}
                    setPagingPageNumber={setPagingPageNumberHandler}
                    setSorting={setSortingHandler}
                    onRefreshClick={getTableRefreshFn}
                    headerActions={getHeaderActions()}
                    isHasRowRadioSelect={isHasRowRadioSelect}
                    getRadioSelectedRow={getRadioSelected}
                    withOverlay={true}
                    defaultRadioSelectedRow={defaultRadioSelectedRow}
                    ref={dataTableRef}
                    overlayContent={
                        <BusyLoader
                            isBusy={
                                isNil(bonusSelectedRow) ||
                                isNil(internalMessages) ||
                                isNil(currencies) ||
                                isNil(bonusCalculateActions) ||
                                isNil(bonusObjects) ||
                                isNil(bonusKPIs)
                            }
                        >
                            {!(
                                isNil(bonusSelectedRow) ||
                                isNil(internalMessages) ||
                                isNil(currencies) ||
                                isNil(bonusCalculateActions) ||
                                isNil(bonusObjects) ||
                                isNil(bonusKPIs)
                            ) && (
                                <BonusOverlayContent
                                    name={bonusSelectedRow.data.Name}
                                    description={bonusSelectedRow.data.Description}
                                    type={bonusSelectedRow.data.Type}
                                    bonusDetails={bonusSelectedRow.data.BonusDetails}
                                    bonusDetailsAmounts={bonusDetailsAmounts}
                                    setBonusDetailsAmounts={setBonusDetailsAmounts}
                                    internalMessages={internalMessages}
                                    internalMessage={internalMessage}
                                    setInternalMessage={setInternalMessage}
                                    bonusCalculationRadioGroupValue={bonusCalculationRadioGroupValue}
                                    setBonusCalculationRadioGroupValue={setBonusCalculationRadioGroupValue}
                                    bonusCalculationActiveTabKey={bonusCalculationActiveTabKey}
                                    setBonusCalculationActiveTabKey={setBonusCalculationActiveTabKey}
                                    bonusCalculateActions={bonusCalculateActions}
                                    bonusCalculateAction={bonusCalculateAction}
                                    setBonusCalculateAction={setBonusCalculateAction}
                                    considerLimits={considerLimits}
                                    setConsiderLimits={setConsiderLimits}
                                    percentage={percentage}
                                    setPercentage={setPercentage}
                                    bonusKind={bonusKind}
                                    bonusKinds={{
                                        [DynamicBonusTypes.Object]: bonusObjects,
                                        [DynamicBonusTypes.KPI]: bonusKPIs,
                                    }}
                                    setBonusKind={setBonusKind}
                                    isVisibleRadioSelection={isVisibleRadioSelection}
                                    isVisibleCalculationSection={isVisibleCalculationSection}
                                    isVisibleAlert={isVisibleAlert}
                                    setIsVisibleBonusInfoModal={setIsVisibleBonusInfoModal}
                                    freeSpinCurrencies={freeSpinCurrencies}
                                    supportedCurrencies={supportedCurrencies}
                                    selectedWallet={selectedWallet}
                                    setSelectedWallet={setSelectedWallet}
                                    selectedExpirationType={selectedExpirationType}
                                    setSelectedExpirationType={setSelectedExpirationType}
                                    expirationDateValue={expirationDateValue}
                                    setExpirationDateValue={setExpirationDateValue}
                                    expirationPeriodValue={expirationPeriodValue}
                                    setExpirationPeriodValue={setExpirationPeriodValue}
                                    supportedWallets={supportedWallets}
                                />
                            )}
                        </BusyLoader>
                    }
                />

                <BonusActionsModals
                    isInfoModalOpenedState={isInfoModalOpenedState}
                    onCloseModalHandler={onCloseModalHandler}
                    modalsStateSetDict={modalsStateSetDict}
                    openedModalData={openedModalData}
                />
            </>
        );
    },
);

BonusList.displayName = 'BonusList';

BonusList.propTypes = {
    listSelectorKey: PropTypes.string.isRequired,
    listAction: PropTypes.shape({
        setBonusListTablePagingPageNumber: PropTypes.func.isRequired,
        setBonusListTablePagingPageSize: PropTypes.func.isRequired,
        setBonusListTableSorting: PropTypes.func.isRequired,
    }).isRequired,
    getRadioSelectedRow: PropTypes.func,
    getHeaderActions: PropTypes.func,
    isHasRowRadioSelect: PropTypes.bool,
    isShowColumnChooser: PropTypes.bool,
    defaultRadioSelectedRow: PropTypes.object,
    setBonusDetailsAmounts: PropTypes.func.isRequired,
    internalMessage: PropTypes.number,
    setInternalMessage: PropTypes.func.isRequired,
    currencies: PropTypes.array,
    bonusDetailsAmounts: PropTypes.object,
    bonusCalculationRadioGroupValue: PropTypes.number,
    setBonusCalculationRadioGroupValue: PropTypes.func.isRequired,
    bonusCalculationActiveTabKey: PropTypes.string.isRequired,
    setBonusCalculationActiveTabKey: PropTypes.func.isRequired,
    setBonusCalculateAction: PropTypes.func.isRequired,
    bonusCalculateAction: PropTypes.string,
    bonusKind: PropTypes.object,
    setBonusKind: PropTypes.func.isRequired,
    considerLimits: PropTypes.object.isRequired,
    setConsiderLimits: PropTypes.func.isRequired,
    percentage: PropTypes.object.isRequired,
    setPercentage: PropTypes.func.isRequired,
    selectedWallet: PropTypes.string,
    setSelectedWallet: PropTypes.func,
    useSecondaryCurrency: PropTypes.bool,
    setUseSecondaryCurrency: PropTypes.func,
    secondaryCurrencyCode: PropTypes.string,
    selectedExpirationType: PropTypes.string,
    setSelectedExpirationType: PropTypes.func,
    expirationDateValue: PropTypes.string,
    setExpirationDateValue: PropTypes.func,
    expirationPeriodValue: PropTypes.string,
    setExpirationPeriodValue: PropTypes.func,
};

BonusList.defaultProps = {
    getHeaderActions: noop,
    getRadioSelectedRow: noop,
    isHasRowRadioSelect: false,
    isShowColumnChooser: true,
    defaultRadioSelectedRow: null,
};

export default memo(BonusList);
