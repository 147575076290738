import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Import Services
import { getCustomerJourneyBlockTranslatableErrorText } from 'services/customerJourney';
import { Helpers } from 'services';
// Import UI Components
import { Icon, Tooltip } from '@geneui/components';
// Import Constants
import {
    l,
    ClientsCountType,
    CustomerJourneyGroupBlockTypes,
    CustomerJourneyStatusLabels,
    CustomerJourneyWorkingState,
    PERMISSIONS,
} from 'constants/common';
// Import Hooks
import { useHasPermission } from 'hooks';

const { hasLabel } = Helpers;

const BaseBlock = ({ node }) => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const isManagerOrAdmin = hasPermission([PERMISSIONS.CRMAdmin, PERMISSIONS.CRMManager]);
    const {
        defaultIcon,
        cssClassName,
        actions,
        name: defaultName,
        blockType,
        readOnly,
        customerJourneyStatus,
        workingState,
        allClientsCounts,
        isFiltered,
        currentClientsCounts,
        isValid,
        errorMessages,
        isEditable,
        isDeletable,
        isCloneable,
        hasInfo,
        totalClientsCount,
        totalClientsRemoveCount,
        clientsCountType,
        removeClientsCounts,
        showRemovedClientsCount,
        targetClientsCount,
        targetClientsRemoveCount,
    } = node.data;

    const blockTitleLabel = l[`CJBlock${blockType}`];

    const [isDisabled, setIsDisabled] = useState(hasLabel(workingState, CustomerJourneyWorkingState.Disabled));
    const [name, setName] = useState(t(defaultName));
    // const [isShowClientsListActive, setIsShowClientsListActive] = useState(false);
    // const [isBlockInfoActive, setIsBlockInfoActive] = useState(false);

    const onNameChange = (e) => {
        node.data.name = e.target.value;
        setName(node.data.name);
    };

    const onBlurHandler = () => {
        actions.editNameAction(node);
    };

    const showClientsList = (_e) => {
        // setIsShowClientsListActive(true);
        // setIsBlockInfoActive(false);
        actions.showClientsList(node);
    };

    const toggleBlockWorkingState = async (_e) => {
        const newState = await actions.toggleBlockWorkingState(node, isDisabled);

        setIsDisabled(newState);
    };

    const showBlockInfo = (_e) => {
        // setIsBlockInfoActive(true);
        // setIsShowClientsListActive(false);
        actions.showBlockInfo(node);
    };

    const removeElementClickHandler = (_e) => {
        actions.deleteAction([node]);
    };

    const cloneElementClickHandler = (_e) => {
        actions.cloneAction(node);
    };

    const editElementClickHandler = (e) => {
        e.stopPropagation();
        actions.editAction(node);
    };

    const getPercentage = (current, total) => {
        if (total === 0 || isNil(total)) {
            return 0;
        }

        const percent = (current / total) * 100;

        if (percent >= 10) {
            return Math.floor(percent);
        }

        return +percent.toFixed(1);
    };

    useEffect(() => {
        setName(node.data.name);
    }, [node]);

    const strBlockClientsCount = useMemo(() => {
        const tmpAllClientCounts = showRemovedClientsCount ? allClientsCounts + removeClientsCounts : allClientsCounts;
        return !isFiltered ? tmpAllClientCounts : `${allClientsCounts} / ${currentClientsCounts}`;
    }, [isFiltered, allClientsCounts, currentClientsCounts, showRemovedClientsCount, removeClientsCounts]);

    const percentageCurrentValue = useMemo(() => {
        const tmpAllClientCounts = showRemovedClientsCount
            ? currentClientsCounts + removeClientsCounts
            : currentClientsCounts;

        const totalClients = showRemovedClientsCount ? totalClientsCount + totalClientsRemoveCount : totalClientsCount;
        const targetClients = showRemovedClientsCount
            ? targetClientsCount + targetClientsRemoveCount
            : targetClientsCount;

        const totalPercentage = getPercentage(
            tmpAllClientCounts,
            clientsCountType === ClientsCountType.Total ? totalClients : targetClients,
        );

        return totalPercentage;
    }, [
        clientsCountType,
        currentClientsCounts,
        removeClientsCounts,
        showRemovedClientsCount,
        targetClientsCount,
        targetClientsRemoveCount,
        totalClientsCount,
        totalClientsRemoveCount,
    ]);

    return (
        <Tooltip
            text={getCustomerJourneyBlockTranslatableErrorText(t, errorMessages ?? [])}
            className="crm-flow-editor-block-tooltip"
        >
            <div className={`crm-flow-editor-block-body ${cssClassName}`}>
                {!isNil(allClientsCounts) && (
                    <div
                        className={classNames('crm-flow-editor-block-clients-count-container', {
                            'disabled-section': isDisabled,
                        })}
                    >
                        {readOnly && node.type !== CustomerJourneyGroupBlockTypes.target && (
                            <div className="crm-flow-editor-block-clients-percentage">{percentageCurrentValue}%</div>
                        )}
                        <div className="crm-flow-editor-block-clients-count">{strBlockClientsCount}</div>
                    </div>
                )}
                <div
                    className={classNames('crm-flow-editor-block-content', {
                        invalid: isValid === false,
                        'disabled-section': isDisabled,
                    })}
                >
                    <div>
                        <Icon type={defaultIcon} />
                    </div>
                    <div>
                        <div className="crm-flow-editor-block-title">
                            <input
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus={readOnly}
                                type="text"
                                disabled={readOnly}
                                value={name}
                                onChange={onNameChange}
                                onBlur={onBlurHandler}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </div>
                        <span>{t(blockTitleLabel)}</span>
                    </div>
                </div>
                <div className="crm-flow-editor-block-actions">
                    {readOnly ? (
                        <>
                            {isManagerOrAdmin && customerJourneyStatus === +CustomerJourneyStatusLabels.InProgress && (
                                <button
                                    onClick={toggleBlockWorkingState}
                                    className={classNames({
                                        'crm-flow-editor-block-action-active': false,
                                    })}
                                >
                                    {isDisabled ? <Icon type="bc-icon-resume" /> : <Icon type="bc-icon-status-stop" />}
                                </button>
                            )}
                            {!isNil(actions.showClientsList) && !isNil(allClientsCounts) && allClientsCounts !== 0 && (
                                <button
                                    onClick={showClientsList}
                                    className={classNames({
                                        'crm-flow-editor-block-action-active': false, //selected && isShowClientsListActive,
                                    })}
                                >
                                    <Icon type="bc-icon-table" />
                                </button>
                            )}
                            {hasInfo !== false && (
                                <button
                                    onClick={showBlockInfo}
                                    className={classNames({
                                        'crm-flow-editor-block-action-active': false, //selected && isBlockInfoActive,
                                    })}
                                >
                                    <Icon type="bc-icon-info-48" />
                                </button>
                            )}
                        </>
                    ) : (
                        <>
                            {isEditable !== false && (
                                <button onClick={editElementClickHandler}>
                                    <Icon type="bc-icon-edit-pen" />
                                </button>
                            )}
                            {isDeletable !== false && (
                                <button onClick={removeElementClickHandler}>
                                    <Icon type="bc-icon-delete-2" />
                                </button>
                            )}
                            {isCloneable !== false && (
                                <button onClick={cloneElementClickHandler}>
                                    <Icon type="bc-icon-clone" />
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Tooltip>
    );
};

BaseBlock.propTypes = {
    node: PropTypes.object.isRequired, // todo shape validation of
};

export default BaseBlock;
