import React, { memo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, noop, values } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Components
import {
    CJFilterCondition,
    CustomerJourneyVisualizationTemplateGroup,
    SelectLanguageWithSearchModal,
    StepsSetting,
} from 'components';
// Import UI Components
import { BusyLoader, Button, Checkbox, Section } from '@geneui/components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { LogicTypesValues, l } from 'constants/common';

const { itemsIsValid } = Helpers;

// eslint-disable-next-line react/display-name
const CustomerJourneyVisualizationTarget = forwardRef(
    (
        {
            collectedProperties,
            node,
            elements,
            option,
            getUpdate,
            defaultValue,
            isLive,
            isLiveChangeHandler,
            selectedLanguagesChangeHandler,
            selectedLanguages,
            stepSettingsData,
            stepSettingChangeHandler,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const [filterConditionFilters, setFilterConditionFilters] = useState(defaultValue?.condition);
        const [templateData, setTemplateData] = useState(defaultValue?.templates);

        const [partnerLanguageModalIsOpen, setPartnerLanguageModalIsOpen] = useState(false);
        const [showStepSetting, setShowStepSetting] = useState(false);

        useImperativeHandle(ref, () => ({
            changeLanguages(languages, partnerLanguages) {
                setTemplateData((prev) => {
                    return languages.reduce(
                        (acc, language) => {
                            if (isNil(prev[language])) {
                                acc[language] = {
                                    name: '',
                                    description: '',
                                    language: { flagId: language, label: partnerLanguages[language]?.Name },
                                };
                            } else {
                                acc[language] = prev[language];
                            }
                            return acc;
                        },
                        { defaultLang: prev.defaultLang },
                    );
                });
            },
        }));

        const conditionUpdateHandler = (filterValue) => {
            setFilterConditionFilters((prev) => ({ ...prev, ...filterValue }));
        };

        const templateChangeHandler = (languageKey, data) => {
            setTemplateData((prev) => {
                return { ...prev, [languageKey]: { ...prev[languageKey], ...data } };
            });
        };

        useEffect(() => {
            const isValidTemplates = itemsIsValid(values(templateData));

            getUpdate({
                isValid: isValidTemplates && filterConditionFilters?.isValid,
                condition: filterConditionFilters,
                templates: templateData,
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filterConditionFilters, templateData]);

        const handleModalIsVisible = () => {
            setPartnerLanguageModalIsOpen(!partnerLanguageModalIsOpen);
        };

        const handleOnOkModal = (values) => {
            setPartnerLanguageModalIsOpen(!partnerLanguageModalIsOpen);
            const tmpSelectedLanguages = values.map((item) => item?.data?.Id);
            selectedLanguagesChangeHandler(tmpSelectedLanguages);
        };

        const stepSettingClickHandler = () => {
            setShowStepSetting(true);
        };

        const stepSettingCloseHandler = () => {
            setShowStepSetting(false);
        };

        const stepSettingOkHandler = (data) => {
            setShowStepSetting(false);
            stepSettingChangeHandler(data);
        };

        return (
            <div className="crm-cj-visualization-step">
                <Section
                    text={
                        <ul className="module-header cr-position-radius p-top s-big">
                            <li className="left-content">
                                <div className="left-inner">
                                    <div className="module-title ellipsis-text" title="">
                                        <div className="crm-statics-segment-data-table-name">{t(l.GeneralDetails)}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    }
                    actions={<Button icon="bc-icon-settings" appearance="minimal" onClick={stepSettingClickHandler} />}
                >
                    <BusyLoader isBusy={isEmpty(elements) || isNil(node) || isNil(option)}>
                        <div className="crm-cj-visualization-target-actions">
                            <Checkbox
                                label={t(l.IsLive)}
                                checked={isLive?.value}
                                onChange={isLiveChangeHandler}
                                disabled={isLive?.isValid !== true}
                            />

                            <Button appearance="outline" onClick={handleModalIsVisible}>
                                <span>{t(l.SelectLanguage)}</span>
                            </Button>
                        </div>
                        {partnerLanguageModalIsOpen && (
                            <SelectLanguageWithSearchModal
                                onCancel={handleModalIsVisible}
                                onOk={handleOnOkModal}
                                languageList={selectedLanguages}
                            />
                        )}
                        {!isEmpty(elements) && !isNil(node) && !isNil(option) && (
                            <div>
                                <CustomerJourneyVisualizationTemplateGroup
                                    templates={templateData}
                                    onChange={templateChangeHandler}
                                />
                                <CJFilterCondition
                                    collectedProperties={collectedProperties}
                                    node={node}
                                    elements={elements}
                                    option={option}
                                    getUpdate={conditionUpdateHandler}
                                    defaultValue={filterConditionFilters}
                                    groupLabel={t(l.Condition)}
                                />
                            </div>
                        )}
                    </BusyLoader>
                </Section>
                {showStepSetting && (
                    <StepsSetting
                        isVisible={showStepSetting}
                        onCancel={stepSettingCloseHandler}
                        onOk={stepSettingOkHandler}
                        steps={stepSettingsData}
                        columnName={l.Step}
                    />
                )}
            </div>
        );
    },
);

CustomerJourneyVisualizationTarget.propTypes = {
    defaultValue: PropTypes.shape({
        condition: {
            filters: PropTypes.array,
            logic: PropTypes.string,
        },
        templates: PropTypes.object,
    }),
    node: PropTypes.object,
    elements: PropTypes.array,
    option: PropTypes.object,
    collectedProperties: PropTypes.array,
    getUpdate: PropTypes.func,
    isLive: PropTypes.object,
    isLiveChangeHandler: PropTypes.func,
    selectedLanguagesChangeHandler: PropTypes.func,
    selectedLanguages: PropTypes.array,
    stepSettingsData: PropTypes.array,
    stepSettingChangeHandler: PropTypes.func,
};

CustomerJourneyVisualizationTarget.defaultProps = {
    defaultValue: {
        condition: {
            logic: LogicTypesValues.and,
            filters: [],
        },
        templates: {},
    },
    node: null,
    elements: null,
    option: null,
    collectedProperties: [],
    getUpdate: noop,
};

export default memo(CustomerJourneyVisualizationTarget);
