import { l } from 'constants/common';

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.Currency),
                dataKey: 'CurrencyCode',
                sortable: false,
            },
            {
                text: t(l.MinAmount),
                dataKey: 'MinAmount',
                sortable: false,
            },

            {
                text: t(l.MaxAmount),
                dataKey: 'MaxAmount',
                sortable: false,
            },
        ],
    };
};

export { DataTableColumnsConfigs };
