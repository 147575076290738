import React from 'react';
import PropTypes from 'prop-types';

// Import UI Components
import CampaignsInActionList from './CampaignsInActionList';
import CustomerRelationshipMetricsList from './CustomerRelationshipMetricsList';
// Import Constants
import { Widgets } from 'constants/common';

const DashboardTablesSection = ({ parentDate, possibleWidgets }) => {
    return (
        <>
            {possibleWidgets.includes(Widgets.CampaignsInAction) && <CampaignsInActionList />}

            {possibleWidgets.includes(Widgets.CustomerRelationshipMetrics) && (
                <CustomerRelationshipMetricsList parentDate={parentDate} />
            )}
        </>
    );
};

DashboardTablesSection.propTypes = {
    parentDate: PropTypes.array.isRequired,
    possibleWidgets: PropTypes.array,
};

DashboardTablesSection.defaultProps = {
    possibleWidgets: [],
};

export default DashboardTablesSection;
