import React, { memo } from 'react';
import { values, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Alert, Tooltip } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const PreSaveValidationModalWarningContent = () => {
    const pageValidation = useSelector((state) => state.pageValidation);
    const { t } = useTranslation();

    const getAlertMessage = (warningValidations) => {
        return warningValidations.reduce((acc, item, index) => {
            acc += item.warningText;
            if (index !== warningValidations.length - 1) {
                acc += '\n';
            }
            return acc;
        }, '');
    };

    const warningValidations = values(pageValidation).filter(
        (singleValidation) => !isEmpty(singleValidation.warningText),
    );
    return !isEmpty(warningValidations) ? (
        <Tooltip
            size="default"
            position="auto"
            title={t(l.PreSaveModalWarningMessage)}
            text={getAlertMessage(warningValidations)}
        >
            <div>
                <Alert
                    className="pre-save-warning-alert"
                    closable="false"
                    title={t(l.PreSaveModalWarningMessage)}
                    message={getAlertMessage(warningValidations)}
                    type="warning"
                    iconProps={{ type: 'bc-icon-info' }}
                />
            </div>
        </Tooltip>
    ) : (
        <></>
    );
};

export default memo(PreSaveValidationModalWarningContent);
