import { isNull, keys } from 'lodash';
// Import Constants
import { ErrorShowType, l, TemplateInputTypes } from 'constants/common';
// Import Services
import { TemplateSymbolsCountFields } from 'services/template';

const buttonTextInputScenario = (value = { text: '', url: '' }) => {
    return {
        isValid: true,
        errorText: '',
        value: { text: value?.text, url: value?.url },
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'telegramButtonText',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'telegramButtonTextMaxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '30',
            },
        ],
    };
};

const buttonUrlInputScenario = (value = { text: '', url: '' }) => ({
    isValid: true,
    errorText: '',
    value: { text: value?.text, url: value?.url },
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'isUrlValidOrEmptyString',
            ErrorMessage: l.EnterValidUrl,
            ParametersValues: null,
        },
        {
            ValidationType: 'telegramButtonUrl',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
    ],
});

const bodyScenario = (state) => ({
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'maxLengthWithRegEx',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: { ...state, fieldName: 'Body', count: TemplateSymbolsCountFields.Telegram },
        },
    ],
});

const telegramTemplateValidationScenario = (state) => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    CrossValidation: {
        isValid: null,
        errorText: '',
        value: {},
        type: ErrorShowType.Toaster,
        validationCredentials: [
            {
                ValidationType: 'templateCrossValidation',
                ErrorMessage: '',
                ParametersValues: [TemplateInputTypes.Body],
            },
        ],
    },
    defaultLangButtonText: {
        ...buttonTextInputScenario(),
    },
    defaultLangButtonUrl: {
        ...buttonUrlInputScenario(),
    },
    defaultLangBody: { ...bodyScenario(state) },
});

const telegramTemplateScenarioPropertiesCreater = (data, editCloneMode, state) => {
    if (editCloneMode) {
        return keys(data).reduce((acc, id) => {
            acc[`${id}Body`] = {
                ...bodyScenario(state),
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].Body,
            };
            acc[`${id}ButtonText`] = {
                ...buttonTextInputScenario({ text: data[id].ButtonText, url: data[id].ButtonUrl }),
                isValid: isNull(data[id].isValid) ? null : true,
            };
            acc[`${id}ButtonUrl`] = {
                ...buttonUrlInputScenario({ text: data[id].ButtonText, url: data[id].ButtonUrl }),
                isValid: isNull(data[id].isValid) ? null : true,
            };
            return acc;
        }, {});
    }
    return data.reduce((acc, id) => {
        acc[`${id}Body`] = { ...bodyScenario(state) };
        acc[`${id}ButtonText`] = { ...buttonTextInputScenario() };
        acc[`${id}ButtonUrl`] = { ...buttonUrlInputScenario() };

        return acc;
    }, {});
};
export { telegramTemplateValidationScenario, telegramTemplateScenarioPropertiesCreater };
