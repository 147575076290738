import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CJMoneyValidationSchema } from 'validators/schemas.validation';
// Import UI Components
import { ExtendedInput, SkeletonLoader } from '@geneui/components';
// Import Components
import { ValidatableDropdown } from 'components';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText, getDoubleValue } from 'services/customerJourney';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import Constants
import { l } from 'constants/common';
import { Helpers } from 'services';

const { getCurrencyLabel } = Helpers;

const CJMoney = ({ defaultValue, getUpdate, option }) => {
    const { settings } = useSelector((state) => state.header);
    const { ActivePartner } = settings;
    const currencyRates = useSelector((state) => state.currencyRates[ActivePartner.CurrencyId]);

    const { t } = useTranslation();
    const { convertedData, isLoading } = useMenuItem(6);
    const formik = useFormik({
        initialValues: {
            amount: defaultValue?.amount,
            currencyCode: defaultValue?.currencyCode,
        },
        onSubmit: noop,
        validationSchema: CJMoneyValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm } = formik;
    const { amount, currencyCode } = values;

    const onChangeAmountHandler = ({ target }) => {
        setFieldValue('amount', getDoubleValue(target?.value));
    };

    const onChangeCurrencyCodeHandler = (data) => {
        setFieldValue('currencyCode', data?.value);
    };

    useEffect(() => {
        getUpdate({ isValid: !errors.amount && !errors.currencyCode });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        getUpdate({ amount, currencyCode });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.amount, values?.currencyCode]);

    useEffect(() => {
        submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="crm-cj-money-cmp">
            <ExtendedInput
                type="number"
                placeholder={t(l.Amount)}
                label={getCurrencyLabel(t(l.Amount), amount, currencyCode, ActivePartner.CurrencyId, currencyRates)}
                labelAppearance="swap"
                onChange={onChangeAmountHandler}
                value={amount}
                isValid={!errors.amount}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.amount)}
                minValue={0}
            />

            <SkeletonLoader isBusy={isLoading}>
                {!isLoading && (
                    <ValidatableDropdown
                        placeholder={t(l.Currency)}
                        label={t(l.Currency)}
                        labelAppearance="swap"
                        data={convertedData}
                        isValid={!errors.currencyCode}
                        errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.currencyCode)}
                        defaultValue={currencyCode}
                        onChange={onChangeCurrencyCodeHandler}
                        clearable={true}
                    />
                )}
            </SkeletonLoader>
        </div>
    );
};

CJMoney.propTypes = {
    defaultValue: PropTypes.shape({
        amount: PropTypes.string || PropTypes.number,
        currencyCode: PropTypes.string,
    }),
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJMoney.defaultProps = {
    defaultValue: { amount: '', currencyCode: '' },
};

export default memo(CJMoney);
