import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, values, first } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// Import UI Components
import { BusyLoader, Tabs, Tab, Tooltip } from '@geneui/components';
// Import Components
import FilterSchemaList from './FiltersContainer/FilterSchemaList';
// Import Reducers
import { DynamicSegmentTabActiveIndexAction } from 'actions';

const { changeTabActiveIndex } = DynamicSegmentTabActiveIndexAction;

const selectQueryConfigurations = (state) => state.queryConfigurations;
const selectTabActiveIndex = (state) => state.dynamicSegmentTabActiveIndex.id;

const DynamicSegmentInfo = ({ segmentFiltersData }) => {
    const dispatch = useDispatch();
    const queryConfigurations = useSelector(selectQueryConfigurations);
    const tabActiveIndex = useSelector(selectTabActiveIndex);

    const tabChangeHandler = (id) => {
        dispatch(changeTabActiveIndex({ id: `${id}` }));
    };

    return (
        <BusyLoader
            isBusy={isEmpty(queryConfigurations) || isEmpty(segmentFiltersData)}
            type="spinner"
            spinnerSize="medium"
            className="crm-justify-content-center"
        >
            {values(segmentFiltersData).length > 1 ? (
                <Tabs type="box" activeKey={tabActiveIndex} onChange={tabChangeHandler}>
                    {values(segmentFiltersData).map((subSegment) => {
                        return (
                            <Tab
                                key={subSegment.seqId}
                                className={`${+tabActiveIndex === subSegment.seqId ? 'active' : ''}`}
                                title={
                                    <Tooltip text={subSegment.name}>
                                        <div className="ellipsis-text">
                                            <button onClick={() => tabChangeHandler(subSegment.seqId, subSegment.id)}>
                                                <p>
                                                    {+tabActiveIndex === subSegment.seqId
                                                        ? subSegment.name
                                                        : subSegment.name.slice(0, 4)}
                                                </p>
                                            </button>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <FilterSchemaList
                                    filtersData={subSegment}
                                    queryConfigurations={queryConfigurations}
                                    withoutActions
                                />
                            </Tab>
                        );
                    })}
                </Tabs>
            ) : (
                <div>
                    <FilterSchemaList
                        filtersData={first(values(segmentFiltersData))}
                        queryConfigurations={queryConfigurations}
                        withoutActions
                    />
                </div>
            )}
        </BusyLoader>
    );
};

DynamicSegmentInfo.propTypes = {
    segmentFiltersData: PropTypes.shape({
        [PropTypes.string]: {
            id: PropTypes.number,
            isMain: PropTypes.bool,
            seqId: PropTypes.number,
            name: PropTypes.string,
            filters: PropTypes.shape({
                [PropTypes.string]: PropTypes.array,
            }),
        },
    }),
};

export default memo(DynamicSegmentInfo);
