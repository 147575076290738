import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { ClientTagHttpService } from 'services/http';
import { publish } from 'services/event';
// Import UI Components
import { Button, ExtendedInput } from '@geneui/components';
// Import Constants
import { l, AlertTypes, GlobalEvents } from 'constants/common';

const { success } = AlertTypes;

const CreateTag = ({ isCreateTagDisabled, onCreateTag }) => {
    const { t } = useTranslation();

    const [isCreateTagLoading, setIsCreateTagLoading] = useState(false);

    const { doPostRequest } = useRequest();
    const { showToaster } = useToaster();

    const { createClientTagRequest } = useMemo(
        () => ({
            createClientTagRequest: ClientTagHttpService.createClientTag(),
        }),
        [],
    );

    const formik = useFormik({
        initialValues: { tagName: '' },
        onSubmit: noop,
        validationSchema: Yup.object({
            tagName: Yup.string()
                .matches(/^[A-Za-z0-9]/, t(l.FirstCharacterCantBeASymbol, { fieldName: t(l.NewTagName) }))
                .min(3, t(l.MustContainMinimumCharacters, { fieldName: t(l.NewTagName), count: 3 }))
                .max(30, t(l.MustContainMaximumCharacters, { fieldName: t(l.NewTagName), count: 30 })),
        }),
    });

    const { setFieldValue, errors, values, isValid } = formik;
    const { tagName } = values;

    const onTagNameChange = (e) => {
        setFieldValue('tagName', e.target.value.trimStart().replace(/\s+/g, ' '));
    };

    const createTagHandler = () => {
        setIsCreateTagLoading(true);

        doPostRequest(createClientTagRequest.request, {
            requestBody: {
                name: tagName,
            },
            successCallback: (data) => {
                publish(GlobalEvents.ClientTagsChange);
                onCreateTag({
                    index: data,
                    TagId: data,
                    Name: tagName,
                    ClientsCount: 0,
                    ClientsCountAll: 0,
                });
                setFieldValue('tagName', '');
                showToaster(success, l.ClientTagWasSuccessfullyCreated);
            },
        }).then(() => {
            setIsCreateTagLoading(false);
        });
    };

    return (
        <div className="create-new-tag-container">
            <ExtendedInput
                className="new-tag-name-input"
                value={tagName}
                labelAppearance="swap"
                placeholder={t(l.NewTagName)}
                disabled={isCreateTagLoading || isCreateTagDisabled}
                onChange={onTagNameChange}
                isValid={isValid}
                errorText={errors.tagName}
            />
            <Button
                className="create-tag-button"
                icon="bc-icon-plus-crm"
                loading={isCreateTagLoading}
                disabled={isCreateTagDisabled || isCreateTagLoading || isEmpty(tagName) || !isValid}
                onClick={createTagHandler}
            >
                {t(l.CreateTag)}
            </Button>
        </div>
    );
};

CreateTag.propTypes = {
    isCreateTagDisabled: PropTypes.bool.isRequired,
    onCreateTag: PropTypes.func.isRequired,
};

export default memo(CreateTag);
