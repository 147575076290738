import { isEmpty, values } from 'lodash';
// Import Constants
import {
    l,
    MonthDayYearWithTimeFormat,
    MonthDayYearFormat,
    TimeFormatWithoutSeconds,
    MonthsAndDays,
} from 'constants/common';
// Import Components
import DateTimeForm from './DateTimeForm';
import DateForm from './DateForm';
import TimeForm from './TimeForm';
import DayOfMonth from './DayOfMonth';
import DayOfYear from './DayOfYear';
import WeekdayForm from './WeekdayForm';
import SameDayForm from './SameDayForm';
import DaysLaterForm from './DaysLaterForm';
import CJDTDaysLaterInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTDaysLaterInfoBlock';
import CJDTSameDayInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTSameDayInfoBlock';
import CJDTWeekdayInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTWeekdayInfoBlock';
import CJDTDayOfYearInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTDayOfYearInfoBlock';
import CJDTDayOfMonthInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTDayOfMonthInfoBlock';
import CJDTDateInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTDateInfoBlock';
import CJDTDateTimeInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTDateTimeInfoBlock';
import CJDTTimeInfoBlock from 'components/CustomerJourney/infoBlocks/CJDateTimeInfoBlock/CJDTTimeInfoBlock';
import EmptyComponent from 'components/common/EmptyComponent';
// Import Services
import { Helpers } from 'services';

const { customMoment, customMomentWithoutTimezoneConversion, monthArrayForDropdown, weeksArrayForDropdown } = Helpers;

const dynamicDateData = {
    today: {
        label: l.Today,
        value: 'today',
        component: EmptyComponent,
        infoComponent: EmptyComponent,
    },
    yesterday: {
        label: l.Yesterday,
        value: 'yesterday',
        component: EmptyComponent,
        infoComponent: EmptyComponent,
    },
    daysLater: {
        label: l.DaysLater,
        value: 'daysLater',
        component: DaysLaterForm,
        infoComponent: CJDTDaysLaterInfoBlock,
    },
    sameDay: { label: l.SameDay, value: 'sameDay', component: SameDayForm, infoComponent: CJDTSameDayInfoBlock },
    lastDayOfMonth: {
        label: l.LastDayOfMonth,
        value: 'lastDayOfMonth',
        component: EmptyComponent,
        infoComponent: EmptyComponent,
    },
    weekday: { label: l.Weekday, value: 'weekday', component: WeekdayForm, infoComponent: CJDTWeekdayInfoBlock },
    dayOfYear: { label: l.DayOfYear, value: 'dayOfYear', component: DayOfYear, infoComponent: CJDTDayOfYearInfoBlock },
    dayOfMonth: {
        label: l.DayOfMonth,
        value: 'dayOfMonth',
        component: DayOfMonth,
        infoComponent: CJDTDayOfMonthInfoBlock,
    },
    date: { label: l.Date, value: 'date', component: DateForm, infoComponent: CJDTDateInfoBlock },
    dateTime: { label: l.DateTime, value: 'dateTime', component: DateTimeForm, infoComponent: CJDTDateTimeInfoBlock },
    time: { label: l.Time, value: 'time', component: TimeForm, infoComponent: CJDTTimeInfoBlock },
};

const cjDateTimeInitialParamsCreator = (defaultValue) => {
    return defaultValue || {};
};

const dayOfMonthInitialValueCreator = (defaultValue, required, t) => {
    const resultValue = {
        months: '',
        dates: '',
        monthsData: monthArrayForDropdown(t),
        daysData: '',
    };

    if (required) {
        if (defaultValue && !isEmpty(defaultValue)) {
            const arrayOfDays = [];
            for (let i = 0; i < values(MonthsAndDays)[defaultValue.months]; i++) {
                arrayOfDays.push({ label: i + 1, value: `${i + 1}` });
            }
            resultValue.dates = defaultValue?.dates;
            resultValue.months = defaultValue?.months;
            resultValue.daysData = arrayOfDays;
        } else {
            const arrayOfDays = [];
            for (let i = 0; i < values(MonthsAndDays)[0]; i++) {
                arrayOfDays.push({ label: i + 1, value: `${i + 1}` });
            }
            resultValue.dates = '1';
            resultValue.months = '1';
            resultValue.daysData = arrayOfDays;
        }
    }
    return resultValue;
};

const weekdayInitialValueCreator = (defaultValue, required, t) => {
    const resultValue = {
        weekday: '',
        weekdayData: weeksArrayForDropdown(t),
    };

    if (required) {
        if (defaultValue && !isEmpty(defaultValue)) {
            resultValue.weekday = defaultValue?.weekday;
        } else {
            resultValue.weekday = '0';
        }
    }
    return resultValue;
};

const dateTimeInitialValueCreator = (defaultValue, required) => {
    const resultValue = {
        date: '',
        time: '',
    };

    if (required) {
        if (defaultValue && !isEmpty(defaultValue)) {
            const dateFromParams = customMomentWithoutTimezoneConversion()
                .set('year', defaultValue.years)
                .set('month', defaultValue.months)
                .set('date', defaultValue.dates)
                .set('hour', defaultValue.hours)
                .set('minute', defaultValue.minutes);
            resultValue.date = dateFromParams.format(MonthDayYearWithTimeFormat);
        } else {
            resultValue.date = customMoment().format(MonthDayYearWithTimeFormat);
        }
    }
    return resultValue;
};

const dateInitialValueCreator = (defaultValue, required) => {
    const resultValue = {
        date: '',
    };

    if (required) {
        if (defaultValue) {
            const dateFromParams = customMomentWithoutTimezoneConversion()
                .set('year', defaultValue.years)
                .set('month', defaultValue.months)
                .set('date', defaultValue.dates);
            resultValue.date = dateFromParams.format(MonthDayYearFormat);
        } else {
            resultValue.date = customMoment().format(MonthDayYearFormat);
        }
    }

    return resultValue;
};

const timeInitialValueCreator = (defaultValue, required) => {
    const resultValue = {
        time: '',
    };

    if (required) {
        if (defaultValue && !isEmpty(defaultValue)) {
            const dateFromParams = customMomentWithoutTimezoneConversion()
                .set('hour', defaultValue.hours)
                .set('minute', defaultValue.minutes);
            resultValue.time = dateFromParams.format(TimeFormatWithoutSeconds);
        } else {
            resultValue.time = customMoment().format(TimeFormatWithoutSeconds);
        }
    }
    return resultValue;
};

export {
    dynamicDateData,
    dateTimeInitialValueCreator,
    dateInitialValueCreator,
    cjDateTimeInitialParamsCreator,
    timeInitialValueCreator,
    dayOfMonthInitialValueCreator,
    weekdayInitialValueCreator,
};
