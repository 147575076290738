import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Components
import { OptionList } from 'components';
// Import SCSS
import 'assets/scss/inputWithDropDown.scss';

const InputWithDropDown = ({ defaultValue, changeHandler, data, ...rest }) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [listData, setListData] = useState(data);

    const handleInputChange = ({ target }) => {
        inputValueSetHandler(target?.value);
    };

    const popoverToggleClick = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    const searchInDropDown = ({ target }) => {
        const { value } = target;
        setSearchValue(value);
        setListData(data.filter((item) => item.label.toLowerCase().includes(value.toLowerCase())));
    };

    const dropDownSelectHandler = (e) => {
        inputValueSetHandler(e?.label);
        setIsVisiblePopover(false);
    };

    const inputValueSetHandler = (value) => {
        setInputValue(value);
        changeHandler(value);
    };

    return (
        <div className="crm-input-with-dropdown-wrapper">
            <ExtendedInput value={inputValue} onChange={handleInputChange} {...rest} />
            <OptionList
                list={listData}
                rowCount={listData.length}
                optionValue={inputValue}
                contentTop={40}
                optionSelectHandler={dropDownSelectHandler}
                keys={['label', '']}
                hideTitleWrapper={true}
                toggleHandler={popoverToggleClick}
                isVisible={isVisiblePopover}
                setIsVisible={setIsVisiblePopover}
                selectedPropertyKey={'label'}
                buttonWrapperClassName={'crm-input-with-dropdown-wrapper_button-wrapper'}
                searchHandler={searchInDropDown}
                contentWrapperClassName={'crm-input-with-dropdown-wrapper_option-list-content-wrapper'}
                searchValue={searchValue}
            />
        </div>
    );
};

InputWithDropDown.propTypes = {
    defaultValue: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

InputWithDropDown.defaultProps = {
    defaultValue: '',
};

export default InputWithDropDown;
