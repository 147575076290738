import { useTranslation } from 'react-i18next';
import { keys } from 'lodash';

const useTranslationHelper = () => {
    const { t } = useTranslation();

    function objectLabelsTranslator(obj, labelKey = 'label') {
        return keys(obj).reduce((acc, key) => {
            obj[key][labelKey] = t(obj[key][labelKey]);
            acc[key] = obj[key];
            return acc;
        }, {});
    }

    function arrayLabelsTranslator(array, labelKey = 'label') {
        return array.map((item) => {
            return { ...item, [labelKey]: t(item[labelKey]) };
        });
    }

    return { objectLabelsTranslator, arrayLabelsTranslator };
};

export default useTranslationHelper;
