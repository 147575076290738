import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Import Components
import { BonusAmountInput } from 'components/CustomerJourney/forms';

const BonusAmountsWrapper = ({ bonusDetails, bonusAmountListChangeHandler, bonusAmountsList, isRequired }) => {
    return (
        !isEmpty(bonusAmountsList) &&
        bonusDetails?.map((bonusDetail, i) => {
            return (
                <BonusAmountInput
                    key={bonusDetail.CurrencyCode}
                    index={i}
                    bonusDetail={bonusDetail}
                    setAmountData={bonusAmountListChangeHandler}
                    defaultAmountValue={bonusAmountsList[i]?.OriginalValue}
                    isRequired={isRequired}
                />
            );
        })
    );
};

BonusAmountsWrapper.propTypes = {
    bonusDetails: PropTypes.array.isRequired,
    bonusAmountListChangeHandler: PropTypes.func.isRequired,
    bonusAmountsList: PropTypes.array.isRequired,
    isRequired: PropTypes.bool.isRequired,
};

export default memo(BonusAmountsWrapper);
