import { CustomerJourneyActionTypes as Types } from 'actions/types';
// Import Constants
import { CustomerJourneyGroupBlockTypes } from 'constants/common';

const { target, action, trigger, condition } = CustomerJourneyGroupBlockTypes;

const blocksCountsInitialState = {
    [target]: 0,
    [action]: 0,
    [trigger]: 0,
    [condition]: 0,
};

const initialState = {
    blocksCounts: { ...blocksCountsInitialState },
    flowSnapshots: [],
    currentVisibleFlowVersion: null,
    maxBlocksCounts: 0,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case Types.INCREMENT_BLOCK_COUNT:
            Object.keys(payload).forEach((item) => {
                state.blocksCounts[item] += payload[item];
            });
            return Object.assign({}, state, {});
        case Types.DECREMENT_BLOCK_COUNT:
            Object.keys(payload).forEach((item) => {
                state.blocksCounts[item] -= payload[item];
            });
            return Object.assign({}, state, {});
        case Types.RESET_BLOCKS_COUNT:
            state.blocksCounts = { ...blocksCountsInitialState };
            return Object.assign({}, state, {});
        case Types.SET_BLOCKS_COUNTS:
            state.blocksCounts = { ...blocksCountsInitialState, ...payload };
            return Object.assign({}, state, {});
        case Types.SET_MAX_BLOCKS_COUNTS:
            state.maxBlocksCounts = payload;
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
