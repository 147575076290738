import React from 'react';
// Import Components
import { UsedInSection } from 'components';
//Import Constants
import { l, StateModesLabels, CustomerJourneyStatusLabels, DateTimeFormat, TimeZonePack } from 'constants/common';
//Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

const defaultClassNames = {
    pair: 'key-value-info-content',
    key: 'key-value-items-title',
    value: 'key-value-items-subtitle',
    skeleton: 'key-value-items-skeleton',
};

const infoItemsConfig = {
    workflowId: {
        label: l.WorkflowId,
        classNames: { ...defaultClassNames },
    },
    title: {
        label: l.Title,
        classNames: { ...defaultClassNames },
    },
    description: {
        label: l.Description,
        classNames: { ...defaultClassNames },
    },
    startDate: {
        label: l.AutoStart,
        format: (createdDate) => customMomentWithoutTimezoneConversion(createdDate).format(DateTimeFormat),
        classNames: { ...defaultClassNames },
    },
    endDate: {
        label: l.AutoEnd,
        format: (endDate) => customMomentWithoutTimezoneConversion(endDate).format(DateTimeFormat),
        classNames: { ...defaultClassNames },
    },
    timeZone: {
        label: l.TimeZone,
        format: (timeZone) => TimeZonePack[timeZone],
        classNames: { ...defaultClassNames },
    },
    mode: {
        label: l.Mode,
        format: (mode) => StateModesLabels[mode],
        classNames: { ...defaultClassNames },
    },
    status: {
        label: l.Status,
        format: (status) => CustomerJourneyStatusLabels[status],
        classNames: { ...defaultClassNames },
    },
    user: { label: l.User, classNames: { ...defaultClassNames } },
    createdDate: {
        label: l.CreatedDate,
        format: (createdDate) => customMomentWithoutTimezoneConversion(createdDate).format(DateTimeFormat),
        classNames: { ...defaultClassNames },
    },
    lastModified: {
        label: l.LastModified,
        format: (createdDate) => customMomentWithoutTimezoneConversion(createdDate).format(DateTimeFormat),
        classNames: { ...defaultClassNames },
    },
    lastModifier: { label: l.LastModifier, classNames: { ...defaultClassNames } },
    usedIn: {
        label: l.UsedIn,
        isLoading: true,
        // eslint-disable-next-line react/display-name
        renderer: (data) => (
            <div className="customer-journey-used-in-section">
                <UsedInSection
                    isLoadingUsedIn={false}
                    usedInData={data}
                    isVisible={true}
                    notUsedInDisplayNameKey={l.NotUsed}
                />
            </div>
        ),
    },
};

export { infoItemsConfig };
