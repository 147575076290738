import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
// Import Components
import { KeyValueItems, UsedInSection } from 'components';
import ColumnKPIsAndFiltersInfo from './ColumnKPIsAndFiltersInfo';
// Import UI components
import { Icon } from '@geneui/components';
// Import Constants
import { l, ReportTypesLabels } from 'constants/common';
//Import SCSS
import 'assets/scss/reportInfoModal.scss';

const CompositeReportInfoTab = ({ report, segmentFiltersData }) => {
    const keyValueItemsData = useMemo(() => {
        const data = [
            {
                label: l.Title,
                value: report.Name,
            },
            {
                label: l.Description,
                value: report.Description,
            },
            {
                label: l.Type,
                value: (
                    <div className="crm-segment-type-label">
                        <Icon type="bc-icon-crm-standart-report crm-segment-type-icon dynamic" />
                        <span>{l[ReportTypesLabels[report.reportType]]}</span>
                    </div>
                ),
            },
            {
                label: '',
                value: (
                    <UsedInSection isLoadingUsedIn={isNil(report.usedIn)} usedInData={report.usedIn} isVisible={true} />
                ),
            },
        ];

        return data;
    }, [report]);

    return (
        <>
            {!isNil(report) && (
                <div className="composite-report-info-tab">
                    <KeyValueItems items={keyValueItemsData} vertical={true} />
                    <div className="column-kpis-filters-wrapper">
                        <ColumnKPIsAndFiltersInfo
                            segmentFiltersData={segmentFiltersData}
                            reportType={report.reportType}
                            reportInfo={report}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

CompositeReportInfoTab.propTypes = {
    report: PropTypes.object.isRequired,
    segmentFiltersData: PropTypes.shape({
        [PropTypes.string]: {
            id: PropTypes.number,
            isMain: PropTypes.bool,
            seqId: PropTypes.number,
            name: PropTypes.string,
            filters: PropTypes.shape({
                [PropTypes.string]: PropTypes.array,
            }),
        },
    }),
};

export default memo(CompositeReportInfoTab);
