import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'lodash';
// Import UI Components
import { LineChart, BusyLoader, Empty } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

const CampaignEmailActivity = ({ isLoading, data }) => {
    const { t } = useTranslation();
    const [xData, setOpenX] = useState([]);
    const [openY, setOpenY] = useState([]);
    const [clickY, setClickY] = useState([]);
    const { OpenData, ClickData } = data;

    const init = () => {
        let dataX = [];
        let dataY = [];
        if (!isNil(OpenData)) {
            OpenData.forEach(function ({ X, Y }) {
                dataX.push(customMomentWithoutTimezoneConversion(X).format('MMM DD'));
                dataY.push(Y);
            });
            setOpenX(dataX);
            setOpenY(dataY);
        }
        if (!isNil(ClickData)) {
            dataX = [];
            dataY = [];
            ClickData.forEach(function ({ X, Y }) {
                dataX.push(X);
                dataY.push(Y);
            });
            setClickY(dataY);
        }
    };

    useEffect(init, [data]);
    return (
        <div className="report-visualization">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name">{t(l.EmailActivity)}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <BusyLoader isBusy={isLoading} />
            {isNil(OpenData) && isEmpty(OpenData) ? (
                <Empty />
            ) : (
                <LineChart
                    categories={xData}
                    series={[
                        {
                            name: t(l.OpenData),
                            data: openY,
                        },
                        {
                            name: t(l.ClickData),
                            data: clickY,
                        },
                    ]}
                />
            )}
        </div>
    );
};

CampaignEmailActivity.propTypes = {
    data: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

CampaignEmailActivity.defaultProps = {
    isLoading: false,
};

export default memo(CampaignEmailActivity);
