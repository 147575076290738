import { combineReducers } from 'redux';
// Import reducers
import auth from './auth.reducer';
import header from './header.reducer';
import maintenance from './maintenance.reducer';
import signIn from './signIn.reducer';
import resetPassword from './resetPassword.reducer';
import userData from './userData.reducer';
import queryConfigurations from './queryConfigurations.reducer';
import dynamicSegment from './dynamicSegment.reducer';
import template from './template.reducer';
import segmentList from './segmentList.reducer';
import reportHistoryList from './reportHistoryList.reducer';
import compositePageSegmentList from './compositePageSegmentList.reducer';
import templateList from './templateList.reducer';
import partnerLanguages from './partnerLanguages.reducer';
import campaignList from './campaignList.reducer';
import pageValidation from './pageValidations.reducer';
import reportList from './reportList.reducer';
import campaignPageSegmentList from './campaignPageSegmentList.reducer';
import campaignPageTemplateList from './campaignPageTemplateList.reducer';
import campaignPageBonusList from './campaignPageBonusList.reducer';
import customerJourneyList from './customerJourneyList.reducer';
import customerJourney from './customerJourney.reducer';
import infoDynamicSegment from './infoDynamicSegment.reducer';
import partnerSettings from './partnerSettings.reducer';
import menuItemsData from './menuItems.reducer';
import campaignLiveMonitorList from './campaignLiveMonitor.reducer';
import communicationList from './communicationList.reducer';
import routeLeaveConfirmation from './routeLeaveConfirmation.reducer';
import compositePageReportList from './compositePageReportList.reducer';
import adminPartnerSettingsList from './adminPartnerSettingsList.reducer';
import adminProviderList from './adminProviderList.reducer';
import adminClassifiersList from './adminClassifiersList.reducer';
import adminMenuItemsList from './adminMenuItemsList.reducer';
import currencyRates from './currencyRates.reducer';
import adminPartnersList from './adminPartnersList.reducer';
import campaignPageCommunicationList from './campaignPageCommunicationList.reducer';
import customerJourneyPageCommunicationList from './customerJourneyPageCommunicationList.reducer';
import dynamicSegmentTabActiveIndex from './dynamicSegmentTabActiveIndex.reducer';
import clientList from './clientList.reducer';
import reportColumns from './reportColumns.reducer';
import actionCampaignPageCommunicationList from './actionCampaignPageCommunicationList.reducer';

export default combineReducers({
    header,
    maintenance,
    auth,
    signIn,
    resetPassword,
    userData,
    queryConfigurations,
    segmentList,
    reportHistoryList,
    compositePageSegmentList,
    template,
    templateList,
    dynamicSegment,
    partnerLanguages,
    campaignList,
    pageValidation,
    reportList,
    campaignPageSegmentList,
    campaignPageTemplateList,
    campaignPageBonusList,
    customerJourneyList,
    customerJourney,
    infoDynamicSegment,
    partnerSettings,
    menuItemsData,
    campaignLiveMonitorList,
    communicationList,
    routeLeaveConfirmation,
    compositePageReportList,
    adminPartnerSettingsList,
    adminProviderList,
    currencyRates,
    adminPartnersList,
    campaignPageCommunicationList,
    customerJourneyPageCommunicationList,
    dynamicSegmentTabActiveIndex,
    clientList,
    reportColumns,
    adminClassifiersList,
    adminMenuItemsList,
    actionCampaignPageCommunicationList,
});
