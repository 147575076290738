import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// Import Constants
import { UnderMaintenanceImage } from 'constants/utils';
import { l } from 'constants/common';

const UnderMaintenance = ({ endDate }) => {
    const { t } = useTranslation();

    const nowTime = new Date().getTime();
    const endDateTime = new Date(endDate).getTime();
    const durationInHours = moment.duration(endDateTime - nowTime).asHours();
    const formattedHours = parseFloat(durationInHours.toFixed(1)).toString();

    return (
        <div className="crm-under-maintenance">
            <img alt="under maintenance" src={UnderMaintenanceImage.path} />
            <p>{t(l.CRMUnderMaintenanceTittle)}</p>
            <p>{t(l.CRMUnderMaintenanceDescription)}</p>
            <h3>
                {t(l.Duration)}: {formattedHours} {t(l.Hours)}
            </h3>
        </div>
    );
};

UnderMaintenance.propTypes = {
    endDate: PropTypes.string.isRequired,
};

export default memo(UnderMaintenance);
