import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Constants
import { dynamicDateData } from 'components/CustomerJourney/forms/CJDateTime/config';
import { l, TimeZonePack } from 'constants/common';

const CJDTDateTimeInfoBlock = ({ data, elements }) => {
    const { t } = useTranslation();
    const { infoComponent: Component, label } = dynamicDateData[data?.value?.template || 'today'];

    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.OptionValue)} value={t(label)} />
            <Component data={data} elements={elements} />
            <CJInfoBlockLabelValueWrapper label={t(l.TimeZone)} value={TimeZonePack[data?.value?.timeZone ?? 0]} />
        </>
    );
};

CJDTDateTimeInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
};

export default memo(CJDTDateTimeInfoBlock);
