import { ReportHistoryListActionTypes as Types } from './types';

const setReportHistoryListTableFilters = (filters) => ({
    type: Types.SET_REPORT_HISTORY_LIST_TABLE_FILTERS,
    payload: filters,
});

const setReportHistoryListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_REPORT_HISTORY_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setReportHistoryListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_REPORT_HISTORY_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setReportHistoryListTableSorting = (sorting) => ({
    type: Types.SET_REPORT_HISTORY_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetReportHistoryListState = () => ({
    type: Types.RESET_REPORT_HISTORY_LIST_STATE,
});

export default {
    setReportHistoryListTableFilters,
    setReportHistoryListTablePagingPageNumber,
    setReportHistoryListTablePagingPageSize,
    setReportHistoryListTableSorting,
    resetReportHistoryListState,
};
