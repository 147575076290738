// auth action types
export const AuthActionTypes = {
    DO_LOGIN: 'DO_LOGIN',
    DO_LOGOUT: 'DO_LOGOUT',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
};

// sign in action types
export const SignInActionTypes = {
    CRM_SIGN_IN_FETCH: 'CRM_SIGN_IN_FETCH',
    CRM_SIGN_IN_SUCCEEDED: 'CRM_SIGN_IN_SUCCEEDED',
    CRM_SIGN_IN_FAILED: 'CRM_SIGN_IN_FAILED',
    CLEAR_CRM_SIGN_IN: 'CLEAR_CRM_SIGN_IN',
};

// reset password action types
export const ResetPasswordActionTypes = {
    CRM_RESET_PASSWORD_FETCH: 'CRM_RESET_PASSWORD_FETCH',
    CRM_RESET_PASSWORD_SUCCEEDED: 'CRM_RESET_PASSWORD_SUCCEEDED',
    CRM_RESET_PASSWORD_FAILED: 'CRM_RESET_PASSWORD_FAILED',
    CLEAR_CRM_RESET_PASSWORD: 'CLEAR_CRM_RESET_PASSWORD',

    CRM_RESET_PASSWORD_CONFIRM_FETCH: 'CRM_RESET_PASSWORD_CONFIRM_FETCH',
    CRM_RESET_PASSWORD_CONFIRM_SUCCEEDED: 'CRM_RESET_PASSWORD_CONFIRM_SUCCEEDED',
    CRM_RESET_PASSWORD_CONFIRM_FAILED: 'CRM_RESET_PASSWORD_CONFIRM_FAILED',
    CLEAR_CRM_RESET_PASSWORD_CONFIRM: 'CLEAR_CRM_RESET_PASSWORD_CONFIRM',
};

// sign out action types
export const SignOutActionTypes = {
    SIGN_OUT_FETCH: 'SIGN_OUT_FETCH',
    SIGN_OUT_SUCCEEDED: 'SIGN_OUT_SUCCEEDED',
    SIGN_OUT_FAILED: 'SIGN_OUT_FAILED',
};

// user data action types
export const UserDataActionTypes = {
    SET_USER_DATA: 'SET_USER_DATA',
    CLEAR_USER_DATA: 'CLEAR_USER_DATA',
    SET_LANGUAGE: 'SET_LANGUAGE',
};

// partner settings data action types
export const PartnerSettingsDataActionTypes = {
    GET_PARTNER_SETTINGS_SUCCEEDED: 'GET_PARTNER_SETTINGS_SUCCEEDED',
    GET_PARTNER_SETTINGS_FAILED: 'GET_PARTNER_SETTINGS_FAILED',
    SET_PARTNER_CURRENCIES: 'SET_PARTNER_CURRENCIES',
};

// header action types
export const HeaderActionTypes = {
    GET_SETTINGS_FETCH: 'GET_SETTINGS_FETCH',
    GET_SETTINGS_SUCCEEDED: 'GET_SETTINGS_SUCCEEDED',
    GET_SETTINGS_FAILED: 'GET_SETTINGS_FAILED',
    SET_SETTINGS_FETCH: 'SET_SETTINGS_FETCH',
    SET_SETTINGS_SUCCEEDED: 'SET_SETTINGS_SUCCEEDED',
    SET_SETTINGS_FAILED: 'SET_SETTINGS_FAILED',
    SET_COLOR_THEME: 'SET_COLOR_THEME',
    SET_SELECTED_PARTNER: 'SET_SELECTED_PARTNER',
    SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
    SET_NAVIGATION: 'SET_NAVIGATION',
    PUSH_TO_PREVIOUS_ROUTES: 'PUSH_TO_PREVIOUS_ROUTES',
    POP_FROM_PREVIOUS_ROUTES: 'POP_FROM_PREVIOUS_ROUTES',
    CLEAR_PREVIOUS_ROUTES: 'CLEAR_PREVIOUS_ROUTES',
    SET_APP_URL: 'SET_APP_URL',
    SET_LANGUAGES_LIST: 'SET_LANGUAGES_LIST',
    SET_APPLICATION_MODES: 'SET_APPLICATION_MODES',
    SET_LANG_ID: 'SET_LANG_ID',
};

// maintenance action types
export const MaintenanceActionTypes = {
    SET_MAINTENANCE_SETTINGS: 'SET_MAINTENANCE_SETTINGS',
    CLEAR_MAINTENANCE_SETTINGS: 'CLEAR_MAINTENANCE_SETTINGS',
};

// segment list action types
export const SegmentListActionTypes = {
    RESET_SEGMENT_LIST_STATE: 'RESET_SEGMENT_LIST_STATE',
    SET_SEGMENT_LIST_TABLE_FILTERS: 'SET_SEGMENT_LIST_TABLE_FILTERS',
    SET_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_SEGMENT_LIST_TABLE_SORTING: 'SET_SEGMENT_LIST_TABLE_SORTING',
    GET_SEGMENTS_FETCH: 'GET_SEGMENTS_FETCH',
    GET_SEGMENTS_SUCCEEDED: 'GET_SEGMENTS_SUCCEEDED',
    GET_SEGMENTS_FAILED: 'GET_SEGMENTS_FAILED',
    SET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS: 'SET_QUERY_CONFIGURATIONS',
};

// report history list action types
export const ReportHistoryListActionTypes = {
    RESET_REPORT_HISTORY_LIST_STATE: 'RESET_REPORT_HISTORY_LIST_STATE',
    SET_REPORT_HISTORY_LIST_TABLE_FILTERS: 'SET_REPORT_HISTORY_LIST_TABLE_FILTERS',
    SET_REPORT_HISTORY_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_REPORT_HISTORY_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_REPORT_HISTORY_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_REPORT_HISTORY_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_REPORT_HISTORY_LIST_TABLE_SORTING: 'SET_REPORT_HISTORY_LIST_TABLE_SORTING',
};

// composite page segment list action types
export const CompositePageSegmentListActionTypes = {
    SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_FILTERS: 'SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_FILTERS',
    SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER:
        'SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_SORTING: 'SET_COMPOSITE_PAGE_SEGMENT_LIST_TABLE_SORTING',
    GET_COMPOSITE_PAGE_SEGMENTS_FETCH: 'GET_COMPOSITE_PAGE_SEGMENTS_FETCH',
    GET_COMPOSITE_PAGE_SEGMENTS_SUCCEEDED: 'GET_COMPOSITE_PAGE_SEGMENTS_SUCCEEDED',
    GET_COMPOSITE_PAGE_SEGMENTS_FAILED: 'GET_COMPOSITE_PAGE_SEGMENTS_FAILED',
    SET_COMPOSITE_PAGE_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS: 'SET_COMPOSITE_PAGE_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS',
    RESET_COMPOSITE_PAGE_SEGMENT_LIST_STATE: 'RESET_COMPOSITE_PAGE_SEGMENT_LIST_STATE',
};

// composite page report list action types
export const CompositePageReportListActionTypes = {
    SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_FILTERS: 'SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_FILTERS',
    SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_SORTING: 'SET_COMPOSITE_PAGE_REPORT_LIST_TABLE_SORTING',
    GET_COMPOSITE_PAGE_REPORTS_FETCH: 'GET_COMPOSITE_PAGE_REPORTS_FETCH',
    GET_COMPOSITE_PAGE_REPORTS_SUCCEEDED: 'GET_COMPOSITE_PAGE_REPORTS_SUCCEEDED',
    GET_COMPOSITE_PAGE_REPORTS_FAILED: 'GET_COMPOSITE_PAGE_REPORTS_FAILED',
    RESET_COMPOSITE_PAGE_REPORT_LIST_STATE: 'RESET_COMPOSITE_PAGE_REPORT_LIST_STATE',
};

// campaign page segment list action types
export const CampaignPageSegmentListActionTypes = {
    RESET_CAMPAIGN_PAGE_SEGMENT_LIST_STATE: 'RESET_CAMPAIGN_PAGE_SEGMENT_LIST_STATE',
    SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_FILTERS: 'SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_FILTERS',
    SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_SORTING: 'SET_CAMPAIGN_PAGE_SEGMENT_LIST_TABLE_SORTING',
    GET_CAMPAIGN_PAGE_SEGMENTS_FETCH: 'GET_CAMPAIGN_PAGE_SEGMENTS_FETCH',
    GET_CAMPAIGN_PAGE_SEGMENTS_SUCCEEDED: 'GET_CAMPAIGN_PAGE_SEGMENTS_SUCCEEDED',
    GET_CAMPAIGN_PAGE_SEGMENTS_FAILED: 'GET_CAMPAIGN_PAGE_SEGMENTS_FAILED',
    SET_CAMPAIGN_PAGE_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS: 'SET_CAMPAIGN_PAGE_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS',
};

export const DynamicSegmentActionsTypes = {
    ADD_FILTER_CONTAINER_ITEM: 'ADD_FILTER_CONTAINER_ITEM',
    ADD_FILTER_CONTAINER_LIST: 'ADD_FILTER_CONTAINER_LIST',
    EDIT_FILTER_CONTAINER: 'EDIT_FILTER_CONTAINER',
    DELETE_FILTER_CONTAINER_LIST: 'DELETE_FILTER_CONTAINER_LIST',
    DELETE_FILTER_SCHEMA_LIST: 'DELETE_FILTER_SCHEMA_LIST',
    DELETE_FILTER_CONTAINER_ITEM: 'DELETE_FILTER_CONTAINER_ITEM',
    CLEAR_FILTER_CONTAINER: 'CLEAR_FILTER_CONTAINER',
    SET_FILTER_LIST: 'SET_FILTER_LIST',
    CLEAR_INFO_FILTER_CONTAINER: 'CLEAR_INFO_FILTER_CONTAINER',
    SET_INFO_FILTER_LIST: 'SET_INFO_FILTER_LIST',
    DELETE_SUB_SEGMENT: 'DELETE_SUB_SEGMENT',
    SET_MAIN_SEGMENT_INITIAL_DATA: 'SET_MAIN_SEGMENT_INITIAL_DATA',
    ADD_SUB_SEGMENT: 'ADD_SUB_SEGMENT',
    CHANGE_SUB_SEGMENT_NAME: 'CHANGE_SUB_SEGMENT_NAME',
    CHANGE_TAB_ACTIVE_INDEX: 'CHANGE_TAB_ACTIVE_INDEX',
};

export const TemplateActionsTypes = {
    ADD_TEMPLATE_LIST: 'ADD_TEMPLATE_LIST',
    SET_TEMPLATE_AS_DEFAULT: 'SET_TEMPLATE_AS_DEFAULT',
    CLEAR_TEMPLATE_LIST: 'CLEAR_TEMPLATE_LIST',
    SET_TEMPLATE_LIST: 'SET_TEMPLATE_LIST',
    CHANGE_TEMPLATE_VALUE: 'CHANGE_TEMPLATE_VALUE',
    SET_TEMPLATE_INITIAL_DATA: 'SET_TEMPLATE_INITIAL_DATA',
    DELETE_TEMPLATE_LANGUAGE: 'DELETE_TEMPLATE_LANGUAGE',
    SET_EDITOR_CONFIG: 'SET_EDITOR_CONFIG',
};

export const QueryConfigurationsTypes = {
    SET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS: 'SET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS',
    RESET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS: 'RESET_DYNAMIC_SEGMENT_QUERY_CONFIGURATIONS',
};

export const ValidationActionTypes = {
    SET_VALIDATION_DATA: 'SET_VALIDATION_DATA',
};

export const PageValidationActionTypes = {
    SET_PAGE_VALIDATION_DATA: 'SET_PAGE_VALIDATION_DATA',
    CLEAR_PAGE_VALIDATION_DATA: 'CLEAR_PAGE_VALIDATION_DATA',
    CHANGE_PAGE_VALIDATION_DATA: 'CHANGE_PAGE_VALIDATION_DATA',
    ADD_PAGE_VALIDATION_SCENARIO_FIELDS: 'ADD_PAGE_VALIDATION_SCENARIO_FIELDS',
    REMOVE_PAGE_VALIDATION_SCENARIO_FIELDS: 'REMOVE_PAGE_VALIDATION_SCENARIO_FIELDS',
};

export const PartnerLanguagesActionTypes = {
    SET_PARTNER_LANGUAGES: 'SET_PARTNER_LANGUAGES',
    RESET_PARTNER_LANGUAGES: 'RESET_PARTNER_LANGUAGES',
};

// template list action types
export const TemplateListActionTypes = {
    RESET_TEMPLATE_LIST_STATE: 'RESET_TEMPLATE_LIST_STATE',
    SET_TEMPLATE_LIST_TABLE_FILTERS: 'SET_TEMPLATE_LIST_TABLE_FILTERS',
    SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_TEMPLATE_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_TEMPLATE_LIST_TABLE_SORTING: 'SET_TEMPLATE_LIST_TABLE_SORTING',
    GET_TEMPLATE_FETCH: 'GET_TEMPLATE_FETCH',
    GET_TEMPLATE_SUCCEEDED: 'GET_TEMPLATE_SUCCEEDED',
    GET_TEMPLATE_FAILED: 'GET_TEMPLATE_FAILED',
};

// campaign page template list action types
export const CampaignPageTemplateListActionTypes = {
    SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_FILTERS: 'SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_FILTERS',
    SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_PAGING_PAGE_NUMBER:
        'SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_SORTING: 'SET_CAMPAIGN_PAGE_TEMPLATE_LIST_TABLE_SORTING',
    GET_CAMPAIGN_PAGE_TEMPLATE_FETCH: 'GET_CAMPAIGN_PAGE_TEMPLATE_FETCH',
    GET_CAMPAIGN_PAGE_TEMPLATE_SUCCEEDED: 'GET_CAMPAIGN_PAGE_TEMPLATE_SUCCEEDED',
    GET_CAMPAIGN_PAGE_TEMPLATE_FAILED: 'GET_CAMPAIGN_PAGE_TEMPLATE_FAILED',
    RESET_CAMPAIGN_PAGE_TEMPLATE_LIST_STATE: 'RESET_CAMPAIGN_PAGE_TEMPLATE_LIST_STATE',
};

// campaign page template list action types
export const CampaignPageBonusListActionTypes = {
    SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_FILTERS: 'SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_FILTERS',
    SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_SORTING: 'SET_CAMPAIGN_PAGE_BONUS_LIST_TABLE_SORTING',
    GET_CAMPAIGN_PAGE_BONUS_FETCH: 'GET_CAMPAIGN_PAGE_BONUS_FETCH',
    GET_CAMPAIGN_PAGE_BONUS_SUCCEEDED: 'GET_CAMPAIGN_PAGE_BONUS_SUCCEEDED',
    GET_CAMPAIGN_PAGE_BONUS_FAILED: 'GET_CAMPAIGN_PAGE_BONUS_FAILED',
};

// report list action types
export const ReportListActionTypes = {
    RESET_REPORT_LIST_STATE: 'RESET_REPORT_LIST_STATE',
    SET_REPORT_LIST_TABLE_FILTERS: 'SET_REPORT_LIST_TABLE_FILTERS',
    SET_REPORT_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_REPORT_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_REPORT_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_REPORT_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_REPORT_LIST_TABLE_SORTING: 'SET_REPORT_LIST_TABLE_SORTING',
    GET_REPORT_FETCH: 'GET_REPORT_FETCH',
    GET_REPORT_SUCCEEDED: 'GET_REPORT_SUCCEEDED',
    GET_REPORT_FAILED: 'GET_REPORT_FAILED',
};

// campaign list action types
export const CampaignListActionTypes = {
    RESET_CAMPAIGN_LIST_STATE: 'RESET_CAMPAIGN_LIST_STATE',
    SET_CAMPAIGN_LIST_TABLE_FILTERS: 'SET_CAMPAIGN_LIST_TABLE_FILTERS',
    SET_CAMPAIGN_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_CAMPAIGN_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CAMPAIGN_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_CAMPAIGN_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CAMPAIGN_LIST_TABLE_SORTING: 'SET_CAMPAIGN_LIST_TABLE_SORTING',
    GET_CAMPAIGNS_FETCH: 'GET_CAMPAIGNS_FETCH',
    GET_CAMPAIGNS_SUCCEEDED: 'GET_CAMPAIGNS_SUCCEEDED',
    GET_CAMPAIGNS_FAILED: 'GET_CAMPAIGNS_FAILED',
};

// campaign live monitor list action types
export const CampaignLiveMonitorListActionTypes = {
    SET_CAMPAIGN_LIVE_MONITOR_LIST_TABLE_FILTERS: 'SET_CAMPAIGN_LIVE_MONITOR_LIST_TABLE_FILTERS',
};

// customerJourney list action types
export const CustomerJourneyListActionTypes = {
    RESET_CUSTOMER_JOURNEY_LIST_STATE: 'RESET_CUSTOMER_JOURNEY_LIST_STATE',
    SET_CUSTOMER_JOURNEY_LIST_TABLE_FILTERS: 'SET_CUSTOMER_JOURNEY_LIST_TABLE_FILTERS',
    SET_CUSTOMER_JOURNEY_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_CUSTOMER_JOURNEY_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CUSTOMER_JOURNEY_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_CUSTOMER_JOURNEY_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CUSTOMER_JOURNEY_LIST_TABLE_SORTING: 'SET_CUSTOMER_JOURNEY_LIST_TABLE_SORTING',
    GET_CUSTOMER_JOURNEYS_FETCH: 'GET_CUSTOMER_JOURNEYS_FETCH',
    GET_CUSTOMER_JOURNEYS_SUCCEEDED: 'GET_CUSTOMER_JOURNEYS_SUCCEEDED',
    GET_CUSTOMER_JOURNEYS_FAILED: 'GET_CUSTOMER_JOURNEYS_FAILED',
};

// customer journey flow editor action types
export const CustomerJourneyActionTypes = {
    INCREMENT_BLOCK_COUNT: 'INCREMENT_BLOCK_COUNT',
    DECREMENT_BLOCK_COUNT: 'DECREMENT_BLOCK_COUNT',
    RESET_BLOCKS_COUNT: 'RESET_BLOCKS_COUNT',
    SET_BLOCKS_COUNTS: 'SET_BLOCKS_COUNTS',
    SET_MAX_BLOCKS_COUNTS: 'SET_MAX_BLOCKS_COUNTS',
};

export const MenuItemsActionTypes = {
    SET_MENU_ITEM: 'SET_MENU_ITEM',
    CLEAR_MENU_ITEMS: 'CLEAR_MENU_ITEMS',
};

// communication list action types
export const CommunicationListActionTypes = {
    RESET_COMMUNICATION_LIST_STATE: 'RESET_COMMUNICATION_LIST_STATE',
    SET_COMMUNICATION_LIST_TABLE_FILTERS: 'SET_COMMUNICATION_LIST_TABLE_FILTERS',
    SET_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_COMMUNICATION_LIST_TABLE_SORTING: 'SET_COMMUNICATION_LIST_TABLE_SORTING',
};

// campaign page segment list action types
export const CampaignPageCommunicationListActionTypes = {
    RESET_CAMPAIGN_PAGE_COMMUNICATION_LIST_STATE: 'RESET_CAMPAIGN_PAGE_COMMUNICATION_LIST_STATE',
    SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_FILTERS: 'SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_FILTERS',
    SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER:
        'SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE:
        'SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_SORTING: 'SET_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_SORTING',
};

export const CustomerJourneyPageCommunicationListActionTypes = {
    RESET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_STATE: 'RESET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_STATE',
    SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_FILTERS:
        'SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_FILTERS',
    SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER:
        'SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE:
        'SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_SORTING:
        'SET_CUSTOMER_JOURNEY_PAGE_COMMUNICATION_LIST_TABLE_SORTING',
};

export const RouteLeaveConfirmationActionTypes = {
    SET_PAGE_NAME: 'SET_PAGE_NAME',
    SET_HAS_DATA_CHANGE: 'SET_HAS_DATA_CHANGE',
    SET_IS_CHANGE_SAVED: 'SET_IS_CHANGE_SAVED',
    RESET_HAS_DATA_CHANGE: 'RESET_HAS_DATA_CHANGE',
};

export const CurrencyRatesActionTypes = {
    SET_CURRENCY_RATES: 'SET_CURRENCY_RATES',
};

export const AdminProviderActionTypes = {
    RESET_ADMIN_PROVIDER_STATE: 'RESET_ADMIN_PROVIDER_STATE',
    SET_ADMIN_PROVIDER_TABLE_FILTERS: 'SET_ADMIN_PROVIDER_TABLE_FILTERS',
    SET_ADMIN_PROVIDER_TABLE_PAGE_NUMBER: 'SET_ADMIN_PROVIDER_TABLE_PAGE_NUMBER',
    SET_ADMIN_PROVIDER_TABLE_PAGE_SIZE: 'SET_ADMIN_PROVIDER_TABLE_PAGE_SIZE',
    SET_ADMIN_PROVIDER_TABLE_SORTING: 'SET_ADMIN_PROVIDER_TABLE_SORTING',
};

// admin partners list action types
export const AdminPartnerSettingsListActionTypes = {
    SET_SETTINGS_LIST_TABLE_FILTERS: 'SET_SETTINGS_LIST_TABLE_FILTERS',
    SET_SETTINGS_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_SETTINGS_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_SETTINGS_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_SETTINGS_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_SETTINGS_LIST_TABLE_SORTING: 'SET_SETTINGS_LIST_TABLE_SORTING',
    RESET_SETTINGS_LIST_STATE: 'RESET_SETTINGS_LIST_STATE',
};
export const AdminPartnersListActionTypes = {
    RESET_ADMIN_PARTNERS_LIST_STATE: 'RESET_ADMIN_PARTNERS_LIST_STATE',
    SET_ADMIN_PARTNERS_LIST_TABLE_FILTERS: 'SET_ADMIN_PARTNERS_LIST_TABLE_FILTERS',
    SET_ADMIN_PARTNERS_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_ADMIN_PARTNERS_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_ADMIN_PARTNERS_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_ADMIN_PARTNERS_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_ADMIN_PARTNERS_LIST_TABLE_SORTING: 'SET_ADMIN_PARTNERS_LIST_TABLE_SORTING',
};
export const AdminClassifiersListActionTypes = {
    SET_CLASSIFIERS_LIST_TABLE_FILTERS: 'SET_CLASSIFIERS_LIST_TABLE_FILTERS',
    SET_CLASSIFIERS_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_CLASSIFIERS_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CLASSIFIERS_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_CLASSIFIERS_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CLASSIFIERS_LIST_TABLE_SORTING: 'SET_CLASSIFIERS_LIST_TABLE_SORTING',
    RESET_CLASSIFIERS_LIST_STATE: 'RESET_CLASSIFIERS_LIST_STATE',
};
export const AdminMenuItemsListActionTypes = {
    SET_MENUITEMS_LIST_TABLE_FILTERS: 'SET_MENUITEMS_LIST_TABLE_FILTERS',
    SET_MENUITEMS_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_MENUITEMS_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_MENUITEMS_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_MENUITEMS_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_MENUITEMS_LIST_TABLE_SORTING: 'SET_MENUITEMS_LIST_TABLE_SORTING',
    RESET_MENUITEMS_LIST_STATE: 'RESET_MENUITEMS_LIST_STATE',
};

// client list action types
export const ClientListActionTypes = {
    RESET_CLIENT_LIST_STATE: 'RESET_CLIENT_LIST_STATE',
    SET_CLIENT_LIST_TABLE_FILTERS: 'SET_CLIENT_LIST_TABLE_FILTERS',
    SET_CLIENT_LIST_TABLE_PAGING_PAGE_NUMBER: 'SET_CLIENT_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_CLIENT_LIST_TABLE_PAGING_PAGE_SIZE: 'SET_CLIENT_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_CLIENT_LIST_TABLE_SORTING: 'SET_CLIENT_LIST_TABLE_SORTING',
};

export const ReportColumnsActionTypes = {
    SET_REPORT_COLUMNS: 'SET_REPORT_COLUMNS',
    CLEAR_REPORT_COLUMNS: 'CLEAR_REPORT_COLUMNS',
};
// action campaign communication list types
export const ActionCampaignPageCommunicationListActionTypes = {
    RESET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_STATE: 'RESET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_STATE',
    SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_FILTERS:
        'SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_FILTERS',
    SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER:
        'SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_NUMBER',
    SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE:
        'SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_PAGING_PAGE_SIZE',
    SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_SORTING:
        'SET_ACTION_CAMPAIGN_PAGE_COMMUNICATION_LIST_TABLE_SORTING',
};
