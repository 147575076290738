import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { first, isEmpty, has } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import Components
import { DataTable, DownloadToolbar } from 'components';
// Import Services
import { ExecutedReportHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Hooks
import { useRequest } from 'hooks';

let executedReportResultData = [];
let resultDataCount = 0;

const { saveToDisk } = Helpers;

const ResultInfoTab = ({ adHocReportResultId, reportType, reportName, adHocReportVersion }) => {
    const { ActivePartner } = useSelector((state) => state.header.settings);
    const partnerCurrencyId = first(
        useSelector((state) => state.header.partners.filter((partner) => partner.value === ActivePartner.PartnerId)),
    )?.CurrencyId;
    const { settings } = useSelector((state) => state.header);
    const { Settings } = settings;

    const { t } = useTranslation();
    const { doPostRequest } = useRequest();

    const [isLoading, setIsLoading] = useState(false);
    const [resultClients, setResultClients] = useState([]);
    const [resultClientsColumns, setResultClientsColumns] = useState([]);
    const [dataCount, setDataCount] = useState(resultDataCount);
    const [isFileLoading, setIsFileLoading] = useState(false);

    const { downloadExecutedReport, getExecutedReportResultRequest } = useMemo(
        () => ({
            downloadExecutedReport: ExecutedReportHttpService.downloadExecutedReport(),
            getExecutedReportResultRequest: ExecutedReportHttpService.getExecutedReportResult(),
        }),
        [],
    );

    const cleanUp = () => {
        return () => {
            downloadExecutedReport.cancel('ResultInfoTab:downloadExecutedReport');
            getExecutedReportResultRequest.cancel('ResultInfoTab:getExecutedReportResultRequest');
        };
    };

    useEffect(cleanUp, []);

    const mapResultClientsColumns = (columns, t) =>
        columns.map((columns, index) => ({
            index,
            text: t(columns.DisplayName),
            dataKey: index,
            sortable: false,
        }));

    const mapResultClients = (data) =>
        data.Data.map((elem, index) => ({
            index,
            actionBar: false,
            dragDisable: true,
            hasHover: true,
            data: data.Columns.reduce(
                (accumulator, _element, i) => {
                    accumulator[i] = elem[i];
                    return accumulator;
                },
                { index },
            ),
        }));

    const executedReportResult = () => {
        const requestBody = {
            ReportResultId: adHocReportResultId,
            Searchmodel: {
                Filters: [{ Name: 'Name', Comparision: 2, Values: [''] }],
                Pageing: { PageSize: 200, PageNumber: 1 },
                Sorting: null,
            },
            CurrencyCode: ActivePartner.CurrencyId ?? partnerCurrencyId,
            Type: reportType,
        };

        setIsLoading(true);
        doPostRequest(getExecutedReportResultRequest.request, {
            requestBody,
            successCallback: (Data) => {
                resultDataCount = Data?.Count;
                setDataCount(resultDataCount);
                executedReportResultData = first(Data.Data);
            },
        }).then(() => setIsLoading(false));
    };

    const downloadClickHandler = (type, downloadDropdownValue) => {
        setIsFileLoading(true);
        const fileName = `${reportName}_Version_${adHocReportVersion}`;
        const requestBody = {
            CurrencyCode: Settings.CurrencyId,
            DocumentType: type,
            ReportType: reportType,
            ReportResultId: adHocReportResultId,
            TopCount: downloadDropdownValue,
            UserTimeZone: ActivePartner.TimeZone,
            fileName,
        };
        doPostRequest(downloadExecutedReport.request, {
            requestBody,
        }).then((res) => {
            if (!has(res, 'HasError')) {
                saveToDisk(res, type, fileName);
            }
            setIsFileLoading(false);
        });
    };

    const getHeaderActions = () => {
        return (
            <DownloadToolbar
                downloadClickHandler={downloadClickHandler}
                withCountDropdown={false}
                withTypeSelector={false}
                isFileLoading={isFileLoading}
                isDisableDownload={isEmpty(executedReportResultData)}
            />
        );
    };

    useEffect(() => {
        executedReportResult();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (!isEmpty(executedReportResultData)) {
            setResultClientsColumns(mapResultClientsColumns(executedReportResultData.Columns, t));
            setResultClients(mapResultClients(executedReportResultData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executedReportResultData]);

    return (
        <DataTable
            columnKey="dataKey"
            rowKey="index"
            data={resultClients}
            dataCount={dataCount}
            columns={resultClientsColumns}
            isLoading={isLoading}
            withPagination={false}
            withPageSelector={false}
            headerActions={getHeaderActions()}
        />
    );
};

ResultInfoTab.propTypes = {
    adHocReportResultId: PropTypes.number.isRequired,
    reportType: PropTypes.number.isRequired,
    reportName: PropTypes.string.isRequired,
    adHocReportVersion: PropTypes.number.isRequired,
};

export default memo(ResultInfoTab);
