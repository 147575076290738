import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Alert, Tooltip } from '@geneui/components';
// Import Constants
import l from 'constants/localizationLabels';

const DynamicVariables = ({ dynamicVariables, clickHandler, insertionOptions }) => {
    const { t } = useTranslation();

    const filteredDynamicVariables = dynamicVariables.filter((el) => {
        if (insertionOptions.inputFilterKey) {
            return el.channels[insertionOptions.channel]?.[insertionOptions.inputFilterKey];
        }

        return el.channels[insertionOptions.channel];
    });

    return (
        <div className="crm-create-template-page-variables-row">
            <div className="crm-create-template-page-variables-row-head">
                <span>{t(l.DynamicVariables)}</span>
                <Tooltip
                    size="default"
                    position="auto"
                    title={t(l.TemplateDynamicVariablesTooltipTitle)}
                    text={t(l.TemplateDynamicVariablesTooltipText)}
                >
                    <i className="bc-icon-info" />
                </Tooltip>
            </div>
            <div className="crm-create-template-page-variables-container">
                {insertionOptions.hintText ? (
                    <Alert
                        closable={false}
                        title={t(insertionOptions.hintText)}
                        type="info"
                        className="partner-group-alert"
                    />
                ) : (
                    <div className="crm-create-template-page-variables-row-content">
                        {filteredDynamicVariables.map((item) => {
                            const dynamicVariableForType = item.channels[insertionOptions.channel];
                            return (
                                <button
                                    key={dynamicVariableForType.FieldName}
                                    onClick={() => clickHandler(dynamicVariableForType.FieldName)}
                                    className="crm-custom-btn"
                                >
                                    {t(item.displayNameKey)}
                                </button>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

DynamicVariables.propTypes = {
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
    clickHandler: PropTypes.func,
    activeChannel: PropTypes.string.isRequired,
    insertionOptions: PropTypes.shape({
        inputFilterKey: PropTypes.string,
        hintText: PropTypes.string,
        channel: PropTypes.string,
    }),
};

export default DynamicVariables;
