import React, { useEffect, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { UtilsHttpService, TemplateHttpService } from 'services/http';
// Import UI components
import { Modal, Button, Switcher, Dropdown, SkeletonLoader } from '@geneui/components';
import { ButtonWithLoader } from 'components';
// Import Constants
import { l, TemplateTypesLabels, FilterComparisions, NotificationFields } from 'constants/common';

const EditNotificationModal = ({ data, isVisibleModal, onCancel, onOk, isLoading }) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();

    const [values, setValues] = useState(cloneDeep(data));
    const [providers, setProviders] = useState({});
    const [templates, setTemplates] = useState({});
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [isLoadingProviders, setIsLoadingProviders] = useState(false);

    const [isOkDisabled, setIsOkDisabled] = useState();

    const isOkStateHandler = () => {
        const enabledNotifications = values.Notifications?.filter((item) => item?.IsEnabled === true);

        if (enabledNotifications.length > 0) {
            const isEmptyTemplatesData = enabledNotifications.some((item) => item?.MessageId === 0);

            const notificationsWithProvider = enabledNotifications.filter(({ Channel }) =>
                getIsFieldHasProviders(Channel),
            );
            const isEmptyProvidersData = notificationsWithProvider.some((item) => item?.ProviderId === 0);

            setIsOkDisabled(isEmptyTemplatesData || isEmptyProvidersData);
        } else {
            setIsOkDisabled(true);
        }
    };

    const { getMessageProvidersRequest, getTemplateListRequest } = useRef({
        getMessageProvidersRequest: UtilsHttpService.getPartnerMessageProviders(),
        getTemplateListRequest: TemplateHttpService.getTemplateList(),
    }).current;

    const getProviders = (channel) => {
        setIsLoadingProviders(true);
        doPostRequest(getMessageProvidersRequest.request, {
            requestBody: { DeliveryMethod: channel },
            successCallback: (Data) => {
                setProviders((prev) => {
                    prev[channel] = Data.map(({ Id, Name }) => {
                        return {
                            value: +Id,
                            label: Name,
                        };
                    });
                    return { ...prev };
                });
            },
        }).then(() => {
            setIsLoadingProviders(false);
        });
    };

    const getTemplates = () => {
        if (isNil(data)) return;

        setIsLoadingTemplates(true);
        const filter = { Comparision: FilterComparisions.Equals, Name: 'EventId', Values: [data.EventId] };
        doPostRequest(getTemplateListRequest.request, {
            requestBody: { Filters: [filter], Sorting: { Name: 'CreatedDate', Direction: 'desc' } },
            successCallback: (Data) => {
                setTemplates((prev) => {
                    data.Notifications.forEach((item) => {
                        const templates = Data?.Data?.filter((template) => template.DeliveryMethod === item.Channel);
                        prev[item.Channel] = templates?.map(({ TemplateId, Name }) => {
                            return {
                                value: +TemplateId,
                                label: Name,
                            };
                        });
                    });
                    return { ...prev };
                });
            },
        }).then(() => {
            setIsLoadingTemplates(false);
        });
    };

    const setNotificationData = (fieldName, fieldValue, index) => {
        setValues((prev) => {
            const result = cloneDeep(prev);
            result.Notifications[index][fieldName] = fieldValue;
            return result;
        });
    };

    const onSwitcherChangeHandler = (index) => {
        return (e) => {
            const isEnabled = e.target.checked;
            setNotificationData(NotificationFields.IsEnabled, isEnabled, index);
        };
    };

    const dropdownChangeHandler = (property, index) => {
        return (e) => {
            setNotificationData(property, e.value, index);
        };
    };

    const onSave = () => {
        onOk(values);
    };

    const cleanUp = () => {
        return () => {
            getMessageProvidersRequest.cancel('NotificationsPage:getMessageProvidersRequest');
            getTemplateListRequest.cancel('NotificationsPage:getTemplateListRequest');
        };
    };

    const getIsFieldHasProviders = (channel) => {
        return channel === TemplateTypesLabels.SMS || channel === TemplateTypesLabels.Email;
    };

    const init = () => {
        isOkStateHandler();
        data.Notifications.forEach(({ Channel }) => {
            if (getIsFieldHasProviders(Channel)) {
                getProviders(Channel);
            }
        });
        getTemplates();
    };

    useEffect(isOkStateHandler, [values]);

    useEffect(init, []);

    useEffect(cleanUp, []);

    return (
        <Modal
            background="dark-background"
            title={t(l.EditNotifications)}
            size="content-size"
            className="crm-modal configuration-notification-modal"
            closable={false}
            closeOnClickOutside={true}
            onCancel={onCancel}
            visible={isVisibleModal}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader color="primary" onClick={onSave} isLoading={isLoading} isDisabled={isOkDisabled}>
                        {t(l.Save)}
                    </ButtonWithLoader>
                </>
            }
        >
            <div className="config-modal-inner">
                {values?.Notifications.map(({ IsEnabled, Channel, MessageId, ProviderId }, index) => {
                    return (
                        <div className="config-modal-row" key={index}>
                            <Switcher
                                label={TemplateTypesLabels[Channel]}
                                onChange={onSwitcherChangeHandler(index)}
                                checked={!!IsEnabled}
                            />

                            <div className="config-modal-dropdown-group">
                                {isLoadingTemplates ? (
                                    <SkeletonLoader isBusy={true} />
                                ) : (
                                    <Dropdown
                                        className="config-modal-dropdown"
                                        defaultValue={MessageId}
                                        isMultiSelect={false}
                                        disabled={!IsEnabled}
                                        hasSearch={true}
                                        inputSize="default"
                                        placeholder={t(l.Template)}
                                        appearance="outline"
                                        data={isNil(templates[Channel]) ? [] : templates[Channel]}
                                        searchPlaceholderText={t(l.Search)}
                                        labelAppearance="swap"
                                        noDataText={t(l.NoDataFound)}
                                        onChange={dropdownChangeHandler(NotificationFields.MessageId, index)}
                                    />
                                )}
                                {getIsFieldHasProviders(Channel) && (
                                    <>
                                        {isLoadingProviders ? (
                                            <SkeletonLoader isBusy={true} />
                                        ) : (
                                            <Dropdown
                                                className="config-modal-dropdown"
                                                defaultValue={ProviderId}
                                                isMultiSelect={false}
                                                disabled={!IsEnabled}
                                                hasSearch={true}
                                                inputSize="default"
                                                placeholder={t(l.Provider)}
                                                appearance="outline"
                                                data={isNil(providers[Channel]) ? [] : providers[Channel]}
                                                searchPlaceholderText={t(l.Search)}
                                                labelAppearance="swap"
                                                noDataText={t(l.NoDataFound)}
                                                onChange={dropdownChangeHandler(NotificationFields.ProviderId, index)}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
};

EditNotificationModal.propTypes = {
    data: PropTypes.object.isRequired,
    isVisibleModal: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default memo(EditNotificationModal);
