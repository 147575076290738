import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import UI Components
import { ConfirmationModal } from 'components';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { ModalsActions, l, ActionsIcons, ModalsClassNames } from 'constants/common';
// Import Services
import { UtilsHttpService } from 'services/http';
import { createDeleteFilterTagDTO } from './service';

const ConfigureFilterTagActionsModals = ({
    filtersTagKey,
    isDeleteModalOpenedState,
    onCloseModalHandler,
    modalsStateSetDict,
    openedModalData,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();

    const { deleteFilterTagRequest } = useRef({
        deleteFilterTagRequest: UtilsHttpService.deleteFiltersTagGroup(),
    }).current;

    const deleteFilterTag = () => {
        setIsLoading(true);

        doPostRequest(deleteFilterTagRequest.request, {
            requestBody: createDeleteFilterTagDTO(filtersTagKey, openedModalData),
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDict.deleteFilterTag.key, ModalsActions.DELETE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const cleanUp = () => {
        return () => {
            deleteFilterTagRequest.cancel('deleteFilterTagRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        !isEmpty(openedModalData) && (
            <>
                {isDeleteModalOpenedState && (
                    <ConfirmationModal
                        onOk={deleteFilterTag}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.deleteFilterTag.key)}
                        isVisibleModal={isDeleteModalOpenedState}
                        titleText={t(l.DeleteFilterGroup)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Delete}
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                        questionLabel={t(l.AreYouSureYouWantToDeleteFilterGroup, {
                            filterTagName: `"${openedModalData.Label}"`,
                        })}
                    />
                )}
            </>
        )
    );
};

ConfigureFilterTagActionsModals.propTypes = {
    filtersTagKey: PropTypes.string.isRequired,
    isDeleteModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.any.isRequired,
};

export default memo(ConfigureFilterTagActionsModals);
