import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Icon, Tooltip } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const Block = ({ titleKey, type, defaultIcon, cssClassName, metaData }) => {
    const { t } = useTranslation();

    const blockTitleLabel = l[`CJBlock${titleKey}`];
    const blockDescriptionLabel = l[`CJBlock${titleKey}Description`];

    const onDragStart = (event) => {
        const blockData = JSON.stringify({
            type,
            titleKey,
            blockTitleLabel,
            defaultIcon,
            cssClassName,
            metaData,
        });
        event.dataTransfer.setData('application/reactflow', blockData);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <div className="crm-flow-sidebar-block" onDragStart={(e) => onDragStart(e)} draggable>
            <div>
                <Icon type={defaultIcon} />
            </div>
            <Tooltip size="default" position="auto" text={t(blockTitleLabel)}>
                <span>{t(blockTitleLabel)}</span>
            </Tooltip>
            <Tooltip title={t(blockTitleLabel)} position="right" text={t(blockDescriptionLabel)}>
                <Icon type="bc-icon-info" />
            </Tooltip>
        </div>
    );
};

Block.propTypes = {
    titleKey: PropTypes.string.isRequired,
    type: PropTypes.string,
    defaultIcon: PropTypes.string,
    cssClassName: PropTypes.string,
    metaData: PropTypes.object.isRequired,
};

Block.defaultProps = {
    type: 'default',
    defaultIcon: '',
    cssClassName: '',
};

export default memo(Block);
