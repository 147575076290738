import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Components
import { l, Months } from 'constants/common';

const CJDTDayOfYearInfoBlock = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.Month)} value={t(l[Months[data?.value?.params?.months]])} />
            <CJInfoBlockLabelValueWrapper label={t(l.Day)} value={data?.value?.params?.dates} />
        </>
    );
};

CJDTDayOfYearInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJDTDayOfYearInfoBlock);
