import { CompositeSegmentOperation, l } from 'constants/common';

const SegmentItemsConfig = {
    maxCountOfSegments: 4,
    firstEnableDropItems: 1,
    enableDropHere: { enableDropHere: true },
};

const SegmentContainerConfig = {
    exportList: [
        { label: 'CSV', value: 1 },
        { label: 'XLS', value: 2 },
    ],
    defaultColumnsReport: [
        { Id: 76 },
        { Id: 75 },
        { Id: 74 },
        { Id: 73 },
        { Id: 53 },
        { Id: 42 },
        { Id: 40 },
        { Id: 38 },
    ],
    minSegmentsCount: 2,
    radioGroupOptions: [
        { label: l.Union, value: CompositeSegmentOperation.Union, icon: 'bc-icon-union' },
        {
            label: l.Intersect,
            value: CompositeSegmentOperation.Intersect,
            icon: 'bc-icon-intersect',
        },
        {
            label: l.Difference,
            value: CompositeSegmentOperation.Difference,
            icon: 'bc-icon-difference',
        },
    ],
    tootltips: {
        [CompositeSegmentOperation.Union]: 'TooltipUnion',
        [CompositeSegmentOperation.Intersect]: 'TooltipIntersect',
        [CompositeSegmentOperation.Difference]: 'TooltipDifference',
    },
};

export { SegmentItemsConfig, SegmentContainerConfig };
