import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { first, isEmpty, isNil } from 'lodash';
// Import UI Components
import { BusyLoader, Icon, SkeletonLoader, Tooltip } from '@geneui/components';
// Import Components
import GeneralInfo from 'components/BonusModal/GeneralInfo';
// Import Services
import { Helpers } from 'services';
import { TemplateHttpService, UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import {
    BonusTriggerType,
    BonusTypesLabels,
    KPISources,
    l,
    ObjectTypeLabelMap,
    ObjectTypesMap,
} from 'constants/common';

const { getBonusSupportedCurrencies } = Helpers;

const CampaignBonusInfoModalBody = ({ campaignBonus, bonusInfo, isLoading }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();

    const [bonusCalculateAction, setBonusCalculateAction] = useState();
    const [bonusKPI, setBonusKPI] = useState();
    const [internalMessageTitle, setInternalMessageTitle] = useState(null);
    const [isLoadingMarketplaces, setIsLoadingMarketplaces] = useState(false);
    const [marketplace, setMarketplace] = useState(true);

    const secondaryCurrencyCode = useSelector((state) => state.partnerSettings.SecondaryCurrencyId);

    const isVisibleUseSecondaryCurrency = useMemo(
        () =>
            !isEmpty(secondaryCurrencyCode) &&
            (getBonusSupportedCurrencies(bonusInfo).includes(secondaryCurrencyCode) ||
                bonusInfo?.Type === BonusTypesLabels.FreeSpin),
        [secondaryCurrencyCode, bonusInfo],
    );

    const {
        getBonusCalculateActionsRequest,
        getBonusObjectsByTypeRequest,
        getTemplateRequest,
        getNftMarketplacesRequest,
    } = useMemo(
        () => ({
            getBonusCalculateActionsRequest: UtilsHttpService.getBonusCalculateActions(),
            getBonusObjectsByTypeRequest: UtilsHttpService.getBonusObjectsByType(),
            getTemplateRequest: TemplateHttpService.getTemplate(),
            getNftMarketplacesRequest: UtilsHttpService.getNftMarketplaces(),
        }),
        [],
    );

    const isBonusTriggerTypeManual = campaignBonus?.TriggerType === BonusTriggerType.Manual;
    const isNFTBonus = bonusInfo?.Type === BonusTypesLabels.NFTBonus;
    const isObjectTypeKPI = campaignBonus?.BonusConfig?.ObjectType === ObjectTypesMap.KPI;
    const isObjectTypeFromFile = campaignBonus?.BonusConfig?.ObjectType === ObjectTypesMap.FromFile;
    const bonusDetails = bonusInfo?.BonusDetails;

    const nftBonusDetails = useMemo(() => first(bonusDetails), [bonusDetails]);

    useEffect(() => {
        if (isNFTBonus && !isEmpty(bonusDetails)) {
            setIsLoadingMarketplaces(true);
            doGetRequest(getNftMarketplacesRequest.request, {
                successCallback: (data) => {
                    const marketplaceValue = first(bonusDetails)?.Marketplace;
                    setMarketplace((data ?? []).find((item) => item.Value === marketplaceValue)?.DisplayName);
                },
            }).then(() => setIsLoadingMarketplaces(false));
        }
    }, [bonusDetails, doGetRequest, getNftMarketplacesRequest, isNFTBonus]);

    const init = () => {
        if (!isNil(campaignBonus?.BonusConfig)) {
            if (isObjectTypeFromFile) {
                setBonusKPI(l.FromFile);
            } else {
                if (isObjectTypeKPI) {
                    doGetRequest(getBonusObjectsByTypeRequest.request, {
                        queryString: { type: KPISources.KPI },
                        successCallback: (Data) => {
                            const kpi = Data.find(
                                (element) => +element.Id === campaignBonus?.BonusConfig?.ColumnInfoId,
                            );
                            setBonusKPI(kpi?.Name);
                        },
                    });
                } else {
                    doGetRequest(getBonusCalculateActionsRequest.request, {
                        successCallback: (Data) => {
                            const action = Data.find((element) => +element.Id === campaignBonus?.BonusConfig?.Function);
                            setBonusCalculateAction(action?.Name);
                        },
                    });
                    doGetRequest(getBonusObjectsByTypeRequest.request, {
                        queryString: { type: KPISources.Object },
                        successCallback: (Data) => {
                            const object = Data.find(
                                (element) => +element.Id === campaignBonus?.BonusConfig?.ObjectType,
                            );
                            setBonusKPI(object?.Name);
                        },
                    });
                }
            }
        }
        if (!isNil(campaignBonus?.InternalTemplateId)) {
            doGetRequest(getTemplateRequest.request, {
                queryString: { templateId: campaignBonus.InternalTemplateId },
                successCallback: (data) => {
                    setInternalMessageTitle(data?.Name);
                },
            });
        }
    };

    const cleanUp = () => {
        return () => {
            getBonusCalculateActionsRequest.cancel('CampaignBonusInfoModalBody:getBonusCalculateActionsRequest');
            getBonusObjectsByTypeRequest.cancel('CampaignBonusInfoModalBody:getBonusObjectsByTypeRequest');
            getTemplateRequest.cancel('CampaignBonusInfoModalBody:getTemplateRequest');
            getNftMarketplacesRequest.cancel('CampaignBonusInfoModalBody:getNftMarketplacesRequest');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
            <div className="crm-scrollable-container">
                <GeneralInfo
                    id={bonusInfo?.Id}
                    title={bonusInfo?.Name}
                    description={bonusInfo?.Description}
                    type={bonusInfo?.Type}
                    startDate={bonusInfo?.StartDate}
                    endDate={bonusInfo?.EndDate}
                    state={bonusInfo?.State}
                    walletType={bonusInfo?.BalanceType}
                    internalMessageTitle={internalMessageTitle}
                />
                <div className="segment-modal-head">
                    {isBonusTriggerTypeManual && (
                        <div className="title-wrapper">
                            <p className="title-wrapper-text">{t(l.BonusCalculationLogic)}</p>
                            <span className="title-wrapper-line" />
                        </div>
                    )}
                    {!isNil(campaignBonus) && (
                        <>
                            <div className="key-value-h">
                                <div className="crm-flex">
                                    <span className="ellipsis-text kv-label">{t(l.Wallet)}</span>
                                    <Tooltip text={t(l.BonusWalletInfo)}>
                                        <Icon type="bc-icon-info" />
                                    </Tooltip>
                                </div>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {isNil(campaignBonus.Wallet) ? t(l.Standart) : campaignBonus.Wallet}
                                </span>
                            </div>
                            {isBonusTriggerTypeManual && (
                                <div className="key-value-h">
                                    <span className="ellipsis-text kv-label">{t(l.Method)}</span>
                                    <span className="ellipsis-text kv-value kv-value-css">
                                        {isNil(campaignBonus.BonusConfig) ? t(l.Static) : t(l.Dynamic)}
                                    </span>
                                </div>
                            )}
                            {isBonusTriggerTypeManual && !isNil(campaignBonus.BonusConfig) && (
                                <div className="key-value-h">
                                    <span className="ellipsis-text kv-label">{t(l.Option)}</span>
                                    <span className="ellipsis-text kv-value kv-value-css">
                                        {ObjectTypeLabelMap[campaignBonus.BonusConfig.ObjectType] ?? KPISources.Object}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    {!isNil(campaignBonus) &&
                        !isNFTBonus &&
                        (isNil(campaignBonus.BonusConfig) ? (
                            <>
                                {isVisibleUseSecondaryCurrency && (
                                    <div className="key-value-h">
                                        <span className="ellipsis-text kv-label">
                                            {t(l.UseSecondaryCurrency, { currencyCode: secondaryCurrencyCode })}
                                        </span>
                                        <span className="ellipsis-text kv-value kv-value-css">
                                            {campaignBonus.UseSecondaryCurrency ? t(l.On) : t(l.Off)}
                                        </span>
                                    </div>
                                )}
                                {!isEmpty(campaignBonus.Amounts) && (
                                    <>
                                        {campaignBonus.Amounts.map(({ CurrencyCode, OriginalValue }, index) => {
                                            return (
                                                <div key={index} className="key-value-h">
                                                    <span className="ellipsis-text kv-label">{t(l.Amount)}</span>
                                                    <span className="ellipsis-text kv-value kv-value-css">
                                                        {OriginalValue} {CurrencyCode}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="key-value-h">
                                    <span className="ellipsis-text kv-label">{t(l.ConsiderLimits)}</span>
                                    <span className="ellipsis-text kv-value kv-value-css">
                                        {campaignBonus.BonusConfig.FixOutOfRangeBonus ? t(l.On) : t(l.Off)}
                                    </span>
                                </div>
                                {!isObjectTypeKPI && !isObjectTypeFromFile && (
                                    <div className="key-value-h">
                                        <span className="ellipsis-text kv-label">{t(l.BonusCalculateAction)}</span>
                                        <span className="ellipsis-text kv-value kv-value-css">
                                            {t(bonusCalculateAction ?? l.NA)}
                                        </span>
                                    </div>
                                )}
                                {!isObjectTypeFromFile && (
                                    <div className="key-value-h">
                                        <span className="ellipsis-text kv-label">
                                            {t(isObjectTypeKPI ? l.BonusKPI : l.BonusObjectKind)}
                                        </span>
                                        <span className="ellipsis-text kv-value kv-value-css">{t(bonusKPI)}</span>
                                    </div>
                                )}
                                <div className="key-value-h">
                                    <span className="ellipsis-text kv-label">{t(l.Percentage)}</span>
                                    <span className="ellipsis-text kv-value kv-value-css">
                                        {campaignBonus.BonusConfig.Percentage}%
                                    </span>
                                </div>
                            </>
                        ))}
                    {isNFTBonus && (
                        <>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.Marketplace)}</span>
                                <SkeletonLoader
                                    isBusy={isLoadingMarketplaces}
                                    className="ellipsis-text kv-value kv-value-css"
                                >
                                    {!isLoadingMarketplaces && (
                                        <span className="ellipsis-text kv-value kv-value-css">{marketplace}</span>
                                    )}
                                </SkeletonLoader>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.From)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">{nftBonusDetails?.From}</span>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.To)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">{nftBonusDetails?.To}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </BusyLoader>
    );
};

CampaignBonusInfoModalBody.propTypes = {
    campaignBonus: PropTypes.object,
    bonusInfo: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
};

export default memo(CampaignBonusInfoModalBody);
