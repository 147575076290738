import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { CJCalculateKPIValidationSchema } from 'validators/schemas.validation';
import { useTranslation } from 'react-i18next';
import { isNil, isObject, noop, has, isEmpty, keys, min } from 'lodash';
// Import Hooks
import { useRequest, useMenuItem } from 'hooks';
// Import Services
import { UtilsHttpService } from 'services/http';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import UI Components
import { RadioGroup, Dropdown, Icon, Tooltip, BusyLoader } from '@geneui/components';
// Import Components
import CJMenuItemDropdown from './CJMenuItemDropDown';
// Import Constants
import { l, DynamicBonusTypes, KPISources } from 'constants/common';
import { DynamicTypesRadioGroupOptionsConfig } from '../config';

const getSupportedCases = (supportedCases) =>
    isEmpty(supportedCases)
        ? {}
        : supportedCases.reduce((acc, item) => {
              has(acc, item.Type) ? acc[item.Type].push(item.Id) : (acc[item.Type] = [item.Id]);

              return acc;
          }, {});

const CJCalculateKPI = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const { restore, config } = useMenuItem(option.CustomAttributes.MenuItemId, false);
    const cJMenuItemDropdownRef = useRef();

    const [isLoadingAmountSources, setIsLoadingAmountSources] = useState(true);
    const [isLoadingSourceObjects, setIsLoadingSourceObjects] = useState(true);
    const [isLoadingSourceKPIs, setIsLoadingSourceKPIs] = useState(true);
    const [isSegmentChanged, setIsSegmentChanged] = useState(false);

    const [amountSources, setAmountSources] = useState();
    const [sourcesObjects, setSourceObjects] = useState([]);
    const [sourceKPIs, setSourceKPIs] = useState([]);

    const [supportedSourceObjects, setSupportedSourceObjects] = useState([]);
    const [supportedSourceKPIs, setSupportedSourceKPIs] = useState([]);
    const [supportedCases, setSupportedCases] = useState({});
    const [supportedAmountSources, setSupportedAmountSources] = useState([]);

    const sourceTypes = {
        [DynamicBonusTypes.Object]: supportedSourceObjects,
        [DynamicBonusTypes.KPI]: supportedSourceKPIs,
    };

    const formik = useFormik({
        initialValues: {
            segmentId: defaultValue?.segmentId,
            amountSourceType: defaultValue?.amountSourceType ?? null,
            objectType: defaultValue?.objectType,
            columnInfoId: defaultValue?.columnInfoId,
            calculationMethod: defaultValue?.calculationMethod,
        },
        onSubmit: noop,
        validationSchema: CJCalculateKPIValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm, setValues } = formik;
    const { segmentId, amountSourceType, objectType, columnInfoId, calculationMethod } = values;

    const sourceTypeError = amountSourceType === DynamicBonusTypes.Object ? errors?.objectType : errors?.columnInfoId;

    const { getAmountSourcesRequest, getSourceObjectsByTypeRequest } = useMemo(
        () => ({
            getAmountSourcesRequest: UtilsHttpService.getBonusCalculateActions(),
            getSourceObjectsByTypeRequest: UtilsHttpService.getBonusObjectsByType(),
        }),
        [],
    );

    useEffect(() => {
        if (isSegmentChanged) {
            setFieldValue('amountSourceType', min(supportedAmountSources));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedAmountSources]);

    useEffect(() => {
        if (isEmpty(supportedCases)) {
            setSupportedAmountSources([]);
        } else {
            const tmp = keys(supportedCases).map((key) => {
                switch (key) {
                    case KPISources.Object:
                        return DynamicBonusTypes.Object;
                    case KPISources.KPI:
                        return DynamicBonusTypes.KPI;
                    case KPISources.FromFile:
                        return DynamicBonusTypes.FromFile;
                    default:
                        return '';
                }
            });
            setSupportedAmountSources(tmp);
        }
    }, [supportedCases]);

    useEffect(() => {
        if (has(supportedCases, KPISources.Object)) {
            const tmpObject = supportedCases.Object.map((item) =>
                sourcesObjects.find((object) => +object.value === +item),
            );

            setSupportedSourceObjects(tmpObject);
        } else {
            if (!isEmpty(supportedSourceObjects)) {
                setSupportedSourceObjects([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedCases, sourcesObjects]);

    useEffect(() => {
        if (has(supportedCases, KPISources.KPI)) {
            const tmpKPI = supportedCases.KPI.map((item) => sourceKPIs.find((kpi) => +kpi.value === +item));

            setSupportedSourceKPIs(tmpKPI);
        } else {
            if (!isEmpty(supportedSourceKPIs)) {
                setSupportedSourceKPIs([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedCases, sourceKPIs]);

    useEffect(() => {
        const tmpSegmentId = defaultValue?.segmentId;

        if (!isNil(tmpSegmentId) && !isNil(config)) {
            restore([tmpSegmentId], config).then((segments) => {
                const restoredSegment = segments.find((s) => s.Id === tmpSegmentId);

                if (isNil(restoredSegment)) {
                    setValues({
                        segmentId: '',
                        objectType: '',
                        columnInfoId: '',
                        calculationMethod: '',
                    });

                    !isEmpty(supportedCases) && setSupportedCases({});
                    cJMenuItemDropdownRef.current.reset();
                } else {
                    setFieldValue('segmentId', tmpSegmentId);
                    const tmpSupportedCases = getSupportedCases(restoredSegment.SupportedCases);
                    setSupportedCases(tmpSupportedCases);

                    if (!isNil(objectType)) {
                        if (!has(tmpSupportedCases, KPISources.Object)) {
                            setFieldValue('objectType', '');
                        } else if (!tmpSupportedCases.Object.some((item) => +item === +objectType)) {
                            setFieldValue('objectType', '');
                        }
                    }

                    if (!isNil(columnInfoId)) {
                        if (!has(tmpSupportedCases, KPISources.KPI)) {
                            setFieldValue('columnInfoId', '');
                        } else if (!tmpSupportedCases.KPI.some((item) => +item === +columnInfoId)) {
                            setFieldValue('columnInfoId', '');
                        }
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    const segmentIdChangeHandler = useCallback(
        (event) => {
            if (has(event, 'value')) {
                const tmpSegmentId = event.value;

                if (!isNil(tmpSegmentId) && !isNil(config)) {
                    restore([tmpSegmentId], config).then((segments) => {
                        const restoredSegment = segments.find((s) => s.Id === tmpSegmentId);

                        if (isNil(restoredSegment)) {
                            setValues({
                                segmentId: '',
                                objectType: '',
                                columnInfoId: '',
                                calculationMethod: '',
                            });
                            !isEmpty(supportedCases) && setSupportedCases({});
                            cJMenuItemDropdownRef.current.reset();
                        } else {
                            setFieldValue('segmentId', tmpSegmentId);
                            setSupportedCases(getSupportedCases(restoredSegment.SupportedCases));
                        }
                    });
                } else {
                    setFieldValue('segmentId', '');
                    !isEmpty(supportedCases) && setSupportedCases({});
                }

                if (tmpSegmentId !== segmentId) {
                    !isSegmentChanged && setIsSegmentChanged(true);

                    setValues({
                        objectType: '',
                        columnInfoId: '',
                        calculationMethod: '',
                    });
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [config, supportedCases, segmentId],
    );

    useEffect(() => {
        switch (amountSourceType) {
            case DynamicBonusTypes.Object:
                return getUpdate({
                    segmentId,
                    amountSourceType,
                    objectType,
                    calculationMethod,
                });
            case DynamicBonusTypes.KPI:
                return getUpdate({
                    segmentId,
                    amountSourceType,
                    columnInfoId,
                });
            case DynamicBonusTypes.FromFile:
                return getUpdate({
                    segmentId,
                    amountSourceType,
                });
            default:
                getUpdate({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objectType, columnInfoId, calculationMethod, amountSourceType, segmentId]);

    const menuItemDefaultValue = useMemo(
        () => ({
            value: defaultValue?.segmentId,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const amountSourceChangeHandler = (event) => {
        setFieldValue('amountSourceType', event.target.value);
    };

    const sourceTypesChangeHandler = (event) => {
        if (amountSourceType === DynamicBonusTypes.Object) {
            setFieldValue('objectType', event.value);
        } else if (amountSourceType === DynamicBonusTypes.KPI) {
            setFieldValue('columnInfoId', event.value);
        }
    };

    const amountSourcesChangeHandler = (event) => {
        setFieldValue('calculationMethod', event.value);
    };

    const init = () => {
        submitForm();

        doGetRequest(getAmountSourcesRequest.request, {
            successCallback: (data) => {
                setAmountSources((isObject(data) && data.map((item) => ({ label: item.Name, value: item.Id }))) || []);
                setIsLoadingAmountSources(false);
            },
        });
        doGetRequest(getSourceObjectsByTypeRequest.request, {
            queryString: { type: KPISources.Object },
            successCallback: (data) => {
                setSourceObjects((isObject(data) && data.map((item) => ({ label: item.Name, value: item.Id }))) || []);
                setIsLoadingSourceObjects(false);
            },
        });
        doGetRequest(getSourceObjectsByTypeRequest.request, {
            queryString: { type: KPISources.KPI },
            successCallback: (data) => {
                setSourceKPIs((isObject(data) && data.map((item) => ({ label: item.Name, value: item.Id }))) || []);
                setIsLoadingSourceKPIs(false);
            },
        });
    };

    const cleanUp = () => {
        return () => {
            getAmountSourcesRequest.cancel('CJCalculateKpi:getAmountSourcesRequest');
            getSourceObjectsByTypeRequest.cancel('CJCalculateKpi:getSourceObjectsByTypeRequest');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <div className="cj-calculate-kpi-block">
            <div className="kind-holder-title">
                <p className="ellipsis-text">{t(l.Segment)}</p>
                <Tooltip text={t(l.CJBlockCalculateKPISegmentDescription)} position="bottom">
                    <Icon type="bc-icon-info" />
                </Tooltip>
            </div>
            <CJMenuItemDropdown
                defaultValue={menuItemDefaultValue}
                option={option}
                getUpdate={segmentIdChangeHandler}
                ref={cJMenuItemDropdownRef}
            />
            {!isNil(segmentId) && !isEmpty(supportedCases) && (
                <BusyLoader isBusy={isLoadingAmountSources || isLoadingSourceObjects || isLoadingSourceKPIs}>
                    {!(
                        isLoadingAmountSources ||
                        isLoadingSourceObjects ||
                        isLoadingSourceKPIs ||
                        isEmpty(supportedAmountSources)
                    ) && (
                        <>
                            <div className="radio-group">
                                <RadioGroup
                                    name="controlled"
                                    options={DynamicTypesRadioGroupOptionsConfig.map((item) => ({
                                        value: item.value,
                                        label: (
                                            <>
                                                <p>{t(item.label)}</p>
                                                <Tooltip text={t(item.tooltip)} position="bottom">
                                                    <Icon type="bc-icon-info" />
                                                </Tooltip>
                                            </>
                                        ),
                                        disabled: !supportedAmountSources.includes(item.value),
                                    }))}
                                    value={amountSourceType}
                                    onChange={amountSourceChangeHandler}
                                />
                            </div>
                            <div className="kind-items-wrap">
                                {!isNil(amountSourceType) && amountSourceType !== DynamicBonusTypes.FromFile && (
                                    <div className="kind-holder">
                                        <div className="kind-holder-title">
                                            <p className="ellipsis-text">{t(l.CJBlockCalculateKPIKind)}</p>
                                            <Tooltip text={t(l.CJBlockCalculateKPIKindDescription)} position="bottom">
                                                <Icon type="bc-icon-info" />
                                            </Tooltip>
                                        </div>
                                        <Dropdown
                                            defaultValue={
                                                amountSourceType === DynamicBonusTypes.Object
                                                    ? objectType
                                                    : columnInfoId
                                            }
                                            isMultiSelect={false}
                                            disabled={false}
                                            inputSize="default"
                                            appearance="outline"
                                            data={sourceTypes[amountSourceType]}
                                            onChange={sourceTypesChangeHandler}
                                            isValid={!sourceTypeError}
                                            errorText={getCustomerJourneyFormFieldTranslatableErrorText(
                                                t,
                                                sourceTypeError,
                                            )}
                                        />
                                    </div>
                                )}
                                {amountSourceType === DynamicBonusTypes.Object && (
                                    <div className="kind-holder">
                                        <div className="kind-holder-title">
                                            <p className="ellipsis-text">{t(l.CJBlockCalculateKPICalculateMethod)}</p>
                                            <Tooltip
                                                text={t(l.CJBlockCalculateKPICalculateMethodDescription)}
                                                position="bottom"
                                            >
                                                <Icon type="bc-icon-info" />
                                            </Tooltip>
                                        </div>
                                        <Dropdown
                                            defaultValue={calculationMethod}
                                            isMultiSelect={false}
                                            disabled={false}
                                            hasSearch={false}
                                            inputSize="default"
                                            placeholder="Dropdown title"
                                            appearance="outline"
                                            data={amountSources}
                                            onChange={amountSourcesChangeHandler}
                                            isValid={!errors?.calculationMethod}
                                            errorText={getCustomerJourneyFormFieldTranslatableErrorText(
                                                t,
                                                errors?.calculationMethod,
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </BusyLoader>
            )}
        </div>
    );
};

CJCalculateKPI.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(CJCalculateKPI);
