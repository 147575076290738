// Import constants
import { objectTypesMap } from '../constants';

export function getByHashKey(getByHashKey, queryConfigurations) {
    return queryConfigurations[getByHashKey];
}

export function getInitialList(queryConfiguration) {
    return getByHashKey(`${objectTypesMap.categoriesFilters}root`, queryConfiguration);
}

export function getComparisonHashKey(columnId, comparisonId) {
    return `${objectTypesMap.comparison}${columnId}/${comparisonId}`;
}

export function generateFiltersList(list, queryConfiguration) {
    if (!list) return {};
    const filterList = {};
    list.Filters[0].forEach((elem) => {
        const comparisonFilterHashKey = getComparisonHashKey(elem.Column.Id, elem.Comparision);
        const comparisonFilter = queryConfiguration[comparisonFilterHashKey];
        if (comparisonFilter) {
            comparisonFilter.setValue(elem.Value);
            filterList[comparisonFilterHashKey] = comparisonFilter;
        }
    });
    return filterList;
}
