import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMenuItem } from 'hooks';
import { useTranslation } from 'react-i18next';
import { isNil, isArray } from 'lodash';
// Import UI Components
import { Tooltip, SkeletonLoader } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const MenuItemInfoValue = ({ value, menuItemId }) => {
    const { t } = useTranslation();
    const [menuItemLabels, setMenuItemLabels] = useState([]);
    const { config, restore, isLoadingRestore, isLoadingConfig } = useMenuItem(menuItemId, false);

    useEffect(() => {
        if (!isNil(value) && !isNil(config)) {
            restore(!isArray(value) ? [value] : value).then((data) =>
                setMenuItemLabels(
                    data
                        .filter(({ Id }) => value.includes(Id))
                        .map(({ Name }) => Name)
                        .join(', '),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, config]);

    return (
        <div>
            <SkeletonLoader isBusy={isLoadingConfig || isLoadingRestore}>
                {!isLoadingConfig &&
                    !isLoadingRestore &&
                    (isNil(value) ? (
                        <p className="key-value-items-subtitle"> {t(l.NA)} </p>
                    ) : (
                        <Tooltip text={menuItemLabels} position="right">
                            <div className="menu-item-info-value">{menuItemLabels}</div>
                        </Tooltip>
                    ))}
            </SkeletonLoader>
        </div>
    );
};

MenuItemInfoValue.propTypes = {
    value: PropTypes.array.isRequired,
    menuItemId: PropTypes.number.isRequired,
};

export default MenuItemInfoValue;
