import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty, isEqual, isNil, noop } from 'lodash';
import { getOutgoers, isNode } from 'react-flow-renderer';
// Import UI Components
import { BusyLoader, Title, Alert } from '@geneui/components';
import ColumnKPIs from './ColumnKPIs';
// Import Components
import { CJFilterGroupTag, formsTypes as CJFormsTypes } from 'components/CustomerJourney';
import { formsTypes, operatorsLabels, referenceInArgumentStringBuilder } from 'components/CustomerJourney/config';
// Import Services
import {
    conditionCorrection,
    getFormContent,
    groupBlocksBuilder,
    mapConfigToFlowEditorModel,
    propsCollector,
    setAutomapOptionsValues,
} from 'services/customerJourney';
import { CustomerJourneyHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { l, defaultLogicFunction, RealtimePromotionTypes } from 'constants/common';
//Import SCSS
import 'assets/scss/customerJourneyPage.scss';

const CustomerJourneyFiltersAndKPIs = ({ report, blocks }) => {
    const { t } = useTranslation();

    const { doGetRequest } = useRequest();
    const oneLastBlockRef = useRef({});

    const [elements, setElements] = useState([]);
    const [isLoadingGroupBlocks, setIsLoadingGroupBlocks] = useState(true);
    const [groupBlocks, setGroupBlocks] = useState([]);
    const [isInvalidState, setIsInvalidState] = useState(false);

    const { getCustomerJourneySchema } = useMemo(
        () => ({
            getCustomerJourneySchema: CustomerJourneyHttpService.getCustomerJourneySchema(),
        }),
        [],
    );

    const kpis = useMemo(() => {
        if (!isNil(report) && !isEmpty(blocks)) {
            return report.WorkflowReportConfiguration.ReportConfig.map((item) => {
                const block = blocks.find((block) => block.Name === item.BlockName);
                const kpi = `${block?.DisplayName}/${t(item.WorkflowBlockProperty.Name)}`;

                if (isNil(block)) {
                    setIsInvalidState(true);
                }

                return { DisplayName: kpi };
            });
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report, blocks]);

    useEffect(() => {
        if (!isEmpty(groupBlocks) && !isEmpty(blocks)) {
            const tmpElements = mapConfigToFlowEditorModel(blocks, groupBlocks);

            tmpElements.forEach((el) => {
                if (isNode(el)) {
                    el.data.collectedProperties = propsCollector(el, tmpElements);
                    setAutomapOptionsValues(el, tmpElements);
                }
            });

            for (let i = 0; i < tmpElements.length; i++) {
                const el = tmpElements[i];
                if (isNode(el) && isEmpty(getOutgoers(el, tmpElements))) {
                    oneLastBlockRef.current = el;
                    break;
                }
            }

            setElements(tmpElements);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupBlocks, blocks]);

    const filters = report.WorkflowReportConfiguration.Filters;

    const getCorrectionApiFilterModel = (filters, elements) => {
        const condition = cloneDeep(filters);
        const currentNodeIds = elements.filter((el) => isNode(el)).map((el) => el.id);
        conditionCorrection(condition, currentNodeIds);

        if (!isEqual(filters, condition)) {
            setIsInvalidState(true);
        }

        return condition;
    };

    const buildFilterUIModel = (filters, elements) => {
        return CJFormsTypes.FilterCondition.mapToUIModel(getCorrectionApiFilterModel(filters, elements));
    };

    const filtersUIModel = isEmpty(elements) ? null : buildFilterUIModel(filters, elements);

    const filtersToFiltersTag = (filters) => {
        const filtersTag = (filters.filters ?? []).map((filter) => {
            if (filter.isGroupFilter === true) {
                return filtersToFiltersTag(filter);
            }
            const { rightValue, property, operator, key } = filter;
            const block = elements.find((element) => element.id === property.parentValue);
            const { Functions, CustomAttributes } = block.data.metaData.Properties.find(
                (item) => item.Name === property.value,
            );
            const tmpFunction = Functions.find((item) => item.value === property?.logicFunction);
            const formContent = getFormContent(tmpFunction?.returnType?.BaseTypes, formsTypes);
            let stringProperty = `${block.data.name}/${t(CustomAttributes.DisplayName)}`;
            if (!isNil(property?.logicFunction) && property?.logicFunction !== defaultLogicFunction) {
                const { Functions } = block.data.metaData.Properties.find((item) => item.Name === property.value);
                const tmpFunction = Functions.find((item) => item.value === property?.logicFunction);
                stringProperty = `${t(tmpFunction?.label)} (${stringProperty})`;
            }
            stringProperty = `${stringProperty} ${t(operatorsLabels[operator])}`;
            return {
                label: stringProperty,
                stringBuilder: formContent?.stringBuilder ?? referenceInArgumentStringBuilder,
                CustomAttributes: tmpFunction?.returnType?.CustomAttributes,
                optionType: formContent?.optionType,
                operator,
                rightValue,
                key,
            };
        });
        return { filtersTag, logic: filters.logic, isGroupFilter: true };
    };

    const init = () => {
        doGetRequest(getCustomerJourneySchema.request, {
            queryString: { promotionType: RealtimePromotionTypes.CustomerJourney },
            successCallback: (data) => {
                setGroupBlocks(groupBlocksBuilder(data));
            },
        }).then(() => setIsLoadingGroupBlocks(false));
    };

    const cleanUp = () => {
        return () => {
            getCustomerJourneySchema.cancel('CustomerJourneyReportPage:getCustomerJourneySchema');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <>
            {isInvalidState ? (
                <Alert
                    className="pre-save-warning-alert"
                    closable="false"
                    title={t(l.PreSaveModalWarningMessage)}
                    message={t(l.InvalidCustomerJourneyReport)}
                    type="warning"
                    iconProps={{ type: 'bc-icon-info' }}
                />
            ) : (
                <>
                    <Title text={t(l.ColumnKPIs)} withLine />
                    <div className="kpis-wrapper">
                        <BusyLoader isBusy={isEmpty(kpis)} type="spinner" spinnerSize="medium">
                            <ColumnKPIs kpis={kpis} color="#1473E6" />
                        </BusyLoader>
                    </div>
                    <Title text={t(l.Filters)} withLine />
                    <div className="filters-wrapper">
                        <BusyLoader
                            isBusy={isLoadingGroupBlocks || isEmpty(elements)}
                            type="spinner"
                            spinnerSize="medium"
                        >
                            {!isEmpty(elements) && (
                                <>
                                    <div className="crm-filter-tag-button-wrapper"></div>
                                    <CJFilterGroupTag
                                        filterTag={filtersToFiltersTag(filtersUIModel)}
                                        isLoading={true}
                                        isViewMode={true}
                                        elements={elements}
                                        removeTag={noop}
                                    />
                                </>
                            )}
                        </BusyLoader>
                    </div>
                </>
            )}
        </>
    );
};

CustomerJourneyFiltersAndKPIs.propTypes = {
    report: PropTypes.object.isRequired,
    blocks: PropTypes.array.isRequired,
};

export default memo(CustomerJourneyFiltersAndKPIs);
