import React, { useState, memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Modal, Dropdown, Button } from '@geneui/components';
// Import Components
import { ButtonWithLoader } from 'components';
// Import SCSS
import 'assets/scss/campaignModal.scss';
// Import Constants
import { l, StateModesLabels } from 'constants/common';

const data = [
    { label: l.Live, value: StateModesLabels.Live },
    { label: l.Test, value: StateModesLabels.Test },
];

const ChangeModeCampaignModal = ({ isVisibleModal, currentMode, onCancel, onOk, isLoading }) => {
    const { t } = useTranslation();
    const [mode, setMode] = useState(StateModesLabels.Live);

    const handleSave = useCallback(() => {
        onOk(mode);
    }, [onOk, mode]);

    const modeChangeHandler = useCallback((e) => {
        setMode(e.value);
    }, []);

    const modeData = useMemo(
        () => data.filter((item) => item.value !== currentMode).map((item) => ({ ...item, label: t(item.label) })),
        [t, currentMode],
    );

    return (
        <Modal
            size="content-size"
            background="dark-background"
            position="top"
            title={t(l.SelectMode)}
            className="crm-modal campaign-modal"
            appearance="compact"
            closable={false}
            closeOnClickOutside={true}
            visible={isVisibleModal}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader color="primary" onClick={handleSave} isLoading={isLoading}>
                        {t(l.Save)}
                    </ButtonWithLoader>
                </>
            }
        >
            <div className="campaign-modal-holder">
                <div className="campaign-modal-select-mode">
                    <Dropdown
                        isMultiSelect={false}
                        disabled={false}
                        hasSearch={false}
                        inputSize="default"
                        placeholder={t(l.Mode)}
                        label={t(l.Mode)}
                        labelAppearance="swap"
                        appearance="outline"
                        data={modeData}
                        onChange={modeChangeHandler}
                        value={mode}
                    />
                </div>
            </div>
        </Modal>
    );
};

ChangeModeCampaignModal.propTypes = {
    isVisibleModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentMode: PropTypes.number.isRequired,
};

export default memo(ChangeModeCampaignModal);
