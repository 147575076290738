// Import Constants
import { BrowserStorageKeys } from 'constants/browserStorage';
// Import Actions
import { AuthActionTypes as Types } from './types';

const doLogin = (token) => {
    localStorage.setItem(BrowserStorageKeys.accessToken, token);
    return {
        type: Types.DO_LOGIN,
        payload: token,
    };
};

const doLogout = () => {
    localStorage.removeItem(BrowserStorageKeys.accessToken);
    return {
        type: Types.DO_LOGOUT,
        payload: null,
    };
};

const updateToken = (token) => {
    localStorage.setItem(BrowserStorageKeys.accessToken, token);
    return {
        type: Types.UPDATE_TOKEN,
        payload: token,
    };
};

export default {
    doLogin,
    doLogout,
    updateToken,
};
