import React, { memo } from 'react';
import PropTypes from 'prop-types';
//Import constants
import { Tag } from '@geneui/components';

const ColumnKPIs = ({ kpis, color }) => {
    return (
        <>
            <div className="tagList-bc c-tagList-bc">
                {kpis.map((item, index) => {
                    return (
                        <Tag
                            color={color}
                            background-color="#ff0000"
                            key={index}
                            name={item.DisplayName}
                            cornerRadius="smooth-radius"
                            appearance="minimal"
                            flexibility="compact"
                        />
                    );
                })}
            </div>
        </>
    );
};

ColumnKPIs.propTypes = {
    kpis: PropTypes.array.isRequired,
    color: PropTypes.string,
};

ColumnKPIs.defaultProps = {
    color: '#009bb2',
};
export default memo(ColumnKPIs);
