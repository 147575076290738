import { AdminClassifiersListActionTypes as Types } from './types';

const setClassifiersListTableFilters = (filters) => ({
    type: Types.SET_CLASSIFIERS_LIST_TABLE_FILTERS,
    payload: filters,
});

const setClassifiersListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_CLASSIFIERS_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setClassifiersListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_CLASSIFIERS_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setClassifiersListTableSorting = (sorting) => ({
    type: Types.SET_CLASSIFIERS_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetClassifiersListState = () => ({
    type: Types.RESET_CLASSIFIERS_LIST_STATE,
});

export default {
    setClassifiersListTableFilters,
    setClassifiersListTablePagingPageNumber,
    setClassifiersListTablePagingPageSize,
    setClassifiersListTableSorting,
    resetClassifiersListState,
};
