import { UserDataActionTypes as Types } from 'actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case Types.SET_USER_DATA:
            state = action.payload;
            return Object.assign({}, state, {});
        case Types.CLEAR_USER_DATA:
            state = {};
            return Object.assign({}, state, {});
        case 'SET_LANGUAGE':
            state.Settings.Language = action.payload;
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
