import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
// Import UI Components
import { Icon, Popover } from '@geneui/components';
// Import Components
import { UsedInSection } from 'components';
// Import Constants
import { l, ReportTypesLabels, DateTimeWithoutSecondsFormat, DateFormat } from 'constants/common';
import { ReportContainerConfig } from 'components/CompositeReport/config';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

const DefaultDatesDict = {
    dailyView: {
        day: '',
        days: [],
        isActivePicker: false,
        month: '',
        months: [],
        viewType: 'dailyView',
        weeks: [],
        year: '',
        years: [],
    },
};

const getOperation = (operation) => ReportContainerConfig.radioGroupOptions.find((item) => item.value === operation);

const GeneralDetailsGroupData = (t) => {
    return [
        {
            title: l.GeneralDetails,
            keyValueItemsData: {
                reportId: {
                    label: t(l.ReportId),
                    isLoading: true,
                },
                name: {
                    label: t(l.Title),
                    isLoading: true,
                },
                description: {
                    label: t(l.Description),
                    isLoading: true,
                },
                executionCount: {
                    label: t(l.ExecutionCount),
                    isLoading: true,
                },
                numberOfCustomers: {
                    label: t(l.NumberOfCustomers),
                    isLoading: true,
                    // eslint-disable-next-line react/display-name,react/prop-types
                    renderer: ({ numberOfCustomersData: { Count, Total, isLoading }, getSegmentsCount }) => {
                        return (
                            <div className="ellipsis-text kv-value kv-value-css crm-segment-filters-header-blocks crm-segment-filters-header-left-side">
                                {`${Count} / ${Total}`}
                                <Icon
                                    className={`crm-blue${isLoading ? ' rotate' : ''}`}
                                    type={'bc-icon-refresh'}
                                    onClick={getSegmentsCount}
                                />
                            </div>
                        );
                    },
                },
                reportState: {
                    label: t(l.State),
                    isLoading: true,
                },
                type: {
                    label: t(l.Type),
                    isLoading: true,
                    //TODO change to a single div
                    // eslint-disable-next-line react/display-name,react/prop-types
                    renderer: ({ type }) => (
                        <div className="static-segment-info-item">
                            {type === ReportTypesLabels.Report ? (
                                <Icon type="bc-icon-crm-standart-report crm-segment-type-icon dynamic" />
                            ) : (
                                <Icon type="bc-icon-static crm-segment-type-icon static" />
                            )}
                            <p>{t(ReportTypesLabels[type])}</p>
                        </div>
                    ),
                },
                operation: {
                    label: t(l.Operation),
                    isLoading: true,
                    // eslint-disable-next-line react/display-name, react/prop-types
                    renderer: ({ operation }) => (
                        <div className="static-segment-info-item">
                            <Icon type={getOperation(operation).icon} className="composite-operation-icon" />
                            <p>{t(l[getOperation(operation).label])}</p>
                        </div>
                    ),
                },
                usedIn: {
                    label: t(l.UsedIn),
                    isLoading: true,
                    // eslint-disable-next-line react/display-name
                    renderer: (data) => (
                        <div className="used-in-section">
                            <UsedInSection
                                isLoadingUsedIn={false}
                                usedInData={data}
                                isVisible={true}
                                notUsedInDisplayNameKey={l.NotUsed}
                            />
                        </div>
                    ),
                },
                customerJourneyName: {
                    label: t(l.CustomerJourney),
                    isLoading: true,
                },
                blockName: {
                    label: t(l.CustomerJourneyBlock),
                    isLoading: true,
                },
            },
            isVisible: true,
            key: 'generalDetails',
        },
        {
            title: t(l.Schedule),
            keyValueItemsData: {
                startDate: {
                    label: l.StartDate,
                    isLoading: true,
                    format: (Schedule) => {
                        return isEmpty(Schedule?.CronTime)
                            ? customMomentWithoutTimezoneConversion(Schedule?.StartDate).format(
                                  DateTimeWithoutSecondsFormat,
                              )
                            : customMomentWithoutTimezoneConversion(Schedule?.StartDate).format(DateFormat);
                    },
                },
                endDate: {
                    label: l.EndDate,
                    isLoading: true,
                    format: (Schedule) => {
                        return isEmpty(Schedule?.CronTime)
                            ? customMomentWithoutTimezoneConversion(Schedule?.EndDate).format(
                                  DateTimeWithoutSecondsFormat,
                              )
                            : customMomentWithoutTimezoneConversion(Schedule?.EndDate).format(DateFormat);
                    },
                },
                startTime: {
                    label: l.StartTime,
                    isLoading: true,
                },
                recurrenceCount: {
                    label: l.RecurrenceCount,
                    isLoading: true,
                    renderer: (recurrenceData) => (
                        <Popover
                            className="next-run-date-popover-container"
                            extendTargetWidth={false}
                            Content={recurrenceData?.UpcomingExecutions?.map((executionDate, i) => {
                                return <p key={i}>{moment(executionDate).format(DateFormat)}</p>;
                            })}
                            align="end"
                            position="right"
                        >
                            <button disabled={recurrenceData?.Count === 0} className="blue-color-button">
                                {recurrenceData?.Count}
                            </button>
                        </Popover>
                    ),
                },
                scheduleType: {
                    label: l.ScheduleType,
                    isLoading: true,
                },
                timeZone: {
                    label: l.TimeZone,
                    isLoading: true,
                },
            },
            isVisible: false,
            key: 'schedule',
        },
    ];
};

export { DefaultDatesDict, GeneralDetailsGroupData };
