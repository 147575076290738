import React from 'react';
import PropTypes from 'prop-types';
// Import Components
import {
    CustomerOnBoardActivityChart,
    DailyActiveCustomersByProductChart,
    DailyCommunicationMetricsChart,
    ConversionTimeAnalyticsChart,
} from 'components';
// Import Constants
import { Widgets } from 'constants/common';

const ChartsSection = ({ parentDate, possibleWidgets }) => {
    return (
        <>
            <div className="charts-wrap">
                {possibleWidgets.includes(Widgets.DailyMetricsForNewClients) && (
                    <div className="charts-wrap-inner">
                        <CustomerOnBoardActivityChart parentDate={parentDate} />
                    </div>
                )}
                {possibleWidgets.includes(Widgets.DailyActivityMetricsByProducts) && (
                    <div className="charts-wrap-inner">
                        <DailyActiveCustomersByProductChart parentDate={parentDate} />
                    </div>
                )}
                {possibleWidgets.includes(Widgets.ConversionTimeAnalysis) && (
                    <div className="charts-wrap-inner">
                        <ConversionTimeAnalyticsChart parentDate={parentDate} />
                    </div>
                )}
                {possibleWidgets.includes(Widgets.ConversionTimeAnalysis) && (
                    <div className="charts-wrap-inner">
                        <DailyCommunicationMetricsChart parentDate={parentDate} />
                    </div>
                )}
            </div>
        </>
    );
};

ChartsSection.propTypes = {
    parentDate: PropTypes.array.isRequired,
    possibleWidgets: PropTypes.array,
};

ChartsSection.defaultProps = {
    possibleWidgets: [],
};

export default ChartsSection;
