import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import UI Components
import { Section } from '@geneui/components';
// Import Components
import CampaignGeneralDetails from './CampaignGeneralDetails';
import { CustomFields, SchedulerWithModal } from 'components';
// Import Constants
import { l, ObjectTypeIds } from 'constants/common';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';

const CampaignDetails = ({
    startDate,
    endDate,
    startTime,
    setStartDate,
    setEndDate,
    setStartTime,
    selectedDateSelector,
    setSelectedDateSelector,
    isVisibleRecCount,
    setIsVisibleRecCount,
    recurrenceData,
    isLoaded,
    datesDict,
    setDictData,
    setDatesDict,
    getRecCount,
    selectedProvider,
    selectedGoalType,
    analysisDate,
    setAnalysisDate,
    setSelectedProvider,
    setSelectedGoalType,
    goalCount,
    setGoalCount,
    customFieldsValues,
    setCustomFieldsValues,
    selectedChannel,
    setIsnNonMarketing,
    isNonMarketing,
    addValidationProperties,
    setDropDownSettings,
    dropDownSettings,
    pageType,
    campaignStatus,
    timeZone,
    setTimeZone,
    isActiveSchedule,
    setIsActiveSchedule,
}) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();
    const [customFields, setCustomFields] = useState(null);

    const { getPartnerCustomFieldsRequest } = useMemo(
        () => ({
            getPartnerCustomFieldsRequest: UtilsHttpService.getPartnerCustomFields(),
        }),
        [],
    );

    const getPartnerCustomFields = () => {
        doPostRequest(getPartnerCustomFieldsRequest.request, {
            queryString: { objectType: ObjectTypeIds.Campaign },
            successCallback: (Data) => {
                setCustomFields(Data);
            },
        });
    };

    const init = () => {
        getPartnerCustomFields();
    };

    useEffect(init, []);

    return (
        <div className="crm-campaign-details">
            <Section text="">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name">{t(l.GeneralDetails)}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <CampaignGeneralDetails
                    selectedProvider={selectedProvider}
                    selectedGoalType={selectedGoalType}
                    setSelectedProvider={setSelectedProvider}
                    setSelectedGoalType={setSelectedGoalType}
                    analysisDate={analysisDate}
                    setAnalysisDate={setAnalysisDate}
                    goalCount={goalCount}
                    setGoalCount={setGoalCount}
                    selectedChannel={selectedChannel}
                    setIsnNonMarketing={setIsnNonMarketing}
                    isNonMarketing={isNonMarketing}
                />
            </Section>
            {!isNil(customFields) && !isEmpty(customFields) && (
                <Section text="">
                    <ul className="module-header cr-position-radius p-top s-big">
                        <li className="left-content">
                            <div className="left-inner">
                                <div className="module-title ellipsis-text" title="">
                                    <div className="crm-statics-segment-data-table-name">{t(l.CustomFields)}</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="crm-create-campaign-custom-fields-body-container">
                        <CustomFields
                            customFieldsValues={customFieldsValues}
                            setCustomFieldsValues={setCustomFieldsValues}
                            customFields={customFields}
                            isLoaded={isLoaded}
                            addValidationProperties={addValidationProperties}
                            setDropDownSettings={setDropDownSettings}
                            dropDownSettings={dropDownSettings}
                        />
                    </div>
                </Section>
            )}
            <Section text="">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name">{t(l.Schedule)}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <SchedulerWithModal
                    startDate={startDate}
                    endDate={endDate}
                    startTime={startTime}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setStartTime={setStartTime}
                    selectedDateSelector={selectedDateSelector}
                    setSelectedDateSelector={setSelectedDateSelector}
                    isVisibleRecCount={isVisibleRecCount}
                    setIsVisibleRecCount={setIsVisibleRecCount}
                    recurrenceData={recurrenceData}
                    isLoaded={isLoaded}
                    datesDict={datesDict}
                    setDictData={setDictData}
                    setDatesDict={setDatesDict}
                    getRecCount={getRecCount}
                    pageType={pageType}
                    campaignStatus={campaignStatus}
                    timeZone={timeZone}
                    setTimeZone={setTimeZone}
                    isActiveSchedule={isActiveSchedule}
                    setIsActiveSchedule={setIsActiveSchedule}
                    schedulerOffInfoMessage={t(l.CampaignSchedulerOffInfoMessage)}
                    addValidationProperties={addValidationProperties}
                />
            </Section>
        </div>
    );
};

CampaignDetails.propTypes = {
    isNonMarketing: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isVisibleRecCount: PropTypes.bool,
    recurrenceData: PropTypes.object,
    goalCount: PropTypes.number,
    selectedProvider: PropTypes.number,
    selectedChannel: PropTypes.string,
    selectedDateSelector: PropTypes.string,
    analysisDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    selectedGoalType: PropTypes.string,
    schedule: PropTypes.object,
    datesDict: PropTypes.object,
    customFieldsValues: PropTypes.array,
    setSelectedProvider: PropTypes.func,
    setSelectedGoalType: PropTypes.func,
    setGoalCount: PropTypes.func,
    setAnalysisDate: PropTypes.func,
    setCustomFieldsValues: PropTypes.func,
    setSelectedDateSelector: PropTypes.func,
    setDatesDict: PropTypes.func,
    setIsVisibleRecCount: PropTypes.func,
    setSchedule: PropTypes.func,
    getRecCount: PropTypes.func,
    setDictData: PropTypes.func,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    startTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    setStartTime: PropTypes.func,
    setIsnNonMarketing: PropTypes.func,
    addValidationProperties: PropTypes.func,
    setDropDownSettings: PropTypes.func,
    dropDownSettings: PropTypes.array,
    pageType: PropTypes.string,
    campaignStatus: PropTypes.number,
    timeZone: PropTypes.number,
    setTimeZone: PropTypes.func,
    isActiveSchedule: PropTypes.bool,
    setIsActiveSchedule: PropTypes.func,
};

CampaignDetails.defaultProps = {
    campaignStatus: null,
};
export default memo(CampaignDetails);
