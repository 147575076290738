import { RouteLeaveConfirmationActionTypes as Types } from 'actions/types';

const initialState = {
    name: '',
    hasDataChange: null,
    isChangeSaved: null,
};

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case Types.SET_PAGE_NAME: {
            state.name = payload;
            state.isChangeSaved = false;
            return Object.assign({}, state);
        }
        case Types.SET_HAS_DATA_CHANGE: {
            state.hasDataChange = payload;
            return Object.assign({}, state);
        }
        case Types.SET_IS_CHANGE_SAVED:
            state.isChangeSaved = payload;
            return Object.assign({}, state);
        case Types.RESET_HAS_DATA_CHANGE: {
            state.name = '';
            state.hasDataChange = null;
            state.isChangeSaved = null;
            return Object.assign({}, state);
        }
        default:
            return state;
    }
};
