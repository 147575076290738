import React, { memo, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import UI Components
import { Alert, Paper, Button } from '@geneui/components';
//Import Components
import { ButtonWithLoader, ConfirmationModal, KeyValueItem } from 'components';
// Import Services
import { ProviderHttpService } from 'services/http';
import { Helpers } from 'services';
//Import Constants
import { l, AlertTypes, CommonIcons, ModalsClassNames, DateFormat, DateToISOStringFormat } from 'constants/common';

const { nativeQueryParamsDecoder, customMomentWithoutTimezoneConversion } = Helpers;

const { success } = AlertTypes;

const HoorySettingsSection = () => {
    const { t } = useTranslation();

    const { search, pathname } = useLocation();
    const history = useHistory();

    const { HooryDomain: hooryDomain } = useSelector((state) => state.partnerSettings);

    const { doPostRequest, doGetRequest } = useRequest();
    const { showToaster } = useToaster();

    const hasSettingsRestoredFromParamsRef = useRef(false);

    const [settings, setSettings] = useState(null);
    const [isSettingsLoading, setIsSettingsLoading] = useState(false);
    const [isDisconnectModalLoading, setIsDisconnectModalLoading] = useState(false);
    const [isReconnectModalLoading, setIsReconnectModalLoading] = useState(false);
    const [isConnectButtonLoading, setIsConnectButtonLoading] = useState(false);
    const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
    const [isReconnectModalOpen, setIsReconnectModalOpen] = useState(false);

    const prettifyExpiryDate = useMemo(() => {
        if (settings?.expiry) {
            const currentDate = customMomentWithoutTimezoneConversion();
            const expiryDate = customMomentWithoutTimezoneConversion(settings.expiry);
            const leftDays = expiryDate.diff(currentDate, 'days');

            return expiryDate.format(DateFormat) + ` (${leftDays} ${t(l.DaysLeft)})`;
        }

        return '';
    }, [settings, t]);

    const decodedSearchParams = useMemo(() => nativeQueryParamsDecoder(search), [search]);

    const { getHoorySettingsRequest, saveHoorySettingsRequest, removeHoorySettingsRequest } = useMemo(
        () => ({
            getHoorySettingsRequest: ProviderHttpService.getHoorySetting(),
            saveHoorySettingsRequest: ProviderHttpService.saveHoorySetting(),
            removeHoorySettingsRequest: ProviderHttpService.removeHoorySetting(),
        }),
        [],
    );

    const parseSettingsToUIModel = (settings) => {
        if (isNil(settings.AccessToken) && isNil(settings.Client) && isNil(settings.Uid)) return {};

        return {
            accountId: settings.AccountId,
            accessToken: settings.AccessToken,
            tokenType: settings.TokenType,
            client: settings.Client,
            expiry: settings.Expiry,
            uid: settings.Uid,
        };
    };

    const parseSettingsToBackEndModel = (searchParams) => {
        const parsedToken = JSON.parse(atob(searchParams.token));

        return {
            AccountId: searchParams.account_id,
            AccessToken: parsedToken['access-token'],
            TokenType: parsedToken['token-type'],
            Client: parsedToken.client,
            Expiry: moment.unix(parsedToken.expiry).format(DateToISOStringFormat),
            Uid: parsedToken.uid,
        };
    };

    const getSettings = () => {
        setIsSettingsLoading(true);

        doGetRequest(getHoorySettingsRequest.request, {
            successCallback: (data) => {
                setSettings(parseSettingsToUIModel(data));
            },
        }).then(() => {
            setIsSettingsLoading(false);
        });
    };

    const clearHooryLoginParams = useCallback(() => {
        const searchParams = new URLSearchParams(search);

        searchParams.delete('account_id');
        searchParams.delete('token');
        searchParams.delete('param1');

        history.replace({ pathname, search: searchParams.toString() });
    }, [history, pathname, search]);

    const removeSettings = useCallback(() => {
        setIsDisconnectModalLoading(true);

        doPostRequest(removeHoorySettingsRequest.request, {
            requestBody: {},
            successCallback: () => {
                setSettings({});
                setIsDisconnectModalOpen(false);

                showToaster(success, t(l.HoorySettingsRemovedSuccessfully));
            },
        }).then(() => {
            setIsDisconnectModalLoading(false);
        });
    }, [doPostRequest, removeHoorySettingsRequest, showToaster, t]);

    const updateSettings = useCallback(() => {
        setIsConnectButtonLoading(true);

        doPostRequest(saveHoorySettingsRequest.request, {
            requestBody: parseSettingsToBackEndModel(decodedSearchParams),
            successCallback: (data) => {
                hasSettingsRestoredFromParamsRef.current = true;

                setSettings(parseSettingsToUIModel(data));
                clearHooryLoginParams();
                showToaster(success, t(l.HoorySettingsSavedSuccessfully));
            },
        }).then(() => {
            setIsConnectButtonLoading(false);
        });
    }, [decodedSearchParams, doPostRequest, saveHoorySettingsRequest, clearHooryLoginParams, showToaster, t]);

    const onOkReconnectModal = useCallback(() => {
        setIsReconnectModalLoading(true);

        doPostRequest(saveHoorySettingsRequest.request, {
            requestBody: parseSettingsToBackEndModel(decodedSearchParams),
            successCallback: (data) => {
                hasSettingsRestoredFromParamsRef.current = true;

                setSettings(parseSettingsToUIModel(data));
                setIsReconnectModalOpen(false);
                clearHooryLoginParams();
                showToaster(success, t(l.HoorySettingsSavedSuccessfully));
            },
        }).then(() => {
            setIsReconnectModalLoading(false);
        });
    }, [decodedSearchParams, doPostRequest, saveHoorySettingsRequest, clearHooryLoginParams, showToaster, t]);

    const onConnect = useCallback(() => {
        const redirectUrl = encodeURIComponent(window.location.href);
        const hooryLoginUrl = `${hooryDomain}app/login?callback=${redirectUrl}`;

        setIsConnectButtonLoading(true);

        window.open(hooryLoginUrl, '_self');
    }, [hooryDomain]);

    const onDisconnect = useCallback(() => setIsDisconnectModalOpen(true), []);
    const onCancelDisconnectModal = useCallback(() => setIsDisconnectModalOpen(false), []);
    const onCancelReconnectModal = useCallback(() => {
        setIsReconnectModalOpen(false);
        clearHooryLoginParams();
    }, [clearHooryLoginParams]);

    useEffect(() => {
        if (
            hasSettingsRestoredFromParamsRef.current ||
            isNil(decodedSearchParams.account_id) ||
            isNil(decodedSearchParams.token) ||
            isNil(settings)
        ) {
            return;
        }

        if (isEmpty(settings)) {
            updateSettings();
        } else {
            setIsReconnectModalOpen(true);
        }
    }, [decodedSearchParams, settings, updateSettings]);

    const init = () => {
        getSettings();
    };

    const cleanUp = () => {
        return () => {
            getHoorySettingsRequest.cancel('HoorySettingsSection:getHoorySettingsRequest');
            removeHoorySettingsRequest.cancel('HoorySettingsSection:removeHoorySettingsRequest');
            saveHoorySettingsRequest.cancel('HoorySettingsSection:saveHoorySettingsRequest');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <>
            <Paper className="hoory-provider-settings card" paperDirection="column">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name ellipsis-text">
                                    {t(l.HoorySettings)}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="content">
                    <Alert closable={false} title={t(l.HooryDescription)} iconProps={{ type: 'bc-icon-info' }} />
                    <div className="fields-btn-group">
                        <KeyValueItem
                            label={l.AccountID}
                            value={settings?.accountId}
                            vertical={true}
                            isLoading={isSettingsLoading}
                        />
                        <KeyValueItem
                            label={l.UserID}
                            value={settings?.uid}
                            vertical={true}
                            isLoading={isSettingsLoading}
                        />

                        <KeyValueItem
                            label={l.Expiry}
                            value={prettifyExpiryDate}
                            vertical={true}
                            isLoading={isSettingsLoading}
                        />
                        <div className="hoory-settings-action-buttons-container">
                            <Button
                                appearance="outline"
                                onClick={onDisconnect}
                                disabled={isEmpty(settings) || isSettingsLoading}
                            >
                                {t(l.Disconnect)}
                            </Button>
                            <ButtonWithLoader
                                isLoading={isConnectButtonLoading}
                                color="confirm"
                                onClick={onConnect}
                                isDisabled={isSettingsLoading}
                            >
                                {isEmpty(settings) ? t(l.Connect) : t(l.Reconnect)}
                            </ButtonWithLoader>
                        </div>
                    </div>
                </div>
            </Paper>
            {isDisconnectModalOpen && (
                <ConfirmationModal
                    onOk={removeSettings}
                    onCancel={onCancelDisconnectModal}
                    isVisibleModal={isDisconnectModalOpen}
                    titleText={t(l.DisconnectHoory)}
                    questionLabel={t(l.DisconnectHooryDescription)}
                    iconType={CommonIcons.Info}
                    actionLabel={t(l.Continue)}
                    className={ModalsClassNames.UsedIn}
                    isLoading={isDisconnectModalLoading}
                />
            )}
            {isReconnectModalOpen && (
                <ConfirmationModal
                    onOk={onOkReconnectModal}
                    onCancel={onCancelReconnectModal}
                    isVisibleModal={isReconnectModalOpen}
                    titleText={t(l.ReconnectHoory)}
                    questionLabel={t(l.ReconnectHooryDescription)}
                    iconType={CommonIcons.Info}
                    actionLabel={t(l.Continue)}
                    className={ModalsClassNames.UsedIn}
                    isLoading={isReconnectModalLoading}
                />
            )}
        </>
    );
};

export default memo(HoorySettingsSection);
