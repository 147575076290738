import React, { useEffect, useRef, useState } from 'react';
import { ExtendedInput } from '@geneui/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';

const TemplateTextInput = ({
    displayNameKey,
    value,
    onBlur,
    onFocus,
    onChange,
    validationState,
    updateInsertionHandler,
}) => {
    const { t } = useTranslation();

    const inputRef = useRef();
    const [currentValue, setCurrentValue] = useState(value);

    const insertTextAtCursor = (textToInsert) => {
        inputRef.current.focus();
        const { selectionStart } = inputRef.current;
        const firstPart = currentValue.slice(0, selectionStart);
        const secondPart = currentValue.slice(selectionStart, currentValue.length);
        const resultValue = `${firstPart} {${textToInsert}} ${secondPart}`;
        onChange(resultValue, 0);
    };

    if (isFunction(updateInsertionHandler)) updateInsertionHandler(insertTextAtCursor);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = (e) => {
        setCurrentValue(e.target.value);
        onChange(e.target.value);
    };

    return (
        <div className="crm-create-template-page-c-c-h-inputs-column">
            <ExtendedInput
                ref={inputRef}
                placeholder={t(displayNameKey)}
                value={currentValue}
                isValid={validationState.isValid}
                errorText={validationState.errorText}
                onBlur={(e) => onBlur?.(e.target.value)}
                onChange={handleChange}
                onFocus={onFocus}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                labelAppearance="swap"
            />
        </div>
    );
};

TemplateTextInput.propTypes = {
    displayNameKey: PropTypes.string.isRequired,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    validationState: PropTypes.shape({
        isValid: PropTypes.bool,
        errorText: PropTypes.string,
    }),
    updateInsertionHandler: PropTypes.func,
};

export default TemplateTextInput;
