import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
// Import components
import SegmentItemView from './SegmentItemView';
// Import constants
import { l } from 'constants/common';

const SegmentItem = ({ dropSegment, itemData, acceptType, onClear, isValid }) => {
    const { t } = useTranslation();
    const [isHover, setIsHover] = useState(false);

    const [, drop] = useDrop({
        accept: acceptType,
        drop: (item) => {
            dropSegment(item.index);
        },
        collect: (monitor) => {
            setIsHover(monitor.isOver());
        },
        canDrop: () => {
            return itemData.enableDropHere;
        },
    });

    return (
        !isNil(itemData) && (
            <div className={`droping-container-row ${isValid ? '' : 'invalid-row'}`} ref={drop}>
                {itemData.enableDropHere ? (
                    <div className={`drop-dash ${isHover ? 'isHoverOn' : 'isNotHover'}`}>{t(l.DropHere)} </div>
                ) : (
                    <SegmentItemView
                        type={itemData.type}
                        title={itemData.title?.Name}
                        isClearable={true}
                        onClear={onClear}
                    />
                )}
            </div>
        )
    );
};

SegmentItem.propTypes = {
    itemData: PropTypes.object,
    acceptType: PropTypes.string,
    dropSegment: PropTypes.func,
    onClear: PropTypes.func,
    isValid: PropTypes.bool,
};

SegmentItem.defaultProps = {
    itemData: null,
    acceptType: '',
    dropSegment: noop,
    onClear: noop,
    isValid: true,
};

export default memo(SegmentItem);
