import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useFormik } from 'formik';
// Import Components
import { CJCascadeDropdown } from 'components/CustomerJourney';

const SameDayForm = ({ defaultValue, getUpdate, ...rest }) => {
    const formik = useFormik({
        initialValues: { property: defaultValue?.property || {} },
        onSubmit: noop,
    });
    const { values, setFieldValue } = formik;
    const { property } = values;

    const cascadeDropdownChangeHandler = ({ parentValue, value, logicFunction }) => {
        getUpdate({ property: { parentValue, value, logicFunction } });
        setFieldValue('property', { parentValue, value, logicFunction });
    };

    useEffect(() => {
        getUpdate({
            property,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <CJCascadeDropdown
                onChange={cascadeDropdownChangeHandler}
                value={{ ...property }}
                onValidationChange={(isValid) => getUpdate({ isValid })}
                {...rest}
            />
        </>
    );
};

SameDayForm.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getUpdate: PropTypes.func.isRequired,
};

export default memo(SameDayForm);
