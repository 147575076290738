import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
// Import Constants
import { l, ActionsIcons } from 'constants/common';

const ClientActionsButtons = ({ data, manageTagAction }) => {
    const { t } = useTranslation();

    return (
        <Tooltip text={t(l.ManageTag)}>
            <Button
                icon={ActionsIcons.ManageTag}
                appearance="minimal"
                color="primary"
                onClick={() => manageTagAction(data)}
            />
        </Tooltip>
    );
};

ClientActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    manageTagAction: PropTypes.func,
};

ClientActionsButtons.defaultProps = {
    manageTagAction: noop,
};

export default memo(ClientActionsButtons);
