import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isArray, isNil } from 'lodash';

const useHasPermission = () => {
    const userAllPermissionList = useSelector((state) => state.userData.PermissionList);
    const partnerSettings = useSelector((state) => state.partnerSettings);

    const hasPermission = useCallback(
        (permissions, isAllMandatory = false) => {
            if (!isNil(userAllPermissionList)) {
                if (isArray(permissions)) {
                    if (isAllMandatory) {
                        let existsPermissions = [];
                        permissions.forEach((permission) => {
                            userAllPermissionList.includes(permission) && existsPermissions.push(permission);
                        });
                        return existsPermissions.length === permissions.length;
                    } else {
                        return permissions.some((permission) => userAllPermissionList.includes(permission));
                    }
                } else {
                    return userAllPermissionList.includes(permissions);
                }
            }
        },
        [userAllPermissionList],
    );

    const hasPartnerSettings = useCallback(
        (settingsKey) => {
            return partnerSettings[settingsKey];
        },
        [partnerSettings],
    );

    return { hasPermission, hasPartnerSettings };
};

export default useHasPermission;
