import { AdminPartnerSettingsListActionTypes as Types } from './types';

const setSettingsListTableFilters = (filters) => ({
    type: Types.SET_SETTINGS_LIST_TABLE_FILTERS,
    payload: filters,
});

const setSettingsListTablePagingPageNumber = (pageNumber) => ({
    type: Types.SET_SETTINGS_LIST_TABLE_PAGING_PAGE_NUMBER,
    payload: pageNumber,
});

const setSettingsListTablePagingPageSize = (pageSize) => ({
    type: Types.SET_SETTINGS_LIST_TABLE_PAGING_PAGE_SIZE,
    payload: pageSize,
});

const setSettingsListTableSorting = (sorting) => ({
    type: Types.SET_SETTINGS_LIST_TABLE_SORTING,
    payload: sorting,
});

const resetSettingsListState = () => ({
    type: Types.RESET_SETTINGS_LIST_STATE,
});

export default {
    setSettingsListTableFilters,
    setSettingsListTablePagingPageNumber,
    setSettingsListTablePagingPageSize,
    setSettingsListTableSorting,
    resetSettingsListState,
};
