import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Import UI Components
import { Section } from '@geneui/components';
// Import Components
import ViberTemplateContent from './ViberTemplateContent';
import SMSContent from './SMSContent';
// Import Services
import { TemplateType } from 'services/template';
// Import Constants
import { l, TemplateInputTypes } from 'constants/common';
import DynamicVariables from './DynamicVariables';
// Import Hooks
import { useInputInsertion } from 'hooks';

const ViberCascadeContent = ({
    templateData,
    templateLangKey,
    handleChange,
    maxSMSPartsCount,
    shortenerAndTrackerSettings,
    dynamicVariables,
}) => {
    const { t } = useTranslation();

    const { IsAllowViberSmsCascading: isCascade } = useSelector((state) => state.partnerSettings);
    const { insertionHandler, updateInsertionHandler, insertionOptions, setInsertionOptions } = useInputInsertion();

    const updateDynamicVariableOptionsViber = useCallback(
        ({ hintText, focusedInputKey }) => {
            setInsertionOptions({ channel: TemplateType.Viber, inputFilterKey: '', hintText, focusedInputKey });
        },
        [setInsertionOptions],
    );

    const updateDynamicVariableOptionsSMS = useCallback(() => {
        setInsertionOptions({
            channel: TemplateType.SMS,
            inputFilterKey: '',
            hintText: '',
            focusedInputKey: TemplateInputTypes.SMSBody,
        });
    }, [setInsertionOptions]);

    return (
        <>
            <DynamicVariables
                dynamicVariables={dynamicVariables}
                clickHandler={insertionHandler}
                insertionOptions={insertionOptions}
            />

            {!isCascade ? (
                <ViberTemplateContent
                    templateType={TemplateType.Viber}
                    templateData={templateData}
                    templateLangKey={templateLangKey}
                    handleChange={handleChange}
                    shortenerAndTrackerSettings={shortenerAndTrackerSettings.viber}
                    updateDynamicVariableOptions={updateDynamicVariableOptionsViber}
                    updateInsertionHandler={updateInsertionHandler}
                />
            ) : (
                <div className="viber-template-cascade-container">
                    <div className="viber-template-cascade-section viber-content">
                        <Section text={t(l.ViberContent)}>
                            <div className="viber-template-cascade-section-content">
                                <ViberTemplateContent
                                    templateType={TemplateType.Viber}
                                    templateData={templateData}
                                    templateLangKey={templateLangKey}
                                    handleChange={handleChange}
                                    shortenerAndTrackerSettings={shortenerAndTrackerSettings.viber}
                                    updateDynamicVariableOptions={updateDynamicVariableOptionsViber}
                                    updateInsertionHandler={updateInsertionHandler}
                                />
                            </div>
                        </Section>
                    </div>
                    <div className="viber-template-cascade-section sms-content">
                        <Section text={t(l.SMSContent)}>
                            <div className="viber-template-cascade-section-content">
                                <SMSContent
                                    bodyPropertyName={TemplateInputTypes.SMSBody}
                                    templateData={templateData}
                                    templateLangKey={templateLangKey}
                                    maxSMSPartsCount={maxSMSPartsCount}
                                    handleChange={handleChange}
                                    shortenerAndTrackerSettings={shortenerAndTrackerSettings.sms}
                                    updateDynamicVariableOptions={updateDynamicVariableOptionsSMS}
                                    updateInsertionHandler={updateInsertionHandler}
                                    shouldFocusOnMount={false}
                                />
                            </div>
                        </Section>
                    </div>
                </div>
            )}
        </>
    );
};

ViberCascadeContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    bodyValidationState: PropTypes.object.isRequired,
    maxSMSPartsCount: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    updateFocusedInput: PropTypes.func.isRequired,
    shortenerAndTrackerSettings: PropTypes.object.isRequired,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
};

export default ViberCascadeContent;
