import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useRequest, useData } from 'hooks';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';

const CJProviderIdInfoBlock = ({ data, customAttributes }) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();
    const dataValue = useData(data);

    const [isLoading, setIsLoading] = useState(false);
    const [providerData, setProviderData] = useState(null);
    const { getPartnerMessageProviders } = useRef({
        getPartnerMessageProviders: UtilsHttpService.getPartnerMessageProviders(),
    }).current;

    useEffect(() => {
        setIsLoading(true);
        doPostRequest(getPartnerMessageProviders.request, {
            requestBody: { DeliveryMethod: customAttributes.DeliveryMethod },
            successCallback: (Data) => {
                setIsLoading(false);
                setProviderData(
                    Data.find((item) => {
                        return item.Id === dataValue.value;
                    }),
                );
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataValue]);

    return (
        <>
            <CJInfoBlockLabelValueWrapper label={t(l.Name)} value={providerData?.Name} isLoading={isLoading} />
        </>
    );
};

CJProviderIdInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
};

export default memo(CJProviderIdInfoBlock);
