import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import UI components
import { RadioGroup, Icon, ModuleTitle, BusyLoader, Tooltip } from '@geneui/components';
// Import Components
import ReportItems from './ReportItems';
// Import Constants
import { ReportContainerConfig, ReportItemsConfig } from './config';
import { l } from 'constants/common';

const { radioGroupOptions } = ReportContainerConfig;
const { maxCountOfReports } = ReportItemsConfig;

const ReportsContainer = ({
    getRowByIndex,
    firstReport,
    setFirstReport,
    reports,
    setReports,
    radioGroupValue,
    setRadioGroupValue,
    isLoading,
    dropAcceptType,
}) => {
    const { t } = useTranslation();

    const getCount = () => {
        // eslint-disable-next-line no-extra-boolean-cast
        let count = !!firstReport?.enableDropHere ? 0 : 1;
        // for each because can drop enable first not 1 or more fields
        reports.forEach((element) => {
            // eslint-disable-next-line no-extra-boolean-cast
            count += !!element.enableDropHere ? 0 : 1;
        });
        return count;
    };

    const getRadioGroupOptions = () => {
        return radioGroupOptions.map((item) => {
            return {
                label: (
                    <div className="union-icon">
                        <Icon type={item.icon} /> {t(l[item.label])}
                    </div>
                ),
                value: item.value,
            };
        });
    };

    return (
        <div className="segment-container-inner">
            <ModuleTitle title="ReportContainer" description=" " />

            <BusyLoader isBusy={isLoading && isNil(dropAcceptType)} type="spinner" spinnerSize="medium">
                {!isNil(dropAcceptType) && (
                    <div className="segment-container-inner-inside">
                        <div className="union-block">
                            {!isNil(radioGroupValue) && (
                                <>
                                    <RadioGroup
                                        name="controlled"
                                        options={getRadioGroupOptions()}
                                        type="tab"
                                        value={radioGroupValue}
                                        onChange={(e) => setRadioGroupValue(e.target.value)}
                                    />
                                    <Tooltip
                                        style={{ marginTop: '5px' }}
                                        text={
                                            <div>
                                                <div>
                                                    <span>{t(l.Union)}</span>
                                                    <p>{t(l.CompositeReportUnionTooltipText)}</p>
                                                </div>
                                                <div>
                                                    <span>{t(l.Intersect)}</span>
                                                    <p>{t(l.CompositeReportIntersectTooltipText)}</p>
                                                </div>
                                                <div>
                                                    <span>{t(l.FirstPriority)}</span>
                                                    <p>{t(l.CompositeReportFirstPriorityTooltipText)}</p>
                                                </div>
                                            </div>
                                        }
                                        position="bottom"
                                    >
                                        <Icon type="bc-icon-info" />
                                    </Tooltip>
                                </>
                            )}
                        </div>
                        <div className="droping-container">
                            <div className="droping-container-inner">
                                <div className="droping-container-info">
                                    {!isEmpty(reports) && !isEmpty(firstReport) && (
                                        <ReportItems
                                            getRowByIndex={getRowByIndex}
                                            firstReport={firstReport}
                                            setFirstReport={setFirstReport}
                                            reports={reports}
                                            setReports={setReports}
                                            dropAcceptType={dropAcceptType}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="segment-txt">
                            <span className="segment-count">
                                {getCount()}
                                {'/'}
                                {maxCountOfReports + 1}
                            </span>
                            {t(l.Reports)}
                        </div>
                    </div>
                )}
            </BusyLoader>
        </div>
    );
};

ReportsContainer.propTypes = {
    getRowByIndex: PropTypes.func.isRequired,
    firstReport: PropTypes.object.isRequired,
    reports: PropTypes.array.isRequired,
    setFirstReport: PropTypes.func.isRequired,
    setReports: PropTypes.func.isRequired,
    radioGroupValue: PropTypes.number.isRequired,
    setRadioGroupValue: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    dropAcceptType: PropTypes.string.isRequired,
};

export default memo(ReportsContainer);
