import { useCallback, useRef, useState } from 'react';

const useInputInsertion = () => {
    const insertionHandlerRef = useRef();
    const [insertionOptions, setInsertionOptions] = useState({
        hintText: '',
        focusedInputKey: '',
        inputFilterKey: '',
        channel: '',
    });

    const updateInsertionHandler = useCallback(
        (inputKey, insertionHandler) => {
            if (inputKey === insertionOptions.focusedInputKey) {
                insertionHandlerRef.current = insertionHandler;
            }
        },
        [insertionOptions.focusedInputKey],
    );

    return {
        insertionHandler: (...args) => insertionHandlerRef.current(...args),
        updateInsertionHandler,
        insertionOptions,
        setInsertionOptions,
    };
};

export default useInputInsertion;
