import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Import configs
import { campaignsInActionColumnsConfigs, campaignsSectionRadioGroupOptionsConfigs } from './config';
// Import Hooks
import { useGACustomEvent, useRequest, useAutocompleteRequest } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
// Import UI Components
import { RadioGroup, ModuleTitle } from '@geneui/components';
import { DataTable } from 'components';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';

const { GADateFilterCategory } = GACategoryEvents;

const CampaignsInActionList = () => {
    const { t } = useTranslation();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();
    const { doGetRequest } = useRequest();
    const { logCustomEvent } = useGACustomEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { campaignsInActionColumns } = useMemo(() => campaignsInActionColumnsConfigs(t), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { campaignsSectionRadioGroupOptions } = useMemo(() => campaignsSectionRadioGroupOptionsConfigs(t), []);

    const [isLoadingCampaignsInAction, setIsLoadingCampaignsInAction] = useState(false);
    const [campaignsIinActionData, setCampaignsIinActionData] = useState([]);
    const [radioGroupButtonValue, setRadioGroupButtonValue] = useState(campaignsSectionRadioGroupOptions[1].value);

    const { getDashboardInActionPromotionsRequest } = useMemo(
        () => ({
            getDashboardInActionPromotionsRequest: DashboardHttpService.getDashboardInActionPromotions,
        }),
        [],
    );

    const getCampaignsInActionTableColumns = () => campaignsInActionColumns;

    const getCampaignsInAction = () => {
        setIsLoadingCampaignsInAction(true);

        const requestObj = getAutocompleteRequest(getDashboardInActionPromotionsRequest);

        doGetRequest(requestObj.request, {
            queryString: radioGroupButtonValue,
            successCallback: (Data) => {
                setCampaignsIinActionData(
                    Data.map(({ Name, CreatorName, Type, ConditionType, ObjectId, HasBonus }, index) => {
                        return {
                            hasHover: true,
                            dragDisable: true,
                            data: {
                                index,
                                isRowOpend: false,
                                Title: { Name, ObjectId, HasBonus, Type, ConditionType },
                                CreatorName,
                                Type,
                                ConditionType: ConditionType,
                            },
                        };
                    }),
                );
                setIsLoadingCampaignsInAction(false);
            },
        });
    };

    const handleRadioButtonChange = (e) => {
        const optionName = e.target?.value?.toString();

        logCustomEvent(
            GADateFilterCategory.name,
            GADateFilterCategory.events[`inActionPromotion${optionName}`],
            `in action promotion date change`,
            optionName,
        );
        setRadioGroupButtonValue(e.target.value);
    };

    useEffect(getCampaignsInAction, [radioGroupButtonValue]);

    const cleanUp = () => {
        return () => {
            cancelRequest('CampaignsInActionList:getDashboardInActionPromotionsRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <div className="campaign-action-metrics-wrap-column">
            <div className="campaign-action-wrap-column-head for-crs-holder-to-button-style">
                <ModuleTitle title={t(l.CampaignsInAction)} />

                <RadioGroup
                    type="tab"
                    options={campaignsSectionRadioGroupOptions}
                    value={radioGroupButtonValue}
                    onChange={handleRadioButtonChange}
                />
            </div>
            <div className="campaign-action-wrap-column-content">
                <DataTable
                    columnKey="dataKey"
                    rowKey="index"
                    data={campaignsIinActionData}
                    dataCount={campaignsIinActionData?.length}
                    columns={getCampaignsInActionTableColumns()}
                    isLoading={isLoadingCampaignsInAction}
                    isVisibleOnlyDataTable={true}
                    withPagination={false}
                    withPageSelector={false}
                />
            </div>
        </div>
    );
};

export default CampaignsInActionList;
