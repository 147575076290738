/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
// Import UI Components
import { Collapse, Panel, Dropdown, Button, Alert, ExtendedInput, DatePickerInput } from '@geneui/components';
// Import Components
import BonusCalculation from './BonusCalculation';
import { CollapseItemsGroup } from 'components';
// Import Hooks
import { useValidator } from 'hooks';
// Import Services
import Helpers from 'services/Helpers';
// Import Constants
import { l, BonusTypesLabels, MonthDayYearFormat, ExpirationTypes } from 'constants/common';
import { ExpirationTypesDropdownData } from './configs';

const { customMoment } = Helpers;

const BonusOverlayContent = ({
    name,
    description,
    type,
    isVisibleCalculationSection,
    isVisibleRadioSelection,
    bonusDetails,
    bonusDetailsAmounts,
    setBonusDetailsAmounts,
    internalMessages,
    internalMessage,
    setInternalMessage,
    bonusCalculationRadioGroupValue,
    setBonusCalculationRadioGroupValue,
    bonusCalculationActiveTabKey,
    setBonusCalculationActiveTabKey,
    bonusCalculateActions,
    setBonusCalculateAction,
    bonusCalculateAction,
    bonusKind,
    setBonusKind,
    bonusKinds,
    considerLimits,
    setConsiderLimits,
    percentage,
    setPercentage,
    setIsVisibleBonusInfoModal,
    isVisibleAlert,
    freeSpinCurrencies,
    supportedCurrencies,
    selectedWallet,
    setSelectedWallet,
    selectedExpirationType,
    setSelectedExpirationType,
    expirationDateValue,
    setExpirationDateValue,
    expirationPeriodValue,
    setExpirationPeriodValue,
    supportedWallets,
}) => {
    const { t } = useTranslation();
    const expirationTypesData = ExpirationTypesDropdownData(t);
    const pageValidation = useSelector((state) => state.pageValidation);
    const validator = useValidator();

    useEffect(() => {
        if (type === BonusTypesLabels.TradingPlan) {
            validator({ expirationType: selectedExpirationType });
        }
        if (selectedExpirationType) {
            const expirationValue =
                selectedExpirationType === ExpirationTypes.ExpirationDate ? expirationDateValue : expirationPeriodValue;
            validator({
                expirationValue: {
                    value: expirationValue,
                    type: selectedExpirationType,
                },
            });
        }
    }, [selectedExpirationType, name]);

    const freeSpinCurrenciesCollapseModel = useMemo(() => {
        return [
            {
                name: t(l.OneSpinPrice),
                isTranslated: true,
                items: freeSpinCurrencies.map((currency) => {
                    return { label: currency?.Currency, value: `${t(l.Amount)}  ${currency?.Amount}` };
                }),
            },
        ];
    }, [freeSpinCurrencies, t]);

    const [isDynamicDisabled, setIsDynamicDisabled] = useState(false);

    const internalMessageChangeHandler = (event) => {
        setInternalMessage(event?.value);
    };

    useEffect(() => {
        const tmpIsDynamicDisabled =
            (bonusDetails.length > 0 && isEmpty(bonusDetails.filter((item) => item.MinAmount !== item.MaxAmount))) ||
            type === BonusTypesLabels.LoyaltyPoint;

        setIsDynamicDisabled(tmpIsDynamicDisabled);
    }, [bonusDetails, type]);

    const daysInputChangeHandler = (e) => {
        setExpirationPeriodValue(e.target.value);
        validator({ expirationValue: { value: e.target.value, type: selectedExpirationType } });
    };

    const expirationDateChangeHandler = (data) => {
        setExpirationDateValue(data);
        validator({ expirationValue: { value: data, type: selectedExpirationType } });
    };

    const getExpirationTypeComponent = useMemo(() => {
        switch (selectedExpirationType) {
            case ExpirationTypes.ExpirationDate:
                return (
                    <DatePickerInput
                        value={expirationDateValue}
                        title={t(l.ExpirationDate)}
                        placeholder={t(l.SelectExpirationDate)}
                        onChange={expirationDateChangeHandler}
                        min={customMoment().add(1, 'days').format(MonthDayYearFormat)}
                        isValid={
                            isNil(pageValidation?.expirationValue?.isValid) || pageValidation?.expirationValue?.isValid
                        }
                        errorText={pageValidation?.expirationValue?.errorText}
                    />
                );
            case ExpirationTypes.ExpirationPeriod:
                return (
                    <ExtendedInput
                        type="number"
                        title={t(l.Name)}
                        label={t(l.Days)}
                        placeholder={t(l.Days)}
                        labelAppearance="swap"
                        onChange={daysInputChangeHandler}
                        value={expirationPeriodValue}
                        isValid={!pageValidation?.expirationValue?.errorText}
                        errorText={pageValidation?.expirationValue?.errorText}
                        min={1}
                    />
                );

            default:
                return <></>;
        }
    }, [
        expirationDateValue,
        expirationPeriodValue,
        pageValidation?.expirationDate?.isValid,
        pageValidation?.expirationPeriod?.errorText,
        selectedExpirationType,
    ]);

    const expirationTypeChangeHandler = (item) => {
        setSelectedExpirationType(item.value);
        if (item.value === ExpirationTypes.ExpirationDate) {
            validator({ expirationValue: { value: expirationDateValue, type: item.value } });
        } else {
            validator({ expirationValue: { value: expirationPeriodValue, type: item.value } });
        }
    };

    return (
        <>
            <div className="overlay-content-header">
                <div>
                    <p className="header-title-name">{name}</p>
                    <p className="header-title-count">{description}</p>
                </div>
                <div>
                    <Button
                        className="info-button"
                        icon="bc-icon-info"
                        appearance="minimal"
                        onClick={() => setIsVisibleBonusInfoModal(true)}
                    >
                        {t(l.Info)}
                    </Button>
                </div>
            </div>
            <div className="accordion-wrap">
                {type !== BonusTypesLabels.TradingPlan && (
                    <Collapse allActive={true}>
                        <Panel title={t(l.IncludeInternalMessage)} key="IncludeInternalMessage">
                            <p>{t(l.InternalMessages)}</p>
                            <div className="accordion-body-holder">
                                <Dropdown
                                    defaultValue={internalMessage}
                                    isMultiSelect={false}
                                    disabled={false}
                                    hasSearch={true}
                                    clearable={true}
                                    inputSize="default"
                                    placeholder="Dropdown title"
                                    appearance="outline"
                                    data={internalMessages}
                                    searchPlaceholderText={t(l.Search)}
                                    noDataText={t(l.NoDataFound)}
                                    onChange={internalMessageChangeHandler}
                                />
                            </div>
                        </Panel>
                    </Collapse>
                )}
                {isVisibleCalculationSection && (
                    <Collapse allActive={true}>
                        <Panel title={t(l.BonusCalculation)} key="BonusCalculation">
                            <BonusCalculation
                                bonusType={type}
                                bonusDetails={bonusDetails}
                                bonusDetailsAmounts={bonusDetailsAmounts}
                                setBonusDetailsAmounts={setBonusDetailsAmounts}
                                radioGroupValue={bonusCalculationRadioGroupValue}
                                setRadioGroupValue={setBonusCalculationRadioGroupValue}
                                bonusCalculationActiveTabKey={bonusCalculationActiveTabKey}
                                setBonusCalculationActiveTabKey={setBonusCalculationActiveTabKey}
                                bonusCalculateActions={bonusCalculateActions}
                                setBonusCalculateAction={setBonusCalculateAction}
                                bonusCalculateAction={bonusCalculateAction}
                                considerLimits={considerLimits}
                                setConsiderLimits={setConsiderLimits}
                                percentage={percentage}
                                setPercentage={setPercentage}
                                bonusKind={bonusKind}
                                setBonusKind={setBonusKind}
                                bonusKinds={bonusKinds}
                                isDynamicDisabled={isDynamicDisabled}
                                isVisibleRadioSelection={isVisibleRadioSelection}
                                supportedCurrencies={supportedCurrencies}
                                selectedWallet={selectedWallet}
                                setSelectedWallet={setSelectedWallet}
                                supportedWallets={supportedWallets}
                            />
                        </Panel>
                    </Collapse>
                )}
                {type === BonusTypesLabels.TradingPlan && (
                    <div className="trading-plan-container">
                        <Dropdown
                            label={t(l.ExpirationType)}
                            placeholder={t(l.ExpirationType)}
                            labelAppearance="swap"
                            data={expirationTypesData}
                            value={selectedExpirationType}
                            onChange={expirationTypeChangeHandler}
                            errorText={pageValidation?.expirationType?.errorText}
                            isValid={!pageValidation?.expirationType?.errorText}
                        />
                        {getExpirationTypeComponent}
                    </div>
                )}

                {isVisibleAlert && (
                    <div className="overlay-content-alert">
                        <Alert
                            closable={false}
                            title={t(l.BonusParametersAreSetupInSpringBackoffice)}
                            iconProps={{ type: 'bc-icon-info' }}
                        />
                    </div>
                )}
                {type === BonusTypesLabels.FreeSpin && !isEmpty(freeSpinCurrencies) && (
                    <div className="collapse-items-group-container">
                        <CollapseItemsGroup data={freeSpinCurrenciesCollapseModel} isAllActive={true} />
                    </div>
                )}
            </div>
        </>
    );
};

BonusOverlayContent.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    bonusDetails: PropTypes.array.isRequired,
    bonusDetailsAmounts: PropTypes.object.isRequired,
    setBonusDetailsAmounts: PropTypes.func.isRequired,
    internalMessages: PropTypes.array.isRequired,
    internalMessage: PropTypes.number,
    setInternalMessage: PropTypes.func.isRequired,
    bonusCalculationRadioGroupValue: PropTypes.number,
    setBonusCalculationRadioGroupValue: PropTypes.func.isRequired,
    bonusCalculationActiveTabKey: PropTypes.string.isRequired,
    setBonusCalculationActiveTabKey: PropTypes.func.isRequired,
    bonusCalculateActions: PropTypes.array.isRequired,
    setBonusCalculateAction: PropTypes.func.isRequired,
    bonusCalculateAction: PropTypes.string,
    bonusKind: PropTypes.object,
    setBonusKind: PropTypes.func.isRequired,
    bonusKinds: PropTypes.object.isRequired,
    considerLimits: PropTypes.object.isRequired,
    setConsiderLimits: PropTypes.func.isRequired,
    percentage: PropTypes.object.isRequired,
    setPercentage: PropTypes.func.isRequired,
    isVisibleCalculationSection: PropTypes.bool,
    setIsVisibleBonusInfoModal: PropTypes.func,
    isVisibleAlert: PropTypes.bool,
    freeSpinCurrencies: PropTypes.array,
    isVisibleRadioSelection: PropTypes.bool,
    supportedCurrencies: PropTypes.array,
    selectedWallet: PropTypes.string,
    setSelectedWallet: PropTypes.func,
    selectedExpirationType: PropTypes.string,
    setSelectedExpirationType: PropTypes.func,
    expirationDateValue: PropTypes.string,
    setExpirationDateValue: PropTypes.func,
    expirationPeriodValue: PropTypes.string,
    setExpirationPeriodValue: PropTypes.func,
    supportedWallets: PropTypes.array,
};

export default memo(BonusOverlayContent);
