import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Components
import { DateTimeWithoutSecondsFormat, l } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMoment } = Helpers;

const CJDTDateTimeInfoBlock = ({ data }) => {
    const { t } = useTranslation();
    const { dates, months, years, hours, minutes } = data?.value?.params;
    const date = customMoment()
        .set('year', years)
        .set('month', months)
        .set('date', dates)
        .set('hour', hours)
        .set('minute', minutes);

    return <CJInfoBlockLabelValueWrapper label={t(l.DateTime)} value={date.format(DateTimeWithoutSecondsFormat)} />;
};

CJDTDateTimeInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJDTDateTimeInfoBlock);
