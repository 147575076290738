import React, { memo, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { BonusHttpService } from 'services/http';
// Import Components
import { BonusModal } from 'components';

const BonusActionsModals = (props) => {
    const { isInfoModalOpenedState, onCloseModalHandler, modalsStateSetDict, openedModalData } = props;

    const { Id, Name, Description, StartDate, EndDate, Type, State, BalanceType } = openedModalData;

    const { doGetRequest } = useRequest();

    const [bonusDetails, setBonusDetails] = useState(null);

    const { getBonusById } = useMemo(
        () => ({
            getBonusById: BonusHttpService.getBonusById(),
        }),
        [],
    );

    useEffect(() => {
        setBonusDetails(null);
        if (!isNil(Id)) {
            doGetRequest(getBonusById.request, {
                queryString: { bonusId: Id },
                successCallback: (data) => {
                    setBonusDetails(data?.BonusDetails ?? []);
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Id]);

    return (
        !isEmpty(openedModalData) && (
            <>
                {isInfoModalOpenedState && (
                    <BonusModal
                        id={Id}
                        title={Name}
                        description={Description}
                        startDate={StartDate}
                        endDate={EndDate}
                        type={Type}
                        bonusDetails={bonusDetails}
                        handleClose={() => onCloseModalHandler(modalsStateSetDict.infoBonus.key)}
                        isVisible={isInfoModalOpenedState}
                        state={State}
                        wallet={BalanceType}
                    />
                )}
            </>
        )
    );
};

BonusActionsModals.propTypes = {
    isInfoModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.object.isRequired,
};

export default memo(BonusActionsModals);
