import { useCallback } from 'react';

/**
 * `useBind` will return a memoized version of the function with bound initial params.
 * @param {Function} func
 * @param  {...any} params
 * @returns
 */
const useBind = (func, ...params) => {
    return useCallback(func.bind(null, ...params), [func]);
};

export default useBind;
