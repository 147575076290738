import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { first, isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
import { Helpers } from 'services';
// Import UI components
import { BusyLoader, Empty, TreeMapChart, RadioGroup, ModuleTitle } from '@geneui/components';
// Import Configs
import { RFMChartRadioGroupOptionsConfigs } from './config';
// Import Constants
import { l } from 'constants/common';

const { customMoment } = Helpers;
let getDashboardRFMChartObj;

const RFMAnalysisChart = () => {
    const { t } = useTranslation();
    const { RFMChartRadioGroupOptions } = RFMChartRadioGroupOptionsConfigs(t);

    const { doGetRequest } = useRequest();
    const [isLoading, setIsLoading] = useState(false);
    const [RFMChartData, setRFMChartData] = useState(null);
    const [radioGroupButtonValue, setRadioGroupButtonValue] = useState(first(RFMChartRadioGroupOptions).value);
    const [total, setTotal] = useState();

    const { getRFMChartRequest } = useRef({
        getRFMChartRequest: DashboardHttpService.getRFMChart,
    }).current;

    const getRFMChart = () => {
        setIsLoading(true);
        if (!isNil(getDashboardRFMChartObj)) {
            getDashboardRFMChartObj.cancel('RFMAnalysisChart:getRFMChartRequest');
        }
        getDashboardRFMChartObj = getRFMChartRequest();
        doGetRequest(getDashboardRFMChartObj.request, {
            queryString: { productId: radioGroupButtonValue, date: customMoment().toISOString(false) },
            successCallback: ({ Parent1, Parent2 }) => {
                let points = [
                    {
                        id: 'id_1',
                        value: Parent1.Value,
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, Parent1.Color], // start
                                [1, Parent1.Color], // end
                            ],
                        },
                        name: Parent1.DisplayName,
                        infoText: Parent1.Description ?? '',
                        rate: Parent1.Rate,
                    },
                    {
                        id: 'id_2',
                        value: Parent2.Value,
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, Parent2.Color], // start
                                [1, Parent2.Color], // end
                            ],
                        },
                        name: Parent2.DisplayName,
                        infoText: Parent2.Description ?? '',
                        rate: Parent2.Rate,
                    },
                ];

                Parent1.Child.forEach((element) => {
                    points.push({
                        id: element.DisplayName,
                        name: element.DisplayName,
                        parent: 'id_1',
                        value: element.Value,
                        color: element.Color,
                        infoText: element.Description ?? '',
                        rate: element.Rate,
                    });
                });

                Parent2.Child.forEach((element) => {
                    points.push({
                        id: element.DisplayName,
                        name: element.DisplayName,
                        parent: 'id_2',
                        value: element.Value,
                        color: element.Color,
                        infoText: element.Description ?? '',
                        rate: element.Rate,
                    });
                });

                setTotal(Parent1.Value + Parent2.Value);

                const newData = [
                    {
                        type: 'treemap',
                        layoutAlgorithm: 'squarified',
                        allowDrillToNode: true,
                        animationLimit: 1000,
                        dataLabels: {
                            enabled: false,
                        },
                        levelIsConstant: false,
                        levels: [
                            {
                                level: 1,
                                dataLabels: {
                                    enabled: true,
                                },
                                borderWidth: 3,
                            },
                        ],
                        data: points,
                    },
                ];

                setRFMChartData(newData);
            },
        }).then(() => setIsLoading(false));
    };

    const handleRadioButtonChange = (e) => {
        setRadioGroupButtonValue(e.target.value);
    };

    useEffect(getRFMChart, [radioGroupButtonValue]);

    const tooltip = {
        borderRadius: 5,
        headerFormat: null,
        formatter: function () {
            const { infoText, rate } = this.point.options;
            return `<div class="hs-tooltip tree-map-tp">
                      <p>${this.key}:${rate} </p>
                      <small> ${infoText} </small>
                 </div>`;
        },
        useHTML: true,
    };

    return (
        <>
            <div className="charts-wrap-inner-head">
                <ModuleTitle title={t(l.CustomersSegmentationBasedOnRFMAnalysis)} />

                <div className="campaign-metrics-wrap-c-h-d">
                    <RadioGroup
                        type="tab"
                        options={RFMChartRadioGroupOptions}
                        value={radioGroupButtonValue}
                        onChange={handleRadioButtonChange}
                    />
                </div>
            </div>

            {isLoading ? (
                <BusyLoader spinnerSize="big" isBusy={true} />
            ) : total === 0 ? (
                <Empty title={t(l.NoDataToDisplay)} />
            ) : (
                <div className="charts-wrap-inner-content">
                    <TreeMapChart series={RFMChartData} tooltip={tooltip} />
                </div>
            )}
        </>
    );
};

export default RFMAnalysisChart;
