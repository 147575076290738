import React, { memo, useMemo, useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, first, isNil } from 'lodash';
// Import Components
import { KeyValueItems } from 'components';
// Import UI Components
import { BusyLoader } from '@geneui/components';
// Import Hooks
import { useReportColumns, useRequest } from 'hooks';
// Import Services
import { SegmentHttpService } from 'services/http';
import { subscribe } from 'services/event';
// Import Constants
import { ClientColumnSets, GlobalEvents, SubscriberSources } from 'constants/common';

let clientsPreviewCache = {};

const clientPreviewExpiration = 60 * 60 * 1000;

const getClientCachedData = (clientId) => {
    if (!isNil(clientsPreviewCache[clientId])) {
        if (Date.now() < clientsPreviewCache[clientId].expirationDate) {
            return clientsPreviewCache[clientId].data;
        }

        clientsPreviewCache[clientId] = null;
    }

    return null;
};

const setClientCachedData = (clientId, data) => {
    clientsPreviewCache[clientId] = {
        expirationDate: Date.now() + clientPreviewExpiration,
        data,
    };
};

subscribe(GlobalEvents.PartnerChanged, SubscriberSources.ClientInfoPreview, () => (clientsPreviewCache = {}));

const ClientPreview = ({ clientId }) => {
    const { doPostRequest } = useRequest();

    const [previewableColumns, isLoadingPreviewableColumns] = useReportColumns(ClientColumnSets.previewableColumns);
    const [clientData, setClientData] = useState(() => getClientCachedData(clientId) ?? []);
    const getClientPreviewTimeoutId = useRef();

    const { getClientPreview } = useMemo(
        () => ({
            getClientPreview: SegmentHttpService.getClientPreview(),
        }),
        [],
    );

    const mapToClientPreviewApiModel = useCallback(
        (columns) => {
            return {
                ClientId: +clientId,
                Columns: columns.map((item) => ({ Id: item.Id })),
                Table: { Id: 4 },
            };
        },
        [clientId],
    );

    const getClientPreviewData = useCallback(() => {
        doPostRequest(getClientPreview.request, {
            requestBody: mapToClientPreviewApiModel(previewableColumns),
            successCallback: (clientData) => {
                const tmpClientData = first(clientData?.Data);

                if (!isEmpty(tmpClientData?.Data) && !isEmpty(tmpClientData?.Columns)) {
                    setClientData((prev) => {
                        const clientDataIdValueGroup = tmpClientData.Columns.reduce((acc, item, index) => {
                            acc[item.Id] = first(tmpClientData.Data)[index];

                            return acc;
                        }, {});

                        const result = prev.map((item) => ({
                            ...item,
                            value: isEmpty(clientDataIdValueGroup) ? '' : clientDataIdValueGroup[item.id],
                            isLoading: false,
                        }));

                        setClientCachedData(clientId, result);

                        return result;
                    });
                }
            },
        });
    }, [previewableColumns, getClientPreview.request, mapToClientPreviewApiModel, doPostRequest, clientId]);

    useEffect(() => {
        if (!isEmpty(previewableColumns) && isNil(getClientCachedData(clientId))) {
            const kpiColumnsData = previewableColumns.map(
                ({ DisplayName, Id }) => ({
                    label: DisplayName,
                    id: Id,
                    value: '',
                    isLoading: true,
                }),
                {},
            );

            setClientData(kpiColumnsData);

            getClientPreviewTimeoutId.current = setTimeout(getClientPreviewData, 300);
        }
    }, [previewableColumns, isLoadingPreviewableColumns, getClientPreviewData, clientId]);

    const cleanUp = () => {
        return () => {
            clearTimeout(getClientPreviewTimeoutId.current);
            getClientPreview.cancel('ClientPreview:getClientPreview');
        };
    };

    useEffect(cleanUp, []);

    return (
        <BusyLoader isBusy={isLoadingPreviewableColumns} type="spinner" spinnerSize="medium">
            {!isLoadingPreviewableColumns && <KeyValueItems items={clientData} vertical={true} />}
        </BusyLoader>
    );
};

ClientPreview.propTypes = {
    clientId: PropTypes.string.isRequired,
};

export default memo(ClientPreview);
