import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, noop } from 'lodash';
import { useFormik } from 'formik';
import { CJVisualizationStepTemplateValidationSchema } from 'validators/schemas.validation';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
//Import Constants
import { LanguagesFlagsPaths } from 'constants/utils';
import { l } from 'constants/common';

const CustomerJourneyVisualizationTemplate = ({ value, onChange }) => {
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: { name: value?.name },
        onSubmit: noop,
        validationSchema: CJVisualizationStepTemplateValidationSchema(), //TODO: rename schema name
    });
    const { setFieldValue, errors, isValid } = formik;

    const [name, setName] = useState(value?.name);
    const [blurredName, setBlurredName] = useState(value?.name);
    const [description, setDescription] = useState(value?.description);
    const [blurredDescription, setBlurredDescription] = useState(value?.description);

    const nameChangeHandler = (e, value) => {
        const resultValue = !isNil(value) ? value : e.target.value.trimStart();
        setName(resultValue);
    };

    const descriptionChangeHandler = (e) => {
        setDescription(e.target.value);
    };

    const nameBlurHandler = (e) => {
        const tmpName = e.target.value.trimEnd();
        setName(tmpName);
        setBlurredName(tmpName);
    };

    const descriptionBlurHandler = (e) => {
        const tmpDescription = e.target.value.trimEnd();
        setDescription(tmpDescription);
        setBlurredDescription(tmpDescription);
    };

    useEffect(() => {
        if (value?.name !== blurredName) {
            setName(value?.name);
        }
        if (value?.description !== blurredDescription) {
            setDescription(value?.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        setFieldValue('name', name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useEffect(() => {
        onChange({ name: blurredName, description: blurredDescription });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurredDescription, blurredName]);

    useEffect(() => {
        onChange({ isValid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const language = value?.language;

    return (
        <div className="crm-cj-visualization-template-wrapper">
            <div className="select-languages-block">
                {isNil(language?.flagId) || isNil(LanguagesFlagsPaths[language.flagId]) ? (
                    <>
                        <span />
                        <p style={{ marginLeft: '0 auto' }}>{t(l.Default)}</p>
                    </>
                ) : (
                    <>
                        <span className="select-language-img">
                            <img src={LanguagesFlagsPaths[language.flagId]} alt={language.name} />
                        </span>
                        <p> {language.label} </p>
                    </>
                )}
            </div>

            <div className="c-input-group-wrapper">
                <div className="c-input-group-item">
                    <ExtendedInput
                        appearance="outline"
                        inputSize="default"
                        labelAppearance="swap"
                        placeholder={t(l.Title)}
                        onChange={nameChangeHandler}
                        value={isNil(name) ? '' : name}
                        isValid={!errors?.name}
                        errorText={t(errors?.name?.label, {
                            fieldName: t(errors?.name?.fieldName),
                            count: t(errors?.name?.count),
                        })}
                        onBlur={nameBlurHandler}
                    />
                </div>
                <div className="c-input-group-item">
                    <ExtendedInput
                        appearance="outline"
                        inputSize="default"
                        labelAppearance="swap"
                        placeholder={t(l.Description)}
                        onChange={descriptionChangeHandler}
                        value={isNil(description) ? '' : description}
                        onBlur={descriptionBlurHandler}
                    />
                </div>
            </div>
        </div>
    );
};

CustomerJourneyVisualizationTemplate.propTypes = {
    value: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        language: {
            label: PropTypes.string.isRequired,
            flagId: PropTypes.string.isRequired,
        },
    }),
    onChange: PropTypes.func,
};

CustomerJourneyVisualizationTemplate.defaultProps = {
    value: null,
    onChange: noop,
};
export default CustomerJourneyVisualizationTemplate;
