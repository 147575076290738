import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { BusyLoader, Empty } from '@geneui/components';
// Import constants
import { l } from 'constants/common';

const CampaignBonusSummaryByCurrency = ({ isLoading, data }) => {
    const { t } = useTranslation();

    return (
        <div className="bonus-currency">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name">{t(l.BonusSummaryByCurrency)}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <BusyLoader isBusy={isLoading} type="spinner" spinnerSize={'big'}>
                {!isNil(data) && isEmpty(data) ? (
                    <Empty title={t(l.NoDataToDisplay)} />
                ) : (
                    !isNil(data) && (
                        <div className="bonus-sum-holder">
                            {data.map(({ CurrencyId, BonusAmount }) => (
                                <div key={CurrencyId} className="bonus-sum-wrap">
                                    <p className="title-currency">{CurrencyId}</p>
                                    <p className="currency-amount">{BonusAmount}</p>
                                </div>
                            ))}
                        </div>
                    )
                )}
            </BusyLoader>
        </div>
    );
};

CampaignBonusSummaryByCurrency.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array,
};

CampaignBonusSummaryByCurrency.defaultProps = {
    isLoading: true,
    data: [],
};

export default memo(CampaignBonusSummaryByCurrency);
