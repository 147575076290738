import { CRMRequestService, postCancelableRequest, deleteCancelableRequest, getCancelableRequest } from './http.config';

const ProviderHttpService = {
    getEmailProviders: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Provider/List',
        });
    },
    getSmsProviders: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Provider/ListSMSProvider',
        });
    },
    deleteEmailProvider: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ emailProviderId }) => `/Provider/Delete?emailProviderId=${emailProviderId}`,
        });
    },
    deleteSmsProvider: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ smsProviderId }) => `/Provider/DeleteSMSProvider?smsProviderId=${smsProviderId}`,
        });
    },
    setDefaultProvider: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Provider/SetDefault',
        });
    },
    setFirebaseKey: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Provider/SetFirebaseKey`,
        });
    },
    removeFirebaseKey: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Provider/RemoveFirebaseKey`,
        });
    },
    getPushNotificationProviders: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Provider/GetPushNotificationProviders',
        });
    },
    setDefaultPushNotificationProvider: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ notificationType }) =>
                `/Provider/SetDefaultPushNotificationProvider?notificationType=${notificationType}`,
        });
    },
    setOneSignalSetting: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Provider/SetOneSignalSetting`,
        });
    },
    getTelegram: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Provider/GetTelegram',
        });
    },
    saveTelegram: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Provider/CreateOrUpdateTelegram',
        });
    },
    getHoorySetting: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Provider/GetHoorySetting',
        });
    },
    saveHoorySetting: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Provider/CreateOrUpdateHoorySetting`,
        });
    },
    removeHoorySetting: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Provider/RemoveHoorySetting`,
        });
    },
};

export default ProviderHttpService;
