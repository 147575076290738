import React from 'react';
import { useTranslation } from 'react-i18next';
// Import Constants
import { RoutesList } from 'routes';
import { l } from 'constants/common';
// Import Hooks
import { useGoToRoute } from 'hooks';
// Import Images
import succeedImage from 'assets/images/crm-auth-reset-password-confirm-succeed-image.svg';

const { CRM_SIGN_IN } = RoutesList;

const ResetPasswordConfirmSucceed = () => {
    const { t } = useTranslation();
    const { goToRoute, getRoutePath } = useGoToRoute();
    const signInPagePath = getRoutePath(CRM_SIGN_IN);

    const handleGoToLogInPageClick = (e) => {
        e.preventDefault();
        goToRoute(CRM_SIGN_IN);
    };

    return (
        <div className="crm-auth-form-card crm-auth-form-confirm-succeed-card">
            <p className="crm-auth-form-line crm-auth-form-success-message">
                {t(l.YourPasswordWasChangedSuccessfully)}
            </p>

            <div className="crm-auth-form-line crm-auth-form-icon-line">
                <img src={succeedImage} alt="succeed icon" />
            </div>

            <div className="crm-auth-form-line crm-auth-form-back-button">
                <a
                    href={signInPagePath}
                    className="crm-auth-form-link crm-auth-form-link-back-button tittle-text ellipsis-text"
                    onClick={handleGoToLogInPageClick}
                >
                    {t(l.GoToLogInPage)}
                </a>
            </div>
        </div>
    );
};

export default ResetPasswordConfirmSucceed;
