import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop, toString } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useGACustomEvent, useHasPermission } from 'hooks';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
import { AccessControl } from 'components';
// Import Constants
import {
    CustomerJourneyStatusLabels,
    l,
    PERMISSIONS,
    ActionsIcons,
    RealtimePromotionTypes,
    GACategoryEvents,
} from 'constants/common';

const { GADetailsCategory } = GACategoryEvents;

const CustomerJourneyActionsButtons = ({
    data,
    unArchiveCustomerJourneyAction,
    archiveCustomerJourneyAction,
    deleteCustomerJourneyAction,
    cloneCustomerJourneyAction,
    editCustomerJourneyAction,
    stopSendCustomerJourneyAction,
    startCustomerJourneyAction,
    viewCustomerJourneyAction,
    resumeCustomerJourneyAction,
    infoCustomerJourneyAction,
    reportCustomerJourneyAction,
    createCustomerJourneyVisualizationAction,
    editCustomerJourneyVisualizationAction,
    activityTimelineAction,
}) => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const { logCustomEvent } = useGACustomEvent();
    const hasExecutePermission = useMemo(() => hasPermission(PERMISSIONS.ExecuteWorkflow), [hasPermission]);

    const { Status, HasVisualization, isViewPage, IsUsed, ArchivedDate, PromotionType } = data;
    const status = toString(Status);

    const isCompleted = status === CustomerJourneyStatusLabels.Completed;
    const isInProgress = status === CustomerJourneyStatusLabels.InProgress;
    const isFailed = status === CustomerJourneyStatusLabels.Failed;
    const isInvalid = status === CustomerJourneyStatusLabels.Invalid;
    const isSuspended = status === CustomerJourneyStatusLabels.Suspended;
    const isNonStarted = status === CustomerJourneyStatusLabels.NonStarted;

    const isStarting = status === CustomerJourneyStatusLabels.Starting;
    const isUpdating = status === CustomerJourneyStatusLabels.Updating;
    const isResuming = status === CustomerJourneyStatusLabels.Resuming;
    const isSuspending = status === CustomerJourneyStatusLabels.Suspending;

    const isVisibleActionButtons = !isUpdating && !isStarting && !isResuming && !isSuspending;

    const isArchived = !isNil(ArchivedDate);
    const isCJ = PromotionType === +RealtimePromotionTypes.CustomerJourney;

    const isVisibleStopSend = () => isInProgress && isVisibleActionButtons && hasExecutePermission;
    const isVisibleStart = () =>
        !isCompleted &&
        !isFailed &&
        !isInProgress &&
        !isInvalid &&
        !isArchived &&
        !isSuspended &&
        isVisibleActionButtons &&
        hasExecutePermission;
    const isVisibleDelete = useMemo(
        () =>
            !isInProgress &&
            !isCompleted &&
            !isSuspended &&
            !isFailed &&
            !IsUsed &&
            isVisibleActionButtons &&
            hasPermission(PERMISSIONS.DeleteWorkflow),
        [IsUsed, hasPermission, isCompleted, isFailed, isInProgress, isSuspended, isVisibleActionButtons],
    );
    const isVisibleArchive = () => !isVisibleDelete && !isInProgress && !isArchived && isVisibleActionButtons;
    const isVisibleUnArchive = () => isArchived && isVisibleActionButtons;
    const isVisibleView = () => !isInvalid && !isViewPage && isVisibleActionButtons;
    const isVisibleResume = () => isSuspended && isVisibleActionButtons && hasExecutePermission;
    const isVisibleInfo = () => !isViewPage;
    const isVisibleActivityTimeline = () => isViewPage && isVisibleActionButtons;
    const isVisibleReport = () => !isNonStarted && !isInvalid && isVisibleActionButtons;
    const isVisibleEdit = useMemo(() => {
        if (!isVisibleActionButtons) {
            return false;
        }

        if (isInProgress || isSuspended) {
            return hasPermission([PERMISSIONS.ExecuteWorkflow, PERMISSIONS.EditWorkflow], true);
        } else {
            return hasPermission(PERMISSIONS.EditWorkflow);
        }
    }, [hasPermission, isInProgress, isSuspended, isVisibleActionButtons]);

    const isVisibleClone = useMemo(
        () => isVisibleActionButtons && hasPermission([PERMISSIONS.CreateWorkflow, PERMISSIONS.ViewWorkflow], true),
        [hasPermission, isVisibleActionButtons],
    );

    const onReportAction = () => {
        logCustomEvent(
            GADetailsCategory.name,
            GADetailsCategory.events.journeyReportActionButton,
            'journey report action button',
            1,
        );
        reportCustomerJourneyAction(data);
    };

    const isVisibleCreateCustomerJourneyVisualization = () =>
        !HasVisualization && !isInvalid && isCJ && isVisibleActionButtons;
    const isVisibleEditCustomerJourneyVisualization = () =>
        HasVisualization && !isInvalid && isCJ && isVisibleActionButtons;

    return (
        <>
            {isVisibleDelete && (
                <Tooltip text={t(l.DeleteCustomerJourney)}>
                    <Button
                        icon={ActionsIcons.Delete}
                        appearance="minimal"
                        color="primary"
                        onClick={() => deleteCustomerJourneyAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleEdit && (
                <Tooltip text={t(l.EditCustomerJourney)}>
                    <Button
                        icon={ActionsIcons.Edit}
                        appearance="minimal"
                        color="primary"
                        onClick={() => editCustomerJourneyAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleEditCustomerJourneyVisualization() && (
                <AccessControl permissions={PERMISSIONS.ManageWorkflow}>
                    <Tooltip text={t(l.EditCustomerJourneyVisualization)}>
                        <Button
                            icon={ActionsIcons.Visualization}
                            appearance="minimal"
                            color="primary"
                            onClick={() => editCustomerJourneyVisualizationAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleArchive() && (
                <AccessControl permissions={PERMISSIONS.ManageWorkflow}>
                    <Tooltip text={t(l.ArchiveCustomerJourney)}>
                        <Button
                            icon={ActionsIcons.Archive}
                            appearance="minimal"
                            color="primary"
                            onClick={() => archiveCustomerJourneyAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleUnArchive() && (
                <AccessControl permissions={PERMISSIONS.ManageWorkflow}>
                    <Tooltip text={t(l.UnarchiveCustomerJourney)}>
                        <Button
                            icon={ActionsIcons.UnArchive}
                            appearance="minimal"
                            color="primary"
                            onClick={() => unArchiveCustomerJourneyAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleView() && (
                <AccessControl permissions={PERMISSIONS.ManageWorkflow}>
                    <Tooltip text={t(l.ViewCustomerJourney)}>
                        <Button
                            icon={ActionsIcons.ViewDoc}
                            appearance="minimal"
                            color="primary"
                            onClick={() => viewCustomerJourneyAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleCreateCustomerJourneyVisualization() && (
                <AccessControl permissions={PERMISSIONS.ManageWorkflow}>
                    <Tooltip text={t(l.CreateCustomerJourneyVisualization)}>
                        <Button
                            icon={ActionsIcons.Visualization}
                            appearance="minimal"
                            color="primary"
                            onClick={() => createCustomerJourneyVisualizationAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleReport() && (
                <AccessControl permissions={PERMISSIONS.ManageWorkflow}>
                    <Tooltip text={t(l.CustomerJourneyReport)}>
                        <Button
                            icon={ActionsIcons.ViewReport}
                            appearance="minimal"
                            color="primary"
                            onClick={onReportAction}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleStart() && (
                <Tooltip text={t(l.StartCustomerJourney)}>
                    <Button
                        icon={ActionsIcons.Start}
                        appearance="minimal"
                        color="primary"
                        onClick={() => startCustomerJourneyAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleStopSend() && (
                <Tooltip text={t(l.StopCustomerJourney)}>
                    <Button
                        icon={ActionsIcons.StatusStop}
                        appearance="minimal"
                        color="primary"
                        onClick={() => stopSendCustomerJourneyAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleResume() && (
                <Tooltip text={t(l.ResumeCustomerJourney)}>
                    <Button
                        icon={ActionsIcons.Resume}
                        appearance="minimal"
                        color="primary"
                        onClick={() => resumeCustomerJourneyAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleClone && (
                <Tooltip text={t(l.CloneCustomerJourney)}>
                    <Button
                        icon={ActionsIcons.Clone}
                        appearance="minimal"
                        color="primary"
                        onClick={() => cloneCustomerJourneyAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleInfo() && (
                <Tooltip text={t(l.CJInfo)}>
                    <Button
                        icon={ActionsIcons.Stop}
                        appearance="minimal"
                        color="primary"
                        onClick={() => infoCustomerJourneyAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleActivityTimeline() && (
                <Tooltip text={t(l.ActivityTimeline)}>
                    <Button
                        icon="bc-icon-log-history"
                        appearance="minimal"
                        color="primary"
                        onClick={() => activityTimelineAction(data)}
                    />
                </Tooltip>
            )}
        </>
    );
};

CustomerJourneyActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    unArchiveCustomerJourneyAction: PropTypes.func,
    archiveCustomerJourneyAction: PropTypes.func,
    deleteCustomerJourneyAction: PropTypes.func,
    cloneCustomerJourneyAction: PropTypes.func,
    stopSendCustomerJourneyAction: PropTypes.func,
    startCustomerJourneyAction: PropTypes.func,
    editCustomerJourneyAction: PropTypes.func,
    viewCustomerJourneyAction: PropTypes.func,
    resumeCustomerJourneyAction: PropTypes.func,
    infoCustomerJourneyAction: PropTypes.func,
    reportCustomerJourneyAction: PropTypes.func,
    createCustomerJourneyVisualizationAction: PropTypes.func,
    editCustomerJourneyVisualizationAction: PropTypes.func,
    activityTimelineAction: PropTypes.func,
    isViewPage: PropTypes.bool,
};

CustomerJourneyActionsButtons.defaultProps = {
    unArchiveCustomerJourneyAction: noop,
    archiveCustomerJourneyAction: noop,
    deleteCustomerJourneyAction: noop,
    cloneCustomerJourneyAction: noop,
    editCustomerJourneyAction: noop,
    stopSendCustomerJourneyAction: noop,
    startCustomerJourneyAction: noop,
    viewCustomerJourneyAction: noop,
    resumeCustomerJourneyAction: noop,
    infoCustomerJourneyAction: noop,
    reportCustomerJourneyAction: noop,
    createCustomerJourneyVisualizationAction: noop,
    editCustomerJourneyVisualizationAction: noop,
    activityTimelineAction: noop,
    isViewPage: false,
};

export default memo(CustomerJourneyActionsButtons);
