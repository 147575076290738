import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop, isNil, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { CJDaysLaterOptionValidationSchema } from 'validators/schemas.validation';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Components
import { CJCascadeDropdown } from 'components/CustomerJourney';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
//.Import Constants
import { l } from 'constants/common';

const DaysLaterForm = ({ defaultValue, getUpdate, ...rest }) => {
    const { t } = useTranslation();

    const [isValid, setIsValid] = useState(null);

    const formik = useFormik({
        initialValues: { property: defaultValue?.property || {}, offset: defaultValue?.offset || 0 },
        onSubmit: noop,
        validationSchema: CJDaysLaterOptionValidationSchema(rest?.option),
    });
    const { values, setFieldValue, errors } = formik;
    const { property, offset } = values;

    const cascadeDropdownChangeHandler = ({ parentValue, value, logicFunction }) => {
        getUpdate({ property: { parentValue, value, logicFunction } });
        setFieldValue('property', { parentValue, value, logicFunction });
    };

    const onChangeHandler = ({ target }) => {
        let replacedValue = target?.value.replace('.', '');
        replacedValue = replacedValue?.replace('-', '');
        const tempValue = isNil(replacedValue) || isEmpty(replacedValue) ? null : +replacedValue;

        setFieldValue('offset', tempValue);
        getUpdate({ offset: tempValue });
    };

    useEffect(() => {
        getUpdate({
            property,
            offset,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsValid((prev) => ({ ...prev, offset: !errors?.offset }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        let tmpIsValid = true;
        if (!isNil(isValid)) {
            tmpIsValid =
                tmpIsValid &&
                (isNil(isValid?.offset) ? true : isValid.offset) &&
                (isNil(isValid?.right) ? true : isValid.right);
        }
        getUpdate({ isValid: tmpIsValid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    return (
        <>
            <ExtendedInput
                type="number"
                value={offset}
                onChange={onChangeHandler}
                placeholder={l.Offset}
                label={l.Offset}
                labelAppearance="swap"
                isValid={!errors?.offset}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.offset)}
            />
            <CJCascadeDropdown
                onChange={cascadeDropdownChangeHandler}
                value={{ ...property }}
                onValidationChange={(isValid) => setIsValid((prev) => ({ ...prev, right: isValid }))}
                {...rest}
            />
        </>
    );
};

DaysLaterForm.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getUpdate: PropTypes.func.isRequired,
};

export default memo(DaysLaterForm);
