import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import Services
import { getFormContent } from 'services/customerJourney';
// Import Constants
import {
    l,
    CJArgumentValuesTypes,
    excludeRightValueContentVisibilitiesOperators,
    defaultLogicFunction,
} from 'constants/common';
import { formsTypes, operatorsLabels } from 'components/CustomerJourney/config';
// Import Components
import { CJInfoBlockLabelValueWrapper, CJReferenceInArgumentInfoBlock } from 'components/CustomerJourney/infoBlocks';

const CJFilterInfo = ({ filter, elements, option, blockId, index }) => {
    const { t } = useTranslation();

    const block = elements.find((el) => el?.id === filter?.property?.parentValue);

    let logicFunction = null;
    let property = null;
    let properties = block?.data?.metaData?.Properties;

    if (filter?.property?.parentValue === blockId && !isNil(option.SelfModelTypeName)) {
        properties = option?.SelfModelProperties?.Properties;
    }

    if (!isNil(properties)) {
        property = properties.find((property) => property?.Name === filter?.property?.value);
        if (!isNil(property?.Functions)) {
            logicFunction = property.Functions.find(
                (logicFunction) => logicFunction.value === filter?.property?.logicFunction,
            );
        }
    }

    const { infoComponent: Component, mapToUIModel } = getFormContent(
        logicFunction?.returnType?.BaseTypes ?? property?.BaseTypes,
        formsTypes,
    );

    const resultData = mapToUIModel(filter?.rightValue);

    return (
        <div key={index} className={'cj-filter-condition-info-block-property-wrapper'}>
            <div className={'cj-filter-condition-info-block-property-wrapper_headline'}>
                <p className={'cj-filter-condition-info-block-property-wrapper_headline_title'}>
                    {t(l.Filter) + ' ' + (index + 1)}
                </p>
            </div>

            <div>
                <div className="crm-filter-condition-info-block-value-item-panel">
                    <span>{t(l.FirstOperand)}</span>
                    <div>
                        <CJInfoBlockLabelValueWrapper label={t(l.Block)} value={block?.data?.name} />
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.Property)}
                            value={t(property?.CustomAttributes?.DisplayName)}
                        />
                        {!isNil(logicFunction) && logicFunction.value !== defaultLogicFunction && (
                            <CJInfoBlockLabelValueWrapper label={t(l.Function)} value={t(logicFunction?.label)} />
                        )}
                    </div>
                </div>

                <div className="crm-filter-condition-info-block-value-item-panel">
                    <span>{t(l.Operator)}</span>
                    <div>
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.SelectedOperator)}
                            value={t(operatorsLabels[filter?.operator])}
                        />
                    </div>
                </div>

                <div className="crm-filter-condition-info-block-value-item-panel">
                    <span>{t(l.SecondOperand)}</span>
                    <div>
                        {!excludeRightValueContentVisibilitiesOperators.includes(filter?.operator) && (
                            <div className={'cj-filter-condition-info-block-right-value-wrapper'}>
                                {resultData.argumentInType === CJArgumentValuesTypes.ReferenceInArgument ? (
                                    <CJReferenceInArgumentInfoBlock
                                        data={resultData}
                                        elements={elements}
                                        option={option}
                                        blockId={blockId}
                                    />
                                ) : (
                                    <Component
                                        data={resultData}
                                        customAttributes={
                                            logicFunction?.returnType?.CustomAttributes ?? property?.CustomAttributes
                                        }
                                        elements={elements}
                                        option={{
                                            ...property,
                                            BaseTypes: logicFunction?.returnType?.BaseTypes ?? property?.BaseTypes,
                                        }}
                                        blockId={blockId}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

CJFilterInfo.propTypes = {
    filter: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

export default CJFilterInfo;
