import {
    FilterHolderFields,
    SegmentTypes,
    EntityStates,
    FilterComparisions,
    CustomersTypes,
    PERMISSIONS,
    l,
    ArchiveStates,
} from 'constants/common';

const { Equals, Like, Between, In, IsBlank } = FilterComparisions;
const { textInput, select, rangeDate, multiSelect } = FilterHolderFields;

const getTitle = (row) => {
    return row.data.Name.Name;
};

const FilterHolderFieldsConfigs = {
    storeKey: 'segmentList',
    idFieldName: 'SegmentId',
    defaultInputsData: {
        defaultInputsDataIds: {
            CategoryId: 4,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'SegmentId',
                comparision: Equals,
                displayName: l.SegmentId,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Name',
                comparision: Like,
                displayName: l.Title,
                value: textInput.defaultValue,
            },
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedBy.Name',
                comparision: Like,
                displayName: l.User,
                value: textInput.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'IsUsed',
                comparision: Equals,
                displayName: l.State,
                value: select.defaultValue,
                data: EntityStates,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CategoryId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: Equals,
                displayName: l.Label,
                value: multiSelect.defaultValue,
                data: [],
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'CreatedDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.CreatedDate,
                value: rangeDate.defaultValue,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Type',
                isMultiSelect: true,
                comparision: In,
                disabled: false,
                hasSearch: true,
                displayName: l.Type,
                data: SegmentTypes,
                value: multiSelect.defaultValue,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'TargetObject',
                comparision: In,
                displayName: l.CustomersType,
                value: select.defaultValue,
                data: CustomersTypes,
                permission: PERMISSIONS.ManageNonCustomer,
            },
            {
                type: select.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ArchivedDate',
                comparision: IsBlank,
                displayName: l.Archive,
                value: select.defaultValue,
                data: ArchiveStates,
            },
        ],
    },
};

export { FilterHolderFieldsConfigs, getTitle };
