import React, { memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
// Import UI Components
import { Tooltip, Button, Popover, SkeletonLoader } from '@geneui/components';
// Import Components
import OptionListContent from './OptionListContent';
// Import Hooks
import { useOnClickOutside } from 'hooks';
// Import SCSS
import 'assets/scss/optionList.scss';

const OptionList = ({
    optionLabel,
    placeholder,
    toggleHandler,
    isVisible,
    setIsVisible,
    tooltipText,
    popoverClassName,
    buttonClassName,
    buttonWrapperClassName,
    popoverMinHeight,
    popoverMaxHeight,
    contentWrapperClassName,
    openIcon,
    closeIcon,
    clearable,
    onClear,
    isLoading,
    isLoadingList,
    isValid,
    errorText,
    ...rest
}) => {
    const optionListRef = useRef(null);

    useOnClickOutside(
        optionListRef,
        useCallback(() => {
            if (isVisible) {
                setIsVisible(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isVisible]),
    );

    const clearClickHandler = (e) => {
        e.stopPropagation();
        onClear(e);
    };

    return (
        <SkeletonLoader isBusy={isLoading}>
            {!isLoading && (
                <div className="crm-cj-visualization-step-dropdown-item" ref={optionListRef}>
                    <Popover
                        isOpen={isVisible}
                        extendTargetWidth={false}
                        className={classNames('crm-option-list-popover', { [popoverClassName]: popoverClassName })}
                        minHeight={popoverMinHeight}
                        maxHeight={popoverMaxHeight}
                        scrollbarNeeded={false}
                        align="end"
                    >
                        <div
                            className={classNames('crm-option-list-content-wrapper', {
                                [contentWrapperClassName]: contentWrapperClassName,
                            })}
                        >
                            <div className={buttonWrapperClassName}>
                                <Tooltip className="crm-c-tooltip" text={tooltipText} position={'bottom'}>
                                    <Button
                                        className={classNames('crm-option-list-content__toggle-button', {
                                            [buttonClassName]: buttonClassName,
                                            'crm-option-list-content__is-not-valid': !isValid,
                                        })}
                                        onClick={toggleHandler}
                                        icon={isVisible ? closeIcon : openIcon}
                                    >
                                        {isEmpty(optionLabel) ? placeholder : optionLabel}
                                        {clearable && !isEmpty(optionLabel) && (
                                            <Button
                                                color="default"
                                                appearance="minimal"
                                                icon="bc-icon-clear-small"
                                                onClick={clearClickHandler}
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                                <span className="crm-option-list-content-wrapper__error-text">{errorText}</span>
                            </div>
                            {isVisible && <OptionListContent {...rest} isLoading={isLoadingList} />}
                        </div>
                    </Popover>
                </div>
            )}
        </SkeletonLoader>
    );
};

OptionList.propTypes = {
    optionLabel: PropTypes.any,
    placeholder: PropTypes.any,
    toggleHandler: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    setIsVisible: PropTypes.func,
    tooltipText: PropTypes.string,
    popoverClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonWrapperClassName: PropTypes.string,
    popoverMinHeight: PropTypes.number,
    popoverMaxHeight: PropTypes.number,
    openIcon: PropTypes.string,
    closeIcon: PropTypes.string,
    contentWrapperClassName: PropTypes.string,
    onClear: PropTypes.func,
    clearable: PropTypes.bool,
    isValid: PropTypes.bool,
    errorText: PropTypes.string,
    isLoadingList: PropTypes.bool,
    isLoading: PropTypes.bool,
};

OptionList.defaultProps = {
    optionLabel: '',
    placeholder: '',
    tooltipText: '',
    isVisible: false,
    setIsVisible: noop,
    popoverClassName: '',
    buttonClassName: '',
    buttonWrapperClassName: '',
    popoverMinHeight: 332,
    popoverMaxHeight: 352,
    openIcon: 'bc-icon-arrow-down',
    closeIcon: 'bc-icon-arrow-up',
    onClear: noop,
    clearable: false,
    contentWrapperClassName: '',
    isValid: true,
    errorText: '',
    isLoading: false,
    isLoadingList: false,
};

export default memo(OptionList);
