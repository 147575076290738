import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
// Import UI Components
import { Icon, Button } from '@geneui/components';
import { AccessControl, AwardCEModal } from 'components';
// Import Components
import MenuItemCircle from './MenuItemCircle';
import PredefinedTemplateListMenu from './PredefinedTemplateListMenu';
import PredefinedSegmentListMenu from './PredefinedSegmentListMenu';
import PredefinedCustomerJourneyListMenu from './PredefinedCustomerJourneyListMenu';
// Import Hooks
import { useGACustomEvent, useGoToRoute } from 'hooks';
// Import Constants
import { CreatePopoverViews, PageTypes, l, PERMISSIONS, GACategoryEvents } from 'constants/common';
import { CreateEntitiesList } from './constants';

const { GACreateCategory } = GACategoryEvents;

const {
    mainView,
    segmentView,
    predefinedSegmentView,
    templateView,
    predefinedTemplateView,
    customerJourneyView,
    predefinedCustomerJourneyView,
    awardView,
    reportView,
    socialTemplateView,
} = CreatePopoverViews;

const Create = ({
    setCreateVisibilityStatus,
    initialContentView,
    predefinedInfoModalVisibleState,
    setPredefinedInfoModalVisibleState,
    callback,
    ...rest
}) => {
    const { t } = useTranslation();

    const { goToRoute } = useGoToRoute();
    const { logCustomEvent } = useGACustomEvent();
    const {
        mainViewChildren,
        segmentViewChildren,
        templateViewChildren,
        customerJourneyViewChildren,
        awardsViewChildren,
        reportViewChildren,
        socialTemplateViewChildren,
    } = CreateEntitiesList;

    const [createPopoverView, setCreatePopoverView] = useState(initialContentView);
    const isVisibleBackButton = createPopoverView !== initialContentView;
    const gaPrefix = initialContentView === mainView ? 'navigation' : 'page';

    const mainViewContent = mainViewChildren.map(
        ({ iconClass, routeModel, hasChildren, contentViewKey, displayNameKey, permission }) => {
            const clickHandler = (e) => {
                e.stopPropagation();
                logCustomEvent(
                    GACreateCategory.name,
                    GACreateCategory.events[`${gaPrefix}${displayNameKey}Group`],
                    displayNameKey,
                    1,
                );

                if (hasChildren === true) {
                    setCreatePopoverView(contentViewKey);
                } else {
                    setCreateVisibilityStatus(false);
                    goToRoute(routeModel);
                }
            };

            return (
                <AccessControl key={`main-${displayNameKey}`} permissions={permission}>
                    <MenuItemCircle
                        iconClass={iconClass}
                        displayNameKey={t(displayNameKey)}
                        clickHandler={clickHandler}
                    />
                </AccessControl>
            );
        },
    );

    const segmentViewContent = segmentViewChildren.mainGroup.map(
        ({ iconClass, displayNameKey, routeModel, permission }) => {
            const clickHandler = () => {
                logCustomEvent(
                    GACreateCategory.name,
                    GACreateCategory.events[`${gaPrefix}${displayNameKey}Segment`],
                    displayNameKey,
                    1,
                );
                setCreateVisibilityStatus(false);
                goToRoute(routeModel);
            };

            return (
                <AccessControl key={`segment-${displayNameKey}`} permissions={permission}>
                    <MenuItemCircle
                        iconClass={iconClass}
                        displayNameKey={t(displayNameKey)}
                        clickHandler={clickHandler}
                    />
                </AccessControl>
            );
        },
    );

    const reportViewContent = reportViewChildren.mainGroup.map(
        ({ iconClass, displayNameKey, routeModel, permission, mandatoryPermissions }) => {
            const clickHandler = () => {
                logCustomEvent(
                    GACreateCategory.name,
                    GACreateCategory.events[`${gaPrefix}${displayNameKey}`],
                    displayNameKey,
                    1,
                );
                setCreateVisibilityStatus(false);
                goToRoute(routeModel);
            };

            return (
                <AccessControl
                    key={`report-${displayNameKey}`}
                    permissions={permission}
                    mandatoryPermissions={mandatoryPermissions}
                >
                    <MenuItemCircle
                        iconClass={iconClass}
                        displayNameKey={t(displayNameKey)}
                        clickHandler={clickHandler}
                    />
                </AccessControl>
            );
        },
    );

    const templateViewContent = templateViewChildren.mainGroup.map(
        ({ iconClass, displayNameKey, routeModel, partnerSetting, hasChildren, contentViewKey }) => {
            const clickHandler = (e) => {
                e.stopPropagation();
                logCustomEvent(
                    GACreateCategory.name,
                    GACreateCategory.events[`${gaPrefix}${displayNameKey}Template`],
                    displayNameKey,
                    1,
                );

                if (hasChildren === true) {
                    setCreatePopoverView(contentViewKey);
                } else {
                    setCreateVisibilityStatus(false);
                    goToRoute(routeModel);
                }
            };

            return (
                <AccessControl key={`template-${displayNameKey}`} partnerSetting={partnerSetting}>
                    <MenuItemCircle
                        iconClass={iconClass}
                        displayNameKey={t(displayNameKey)}
                        clickHandler={clickHandler}
                    />
                </AccessControl>
            );
        },
    );

    const socialTemplateViewContent = socialTemplateViewChildren.mainGroup.map(
        ({ iconClass, displayNameKey, routeModel, partnerSetting }) => {
            const clickHandler = () => {
                logCustomEvent(
                    GACreateCategory.name,
                    GACreateCategory.events[`${gaPrefix}${displayNameKey}Template`],
                    displayNameKey,
                    1,
                );
                setCreateVisibilityStatus(false);
                goToRoute(routeModel);
            };

            return (
                <AccessControl key={`social-${displayNameKey}`} partnerSetting={partnerSetting}>
                    <MenuItemCircle
                        iconClass={iconClass}
                        displayNameKey={t(displayNameKey)}
                        clickHandler={clickHandler}
                    />
                </AccessControl>
            );
        },
    );

    const customerJourneyViewContent = customerJourneyViewChildren.mainGroup.map(
        ({ iconClass, displayNameKey, routeModel, permission }) => {
            const clickHandler = () => {
                logCustomEvent(
                    GACreateCategory.name,
                    GACreateCategory.events[`${gaPrefix}${displayNameKey}Promotion`],
                    displayNameKey,
                    1,
                );
                setCreateVisibilityStatus(false);
                goToRoute(routeModel);
            };
            return (
                <AccessControl key={`customerJourney-${displayNameKey}`} permissions={permission}>
                    <MenuItemCircle
                        iconClass={iconClass}
                        displayNameKey={t(displayNameKey)}
                        clickHandler={clickHandler}
                    />
                </AccessControl>
            );
        },
    );

    // TODO: need refactor all award create edit logic including award modals
    const [selectedAwardType, setSelectedAwardType] = useState(null);

    const awardsViewContent = awardsViewChildren.mainGroup.map(
        ({ iconClass, displayNameKey, partnerSetting, permissions }) => {
            const clickHandler = (key) => {
                setSelectedAwardType(key);
                rest?.setIsAwardModalOpenedState && rest.setIsAwardModalOpenedState(true);
            };
            return (
                <AccessControl
                    key={`awards-${displayNameKey}`}
                    partnerSetting={partnerSetting}
                    permissions={permissions}
                >
                    <MenuItemCircle
                        iconClass={iconClass}
                        displayNameKey={t(displayNameKey)}
                        clickHandler={() => clickHandler(displayNameKey)}
                    />
                </AccessControl>
            );
        },
    );

    const renderSwitch = () => {
        switch (createPopoverView) {
            case mainView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            <span>{t(l.Create)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">{mainViewContent}</div>
                    </>
                );
            case segmentView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            {isVisibleBackButton && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(mainView);
                                    }}
                                    className="back-btn"
                                >
                                    <Icon type={'bc-icon-arrow-back'} />
                                </button>
                            )}
                            <span>{t(l.ChooseSegment)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">{segmentViewContent}</div>
                        <div className="crm-h-c-cnt-footer">
                            <div className="devider-with-text">{t(l.Or)}</div>
                            <div className="crm-h-c-cnt-f-inner">
                                <AccessControl permissions={PERMISSIONS.ViewSegment}>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setCreatePopoverView(predefinedSegmentView);
                                        }}
                                        color="default"
                                        flexibility="full-width"
                                        appearance={'grayscale'}
                                    >
                                        {t(l.CreateFromPredefinedSegment)}
                                    </Button>
                                </AccessControl>
                            </div>
                        </div>
                    </>
                );
            case templateView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            {isVisibleBackButton && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(mainView);
                                    }}
                                    className="back-btn"
                                >
                                    <Icon type={'bc-icon-arrow-back'} />
                                </button>
                            )}
                            <span>{t(l.ChooseTemplate)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">{templateViewContent}</div>
                        <div className="crm-h-c-cnt-footer">
                            <div className="devider-with-text">{t(l.Or)}</div>
                            <div className="crm-h-c-cnt-f-inner">
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(predefinedTemplateView);
                                    }}
                                    color="default"
                                    flexibility="full-width"
                                    appearance={'grayscale'}
                                >
                                    {t(l.CreateFromPredefinedTemplate)}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            case customerJourneyView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            {isVisibleBackButton && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(mainView);
                                    }}
                                    className="back-btn"
                                >
                                    <Icon type={'bc-icon-arrow-back'} />
                                </button>
                            )}
                            <span>{t(l.ChoosePromotionType)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">{customerJourneyViewContent}</div>
                        <div className="crm-h-c-cnt-footer">
                            <div className="devider-with-text">{t(l.Or)}</div>
                            <div className="crm-h-c-cnt-f-inner">
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(predefinedCustomerJourneyView);
                                    }}
                                    color="default"
                                    flexibility="full-width"
                                    appearance={'grayscale'}
                                >
                                    {t(l.CreateFromPredefinedCustomerJourney)}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            case predefinedSegmentView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCreatePopoverView(segmentView);
                                }}
                                className="back-btn"
                            >
                                <Icon type={'bc-icon-arrow-back'} />
                            </button>
                            <span>{t(l.PredefinedSegments)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">
                            <PredefinedSegmentListMenu
                                handleClose={() => {
                                    setCreateVisibilityStatus(false);
                                }}
                                predefinedInfoModalVisibleState={predefinedInfoModalVisibleState}
                                setPredefinedInfoModalVisibleState={setPredefinedInfoModalVisibleState}
                            />
                        </div>
                    </>
                );
            case predefinedTemplateView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCreatePopoverView(templateView);
                                }}
                                className="back-btn"
                            >
                                <Icon type={'bc-icon-arrow-back'} />
                            </button>
                            <span>{t(l.PredefinedTemplates)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">
                            <PredefinedTemplateListMenu
                                handleClose={() => {
                                    setCreateVisibilityStatus(false);
                                }}
                                predefinedInfoModalVisibleState={predefinedInfoModalVisibleState}
                                setPredefinedInfoModalVisibleState={setPredefinedInfoModalVisibleState}
                            />
                        </div>
                    </>
                );
            case predefinedCustomerJourneyView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCreatePopoverView(customerJourneyView);
                                }}
                                className="back-btn"
                            >
                                <Icon type={'bc-icon-arrow-back'} />
                            </button>
                            <span>{t(l.PredefinedCustomerJourneys)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">
                            <PredefinedCustomerJourneyListMenu
                                handleClose={() => {
                                    setCreateVisibilityStatus(false);
                                }}
                                predefinedInfoModalVisibleState={predefinedInfoModalVisibleState}
                                setPredefinedInfoModalVisibleState={setPredefinedInfoModalVisibleState}
                            />
                        </div>
                    </>
                );
            case awardView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            {isVisibleBackButton && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(mainView);
                                    }}
                                    className="back-btn"
                                >
                                    <Icon type={'bc-icon-arrow-back'} />
                                </button>
                            )}
                            <span>{t(l.ChooseAwardType)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">{awardsViewContent}</div>
                        {!isNil(selectedAwardType) && (
                            <AwardCEModal
                                type={PageTypes.create}
                                bonusType={selectedAwardType}
                                setIsCEAwardModalOpenedState={() => {
                                    setSelectedAwardType(null);
                                    setCreateVisibilityStatus(false);
                                    rest?.setIsAwardModalOpenedState && rest.setIsAwardModalOpenedState(false);
                                }}
                                getTableRefreshFn={callback}
                            />
                        )}
                    </>
                );
            case reportView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            {isVisibleBackButton && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(mainView);
                                    }}
                                    className="back-btn"
                                >
                                    <Icon type={'bc-icon-arrow-back'} />
                                </button>
                            )}
                            <span>{t(l.ChooseReport)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">{reportViewContent}</div>
                    </>
                );
            case socialTemplateView:
                return (
                    <>
                        <div className="crm-h-c-cnt-head">
                            {isVisibleBackButton && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCreatePopoverView(templateView);
                                    }}
                                    className="back-btn"
                                >
                                    <Icon type={'bc-icon-arrow-back'} />
                                </button>
                            )}
                            <span>{t(l.ChooseTemplate)}</span>
                        </div>
                        <div className="crm-h-c-cnt-content">{socialTemplateViewContent}</div>
                    </>
                );
            default:
                return '';
        }
    };

    return <div className="crm-header-create-cnt">{renderSwitch()}</div>;
};

Create.defaultProps = {
    initialContentView: mainView,
    callback: noop,
};

Create.propTypes = {
    setNavigation: PropTypes.func.isRequired,
    setCreateVisibilityStatus: PropTypes.func.isRequired,
    initialContentView: PropTypes.string,
    predefinedInfoModalVisibleState: PropTypes.bool.isRequired,
    setPredefinedInfoModalVisibleState: PropTypes.func.isRequired,
    callback: PropTypes.func,
};

export default memo(Create);
