/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Import UI components
import { Icon, SkeletonLoader, Switcher } from '@geneui/components';
// Import constants
import { TemplateTypesLabels, l } from 'constants/common';
import { channelIcons } from './config';

const ChannelWidget = ({
    data,
    onClickHandler,
    switcherChangeHandler,
    isLoadingTemplates,
    isLoadingProviders,
    isSelected,
}) => {
    const { t } = useTranslation();
    //TODO remove onClick from div
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={classNames('channel-tab-holder', { selected: isSelected })} onClick={onClickHandler}>
            {!isNil(data) && (
                <div className="channel-tab-inner">
                    <div className="channel-tab-head">
                        <div className="channel-tab-icon">
                            <Icon type={channelIcons[data.Channel]} />
                        </div>
                        <p className="campaign-tab-heading">{t(TemplateTypesLabels[data.Channel])}</p>
                    </div>
                    <Switcher checked={data.IsEnabled} onChange={switcherChangeHandler} />
                    {isLoadingTemplates ? (
                        <SkeletonLoader isBusy={true} height="20px" />
                    ) : isNil(data.TemplateName) ? (
                        <p className="campaign-tab-desc">{t(l.TemplateDescription)}</p>
                    ) : (
                        <p className="campaign-tab-title">
                            {l.SelectedTemplateName}: {data.TemplateName}
                        </p>
                    )}

                    {isLoadingProviders ? (
                        <SkeletonLoader isBusy={true} height="20px" />
                    ) : (
                        !isNil(data.ProviderName) && (
                            <p className="campaign-tab-title">
                                {l.SelectedProviderName}: {data.ProviderName}
                            </p>
                        )
                    )}
                </div>
            )}
        </div>
    );
};

ChannelWidget.propTypes = {
    data: PropTypes.object,
    onClickHandler: PropTypes.func,
    switcherChangeHandler: PropTypes.func,
    isLoadingTemplates: PropTypes.bool,
    isLoadingProviders: PropTypes.bool,
    isSelected: PropTypes.bool,
};

ChannelWidget.defaultProps = {
    data: null,
};

export default memo(ChannelWidget);
