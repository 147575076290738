import React, { memo, useEffect, useRef, useState } from 'react';

const FTNRate = () => {
    const templateRef = useRef(null);
    const [iframeContent, setIframeContent] = useState('');

    useEffect(() => {
        setIframeContent(templateRef.current.innerHTML);
    }, []);

    return (
        <>
            {!iframeContent && (
                <script type="text/template" ref={templateRef}>
                    <html lang="en" style={{ 'font-size': '10px' }}>
                        <head>
                            <meta charset="UTF-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <title>Iframe Content</title>
                            <link
                                rel="stylesheet"
                                href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
                            />
                        </head>
                        <body
                            style={{
                                'font-size': '1.4rem',
                                display: 'flex',
                                'justify-content': 'end',
                                'font-family': "'Open Sans', sans-serif",
                            }}
                        >
                            <ftn-rate
                                link="https://exchange.fastex.com/"
                                update-period="10000"
                                is-icon-visible="false"
                                style={{
                                    '--font-family': '"Open Sans", "Arial", "Helvetica Neue", sans-serif',
                                    '--ftn-rate-widget-color': '#fff',
                                    '--ftn-rate-widget-font-size': '1.4rem/2rem',
                                    '--ftn-rate-widget-font-family': 'var(--font-family)',
                                }}
                            />

                            <script src="https://ftnratewidget.fjykjczttspydxcx.com/"></script>
                        </body>
                    </html>
                </script>
            )}

            <iframe
                title="FTNRate"
                srcDoc={iframeContent}
                style={{
                    width: '155px',
                    height: '50px',
                }}
            ></iframe>
        </>
    );
};

export default memo(FTNRate);
