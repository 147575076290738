import { isNull, keys } from 'lodash';
// Import Constants
import { ErrorShowType, l, TemplateInputTypes } from 'constants/common';
// Import Services
import { TemplateSymbolsCountFields } from 'services/template';

const bodyScenario = (state) => ({
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'maxLengthWithRegEx',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: { ...state, fieldName: 'Body', count: TemplateSymbolsCountFields.PushNotification },
        },
    ],
});

const subjectScenario = {
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'minLength',
            ErrorMessage: l.MustContainMinimumCharacters,
            ParametersValues: '2',
        },
        {
            ValidationType: 'maxLength',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: '100',
        },
    ],
};

const pushNotificationTemplateValidationScenario = (state) => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    AppType: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
        ],
    },
    Route: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
        ],
    },
    ExtraInfo: {
        isValid: null,
        value: false,
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: '',
                ParametersValues: 'false',
            },
        ],
    },
    CrossValidation: {
        isValid: null,
        errorText: '',
        value: {},
        type: ErrorShowType.Toaster,
        validationCredentials: [
            {
                ValidationType: 'templateCrossValidation',
                ErrorMessage: '',
                ParametersValues: [TemplateInputTypes.Body, TemplateInputTypes.TitleOfPushNotification],
            },
        ],
    },
    defaultLangBody: { ...bodyScenario(state) },
    defaultLangTitleOfPushNotification: { ...subjectScenario },
});

function pushNotificationTemplateScenarioProperiesCreater(data, editCloneMode, state) {
    if (editCloneMode) {
        return keys(data).reduce((acc, id) => {
            acc[`${id}Body`] = {
                ...bodyScenario(state),
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].Body,
            };
            acc[`${id}TitleOfPushNotification`] = {
                ...subjectScenario,
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].TitleOfPushNotification,
            };
            return acc;
        }, {});
    }
    return data.reduce((acc, id) => {
        acc[`${id}Body`] = { ...bodyScenario(state) };
        acc[`${id}TitleOfPushNotification`] = subjectScenario;
        return acc;
    }, {});
}

export { pushNotificationTemplateScenarioProperiesCreater, pushNotificationTemplateValidationScenario };
