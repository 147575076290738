import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, has, noop } from 'lodash';
// Import UI Components
import { TemplateInfoModal, TemplateTestSendModal, ConfirmationModal } from 'components';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { ModalsActions, l, ActionsIcons, ModalsClassNames } from 'constants/common';
// Import Services
import { TemplateHttpService } from 'services/http';
// Import Helpers
import { Helpers } from 'services';

// TODO: change all actions with service with doAction methods
const TemplateActionsModals = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { doPostRequest, doDeleteRequest } = useRequest();
    const { getUsedInConfirmationText } = Helpers;
    const {
        isDeleteModalOpenedState,
        isTestTemplateModalOpenedState,
        isArchiveModalOpenedState,
        isUnArchiveModalOpenedState,
        isInfoModalOpenedState,
        isUsedInConfirmationModalOpenedState,
        onCloseModalHandler,
        modalsStateSetDict,
        openedModalData,
        goToEditTemplate,
        providers,
    } = props;

    const { Name, TemplateId, ChildItemsInfo, DeliveryMethod, IsGlobal } = openedModalData;

    const {
        deleteTemplateRequest,
        deleteTemplatesRequest,
        archiveTemplateRequest,
        unarchiveTemplateRequest,
        archiveTemplatesRequest,
        unarchiveTemplatesRequest,
    } = useRef({
        deleteTemplateRequest: TemplateHttpService.deleteTemplate(),
        deleteTemplatesRequest: TemplateHttpService.deleteTemplates(),
        archiveTemplateRequest: TemplateHttpService.archiveTemplate(),
        archiveTemplatesRequest: TemplateHttpService.archiveTemplates(),
        unarchiveTemplateRequest: TemplateHttpService.unarchiveTemplate(),
        unarchiveTemplatesRequest: TemplateHttpService.unarchiveTemplates(),
    }).current;

    const deleteTemplate = () => {
        setIsLoading(true);
        doDeleteRequest(deleteTemplateRequest.request, {
            queryString: { templateId: openedModalData.TemplateId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDict.deleteTemplate.key, ModalsActions.DELETE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const deleteTemplates = () => {
        setIsLoading(true);
        const { allowDeleteTemplates } = openedModalData;
        if (isEmpty(allowDeleteTemplates)) {
            return onCloseModalHandler(modalsStateSetDict.deleteTemplate.key);
        }
        const allowDeleteTemplatesIds = allowDeleteTemplates.map(({ templateId }) => {
            return templateId;
        });
        doPostRequest(deleteTemplatesRequest.request, {
            requestBody: allowDeleteTemplatesIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.deleteTemplate.key,
                ModalsActions.DELETE,
                AlertType,
                AlertMessage,
                allowDeleteTemplatesIds.length,
            );
            setIsLoading(false);
        });
    };

    const archiveTemplate = () => {
        setIsLoading(true);
        doPostRequest(archiveTemplateRequest.request, {
            requestBody: null,
            queryString: { templateId: openedModalData.TemplateId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalsStateSetDict.archiveTemplate.key, ModalsActions.ARCHIVE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const archiveTemplates = () => {
        setIsLoading(true);
        const { allowArchiveTemplates } = openedModalData;
        if (isEmpty(allowArchiveTemplates)) {
            return onCloseModalHandler(modalsStateSetDict.archiveTemplate.key);
        }
        const allowArchiveTemplatesIds = allowArchiveTemplates.map(({ templateId }) => {
            return templateId;
        });
        doPostRequest(archiveTemplatesRequest.request, {
            requestBody: allowArchiveTemplatesIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.archiveTemplate.key,
                ModalsActions.ARCHIVE,
                AlertType,
                AlertMessage,
                allowArchiveTemplatesIds.length,
            );
            setIsLoading(false);
        });
    };

    const unarchiveTemplate = () => {
        setIsLoading(true);
        doPostRequest(unarchiveTemplateRequest.request, {
            requestBody: null,
            queryString: { templateId: openedModalData.TemplateId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.unarchiveTemplate.key,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const unarchiveTemplates = () => {
        setIsLoading(true);
        const { allowUnarchiveTemplates } = openedModalData;
        if (isEmpty(allowUnarchiveTemplates)) {
            return onCloseModalHandler(modalsStateSetDict.archiveTemplate.key);
        }
        const allowUnarchiveTemplatesIds = allowUnarchiveTemplates.map(({ templateId }) => {
            return templateId;
        });
        doPostRequest(unarchiveTemplatesRequest.request, {
            requestBody: allowUnarchiveTemplatesIds,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.unarchiveTemplate.key,
                ModalsActions.UNARCHIVE,
                AlertType,
                AlertMessage,
                allowUnarchiveTemplatesIds.length,
            );
            setIsLoading(false);
        });
    };

    const isBulkAction = () => {
        return (
            has(openedModalData, 'allowDeleteTemplates') ||
            has(openedModalData, 'allowArchiveTemplates') ||
            has(openedModalData, 'allowUnarchiveTemplates')
        );
    };

    const cleanUp = () => {
        return () => {
            deleteTemplateRequest.cancel('TemplateListPage:deleteTemplateRequest');
            archiveTemplateRequest.cancel('TemplateListPage:archiveTemplateRequest');
            unarchiveTemplateRequest.cancel('TemplateListPage:unarchiveTemplateRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        !isEmpty(openedModalData) && (
            <>
                {isDeleteModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? deleteTemplates : deleteTemplate}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowDeleteTemplates) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.deleteTemplate.key)}
                        isVisibleModal={isDeleteModalOpenedState}
                        titleText={isBulkAction() ? t(l.DeleteSelectedTemplates) : t(l.DeleteTemplate)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Delete}
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                        allowDataList={openedModalData.allowDeleteTemplates}
                        notAllowDataList={openedModalData.notAllowDeleteTemplates}
                        allowDataLabel={l.AllowDeleteSelectedTemplates}
                        notAllowDataLabel={l.NotAllowDeleteSelectedTemplates}
                        questionLabel={
                            isBulkAction()
                                ? l.DeleteSelectedTemplatesQuestion
                                : t(l.AreYouSureYouWantToDeleteTemplate, {
                                      templateName: `"${Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? archiveTemplates : archiveTemplate}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowArchiveTemplates) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.archiveTemplate.key)}
                        isVisibleModal={isArchiveModalOpenedState}
                        titleText={isBulkAction() ? t(l.ArchiveSelectedTemplates) : t(l.ArchiveTemplate)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Archive}
                        actionLabel={t(l.Archive)}
                        className={ModalsClassNames.Archive}
                        allowDataList={openedModalData.allowArchiveTemplates}
                        notAllowDataList={openedModalData.notAllowArchiveTemplates}
                        allowDataLabel={l.AllowArchiveSelectedTemplates}
                        notAllowDataLabel={l.NotAllowArchiveSelectedTemplates}
                        questionLabel={
                            isBulkAction()
                                ? l.ArchiveSelectedTemplatesQuestion
                                : t(l.AreYouSureYouWantToArchiveTemplate, {
                                      templateName: `"${Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isUnArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? unarchiveTemplates : unarchiveTemplate}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowUnarchiveTemplates) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.unarchiveTemplate.key)}
                        isVisibleModal={isUnArchiveModalOpenedState}
                        titleText={isBulkAction() ? t(l.UnArchiveSelectedTemplates) : t(l.UnArchiveTemplate)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.UnArchive}
                        actionLabel={t(l.Unarchive)}
                        className={ModalsClassNames.UnArchive}
                        allowDataList={openedModalData.allowUnarchiveTemplates}
                        notAllowDataList={openedModalData.notAllowUnarchiveTemplates}
                        allowDataLabel={l.AllowUnarchiveSelectedTemplates}
                        notAllowDataLabel={l.NotAllowUnarchiveSelectedTemplates}
                        questionLabel={
                            isBulkAction()
                                ? l.UnarchiveSelectedTemplatesQuestion
                                : t(l.AreYouSureYouWantToUnArchiveTemplate, {
                                      templateName: `"${Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}

                {isTestTemplateModalOpenedState && (
                    <TemplateTestSendModal
                        templateId={TemplateId}
                        templateType={DeliveryMethod}
                        handleClose={() => onCloseModalHandler(modalsStateSetDict.testTemplate.key)}
                        isVisible={isTestTemplateModalOpenedState}
                        providers={providers}
                    />
                )}

                {isUsedInConfirmationModalOpenedState && (
                    <ConfirmationModal
                        onOk={() => goToEditTemplate(openedModalData?.template)}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.editTemplate.key)}
                        isVisibleModal={isUsedInConfirmationModalOpenedState}
                        titleText={t(l.EditTemplate)}
                        questionLabel={getUsedInConfirmationText(
                            t,
                            l.Template,
                            openedModalData?.objects,
                            l.IsUsedInObjectsLabel,
                        )}
                        iconType={ActionsIcons.UsedIn}
                        actionLabel={t(l.Continue)}
                        className={ModalsClassNames.UsedIn}
                    />
                )}

                {isInfoModalOpenedState && (
                    <TemplateInfoModal
                        templateId={TemplateId}
                        handleClose={() => onCloseModalHandler(modalsStateSetDict.infoTemplate.key)}
                        isVisible={isInfoModalOpenedState}
                        childItemsInfo={ChildItemsInfo}
                        isGlobal={IsGlobal}
                    />
                )}
            </>
        )
    );
};

TemplateActionsModals.propTypes = {
    isDeleteModalOpenedState: PropTypes.bool.isRequired,
    isArchiveModalOpenedState: PropTypes.bool.isRequired,
    isUnArchiveModalOpenedState: PropTypes.bool.isRequired,
    isInfoModalOpenedState: PropTypes.bool.isRequired,
    isUsedInConfirmationModalOpenedState: PropTypes.bool.isRequired,
    isTestTemplateModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.object.isRequired,
    goToEditTemplate: PropTypes.func,
    providers: PropTypes.array,
};

TemplateActionsModals.defaultProps = {
    goToEditTemplate: noop,
    providers: [],
};

export default memo(TemplateActionsModals);
