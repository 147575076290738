/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { keys, noop } from 'lodash';
// Import UI components
import { Button, Icon, Paper } from '@geneui/components';
// Import Constants
import { SaveModes } from 'constants/common';
// Import SCSS
import 'assets/scss/buttonWithDropDown.scss';

const ButtonWithDropDown = ({ color, name, handleSave, buttonWithDropDownConfig, setSaveButtonLabel, disabled }) => {
    const { t } = useTranslation();
    const [openDropDown, setOpenDropDown] = useState(false);

    function handleIconClick() {
        setOpenDropDown(!openDropDown);
    }

    function handleAdditionalSettingChoose(label) {
        return () => {
            setOpenDropDown(false);
            setSaveButtonLabel(label);
            handleSave();
        };
    }

    function saveHandler() {
        setSaveButtonLabel(SaveModes.SaveAsLive);
        handleSave();
    }

    return (
        <div className="crm-button-with-dropdown">
            <Button size="default" color={color} onClick={saveHandler} disabled={disabled}>
                {name}
            </Button>
            <div className="crm-button-with-dropdown_icon-box">
                <Icon onClick={handleIconClick} type={`bc-icon-arrow-${!openDropDown ? 'down' : 'up'}`} />
            </div>
            {openDropDown && (
                <div className="crm-button-with-dropdown_dropdown-box">
                    {keys(buttonWithDropDownConfig).map((label, key) => (
                        // TODO: remove paper
                        <Paper
                            key={key}
                            onClick={handleAdditionalSettingChoose(label)}
                            className="crm-button-with-dropdown_dropdown-box_content-box"
                        >
                            <p>{t(label)}</p>
                        </Paper>
                    ))}
                </div>
            )}
        </div>
    );
};

ButtonWithDropDown.propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
    handleSave: PropTypes.func,
    buttonWithDropDownConfig: PropTypes.object,
    setSaveButtonLabel: PropTypes.func,
    disabled: PropTypes.bool,
};

ButtonWithDropDown.defaultProps = {
    color: 'confirm', // TODO: why this need
    disabled: true,
    additionalSettings: [],
    buttonWithDropDownConfig: {},
    handleSave: noop,
    setSaveButtonLabel: noop,
};

export default ButtonWithDropDown;
