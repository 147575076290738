import React, { memo, useEffect, useMemo, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';
// Import Routes
import { mainAppRoutes, notFoundRoute } from 'routes/config';
// Import Hooks
import { useRequest, useHasPermission, useMaintenanceSettings } from 'hooks';
// Import UI Components
import { BusyLoader } from '@geneui/components';
// Import Components
import { RouteLeaveConfirmation, NotActive, Preparing, UnderMaintenance } from 'components';
// Import Actions
import { CurrencyRatesAction } from 'actions';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import Constants
import { l, PERMISSIONS } from 'constants/common';

const MainAppRoutes = () => {
    const dispatch = useDispatch();
    const { setCurrencyRatesData } = CurrencyRatesAction;
    const { url } = useRouteMatch();
    const { doGetRequest } = useRequest();
    const { hasPermission } = useHasPermission();
    const { isUnderMaintenance, endDate } = useMaintenanceSettings();

    const { getPartnerState, getCurrencyRatesRequest } = useMemo(
        () => ({
            getPartnerState: UtilsHttpService.getPartnerState(),
            getCurrencyRatesRequest: UtilsHttpService.getCurrencyRates(),
        }),
        [],
    );

    const [isLoading, setIsLoading] = useState(true);
    const [partnerState, setPartnerState] = useState(() => ({}));

    const init = () => {
        doGetRequest(getPartnerState.request, {
            successCallback: (data) => {
                setPartnerState({ isActivated: data.IsCrmActivated, isPreparing: data.IsPreparing });
                setIsLoading(false);
            },
        });
        doGetRequest(getCurrencyRatesRequest.request, {
            successCallback: (data) => {
                dispatch(setCurrencyRatesData(data));
            },
        });
    };

    const cleanUp = () => {
        return () => getPartnerState.cancel('MainAppRoutes:getPartnerState');
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <Switch>
            {mainAppRoutes.map(({ path, component, titleKey, routeLeaveConfirmationType, isExact }, index) => {
                const Component = component;
                return (
                    <Route path={`${url}/${path}`} key={index} exact={isExact}>
                        <BusyLoader
                            isBusy={isLoading}
                            type="spinner"
                            spinnerSize="big"
                            className="crm-full-width crm-justify-content-center"
                        >
                            {!isLoading && (
                                <>
                                    {isUnderMaintenance && !hasPermission([PERMISSIONS.CRMAdmin]) ? (
                                        <UnderMaintenance endDate={endDate} />
                                    ) : !partnerState.isActivated &&
                                      !hasPermission([PERMISSIONS.CRMAdmin, PERMISSIONS.CRMManager]) ? (
                                        <NotActive />
                                    ) : (
                                        <>
                                            {partnerState?.isPreparing ? (
                                                <Preparing />
                                            ) : (
                                                <>
                                                    <Component titleKey={titleKey} />
                                                    {!partnerState.isActivated && (
                                                        <div className="crm-not-active-footer">
                                                            <span>{l.CRMIsNotActivated}</span>
                                                        </div>
                                                    )}
                                                    {!isUndefined(routeLeaveConfirmationType) && (
                                                        <RouteLeaveConfirmation
                                                            routeLeaveConfirmationType={routeLeaveConfirmationType}
                                                            pageName={titleKey}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </BusyLoader>
                    </Route>
                );
            })}

            <Route component={notFoundRoute.component} />
        </Switch>
    );
};

export default memo(MainAppRoutes);
